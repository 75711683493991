import { AppFactory } from '@app/app-factory';
import type { Stripe } from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { appConfig } from 'app/env';
// import { bugsnagNotify } from '@app/notification-service';

let stripeInstance: Stripe | null = null;

async function ensuredStripeInstance() {
  if (!stripeInstance) {
    // need an override for local development
    const overrideKey = appConfig.stripe.overridePublishableKey;
    const key =
      overrideKey || AppFactory.root.globalSettings.stripePublishableKey;
    // console.log(`stripe key`, key);
    stripeInstance = await loadStripe(key);
  }

  return stripeInstance;
}

export const useStripeCheckout = () => {
  return async (sessionId: string) => {
    // @armando, not sure if this should catch and only report, or trickle up to get caught and alerted
    // try {
    const stripe = await ensuredStripeInstance();
    return stripe.redirectToCheckout({ sessionId });
    // } catch (error) {
    //   bugsnagNotify(error as Error);
    // }
  };
};
