// codemod-ignore-file
import React from 'react';
import { AppFactory } from '@app/app-factory';
import { styled } from '@naan/stitches.config';
import { marketingSiteL1 } from 'components/nav/path-helpers';
import { PlainMarkdownWithLinks } from '@naan/primitives/text';
import __ from 'core/lib/localization';

const Wrapper = styled('div', {
  font: 'var(--body-font)',
  textAlign: 'center',
  color: 'var(--red)',
  marginTop: '56px',
  padding: '8px 12px',
  border: '1px solid var(--pink)',
  breakInside: 'avoid-page',

  '& .learn-more a': {
    color: 'var(--text-secondary)',
  },
});

export const StoryGuideLicense = ({ learnMore = false }) => {
  const { userManager } = AppFactory.root;
  const { email } = userManager?.accountData ?? {};
  return (
    <Wrapper>
      {__(
        'This guide is licensed to %{email} for use with the Jiveworld app in a classroom setting',
        'thisGuideIsLicensedTo',
        { email }
      )}
      {learnMore ? (
        <div className="learn-more">
          <PlainMarkdownWithLinks
            source={__(
              'Learn about Jiveworld for Schools at [jiveworld.com/schools](%{link})',
              'learnAboutJiveworldForSchools',
              { link: marketingSiteL1('/schools') }
            )}
          ></PlainMarkdownWithLinks>
        </div>
      ) : null}
    </Wrapper>
  );
};
