import * as React from 'react';

export function ArrowLeftExtraSmallIcon({
  width = 16,
  height = 16,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.82829 6.99991L13.9141 6.99991L13.9141 8.99991L5.82826 8.99991L8.12117 11.2928L6.70696 12.707L2.99983 8.99991L2.91406 8.99991L2.91406 8.91414L2.70695 8.70703L1.99985 7.99993L2.70695 7.29282L2.91406 7.08571L2.91406 6.99991L2.99987 6.99991L6.70696 3.29282L8.12117 4.70703L5.82829 6.99991Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}

export function ArrowLeftSmallIcon({
  width = 20,
  height = 20,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.03195 11H17.9743V9H6.03195L10.6526 4.7348L9.29602 3.2652L3.08332 9H2.9743V9.10063L2.79602 9.2652L1.99999 10L2.79602 10.7348L2.9743 10.8994V11H3.08332L9.29602 16.7348L10.6526 15.2652L6.03195 11Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}

export function ArrowLeftIcon({
  width = 24,
  height = 24,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.02176 11L12.6854 4.72837L11.3146 3.27197L2.04092 12.0002L11.3146 20.7284L12.6854 19.272L6.02139 13H22V11H6.02176Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}
