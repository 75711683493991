import * as React from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import { learnStoriesPath } from 'components/nav/path-helpers';
import { AppFactory } from '@app/app-factory';
import { Story } from '@core/models/story-manager';
import {
  SectionHeading,
  SectionHeadingLink,
} from './dashboard-section-heading';
import { NewStoryCard } from 'components/stories/new-story-card';
import { Button } from '@naan/primitives/button';
import { ArrowDownIcon } from '@naan/icons/arrow-down-icon';
import { styled, keyframes } from '@naan/stitches.config';
import { useIntersectionObserver } from '@common/hooks/use-intersection-observer';
import { MediumGap } from '@naan/primitives/spacer/gaps';
import { useSwitch2 } from '@naan/hooks/use-switch-2';

import __ from '@core/lib/localization';

const jumpingAnimation = keyframes({
  '0%': { transform: 'translateY(80px)' },
  '50%': { transform: 'translateY(-12px)' },
  '100%': { transform: 'translateY(0)' },
});

export const FloatingButtonWrapper = styled('div', {
  display: 'flex',
  position: 'sticky',
  bottom: 16,
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'red',
  height: 0,

  '.inner': {
    position: 'absolute',
    bottom: '0',
    opacity: 1,
    transform: 'translateY(80px)',
    transition: 'opacity .5s, transform .5s ease-out',
    animation: `${jumpingAnimation} .6s ease-out .8s forwards`,

    '&.hidden': {
      transform: 'translateY(100%)',
      opacity: 0,
    },
  },
});

const NewStoryFloatingButton = ({ isInView }: { isInView: boolean }) => (
  <FloatingButtonWrapper>
    <div
      className={classNames('inner', {
        hidden: isInView,
      })}
    >
      <Button
        presentation={'teal'}
        label={__('New story', 'newStory')}
        rightIcon={<ArrowDownIcon />}
        size="small"
        alignment={'unset'}
        onClick={() => {
          document
            .getElementById('new-widget')
            .scrollIntoView({ behavior: 'smooth' });
        }}
      />
    </div>
  </FloatingButtonWrapper>
);

export const NEW_STORY_KEY = 'lastNewStoryViewed';

export const NewStorySection = observer(({ story }: { story: Story }) => {
  const filterPath = learnStoriesPath({
    search: {
      sorting: 'release_date',
    },
  });

  return (
    <>
      <SectionHeading
        title={__('Recent releases', 'recentReleases')}
        renderLinks={() => (
          <SectionHeadingLink to={filterPath}>
            {__('Show all', 'showAll')}
          </SectionHeadingLink>
        )}
      />
      <NewStoryCard story={story} />
    </>
  );
});

export const NewStoryWidgetContents = observer(
  ({
    story,
    hideFloatingButton,
  }: {
    story: Story;
    hideFloatingButton?: boolean;
  }) => {
    const { userSettings } = AppFactory.root.userManager.userData;
    // we can remove the legacy localStorage state in a future release
    const seenStorySlug =
      userSettings.lastViewedNewStoryWidgetSlug ||
      localStorage.getItem(NEW_STORY_KEY);
    const seenStoryWidget = seenStorySlug === story.slug;
    const hideButton = hideFloatingButton ?? seenStoryWidget;
    const hideButtonSwitch = useSwitch2(hideButton);

    const { isInView, newWidgetRef } = useIntersectionObserver({
      inViewCallback: () => {
        if (seenStorySlug !== story.slug) {
          // localStorage.setItem(NEW_STORY_KEY, story.slug);
          userSettings.updateLastViewedNewStoryWidgetSlug(story.slug);
          hideButtonSwitch.on();
        }
      },
    });

    return (
      <>
        <div id="new-widget" ref={newWidgetRef}>
          <NewStorySection story={story} />
        </div>
        {hideButtonSwitch.value ? null : (
          <NewStoryFloatingButton isInView={isInView} />
        )}
        <MediumGap />
      </>
    );
  }
);

export const NewStoryWidget = observer(
  ({ hideFloatingButton }: { hideFloatingButton?: boolean }) => {
    const DAYS = 30;
    const { storyManager } = AppFactory.root;
    const story = storyManager.getNewestNeverStudiedStoryWithin(DAYS);

    if (!story) {
      return null;
    }

    return (
      <NewStoryWidgetContents
        story={story}
        hideFloatingButton={hideFloatingButton}
      />
    );
  }
);
