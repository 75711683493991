import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { NavigateFunction, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { FullScreenLoader } from 'components/ds/modals';
import { AppFactory } from 'app/app-factory';
import { homePath } from 'components/nav/path-helpers';
import { bugsnagNotify } from '@app/notification-service';
import { createLogger } from '@common/log';

const log = createLogger('affiliate-attribution');

const attributeCode = async (code: string, navigate: NavigateFunction) => {
  log.info(`attributing session with affiliate code: ${code}`);
  const { userManager } = AppFactory.root;
  await userManager.applyAffiliateCode(code);
  navigate(homePath(), { replace: true });
};

export const AffiliateAttribution = observer(() => {
  const { code } = useParams<{ code: string }>();
  const navigate = useNavigate();
  // todo: capture urchin params

  useEffect(() => {
    attributeCode(code, navigate).catch(bugsnagNotify);
  }, [code, navigate]);

  return <FullScreenLoader />;
});
