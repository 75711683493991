import { styled } from '@naan/stitches.config';

export const ChapterItemContents = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: 12,
  paddingLeft: 40,
  '& > :last-child': {
    marginBottom: 16,
  },
});

export const CollapsibleWrapper = styled('div', {
  display: 'grid',
  gridTemplateRows: '1fr',
  transition: 'grid-template-rows .3s',
  '& > div.expandable-inner': {
    gridRow: '1 / span 2',
    // we use 'clip' rather than 'hidden' here so that the listen widget's outline can extend horizontally outside the container when we point a tooltip at it
    overflowY: 'clip',
  },

  '&.collapsed': {
    gridTemplateRows: '0fr',
  },
});
