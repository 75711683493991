import { makeObservable, observable } from 'mobx';
// import { createLogger } from '@common/log';
// const log = createLogger('sign-up-model');

class SignUpModel {
  name: string;
  email: string;
  password: string;

  constructor() {
    makeObservable(this, {
      name: observable,
      email: observable,
      password: observable,
    });
  }

  reset() {
    this.name = '';
    this.email = '';
    this.password = '';
  }
}

// state shared between password and code versions of signin screen
const signUpModel = new SignUpModel();

export const getSignUpModel = () => signUpModel;
