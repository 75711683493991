import { ModelTreeNode } from 'ts-state-tree/tst-core';

export const msToPoints = (ms: number) => {
  const msPerPoint = 6000;
  return Math.round(ms / msPerPoint);
};

export const msToMins = (ms: number, floor: boolean = true) => {
  const amount = ms / (1000 * 60);

  if (floor) {
    return Math.floor(amount);
  }

  return amount;
};

// todo: confirm if needs to be TST tree or not
export class ListeningStats extends ModelTreeNode {
  static CLASS_NAME = 'ListeningStats' as const;

  static create(snapshot: any) {
    return super.create(ListeningStats, snapshot) as ListeningStats;
  }

  millisListened: number = 0;
  // millisRelistened: number = 0;

  get minsListened() {
    return msToMins(this.millisListened);
  }

  // get minsRelistened() {
  //   return msToMins(this.millisRelistened);
  // }

  get totalMillis() {
    return this.millisListened; // + this.millisRelistened;
  }

  get totalPoints() {
    // could have slightly different rounding that converting the combined millis
    // return msToPoints(this.millisListened) + msToPoints(this.millisRelistened);
    return msToPoints(this.totalMillis);
  }
}
