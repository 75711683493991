import * as React from 'react';
// import * as PC from './pricing-cards/pricing-card';
import { Plan } from '@cas-shared/plan';
import { useStripeCheckout } from 'common/stripe-context';
import { alertSevereError } from '@app/notification-service';
import { AppFactory } from '@app/app-factory';
import { darkThemeSelector, styled } from '@naan/stitches.config';
import { Button } from '@naan/primitives/button';
import { ArrowRightIcon } from '@naan/icons/arrow-right-icon';
import { useDerivedPlanVars } from './use-derived-plan-vars';

import __, { addNbsps } from 'core/lib/localization';

const useStripeCheckoutHandler = (plan: Plan) => {
  const stripeCheckout = useStripeCheckout();
  const handleCheckout = React.useCallback(() => {
    const { membership } = AppFactory.root.userManager;
    membership
      .initiateCheckout(plan)
      .then(result => {
        return stripeCheckout(result.stripeSessionId);
      })
      .catch(error => {
        alertSevereError({ error, note: 'PlanPricingCard.handleCheckout' });
      });
  }, [plan, stripeCheckout]);

  return handleCheckout;
};

export const PlanSubscribeButton = ({
  plan,
  debug = false,
}: {
  plan: Plan;
  debug?: boolean;
}) => {
  const handleCheckout = useStripeCheckoutHandler(plan);
  const debugLabel = debug ? `(${plan.interval})` : '';
  return (
    <Button
      onClick={handleCheckout}
      label={__('Subscribe', 'subscribe') + debugLabel}
      presentation="green"
      size="extraLarge"
      rightIcon={<ArrowRightIcon />}
      fullWidth
    />
  );
};

const StandardCardWrapper = styled('div', {
  $$borderColor: '$colors$globalWhite-alpha-10',
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  padding: 20,
  paddingTop: 16,
  gap: 16,
  borderRadius: '$radii$large',
  border: '4px solid $$borderColor',
  background: '$colors$globalBlack-alpha-10',
  color: '$colors$globalWhite',
  backdropFilter: 'blur(10px)',
  position: 'relative',
  cursor: 'pointer',

  '& .card-title': {
    textStyle: 'small-heading',
    marginTop: 5,
  },

  '& .checkmark-icon': {
    position: 'absolute',
    top: -10,
    right: -10,
    borderRadius: '50%',
    color: '$green-500',
    opacity: 0,
    // boxShadow: '0 1px 4px $colors$black-alpha-20',
    shadowStyle: 'small',
    background: '$colors$green-500',
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  '& .card-content': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    gap: 8,

    '& .card-price-per-interval': {
      alignItems: 'flex-end',
      display: 'flex',
      '& .currency, & .interval': {
        marginBottom: 2,
      },
      '& .amount': {
        textStyle: 'medium-heading',
        margin: '0 1px',
      },
    },

    '& .card-pricing-expansion': {
      textAlign: 'right',
      '& .striked-amount': {
        textDecoration: 'line-through',
      },

      '& .amount': {
        textStyle: 'small-heading',
      },

      '& .explanation': {
        textTransform: 'lowercase',
      },
    },

    '& .yellow-text': {
      marginTop: -8,
      textStyle: 'body-bold',
      color: '$colors$yellow-300',
      [darkThemeSelector]: {
        color: '$colors$yellow-700',
      },
    },
  },

  variants: {
    selected: {
      true: {
        borderColor: '$colors$green-600',
        [darkThemeSelector]: {
          borderColor: '$colors$green-400',
        },
        '& .checkmark-icon': {
          opacity: 1,
        },
      },
    },
  },
});

const CheckMark = (
  <svg
    width={12}
    height={12}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.2041 0.900391L11.7941 2.01369L5.29985 11.2884L0 7.57743L1.1133 5.98747L4.82319 8.58517L10.2041 0.900391Z"
      fill="white"
    />
  </svg>
);

export const StandardPlanPricingCard = ({
  plan,
  selectAction,
  selected = false,
}: {
  plan: Plan;
  selectAction?: () => void;
  selected?: boolean;
}) => {
  const { isMonthly, diminutiveCurrencyPrefix } = plan;

  const {
    intervalLabel,
    actualPrice,
    perMonthPrice,
    annualizedMonthlyPrice,
    planPrice, // before percentage discount
    yellowText,
    hasPercentageDiscount,
    formatCurrency,
  } = useDerivedPlanVars(plan);

  const handleClick = React.useCallback(() => {
    if (selectAction) {
      selectAction();
    }
  }, [selectAction]);

  return (
    <StandardCardWrapper onClick={handleClick} selected={selected}>
      <div className="checkmark-icon">{CheckMark}</div>
      <h3 className="card-title">{intervalLabel}</h3>
      <div className="card-content">
        <div className="card-price-per-interval">
          {!!diminutiveCurrencyPrefix ? (
            <span className="currency">{diminutiveCurrencyPrefix}</span>
          ) : null}
          <span className="amount">{formatCurrency(perMonthPrice)}</span>
          <span className="interval">{__('/mo', 'perMoLower')}</span>
        </div>
        {isMonthly ? (
          hasPercentageDiscount ? (
            <div className="card-pricing-expansion">
              <span className="striked-amount">
                {formatCurrency(planPrice)}
                {__('/mo', 'perMoLower')}
              </span>
            </div>
          ) : null
        ) : (
          <div className="card-pricing-expansion">
            <span className="striked-amount">
              {formatCurrency(annualizedMonthlyPrice)}
            </span>
            <span className="amount"> {formatCurrency(actualPrice)} </span>
            <span className="explanation">
              {/* downcased via css transform */}
              {addNbsps(__('Billed yearly', 'billedYearly'))}
            </span>
          </div>
        )}
        {yellowText ? <div className="yellow-text">{yellowText}</div> : null}
      </div>
    </StandardCardWrapper>
  );
};
