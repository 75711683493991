import * as React from 'react';

export function LifebuoyIcon({
  width = 24,
  height = 24,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="9.5" stroke={color} />
      <circle cx="12" cy="12" r="5.5" stroke={color} />
      <path d="M10 6.5L9.5 3L12 2.5L14.5 3L14 6.5H10Z" fill={color} />
      <path d="M17.5 10L21 9.5L21.5 12L21 14.5L17.5 14L17.5 10Z" fill={color} />
      <path d="M10 17.5L9.5 21L12 21.5L14.5 21L14 17.5H10Z" fill={color} />
      <path d="M6.5 10L3 9.5L2.5 12L3 14.5L6.5 14L6.5 10Z" fill={color} />
    </svg>
  );
}
