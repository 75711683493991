import * as React from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { HomeIcon } from 'naan/icons/home-icon';
import { FavoriteFilledIcon } from 'naan/icons/favorite-filled-icon';
import { AvatarIcon } from 'naan/icons/avatar-icon';
import { EduIcon } from 'naan/icons/edu-icon';
import {
  ExternalItemWrapper,
  LinkNavItem,
  NavArrow,
  NavBottomAnchor,
  NavContainer,
  NavItem,
  NavLogoWrapper,
  PopoverNavHeader,
  PopoverNavTrigger,
} from 'components/ui/navigation/responsive-navigation';
import { MenuContent } from 'naan/primitives/menus/menu-content';
import { MenuItem } from 'naan/primitives/menus';
import { SignOutIcon } from 'naan/icons/sign-out-icon';
import {
  ArrowTopRightExtraSmallIcon,
  ArrowTopRightIcon,
} from 'naan/icons/arrow-top-right-icon';
import { Text } from 'naan/primitives/text';
import { useNavigate } from 'react-router-dom';
import { Button } from 'naan/primitives/button';
import { Responsive } from 'naan/primitives/responsive';
import { Box } from 'naan/primitives/box';
import {
  classroomsPath,
  discoverPath,
  favoritesPath,
  learnHomePath,
  logoutPath,
  profileHomePath,
} from './path-helpers';
import { SharedMenuItems } from './learn-navigation';
import { AppFactory } from '@app/app-factory';
import {
  clearSavedScrollPosition,
  intentionallyResetAllScrollStorageKeys,
} from 'lib/scroll-utils/deep-scroll-restoration';

import __ from 'core/lib/localization';
import { JwLogo } from 'components/branding/jw-logo';

// type MenuItemProps = React.ComponentProps<typeof MenuItem>;

// const ExternalMenuItem = ({
//   label,
//   leftIcon,
//   action,
// }: Pick<MenuItemProps, 'label' | 'leftIcon' | 'action'>) => {
//   return (
//     <MenuItem
//       label={label}
//       leftIcon={leftIcon}
//       rightIcon={<ArrowTopRightIcon width={20} height={20} />}
//       presentation="navItem"
//       action={action}
//     />
//   );
// };

const TeachMenuContent = () => {
  const { accountData } = AppFactory.root.userManager;

  const navigate = useNavigate();

  const goto = React.useCallback(
    (to: string) => () => {
      clearSavedScrollPosition(to);
      navigate(to);
    },
    [navigate]
  );

  const handleGoToMy = React.useCallback(() => {
    const to = learnHomePath();
    clearSavedScrollPosition(to as string);
    navigate(to);
  }, [navigate]);

  const handleLogout = React.useCallback(() => {
    navigate(logoutPath());
    intentionallyResetAllScrollStorageKeys();
  }, [navigate]);

  // const open = React.useCallback(
  //   (to: string) => () => {
  //     openUrl(to);
  //   },
  //   []
  // );

  // const handleApplyCode = useCouponDialog();

  return (
    <MenuContent
      sideOffset={-6}
      collisionPadding={8}
      css={{ minWidth: '300px' }}
    >
      <PopoverNavHeader>
        <Text textStyle="small-heading">{accountData.name}</Text>
        <Text color="textSecondary" textStyle="small-text">
          {accountData.email}
        </Text>
      </PopoverNavHeader>
      <MenuItem
        label={__('Manage my account', 'manageMyAccount')}
        leftIcon={<AvatarIcon />}
        presentation="navItem"
        action={goto(profileHomePath())}
      />
      <SharedMenuItems />
      {/* // todo: refactor to handle native wrapper logout here also */}
      <MenuItem
        label={__('Sign out', 'signOut')}
        leftIcon={<SignOutIcon />}
        presentation="navItem"
        action={handleLogout}
      />
      <Responsive
        renderDefault={() => (
          <Box css={{ padding: 16 }}>
            <Button
              label={__('Go to Jiveworld', 'goToJiveworld')}
              onClick={handleGoToMy}
              rightIcon={<ArrowTopRightIcon width={20} height={20} />}
              presentation="grayLight"
              size={'small'}
            />
          </Box>
        )}
        renderLargeAndUp={() => null}
      />
      <DropdownMenu.Arrow asChild>
        <NavArrow />
      </DropdownMenu.Arrow>
    </MenuContent>
  );
};

export const TeachNavigation = () => {
  const { accountData } = AppFactory.root.userManager;
  const navigate = useNavigate();
  const handleGoToMy = React.useCallback(() => {
    const to = learnHomePath();
    clearSavedScrollPosition(to as string);
    navigate(to);
  }, [navigate]);

  return (
    <NavContainer presentation={'classroom'}>
      <NavLogoWrapper>
        <JwLogo subBrand="classrooms" presentation="colorOnDark" />
      </NavLogoWrapper>
      <Responsive
        renderLargeAndUp={() => (
          <ExternalItemWrapper>
            <Button
              label={__('Go to Jiveworld', 'goToJiveworld')}
              onClick={handleGoToMy}
              rightIcon={<ArrowTopRightExtraSmallIcon />}
              size={'small'}
              presentation="whiteLighten"
            />
          </ExternalItemWrapper>
        )}
      />
      <LinkNavItem
        to={classroomsPath()}
        // beforeNavigate={clearSavedScrollPosition}
        icon={<EduIcon />}
        label={__('My classes', 'myClasses')}
      />
      <LinkNavItem
        to={discoverPath()}
        // beforeNavigate={clearSavedScrollPosition}
        icon={<HomeIcon />}
        label={__('Discover', 'discover')}
      />
      <LinkNavItem
        to={favoritesPath()}
        // beforeNavigate={clearSavedScrollPosition}
        icon={<FavoriteFilledIcon />}
        label={__('Favorites', 'favorites')}
      />

      <NavBottomAnchor>
        <DropdownMenu.Root>
          <PopoverNavTrigger>
            <NavItem
              icon={<AvatarIcon />}
              label={
                <>
                  <Box
                    css={{ display: 'none', '@large': { display: 'block' } }}
                  >
                    {accountData.name}
                  </Box>
                  <Box css={{ '@large': { display: 'none' } }}>
                    {__('Me', 'me')}
                  </Box>
                </>
              }
            />
          </PopoverNavTrigger>
          <TeachMenuContent />
        </DropdownMenu.Root>
      </NavBottomAnchor>
    </NavContainer>
  );
};
