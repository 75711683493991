import * as React from 'react';
import * as Dialog from '@naan/primitives/modals/dialog';

import classnames from 'classnames';
import { observer } from 'mobx-react';
import { MediumGap } from '@naan/primitives/spacer/gaps';
import { Classroom } from 'core/models/user-manager';
import { keyframes, styled } from 'naan/stitches.config';
import { OverflowIcon } from '@naan/icons/overflow-icon';
import { Menu, MenuItem } from '@naan/primitives/menus';
import { IconButton } from '@naan/primitives/button';
import { AppFactory } from '@app/app-factory';
import { storiesPrimaryFiltered } from 'components/nav/path-helpers';
import { Link } from 'react-router-dom';
import { alertSevereError } from '@app/notification-service';
import { DialogHtmlContainer } from 'components/ui/dialog-html-container';
import { ellipsis } from '@utils/string-utils';
import { SectionHeading } from 'components/learn/dashboard/dashboard-section-heading';

import __ from 'core/lib/localization';

const fade = keyframes({
  '0%': { background: '#FFEEAA' },
  '50%': { background: '#FFEEAA' },
  '100%': { background: 'transparent' },
});

const Wrapper = styled('div', {
  $$borderColor: '$colors$gray-100',

  textStyle: 'body',

  '& .title': {
    textStyle: 'medium-heading',
    marginBottom: 16,
  },

  '& .class-list': {
    borderBottom: '1px solid $$borderColor',
  },

  '& .class-row': {
    borderTop: '1px solid $$borderColor',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&.highlighted': {
      animationName: `${fade}`,
      animationDuration: '2.5s',
    },

    '& .name': {
      flex: '1',
      padding: '20px 0',

      '& a': {
        color: '$colors$teal-500',
      },
    },

    'div.menu': {
      marginLeft: 'auto',
    },
  },
});

const DropClassroomConfirmationDialog = observer(
  ({
    classroom,
    onOk,
    onDismiss,
  }: {
    classroom: Classroom;
    onOk: () => void;
    onDismiss: () => void;
  }) => {
    return (
      <DialogHtmlContainer onDismiss={onDismiss}>
        <Dialog.HtmlHeading>
          {__('Leave %{class}?', 'leaveClassroomDialogTitle', {
            class: classroom.label,
          })}
        </Dialog.HtmlHeading>
        <Dialog.Body>
          {__(
            'You will no longer see class assignments, and your progress will not be visible to the teacher.',
            'leaveClassroomDialogBody'
          )}
        </Dialog.Body>
        <Dialog.ButtonsContainer>
          <Dialog.Button
            onClick={onDismiss}
            label={__('Cancel', 'cancel')}
            presentation="grayTransparent"
          />
          <Dialog.Button
            onClick={onOk}
            label={__('OK', 'ok')}
            presentation="teal"
          />
        </Dialog.ButtonsContainer>
      </DialogHtmlContainer>
    );
  }
);

export const JoinedClassrooms = observer(() => {
  const { joinedL2Classrooms } = AppFactory.root.userManager.accountData;

  const handleDropClassroom = (classroom: Classroom) => {
    AppFactory.dialogPresenter.present(onDismiss => (
      <DropClassroomConfirmationDialog
        classroom={classroom}
        onOk={async () => {
          classroom
            .drop()
            .catch(error =>
              alertSevereError({ error, note: 'joined-classrooms - drop' })
            );
          onDismiss();
        }}
        onDismiss={onDismiss}
      />
    ));
  };

  if (joinedL2Classrooms?.length === 0) {
    return null;
  }
  return (
    <>
      <SectionHeading title={__('My classes', 'myClasses')} />
      <Wrapper>
        <div className="class-list">
          {joinedL2Classrooms?.map(classroom => {
            const storiesPath = storiesPrimaryFiltered(classroom.filterKey);

            return (
              <div
                className={classnames('class-row', {
                  highlighted: false,
                })}
                key={`class-${classroom.id}`}
              >
                <div className="name">
                  {classroom.label}{' '}
                  <Link to={storiesPath}>
                    {__('View assignments', 'viewAssignments')}
                  </Link>
                </div>

                <div className="menu">
                  <Menu
                    trigger={
                      <IconButton
                        presentation={'grayTransparent'}
                        icon={<OverflowIcon />}
                        testId={`joined-classroom-menu-${classroom.id}`}
                      />
                    }
                  >
                    <MenuItem
                      label={__('Leave classroom', 'leaveClassroom') + ellipsis}
                      action={() => {
                        handleDropClassroom(classroom);
                      }}
                    />
                  </Menu>
                </div>
              </div>
            );
          })}
        </div>
        <MediumGap />
      </Wrapper>
    </>
  );
});
