import * as React from 'react';
import { styled } from 'naan/stitches.config';
import { AlertIcon } from 'naan/icons/alert-icon';

const Wrapper = styled('div', {
  textStyle: 'body',
  color: '$red-500',
  padding: '$space$3 0',
  transition: 'all 0.3s',
  display: 'flex',

  '&.max-height': {
    maxHeight: '9rem',
  },

  '&.no-max-height': {
    maxHeight: 'auto',
  },

  '.error-icon': {
    marginRight: '$space$2',
  },
});

type FormErrorProps = {
  error?:
    | string
    | {
        message?: string;
      };
  maxHeight?: boolean;
};

export const FormError = ({ error, maxHeight = true }: FormErrorProps) => {
  if (!error) {
    return null;
  }
  const message = typeof error === 'string' ? error : error.message;

  return (
    <Wrapper
      data-test-id="error-message"
      className={'field-error' + maxHeight ? 'max-height' : 'no-max-height'}
    >
      <div className="error-icon">
        <AlertIcon />
      </div>
      {message}
    </Wrapper>
  );
};
