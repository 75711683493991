import { Divider } from 'naan/primitives/divider';
import { VSpacer } from 'naan/primitives/spacer';
import { Text } from 'naan/primitives/text';
import React, { Fragment } from 'react';
import { CouponPurchase } from './coupon-purchase';

import __ from 'core/lib/localization';

export const PurchasedCoupons = ({ coupons }: { coupons: any }) => {
  if (!coupons.length) {
    return null;
  }
  return (
    <div className="gift-coupons__purchased">
      <Text textStyle="medium-heading">
        {__('My coupon purchases', 'myCouponPurchases')}
      </Text>
      <VSpacer size={4} />
      <Divider />
      {coupons.map((coupon: any) => (
        <Fragment key={coupon.code}>
          <CouponPurchase coupon={coupon} />
          <Divider />
        </Fragment>
      ))}
      <VSpacer size={14} />
    </div>
  );
};
