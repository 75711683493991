import {
  ElementList,
  IndexedElement,
  IndexRange,
  NO_INDEX,
  WordElement,
} from '../basic-types';
import { Interval, isPoint, Intervals } from '../intervals/intervals';
import { ElementNodeType as BaseElementNodeType } from './element-tree';
import { Precedence } from './precedence';

interface ElementNodeImpl<
  ET extends IndexedElement | WordElement,
  CET extends IndexedElement | WordElement
> extends BaseElementNodeType<ET, CET> {
  interval: Interval;
}

// TODO duplicating now, move shared module or find a builtin?

export class ElementTreeRendererImpl<
  ET extends IndexedElement,
  WT extends WordElement,
  ENT extends ElementNodeImpl<ET | WT, ET | WT> = ElementNodeImpl<
    ET | WT,
    ET | WT
  >
> {
  elements: ElementList<ET>;
  precedence: Precedence<readonly string[]>;
  leastPrecedence: number; // count of precedence levels
  words: WordElement[]; // TODO change to some kind of generic Atom type?
  elementKindLists: ElementList<IndexedElement>[] = [];
  indexes: Intervals[] = [];
  isPoint: boolean[] = [];

  constructor(
    elements: ElementList<ET>,
    words: WordElement[],
    precedence0: Precedence<readonly string[]>,
    asPoints: readonly string[] = [] as const,
    asSpans: readonly string[] = [] as const
  ) {
    this.elements = elements;
    this.precedence = precedence0;
    this.leastPrecedence = this.precedence.leastPrecedenceLevel();
    this.words = words;
    for (const kind of this.precedence.precedence) {
      // TODO figure out how to tell typescript that kind type is one string and not union
      const kindList = this.elements.filterByKind(
        kind
      ) as unknown as ElementList<IndexedElement>;
      this.elementKindLists.push(kindList);
      if (asSpans.includes(kind)) {
        this.isPoint.push(false);
        const intervals = kindList.wordIntervals;
        this.indexes.push(intervals);
      } else if (asPoints.includes(kind)) {
        this.isPoint.push(true);
        const intervals = kindList.wordIntervals.fromStartPoints();
        this.indexes.push(intervals);
      } else {
        // TODO throw?
        // this.indexes.push(kindList.wordIntervals);
      }
    }
    // this.indexes = this.elementKindLists.map(
    // elementList => elementList.atomIntervals
    // ); // TODO change to atomIntervals
  }

  findWithPrecedence(
    wordIndex: number,
    precedenceLevel0: number
  ): [ENT, number][] {
    let precedenceLevel = precedenceLevel0;
    let foundIndex = NO_INDEX;
    let foundInterval = null;
    let foundElement: IndexedElement | WordElement = null;

    let p = precedenceLevel;
    const makeResult = (
      element: IndexedElement | WordElement,
      interval: Interval
    ) => {
      return [{ element, interval, children: null }, p] as unknown as [
        ENT,
        number
      ];
    };

    while (p < this.leastPrecedence && !foundInterval) {
      const intervals = this.indexes[p];
      const isPoint = this.isPoint[p];
      foundIndex = intervals.containing(wordIndex);
      if (foundIndex !== NO_INDEX) {
        const elementList = this.elementKindLists[p];
        // TODO change implementation of Intervals to always return range interval
        foundInterval = intervals.intervalAt(foundIndex);
        if (isPoint) {
          foundInterval.end = NO_INDEX;
        }
        const elements = elementList.values;
        foundElement = elements[foundIndex];
        if (isPoint) {
          if (foundInterval.begin !== wordIndex) {
            foundInterval = null;
            p++;
          } else {
            while (foundIndex > 0) {
              if (elements[foundIndex - 1].address === wordIndex) {
                foundIndex--;
              } else {
                break;
              }
            }
            foundElement = elements[foundIndex];
            const result = [makeResult(foundElement, foundInterval)];
            for (let i = foundIndex + 1; i < elements.length; i++) {
              const nextOfKind = elements[i];
              if (nextOfKind.address === wordIndex) {
                result.push(makeResult(nextOfKind, foundInterval));
              } else {
                break;
              }
            }
            return result;
          }
        }
      } else {
        p++;
      }
    }

    if (!foundInterval && this.words) {
      foundIndex = wordIndex;
      foundElement = this.words[foundIndex];
    }

    if (foundIndex !== NO_INDEX) {
      return [makeResult(foundElement, foundInterval)];
    } else {
      return [[null, null]];
    }
  }

  getTreeOfNodesWithPrecedence(
    range: IndexRange,
    precedenceLevel0: number
  ): ENT[] {
    const tree: ENT[] = [];
    const startingPrecedence = precedenceLevel0 + 1;
    let precedenceLevel = startingPrecedence;
    let index = range.begin;
    while (index <= range.end) {
      let node: ENT = null;
      let resultPrecedence: number;
      const results = this.findWithPrecedence(index, precedenceLevel);
      [node, resultPrecedence] = results.pop();
      if (results.length) {
        for (const result of results) {
          // eslint-disable-next-line no-unused-vars
          const [n, _] = result;
          tree.push(n);
        }
      }
      if (node) {
        if (node.interval && isPoint(node.interval)) {
          precedenceLevel = resultPrecedence + 1;
        } else {
          precedenceLevel = startingPrecedence;
          if (node.interval) {
            let interval = node.interval;
            const begin = Math.max(interval.begin, index);
            const end = Math.min(interval.end, range.end);
            interval = { begin, end };
            node.interval = interval;
            const newSearchRange = { begin, end };
            node.children = this.getTreeOfNodesWithPrecedence(
              newSearchRange,
              resultPrecedence
            );
            index = Math.min(range.end + 1, end + 1);
          } else {
            index++;
          }
        }
        tree.push(node);
      } else {
        return tree;
      }
    }
    return tree;
  }

  getTreeOfNodesForRange(range: IndexRange) {
    return this.getTreeOfNodesWithPrecedence(range, -1);
  }

  getTreeOfNodes() {
    return this.getTreeOfNodesForRange({
      begin: 0,
      end: this.words.length - 1,
    });
  }
}
