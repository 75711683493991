import * as React from 'react';
import { Soundbite } from '@core/models/story-manager/soundbite';
import { icons } from 'components/soundbites/soundbites-icons';
import classNames from 'classnames';
import { darkThemeSelector, styled } from '@naan/stitches.config';
import { Button } from '@naan/primitives/button';
import { useChapterRowUiContext } from './chapter-row-ui-model';
import { observer } from 'mobx-react-lite';
import autoAnimate from '@formkit/auto-animate';
import { CheckmarkCircleIcon } from '@naan/icons/checkmark-circle-icon';
import { Link } from 'react-router-dom';
import { calendarSoundbitePath } from 'components/nav/path-helpers';
import { SoundbiteIcon } from '@naan/icons/soundbite-icon';

import __ from '@core/lib/localization';

const FocusedWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 4,
  width: '100%',

  $$contextualButtonBackgroundColor: '$colors$orange-500',
  $$contextualButtonBackgroundColorHover: '$colors$orange-600',
  $$contextualButtonBackgroundColorActive: '$colors$orange-700',
  $$contextualButtonTextColor: '$colors$globalWhite',

  '& .category-name': {
    textStyle: 'small-caps',
    color: '$textSecondary',
    cursor: 'default',
  },
  '& .sb-icon > svg': {
    width: 96,
    height: 96,
    marginTop: 16,
  },
  '& a': {
    flex: 1,
    marginTop: 24,
    width: '100%',
  },
  '@medium': {
    '& .sb-icon > svg': {
      width: 96,
      height: 96,
      marginTop: 32,
    },
    '& a': {
      marginTop: 40,
    },
  },
});

const FocusedSoundbiteCardContents = observer(
  ({ soundbite }: { soundbite: Soundbite }) => {
    const iconSet = icons[soundbite.category as keyof typeof icons] ?? null;
    const LargeIcon = iconSet?.large ?? null;

    return (
      <>
        <FocusedWrapper>
          <span className="sb-icon">{LargeIcon && <LargeIcon />}</span>
          <span className="category-name">
            {soundbite.localizedCategoryLabel}
          </span>
          <Link to={calendarSoundbitePath(soundbite.slug)}>
            <Button
              leftIcon={<SoundbiteIcon />}
              presentation="contextual"
              label={__('Begin Soundbite', 'beginSoundbite')}
              size="extraLarge"
              fullWidth
            />
          </Link>
        </FocusedWrapper>
      </>
    );
  }
);

const UnfocusedWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  '& svg': {
    width: 40,
    height: 40,
  },
});

const UnfocusedSoundbiteCardContents = observer(
  ({ soundbite }: { soundbite: Soundbite }) => {
    const iconSet = icons[soundbite.category as keyof typeof icons] ?? null;
    const SmallIcon = iconSet?.small ?? null;

    return <UnfocusedWrapper>{SmallIcon && <SmallIcon />}</UnfocusedWrapper>;
  }
);

const CompletedWrapper = styled(Link, {
  all: 'unset',
  cursor: 'pointer',
  flex: 1,
  width: '100%',
  display: 'grid',
  gridTemplateColumns: 'auto 1fr auto',
  alignItems: 'center',
  gap: 12,
  '& .icon svg': {
    width: 40,
    height: 40,
  },
  '& .text': {
    display: 'flex',
    flexDirection: 'column',
    // gap: 4,
    '& .category-name': {
      textStyle: 'small-caps',
      color: '$textSecondary',
      cursor: 'default',
    },
    '& .soundbite-name': {
      textStyle: 'small-text-bold',
      color: '$textSecondary',
      cursor: 'default',
    },
  },
});

const CompletedSoundbiteCardContents = observer(
  ({ soundbite }: { soundbite: Soundbite }) => {
    const Icon =
      icons[soundbite.category as keyof typeof icons]?.['small'] ?? null;

    return (
      <CompletedWrapper to={calendarSoundbitePath(soundbite.slug)}>
        <div className="icon">{Icon && <Icon presentation="grayscale" />}</div>
        <div className="text">
          <div className="category-name">
            {soundbite.localizedCategoryLabel}
          </div>
          <div className="soundbite-name">{soundbite.title}</div>
        </div>
        <div className="checkmark">
          <CheckmarkCircleIcon color="var(--colors-green-500)" />
        </div>
      </CompletedWrapper>
    );
  }
);

const Wrapper = styled('div', {
  padding: 16,
  background: '$colors$white',
  [darkThemeSelector]: {
    background: '$colors$gray-50',
  },
  borderRadius: 12,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
  '&.current': {
    // boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.20)',
    shadowStyle: 'small',
  },
});

export const ChapterSoundbiteCard = observer(
  ({
    soundbite,
    // focused,
    index,
  }: {
    soundbite: Soundbite;
    // focused: boolean;
    index: number;
  }) => {
    const parent = React.useRef<HTMLDivElement>(null);
    // const completed = false;

    React.useEffect(() => {
      parent.current &&
        autoAnimate(parent.current, {
          duration: 150,
          easing: 'ease-out',
        });
    }, [parent]);

    const model = useChapterRowUiContext();
    const focused = model.currentSoundbiteIndex === index;

    const onCardClick = React.useCallback(() => {
      if (model.currentSoundbiteIndex === index) {
        return;
      }
      model.setCurrentSoundbiteIndex(index);
    }, [model, index]);

    if (soundbite.completed) {
      return (
        <Wrapper>
          <CompletedSoundbiteCardContents soundbite={soundbite} />
        </Wrapper>
      );
    }

    return (
      <Wrapper
        ref={parent}
        onClick={onCardClick}
        className={classNames([
          'soundbite-card',
          {
            current: focused,
          },
        ])}
      >
        {focused ? (
          <FocusedSoundbiteCardContents soundbite={soundbite} />
        ) : (
          <UnfocusedSoundbiteCardContents soundbite={soundbite} />
        )}
      </Wrapper>
    );
  }
);
