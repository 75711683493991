import * as React from 'react';

export function EyeIcon({ width = 24, height = 24, color = 'currentColor' }) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.17214 11.4427C3.98486 7.45504 7.79667 5 12 5C16.2033 5 20.0151 7.45504 22.8279 11.4475C23.0574 11.7742 23.0574 12.2306 22.8279 12.5573C20.0151 16.545 16.2033 19 12 19C7.79667 19 3.98486 16.545 1.17214 12.5525C0.94262 12.2258 0.94262 11.7694 1.17214 11.4427ZM8.00813 11.7393C7.8564 14.1554 9.84844 16.1436 12.2607 15.9919C14.2488 15.8635 15.8635 14.2488 15.9919 12.2607C16.1436 9.84455 14.1516 7.85641 11.7393 8.00815C9.74728 8.13265 8.13264 9.74729 8.00813 11.7393Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}
