import * as React from 'react';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import { keyframes, styled, darkThemeSelector } from 'naan/stitches.config';
import { CloseButton, Overlay } from 'naan/primitives/modals';
import { Divider } from '../divider';
import { VSpacer } from '../spacer';
import { MarkSimple } from '../text';

const Root = DialogPrimitive.Root;
const Portal = DialogPrimitive.Portal;

const Heading = styled('div', {
  paddingTop: '$space$4',
  px: '$space$4',
  textStyle: 'small-heading',
  '@medium': {
    textStyle: 'medium-heading',
    px: '$space$6',
  },
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const contentShow = keyframes({
  '0%': { opacity: 0, transform: 'translate(-50%, -48%) scale(.75)' },
  '100%': { opacity: 1, transform: 'translate(-50%, -50%) scale(1)' },
});

const Content = styled(DialogPrimitive.Content, {
  $$maxWidth: '960px',
  $$maxHeight: '800px',
  backgroundColor: '$white',
  [darkThemeSelector]: {
    backgroundColor: '$colors$gray-50',
  },
  borderRadius: '$radii$large',
  shadowStyle: 'medium',
  position: 'fixed',
  top: 'calc(50% + (var(--sat) / 2) - (var(--sab) / 2))',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'min(calc(100% - 32px), $$maxWidth)',
  maxHeight: 'min(calc(100% - 32px - var(--sat) - var(--sab)), $$maxHeight)',
  display: 'flex',
  flexDirection: 'column',
  '@media (prefers-reduced-motion: no-preference)': {
    animation: `${contentShow} 0.5s cubic-bezier(0.16, 1, 0.3, 1)`,
    willChange: 'transform',
  },
  '&:focus': { outline: 'none' },
});

const Body = styled('div', {
  flexGrow: 1,
  overflowY: 'auto',
  px: '$space$4',
  '@medium': {
    px: '$space$6',
  },
});

export const Modal = ({
  heading,
  children,
  trigger,
  onDismiss,
}: {
  heading?: string;
  children?: React.ReactNode;
  trigger?: React.ReactNode;
  onDismiss?: () => void;
}) => {
  return (
    <Root onOpenChange={onDismiss}>
      <DialogPrimitive.Trigger asChild>{trigger}</DialogPrimitive.Trigger>
      <Portal>
        <Overlay css={{ zIndex: 1001 }}>
          <Content>
            <Heading>
              <MarkSimple source={heading} />
              <CloseButton />
              <VSpacer size={4} />
              <Divider />
            </Heading>
            <Body>{children}</Body>
          </Content>
        </Overlay>
      </Portal>
    </Root>
  );
};
