import deepmerge from 'deepmerge';
import { config as baseConfig } from './base';
import { logLevels } from '@common/log/logger-types';
import { CatalogsMap } from '@utils/util-types';

//
// beta.jiveworld.app
//
// npm start deploy.beta
//

export const config = deepmerge(baseConfig, {
  apiEnv: 'beta',
  caliServerEnv: 'beta',

  authShortCodeEnabled: false,

  showImportAction: false,
  updateCheckIntervalMs: 2 * 60 * 1000, // check for new deployments

  piMenuEnabled: false,
  inviteGateEnabled: true,

  showUnhandledErrors: false, // turn off until more stable
  showErrorDetails: false,
  showWarningAlerts: false,

  priceIncreaseDate: null,

  // disabled via spa.vite.config.ts (matches live)
  // isolate beta users from the localized UI support until it's more flushed out and selector UI exposed
  // i18n: {
  // },

  firebase: {
    // disable a/b testing for the beta testers until we improve the data stompage issue
    forceDisconnectedSampleRate: 0,
  },

  analytics: {
    mixpanel: {
      // mixpanel project: external (red/beta)
      projectToken: 'f3c27a009666f9efb7a724d2f591e1f2',
    },
  },

  bugsnag: {
    apiKey: 'e37d8f93752f2537c032d6f4b3247569',
    enabled: true,
  },

  sentry: {
    enabled: true,
    /// spa-beta dsn
    dsn: 'https://e575790a92be41e693411dc72f3b8c8e@o260366.ingest.sentry.io/4505002297262080',
    tracesSampleRate: 1.0, // performance metrics
    enableReplay: true,
    replaysSessionSampleRate: 0.5, // review usage and potentially reduce
    replaysOnErrorSampleRate: 1.0,
  },

  // raygun: {
  //   enabled: true,
  //   apiKey: 'Y5VRNm3rgVA5l9t43Okg', // spa-beta
  // },

  logging: {
    minimumLogLevel: logLevels.debug,
  },

  stripe: {
    // expecting stripe errors in the cloned production db
    ignoreCancelAutoRenewErrors: true,
  },

  crossSiteCookiesSupported: true,

  website: {
    urls: {
      marketingSite: 'https://www.jiveworld.com',
      jwPressServer: 'https://resources.jiveworld.com',
    },
    downloadUrls: {
      playStore: 'https://go.jiveworld.com/android-red',
    },
    // not currently relevant - no cookie sharing
    cookieDomain: 'beta.jiveworld.app',
  },

  // staged catalogs
  catalogs: {
    es: {
      defaultL1: 'en',
      en: 'lupa',
    },
    en: {
      defaultL1: 'pt',
      pt: 'english-pt',
      es: 'english-es',
    },
  } as CatalogsMap,

  // soundbites: {
  //   epochDate: '2023-06-05',
  // },

  lambdaFunctions: {
    enabled: true,
  },

  player: {
    debug: false,
  },
});
