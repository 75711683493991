import * as React from 'react';

export function MinusCircleSmallIcon({
  width = 20,
  height = 20,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6 9H14V11H6V9Z" fill={color} />
      <circle cx="10" cy="10" r="7.5" stroke={color} />
    </svg>
  );
}
