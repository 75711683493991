import * as React from 'react';
import { styled } from '@naan/stitches.config';
import classnames from 'classnames';

const Wrapper = styled('div', {
  $$backgroundColor: '$colors$gray-400',
  $$borderColor: '$colors$gray-400',
  $$activeBackgroundColor: '$colors$green-500',
  $$activeBorderColor: '$colors$green-600',
  $$foregroundColor: '$colors$white',

  height: '30px',
  width: '52px',
  left: '0px',
  top: '0px',
  borderRadius: '$radii$large',
  position: 'relative',
  cursor: 'pointer',
  backgroundColor: '$$backgroundColor',
  border: '1px solid $$borderColor',

  transitionProperty: 'all',
  transitionDuration: '120ms',
  transitionDelay: '50ms',

  '&.presentation-yellow': {
    $$activeBackgroundColor: '$colors$yellow-300',
    $$activeBorderColor: '$colors$yellow-500',
  },

  '&.disabled': {
    $$backgroundColor: '$colors$gray-100',
    $$borderColor: '$colors$gray-100',
    $$activeBackgroundColor: '$colors$gray-100',
    $$activeBorderColor: '$colors$gray-100',
    cursor: 'default',
  },

  '& .knob': {
    position: 'absolute',
    height: '28px',
    width: '28px',
    left: '0',
    top: '0',
    borderRadius: '14px',
    backgroundColor: '$$foregroundColor',
    transitionProperty: 'transform',
    transitionDuration: '180ms',
    boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.5)',
  },

  '&.active': {
    $$backgroundColor: '$$activeBackgroundColor',
    $$borderColor: '$$activeBorderColor',
    '& .knob': {
      transform: 'translateX(22px)',
    },
  },
});

export const SwitchButton = ({
  active = false,
  disabled = false,
  presentation = 'default',
  onChange = () => {},
}) => {
  const handleClick = React.useCallback(() => {
    if (disabled) {
      return null;
    }
    onChange();
  }, [onChange, disabled]);

  return (
    <Wrapper
      className={classnames([
        `presentation-${presentation}`,
        { active, disabled },
      ])}
      onClick={handleClick}
    >
      <div className="knob"></div>
    </Wrapper>
  );
};
