import * as React from 'react';
import { textStyles } from '../../tokens/text-styles';
import { styled, theme } from 'naan/stitches.config';

export const TextStyled = styled('div', {
  $$color: '$colors$textPrimary',
  color: '$$color',
  textStyle: 'body',
});

type TextStyledProps = React.ComponentProps<typeof TextStyled>;

type TextProps = TextStyledProps & {
  children: React.ReactNode;
  textStyle?: keyof typeof textStyles;
  color?: keyof typeof theme.colors;
  as?: React.ElementType;
};

export const Text = ({
  children,
  textStyle,
  color: colorName,
  css,
  ...wrapperProps
}: TextProps) => {
  // const color = colorName ? colors[colorName] : undefined;

  const styles: any = { ...css, textStyle };

  if (colorName) {
    styles['$$color'] = `$colors$${colorName}`;
  }

  return (
    <TextStyled {...wrapperProps} css={styles}>
      {children}
    </TextStyled>
  );
};
