import * as React from 'react';
import { observer } from 'mobx-react';
import { Assignment } from '@core/models/user-manager/assignment';
import { notEmpty } from '@utils/conditionals';
import { Button } from '@naan/primitives/button';
import { AppFactory } from '@app/app-factory';
import { AssignmentDetailsDialog } from './assignment-details-dialog';
import { isNil } from 'lodash';

import __ from '@core/lib/localization';

export const StoryCardAssignment = observer(
  ({ assignment }: { assignment: Assignment; sticky?: boolean }) => {
    // switching catalogs can result in orphaned data
    if (isNil(assignment)) return null;

    const assignmentDateText = notEmpty(assignment.displayDueDateShort)
      ? __('Due %{date}', 'dueOnDate', {
          date: assignment.displayDueDateShort,
        })
      : null;

    const assignmentInfo = notEmpty(assignmentDateText)
      ? assignmentDateText + ' • ' + assignment.classroom?.label
      : assignment.classroom?.label;

    const showDetails = React.useCallback(() => {
      AppFactory.dialogPresenter.present(onDismiss => (
        <AssignmentDetailsDialog
          assignment={assignment}
          onDismiss={onDismiss}
        />
      ));
    }, [assignment]);

    return (
      <Button
        label={assignmentInfo}
        size="small"
        presentation={assignment.isPastDueDate ? 'grayLight' : 'yellow'}
        onClick={showDetails}
      />
    );
  }
);
