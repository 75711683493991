import __ from '@core/lib/localization';

export const instructions = ({
  productName,
  l2Language,
  invitationUrl,
  className,
  code,
}: {
  productName: string;
  l2Language: string;
  invitationUrl: string;
  className: string;
  code: string;
}) =>
  __(
    `
# {{productName}}

{{productName}} is designed to improve your command of real-world %{l2Language},
through award-winning podcasts that have entertained millions of native listeners.

It's challenging material, but Jiveworld helps you out with vocabulary, hints,
language notes, and even translations so you can get by. Use as little of the help as possible,
and use your ears as much as possible, so you're better prepared when you
meet native speakers outside the classroom.

GETTING STARTED

Tap the following link, and create your Jiveworld account with your student email address:

{{invitationUrl}}

Confirm on your account page that you're enrolled in {{className}}.

* Check that you're signed in with the correct email address
* If you don't see yourself enrolled, tap Redeem a code and enter {{code}}
`,
    'classroomStudentInstructions',
    {
      productName,
      l2Language,
      invitationUrl,
      className,
      code,
    }
  );
