export abstract class AnalyticsAdapter {
  isEnabled: boolean = false;
  abstract serviceName: string;

  abstract aliasNewAccount(userId: string): void; // mixpanel.alias
  abstract identify(userId: string): void; // mixpanel.identify
  abstract reset(): void; // mixpanel.reset

  // only relevant to mixpanel-node
  // setInstallationId(installationId: string): void {}
  offline(): void {}
  online(): void {}

  // client-side updates not currently needed
  // abstract setProfileData(data: object): void; // mixpanel.people.set

  abstract track(eventName: string, data?: any): void;
  abstract page(pageName: string, pageData?: any): void;
}
