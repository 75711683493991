import useSWRImmutable from 'swr/immutable';

import { Story } from 'core/models/story-manager';
import { AppFactory } from 'app/app-factory';
import { VocabListModel } from '@core/models/ui/vocab-list-model';
import { StoryProgress } from '@core/models/user-manager';

const fetcher = ({
  url,
  vocabSlugs,
  learnedVocabSlugs,
}: {
  url: string;
  vocabSlugs: string[];
  learnedVocabSlugs: string[];
}): Promise<VocabListModel> =>
  AppFactory.root.storyManager
    .loadVolumeDataUrl(url)
    .then(
      volumeData =>
        new VocabListModel({ volumeData, vocabSlugs, learnedVocabSlugs })
    );

export function useVocabListData(story: Story, progress: StoryProgress) {
  const { data, error } = useSWRImmutable(
    {
      url: story.volumeDataUrl,
      vocabSlugs: progress.vocabList,
      learnedVocabSlugs: progress.learnedVocabList,
    },
    fetcher
  );

  return { model: data, error, loading: data === undefined && !error };
}
