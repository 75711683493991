import { styled } from '@naan/stitches.config';
import { AuthLayoutWrapper } from 'components/layouts';

export const OnboardingAuthLayout = styled(AuthLayoutWrapper, {
  '& .back-link': {
    '& button': {
      // ugly, but nexcessary to affect the color of the icon inside BackButton
      color: '$black-alpha-40',
    },
  },
  '& .form-contents': {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 24,

    '& .hint': {
      textStyle: 'small-text',
      color: '$textSecondary',
    },
  },
});
