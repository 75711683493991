import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { styled } from 'naan/stitches.config';

const Wrapper = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  marginBottom: '4px',

  '& > .left': {
    flex: 4,

    '& .cell-header': {
      color: '$colors$textSecondary',
    },
  },

  '& > .right': {
    flex: 6,
  },
});

export const MobileHeaderCell = ({
  header,
  children,
}: {
  header: string;
  children: any;
}) => {
  const isMobile = useMediaQuery({
    query: '(max-device-width: 550px)',
  });

  // pass thru for non-mobile devices
  if (!isMobile) {
    return children;
  }

  return (
    <Wrapper>
      <div className="left">
        <span className="cell-header">{header}</span>
      </div>
      <div className="right">{children}</div>
    </Wrapper>
  );
};
