import * as React from 'react';
import { getVideoAssets } from 'video-assets/recap';
import { VideoInterstitial } from './component/video-interstitial';
import __ from '@core/lib/localization';

export const RecapInterstitial = ({
  onForcePass,
  onDismiss,
}: {
  onForcePass: () => void;
  onDismiss: () => void;
}) => {
  return (
    <VideoInterstitial
      title={__('Recap', 'recap')}
      onForcePass={onForcePass}
      onDismiss={onDismiss}
      getVideoAssets={getVideoAssets}
      videoAchievementKey="video:recap"
    />
  );
};
