import React from 'react';
import { styled } from 'naan/stitches.config';
import classNames from 'classnames';

const maxBarHeight = 120;

const ChartContainer = styled('div', {
  $$barLabelColor: '$colors$textPrimary',
  display: 'grid',
  // flexDirection: 'row',
  gridTemplateColumns: 'repeat(14, 1fr)',
  alignItems: 'flex-end',
  borderTop: '1px solid $black-alpha-10',
  gap: '$space$1',
  '@small': {
    gap: '$space$2',
  },
  variants: {
    zeroState: {
      true: {
        $$barLabelColor: '$colors$gray-300',
      },
    },
  },
});

const Bar = styled('div', {
  backgroundColor: '$yellow-200',
  borderRadius: '$space$1',
  marginBottom: '$space$1',
  variants: {
    thisWeek: {
      true: {
        backgroundColor: '$yellow-500',
      },
    },
  },
});

const BarContainer = styled('div', {
  flex: 1,
  position: 'relative',
  // background: '$red-100',
  textAlign: 'center',

  '& > .value-label': {
    position: 'absolute',
    transform: 'translate(-50%, -100%)',
    left: '50%',
    paddingBottom: '$space$1',
    textAlign: 'center',
    color: '$black-alpha-50',
    textStyle: 'small-text-bold',
    opacity: 0,
    cursor: 'default',
    transition: 'opacity 0.15s ease-in-out',
  },

  '&:hover > .value-label': {
    opacity: 1,
  },

  '&.show-value-label > .value-label': {
    opacity: 1,
  },

  '& > .day-label': {
    textAlign: 'center',
    textStyle: 'tiny-text',
    color: '$$barLabelColor',
  },
});

const BarSegment = ({
  dayAbbreviation,
  height,
  showValueLabel,
  value,
  thisWeek,
}: {
  dayAbbreviation: string;
  height: number;
  showValueLabel: boolean;
  value: number;
  thisWeek: boolean;
}) => {
  return (
    <BarContainer
      className={classNames({ 'show-value-label': showValueLabel })}
    >
      {value > 0 ? <span className="value-label">{value}</span> : null}
      <Bar style={{ height }} thisWeek={thisWeek} />
      <span className="day-label">{dayAbbreviation}</span>
    </BarContainer>
  );
};

export const BarChart = ({
  viewData,
}: {
  viewData: {
    highestDayPoints: number;
    graphData: { letter: string; points: number }[];
  };
}) => {
  const { highestDayPoints, graphData } = viewData;
  const arrPoints = graphData.map(el => el.points);
  const highScoreIndex = arrPoints.lastIndexOf(highestDayPoints);
  const lastIndex = arrPoints.length - 1;
  const isZeroState = viewData.highestDayPoints === 0;

  return (
    <ChartContainer zeroState={isZeroState}>
      {viewData.graphData.map(({ letter, points }, i) => {
        const height =
          highestDayPoints === 0
            ? 0
            : (points / highestDayPoints) * maxBarHeight;

        const showValueLabel =
          (i === highScoreIndex || i === lastIndex) && !isZeroState;

        return (
          <BarSegment
            key={i}
            dayAbbreviation={letter}
            height={height}
            showValueLabel={showValueLabel}
            value={points}
            thisWeek={i > 6}
          />
        );
      })}
    </ChartContainer>
  );
};
