import * as React from 'react';
import { AppFactory } from '@app/app-factory';
import { useCurrentStory } from 'components/story-detail/current-story-context';
import { ProgressPanelSection } from './panel-section';
import { SectionItem } from './section-item';
import { CheckmarkCircleIcon } from '@naan/icons/checkmark-circle-icon';
import { HeadphonesIcon } from '@naan/icons/headphones-icon';
import { SectionHeaderButton } from './section-header-button';
import { millisToPrettyDuration } from '@core/lib/pretty-duration';
import { ProgressPie } from 'components/ui/progress-pie';
import { ResetStoryDialog } from 'components/story-detail/reset-story-dialog';
import { scrollToCurrentChapter } from 'routes/learn/scrolling-fx';
import { ResetOptions } from '@core/models/user-manager/story-progress';

import __ from '@core/lib/localization';

const CompletionPanel = () => {
  const story = useCurrentStory();

  return (
    <SectionItem
      presentation="teal"
      icon={
        story.completed ? (
          <CheckmarkCircleIcon />
        ) : (
          <ProgressPie current={story.studyProgressRatio} />
        )
      }
      // icon={<CheckmarkCircleIcon />}
      title={story.studyProgressPercentage + '%'}
      subtitle={__('Complete', 'complete')}
    />
  );
};

const ProgressPanel = () => {
  const story = useCurrentStory();
  const { userData } = AppFactory.root.userManager;

  return (
    <SectionItem
      presentation="teal"
      icon={<HeadphonesIcon />}
      title={millisToPrettyDuration(userData.totalMillisForStory(story.slug))}
      subtitle={__('Listened', 'listened')}
    />
  );
};

const ActionButton = () => {
  const story = useCurrentStory();

  return (
    <SectionHeaderButton
      presentation="teal"
      onClick={() => {
        AppFactory.dialogPresenter.present(onDismiss => (
          <ResetStoryDialog
            onReset={(options: ResetOptions) => {
              story.progress.resetStory(options);
              setTimeout(() => {
                scrollToCurrentChapter();
              }, 1000);
              onDismiss();
            }}
            onDismiss={onDismiss}
          />
        ));
      }}
    >
      {__('Reset', 'reset')}
    </SectionHeaderButton>
  );
};

export const StudyAndListenSection = () => {
  const story = useCurrentStory();

  return (
    <ProgressPanelSection
      completed={story.completed}
      title={__('Study & listen', 'studyAmpListen')}
      action={<ActionButton />}
    >
      <CompletionPanel />
      <ProgressPanel />
    </ProgressPanelSection>
  );
};
