import * as React from 'react';
import { Speaker } from 'core/models/catalog/speaker';
import { styled } from 'naan/stitches.config';
import { paletteKey } from '../../../study/views/study-palette';
import { Tooltip } from '@naan/primitives/tooltip';
import { LocationIcon } from '@naan/icons/location-icon';
import { useSwitch2 } from '@naan/hooks/use-switch-2';
import { useOnClickOutside } from '@common/hooks/use-onclick-outside';
import { isEmptyOrNA } from '@utils/string-utils';

const Label = styled('div', {
  $$backgroundColor: paletteKey('$$speakerLabel__backgroundColor_unvisited'),
  $$textColor: paletteKey('$$speakerLabel__color_unvisited'),
  $$borderColor: paletteKey('$$speakerLabel__borderColor_unvisited'),

  backgroundColor: '$$backgroundColor',
  color: '$$textColor',
  border: '1px solid $$borderColor',
  cursor: 'default',

  textStyle: 'body-bold',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '3px 15px',
  width: 'fit-content',
  borderRadius: '$radii$medium',
  flex: 'none',
  order: '0',
  flexGrow: '0',

  marginTop: '$space$6',
  marginBottom: '$space$2',
  transition: 'transform .3s',
  position: 'relative',

  /// @joseph, @frank, there's no support for `visited` labels in the code AFAIK
  '&.visited': {
    $$backgroundColor: paletteKey('$$speakerLabel__backgroundColor_visited'),
    $$textColor: paletteKey('$$speakerLabel__color_visited'),
    $$borderColor: paletteKey('$$speakerLabel__borderColor_visited'),
  },

  '&.current': {
    $$backgroundColor: paletteKey('$$speakerLabel__backgroundColor_current'),
    $$textColor: paletteKey('$$speakerLabel__color_current'),
    $$borderColor: paletteKey('$$speakerLabel__borderColor_current'),
    // boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.2)',
    shadowStyle: 'small',
    zIndex: 1,
  },

  '.showing-translation &': {
    '@playerMedium': {
      transform: 'translateX(-196px)',
    },
  },
});

const TooltipContent = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  width: 'max-content',
  maxWidth: 'min(calc(100vw - 48px), 400px)',
  '& > .l1-label': {
    textStyle: 'body-bold',
    color: '$textPrimary',
    // background: 'red',
  },
  '& > .bio': {
    textStyle: 'body',
    display: 'block',
    marginBottom: '$space$2',
    color: '$textPrimary',
  },
  '& > .accent': {
    display: 'flex',
    flexDirection: 'row',
    textStyle: 'body',
    color: '$textSecondary',
    gap: 4,
    marginLeft: -3,
  },
});

type Props = {
  speaker: Speaker; // required now
} & React.ComponentProps<typeof Label>;

const LabelWithTooltip = ({ speaker, ...props }: Props) => {
  const tipSwitch = useSwitch2(false);
  const ref = React.useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, () => {
    tipSwitch.off();
  });

  const l2Label = speaker.labelL12?.l2;
  const l1Label = speaker.labelL12?.l1;

  return (
    <Label {...props} onClick={tipSwitch.toggle} ref={ref}>
      <Tooltip
        tipContent={
          <TooltipContent>
            {!isEmptyOrNA(l1Label) ? (
              <span className="l1-label">{l1Label}</span>
            ) : null}
            {!isEmptyOrNA(speaker.bio) ? (
              <span className="bio">{speaker.bio}</span>
            ) : null}
            {speaker.accent ? (
              <span className="accent">
                <LocationIcon />
                {speaker.accent}
              </span>
            ) : null}
          </TooltipContent>
        }
        showTip={tipSwitch.value}
        placement={'top-start'}
        presentation={'white'}
        offset={[-16, 12]}
      >
        <span>{l2Label}</span>
      </Tooltip>
    </Label>
  );
};

const SimpleLabel = ({ speaker, ...props }: Props) => {
  return (
    <Label {...props}>
      <span>{speaker.label}</span>
    </Label>
  );
};

export const SpeakerLabelPresentation = ({
  speaker, // required now
  ...props
}: Props) => {
  const tipSwitch = useSwitch2(false);
  const ref = React.useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, () => {
    tipSwitch.off();
  });

  if (!speaker) {
    return null;
  }

  // const showTooltip = speaker.label || speaker.bio || speaker.accent;
  if (speaker.showTip) {
    return <LabelWithTooltip speaker={speaker} {...props} />;
  }

  return <SimpleLabel speaker={speaker} {...props} />;
};
