import * as React from 'react';
import { observer } from 'mobx-react';
import { AppFactory } from 'app/app-factory';
import { learnProgressPath } from 'components/nav/path-helpers';
import { TotalPoints } from 'components/ui/total-points';
import { styled } from '@naan/stitches.config';
import { Link } from 'react-router-dom';
import { Streak } from 'components/ui/streak';

const PointsLink = styled(Link, {
  textDecoration: 'none',
});

const Wrapper = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: 16,
});

export const PointsNav = observer(() => {
  const { userManager } = AppFactory.root;
  const { userData } = userManager;

  const { totalPoints, currentStreak } = userData;

  return (
    <Wrapper>
      {currentStreak === 0 ? null : (
        <PointsLink to={learnProgressPath()}>
          <Streak points={currentStreak} />
        </PointsLink>
      )}
      {totalPoints === 0 ? null : (
        <PointsLink to={learnProgressPath()}>
          <TotalPoints points={totalPoints} isLargeOnDesktop={true} />
        </PointsLink>
      )}
    </Wrapper>
  );
});
