import * as React from 'react';
import { PlainMarkdown } from '@naan/primitives/text';
import { styled } from '@naan/stitches.config';

const VocablistWrapper = styled('div', {
  '& h1': {
    borderTop: '1px solid $colors$gray-100',
    paddingTop: '8px',
    textStyle: 'serif',
    margin: 0,
  },
  '& p': {
    textStyle: 'body',
    color: '$colors$textSecondary',
    marginBottom: '12px',
  },
});

export const VocablistMarkdown = ({ source }: { source: string }) => (
  <VocablistWrapper>
    <PlainMarkdown source={source} />
  </VocablistWrapper>
);
