import * as React from 'react';

import { PlainMarkdownWithLinks } from 'naan/primitives/text';
import { styled } from 'naan/stitches.config';

const Wrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textStyle: 'body',
  color: '$textSecondary',
  '& a': {
    color: '$teal-500',
    textDecoration: 'underline',
  },
});

export const AuthInlineLink = ({ text }: { text: string }) => {
  return (
    <Wrapper>
      <PlainMarkdownWithLinks source={text} />
    </Wrapper>
  );
};
