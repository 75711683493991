import * as React from 'react';
import classNames from 'classnames';
import { ChapterCatalogData } from '@core/models/catalog';
import { styled } from '@naan/stitches.config';
import { useChapterRowUiContext } from '../chapter-row-ui-model';
import { observer } from 'mobx-react';
import { scrollToCurrentChapter } from 'routes/learn/scrolling-fx';
import { ChapterActionButton } from '../chapter-action-button';
import { ChapterOverflowMenu } from '../chapter-overflow-menu';
import { CardWrapper } from './chapter-card-styles';
import { ChapterStudyCardContent } from './card-content';
import { ChapterCardButtons } from './ card-buttons';
import { useAutoanimate } from '@common/hooks/use-autoanimate';

import __ from '@core/lib/localization';

const OverflowMenuWrapper = styled('div', {
  position: 'absolute',
  top: 4,
  right: 8,
});

type Mode = 'study' | 'listen' | 'completed' | 'compact' | 'whoa';

export const ChapterStudyCard = observer(
  ({ chapter }: { chapter: ChapterCatalogData }) => {
    const model = useChapterRowUiContext();
    const { currentStudyMode: mode } = model;
    const whoa = mode === 'completed' && !chapter.isFirstListenComplete;

    const parent = useAutoanimate();

    const open = model.currentStage === 'study';

    (window as any).chapter = chapter;

    let wrapperMode: Mode = !open ? 'compact' : whoa ? 'whoa' : mode;

    return (
      <CardWrapper
        ref={parent}
        mode={wrapperMode}
        onClick={() => {
          model.setCurrentStage('study');
          scrollToCurrentChapter(true);
        }}
      >
        <div className={classNames('buttons-title-container', { open })}>
          {open ? null : (
            <span className="card-title">
              {__('Study & listen', 'StudyAmpListen')}
            </span>
          )}
          <ChapterCardButtons compact={!open} chapter={chapter} />
        </div>
        {open ? (
          <>
            <OverflowMenuWrapper>
              <ChapterOverflowMenu chapter={chapter} />
            </OverflowMenuWrapper>
            <ChapterStudyCardContent chapter={chapter} />
            <ChapterActionButton chapter={chapter} viaDetail={false} />
          </>
        ) : null}
      </CardWrapper>
    );
  }
);
