import React from 'react';
// import { useMediaQuery } from 'react-responsive';
import { styled } from '@naan/stitches.config';
import { StudentProgress } from '@core/models/user-manager';
import classNames from 'classnames';
import { SoundbiteIconSmall } from '@naan/icons/soundbite-icon';

// import __ from 'core/lib/localization';

const VocabBadge = styled('div', {
  '.pill': {
    userSelect: 'none',
    background: '$colors$transparent',
    // color: '$colors$white',
    paddingInline: 7,
    paddingBlock: 5,
    borderRadius: '$radii$medium',
    textAlign: 'center',
    border: '1px solid $colors$black-alpha-10',
    display: 'inline-flex',
    gap: 16,
    textStyle: 'body-bold',

    '& > div': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      color: '$colors$orange-500',
      '& .icon': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },

      '&.is-zero': {
        color: '$colors$gray-300',
      },
    },
    '@extraSmallOnly': {
      marginTop: 8,
    },
  },
});

export const SoundbiteCell = ({
  studentProgress,
}: {
  studentProgress: StudentProgress;
}) => {
  // const isMobile = useMediaQuery({
  //   query: '(max-device-width: 550px)',
  // });

  const { soundbiteProgress, completedSoundbitesCount } = studentProgress;

  return (
    <VocabBadge>
      <div className="pill">
        <div
          className={classNames('count', {
            'is-zero': completedSoundbitesCount === 0,
          })}
        >
          <span className="icon">
            <SoundbiteIconSmall />
          </span>
          <span className="label">{soundbiteProgress}</span>
        </div>
      </div>
    </VocabBadge>
  );
};
