import * as React from 'react';

export function AvatarLargeIcon({
  width = 28,
  height = 28,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 28 28"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.50811 20.9925C7.56545 17.6797 10.4102 16.0002 14 16.0002C17.5899 16.0002 20.4346 17.6797 22.4919 20.9924C24.0589 19.0916 25 16.6557 25 14C25 7.92487 20.0751 3 14 3C7.92487 3 3 7.92487 3 14C3 16.6557 3.94114 19.0917 5.50811 20.9925ZM13.9368 25.9998C7.33845 25.9658 2 20.6063 2 14C2 7.37258 7.37258 2 14 2C20.6274 2 26 7.37258 26 14C26 20.6119 20.6525 25.9749 14.0465 25.9999C14.031 26 14.0155 26 14 26C13.9789 26 13.9578 26 13.9368 25.9998ZM14 15.9993C16.7615 15.9993 19 13.7608 19 10.9994C19 8.23805 16.7615 5.99951 14 5.99951C11.2386 5.99951 9.00005 8.23805 9.00005 10.9994C9.00005 13.7608 11.2386 15.9993 14 15.9993Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}

export function AvatarIcon({
  width = 24,
  height = 24,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.01725 17.6786C6.72398 15.0164 9.06268 13.667 12 13.667C14.9374 13.667 17.276 15.0163 18.9828 17.6786C20.2439 16.1296 21 14.1531 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 14.1531 3.75609 16.1297 5.01725 17.6786ZM11.6751 21.9948C6.3026 21.8233 2 17.4141 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.4453 17.6476 21.8741 12.2319 21.9974C12.155 21.9993 12.0777 22.0003 12 22.0003C11.8908 22.0003 11.7824 21.9985 11.6751 21.9948ZM12 13.6663C14.3012 13.6663 16.1667 11.8009 16.1667 9.49967C16.1667 7.19849 14.3012 5.33301 12 5.33301C9.69885 5.33301 7.83337 7.19849 7.83337 9.49967C7.83337 11.8009 9.69885 13.6663 12 13.6663Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}
