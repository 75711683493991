import { AppFactory } from '@app/app-factory';
import { pickLocalizedValue } from '@core/lib/localization';

const poster = 'vocab-review-poster.svg';

const shortVideoId = '0add5abdd9a7a2f57dc80939f3b06f5f';

const en = {
  videoId: 'cd7a9224f106ea8a3093f4f137192fbf',
  poster,
};

const pt = {
  videoId: 'dee7d21a25ff88fc098d022a4532868f',
  poster,
};

const es = {
  videoId: '4cdf00e53ab54a0f3117cd81823223e6',
  poster,
};

export function getVideoAssets() {
  const result = pickLocalizedValue({ en, pt, es });
  const { shortVideosEnabled } = AppFactory.root.localState;
  if (shortVideosEnabled) {
    result.videoId = shortVideoId;
  }
  return result;
}
