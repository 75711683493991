import { nanoid } from 'nanoid';
// import Cookies from 'js-cookie';
import { createLogger } from '@common/log';
import { bugsnagNotify } from '@app/notification-service';

const log = createLogger('installation-id');

const INSTALLATION_ID_KEY = 'jw-installation-id';

// assigned during first app init. and saved to local storage (so can be sync)
// used for anonymous metrics and drives ability to show the welcome screen for first embedded visit
let _installationId: string = null;

export const getInstallationId = (): string => {
  if (_installationId) {
    return _installationId;
  }

  loadInstallationId();

  if (!_installationId) {
    _installationId = nanoid();
    log.debug(`allocating installationId: ${getInstallationId()}`);
    storeInstallationId();
    // don't think a separate event is warranted
    // track('system__new_installation_id', {
    //   installationId: this.installationId,
    // });
  }

  return _installationId;
};

const loadInstallationId = (): void => {
  try {
    // installationId = Cookies.get(INSTALLATION_ID_COOKIE_KEY);
    _installationId = localStorage.getItem(INSTALLATION_ID_KEY);
  } catch (error) {
    bugsnagNotify(`failed to fetch installationId from localStorage: ${error}`);
  }
};

const storeInstallationId = (): void => {
  try {
    localStorage.setItem(INSTALLATION_ID_KEY, _installationId);
  } catch (error) {
    bugsnagNotify(`failed to store installationId to localStorage: ${error}`);
  }
};

// allow easier testing of first visit logic
export const resetFirstVisitState = () => {
  _installationId = null;
  // Cookies.remove(INSTALLATION_ID_COOKIE_KEY);
  try {
    localStorage.removeItem(INSTALLATION_ID_KEY);
  } catch (error) {
    bugsnagNotify(
      `failed to remove installationId from localStorage: ${error}`
    );
  }
};

export const hasBeenVisited = (): boolean => {
  loadInstallationId();
  return !!_installationId;
};
