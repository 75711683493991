import { TimelineNavigator } from '@tikka/navigation/timeline-navigator';
import { AudioTransport, TransportState } from '@tikka/player/audio-transport';
import { StructuredPlayer } from '@tikka/player/structured-player';

export const NAVIGATION_SOFT_PAUSE_MS = 300;
const REWIND_TO_PREVIOUS_WHILE_PLAYING_TOLERANCE_MS = 500;
// nav stop and line stops are typically 1ms different, so need to nudge past the notionally concurrent stops
const MIN_NAVIGATION_TOLERANCE_MS = 10;

export class ClientPlayer extends StructuredPlayer {
  nextClosest(navigator: TimelineNavigator, keepPauseAfter = false) {
    const nextPoint = navigator.nextClosest(
      this.getAudioPosition() + MIN_NAVIGATION_TOLERANCE_MS
    );
    if (nextPoint) {
      if (this.transportState.isPlaying) {
        this.pauseThenPlayAt(
          NAVIGATION_SOFT_PAUSE_MS,
          nextPoint.position,
          keepPauseAfter
        );
      } else {
        this.seek(nextPoint.position, keepPauseAfter);
      }
    }
  }

  prevClosest(navigator: TimelineNavigator, keepPauseAfter = false) {
    const tolerance = this.transportState.isPlaying
      ? REWIND_TO_PREVIOUS_WHILE_PLAYING_TOLERANCE_MS
      : MIN_NAVIGATION_TOLERANCE_MS;
    // make sure we always navigate back prior to the last position
    // avoids noop navigation frequently encountered when end of audio reached
    const position = Math.min(
      this.getAudioPosition(),
      navigator.positions.finalStartPoint
    );
    const nextPoint = navigator.prevClosest(position - tolerance);
    // TODO factor out this code also in nextClosest??
    if (nextPoint) {
      if (this.transportState.isPlaying) {
        this.pauseThenPlayAt(
          NAVIGATION_SOFT_PAUSE_MS,
          nextPoint.position,
          keepPauseAfter
        );
      } else {
        this.seek(nextPoint.position, keepPauseAfter);
      }
    }
  }
}

export function CreateClientPlayer(
  audioTransport: AudioTransport,
  transportState: TransportState
) {
  return new ClientPlayer(audioTransport, transportState);
}
