import React from 'react';
import { globalCss } from '@naan/stitches.config';
import { Helmet } from 'react-helmet';
import { styled } from '@naan/stitches.config';

const globalStyles = globalCss({
  ':root': {
    '--gray-6': '#444',
    '--gray-5': '#626262',
    '--gray400': '#999',
    '--gray300': '#BBBBBB',
    '--gray200': '#D0D0D0',
    '--gray100': '#e5e5e5',
    '--text-secondary': '#909090',
    '--dark-blue': '#4c7faa',
    '--green': '#4ed152',
    '--red': '#ff6767',
    '--pink': '#ffaeae',
    '--light-orange': '#ffa000',
    '--lighter-orange': 'rgba(255,160,0,.12)',
    '--very-light-yellow': '#fff2cd',
    '--green400': '#edfaf0',
    '--large-heading-font': 'bold 37px/48px arial',
    '--medium-heading-font': 'bold 24px/28px arial',
    '--small-heading-font': '700 16px/24px roboto',
    '--body-font': '400 17px/24px roboto',
    '--small-text-font': '400 15px/20px roboto',
    '--tiny-text-font': '400 12px/20px roboto',
    '--footer-text-font': '400 14px/20px roboto',
    '--compact-script-font': "17px/24px 'Source Serif Pro'",
  },

  '*': {
    padding: '0',
    margin: '0',
    border: 'none',
    background: 'transparent',
    color: 'var(--gray-6) */',
    boxSizing: 'border-box',
  },
  table: {
    borderCollapse: 'collapse',
    borderSpacing: '0',
  },
  td: {
    verticalAlign: 'top',
  },
  img: {
    display: 'block',
    maxWidth: '100%',
  },

  body: {
    webkitPrintColorAdjust: 'exact',
    printerColors: 'exact',
    colorAdjust: 'exact',
  },

  '#root': {
    backgroundColor: 'white',
  },
});

const GlobalGuideStyles: React.FC<React.PropsWithChildren<unknown>> = () => {
  globalStyles();
  return null;
};

const PageLayoutWrapper = styled('div', {
  width: '100%',
  maxWidth: '1200px',
  padding: '0 20px',
  margin: '0 auto',

  '@media print, screen and (min-width: 700px)': {
    padding: '0 32px',
  },
  '@media print': {
    width: '900px',
    padding: '0',
  },
});

export const PageLayout = (props: any) => {
  return (
    <>
      <GlobalGuideStyles />
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;0,900;1,400&family=Source+Serif+Pro:ital,wght@0,400;0,600;1,400;1,600&display=swap"
          rel="stylesheet"
        ></link>
      </Helmet>
      <PageLayoutWrapper {...props} />
    </>
  );
};
