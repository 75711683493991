import * as React from 'react';
import { observer } from 'mobx-react';
import { AppFactory } from '@app/app-factory';
import { VideoModal } from './video-modal';
import { VideoPlayerModel } from './video-player-model';
import { namedStyled } from '@naan/stitches.config';
import { PlayVideoIcon } from './play-video-icon';
import { CheckmarkCircleIcon } from '@naan/icons/checkmark-circle-icon';
import { track } from '@app/track';
import { VideoGuide } from '@core/models/catalog/video-guide';
// import { TVideoGuide } from 'components/support/video-guides';

const presentVideoModal = (video: VideoGuide) => {
  const { videoGuideUserData } = AppFactory.root.userManager.userData;
  const model = new VideoPlayerModel(videoGuideUserData, video);

  track('helplet__open_from_list', { videoSlug: video?.slug });
  AppFactory.dialogPresenter.present(onDismiss => {
    return <VideoModal model={model} onDismiss={onDismiss} />;
  });
};

const Wrapper = namedStyled('videoListCardWrapper', 'div', {
  '&  .title': {
    display: 'block',
    marginTop: 8,
    textStyle: 'body-bold',
    cursor: 'pointer',
  },
});

const PreviewWrapper = namedStyled('videoPreviewWrapper', 'div', {
  $$image: '',
  background: '$gray-100',
  width: '100%',
  paddingTop: '56.25%', // ratio trick
  borderRadius: '$radii$large',
  overflow: 'hidden',
  position: 'relative',
  backgroundImage: '$$image',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  cursor: 'pointer',
  '--play-video-button-bg': '$colors$teal-500',

  '&:hover': {
    '--play-video-button-bg': '$colors$teal-600',
  },

  '& > .play-btn': {
    all: 'unset',
    position: 'absolute',
    inset: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 3,
    svg: {
      width: '64px',
    },
  },

  '& > .completed-container': {
    position: 'absolute',
    top: 8,
    right: 8,
    zIndex: 4,
    // opacity: 0.4,
    color: '$colors$gray-600',
  },
});

export const VideoListCard = observer(({ video }: { video: VideoGuide }) => {
  if (!video) {
    return null;
  }

  return (
    <Wrapper>
      <PreviewWrapper
        className="video-list-card"
        css={{
          $$image: `url(${video.imageUrlLandscape})`,
        }}
      >
        <button onClick={() => presentVideoModal(video)} className="play-btn">
          <PlayVideoIcon />
        </button>
        {video.completed ? (
          <div className="completed-container">
            <CheckmarkCircleIcon />
          </div>
        ) : null}
      </PreviewWrapper>

      <span className="title">{video.title}</span>
    </Wrapper>
  );
});
