// const formatterWithCents = () =>
//   new Intl.NumberFormat('en-US', {
//     style: 'currency',
//     currency: 'USD',
//     minimumFractionDigits: 2,
//   });

import { Currency } from './cas-types';

// const formatterNoCents = () =>
//   new Intl.NumberFormat('en-US', {
//     style: 'currency',
//     currency: 'USD',
//     minimumFractionDigits: 0,
//   });

// export const formatMoney = (number: number) => {
//   const withCents = number % 1 !== 0;

//   if (withCents) {
//     return formatterWithCents().format(number);
//   }

//   return formatterNoCents().format(number);
// };

export const formatMoney = (
  number: number,
  currency: Currency /* = 'USD'*/,
  { qualified = false }: { qualified?: boolean } = {}
) => {
  // just in case we're passed a string
  if (!number.toFixed) {
    number = Number(number);
  }
  // currency = currency.toUpperCase();
  const withCents = number % 1 !== 0;
  const formattedNumber = withCents ? number.toFixed(2) : number.toFixed(0);

  if (currency === 'usd') {
    if (qualified) {
      // equivalent to prepending the diminutiveCurrencyPrefix
      // (but the specs keep on changing so i don't want to refactor right now)
      return `US$${formattedNumber}`;
    } else {
      return `$${formattedNumber}`;
    }
  } else if (currency === 'brl') {
    return `R$ ${formattedNumber}`;
  } else {
    throw new Error(`Unsupported currency: ${currency}`);
  }
};

export const diminutiveCurrencyPrefix = (currency: Currency): string => {
  return currency === 'usd' ? 'US' : undefined;
};
