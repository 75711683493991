import { AppFactory } from 'app/app-factory';

export const track = (eventName: string, eventProperties: any = {}) => {
  AppFactory.analyticsManager.trackEvent(eventName, eventProperties);
};

const trackNavBack = (
  eventProperties: { from: string; to?: string } = {
    to: '__history_back__', // @elliottjf do you like this for a special event property that means browser history back?,    ,
    from: undefined,
  }
) => {
  AppFactory.analyticsManager.trackEvent('history-back', eventProperties);
};

// auto-fills the `from` path
export function getPageBackTracker() {
  const from = window.location.pathname;

  const trackBack = (to?: string) => {
    trackNavBack({ to, from });
  };

  return trackBack;
}

export const trackPage = (pageName: string, pageData?: any) => {
  AppFactory.analyticsManager.trackPage(pageName, pageData);
};

export const setAnalyticsContext = (data: any) => {
  AppFactory.analyticsManager.setContextData(data);
};
