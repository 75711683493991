import * as React from 'react';

export function PointsIcon({
  width = 24,
  height = 24,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM7.5916 18.0676L12 15.75L16.4084 18.0676L15.5665 13.1588L19.1329 9.68237L14.2042 8.96619L12 4.5L9.79579 8.96619L4.86707 9.68237L8.43353 13.1588L7.5916 18.0676Z"
        fill={color}
      />
    </svg>
  );
}
