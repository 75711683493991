import * as React from 'react';
import { Text } from '../../../naan/primitives/text';

export const Code = ({ code }: { code: string }) => {
  return (
    <Text data-test-id="code-label" textStyle="code" color="gray-800">
      {code}
    </Text>
  );
};
