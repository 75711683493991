import { isEmpty, isEqual } from 'lodash';
import { createLogger } from '@app/logger';

const log = createLogger('deep-merge-diff');

type SimpleType =
  | 'object'
  | 'array'
  | 'undefined'
  | 'primitive'
  | 'null'
  | 'unsupported';
type Warnings = { [index: string]: string };
type Options = {
  atomic?: string[];
  ignoreDelete?: string[];
  allowDelete?: boolean;
};

function isObjectLiteral(obj: any) {
  if (typeof obj !== 'object' || !obj) {
    return false;
  }
  return Object.getPrototypeOf(obj) === Object.prototype;
}

function simpleType(a: any): SimpleType {
  if (a && Array.isArray(a)) {
    return 'array';
  }
  const t = typeof a;
  if (t === 'object') {
    if (a === null) {
      return 'null';
    }
    if (!isObjectLiteral(a)) {
      return 'unsupported';
    }
    return t; // 'object'
  }
  if (t === 'undefined') {
    return t;
  }
  return 'primitive';
}

function computeDiff(
  base: any,
  changed: any,
  diff: any,
  field: string,
  path: string,
  warnings: Warnings,
  atomicFieldSet: Set<string>,
  ignoreDeleteFieldSet: Set<string>,
  allowDelete: boolean
) {
  const baseType = simpleType(base);
  const changedType = simpleType(changed);

  // special case for changed is empty object
  if (changedType === 'object' && isEmpty(changed)) {
    return;
  }
  // beware: still possible to have nested empty properties with current diff logic

  // on root both sides must be object
  if (path === '' && (baseType !== 'object' || changedType !== 'object')) {
    throw Error('base and changed must be objects');
  }

  // unsupported cases
  if (baseType === 'unsupported') {
    warnings[path] = 'unsupported type in base, ignored';
    return;
  }

  if (changedType === 'unsupported') {
    warnings[path] = 'unsupported type in changed, ignored';
    return;
  }

  // previously undefined case
  if (baseType === 'undefined' && changedType !== 'undefined') {
    diff[field] = changed;
    return;
  }

  // change is delete case
  if (changedType === 'undefined' && baseType !== 'undefined') {
    if (
      allowDelete ||
      (ignoreDeleteFieldSet && ignoreDeleteFieldSet.has(field))
    ) {
      if (baseType !== 'null') {
        log.debug(`assigning 'null' for deleted prop: ${path}`);
        diff[field] = null;
      } else {
        // log.trace(`preserving 'null' for undefined: ${path}`);
      }
    } else {
      warnings[path] = 'change is delete, ignored';
    }
    return;
  }

  if (changedType === 'null' && baseType !== 'null') {
    log.debug(`changed to 'null': ${path}`);
    diff[field] = null;
    return;
  }

  if (changedType !== 'null' && baseType === 'null') {
    log.debug(`changed from 'null': ${path}`);
    diff[field] = changed;
    return;
  }

  // incompatibe type case
  if (baseType !== changedType) {
    warnings[path] = 'incompatible change of type, ignored';
    return;
  }

  const commonType = baseType;

  // for some reason encountered both undefined case
  if (commonType === 'undefined') {
    return;
  }

  // overwrite if changed primitive or array case
  if (commonType !== 'object') {
    // beware, the stringify compare was failing to correctly match so arrays of objects
    // if (JSON.stringify(base) !== JSON.stringify(changed)) {
    if (!isEqual(base, changed)) {
      diff[field] = changed;
    }
    return;
  }

  // common type must be object
  const keys = Object.keys(base);
  keys.push(...Object.keys(changed));
  const keySet = new Set(keys);
  const recursiveDiff = {} as any;
  for (const key of keySet.values()) {
    const recursionPath = path ? `${path}.${key}` : key;
    computeDiff(
      base[key],
      changed[key],
      recursiveDiff,
      key,
      recursionPath,
      warnings,
      atomicFieldSet,
      ignoreDeleteFieldSet,
      allowDelete
    );
  }
  if (!isEmpty(recursiveDiff)) {
    // field is null on root
    if (!field) {
      Object.assign(diff, recursiveDiff);
    } else {
      if (atomicFieldSet && atomicFieldSet.has(field)) {
        // log.trace(`atomic field: ${path}`);
        if (!isEmpty(changed)) {
          diff[field] = { ...changed };
        } else {
          log.error(`unexpectedly empty object node diff: ${path}`);
        }
      } else {
        diff[field] = recursiveDiff;
      }
    }
  }
}

export function deepMergeDiff(
  base: any,
  changed: any,
  options: Options = {}
): [any, Warnings] {
  let diff = {} as any;
  const { atomic, ignoreDelete, allowDelete } = options;
  let warnings: Warnings = {} as any;
  const atomicFieldSet = atomic ? new Set(atomic) : null;
  const ignoreDeleteFieldSet = ignoreDelete ? new Set(ignoreDelete) : null;
  const path = '';
  const field: string = null;
  computeDiff(
    base,
    changed,
    diff,
    field,
    path,
    warnings,
    atomicFieldSet,
    ignoreDeleteFieldSet,
    allowDelete
  );
  diff = isEmpty(diff) ? null : diff;
  // todo: warn if nested empty properties found
  diff = deepCloneNoEmpty(diff);
  warnings = isEmpty(warnings) ? null : warnings;
  return [diff, warnings];
}

// eslint-disable-next-line no-unused-vars
function testDeepMergeDiff() {
  const a = { x: 'blort', y: 'blah', q: 'delete' };
  const b = { x: 'blort', y: 'blah2', z: 'new' };
  const [diff, warnings] = deepMergeDiff(a, b);
  // eslint-disable-next-line no-console
  console.log(diff);
  // eslint-disable-next-line no-console
  console.log(warnings);
}

// beware, this name of this fuction is a bit of a lie, it's still possible to have some
// nested property empty objects in the result
export function deepNoEmptyObject(obj: any) {
  if (isObjectLiteral(obj)) {
    if (isEmpty(obj)) {
      return false;
    }
    for (const key of Object.keys(obj)) {
      const result = deepNoEmptyObject(obj[key]);
      if (!result) {
        return false;
      }
    }
  }
  return true;
}

// eslint-disable-next-line no-unused-vars
function testDeepNoEmptyObject() {
  const obj1 = {};
  const obj2 = {
    blort: 'blort',
    obj1,
  };
  const obj3 = {
    blort: 'blort',
  };
  // eslint-disable-next-line no-console
  console.log('TEST DEEP NO EMPTY');
  // eslint-disable-next-line no-console
  console.log(!deepNoEmptyObject(obj1) ? 'passed' : 'failed');
  // eslint-disable-next-line no-console
  console.log(!deepNoEmptyObject(obj2) ? 'passed' : 'failed');
  // eslint-disable-next-line no-console
  console.log(deepNoEmptyObject(obj3) ? 'passed' : 'failed');
}

export function deepCloneNoEmpty<T>(src: T): T {
  const type = simpleType(src);
  if (type === 'object') {
    const obj: any = src as any;
    const result: any = {};
    for (const key of Object.keys(src)) {
      const srcVal = obj[key];
      if (isObjectLiteral(srcVal) && isEmpty(srcVal)) {
        continue;
      }
      const clonedValue = deepCloneNoEmpty(srcVal);
      if (isObjectLiteral(clonedValue) && isEmpty(clonedValue)) {
        continue;
      }
      result[key] = clonedValue;
    }
    return result;
  } else if (type === 'array') {
    const arr: any[] = src as any;
    const result: any[] = [];
    for (const val of arr) {
      result.push(deepCloneNoEmpty(val));
    }
    return result as any;
  } else if (type === 'unsupported') {
    throw Error('unsupported type');
  }
  // null, primitive, undefined case
  return src;
}
