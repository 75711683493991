import * as React from 'react';

export function ChannelSmallIcon({
  width = 20,
  height = 20,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.5 17.5L9.5 10.4166M13.5 17.5L10.5 10.4166"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M7.5 14.5H12.5" stroke={color} />
      <path d="M7 16.5H13" stroke={color} />
      <path
        d="M14 9C14 6.79086 12.2091 5 10 5C7.79086 5 6 6.79086 6 9"
        stroke={color}
      />
      <path
        d="M17 9C17 5.13401 13.866 2 10 2C6.13401 2 3 5.13401 3 9"
        stroke={color}
      />
      <circle cx="10" cy="9" r="1" stroke={color} />
    </svg>
  );
}
