import React from 'react';
import { observer } from 'mobx-react';
// import { omit as _omit } from 'lodash';
import { FieldError as TFieldError } from 'react-hook-form';
import { FormError } from './form-error';

type TErrorLike = Partial<TFieldError> | null;

type FieldErrorProps = {
  errors?: { [key: string]: TErrorLike };
};

export const CatchAllFormErrors = observer(
  ({ errors = {} }: FieldErrorProps) => {
    const keys = Object.keys(errors);
    return (
      <>
        {keys.map(key => {
          const error = errors[key];
          return <FormError key={key} error={error} />;
        })}
      </>
    );
  }
);
