import { Insets } from './../../native-support/insets';
import * as React from 'react';
import {
  getPlayerControls,
  SCRIPT_SCROLLING_CONTAINER_ID,
} from './player-ui-components';
import { appConfig } from 'app/env';
import { UaService } from 'lib/services/ua-service';
import { createLogger } from '@common/log';

const log = createLogger('use-old-ios-scroll-fix');

/// this is just to solve a bug in iPhone iOS 14 and older
/// which prevents the ability to scroll or see the player controls

export const useOldIosScrollFix = () => {
  if (Insets.top > 0) {
    // exit early if we need to compensate insets in native
    return () => {};
  }

  if (
    // conditional flow here is safe since the conditions won't change during the lifecycle of the app
    appConfig.player.enableOldIosScrollFix || // force on for devtest
    UaService.shouldManuallySetScriptContainerHeight
  ) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    React.useLayoutEffect(() => {
      function adjust() {
        const controls = getPlayerControls();
        const controlsHeight = controls?.offsetHeight;
        const element = document.getElementById(SCRIPT_SCROLLING_CONTAINER_ID);
        if (element) {
          element.style.height = `${window.innerHeight - controlsHeight}px`;
        } else {
          log.error(`script-scrolling-container element unexpectedly missing`);
        }
      }

      requestAnimationFrame(() => {
        adjust();
        window.addEventListener('resize', adjust);
        window.addEventListener('orientationchange', adjust);
      });

      return () => {
        window.removeEventListener('resize', adjust);
        window.removeEventListener('orientationchange', adjust);
      };
    }, []);
  }
};
