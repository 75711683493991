import { pickLocalizedValue } from '@core/lib/localization';
import { AppFactory } from '@app/app-factory';

const poster = 'recap-poster.svg';

const shortVideoId = '3960a9b4b190c2e8ec144c03eff186ab';

const en = {
  videoId: 'a7f4a76bfb7cb55dcf2f2b47f8ff8210',
  poster,
};

const pt = {
  videoId: 'ce1fdc4242f8974b43a4025110afd66f',
  poster,
};

const es = {
  videoId: '9cd344c11c75ed99777c861da983a314',
  poster,
};

export function getVideoAssets() {
  const result = pickLocalizedValue({ en, pt, es });
  const { shortVideosEnabled } = AppFactory.root.localState;
  if (shortVideosEnabled) {
    result.videoId = shortVideoId;
  }
  return result;
}
