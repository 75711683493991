import * as React from 'react';
import { observer } from 'mobx-react';
import { AppFactory } from 'app/app-factory';
import { styled } from 'naan/stitches.config';
import { getResources } from './get-resources';
import { Story } from 'core/models/story-manager/story';
import { TeacherResourcesModal } from 'components/teacher-resources';
import { ActivityGuideSection } from './activity-guide-section';
import { ResourcesList } from './resources-list';
// import { useSwitch2 } from 'common/hooks/use-switch-2';
import { LockedResourceDialog } from './locked-resource-dialog';

const OuterWrapper = styled('div', {
  gridColumn: '1/-1',
  backgroundColor: '$yellow-100',
  borderRadius: '$radii$large',
});

const InnerWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  '@medium': {
    flexDirection: 'row',
  },
});

export const Resources = observer(({ story }: { story: Story }) => {
  const { userManager } = AppFactory.root;
  const data = story.activityGuideData;
  const { email, canAccessAllStoryScripts } = userManager.accountData;

  const canAccess: boolean = story.trial ? true : canAccessAllStoryScripts;

  const locked = !canAccess;

  const resources = getResources(story, email);

  const handleLockedResourceTap = React.useCallback(() => {
    AppFactory.dialogPresenter.present(onDismiss => (
      <LockedResourceDialog onDismiss={onDismiss} />
    ));
  }, []);

  const handleTeacherResourcesTap = React.useCallback(() => {
    AppFactory.dialogPresenter.present(onDismiss => (
      <TeacherResourcesModal onDismiss={onDismiss} />
    ));
  }, []);

  return (
    <>
      <OuterWrapper>
        <InnerWrapper>
          {data ? (
            <ActivityGuideSection
              data={data}
              locked={locked}
              onTappingLockedResource={handleLockedResourceTap}
            />
          ) : null}
          <ResourcesList
            hasActivityGuides={!!data}
            resources={resources}
            locked={locked}
            onShowTeacherResources={handleTeacherResourcesTap}
            onTappingLockedResource={handleLockedResourceTap}
          />
        </InnerWrapper>
      </OuterWrapper>
    </>
  );
});
