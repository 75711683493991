// Dec 2021 note: This is a refactor/rewrite of the old component duedate-control
// the UI changed enough that it deserved a rewrite.
import React, { useState } from 'react';
import dayjs from 'dayjs';
import { observer } from 'mobx-react';
import { Button, IconButton } from 'naan/primitives/button';
import { HStack } from 'naan/primitives/stack';
import { HSpacer, VSpacer } from 'naan/primitives/spacer';
import { Text } from 'naan/primitives/text';
import { Divider } from 'naan/primitives/divider';
import { styled } from 'naan/stitches.config';
// I hate including external stylesheets, but this is a necessary evil
// import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import './react-calendar.css';

import Calendar from 'react-calendar';
import { CloseSmallIcon } from 'naan/icons/close-icon';

import __ from 'core/lib/localization';

const DateControlWrapper = styled('div', {
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '$radii$medium',
  backgroundColor: 'rgba(0, 0, 0, 0.05)',

  '& > .calendar-inner-wrapper': {
    position: 'relative',
    maxWidth: 'fit-content',
    background: 'white',
    // boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.2)',
    shadowStyle: 'medium',
    borderRadius: '$radii$large',
    px: '$space$4',
    py: '$space$2',
    zIndex: 1,
  },

  /* Ovewrite component built-in styles for customization sake */
  '.Calendar': {
    /* The component uses css custom props, but we need to use important so they don't get overriden */
    '--cl-color-primary': '$colors$teal-500 !important',
    '--cl-color-primary-light': '$colors$teal-400 !important',
    '--animation-duration': '0.4s',
    boxShadow: 'none',
    margin: '0 -16px',
    padding: '0',
    width: '298px',
    minHeight: '0',
  },
  '.Calendar__header, .Calendar__weekDays, .Calendar__section': {
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  '.Calendar__sectionWrapper': {
    minHeight: '244px',
  },
  '.Calendar__section.-hiddenNext': {
    transform: 'translateX(100%)',
  },
  '.Calendar__section.-hiddenPrevious': {
    transform: 'translateX(-100%)',
  },
  '.Calendar__day': {
    marginBottom: '0',
  },
});

const DateControlHeader = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingBottom: '$space$2',
  borderBottom: '1px solid $colors$gray-100',
  '& > button': {
    marginRight: '-8px',
  },
});

// // What's this? well, The datepicker we are using
// // only accepts values in the form of an object with the shape
// // {year:2020,day:16,month:6} (month values are zero-index based so we need to compensate )
// // the object that dayjs creates already has a weird internal representation
// // of this, so we just translate the keynames
// const dateToObject = (date: string): DayValue => {
//   if (!date) {
//     return null;
//   }
//   const { $y: year, $D: day, $M: month } = dayjs(date) as any;
//   return { year, month: month + 1, day };
// };

// receives a date as a satring in the shape yyyy-mm-dd and returns a Date object
const unserializeDate = (date: string): Date => {
  if (!date) {
    return null;
  }
  const [year, month, day] = date.split('-').map(Number);
  return new Date(year, month - 1, day);
};

const serializeDate = (date: Date): string => {
  if (!date) {
    return null;
  }
  return dayjs(date).format('YYYY-MM-DD');
};

type CalendarWrapperProps = {
  date: any;
  onChange: (date: string | null) => void;
  onReset: () => void;
  onDismiss: () => void;
};

export const CalendarWrapper = observer(
  ({ date, onChange, onReset, onDismiss }: CalendarWrapperProps) => {
    const [selectedDate, setSelectedDate] = useState(unserializeDate(date));
    const [dirty, setDirty] = useState(false);

    const handleOkButton = () => {
      if (selectedDate) {
        onChange(serializeDate(selectedDate));
      } else {
        onChange(null);
      }
    };

    const handleClearButton = () => {
      if (!selectedDate) {
        return;
      }

      setSelectedDate(null);
      onReset();
    };

    return (
      <DateControlWrapper>
        <div className="calendar-inner-wrapper">
          <DateControlHeader>
            <Text textStyle="body-bold" color="textPrimary">
              {__('Due date', 'dueDate')}
            </Text>
            <IconButton
              icon={<CloseSmallIcon />}
              size={'small'}
              onClick={onDismiss}
              testId="close-date-picker"
            />
          </DateControlHeader>
          <Calendar
            onChange={date => {
              setSelectedDate(date as Date);
              setDirty(true);
            }}
            value={selectedDate}
          />
          <Divider />
          <VSpacer size="2" />
          <HStack>
            <Button
              label={__('Remove date', 'removeDate')}
              presentation="text-only-red"
              type="button"
              onClick={handleClearButton}
              disabled={!selectedDate}
              flush="left"
            />
            <HSpacer expand />
            <Button
              label={__('Set due date', 'setDueDate')}
              presentation="tealTransparent"
              type="button"
              flush="right"
              disabled={!dirty}
              onClick={handleOkButton}
            />
          </HStack>
        </div>
      </DateControlWrapper>
    );
  }
);
