import React from 'react';
import { Student } from 'core/models/user-manager';
import { styled } from '@naan/stitches.config';
import { observer } from 'mobx-react';

const AccessCellWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',

  '@media screen and (max-width: 549px)': {
    color: '$colors$textSecondary',
    marginBottom: '4px',
  },

  '& svg': {
    marginRight: '8px',
    width: '12px',
    height: '12px',
    color: '$colors$green-500',
    '&.red': {
      color: '$colors$red-500',
    },
  },

  /* We'll make this button look like a link */
  '& button': {
    cursor: 'pointer',
    margin: '0',
    padding: '0',
    border: 'none',
    background: 'none',
    textDecoration: 'underline',
    color: '$colors$textSecondary',
    '&:hover': {
      color: '$colors$teal-600',
    },
  },
});

const AccessDot = ({ fullAccess }: { fullAccess: boolean }) => {
  return (
    <svg viewBox="0 0 10 10" className={`${fullAccess ? 'green' : 'red'}`}>
      <circle cx="5" cy="5" r="5" fill="currentColor"></circle>
    </svg>
  );
};

export const StudentAccessCell = observer(
  ({ student }: { student: Student }) => {
    const { localizedAccessDescription, fullAccess } = student;

    return (
      <AccessCellWrapper>
        <AccessDot fullAccess={!!fullAccess} />
        {localizedAccessDescription}
      </AccessCellWrapper>
    );
  }
);
