import React from 'react';
import { styled } from '@naan/stitches.config';

const Wrapper = styled('div', {
  color: '$colors$textSecondary',
  marginBottom: '4px',
  wordBreak: 'break-all',
  '@small': {
    marginBottom: '0',
  },
});

export const StudentEmailCell = ({ email }: { email: string }) => {
  return <Wrapper>{email}</Wrapper>;
};
