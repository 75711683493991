import * as React from 'react';
import { ChapterCatalogData } from '@core/models/catalog';
import { observer } from 'mobx-react';
// import { useReaction } from '@common/hooks/use-reaction';
// import { scrollToCurrentChapter } from 'routes/learn/scrolling-fx';
import { FurthestNotCurrent } from './furthest-not-current';
import { CurrentNotFurthest } from './current-not-furthest';
import { CurrentAndFurthest } from './current-and-furthest';
import { Completed } from './completed';
import { Unvisited } from './unvisited';
import { Locked } from './locked';
import { useChapterRowUiContext } from './chapter-item-contents/chapter-row-ui-model';

export const ChapterListItem = observer(
  ({ chapter }: { chapter: ChapterCatalogData }) => {
    const rowUiModel = useChapterRowUiContext();
    const { progress } = chapter.story;

    React.useEffect(() => {
      rowUiModel.initWithChapter(chapter);
    }, [
      rowUiModel,
      chapter,
      // these dependencies are needed to correctly reinit the state after a chapter is completed
      // (otherwise the 'whoa' state was incorrectly shown on the next chapter)
      progress.firstIncompleteSoundbiteIndex,
      progress.currentStage,
      progress.currentStudyMode,
    ]);

    // // temporary short-circuit of catalog data without jwnext ingestion
    // if (!chapter.playerDataUrl) {
    //   return (
    //     <ChapterListItemPresentationWrapper
    //       chapter={chapter}
    //       title={chapter.title}
    //     />
    //   );
    // }

    if (chapter.unstarted) {
      return <Locked chapter={chapter} />;
    }

    if (chapter.isCurrent) {
      if (chapter.isFurthest) {
        return <CurrentAndFurthest chapter={chapter} />;
      } else {
        return <CurrentNotFurthest chapter={chapter} />;
      }
    } else {
      // not current
      if (chapter.isFurthest) {
        return <FurthestNotCurrent chapter={chapter} />;
      }
      if (chapter.isCompleted) {
        return <Completed chapter={chapter} />;
      }
      return <Unvisited chapter={chapter} />;
    }
  }
);
