import { classroomNavPathById } from 'app/app-helpers';
import { styled } from 'naan/stitches.config';
import React from 'react';
import { Link } from 'react-router-dom';

const LabelCellWrapper = styled('div', {
  '& a': {
    color: '$colors$teal-500',
    textDecoration: 'none',
  },
});

export const ClassLabelCell = ({
  label,
  id,
}: {
  label: string;
  id: string;
}) => {
  // const { l2 } = useParams<{ l2: string }>();
  return (
    <LabelCellWrapper>
      <Link to={classroomNavPathById(id)} data-test-id="class-label">
        {label}
      </Link>
    </LabelCellWrapper>
  );
};
