import * as React from 'react';
import { observer } from 'mobx-react';
import { IconButton } from 'naan/primitives/button';
import { Menu, MenuItemProps } from '@naan/primitives/menus';
import { OverflowIcon } from '@naan/icons/overflow-icon';
import { Story } from '@core/models/story-manager';
import { AppFactory } from '@app/app-factory';
import { ResetOptions } from '@core/models/user-manager/story-progress';
import { ResetStoryDialog } from './reset-story-dialog';
import { MarkStoryCompleteDialog } from './mark-story-complete-dialog';
import {
  scrollToCurrentChapter,
  scrollToProgressPanel,
} from 'routes/learn/scrolling-fx';
import { presentVocabExportDialog } from './export-vocab-dialog';
import { ellipsis } from '@utils/string-utils';
import __ from '@core/lib/localization';

type Props = {
  story: Story;
  cardFlavor?: boolean;
  vocabularyReview?: () => void;
} & Pick<React.ComponentProps<typeof IconButton>, 'presentation'>;

export const StoryOverflowMenu = observer(
  ({ story, presentation = 'whiteTransparent', cardFlavor = false }: Props) => {
    const { started, completed, inProgress, unqueued, queued } = story;
    // const { userManager } = AppFactory.root;

    const menuItems: MenuItemProps[] = [];

    if (started) {
      menuItems.push({
        label: __('Reset story', 'resetStory') + ellipsis,
        action: () => {
          AppFactory.dialogPresenter.present(onDismiss => (
            <ResetStoryDialog
              onReset={(options: ResetOptions) => {
                story.progress.resetStory(options);
                scrollToCurrentChapter();
                onDismiss();
              }}
              onDismiss={onDismiss}
            />
          ));
        },
      });
    }

    if (completed) {
      if (inProgress) {
        menuItems.push({
          label: __('Exit review', 'exitReview'),
          action: () => story.progress.markStoryComplete(),
        });
      }
    } else {
      menuItems.push({
        label: __('Mark story complete', 'markStoryComplete') + ellipsis,
        action: () => {
          AppFactory.dialogPresenter.present(onDismiss => (
            <MarkStoryCompleteDialog
              onReset={() => {
                story.progress.markStoryComplete();
                // scrollToCurrentChapter();
                setTimeout(() => {
                  scrollToProgressPanel();
                }, 300);

                onDismiss();
              }}
              onDismiss={onDismiss}
            />
          ));
        },
      });
    }

    if (cardFlavor) {
      if (unqueued) {
        menuItems.push({
          label: __('Study later', 'studyLater'),
          action: () => story.progress.markStudyLater(),
        });
      }

      if (queued) {
        menuItems.push({
          label: __('Remove from Study later', 'removeFromStudyLater'),
          action: () => story.progress.unmarkStudyLater(),
        });
      }
    }

    if (story.progressMayBeNull?.showVocabExportUi) {
      menuItems.push({
        label: __('Export vocab', 'exportVocab') + ellipsis,
        // action: () => handleExportVocab(story.progress),
        action: () => {
          presentVocabExportDialog(story);
        },
      });
    }

    return (
      <Menu
        align="end"
        trigger={
          <IconButton
            presentation={presentation}
            icon={<OverflowIcon />}
            testId="story-overflow-menu"
          />
        }
        items={menuItems}
      />
    );
  }
);
