import * as React from 'react';
import { StreakIcon } from '@naan/icons/streak-icon';
import { colors } from 'naan/tokens/colors';
import { styled } from '@naan/stitches.config';

const Wrapper = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  textStyle: 'medium-heading',
  gap: 4,
  color: '$textPrimary',
  '@medium': {
    textStyle: 'large-heading',
    '& svg': {
      width: 28,
      height: 28,
    },
  },
});

export const Streak = ({ points }: { points: number }) => (
  <Wrapper>
    <StreakIcon color={colors['orange-500']} />
    <span>{points.toLocaleString()}</span>
  </Wrapper>
);
