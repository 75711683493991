import { ModelTreeNode } from 'ts-state-tree/tst-core';
import { Author } from './author';

export class ActivityGuide extends ModelTreeNode {
  static CLASS_NAME = 'ActivityGuide' as const;

  resourceUrl: string = null;
  authorNote: string = null;
  authorData: Author = null;

  static create(snapshot: any) {
    return super.create(ActivityGuide, snapshot) as ActivityGuide;
  }
}
