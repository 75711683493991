import * as React from 'react';

export function TrashSmallIcon({
  width = 20,
  height = 20,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10 7V15" stroke={color} />
      <path d="M7 7V15" stroke={color} />
      <path d="M13 7V15" stroke={color} />
      <path
        d="M7.5 4C7.5 3.17157 8.17157 2.5 9 2.5H11C11.8284 2.5 12.5 3.17157 12.5 4V4.5H7.5V4Z"
        stroke={color}
      />
      <path d="M3 4.5H17" stroke={color} />
      <path
        d="M4.5 4.5H15.5V16C15.5 16.8284 14.8284 17.5 14 17.5H6C5.17157 17.5 4.5 16.8284 4.5 16V4.5Z"
        stroke={color}
      />
    </svg>
  );
}

export function TrashIcon({ width = 24, height = 24, color = 'currentColor' }) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7.5 8V18H8.5V8H7.5Z" fill={color} />
      <path d="M11.5 8V18H12.5V8H11.5Z" fill={color} />
      <path d="M15.5 18V8H16.5V18H15.5Z" fill={color} />
      <path
        d="M8 5V4C8 2.89543 8.89543 2 10 2H14C15.1046 2 16 2.89543 16 4V5H22V6H20V19C20 20.1046 19.1046 21 18 21H6C4.89543 21 4 20.1046 4 19V6H2V5H8ZM10 3H14C14.5523 3 15 3.44772 15 4V5H9V4C9 3.44772 9.44772 3 10 3ZM5 6V19C5 19.5523 5.44772 20 6 20H18C18.5523 20 19 19.5523 19 19V6H5Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}
