type Shadow = {
  light: string;
  dark: string;
};

export const shadowStyles: Record<string, Shadow> = {
  // overlay: '0 1px 4px rgba(0, 0, 0, 0.2)',
  // panel: ' 0 0 8px rgba(0, 0, 0, 0.2)',

  small: {
    light: '0px 1px 3px 0px rgba(0, 0, 0, 0.20)',
    dark: '0px 1px 3px 0px rgba(0, 0, 0, 0.40)',
  },
  medium: {
    light: '0px 1px 6px 0px rgba(0, 0, 0, 0.20)',
    dark: '0px 1px 6px 0px rgba(0, 0, 0, 0.40)',
  },
  large: {
    light: '0px 1px 10px 0px rgba(0, 0, 0, 0.20)',
    dark: '0px 1px 10px 0px rgba(0, 0, 0, 0.40)',
  },
} as const;

export type ShadowStyle = keyof typeof shadowStyles;
