import { AppFactory } from '@app/app-factory';
import * as React from 'react';

export const useAudioPauseOnBackground = () => {
  const model = AppFactory.playerModel;

  React.useEffect(() => {
    const listener = () => {
      if (document.hidden) {
        model?.player?.pause();
      }
    };
    document.addEventListener('visibilitychange', listener);
    return () => {
      document.removeEventListener('visibilitychange', listener);
    };
  }, [model?.player]);
};
