import * as React from 'react';

export const useTimedNotification = ({
  hasNotification,
  timeout,
  onClear,
}: {
  hasNotification: boolean;
  timeout: number;
  onClear: () => void;
}) => {
  const timeoutId = React.useRef<number>();

  React.useEffect(() => {
    if (timeoutId) {
      window.clearTimeout(timeoutId.current);
    }

    if (!hasNotification) {
      return;
    }

    if (timeout > 0) {
      timeoutId.current = window.setTimeout(onClear, timeout);
      return () => window.clearTimeout(timeoutId.current);
    }
  }, [hasNotification, timeout, onClear]);
};
