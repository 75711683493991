import * as React from 'react';

export const CompletedCheckmark = () => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#44C560"
      />
      <path
        d="M16.2041 6.89954L17.7941 8.01283L11.2998 17.2876L6 13.5766L7.1133 11.9866L10.8232 14.5843L16.2041 6.89954Z"
        fill="white"
      />
    </svg>
  );
};
