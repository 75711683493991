import { useEffect } from 'react';
// @ts-ignore
import createListener from 'keyboardist';

// cache and lazy-load the global listeners
let __listeners: any = {};
const getListener = (name: string) => {
  if (!__listeners[name]) {
    __listeners[name] = createListener(name);
  }
  return __listeners[name];
};

/**
 * set only one keyboard binding
 */
export const useKeyboardBinding = (
  keyName = '',
  fn = () => {},
  eventName = 'keydown'
) => {
  useEffect(() => {
    const keyboardListener = getListener(eventName);
    const subscription = keyboardListener.subscribe(keyName, fn);
    return subscription.unsubscribe;
  }, [eventName, fn, keyName]);
};
