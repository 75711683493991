import * as React from 'react';
import { pick } from './pick';

type TLargePresentation = 'color' | 'grayscale';

export const IdiomsIconLarge = ({
  presentation = 'color',
}: {
  presentation?: TLargePresentation;
}) => {
  const color = pick(presentation, {
    color: '#F5832B',
    grayscale: '#999',
  });

  return (
    <svg
      width={72}
      height={72}
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M53.4028 5.04821C51.9915 4.4856 48.7468 8.96507 46.89 12.3435C45.8025 12.1189 44.6674 12 43.5 12C42.3326 12 41.1975 12.1189 40.11 12.3435C38.2532 8.96507 35.0085 4.4856 33.5972 5.04821C31.0441 6.06595 30.1342 12.1516 31.957 16.9338C30.1016 19.0342 29 21.6562 29 24.5C29 28.4132 31.0859 31.9064 34.3514 34.1985C29.6089 38.389 25 44.7617 25 52V60H21.35C18.2567 60 15.7833 59.5211 14.1341 58.4103C12.6793 57.4305 11.5 55.7099 11.5 52.1774C11.5 48.8102 12.8744 46.636 14.626 44.123C14.7219 43.9854 14.8197 43.8459 14.919 43.7044C16.5834 41.3321 18.65 38.3865 18.65 34.1774C18.65 30.8618 17.7539 28.3988 16.4908 26.5971C15.2455 24.8208 13.756 23.8486 12.8334 23.3254C11.6324 22.6442 10.1066 23.0656 9.42542 24.2666C8.74425 25.4676 9.16564 26.9934 10.3666 27.6745C10.9607 28.0115 11.7461 28.5394 12.3967 29.4673C13.0294 30.3699 13.65 31.8155 13.65 34.1774C13.65 36.7799 12.4273 38.5339 10.5953 41.1617L10.524 41.264C8.70059 43.8801 6.5 47.1575 6.5 52.1774C6.5 57.0319 8.24565 60.4726 11.3409 62.5574C14.2417 64.5111 17.9433 65 21.35 65L43.554 65C45.0827 65 46.4877 65.0141 47.7789 65.027C59.9621 65.1491 62 65.1695 62 52.2992C62 45.0077 57.3578 38.4986 52.6088 34.2263C55.8972 31.9348 58 28.4291 58 24.5C58 21.6562 56.8984 19.0342 55.043 16.9338C56.8658 12.1516 55.9559 6.06595 53.4028 5.04821Z"
        fill={color}
      />
    </svg>
  );
};

export const IdiomsIconSmall = ({
  presentation = 'color',
}: {
  presentation?: 'color' | 'grayscale' | 'dark';
}) => {
  const color = pick(presentation, {
    color: '#F5832B',
    grayscale: '#999',
    dark: 'white',
  });

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.1217 2.01606C16.6517 1.82867 15.5709 3.32063 14.9525 4.44587C14.5903 4.37107 14.2122 4.33146 13.8234 4.33146C13.4346 4.33146 13.0565 4.37107 12.6943 4.44587C12.0759 3.32063 10.9952 1.82867 10.5251 2.01606C9.6748 2.35503 9.37175 4.38195 9.97885 5.97475C9.36088 6.67432 8.99398 7.54761 8.99398 8.49479C8.99398 9.79814 9.68871 10.9616 10.7764 11.725C9.19678 13.1207 7.66172 15.2433 7.66172 17.6541V20.3186H6.44603C5.41575 20.3186 4.59197 20.1591 4.04265 19.7891C3.55813 19.4628 3.16533 18.8897 3.16533 17.7132C3.16533 16.5917 3.6231 15.8675 4.20648 15.0305C4.23842 14.9847 4.27102 14.9383 4.3041 14.8911C4.85843 14.101 5.54675 13.1199 5.54675 11.718C5.54675 10.6137 5.24829 9.79335 4.8276 9.19326C4.41283 8.60162 3.91671 8.27784 3.60943 8.10355C3.20942 7.87668 2.70123 8.01703 2.47436 8.41704C2.24748 8.81705 2.38783 9.32524 2.78784 9.55211C2.98571 9.66434 3.24731 9.84015 3.46398 10.1492C3.67473 10.4498 3.88142 10.9313 3.88142 11.718C3.88142 12.5848 3.47417 13.169 2.86401 14.0442L2.84027 14.0783C2.23294 14.9496 1.5 16.0412 1.5 17.7132C1.5 19.3301 2.08142 20.476 3.11235 21.1704C4.07848 21.8211 5.31138 21.9839 6.44603 21.9839L13.8414 21.984C14.3506 21.984 14.8185 21.9886 15.2486 21.993C19.3064 22.0336 19.9851 22.0404 19.9852 17.7537C19.9852 15.3252 18.439 13.1572 16.8573 11.7343C17.9525 10.9711 18.6529 9.80343 18.6529 8.49479C18.6529 7.54761 18.286 6.67431 17.668 5.97475C18.2751 4.38195 17.9721 2.35503 17.1217 2.01606Z"
        fill={color}
      />
    </svg>
  );
};
