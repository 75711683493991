import * as React from 'react';

import { StudyModel } from 'study/models/study-model';
import { SoundbiteModel } from 'soundbite/models/soundbite-model';
import { SentenceId } from '@tikka/basic-types';

type StudyOrSoundbiteModel = StudyModel | SoundbiteModel;

export const MenuContext = React.createContext<{
  model: StudyOrSoundbiteModel;
  onDismiss: () => void;
  sentenceId: SentenceId;
} | null>(null);
