import * as React from 'react';
import { styled } from 'naan/stitches.config';
import { ChevronDownSmallIcon } from 'naan/icons/chevron-down-icon';

const StyledSelect = styled('select', {
  all: 'unset',
  textStyle: 'body',
  color: '$textPrimary',
  padding: '12px 40px 12px 16px',
  borderRadius: '$radii$medium',
  '&::placeholder': {
    color: '$gray-300',
  },
});

const StyledWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  backgroundColor: '$gray-50',
  textStyle: 'body',
  color: '$textPrimary',
  borderRadius: '$radii$medium',
  position: 'relative',
  [`& > ${StyledSelect}`]: {
    flex: 1,
  },
  '& > .icon': {
    color: '$gray-400',
    paddingLeft: '$space$3',
    lineHeight: 0,
  },
  '& > .arrow': {
    position: 'absolute',
    right: 12,
    top: 16,
    width: 16,
    height: 16,
    // bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    pointerEvents: 'none',
    '& > svg': {
      width: 16,
      height: 16,
      '& > path': {
        stroke: '$gray-400',
      },
    },
  },
});

type StyledSelectProps = React.ComponentProps<typeof StyledSelect>;

type InputProps = StyledSelectProps & {
  leftIcon?: React.ReactNode;
};

export const Select = React.forwardRef<HTMLSelectElement, InputProps>(
  ({ leftIcon, ...props }, ref) => {
    return (
      <StyledWrapper>
        {leftIcon ? (
          <span className="icon" aria-hidden>
            {leftIcon}
          </span>
        ) : null}
        <StyledSelect {...props} ref={ref}></StyledSelect>
        <span className="arrow">
          <ChevronDownSmallIcon />
        </span>
      </StyledWrapper>
    );
  }
);
