import { styled } from 'naan/stitches.config';

export const Stack = styled('div', {
  $$gapSize: '0',
  display: 'flex',
  variants: {
    axis: {
      vertical: {
        flexDirection: 'column',
        '& > * + *': {
          marginTop: '$$gapSize',
        },
      },
      horizontal: {
        flexDirection: 'row',
        '& > * + *': {
          marginLeft: '$$gapSize',
        },
      },
    },
    gap: {
      small: {
        $$gapSize: '$space$2',
      },
      medium: {
        $$gapSize: '$space$4',
      },
      large: {
        $$gapSize: '$space$6',
      },
    },
    justify: {
      start: {
        justifyContent: 'flex-start',
      },
      end: {
        justifyContent: 'flex-end',
      },
      space: {
        justifyContent: 'space-between',
      },
      center: {
        justifyContent: 'center',
      },
    },
    align: {
      start: {
        alignItems: 'flex-start',
      },
      end: {
        alignItems: 'flex-end',
      },
      center: {
        alignItems: 'center',
      },
    },
  },
  defaultVariants: {
    axis: 'horizontal',
  },
});
