import * as React from 'react';
import { aboutRaId } from './about-ra';
import { darkThemeSelector, styled } from '@naan/stitches.config';
import { Image } from '@naan/primitives/image';
import storiesByRaImg from './assets/stories-by-ra.png';
import { IconButton } from '@naan/primitives/button';
import { InfoCircleSmallIcon } from '@naan/icons/info-circle-icon';
import { scrollContainerToElementId } from 'lib/scroll-utils';
import { AppFactory } from '@app/app-factory';

function scrollToAboutRa() {
  const id = aboutRaId;
  scrollContainerToElementId(id);
}

const Wrapper = styled('div', {
  backgroundColor: '$blue-500',
  [darkThemeSelector]: {
    backgroundColor: '$blue-300',
  },
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 16,
  '& img': {
    width: 255,
    '@medium': {
      width: 306,
    },
  },
});

export const ChannelBanner = () => {
  // @armando, i'll let you revisit this
  // const { storyManager } = AppFactory.root;
  // const raBranding = !storyManager.isMultiChannel;

  const { raBranding } = AppFactory.root;

  if (!raBranding) {
    return null;
  }

  return (
    <Wrapper>
      <Image src={storiesByRaImg} />
      <IconButton
        testId="channel-banner-info-button"
        presentation="whiteTransparent"
        icon={<InfoCircleSmallIcon />}
        onClick={scrollToAboutRa}
      />
    </Wrapper>
  );
};
