import { Intervals } from '../intervals/intervals';

export enum CanNavigateResult {
  // eslint-disable-next-line no-unused-vars
  CAN_NAVIGATE_FORWARD = 1 << 0,
  // eslint-disable-next-line no-unused-vars
  CAN_NAVIGATE_BACK = 1 << 1,
}

export class NavigationPoint {
  navigatorKey: string;
  index: number;
  position: number;
  // }
  constructor(navigatorKey: string, index: number, position: number) {
    this.navigatorKey = navigatorKey;
    this.index = index;
    this.position = position;
  }
}

export enum EdgeEnum {
  // eslint-disable-next-line no-unused-vars
  Leading = 1,
  // eslint-disable-next-line no-unused-vars
  Trailing = 2,
  // eslint-disable-next-line no-unused-vars
  Midpoint = 3,
}

export class TimelineNavigator {
  navigatorKey: string;
  positions: Intervals;

  constructor(key: string = 'DEFAULT') {
    this.navigatorKey = key;
    this.positions = null;
  }

  setPositions(intervals: Intervals) {
    this.positions = intervals;
  }

  getKey() {
    return this.navigatorKey;
  }

  setIntervals(intervals: Intervals, edge0: EdgeEnum = EdgeEnum.Leading) {
    const edge = edge0 || EdgeEnum.Leading;
    //         match edge with
    if (edge === EdgeEnum.Leading) {
      this.setPositions(intervals.fromStartPoints());
    } else if (edge === EdgeEnum.Trailing) {
      this.setPositions(intervals.fromEndPoints());
    } else if (edge === EdgeEnum.Midpoint) {
      this.setPositions(intervals.fromMidPoints());
    }
  }

  getPositionForIndex(index: number) {
    if (this.positions) {
      return this.positions.pointAt(index).begin;
    } else {
      throw Error('no positions');
    }
  }

  navigationPoint(index: number) {
    if (this.positions) {
      if (this.positions.checkValidIndex(index)) {
        const position = this.getPositionForIndex(index);
        return new NavigationPoint(this.navigatorKey, index, position);
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  move(point0: NavigationPoint, direction: number) {
    if (point0.navigatorKey !== this.navigatorKey) {
      throw Error("navigator keys don't match");
    }
    const nextNavigationPoint = this.navigationPoint(point0.index + direction);
    if (nextNavigationPoint) {
      return nextNavigationPoint;
    } else {
      return point0;
    }
  }

  next(point: NavigationPoint) {
    return this.move(point, 1);
  }

  prev(point: NavigationPoint) {
    return this.move(point, -1);
  }

  nextClosest(position: number) {
    if (this.positions) {
      return this.navigationPoint(this.positions.firstAfter(position));
    } else {
      return null;
    }
  }

  prevClosest(position: number) {
    if (this.positions) {
      return this.navigationPoint(
        this.positions.lastBeforeOrAt(position) // @jason is the -1 tweak better placed here?
      );
    } else {
      return null;
    }
  }

  queryCanNavigate(pos: number, beginRange: number, endRange: number) {
    const nextNavPoint = this.nextClosest(pos);
    let result: CanNavigateResult = 0;

    if (nextNavPoint && nextNavPoint.position < endRange) {
      result = result | CanNavigateResult.CAN_NAVIGATE_FORWARD;
    }

    // @jason, please document the implications of the -1 here (inclusive/exclusive hack?)
    const prevNavPoint = this.prevClosest(pos - 1);
    if (
      prevNavPoint &&
      pos > prevNavPoint.position &&
      prevNavPoint.position > beginRange
    ) {
      result = result | CanNavigateResult.CAN_NAVIGATE_BACK;
    }
    // console.log(`queryCanNavigate`, {
    //   pos,
    //   beginRange,
    //   endRange,
    //   nextNavPoint,
    //   prevNavPoint,
    // });
    return result;
  }
}

export class Navigation {
  navigators: any = {};

  addNavigator(navigator: TimelineNavigator) {
    this.navigators[navigator.getKey()] = navigator;
  }

  getNavigatorForKey(key: string = 'DEFAULT'): TimelineNavigator {
    const navigators = Object.values(this.navigators) as TimelineNavigator[];
    if (navigators.length === 1) {
      return navigators[0];
    }
    return this.navigators[key];
  }

  next(point: NavigationPoint): NavigationPoint {
    if (point) {
      const navigator = this.getNavigatorForKey(point.navigatorKey);
      return navigator.next(point);
    } else {
      return null;
    }
  }

  prev(point: NavigationPoint): NavigationPoint {
    if (point) {
      const navigator = this.getNavigatorForKey(point.navigatorKey);
      return navigator.prev(point);
    } else {
    }
  }

  nextClosest(point: NavigationPoint, position: number): NavigationPoint {
    if (point) {
      const navigator = this.getNavigatorForKey(point.navigatorKey);
      return navigator.nextClosest(position);
    } else {
      return null;
    }
  }

  prevClosest(point: NavigationPoint, position: number): NavigationPoint {
    if (point) {
      const navigator = this.getNavigatorForKey(point.navigatorKey);
      return navigator.prevClosest(position);
    } else {
      return null;
    }
  }
}
