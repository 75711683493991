import Dayjs from 'dayjs';
import { ModelTreeNode, volatile } from 'ts-state-tree/tst-core';
import { AppFactory } from '@app/app-factory';
import __ from '@core/lib/localization';
// import { EntitlementData } from './entitlement-data';
// import localizedFormat from 'dayjs/plugin/localizedFormat';
// Dayjs.extend(localizedFormat);

import { createLogger } from '@common/log';

const log = createLogger('student');
export class Student extends ModelTreeNode {
  static CLASS_NAME = 'Student' as const;

  static create(snapshot: any) {
    return super.create(Student, snapshot) as Student;
  }

  email: string = '';
  name: string = '';
  membershipType: string = null; // obsolete rails status
  accessDescription: string = null; // obsolete rails status
  fullAccess: boolean = false; // initially the rails status, but will be transiently updated with the node status
  fullAccessUntil: string; // iso8061 formatted date string
  userDataUuid: string; // firestore data key for client-side access to study progress

  // dynamically fetched from node tier (as needed for teacher's classroom summary view)
  @volatile
  nodeStatusFetched: boolean = false;
  // nodeState: EntitlementData;

  // get localizedFullAccessUntil() {
  //   return Dayjs(this.fullAccessUntil).format('ll');
  // }

  async refreshAccessStatus() {
    const { root, caliServerInvoker } = AppFactory;

    if (!this.userDataUuid) {
      log.warn(`userDataUuid not set for: ${this.email}`);
      return;
    }
    log.debug(`refreshAccessStatus: ${this.email}`);
    const nodeAccountData = await caliServerInvoker.fetchNodeAccountData(
      this.userDataUuid
    );
    const nodeState = nodeAccountData?.entitlements?.[root.l2];
    if (nodeState) {
      this.fullAccess =
        nodeState.accessUntil &&
        // todo: this date logic technically belongs in the server, but this is convenient for testing
        root.storyManager.isTodaySameOrBefore(Dayjs(nodeState.accessUntil));
    }
    // applySnapshot(this.nodeState, nodeState);
    this.nodeStatusFetched = true;
  }

  // todo: flush out more detail access description
  get localizedAccessDescription(): string {
    return this.fullAccess
      ? __('All stories', 'allStories')
      : __('Trial stories only', 'trialStoriesOnly');
  }
}
