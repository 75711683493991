import * as React from 'react';
import {
  PointsCardWrapper,
  PointsRow,
  SummaryRow,
} from 'components/ui/player-end-card/points-card';

import __ from '@core/lib/localization';

export const ProgressCard = ({
  itemsReviewed,
  itemPoints,
  today,
  last7Days,
}: {
  itemsReviewed: number;
  itemPoints: number;
  today: number;
  last7Days: number;
}) => {
  return (
    <PointsCardWrapper>
      <PointsRow
        title={__('%{n} items reviewed', 'nItemsReviewed', {
          n: itemsReviewed,
        })}
        points={itemPoints}
      />
      <SummaryRow todayPoints={today} sevenDayPoints={last7Days} />
    </PointsCardWrapper>
  );
};
