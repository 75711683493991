import * as React from 'react';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { styled } from '@naan/stitches.config';

import __ from '@core/lib/localization';

export function splitSeconds(seconds: number) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;
  return { hours, minutes, seconds: remainingSeconds };
}

class Clock {
  targetTime: number;
  remainingSeconds: number;
  timerId: number | null = null;

  constructor(initialSeconds: number) {
    this.remainingSeconds = initialSeconds;
    this.targetTime = Date.now() + initialSeconds * 1000;

    makeObservable(this, {
      remainingSeconds: observable,
      setRemainingSeconds: action,
    });

    this.startTimer();
  }

  startTimer() {
    this.timerId = window.setInterval(() => this.update(), 1000);
  }

  stopTimer() {
    if (this.timerId !== null) {
      clearInterval(this.timerId);
      this.timerId = null;
    }
  }

  update() {
    const now = Date.now();
    const diff = Math.round((this.targetTime - now) / 1000);
    runInAction(() => {
      this.remainingSeconds = diff > 0 ? diff : 0;
    });

    if (diff <= 0) {
      this.stopTimer();
    }
  }

  setRemainingSeconds(seconds: number) {
    this.remainingSeconds = seconds;
    this.targetTime = Date.now() + seconds * 1000;
    if (this.timerId === null) {
      this.startTimer();
    }
  }
}

const Wrapper = styled('div', {
  width: 'max-content',
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gap: 4,

  '& > div': {
    color: '$colors$globalBlack-alpha-80',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: '$globalWhite',
    borderRadius: '$radii$medium',
    width: 56,
    height: 56,
    paddingBlock: 4,
    shadowStyle: 'small',

    textStyle: 'medium-heading',

    '& .label': {
      marginTop: -8,
      textStyle: 'tiny-text-bold',
      color: '$colors$globalBlack-alpha-50',
    },
  },

  '&.hide-label': {
    '& > div': {
      width: 34,
      height: 'auto',
      textStyle: 'small-heading',
      '& .label': {
        display: 'none',
      },
    },
  },
});

export const CountdownClock = observer(
  ({ seconds, showLabel = true }: { seconds: number; showLabel?: boolean }) => {
    const clock = React.useRef(new Clock(seconds));

    React.useEffect(() => {
      // If the `seconds` prop changes, reset the clock
      clock.current.setRemainingSeconds(seconds);
    }, [seconds]);

    const {
      hours,
      minutes,
      seconds: remainingSeconds,
    } = splitSeconds(clock.current.remainingSeconds);

    return (
      <Wrapper className={showLabel ? 'show-label' : 'hide-label'}>
        <div>
          {hours}
          <span className="label">{__('hrs', 'hrs')}</span>
        </div>
        <div>
          {minutes.toString().padStart(2, '0')}
          <span className="label">{__('mins', 'mins')}</span>
        </div>
        <div>
          {remainingSeconds.toString().padStart(2, '0')}
          <span className="label">{__('secs', 'secs')}</span>
        </div>
      </Wrapper>
    );
  }
);
