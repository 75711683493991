import * as React from 'react';

export function EarSmallIcon({
  width = 20,
  height = 20,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.5 7.50934C4.5 4.47147 6.972 2 10.0093 2C13.0472 2 15.5187 4.47147 15.5186 7.50934C15.5186 9.56068 14.6 10.4573 13.7111 11.3249L13.7106 11.3253L13.71 11.3259C12.8249 12.1908 11.9127 13.082 11.9127 15.104C11.9127 16.7008 10.613 18 9.01622 18C7.4194 18 6.12022 16.7008 6.12022 15.104C6.12022 14.8091 6.35916 14.5706 6.65356 14.5706C6.94797 14.5706 7.18691 14.8091 7.18691 15.104C7.18691 16.1131 8.00772 16.9333 9.01625 16.9333C10.0253 16.9333 10.8461 16.1131 10.8461 15.104C10.8461 12.6325 12.019 11.4869 12.9618 10.5659L12.9624 10.5653C13.8285 9.72 14.452 9.11093 14.452 7.50934C14.452 5.05975 12.4589 3.06669 10.0093 3.06669C7.55975 3.06669 5.56669 5.05975 5.56669 7.50934C5.56669 7.80375 5.32775 8.04268 5.03334 8.04268C4.73894 8.04268 4.5 7.80375 4.5 7.50934ZM9.16881 8.65281C9.71812 8.97812 10.1085 9.49865 10.2669 10.1189C10.4253 10.7397 10.3331 11.3845 10.0056 11.9354C9.67919 12.4853 9.15812 12.8757 8.53784 13.0341C8.34106 13.0848 8.14106 13.1098 7.94212 13.1098C7.51706 13.1098 7.09841 12.9957 6.72347 12.7733C6.47016 12.6229 6.38694 12.2955 6.53681 12.0421C6.68666 11.7888 7.01412 11.7056 7.26747 11.8555C7.57306 12.0368 7.93041 12.088 8.27441 12.0005C8.61841 11.9125 8.90747 11.6965 9.08881 11.3904C9.27015 11.0853 9.32187 10.7275 9.23334 10.3829C9.14534 10.0389 8.92934 9.7504 8.62428 9.57012C8.37628 9.4224 8.09416 9.36106 7.80828 9.3904C7.50962 9.41815 7.25256 9.20856 7.22216 8.91574C7.22172 8.91159 7.22133 8.90745 7.22099 8.90331C7.21788 8.88276 7.21593 8.86181 7.21522 8.84052L7.16776 7.42187C7.16531 7.35108 7.17681 7.28307 7.19977 7.22046C7.38731 5.68277 8.70084 4.48746 10.2883 4.48746C12.0035 4.48746 13.3992 5.88371 13.3993 7.59946C13.3993 7.89387 13.1609 8.13281 12.8659 8.13281C12.571 8.13281 12.3326 7.89387 12.3326 7.59946C12.3326 6.47146 11.4153 5.55412 10.2883 5.55412C9.16085 5.55412 8.2435 6.47146 8.2435 7.59946C8.2435 7.61184 8.24308 7.62412 8.24225 7.63629L8.26572 8.33759C8.58236 8.37988 8.88803 8.48575 9.16881 8.65281Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}

export function EarIcon({ width = 24, height = 24, color = 'currentColor' }) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 8.88668C5 5.08934 8.09 2 11.8867 2C15.684 2 18.7734 5.08934 18.7733 8.88668C18.7733 11.4509 17.625 12.5716 16.5139 13.6561L16.5133 13.6567L16.5126 13.6574C15.4061 14.7385 14.2659 15.8525 14.2659 18.38C14.2659 20.376 12.6413 22 10.6453 22C8.64926 22 7.02527 20.376 7.02527 18.38C7.02527 18.0113 7.32395 17.7133 7.69195 17.7133C8.05996 17.7133 8.35863 18.0113 8.35863 18.38C8.35863 19.6413 9.38465 20.6667 10.6453 20.6667C11.9066 20.6667 12.9327 19.6413 12.9327 18.38C12.9327 15.2907 14.3987 13.8586 15.5773 12.7074L15.578 12.7067C16.6607 11.65 17.44 10.8887 17.44 8.88668C17.44 5.82469 14.9487 3.33336 11.8867 3.33336C8.82469 3.33336 6.33336 5.82469 6.33336 8.88668C6.33336 9.25469 6.03469 9.55336 5.66668 9.55336C5.29867 9.55336 5 9.25469 5 8.88668ZM10.836 10.316C11.5226 10.7227 12.0106 11.3733 12.2087 12.1487C12.4067 12.9246 12.2913 13.7307 11.8819 14.4193C11.474 15.1066 10.8226 15.5946 10.0473 15.7926C9.80131 15.856 9.55131 15.8873 9.30264 15.8873C8.77131 15.8873 8.24799 15.7446 7.77932 15.4666C7.46268 15.2787 7.35866 14.8693 7.546 14.5527C7.7333 14.236 8.14264 14.132 8.45932 14.3193C8.84131 14.546 9.28799 14.61 9.71799 14.5007C10.148 14.3907 10.5093 14.1207 10.736 13.738C10.9627 13.3567 11.0273 12.9093 10.9167 12.4787C10.8067 12.0487 10.5367 11.688 10.1553 11.4627C9.84534 11.278 9.49268 11.2013 9.13534 11.238C8.76202 11.2727 8.44069 11.0107 8.40268 10.6447C8.40209 10.6391 8.40157 10.6336 8.40113 10.628C8.39734 10.6027 8.39496 10.5769 8.39409 10.5507L8.33476 8.77733C8.33171 8.68905 8.34601 8.60422 8.37458 8.5261C8.60878 6.60375 10.2508 5.10936 12.2353 5.10936C14.3793 5.10936 16.124 6.85468 16.124 8.99936C16.124 9.36737 15.826 9.66604 15.4574 9.66604C15.0887 9.66604 14.7907 9.36737 14.7907 8.99936C14.7907 7.58936 13.644 6.44268 12.2353 6.44268C10.826 6.44268 9.67931 7.58936 9.67931 8.99936C9.67931 9.01438 9.67881 9.02928 9.67783 9.04406L9.70721 9.92201C10.103 9.97488 10.485 10.1072 10.836 10.316Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}
