import * as React from 'react';
import { observer } from 'mobx-react';
import { useCouponDialog } from './use-coupon-dialog';
import { useAnonymousCouponDialog } from './use-anonymous-coupon-dialog';
import { NoLink } from '@naan/primitives/text/no-link';
import { subscribePath } from 'components/nav/path-helpers';

import __ from 'core/lib/localization';

export const PlainCouponLink = observer(
  ({
    label = __('Have a classroom, coupon or promo code?', 'haveACoupon'),
    afterSuccess,
  }: {
    label?: string;
    afterSuccess?: () => void;
  }) => {
    const handleApplyCode = useCouponDialog({
      afterSuccess,
    });
    const handleClick: React.MouseEventHandler<HTMLAnchorElement> =
      React.useCallback(
        e => {
          e.preventDefault();
          handleApplyCode();
        },
        [handleApplyCode]
      );

    return (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <NoLink onClick={handleClick}>{label}</NoLink>
    );
  }
);

export const AnonymousPlainCouponLink = observer(
  ({
    label = __('Have a classroom, coupon or promo code?', 'haveACoupon'),
  }: {
    label?: string;
  }) => {
    const handleApplyCode = useAnonymousCouponDialog({
      redirectTo: subscribePath(),
    });
    const handleClick: React.MouseEventHandler<HTMLAnchorElement> =
      React.useCallback(
        e => {
          e.preventDefault();
          handleApplyCode();
        },
        [handleApplyCode]
      );

    return (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <NoLink onClick={handleClick}>{label}</NoLink>
    );
  }
);
