/// This is a mechanism to use descriptive css variables
/// so we can reuse most of the player styles and just switch the colors
/// when necessary.
/// This is different from the global theming mechanism which will work just by swapping palettes.
export const studyPalette = {
  $$chapterTitle__color: '$colors$textPrimary',
  $$chapterTitle__positionColor: '$colors$textSecondary',
  $$endCard__backgroundColor: '$colors$white',
  $$endCard__dividerColor: '$colors$gray-100',
  $$endCard__primaryColor: '$colors$textPrimary',
  $$endCard__secondaryColor: '$colors$textSecondary',
  $$header__backgroundColor: '$colors$blue-500',
  $$keyboardButton__hoverBackground: '$colors$black-alpha-06',
  $$keyboardButton__iconColor: '$colors$black-alpha-30',
  $$passageHint__borderColor: '$colors$black-alpha-06',
  $$passageHint__color_unvisited: '$colors$black-alpha-06',
  $$passageHint__color_visited: '$colors$textPrimary',
  $$redacted__backgroundColor_current: '$colors$teal-400',
  $$redacted__backgroundColor_pendingCurrent: '$colors$orange-400',
  $$redacted__backgroundColor_remaining: '$colors$orange-100',
  $$redacted__backgroundColor_unvisited: '$colors$black-alpha-06',
  $$redacted__backgroundColor_visited: '$colors$gray-400',
  $$script__backgroundColor_default: '$colors$gray-75',
  $$scriptPlayingBackdrop__backgroundColor: '$colors$globalBlack-alpha-20',
  $$sentence__backgroundColor_current: '$colors$white',
  $$sentence__backgroundColor_default: '$colors$transparent',
  $$sentence__backgroundColor_hover: '$colors$white-alpha-30',
  $$sentence__inlineTranslationColor: '$colors$gray-500',
  $$sentence_toolbar__backgroundColor: '$colors$gray-25',
  $$sentence_toolbar__color: '$colors$gray-800',
  $$sentence_toolbar__shadowColor: '$colors$black-alpha-20',
  $$spacer__backgroundColor: '$colors$yellow-500',
  $$spacer__backgroundColor_current: '$colors$teal-500',
  $$spacer__backgroundColor_pauseIcon: '$colors$orange-400',
  $$spacer__backgroundColor_pendingCurrent: '$colors$orange-400',
  $$spacer__backgroundColor_remaining: '$colors$orange-100',
  $$spacer__backgroundColor_unvisited: '$colors$black-alpha-06',
  $$speakerLabel__backgroundColor_current: '$colors$white',
  $$speakerLabel__backgroundColor_unvisited: '$colors$transparent',
  $$speakerLabel__backgroundColor_visited: '$colors$transparent',
  $$speakerLabel__borderColor_current: '$colors$transparent',
  $$speakerLabel__borderColor_unvisited: '$colors$black-alpha-06',
  $$speakerLabel__borderColor_visited: '$colors$black-alpha-06',
  $$speakerLabel__color_current: '$colors$textPrimary',
  $$speakerLabel__color_unvisited: '$colors$black-alpha-10',
  $$speakerLabel__color_visited: '$colors$textPrimary',
  $$translation__color: '$colors$black-alpha-06',
  $$translation__color_visited: '$colors$gray-500',
  $$transport__backgroundColor: '$colors$gray-25',
  $$transport__buttonColor: '$colors$gray-800',
  $$transport__buttonColor_active: '$colors$teal-500',
  $$transport__buttonColor_disabled: '$colors$gray-100',
  $$transport__pendingPauseColor: '$colors$gray-100',
  $$transport__pendingPauseProgressColor: '$colors$orange-400',
  $$transport__progressBackgroundColor: '$colors$gray-75',
  $$transport__progressCurrentColor: '$colors$teal-400',
  $$transport__progressFurthestColor: '$colors$teal-500-alpha-30',
  $$word__color_current: '$colors$teal-500',
  $$word__color_default: '$colors$black-alpha-10',
  $$word__color_pending: '$colors$orange-100',
  $$word__color_pendingCurrent: '$colors$orange-500',
  $$word__color_sic_visited: '$colors$textSecondary',
  $$word__color_visited: '$colors$textPrimary',
  $$word__backgroundColor_vocabHighlight: '$colors$purple-100',
} as const;

type PaletteShape = typeof studyPalette;

export const darkStudyPalette = {
  $$chapterTitle__color: '$colors$textPrimary',
  $$chapterTitle__positionColor: '$colors$textSecondary',
  $$endCard__backgroundColor: '$colors$white',
  $$endCard__dividerColor: '$colors$gray-100',
  $$endCard__primaryColor: '$colors$textPrimary',
  $$endCard__secondaryColor: '$colors$textSecondary',
  $$header__backgroundColor: '$colors$blue-400',
  $$keyboardButton__hoverBackground: '$colors$black-alpha-06',
  $$keyboardButton__iconColor: '$colors$black-alpha-30',
  $$passageHint__borderColor: '$colors$black-alpha-06',
  $$passageHint__color_unvisited: '$colors$black-alpha-06',
  $$passageHint__color_visited: '$colors$textPrimary',
  $$redacted__backgroundColor_current: '$colors$teal-500',
  $$redacted__backgroundColor_pendingCurrent: '$colors$orange-400',
  $$redacted__backgroundColor_remaining: '$colors$orange-500-alpha-20',
  $$redacted__backgroundColor_unvisited: '$colors$black-alpha-06',
  $$redacted__backgroundColor_visited: '$colors$black-alpha-30',
  $$script__backgroundColor_default: '$colors$gray-100',
  $$scriptPlayingBackdrop__backgroundColor: '$colors$globalBlack-alpha-30',
  $$sentence__backgroundColor_current: '$colors$gray-200',
  $$sentence__backgroundColor_default: '$colors$transparent',
  $$sentence__backgroundColor_hover: '$colors$globalWhite-alpha-06',
  $$sentence__inlineTranslationColor: '$colors$gray-600',
  $$sentence_toolbar__backgroundColor: '$colors$globalBlack-alpha-15',
  $$sentence_toolbar__color: '$colors$gray-800',
  $$sentence_toolbar__shadowColor: '$colors$transparent',
  $$spacer__backgroundColor: '$colors$yellow-300',
  $$spacer__backgroundColor_current: '$colors$teal-500',
  $$spacer__backgroundColor_pauseIcon: '$colors$orange-400',
  $$spacer__backgroundColor_pendingCurrent: '$colors$orange-400',
  $$spacer__backgroundColor_remaining: '$colors$orange-500-alpha-20',
  $$spacer__backgroundColor_unvisited: '$colors$black-alpha-06',
  $$speakerLabel__backgroundColor_current: '$colors$gray-200',
  $$speakerLabel__backgroundColor_unvisited: '$colors$transparent',
  $$speakerLabel__backgroundColor_visited: '$colors$transparent',
  $$speakerLabel__borderColor_current: '$colors$transparent',
  $$speakerLabel__borderColor_unvisited: '$colors$black-alpha-06',
  $$speakerLabel__borderColor_visited: '$colors$black-alpha-06',
  $$speakerLabel__color_current: '$colors$textPrimary',
  $$speakerLabel__color_unvisited: '$colors$black-alpha-10',
  $$speakerLabel__color_visited: '$colors$textPrimary',
  $$translation__color: '$colors$black-alpha-06',
  $$translation__color_visited: '$colors$gray-500',
  $$transport__backgroundColor: '$colors$gray-200',
  $$transport__buttonColor: '$colors$gray-800',
  $$transport__buttonColor_active: '$colors$teal-500',
  $$transport__buttonColor_disabled: '$colors$globalWhite-alpha-10',
  $$transport__pendingPauseColor: '$colors$globalWhite-alpha-10',
  $$transport__pendingPauseProgressColor: '$colors$orange-400',
  $$transport__progressBackgroundColor: '$colors$gray-100',
  $$transport__progressCurrentColor: '$colors$teal-400',
  $$transport__progressFurthestColor: '$colors$teal-500-alpha-30',
  $$word__color_current: '$colors$teal-600',
  $$word__color_default: '$colors$black-alpha-10',
  $$word__color_pending: '$colors$orange-500-alpha-20',
  $$word__color_pendingCurrent: '$colors$orange-500',
  $$word__color_sic_visited: '$colors$textSecondary',
  $$word__color_visited: '$colors$textPrimary',
  $$word__backgroundColor_vocabHighlight: '$colors$purple-300',
} as const;

export const listenModePalette: Record<keyof PaletteShape, string> = {
  $$chapterTitle__color: '$colors$gray-300',
  $$chapterTitle__positionColor: '$colors$gray-500',
  $$endCard__backgroundColor: '$colors$gray-800',
  $$endCard__dividerColor: '$colors$gray-700',
  $$endCard__primaryColor: '$colors$white',
  $$endCard__secondaryColor: '$colors$gray-300',
  $$header__backgroundColor: '$colors$gray-800',
  $$keyboardButton__hoverBackground: '$colors$white-alpha-30',
  $$keyboardButton__iconColor: '$colors$white-alpha-50',
  $$passageHint__borderColor: '$colors$white-alpha-06',
  $$passageHint__color_unvisited: '$colors$white-alpha-06',
  $$passageHint__color_visited: '$colors$gray-300',
  $$redacted__backgroundColor_current: '$colors$teal-500-alpha-50',
  $$redacted__backgroundColor_pendingCurrent: '$colors$orange-800',
  $$redacted__backgroundColor_remaining: '$colors$orange-500-alpha-10',
  $$redacted__backgroundColor_unvisited: '$colors$white-alpha-03',
  $$redacted__backgroundColor_visited: '$colors$white-alpha-10',
  $$script__backgroundColor_default: '$colors$gray-900',
  $$scriptPlayingBackdrop__backgroundColor: '$colors$globalBlack-alpha-20',
  $$sentence__backgroundColor_current: '$colors$gray-800',
  $$sentence__backgroundColor_default: '$colors$transparent',
  $$sentence__backgroundColor_hover: '$colors$black-alpha-30',
  $$sentence__inlineTranslationColor: '$colors$gray-500',
  $$sentence_toolbar__backgroundColor: '$colors$black-alpha-15',
  $$sentence_toolbar__color: '$colors$globalWhite',
  $$sentence_toolbar__shadowColor: '$colors$transparent',
  $$spacer__backgroundColor: '$colors$yellow-500-alpha-20',
  $$spacer__backgroundColor_current: '$colors$teal-500-alpha-50',
  $$spacer__backgroundColor_pauseIcon: '$colors$orange-800',
  $$spacer__backgroundColor_pendingCurrent: '$colors$orange-400',
  $$spacer__backgroundColor_remaining: '$colors$orange-500-alpha-10',
  $$spacer__backgroundColor_unvisited: '$colors$white-alpha-06',
  $$speakerLabel__backgroundColor_current: '$colors$gray-400',
  $$speakerLabel__backgroundColor_unvisited: '$colors$transparent',
  $$speakerLabel__backgroundColor_visited: '$colors$transparent',
  $$speakerLabel__borderColor_current: '$colors$transparent',
  $$speakerLabel__borderColor_unvisited: '$colors$white-alpha-06',
  $$speakerLabel__borderColor_visited: '$colors$white-alpha-10',
  $$speakerLabel__color_current: '$colors$gray-900',
  $$speakerLabel__color_unvisited: '$colors$white-alpha-06',
  $$speakerLabel__color_visited: '$colors$white-alpha-20',
  $$translation__color: '$colors$gray-700',
  $$translation__color_visited: '$colors$gray-300',
  $$transport__backgroundColor: '$colors$gray-800',
  $$transport__buttonColor: '$colors$white',
  $$transport__buttonColor_active: '$colors$teal-500',
  $$transport__buttonColor_disabled: '$colors$gray-700',
  $$transport__pendingPauseColor: '$colors$gray-700',
  $$transport__pendingPauseProgressColor: '$colors$orange-700',
  $$transport__progressBackgroundColor: '$colors$gray-600',
  $$transport__progressCurrentColor: '$colors$teal-400',
  $$transport__progressFurthestColor: '$colors$teal-500-alpha-50',
  $$word__color_current: '$colors$teal-500',
  $$word__color_default: '$colors$white-alpha-03',
  $$word__color_pending: '$colors$orange-500-alpha-10',
  $$word__color_pendingCurrent: '$colors$orange-500',
  $$word__color_sic_visited: '$colors$gray-300',
  $$word__color_visited: '$colors$globalWhite',
  $$word__backgroundColor_vocabHighlight: '$colors$purple-200',
};

export const listenModeDarkPalette = {
  $$chapterTitle__color: '$colors$gray-500',
  $$chapterTitle__positionColor: '$colors$gray-300',
  $$endCard__backgroundColor: '$colors$white',
  $$endCard__dividerColor: '$colors$gray-100',
  $$endCard__primaryColor: '$colors$textPrimary',
  $$endCard__secondaryColor: '$colors$textSecondary',
  $$header__backgroundColor: '$colors$white',
  $$keyboardButton__hoverBackground: '$colors$black-alpha-10',
  $$keyboardButton__iconColor: '$colors$black-alpha-30',
  $$passageHint__borderColor: '$colors$black-alpha-10',
  $$passageHint__color_unvisited: '$colors$black-alpha-10',
  $$passageHint__color_visited: '$colors$gray-500',
  $$redacted__backgroundColor_current: '$colors$teal-200',
  $$redacted__backgroundColor_pendingCurrent: '$colors$orange-400',
  $$redacted__backgroundColor_remaining: '$colors$orange-100',
  $$redacted__backgroundColor_unvisited: '$colors$black-alpha-10',
  $$redacted__backgroundColor_visited: '$colors$black-alpha-20',
  $$script__backgroundColor_default: '$colors$globalBlack',
  $$scriptPlayingBackdrop__backgroundColor: '$colors$globalBlack-alpha-20',
  $$sentence__backgroundColor_current: '$colors$white',
  $$sentence__backgroundColor_default: '$colors$transparent',
  $$sentence__backgroundColor_hover: '$colors$globalWhite-alpha-06',
  $$sentence__inlineTranslationColor: '$colors$gray-500',
  $$sentence_toolbar__backgroundColor: '$colors$gray-25',
  $$sentence_toolbar__color: '$colors$gray-800',
  $$sentence_toolbar__shadowColor: '$transparent',
  $$spacer__backgroundColor: '$colors$yellow-500-alpha-20',
  $$spacer__backgroundColor_current: '$colors$teal-300',
  $$spacer__backgroundColor_pauseIcon: '$colors$orange-400',
  $$spacer__backgroundColor_pendingCurrent: '$colors$orange-400',
  $$spacer__backgroundColor_remaining: '$colors$orange-100',
  $$spacer__backgroundColor_unvisited: '$colors$black-alpha-06',
  $$speakerLabel__backgroundColor_current: '$colors$white',
  $$speakerLabel__backgroundColor_unvisited: '$colors$transparent',
  $$speakerLabel__backgroundColor_visited: '$colors$transparent',
  $$speakerLabel__borderColor_current: '$colors$transparent',
  $$speakerLabel__borderColor_unvisited: '$colors$black-alpha-06',
  $$speakerLabel__borderColor_visited: '$colors$black-alpha-06',
  $$speakerLabel__color_current: '$colors$gray-500',
  $$speakerLabel__color_unvisited: '$colors$black-alpha-10',
  $$speakerLabel__color_visited: '$colors$gray-200',
  $$translation__color: '$colors$black-alpha-06',
  $$translation__color_visited: '$colors$gray-500',
  $$transport__backgroundColor: '$colors$globalBlack',
  $$transport__buttonColor: '$colors$gray-800',
  $$transport__buttonColor_active: '$colors$teal-500',
  $$transport__buttonColor_disabled: '$colors$gray-100',
  $$transport__pendingPauseColor: '$colors$gray-100',
  $$transport__pendingPauseProgressColor: '$colors$orange-400',
  $$transport__progressBackgroundColor: '$colors$gray-25',
  $$transport__progressCurrentColor: '$colors$teal-400',
  $$transport__progressFurthestColor: '$colors$teal-500-alpha-30',
  $$word__color_current: '$colors$teal-500',
  $$word__color_default: '$colors$black-alpha-10',
  $$word__color_pending: '$colors$orange-100',
  $$word__color_pendingCurrent: '$colors$orange-500',
  $$word__color_sic_visited: '$colors$textSecondary',
  $$word__color_visited: '$colors$textPrimary',
  $$word__backgroundColor_vocabHighlight: '$colors$purple-100',
} as const;

// doesn't really do anything, just a TS helper
export function paletteKey(key: keyof PaletteShape) {
  return key;
}
