import React from 'react';
import { styled } from 'naan/stitches.config';
import { Message } from 'components/ui/message';
import { PageHeading } from 'components/page-heading';
import { useNavigate } from 'react-router-dom';
import { learnHomePath } from 'components/nav/path-helpers';
import { PlainMarkdownWithLinks } from '@naan/primitives/text';

import __ from 'core/lib/localization';
import { AppFactory } from '@app/app-factory';

const Wrapper = styled('div', {
  width: 'min(720px, calc(100% - 32px))',
  margin: '$space$20 auto',
  a: {
    color: '$teal-500',
  },
});
export const AccountClosedScreen = () => {
  const navigate = useNavigate();
  const { productName } = AppFactory.root.storyManager;

  return (
    <Wrapper>
      <PageHeading
        showBackArrow={true}
        backAction={() => navigate(learnHomePath(), { replace: true })}
        backTitle={__('Go back', 'goBack')}
      />
      <Message
        type={'success'}
        title={__('Your account has been closed', 'yourAccountHasBeenClosed')}
        body={
          <PlainMarkdownWithLinks
            source={__(
              `Thank you for using %{productName}. Please take a moment to [share your feedback](mailto:feedback@jiveworld.com) - we'd love to know how we could improve.`,
              'thankYouForUsingJiveworldEspanol',
              {
                url: 'mailto:feedback@jiveworld.com',
                productName,
              }
            )}
          />
        }
      />
    </Wrapper>
  );
};
