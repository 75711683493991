import React from 'react';
import { NavLink } from 'react-router-dom';
import { devToolsPath } from 'components/nav/path-helpers';
import { alertSevereError } from '@app/notification-service';
import { createLogger } from '@common/log';
import { AppFactory } from '@app/app-factory';

const log = createLogger('sync-stripe-data');

//
// invoke node api which sync's price and discount data to stripe
//

interface LoaderState {
  result?: any;
  failure?: Error;
}

const handle = async (): Promise<any> => {
  const result = await AppFactory.caliServerInvoker.stripeSyncCatalog();
  log.info(`handle - result: ${JSON.stringify(result)}`);

  return result;
};

export const SyncStripeData = () => {
  const [loaderState, setLoaderState] = React.useState<LoaderState>({});
  const { result, failure } = loaderState;
  log.info(`render - result: ${String(!!result)}, failure: ${String(failure)}`);

  React.useEffect(
    () => {
      if (result === undefined && !failure) {
        handle()
          .then(result => setLoaderState({ result }))
          .catch(failure => setLoaderState({ failure }));
      }
      // should only ever run the effect once per page load
    } /*, [status, failure]*/
  );

  if (!result && !failure) {
    return <>loading...</>;
  }

  if (failure) {
    alertSevereError({ error: failure });
  }
  // if (status) {
  //   notifySuccess(status);
  // }

  return (
    <>
      <br />
      {result ? (
        <p>
          result:
          <pre>{JSON.stringify(result, null, 2)}</pre>
        </p>
      ) : null}
      {failure ? <p>failure: {String(failure)}</p> : null}
      <br />
      <NavLink to={devToolsPath() + '/catalog'}>
        [dev tools - catalog stuff]
      </NavLink>
    </>
  );
};
