import { IReactionPublic, reaction } from 'mobx';
import React from 'react';

export function useReaction<T>(
  expression: (r: IReactionPublic) => T,
  effect: (arg: T, prev: T, r: IReactionPublic) => void,
  deps: React.DependencyList = []
) {
  React.useEffect(() => {
    return reaction(expression, effect, { fireImmediately: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}
