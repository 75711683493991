const globalColors = {
  inherit: 'inherit',
  globalBlack: '#000000',
  globalWhite: '#ffffff',
  'globalBlack-alpha-03': 'rgba(0, 0, 0, 0.03)',
  'globalBlack-alpha-06': 'rgba(0, 0, 0, 0.06)',
  'globalBlack-alpha-10': 'rgba(0, 0, 0, 0.10)',
  'globalBlack-alpha-15': 'rgba(0, 0, 0, 0.15)',
  'globalBlack-alpha-20': 'rgba(0, 0, 0, 0.20)',
  'globalBlack-alpha-30': 'rgba(0, 0, 0, 0.30)',
  'globalBlack-alpha-40': 'rgba(0, 0, 0, 0.40)',
  'globalBlack-alpha-50': 'rgba(0, 0, 0, 0.50)',
  'globalBlack-alpha-60': 'rgba(0, 0, 0, 0.60)',
  'globalBlack-alpha-70': 'rgba(0, 0, 0, 0.70)',
  'globalBlack-alpha-80': 'rgba(0, 0, 0, 0.80)',
  'globalBlack-alpha-90': 'rgba(0, 0, 0, 0.90)',
  'globalWhite-alpha-03': 'rgba(255, 255, 255, 0.03)',
  'globalWhite-alpha-06': 'rgba(255, 255, 255, 0.06)',
  'globalWhite-alpha-10': 'rgba(255, 255, 255, 0.10)',
  'globalWhite-alpha-15': 'rgba(255, 255, 255, 0.15)',
  'globalWhite-alpha-20': 'rgba(255, 255, 255, 0.20)',
  'globalWhite-alpha-30': 'rgba(255, 255, 255, 0.30)',
  'globalWhite-alpha-40': 'rgba(255, 255, 255, 0.40)',
  'globalWhite-alpha-50': 'rgba(255, 255, 255, 0.50)',
  'globalWhite-alpha-60': 'rgba(255, 255, 255, 0.60)',
  'globalWhite-alpha-70': 'rgba(255, 255, 255, 0.70)',
  'globalWhite-alpha-80': 'rgba(255, 255, 255, 0.80)',
  'globalWhite-alpha-90': 'rgba(255, 255, 255, 0.90)',
} as const;

export const colors = {
  ...globalColors,
  black: '#000000',
  'dark-mode-header-darken': 'rgba(0, 0, 0, 0.00)',
  textPrimary: '#292929',
  textSecondary: '#808080',
  transparent: 'rgba(255, 255, 255, 0.00)',
  white: '#ffffff',
  'black-alpha-03': 'rgba(0, 0, 0, 0.03)',
  'black-alpha-06': 'rgba(0, 0, 0, 0.06)',
  'black-alpha-10': 'rgba(0, 0, 0, 0.10)',
  'black-alpha-15': 'rgba(0, 0, 0, 0.15)',
  'black-alpha-20': 'rgba(0, 0, 0, 0.20)',
  'black-alpha-30': 'rgba(0, 0, 0, 0.30)',
  'black-alpha-40': 'rgba(0, 0, 0, 0.40)',
  'black-alpha-50': 'rgba(0, 0, 0, 0.50)',
  'black-alpha-60': 'rgba(0, 0, 0, 0.60)',
  'black-alpha-70': 'rgba(0, 0, 0, 0.70)',
  'black-alpha-80': 'rgba(0, 0, 0, 0.80)',
  'black-alpha-90': 'rgba(0, 0, 0, 0.90)',
  'blue-100': '#d9e5f0',
  'blue-200': '#b3cce1',
  'blue-300': '#8cb2d2',
  'blue-400': '#6699c3',
  'blue-50': '#ebf2f7',
  'blue-500': '#3e7bae',
  'blue-500-alpha-50': 'rgba(62, 123, 174, 0.50)',
  'blue-600': '#3971a0',
  'blue-700': '#346792',
  'blue-800': '#2d597e',
  'blue-900': '#264c6c',
  'gray-100': '#e6e6e6',
  'gray-200': '#cccccc',
  'gray-25': '#f7f7f7',
  'gray-300': '#b3b3b3',
  'gray-400': '#999999',
  'gray-50': '#f2f2f2',
  'gray-500': '#808080',
  'gray-600': '#666666',
  'gray-700': '#4d4d4d',
  'gray-75': '#ececec',
  'gray-800': '#383838',
  'gray-800-alpha-90': 'rgba(56, 56, 56, 0.90)',
  'gray-900': '#292929',
  'green-100': '#daf3df',
  'green-200': '#b4e8b6',
  'green-300': '#8fdca0',
  'green-400': '#69d180',
  'green-50': '#ecf9ef',
  'green-500': '#44c560',
  'green-500-alpha-50': 'rgba(68, 197, 72, 0.50)',
  'green-600': '#3fb558',
  'green-700': '#39a551',
  'green-800': '#349649',
  'green-900': '#2e8641',
  'orange-100': '#fde6d5',
  'orange-200': '#fbcdaa',
  'orange-300': '#f9b580',
  'orange-400': '#f79c55',
  'orange-50': '#fef2e9',
  'orange-500': '#f5832b',
  'orange-500-alpha-10': 'rgba(245, 131, 43, 0.10)',
  'orange-500-alpha-20': 'rgba(245, 131, 43, 0.20)',
  'orange-600': '#dd7627',
  'orange-700': '#c46922',
  'orange-800': '#ac5c1e',
  'orange-900': '#934f1a',
  'pink-100': '#f8e1f1',
  'pink-200': '#f0c2e3',
  'pink-300': '#e9a4d6',
  'pink-400': '#e185c8',
  'pink-50': '#fbf0f8',
  'pink-500': '#da67ba',
  'pink-600': '#c95fab',
  'pink-700': '#b7569c',
  'pink-800': '#a64e8d',
  'pink-900': '#94467e',
  'purple-100': '#f4e1ff',
  'purple-200': '#e8c4ff',
  'purple-300': '#dda6ff',
  'purple-400': '#d189ff',
  'purple-50': '#f9f0ff',
  'purple-500': '#c66bff',
  'purple-500-alpha-50': 'rgba(186, 102, 250, 0.50)',
  'purple-600': '#b557ef',
  'purple-700': '#a24ad9',
  'purple-800': '#8d44bb',
  'purple-900': '#744c8d',
  'red-100': '#ffdddc',
  'red-200': '#ffbab9',
  'red-300': '#ff9a9a',
  'red-400': '#ff7878',
  'red-50': '#ffeded',
  'red-500': '#ff534f',
  'red-500-alpha-10': 'rgba(255, 83, 79, 0.10)',
  'red-500-alpha-20': 'rgba(255, 83, 79, 0.20)',
  'red-500-alpha-30': 'rgba(255, 83, 79, 0.30)',
  'red-500-alpha-40': 'rgba(255, 83, 79, 0.40)',
  'red-500-alpha-50': 'rgba(255, 83, 79, 0.50)',
  'red-500-alpha-60': 'rgba(255, 83, 79, 0.60)',
  'red-600': '#eb4c49',
  'red-70': '#ffe5e5',
  'red-700': '#cc4545',
  'red-800': '#b33c3c',
  'red-900': '#993434',
  'teal-100': '#d1f1f6',
  'teal-200': '#a3e4ee',
  'teal-300': '#75d6e5',
  'teal-400': '#47c9dd',
  'teal-50': '#e8f8fb',
  'teal-500': '#19bbd4',
  'teal-500-alpha-30': 'rgba(25, 187, 212, 0.30)',
  'teal-500-alpha-50': 'rgba(25, 187, 212, 0.50)',
  'teal-600': '#17acc3',
  'teal-700': '#1496aa',
  'teal-800': '#128395',
  'teal-900': '#0f707f',
  'white-alpha-03': 'rgba(255, 255, 255, 0.03)',
  'white-alpha-06': 'rgba(255, 255, 255, 0.06)',
  'white-alpha-10': 'rgba(255, 255, 255, 0.10)',
  'white-alpha-15': 'rgba(255, 255, 255, 0.15)',
  'white-alpha-20': 'rgba(255, 255, 255, 0.20)',
  'white-alpha-30': 'rgba(255, 255, 255, 0.30)',
  'white-alpha-40': 'rgba(255, 255, 255, 0.40)',
  'white-alpha-50': 'rgba(255, 255, 255, 0.50)',
  'white-alpha-60': 'rgba(255, 255, 255, 0.60)',
  'white-alpha-70': 'rgba(255, 255, 255, 0.70)',
  'white-alpha-80': 'rgba(255, 255, 255, 0.80)',
  'white-alpha-90': 'rgba(255, 255, 255, 0.90)',
  'yellow-100': '#fef3cc',
  'yellow-150': '#ffffff',
  'yellow-200': '#fde899',
  'yellow-300': '#fddc66',
  'yellow-400': '#f9d23b',
  'yellow-50': '#fff9e5',
  'yellow-500': '#fbc500',
  'yellow-500-alpha-20': 'rgba(251, 197, 0, 0.20)',
  'yellow-600': '#e7b600',
  'yellow-700': '#c69f08',
  'yellow-800': '#ad8c07',
  'yellow-900': '#947706',
} as const;

type PaletteKeys = keyof typeof colors;

export const darkColors: Record<PaletteKeys, string> = {
  ...globalColors,
  inherit: 'inherit',
  black: '#ffffff',
  'dark-mode-header-darken': 'rgba(0, 0, 0, 0.30)',
  textPrimary: '#ffffff',
  textSecondary: '#cccccc',
  transparent: 'rgba(255, 255, 255, 0.00)',
  white: '#1a1a1a',
  'black-alpha-03': 'rgba(255, 255, 255, 0.03)',
  'black-alpha-06': 'rgba(255, 255, 255, 0.06)',
  'black-alpha-10': 'rgba(255, 255, 255, 0.10)',
  'black-alpha-15': 'rgba(255, 255, 255, 0.15)',
  'black-alpha-20': 'rgba(255, 255, 255, 0.20)',
  'black-alpha-30': 'rgba(255, 255, 255, 0.30)',
  'black-alpha-40': 'rgba(255, 255, 255, 0.40)',
  'black-alpha-50': 'rgba(255, 255, 255, 0.50)',
  'black-alpha-60': 'rgba(255, 255, 255, 0.60)',
  'black-alpha-70': 'rgba(255, 255, 255, 0.70)',
  'black-alpha-80': 'rgba(255, 255, 255, 0.80)',
  'black-alpha-90': 'rgba(255, 255, 255, 0.90)',
  'blue-100': '#25313c',
  'blue-200': '#294156',
  'blue-300': '#2c5170',
  'blue-400': '#30618a',
  'blue-50': '#23292f',
  'blue-500': '#3471a4',
  'blue-500-alpha-50': 'rgba(62, 123, 174, 0.50)',
  'blue-600': '#598ab2',
  'blue-700': '#7ea3c1',
  'blue-800': '#a3bcd0',
  'blue-900': '#c8d5df',
  'gray-100': '#383838',
  'gray-200': '#505050',
  'gray-25': '#222222',
  'gray-300': '#7d7d7d',
  'gray-400': '#9e9e9e',
  'gray-50': '#2a2a2a',
  'gray-500': '#b5b5b5',
  'gray-600': '#cccccc',
  'gray-700': '#d6d6d6',
  'gray-75': '#313131',
  'gray-800': '#e5e5e5',
  'gray-800-alpha-90': 'rgba(56, 56, 56, 0.90)',
  'gray-900': '#efefef',
  'green-100': '#26402c',
  'green-200': '#2b5f36',
  'green-300': '#307d41',
  'green-400': '#359c4b',
  'green-50': '#243127',
  'green-500': '#3abb56',
  'green-500-alpha-50': 'rgba(68, 197, 72, 0.50)',
  'green-600': '#5ec574',
  'green-700': '#82cf92',
  'green-800': '#a6d9b1',
  'green-900': '#cae3cf',
  'orange-100': '#4a3321',
  'orange-200': '#724421',
  'orange-300': '#9a5621',
  'orange-400': '#c26721',
  'orange-50': '#362a21',
  'orange-500': '#eb7921',
  'orange-500-alpha-10': 'rgba(245, 131, 43, 0.10)',
  'orange-500-alpha-20': 'rgba(245, 131, 43, 0.20)',
  'orange-600': '#eb904a',
  'orange-700': '#eca773',
  'orange-800': '#ecbf9c',
  'orange-900': '#edd6c5',
  'pink-100': '#442d3e',
  'pink-200': '#67395a',
  'pink-300': '#8a4577',
  'pink-400': '#ad5193',
  'pink-50': '#332730',
  'pink-500': '#d05db0',
  'pink-600': '#d67abc',
  'pink-700': '#dc97c8',
  'pink-800': '#e2b4d5',
  'pink-900': '#e8d1e1',
  'purple-100': '#402e4c',
  'purple-200': '#5f3b76',
  'purple-300': '#7e47a0',
  'purple-400': '#9d54ca',
  'purple-50': '#312837',
  'purple-500': '#bc61f5',
  'purple-500-alpha-50': 'rgba(186, 102, 250, 0.50)',
  'purple-600': '#c67df3',
  'purple-700': '#d099f2',
  'purple-800': '#dab5f0',
  'purple-900': '#e4d1ef',
  'red-100': '#763130',
  'red-200': '#763130',
  'red-300': '#a03937',
  'red-400': '#ca413e',
  'red-50': '#372525',
  'red-500': '#f54945',
  'red-500-alpha-10': 'rgba(255, 83, 79, 0.10)',
  'red-500-alpha-20': 'rgba(255, 83, 79, 0.20)',
  'red-500-alpha-30': 'rgba(255, 83, 79, 0.30)',
  'red-500-alpha-40': 'rgba(255, 83, 79, 0.40)',
  'red-500-alpha-50': 'rgba(255, 83, 79, 0.50)',
  'red-500-alpha-60': 'rgba(255, 83, 79, 0.60)',
  'red-600': '#f36a66',
  'red-70': '#372525',
  'red-700': '#f28b88',
  'red-800': '#f0acaa',
  'red-900': '#efcdcc',
  'teal-100': '#1e3e43',
  'teal-200': '#1a5b65',
  'teal-300': '#167786',
  'teal-400': '#1294a8',
  'teal-50': '#203032',
  'teal-500': '#0fb1ca',
  'teal-500-alpha-30': 'rgba(25, 187, 212, 0.30)',
  'teal-500-alpha-50': 'rgba(25, 187, 212, 0.50)',
  'teal-600': '#3bbdd1',
  'teal-700': '#68c9d8',
  'teal-800': '#94d5df',
  'teal-900': '#c1e1e6',
  'white-alpha-03': 'rgba(0, 0, 0, 0.03)',
  'white-alpha-06': 'rgba(0, 0, 0, 0.06)',
  'white-alpha-10': 'rgba(0, 0, 0, 0.10)',
  'white-alpha-15': 'rgba(0, 0, 0, 0.15)',
  'white-alpha-20': 'rgba(0, 0, 0, 0.20)',
  'white-alpha-30': 'rgba(0, 0, 0, 0.30)',
  'white-alpha-40': 'rgba(0, 0, 0, 0.40)',
  'white-alpha-50': 'rgba(0, 0, 0, 0.50)',
  'white-alpha-60': 'rgba(0, 0, 0, 0.60)',
  'white-alpha-70': 'rgba(0, 0, 0, 0.70)',
  'white-alpha-80': 'rgba(0, 0, 0, 0.80)',
  'white-alpha-90': 'rgba(0, 0, 0, 0.90)',
  'yellow-100': '#4b401b',
  'yellow-150': '#ffffff',
  'yellow-200': '#745f14',
  'yellow-300': '#9e7d0d',
  'yellow-400': '#c79c06',
  'yellow-50': '#36311e',
  'yellow-500': '#f1bb00',
  'yellow-500-alpha-20': 'rgba(251, 197, 0, 0.20)',
  'yellow-600': '#f0c52f',
  'yellow-700': '#efcf5f',
  'yellow-800': '#efd98e',
  'yellow-900': '#eee3be',
};
