import * as React from 'react';

export function ArrowUpExtraSmallIcon({
  width = 16,
  height = 16,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.99716 5.87126L8.99716 13.957L6.99716 13.957L6.99716 5.87123L4.70425 8.16414L3.29004 6.74992L6.99716 3.0428L6.99716 2.95703L7.08293 2.95703L7.29004 2.74992L7.99715 2.04282L8.70425 2.74992L8.91136 2.95703L8.99716 2.95703L8.99716 3.04283L12.7043 6.74992L11.29 8.16414L8.99716 5.87126Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </svg>
  );
}
