import React from 'react';
import { MobileHeaderCell } from './mobile-header-cell';

export const ClassStudentsCell = ({
  studentCount,
  cell,
}: {
  studentCount: string;
  cell: any;
}) => {
  return (
    <MobileHeaderCell header={cell.headerLabel}>
      <span data-test-id="students-count">{studentCount}</span>
    </MobileHeaderCell>
  );
};
