import * as React from 'react';
import * as Dialog from '@naan/primitives/modals/dialog';
import type { FeedbackMetadata } from './feedback-metadata';
import { Textarea } from '@naan/primitives/input';
import { styled } from '@naan/stitches.config';
import { AppFactory } from '@app/app-factory';
import { bugsnagNotify, NotificationService } from '@app/notification-service';
import { SpinnerIcon } from '../icons/spinner-icon';
import { keyboardService } from 'lib/services/keyboard-service';
import { track } from '@app/track';
import { events } from '@common/instrumentation/events';

import __ from '@core/lib/localization';

const FeedbackDialogWrapper = styled('div', {
  height: 400,
  display: 'grid',
  '&:has(.context)': {
    gridTemplateRows: 'auto 1fr',
  },
  gap: 24,
  '& .context': {
    borderLeft: '4px solid $colors$gray-100',
    paddingLeft: 12,
  },
  '& .form-area': {
    '& .textarea-container': {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      '& textarea': {
        flex: 1,
        display: 'flex',
        alignSelf: 'stretch',
        margin: 0,
      },
    },
  },
});

export const FeedbackDialog = ({
  onDismiss,
  afterSubmit,
  prompt,
  context,
  metadata,
}: {
  onDismiss?: () => void;
  afterSubmit: () => void;
  prompt?: React.ReactNode;
  context?: React.ReactNode;
  metadata: FeedbackMetadata;
}) => {
  const ref = React.useRef<HTMLTextAreaElement>(null);

  const [text, setText] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    track(events.feedback.openFeedback, {
      context: metadata.context,
    });
    return keyboardService.stopCurrentShortcutSet();
  }, [metadata.context]);

  const handleDismiss = React.useCallback(() => {
    track(events.feedback.closeFeedbackWithoutSending, {
      context: metadata.context,
    });
    onDismiss();
  }, [metadata.context, onDismiss]);

  const handleSubmit = React.useCallback(() => {
    if (ref.current) {
      const value = ref.current.value;

      if (value.trim().length > 0) {
        setLoading(true);
        const { type, context: internalContext, uglyContext } = metadata;

        // // @elliottjf I think we need to adjust the sendFeedback method
        // // to allow for the differnet types of feedback, which arent just stories
        // let storySlug = '';
        // if (type === 'chapter') {
        //   storySlug = metadata.chapter.story.slug;
        // } else if (type === 'story') {
        //   storySlug = metadata.story.slug;
        // }
        const storySlug = metadata.story?.slug;

        const { userManager } = AppFactory.root;

        const { offline } = AppFactory.root;

        if (offline) {
          NotificationService.open({
            message: __(
              "Looks like you're offline. Please try again.",
              'looksLikeYoureOffline'
            ),
            type: 'error',
          });
          setLoading(false);
          return;
        }

        userManager
          .sendFeedback({
            storySlug,
            context: `${type}: ${uglyContext || internalContext}`,
            feedback: value,
          })
          .then(() => {
            track(events.feedback.sendFeedback, {
              context: metadata.context,
            });
            afterSubmit();
          })
          .catch(error => {
            // TODO: end-user error UX
            bugsnagNotify(error);
            NotificationService.open({
              message: __('Feedback failed to send', 'feedbackFailedToSend'),
              type: 'error',
            });
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        // @elliottjf We may need to think of validation here
        // je: i asked frank to flush out the specs
        // eslint-disable-next-line no-console
        console.error('Feedback is empty');
      }
    }
  }, [ref, afterSubmit, metadata]);

  const disableButton =
    text.trim().length === 0 || AppFactory.root.offline || loading;

  return (
    <Dialog.Container
      open
      onDismiss={() => {
        handleDismiss();
      }}
    >
      <Dialog.Heading>{__('Give feedback', 'giveFeedback')}</Dialog.Heading>
      <Dialog.CloseButton />
      <Dialog.Body>
        <FeedbackDialogWrapper>
          {prompt ? <div className="prompt">{prompt}</div> : null}
          {context ? <div className="context">{context}</div> : null}
          <div className="form-area">
            <Textarea
              ref={ref}
              placeholder={__('Share your thoughts', 'shareYourThoughts')}
              value={text}
              onChange={e => {
                setText(e.target.value);
              }}
            />
          </div>
        </FeedbackDialogWrapper>
      </Dialog.Body>
      <Dialog.ButtonsContainer>
        <Dialog.Button
          presentation={'teal'}
          onClick={handleSubmit}
          label={loading ? '' : __('Submit', 'submit')}
          rightIcon={loading ? <SpinnerIcon /> : undefined}
          disabled={disableButton}
        ></Dialog.Button>
      </Dialog.ButtonsContainer>
    </Dialog.Container>
  );
};
