import * as React from 'react';
import { pick } from './pick';

type TLargePresentation = 'color' | 'grayscale';

export const StrategiesIconLarge = ({
  presentation = 'color',
}: {
  presentation?: TLargePresentation;
}) => {
  const color = pick(presentation, {
    color: 'var(--colors-gray-800)',
    grayscale: '#999',
  });

  return (
    <svg
      width={72}
      height={72}
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M37.7889 9.84887C37.31 7.93327 34.6761 5.81812 33.419 5C33.419 6.49657 33.3471 9.62139 33.0598 10.1482C33.0598 10.1482 29.0001 12.5 25.1579 15.7753C21.3158 19.0505 21.5776 20.0422 20.5001 21C19.4225 21.9578 15.2806 25.1138 14.4425 25.9519C13.6045 26.79 13.5446 29.7233 14.4425 31.579C15.3405 33.4347 16.5377 34.0334 19.471 34.4524C22.4043 34.8714 24.0205 31.6389 25.6368 31.2198C27.2531 30.8008 30.9047 32.2375 32.3414 32.2973C33.4908 32.3452 34.696 31.998 35.155 31.8184C32.9401 34.3127 27.8158 40.307 25.0382 44.3297C22.2606 48.3525 22.4043 53.4289 22.8233 55.4642C21.7059 55.5065 19.471 56.2602 19.471 58.9362V66H51.7968V59.475C51.7968 56.655 48.9633 55.6261 47.5466 55.4642C46.7085 47.4426 54.0117 41.0972 52.6349 27.1492C51.5334 15.9908 42.2786 10.9663 37.7889 9.84887Z"
        fill={color}
      />
    </svg>
  );
};

export const StrategiesIconSmall = ({
  presentation = 'color',
}: {
  presentation?: 'color' | 'grayscale' | 'dark';
}) => {
  const color = pick(presentation, {
    color: 'var(--colors-gray-800)',
    grayscale: '#999',
    dark: 'var(--colors-globalWhite)',
  });

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.455 1.71875C13.2889 1.02788 12.3272 0.317308 11.7248 0C11.7248 0.539745 11.6999 1.66673 11.6001 1.85672C11.6001 1.85672 10.1915 2.70492 8.85828 3.88616C7.5251 5.06741 7.61594 5.42506 7.24205 5.77049C6.86816 6.11593 5.43095 7.25417 5.14014 7.55643C4.84934 7.85869 4.82857 8.91658 5.14014 9.58587C5.45172 10.2552 5.86715 10.4711 6.88497 10.6222C7.90278 10.7733 8.46362 9.60746 9.02445 9.45633C9.58529 9.3052 10.8524 9.82336 11.3509 9.84495C11.7497 9.86222 12.1679 9.737 12.3272 9.67223C11.5586 10.5718 9.78055 12.7337 8.81674 14.1845C7.85293 15.6353 7.90278 17.4661 8.04818 18.2002C7.66045 18.2155 7.02957 18.4964 7.02957 19.4615V22H18.0296V19.6467C18.0296 18.6297 17.1185 18.2586 16.6269 18.2002C16.3361 15.3072 18.8702 13.0186 18.3925 7.98822C18.0103 3.96389 15.0129 2.12176 13.455 1.71875Z"
        fill={color}
      />
    </svg>
  );
};
