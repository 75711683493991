import * as React from 'react';
import { styled } from 'naan/stitches.config';
import { useForm } from 'react-hook-form';
import { AlertDialog } from './alert-dialog';
import { FormError } from '../forms';

const Wrapper = styled('div', {
  minWidth: '360px',
  textStyle: 'body',
  '.hint': {
    paddingBottom: '16px',
  },
  '& input, & textarea': {
    flex: '1',
    width: '100%',
    display: 'block',
    background: '$white',
    border: '1px solid $gray-100',
    boxSizing: 'border-box',
    borderRadius: '$radii$small',
    padding: '12px 16px',

    '&::placeholder': {
      color: '$gray-300',
    },

    '&:focus': {
      borderColor: '"teal-500"',
      outline: 'none',
    },
  },

  textarea: {
    height: '142px',
    resize: 'none',
  },
});

type PromptProps = {
  open: boolean;
  label?: string;
  initialValue?: string;
  placeholder?: string;
  okButtonAction: (value: string) => void;
  cancelButtonAction: (value: string) => void;
  showClearButton?: boolean;
  type: 'text' | 'textarea';
  validation: any; // TODO: check this
  hint?: React.ReactNode;
  error: any;
};

export const Prompt = ({
  open = true,
  label,
  initialValue = '',
  placeholder = '',
  okButtonAction = () => {},
  cancelButtonAction = () => {},
  showClearButton = false,
  type,
  validation = {},
  hint,
  error: externalError,
}: PromptProps) => {
  const inputRef = React.useRef<HTMLFormElement | undefined>();

  const form = useForm({
    defaultValues: { value: initialValue },
  });

  const { handleSubmit, register, watch, /* setValue, */ reset } = form;

  const { formState } = form;
  const { errors: localValidationErrors } = formState;

  const inputName = 'value';

  const submitHandler = handleSubmit(({ value }) => {
    return okButtonAction(value);
  });

  /// the result changed from v5 to v6, not sure if it and how it impacts us.
  const valueWatch = watch(inputName);

  const handleCancelButton = () => {
    reset();
    return cancelButtonAction(valueWatch);
  };

  React.useEffect(() => {
    if (inputRef.current && inputRef.current[0]) {
      const input = inputRef.current[0] as HTMLInputElement;
      input.focus();
      input.select();
    }
    // console.log(inputRef);
  }, []);

  // const clear = () => {
  //   if (valueWatch) {
  //     setValue(inputName, '', { shouldValidate: true });
  //   }
  // };

  return (
    <AlertDialog
      open={open}
      heading={label}
      okIsDisabled={!valueWatch}
      okButtonAction={submitHandler}
      cancelButtonAction={handleCancelButton}
      body={
        <Wrapper>
          <form onSubmit={submitHandler} ref={inputRef as any}>
            {hint ? <div className="hint">{hint}</div> : null}
            {type === 'textarea' ? (
              <textarea
                placeholder={placeholder}
                {...register(inputName, validation)}
                data-test-id="prompt-input-textarea"
              />
            ) : (
              <input
                placeholder={placeholder}
                type={type}
                {...register(inputName, validation)}
                data-test-id="prompt-input-value"
              />
            )}
            {localValidationErrors?.value ? (
              <FormError
                error={localValidationErrors?.value?.message}
                maxHeight={false}
              />
            ) : null}
            {externalError ? (
              <FormError error={externalError} maxHeight={false} />
            ) : null}
          </form>
        </Wrapper>
      }
    />
  );
};
