import * as React from 'react';
import { styled } from '@naan/stitches.config';
import { ProgressGaugeBar } from './progress-gauge-bar';
// import { ProgressCard } from './progress-card';
import { Button } from '@naan/primitives/button';
import { createLogger } from '@common/log';
import { observer } from 'mobx-react';
import { useChapterReviewModel } from 'vocab-review/review-ui';
import { PointsIcon } from '@naan/icons/points-icon';

import __ from '@core/lib/localization';

const log = createLogger('study-ui');

const Wrapper = styled('div', {
  opacity: 0,
  transition: 'opacity 0.3s ease-in-out',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  height: '100%',
  overflow: 'auto',
  paddingTop: 'var(--top-bar-height)',
  '&.visible': {
    opacity: 1,
  },
  '& .inner': {
    padding: '32px 16px 16px',
    background: '$white',
    flex: 1,
    display: 'grid',
    gridAutoFlow: 'row',
    gridTemplateRows: '1fr auto',

    '& .top': {
      display: 'grid',
      placeItems: 'center',
    },
    '& .bottom': {
      display: 'flex',
      flexDirection: 'column',
      gap: 12,
    },
    '& .progress-gauge-wrapper': {
      padding: '0 16px 16px',
    },

    '@widerThanScript': {
      height: 'min(100% - 32px, 700px)',
      flex: 'none',
      borderRadius: '$radii$large',
      boxShadow: '0px 1px 6px 0px rgba(0, 0, 0, 0.20)',
      // this margin is necessary otherwise the shadow will be clipped in the parent scrolling container
      margin: '0 6px',
      paddingTop: 48,
      '& .progress-gauge-wrapper': {
        padding: '0 24px 24px',
      },
    },
  },

  '& .card-heading': {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: 12,

    '& .checkmark-icon': {
      color: '$green-500',
      '& svg': {
        width: 80,
        height: 80,
      },
    },

    '& h2': {
      color: '$textPrimary',
      textStyle: 'medium-heading',
    },

    '& .new-points': {
      borderRadius: 20,
      gap: 4,
      padding: '8px 16px',
      display: 'flex',
      textStyle: 'small-heading',
      background: '$yellow-200',
      '& .points-icon': {
        color: '$yellow-500',
        marginRight: -4,
      },
    },
  },
});

const CheckmarkIcon = () => (
  <svg
    width={97}
    height={96}
    viewBox="0 0 97 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M92.5 48C92.5 72.3005 72.8005 92 48.5 92C24.1995 92 4.5 72.3005 4.5 48C4.5 23.6995 24.1995 4 48.5 4C72.8005 4 92.5 23.6995 92.5 48Z"
      stroke="#44C560"
      strokeWidth={8}
    />
    <path
      d="M28.7949 52L44.9633 63.5L66.6897 33"
      stroke="#44C560"
      strokeWidth={8}
    />
  </svg>
);

export const ReviewEndCard = observer(() => {
  // const model = AppFactory.chapterReviewModel;
  const model = useChapterReviewModel();
  const { vocabReviewModel } = model;
  const {
    chapterLearnedCount,
    chapterReviewAgainCount,
    chapterJustAddedCount,
  } = vocabReviewModel;
  const itemsReviewed = chapterLearnedCount + chapterReviewAgainCount;
  const itemsPoints = itemsReviewed * 5;

  React.useEffect(() => {
    log.debug(`ReviewEndCard mounted`);
  }, []);

  return (
    <Wrapper className={model.endOfChapter ? 'visible' : ''}>
      <div className="inner">
        <div className="top">
          <div className="card-heading">
            <div className="checkmark-icon">
              <CheckmarkIcon />
            </div>
            <h2>{__('Chapter complete', 'chapterComplete')}</h2>
            <div className="new-points">
              <span>+</span>
              <span className="points-icon">
                <PointsIcon />
              </span>
              <span>{itemsPoints}</span>
            </div>
          </div>
        </div>
        <div className="bottom">
          <div className="progress-gauge-wrapper">
            <ProgressGaugeBar
              learned={chapterLearnedCount}
              toReview={chapterReviewAgainCount}
              added={chapterJustAddedCount}
            />
          </div>
          <Button
            presentation={'teal'}
            onClick={() => {
              model.vocabReviewModel.nextChapter();
            }}
            size="extraLarge"
            label={__('Continue', 'continue')}
            fullWidth
          />
        </div>
      </div>
    </Wrapper>
  );
});
