import React from 'react';
import { AppFactory } from 'app/app-factory';
import { CollectionWidget } from './collection-widget';
import { FilterWidget } from './filter-widget';
import { MediumGap } from '@naan/primitives/spacer/gaps';
import { createLogger } from '@common/log';
import { namedObserver } from 'lib/named-observer';
import { SoundbiteStoriesWidget } from './soundbite-stories-widget';
import { ChannelWidget } from './channel-widget';

const log = createLogger('featured-content');

export const FeaturedContent = namedObserver('FeaturedContent', () => {
  const { storyManager, isMultiChannel } = AppFactory.root;

  log.trace(
    `catalog url: ${storyManager.catalogUrl}, version: ${storyManager.version}, generated at: ${storyManager.generatedAt}`
  );

  return (
    <>
      {isMultiChannel ? (
        <MultiChannelFeaturedContent />
      ) : (
        <SingleChannelFeaturedContent />
      )}
    </>
  );
});

const MultiChannelFeaturedContent = namedObserver(
  'MultiChannelFeaturedContent',
  () => {
    const { storyManager } = AppFactory.root;

    return (
      <>
        <SoundbiteStoriesWidget />
        <MediumGap />
        {storyManager.channelsWithStories.map(channel => {
          return (
            <React.Fragment key={channel?.slug}>
              <ChannelWidget channel={channel} />
              <MediumGap />
            </React.Fragment>
          );
        })}
        <CollectionWidget collection={storyManager.featuredCollection} />
      </>
    );
  }
);

const SingleChannelFeaturedContent = namedObserver(
  'SingleChannelFeaturedContent',
  () => {
    const { storyManager } = AppFactory.root;

    return (
      <>
        <SoundbiteStoriesWidget />
        <MediumGap />
        {/* // todo: omit already featured stories? */}
        {storyManager.featuredFilters.map(widgetData => (
          <React.Fragment key={widgetData?.slug}>
            <FilterWidget widgetData={widgetData} />
            <MediumGap />
          </React.Fragment>
        ))}
        <CollectionWidget collection={storyManager.featuredCollection} />
      </>
    );
  }
);
