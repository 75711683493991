import * as React from 'react';

export function CheckmarkSuperHeavyIcon({
  width = 20,
  height = 20,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.6416 4.64211L9.502 17.7802L1.36133 12.1444L3.63817 8.85559L8.49749 12.2197L15.3579 2.35785L18.6416 4.64211Z"
        fill={color}
      />
    </svg>
  );
}
