import * as React from 'react';
import { ToastService } from 'common/notifications/toast-service';
import { Story } from 'core/models/story-manager';
import { alertWarningError } from '@app/notification-service';
import { track } from '@app/track';
import { events } from '@common/instrumentation/events';

import __ from 'core/lib/localization';

export const useFavoriteToggle = (story: Story) => {
  const favoriteToggle = React.useCallback(() => {
    story
      .toggleClassroomFavorite()
      .then(() => {
        track(events.classroom.favoriteStory, {
          story: story.title,
        });
      })
      .catch(error =>
        alertWarningError({ error, note: 'use-favorite-toggle' })
      );
    if (!story.isClassroomFavorited) {
      // @frank do we even care about showing a toast here?
      ToastService.open({
        message: __('Removed from favorites', 'favoriteRemoved'),
        type: 'success',
      });
    }
  }, [story]);

  return { favoriteToggle };
};
