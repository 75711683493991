/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { useParams } from 'react-router-dom';
import { track } from 'app/track';
import { MarkSimple } from '@naan/primitives/text';
import { styled } from '@naan/stitches.config';
import { JiveworldLogo } from 'components/branding/jiveworld-logo';
import { PrinterIcon } from '@naan/icons/printer-icon';
import { IconButton } from '@naan/primitives/button';
import { ArrowLeftIcon } from '@naan/icons/arrow-left-icon';
// import { subBrand } from '@core/lib/app-util';
import { AppFactory } from '@app/app-factory';

const HeaderWrapper = styled('header', {
  padding: '12px 0',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  top: '0',
  background: '#fff',
  borderBottom: '1px solid var(--gray100)',
  marginBottom: '-1px',
  position: 'sticky',
  zIndex: '1000',

  '& .logo': {
    display: 'flex',
    alignItems: 'flex-end',
    textDecoration: 'none',
    color: '$colors$gray-400',
    cursor: 'default',
    userSelect: 'none',
    svg: {
      width: 130,
      height: 29,
    },
  },
  '& .logo-suffix': {
    textStyle: 'small-caps',
    color: '$colors$gray-400',
    marginLeft: '5px',
    position: 'relative',
    top: '1px',
  },
  '& .title': {
    font: 'var(--body-font)',
    color: 'var(--text-secondary)',
    marginLeft: 'auto',
    paddingLeft: '24px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  '& .print': {
    marginLeft: '20px',
    cursor: 'pointer',
    color: '$colors$gray-400',
  },

  '@media print': {
    paddingTop: '0',
    /* otherwise it renders on random places of the page */
    position: 'static',

    '& .print': {
      display: 'none',
    },
  },
});

export const PageHeader = ({ title }: { title: string }) => {
  const { slug } = useParams();
  const handlePrintButton = () => {
    window.print();
    track('storyscript__print', { story_slug: slug });
  };

  const { storyManager } = AppFactory.root;
  const isPwa = window.matchMedia('(display-mode: standalone)').matches;

  return (
    <HeaderWrapper>
      {isPwa ? (
        <IconButton
          icon={<ArrowLeftIcon />}
          onClick={() => {
            window.close();
          }}
          testId="guides-close-button"
        ></IconButton>
      ) : null}
      <span className="logo">
        <JiveworldLogo width={130} height={29} />
        <span className="logo-suffix">{storyManager.subBrand()}</span>
      </span>
      <h1 className="title">
        <MarkSimple source={title} />
      </h1>
      <button className="print" onClick={handlePrintButton}>
        <PrinterIcon />
      </button>
    </HeaderWrapper>
  );
};
