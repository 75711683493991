import * as React from 'react';
import { observer } from 'mobx-react';
import { Divider } from 'naan/primitives/divider';
import { VStack } from 'naan/primitives/stack';
import { Story } from 'core/models/story-manager';
import { StoryCard } from './story-card';

export const StoryList = observer(({ stories }: { stories: Story[] }) => {
  return (
    <>
      <VStack>
        {stories?.map((story, index) => {
          const isLast = index === stories.length - 1;
          return (
            <React.Fragment key={story.slug}>
              <StoryCard story={story} truncateDescription={true} />
              {!isLast ? <Divider className="divider" /> : null}
            </React.Fragment>
          );
        })}
      </VStack>
    </>
  );
});
