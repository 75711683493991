import { ModelTreeNode, TSTStringMap, snap } from 'ts-state-tree/tst-core';
import { Currency } from '@cas-shared/cas-types';
import { EntitlementData } from './entitlement-data';
// import { LocaleCode } from '@utils/util-types';

export class NodeAccountData extends ModelTreeNode /*implements NodeAccountDataType entitlements map/object mismatched */ {
  static CLASS_NAME = 'NodeAccountData' as const;

  static create(snapshot: any = {}): NodeAccountData {
    return super.create(NodeAccountData, snapshot) as NodeAccountData;
  }

  _docId: string; // uuid
  userId: string; // server qualified
  email: string;

  stripeCustomerId?: string;
  currency?: Currency;

  // reflects offered discount for potential checkout
  discountSlug?: string;
  discountExpiry?: string; // iso8601 date for which offered discount pricing expires

  // entitlements: {
  //   // eslint-disable-next-line no-unused-vars
  //   [l2 in LocaleCode]?: EntitlementDataType;
  // };
  entitlements: TSTStringMap<EntitlementData> = snap({});
}
