import React from 'react';
import { VStack } from 'naan/primitives/stack';

import { AppFactory } from 'app/app-factory';
import { observer } from 'mobx-react';
import { CatalogSelection } from 'components/admin/catalog-selection';

import { UserManager } from '@core/models/user-manager';
import { MediumHeading } from '@naan/primitives/text';
import { Definition } from './components/definition';
import { NavLink } from 'react-router-dom';
// import { VSpacer } from '@naan/primitives/spacer';
import { adminPath, scaffoldedScriptPath } from 'components/nav/path-helpers';
// import { dayjsToIsoDate } from '@utils/date-utils';

export const path = 'catalog';
export const label = 'Catalog stuff';

export const Component = observer(() => {
  const { root } = AppFactory;
  const { userManager, storyManager } = root;

  const userManagerOtherData = userManager.snapshot as UserManager;
  userManagerOtherData.accountData = undefined;
  userManagerOtherData.userData = undefined;

  // const { storyManager } = AppFactory.root;
  // const { calendarEditions } = storyManager;

  const lastPath = window.location.pathname.split('/').pop();
  const currentStory = storyManager.story(lastPath);

  return (
    <VStack>
      <MediumHeading>Catalog Stuff</MediumHeading>
      <Definition label={'Catalog (cali)'} value={<CatalogSelection />} />
      {/* <VSpacer size={4} /> */}
      {/* <NavLink to={`${adminPath()}/sync-catalog-urls`}>
        [sync catalog urls]
      </NavLink>
      <br /> */}

      {currentStory ? (
        <NavLink to={scaffoldedScriptPath(currentStory)}>
          [scaffolded story script]
        </NavLink>
      ) : null}
      <br />
      <NavLink to={`${adminPath()}/sync-stripe-data`}>
        [sync stripe data]
      </NavLink>

      {/* <VSpacer size={4} />
      <NavLink to="/app/learn/en">/app/learn/en</NavLink>
      <NavLink to="/app/learn/es">/app/learn/es</NavLink>
      <VSpacer size={4} /> */}
      {/* <h3>Soundbite editions</h3>
      {calendarEditions.map(edition => (
        <p>
          {dayjsToIsoDate(edition.visibilityDayjs)} - {edition.story?.slug}
        </p>
      ))} */}
    </VStack>
  );
});
