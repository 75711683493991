import * as React from 'react';
import { AppFactory } from 'app/app-factory';
import { styled } from '@naan/stitches.config';
import { MarkSimple } from '@naan/primitives/text';
import classNames from 'classnames';
import { ChapterCatalogData } from '@core/models/catalog';
import { CIRCLE_RADIUS } from '../components/circle-icon';

import __ from '@core/lib/localization';

const TitleWrapper = styled('div', {
  padding: '16px 0',
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column',
  flex: 1,

  '& .title': {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textStyle: 'body-bold',
  },
  '& .position': {
    color: '$textSecondary',
    textStyle: 'small-caps',
    marginBottom: -4,
  },

  variants: {
    presentation: {
      current: {},
      unvisited: {
        color: '$colors$gray-200',
        '& .position': {
          color: '$gray-200',
        },
      },
    },
  },
});

const Title = ({
  chapter,
  presentation,
}: {
  presentation: Props['presentation'];
  chapter: ChapterCatalogData;
}) => {
  const { userManager } = AppFactory.root;
  const { title, position } = chapter;
  return (
    <TitleWrapper presentation={presentation}>
      <div className="position">
        {__('Chapter %{position}', 'chapterPosition', { position })}
        {userManager.showUglyDevUI ? (
          <b>&nbsp;({chapter.clockLikeDuration})</b>
        ) : null}
      </div>
      <div className="title">
        <MarkSimple source={title} />
      </div>
    </TitleWrapper>
  );
};

const Wrapper = styled('div', {
  position: 'relative',
  display: 'flex',
  width: '100%',
  cursor: 'default',
  userSelect: 'none',

  '& > .position': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 16,
    position: 'relative',

    '&:not(.is_first)::before': {
      content: '""',
      width: 3,
      height: `calc(50% - ${CIRCLE_RADIUS}px)`,
      position: 'absolute',
      left: CIRCLE_RADIUS - 1,
      top: 0,
      background: '$gray-75',
    },

    '&:not(.is_last)::after': {
      content: '""',
      width: 3,
      position: 'absolute',
      left: CIRCLE_RADIUS - 1,
      top: `calc(50% + ${CIRCLE_RADIUS}px)`,
      height: '9999px',
      background: '$gray-75',
    },
  },

  // add top rule to all but first item
  '&:after': {
    content: '',
    display: 'block',
    position: 'absolute',
    top: 0,
    left: 40,
    right: 0,
    borderTop: '1px solid $colors$gray-100',
  },
  // TODO: is there a better way to target first child here?
  '#chapter-list > div > div:first-child &:after': {
    borderTop: 'none',
  },

  '& .outer': {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  '& .inner': {
    display: 'grid',
    flex: 1,
    gridTemplateColumns: '1fr auto',
    alignItems: 'center',
    // '@medium': {
    //   flexDirection: 'row-reverse',
    //   justifyContent: 'flex-end',
    // },
    '& .left': {
      minWidth: 0,
    },
    '& .right': {
      width: '100%',
      '@medium': {
        display: 'flex',
        flex: 1,
        justifyContent: 'flex-end',
        alignItems: 'center',
      },
    },
  },
});

type Props = {
  chapter: ChapterCatalogData;
  presentation?: 'current' | 'unvisited';
  accessory?: React.ReactNode;
  stationIcon?: React.ReactNode;
};

export const ChapterListHeader = ({
  chapter,
  presentation,
  accessory,
  stationIcon = null,
}: Props) => {
  return (
    <Wrapper>
      <div
        className={classNames('position', {
          is_first: chapter.place === 'first',
          is_last: chapter.place === 'last',
        })}
      >
        {stationIcon}
      </div>

      <div className="inner">
        <div className="left">
          <Title chapter={chapter} presentation={presentation} />
        </div>
        <div className="right">{accessory}</div>
      </div>
    </Wrapper>
  );
};
