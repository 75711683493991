import { styled } from 'naan/stitches.config';

export const Divider = styled('hr', {
  $$color: '$colors$black-alpha-10',
  margin: 0,
  height: 0,
  border: 'none',
  borderBottom: '1px solid $$color',
  width: '100%',
  // TODO: we can remove this variant now – black-alpha-10 should work everywhere
  variants: {
    type: {
      transparent: {
        $$color: '$colors$black-alpha-10',
      },
    },
  },
});
