import { bugsnagNotify } from '@app/notification-service';
import { createLogger } from '@common/log';
import { isEmpty } from 'lodash';

const log = createLogger('analytics:events-queue');

/**
 * local storage persisted list of pending mixpanel events
 *
 * always read, updated, stored as a single operation to minize interference
 * between tabs
 */
export class EventQueue<T> {
  storageKey: string;
  resolveIdFn: (item: T) => string;
  persistBytesLimit: number;
  truncationCount: number;

  constructor({
    storageKey,
    resolveIdFn,
    persistBytesLimit,
    truncationCount,
  }: {
    storageKey: string;
    resolveIdFn: (item: T) => string;
    persistBytesLimit: number;
    truncationCount: number;
  }) {
    // @jason, @armando, is there a more clever way to bulk assign object properties from constructor params in a typesafe way?
    this.storageKey = storageKey;
    this.resolveIdFn = resolveIdFn;
    this.persistBytesLimit = persistBytesLimit;
    this.truncationCount = truncationCount;
  }

  all(): T[] {
    try {
      // beware, not sure exactly why and if from this code or not, but was hitting errors related to
      // an null localStorage object
      if (!localStorage.getItem) {
        log.error(`all() - missing localStorage.getItem`);
        return [];
      }
      const data = localStorage.getItem(this.storageKey) || '[]';
      const result: T[] = JSON.parse(data) || [];
      return result;
    } catch (error) {
      bugsnagNotify(error as Error);
      return [];
    }
  }

  add(item: T): void {
    this.addItems([item]);
  }

  addItems(items: T[]): void {
    try {
      if (!localStorage.setItem) {
        log.error(
          `addItems() - missing localStorage.setItems, items: ${JSON.stringify(
            items
          )}`
        );
        return;
      }
      for (const item of items) {
        if (isEmpty(this.resolveIdFn(item))) {
          // fail fast on insert since this would break our later attempt to remove
          log.error(
            `add - unable to resolve insert id for event: ${JSON.stringify(
              item
            )}`
          );
          throw Error('invalid event data - unable to resolve id');
        }
      }

      const all = this.all();
      all.push(...items);

      let data = JSON.stringify(all);
      log.debug(`store - len: ${all.length}, data size: ${data?.length}`);
      if (data.length > this.persistBytesLimit) {
        bugsnagNotify(
          `mixpanel persist queue exceeded size limit: ${all.length}, data size: ${data?.length} - truncating`
        );
        all.splice(0, this.truncationCount);
        data = JSON.stringify(all);
      }
      localStorage.setItem(this.storageKey, data);
    } catch (error) {
      bugsnagNotify(error as Error);
    }
  }

  remove(ids: string[]): void {
    try {
      if (!localStorage.setItem) {
        log.error(`remove() - missing localStorage.setItems`);
        return;
      }
      const all = this.all();
      const remaining = all.filter(item => {
        const id = this.resolveIdFn(item);
        if (isEmpty(id)) {
          log.error(
            `remove - unable to resolve insert id for event: ${JSON.stringify(
              item
            )}`
          );
          throw Error('invalid event data - unable to resolve id');
        }
        return !ids.includes(id);
      });
      const data = JSON.stringify(remaining);
      localStorage.setItem(this.storageKey, data);
    } catch (error) {
      bugsnagNotify(error as Error);
    }
  }
}
