//
// BEWARE, no longer used
//

import mixpanel from 'mixpanel-browser';

import { appConfig } from 'app/env';
import { createLogger } from 'app/logger';

import { AnalyticsAdapter } from '../analytics-adapter';
import { notEmpty } from '@utils/conditionals';
// import { sleep } from '@utils/util';

const log = createLogger('analytics:mixpanel');
const { projectToken, debug } = appConfig.analytics.mixpanel;

(window as any).mixpanel = mixpanel;

const format = (eventName: string) => {
  const prefix = appConfig.analytics.eventPrefix;
  return `${prefix}:${eventName}`;
};

//
// note, the mixpanel sdk operations will by default queue up api calls to be handled asynchronously.
// the interface is callback based, not promise based.
//

export class MixpanelAnalytics extends AnalyticsAdapter {
  serviceName = 'MixpanelAnalytics';
  isEnabled = notEmpty(projectToken);
  identifiedUserId = null as string; // supress gratuitous identify calls

  constructor() {
    super();
    if (this.isEnabled) {
      mixpanel.init(projectToken, { debug }); // https://developer.mixpanel.com/docs/javascript#debug-mode
      log.debug('enabled - initialized');
    } else {
      log.debug('disabled');
    }
  }

  //
  // called upon logging into existing account - links future events to specified user
  // note, assigning user profile data is a separate action with mixpanel (vs segment api)
  //
  // assumes mixpanel's "identity management" feature is not enabled on the project
  // the problem with the "identity management" feature is that logging in/out
  // burns through a finite number of associations between logged out and logged in activity.
  // what we lose by not using is only being able to associate anonymous session events with
  // the newly registered user. (i.e. can't associate anonymous events with later logins)
  //
  // https://help.mixpanel.com/hc/en-us/articles/115004497803
  //
  identify(userId: string) {
    log.info(`identify: ${userId}`);
    if (userId !== this.identifiedUserId) {
      this.identifiedUserId = userId;
      mixpanel.identify(userId);
    }
  }

  //
  // called upon registering a new user - will link anonymous events from this session with this user
  // assumes 'identify' also called separately afterwards
  //
  // 'alias' will be ignored if called more than once with the same userId
  //
  aliasNewAccount(userId: string) {
    log.info(`alias: ${userId}`);
    mixpanel.alias(userId);
    // this.identify(userId); // identify get automatically called anyways after alias
  }

  //
  // called upon logout - clears use association and local distinct id
  //
  // w/ segment integration, this didn't seem to reset the mixpanel level user association
  //
  reset() {
    log.info('reset');
    this.identifiedUserId = null;
    mixpanel.reset();
  }

  // //
  // // associates user information ('people' data) with user id
  // // assumes 'identify' already called first
  // //
  // setProfileData(data: object) {
  //   // our needs our this are currently met by rails server-side logic
  //   // log.info(`setProfile: ${JSON.stringify(data)}`);
  //   // mixpanel.people.set(data);
  // }

  track(eventName: string, data?: any) {
    // log.info(`track: ${JSON.stringify({ eventName, data })}`);
    log.info(`track: ${eventName}`);
    mixpanel.track(format(eventName), data);
  }

  page(pageName: string, data?: any) {
    // log.info(`page: ${JSON.stringify({ pageName, data })}`);
    log.info(`page: ${pageName}`);
    // mixpanel doesn't has specialized handling of 'page' events like segment and GA
    const eventName = `p:${pageName}`;
    mixpanel.track(eventName, { ...data, pageEvent: true });
  }

  // async testAnon() {
  //   mixpanel.reset();
  //   await sleep(200);
  //   mixpanel.track('anonymous');
  // }

  // async testIdent() {
  //   mixpanel.people.set({ distinct_id: 'u-3', email: 'u3@jw.app' });
  //   await sleep(200);
  //   mixpanel.alias('u-3');
  //   // mixpanel.identify('u-3');
  //   await sleep(200);
  //   mixpanel.track('identified');
  // }
}
