import { styled } from '@naan/stitches.config';

export const CardWrapper = styled('div', {
  $$backgroundColor: '$colors$white',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 16,
  paddingTop: 40,
  borderRadius: '$radii$large',
  backgroundColor: '$$backgroundColor',
  transition:
    'background-color 0.2s ease-in-out, transform 0.2s ease-in-out, opacity 0.2s ease-in-out',
  overflow: 'auto',
  '@medium': {
    paddingTop: 48,
  },

  '& .buttons-title-container': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    '&.open': {
      display: 'flex',
      justifyContent: 'center',
    },
    '& .card-title': {
      textStyle: 'small-caps',
      color: '$black-alpha-50',
    },
  },

  variants: {
    mode: {
      study: {
        $$contextualButtonBackgroundColor: '$colors$teal-500',
        $$contextualButtonBackgroundColorHover: '$colors$teal-600',
        $$contextualButtonBackgroundColorActive: '$colors$teal-700',
        $$contextualButtonTextColor: '$colors$white',
        $$backgroundColor: '$colors$teal-200',
      },
      listen: {
        $$contextualButtonBackgroundColor: '$colors$gray-700',
        $$contextualButtonBackgroundColorHover: '$colors$gray-800',
        $$contextualButtonBackgroundColorActive: '$colors$gray-900',
        $$contextualButtonTextColor: '$colors$globalWhite',
        $$backgroundColor: '$colors$gray-100',
      },
      whoa: {
        $$contextualButtonBackgroundColor: '$colors$black-alpha-06',
        $$contextualButtonBackgroundColorHover: '$colors$black-alpha-10',
        $$contextualButtonBackgroundColorActive: '$colors$black-alpha-15',
        $$contextualButtonTextColor: '$colors$black-alpha-50',
        $$backgroundColor: '$colors$green-200',
      },
      completed: {
        $$contextualButtonBackgroundColor: '$colors$green-500',
        $$contextualButtonBackgroundColorHover: '$colors$green-600',
        $$contextualButtonBackgroundColorActive: '$colors$green-700',
        $$contextualButtonTextColor: '$colors$globalWhite',
        $$backgroundColor: '$colors$green-200',
      },
      compact: {
        $$contextualButtonBackgroundColor: '$colors$gray-700',
        $$contextualButtonBackgroundColorHover: '$colors$gray-800',
        $$contextualButtonBackgroundColorActive: '$colors$gray-900',
        $$contextualButtonTextColor: '$colors$white',
        $$backgroundColor: '$colors$gray-50',
        padding: '14px 20px ',
        gap: 16,
        // transform: 'scale(.75)',
      },
    },
    // compact: {
    //   true: {
    //     padding: '20px 16px ',
    //     $$backgroundColor: '$colors$gray-50',
    //     gap: 16,
    //   },
    // },
  },

  defaultVariant: {
    stage: 'study',
  },
});
