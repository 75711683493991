// codemod-ignore-file
import * as React from 'react';
import { Story } from '@core/models/story-manager';
import { Button } from '@naan/primitives/button';
import { DownloadIcon } from '@naan/icons/download-icon';
import { SpinnerIcon } from 'components/ui/icons/spinner-icon';

import { TrashSmallIcon } from '@naan/icons/trash-icon';
import { HStack } from '@naan/primitives/stack';
import { ActionLink } from '@common/ui/action-link';
import { alertWarningError } from '@app/notification-service';

type CacheStates =
  | 'uninitialized'
  | 'uncached'
  | 'downloading'
  | 'cached'
  | 'error';

export const DownloadControlTemp = ({ story }: { story: Story }) => {
  const [cacheState, setCacheState] =
    React.useState<CacheStates>('uninitialized');

  // const [playerReady, setPlayerReady] = React.useState(false);
  // const [demoUrl, setDemoUrl] = React.useState<string | null>(null);

  const downloadAllAssets = React.useCallback(() => {
    async function downloadAll() {
      await story.ensureCached();
      setCacheState('cached');
    }

    setCacheState('downloading');
    requestAnimationFrame(() => {
      downloadAll().catch(error =>
        alertWarningError({ error, note: 'download controls - downloadAll' })
      );
    });
  }, [story]);

  const clearAllAssets = React.useCallback(() => {
    async function clearAll() {
      await story.removeFromCache();
      setCacheState('uncached');
    }

    clearAll().catch(error =>
      alertWarningError({ error, note: 'download controls - clearAll' })
    );
  }, [story]);

  React.useEffect(() => {
    async function checkInitialCacheStatus() {
      const cached = await story.isCached();
      if (cached) {
        setCacheState('cached');
      } else {
        setCacheState('uncached');
      }

      // handled now with StoryDetailScreen hook
      // if (story.shouldCache && !cached) {
      //   downloadAllAssets();
      // }
      // if (!story.shouldCache && cached) {
      //   clearAllAssets();
      // }
    }

    if (cacheState === 'uninitialized') {
      checkInitialCacheStatus().catch(error =>
        alertWarningError({ error, note: 'download controls - check statua' })
      );
    }
  }, [cacheState, clearAllAssets, downloadAllAssets, story]);

  // React.useEffect(() => {
  //   if (cacheState === 'cached' && demoUrl === null) {
  //     const firstChapter = story.chapters[0];
  //     retrieveSourceFromCache(firstChapter.normalAudioUrl).then(url => {
  //       setDemoUrl(url);
  //     });
  //   }
  // }, [cacheState, demoUrl, story.chapters]);

  if (cacheState === 'uninitialized') {
    return (
      <Button
        label="checking status"
        size="small"
        leftIcon={<SpinnerIcon color="#eee" />}
      />
    );
  }

  if (cacheState === 'downloading') {
    return (
      <Button
        label="downloading"
        size="small"
        presentation="green"
        leftIcon={<SpinnerIcon color="#eee" />}
      />
    );
  }

  const refreshLink = () => {
    async function refreshCacheStatus() {
      const cached = await story.isCached();
      if (cached) {
        setCacheState('cached');
      } else {
        setCacheState('uncached');
      }
    }

    return (
      <>
        <ActionLink onPress={refreshCacheStatus}>[refresh]</ActionLink> (needed
        after status triggered changes)
      </>
    );
  };

  if (cacheState === 'cached' /* && demoUrl*/) {
    // console.log(`cached audio url: ${demoUrl}`);
    return (
      <HStack align={'center'} gap={'small'}>
        {/* <audio controls controlsList="nodownload" src={demoUrl}></audio> */}
        <div>
          <Button
            size="small"
            leftIcon={<TrashSmallIcon />}
            onClick={clearAllAssets}
            label="uncache"
            presentation="redLight"
          />{' '}
          {refreshLink()}
        </div>
      </HStack>
    );
  }

  return (
    <>
      <Button
        label="cache"
        size="small"
        leftIcon={<DownloadIcon />}
        onClick={downloadAllAssets}
      />{' '}
      {refreshLink()}
    </>
  );
};
