import * as React from 'react';
import { observer } from 'mobx-react';

// import { VSpacer } from 'naan/primitives/spacer';
import { Button } from 'naan/primitives/button';
import { AppFactory } from 'app/app-factory';
// import { colors } from '@naan/tokens/colors';
// import { openUrl } from '@naan/primitives/text/external-link';
import { alertSevereError } from '@app/notification-service';
import { profileHomePath } from 'components/nav/path-helpers';
import { useNavigate } from 'react-router-dom';

import { resolveApiMessage } from '@core/models/user-manager/api-messages';
import { LocaleCode } from '@utils/util-types';
import { resumeSubscriptionDates } from '@cas-shared/subscription-helpers';
import { ResumeDialog } from '../dialogs/resume-subscription';

import __ from 'core/lib/localization';

export const ResumeButton = observer(({ l2 }: { l2: LocaleCode }) => {
  const { userManager, storyManager } = AppFactory.root;
  const membership = userManager.membershipL2(l2);
  const entitlementData = membership.nodeEntitlement;

  const effectiveDate = storyManager.currentDate;

  // const { paymentData } = accountData;
  const navigate = useNavigate();

  // DEFERRED until after 10.x release
  const handleResume = () => {
    const { accessUntil: newBillingResumesOn } = resumeSubscriptionDates(
      entitlementData,
      {
        effectiveDate,
      }
    );

    AppFactory.dialogPresenter.present(onDismiss => (
      <ResumeDialog
        onOk={() => {
          onDismiss();
          membership
            .resumeSubscription()
            .then(result => {
              navigate(profileHomePath()); // todo: figure out responsive issue
              const message = resolveApiMessage(result);
              if (message) {
                AppFactory.toastService.open({
                  type: 'success',
                  message,
                });
              }
            })
            .catch(error => {
              const message = resolveApiMessage(error) || error.userMessage;
              if (message) {
                AppFactory.toastService.open({
                  type: 'error',
                  message,
                });
              } else {
                alertSevereError({ error, note: 'cancelAutoRenew' });
              }
            });
          // setTimeout(() => {
          //   DialogService.closeDialog();
          // }, 100);
        }}
        onDismiss={onDismiss}
        l2={l2}
        newBillingResumesOn={newBillingResumesOn}
      />
    ));
  };

  // DEFERRED until after 10.x release

  return (
    <Button
      onClick={handleResume}
      label={__('Resume', 'resume')}
      size={'small'}
      presentation={'redLight'}
      data-test-id="auto-renew-off"
      css={{
        margin: '-4px 0',
      }}
    />
  );
});
