import { mainScrollingAreaId } from 'components/global-layout/responsive-layout';

export function getMainScrollingContainer(): HTMLDivElement | null {
  const node = document.getElementById(mainScrollingAreaId) as HTMLDivElement;
  if (!node) {
    return null;
  }

  return node;
}
