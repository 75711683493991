import * as React from 'react';

export function ArrowTopRightIcon({
  width = 24,
  height = 24,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5858 6L7 6V4L19 4L20 4V5V17H18V7.41421L5.70711 19.7071L4.29289 18.2929L16.5858 6Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}

export function ArrowTopRightExtraSmallIcon({
  width = 16,
  height = 16,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5858 4L5.00001 4V2L13 2H14V3L14 11H12L12 5.41421L3.70712 13.7071L2.29291 12.2929L10.5858 4Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}
