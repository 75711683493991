/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import * as Dialog from 'naan/primitives/modals/dialog';
import { useNavigate } from 'react-router-dom';
import { AppFactory } from 'app/app-factory';

import { styled } from 'naan/stitches.config';
import {
  ContentGrid,
  ContentGridInnerContainer,
} from 'components/global-layout';
import { AlertTriangleIcon } from 'naan/icons/alert-triangle-icon';
import { PageHeading } from 'components/page-heading';
import { HStack, VStack } from 'naan/primitives/stack';
import { PlainMarkdown, Text } from 'naan/primitives/text';
import { VSpacer } from 'naan/primitives/spacer';
import { Button } from 'naan/primitives/button';
import { LargeGap } from '@naan/primitives/spacer/gaps';
import { ArrowLeftIcon } from '@naan/icons/arrow-left-icon';
import { alertSevereError } from '@app/notification-service';
import {
  accountClosedPath,
  profileHomePath,
} from 'components/nav/path-helpers';

import __ from 'core/lib/localization';
import { WithAccountLocale } from 'lib/with-account-locale';

const Wrapper = styled('section', {
  gridColumn: '1/-1',
  '@large': {
    gridColumn: '3/-3',
  },

  '& .close-title': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    svg: {
      color: '$red-500',
      marginTop: '3px',
    },
  },
});

const CloseAccountConfirmationDialog = ({
  onDismiss,
  okAction,
}: {
  onDismiss: () => void;
  okAction: () => void;
}) => {
  const handleOkAndDismiss = React.useCallback(() => {
    onDismiss();
    okAction();
  }, [okAction, onDismiss]);

  return (
    // @armando, for some reason the 'WithAccountLocale' wrapper works the first time trigger the dialog
    // but if I dismiss and reopen, then it reverts to the "immersive view" locale.
    <WithAccountLocale>
      <Dialog.Container open={true} onDismiss={onDismiss}>
        <Dialog.Heading>{__('Are you sure?', 'areYouSure')}</Dialog.Heading>
        <Dialog.Body>
          <VStack>
            <VSpacer size={3} />
            <Text>
              {__(
                'Closing your account cannot be undone',
                'closingYourAccountCannot'
              )}
            </Text>
            <VSpacer size={1} />
          </VStack>
        </Dialog.Body>
        <Dialog.ButtonsContainer direction={'column'}>
          <Dialog.Button
            presentation={'cancel'}
            label={__('Keep account', 'keepAccount')}
            data-test-id="cancelButton"
            onClick={onDismiss}
          />
          <Dialog.Button
            presentation={'destructive'}
            label={__('Yes, close my account', 'yesCloseMyAccount')}
            data-test-id="okButton"
            onClick={handleOkAndDismiss}
          />
        </Dialog.ButtonsContainer>
      </Dialog.Container>
    </WithAccountLocale>
  );
};

export const CloseAccountScreen = () => {
  const { userManager } = AppFactory.root;
  const { accountData } = userManager ?? {};
  const navigate = useNavigate();

  const handleCloseAccount = () => {
    AppFactory.dialogPresenter.present(onDismiss => {
      return (
        <CloseAccountConfirmationDialog
          onDismiss={onDismiss}
          okAction={() => {
            userManager
              .closeAccount()
              .then(() => {
                navigate(accountClosedPath());
              })
              .catch(error =>
                alertSevereError({ error, note: 'close-account' })
              );
          }}
        />
      );
    });
  };

  return (
    <WithAccountLocale>
      <ContentGridInnerContainer className="center">
        <ContentGrid>
          <Wrapper>
            <PageHeading
              showBackArrow={true}
              backAction={() => navigate(profileHomePath())}
              backTitle={__('My account', 'myAccount')}
            />
            <HStack>
              {/* <InlineBox css={{ color: '$red-400' }}>
              <AlertTriangleIcon width={36} height={36} />
            </InlineBox> */}
              <Text textStyle="medium-heading">
                {__('Close account', 'closeAccount')}
              </Text>
            </HStack>
            <VSpacer size={2} />
            <PlainMarkdown
              source={__(
                `If you close your account you will no longer be able to sign in with %{email} and you will not receive any further account related emails.`,
                'ifYouCloseYourAccountYouWillNoLongerBeAbleToSignIn',
                { email: accountData.email }
              )}
            />
            <VSpacer size={14} />
            <HStack justify={'space'}>
              <Button
                label={__('Go back', 'goBack')}
                presentation={'secondary'}
                onClick={() => navigate(profileHomePath())}
                leftIcon={<ArrowLeftIcon />}
              />
              <Button
                label={__('Close account', 'closeAccount')}
                presentation={'destructive'}
                onClick={handleCloseAccount}
                leftIcon={<AlertTriangleIcon />}
              />
            </HStack>
            <LargeGap />
          </Wrapper>
        </ContentGrid>
      </ContentGridInnerContainer>
    </WithAccountLocale>
  );
};
