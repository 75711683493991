import * as React from 'react';
import { getVideoAssets } from 'video-assets/listening-warmup';
import {
  VideoInterstitial,
  VideoInterstitialProps,
} from './component/video-interstitial';
import __ from '@core/lib/localization';

export const ListeningWarmupInterstitial = ({
  onForcePass,
  onDismiss,
}: VideoInterstitialProps) => {
  // debugger;
  return (
    <VideoInterstitial
      title={__('Listening warm-up', 'listeningWarmUp')}
      onForcePass={onForcePass}
      onDismiss={onDismiss}
      getVideoAssets={getVideoAssets}
      videoAchievementKey="video:listening-warmup"
    />
  );
};
