import { alertLevels } from './alert-levels';
import { prefix } from './prefix';
import { GenericError } from './generic-error';

const { ERROR } = alertLevels;

export class NetworkError extends GenericError {
  constructor(error: Error | string, context: any = {}) {
    super(
      error instanceof Error
        ? prefix(error.constructor.name, error.message)
        : error
    );

    this.context = context;
    this.alertLevel = ERROR;
    this.report = false;

    if (error instanceof Error) {
      // get the fetch options too
      this.context = { error, ...context };
      // pretty sure this was meaningless code
      // this.type = this.constructor.type;
    }
  }
}
