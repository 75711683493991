import React, { Component, ErrorInfo, ReactNode } from 'react';
import { Button } from 'naan/primitives/button';
import { styled } from 'naan/stitches.config';

import { reportException } from 'common/error-reporting';
import { embeddedMode, postNativeMessage } from '@app/app-util';
import __ from '@core/lib/localization';
import { Text } from '@naan/primitives/text';

const Wrapper = styled('div', {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textStyle: 'body',
  color: '$textSecondary',
  textAlign: 'center',

  h1: {
    color: '$red-500',
    textStyle: 'extra-large-heading',
    textAlign: 'center',
    marginTop: '$10',
    marginBottom: '$10',
  },
  h2: {
    textStyle: 'medium-heading',
    color: '$textSecondary',
    marginBottom: '$2',
    textAlign: 'center',
  },
  section: {
    padding: '$2 0',
    textAlign: 'center',
  },
  button: {
    marginTop: '$8',
  },
});

export const FullScreenError = ({ message }: { message?: string }) => {
  const reload = () => {
    if (embeddedMode()) {
      postNativeMessage({
        type: 'RELOAD',
      });
    } else {
      (window as any).location = '/account';
    }
  };
  return (
    <Wrapper>
      <div className="error-boundary__container">
        <h1>{__('Oops', 'oops')}</h1>
        <h2>
          {Boolean(message)
            ? message
            : __('Something went wrong', 'somethingWentWrong')}
        </h2>
        <section>
          {__('The developers have been notified', 'theDevelopersHaveBeen')}.
        </section>
        <Button
          onClick={reload}
          label={__('Reload application', 'reloadApplication')}
        />
      </div>
    </Wrapper>
  );
};

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

export class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, info: ErrorInfo) {
    reportException(error, info);
  }

  public render() {
    if (this.state.hasError) {
      return <FullScreenError />;
    }
    return this.props.children;
  }
}

export class MiniErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, info: ErrorInfo) {
    reportException(error, info);
  }

  public render() {
    if (this.state.hasError) {
      return <Text>error</Text>;
    }
    return this.props.children;
  }
}
