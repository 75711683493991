import { TFilterKey } from 'common/filtering/filter-keys';
import { useFiltering } from 'common/filtering/use-filtering';
import { isArray } from 'lodash';
import * as React from 'react';

type FilterBoxProps = {
  filterItems: string[];
  filterQueryKey: TFilterKey;
  renderFilterControl: (
    item: string,
    selected: boolean,
    action: () => void
  ) => React.ReactNode;
};

export const FilterBox = ({
  filterItems,
  filterQueryKey,
  renderFilterControl,
}: FilterBoxProps) => {
  const { addFilter, removeValueFromFilter, getFilter } = useFiltering();

  const currentValues = getFilter(filterQueryKey) ?? [];
  return (
    <>
      {filterItems.map((item, index) => {
        let selected = false;
        // This is only for TS benefit. It should always be an array for this kind of filter
        if (isArray(currentValues)) {
          selected = currentValues.includes(item) ?? false;
        }
        const action = () =>
          selected
            ? removeValueFromFilter(item)
            : addFilter(filterQueryKey, item);
        return (
          <React.Fragment key={index}>
            {renderFilterControl(item, selected, action)}
          </React.Fragment>
        );
      })}
    </>
  );
};
