import { appConfig } from 'app/env';
import '../../native-support/init-embed-state';
import { createLogger } from '@common/log';
import {
  version,
  curEnv,
  buildTimestampIso,
  commit,
  commitTimestampIso,
  hash,
  // @ts-expect-error
} from '@jw-spa-version';
import { bugsnagNotify } from '@app/notification-service';
import { track } from '@app/track';
import {
  clearEmbedState,
  saveEmbedStateToLocalStorage,
} from '../../native-support/init-embed-state';
import { argsToString /*, bufferLogger*/ } from '@common/log/buffer-logger';

const log = createLogger('app-util');

export const devMode = !!import.meta.env.DEV;

export const getProductVersion = () => `${version}-spa`;

export const appSlug = () => appConfig.appSlug;

// relies on init-embedded-state.ts import/init of window state
export const embeddedPlatform = () => window.embedState?.platform;
export const embeddedBuildNum = (): number => window.embedState?.buildNum;

export const splitHundreds = (n: number) => [Math.floor(n / 100), n % 100];

export const formattedEmbeddedBuildNumber = () => {
  try {
    let [head1, rev] = splitHundreds(embeddedBuildNum());
    let [head2, patch] = splitHundreds(head1);
    let [major, minor] = splitHundreds(head2);
    if (major === 8 && minor === 10) {
      minor = 1; // hack around mangled native version build number
    }
    return `${major}.${minor}.${patch}-${rev.toString().padStart(2, '0')}`;
  } catch (error) {
    // should be safe, but don't risk fatality here
    bugsnagNotify(error as any);
    return String(embeddedBuildNum());
  }
};

// this was going to break if hit v10.x
// export const formattedEmbeddedBuildNumber = () => {
//   const raw = String(embeddedBuildNumber());
//   try {
//     const major = Number(raw[0]);
//     const minor = Number(raw.substring(1, 3));
//     const patch = Number(raw.substring(3, 5));
//     const rev = raw.substring(5);
//     return `${major}.${minor}.${patch}-${rev}`;
//   } catch (error) {
//     return raw;
//   }
// };

export const buildInfoDisplay = () => {
  const env = appConfig.apiEnv === 'LIVE' ? '' : ` (${appConfig.apiEnv})`;

  if (embeddedMode()) {
    return `${embeddedPlatform()}: ${formattedEmbeddedBuildNumber()}, web: ${version}${env}`;
  } else {
    return `v${version}${env}`;
  }
};

export const updateStoreUrl = () => {
  return embeddedAndroid()
    ? appConfig.website.downloadUrls.playStore
    : appConfig.website.downloadUrls.appStore;
};

export const embeddedMode = (): boolean => {
  return /(ios|android)/.test(embeddedPlatform());
};

export const isEmbeddedPreV8 = () => {
  const num = embeddedBuildNum();
  return num && num < 8000000;
};

export const embeddedIos = () => embeddedPlatform() === 'ios';
export const embeddedAndroid = () => embeddedPlatform() === 'android';

// dev tools support
export const setEmbeddedPlatform = (platform: string) => {
  window.embedState.platform = platform;
  persistEmbedState();
};

export const setEmbeddedBuildNum = (num: number) => {
  window.embedState.buildNum = num;
  persistEmbedState();
};

export const persistEmbedState = () => {
  saveEmbedStateToLocalStorage(window.embedState);
};

export const resetEmbeddedState = () => {
  clearEmbedState();
  reload();
};

export const postNativeMessage = ({
  type,
  payload,
  data,
}: {
  type: string;
  payload?: any;
  data?: any;
}) => {
  if (type !== 'LOG') {
    log.info(
      `postNativeMessage: ${type}, payload: ${JSON.stringify(
        payload
      )}, data: ${JSON.stringify(data)}`
    );
  }
  if (!window.ReactNativeWebView?.postMessage) {
    if (type !== 'LOG') {
      log.error(`ReactNativeWebView.postMessage unexpectedly missing`);
      track('error__native_post_message', {
        type,
        payload,
        data,
        flavor: 'guarded',
        error: 'postMessage function missing',
      });
    }
  } else {
    // TODO, for some android devices we're hitting "postMessage is not a function" here
    // https://app.bugsnag.com/jiveworld/cali-spa-live/errors/642385976797fd000939a822?filters[event.since]=30d&filters[error.status]=open
    try {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type,
          payload,
          data,
        })
      );
      // track('system__native_post_message', {
      //   type,
      //   payload,
      //   data,
      // });
    } catch (error) {
      if (type !== 'LOG') {
        track('error__native_post_message', {
          type,
          payload,
          data,
          flavor: 'caught',
          error: String(error),
        });
      }
    }
  }
};

const logToNative = (...args: any[]) => {
  if (embeddedMode()) {
    const payload = argsToString(...args);
    postNativeMessage({ type: 'LOG', payload });
  }
};

// loose depdency so we don't risk making the base logging infrastructure depend on this module
// if (embeddedMode()) {
window.logToNative = logToNative;
// } else {
//   bufferLogger.nativeRelayActivated = false;
// }

export const reload = () => {
  // JE: was seeing more warning that i'd like during testing of the timeout flow getting triggered
  // and shoudln't be materially different anyways from the window level reload, so keeping this simple.

  // if (embeddedMode()) {
  //   postNativeMessage({ type: 'RELOAD' });

  //   // fall-back to window level reload in case native message isn't honored
  //   // @armando, is this over engineered? would simplisticly falling though here be safe and sufficient?
  //   setTimeout(() => {
  //     bugsnagNotify(
  //       'native RELOAD message not honored, falling back to window level reload'
  //     );
  //     window.location.reload();
  //   }, 1000);
  // } else {
  window.location.reload();
  // }
};

// when on native, will reset to the dashboard with embed params
export const reloadOrNativeReset = () => {
  if (embeddedMode()) {
    postNativeMessage({ type: 'RESET' });

    // fall-back to window level reload in case native message isn't honored
    setTimeout(() => {
      bugsnagNotify(
        'native RESET message not honored, falling back to window level reload'
      );
      window.location.reload();
    }, 1000);
  } else {
    window.location.reload();
  }
};

export const reloadOrNativeRestart = () => {
  if (embeddedMode()) {
    postNativeMessage({ type: 'RESTART' });

    // fall-back to window level reload in case native message isn't honored
    setTimeout(() => {
      bugsnagNotify(
        'native RESTART message not honored, falling back to window level reload'
      );
      window.location.reload();
    }, 1000);
  } else {
    window.location.reload();
  }
};

// used in error reporting and metrics
export const buildInfo = () => {
  return {
    version,
    curEnv,
    buildTimestampIso,
    commit,
    commitTimestampIso,
    hash,
    embedded: {
      mode: embeddedMode(),
      platform: embeddedPlatform(),
      buildNumber: embeddedBuildNum(),
    },
    // distinguishes brasil demo version of site
    appSlug: appSlug(),
  };
};

// Detect native Wake Lock API support
export const nativeWakeLockSupported = (): boolean => 'wakeLock' in navigator;
