import React from 'react';
import { observer } from 'mobx-react';
import { StoryCard } from 'components/stories/story-card';
import { Story } from '@core/models/story-manager/story';
import { storiesPrimaryFiltered } from 'components/nav/path-helpers';
import { AppFactory } from '@app/app-factory';
import { PrimaryFilterKeys } from '@core/models/story-manager/story-manager';
import {
  SectionHeading,
  SectionHeadingLink,
} from './dashboard-section-heading';
import __ from '@core/lib/localization';
import { Divider } from '@naan/primitives/divider';

export const LastListenedWidget = observer(({ story }: { story: Story }) => {
  if (!story) return null;

  const { storyManager } = AppFactory.root;

  const inProgressCount = storyManager.inProgress.length;
  const inProgressPath = storiesPrimaryFiltered(PrimaryFilterKeys.IN_PROGRESS);

  return (
    <>
      <SectionHeading
        title={__('Where you were', 'whereYouWere')}
        renderLinks={() => (
          <>
            {inProgressCount > 1 ? (
              <SectionHeadingLink to={inProgressPath}>
                {__('All in progress', 'allInProgress')}
              </SectionHeadingLink>
            ) : null}
          </>
        )}
      />
      <Divider />
      <StoryCard story={story} truncateDescription={true} />
    </>
  );
});
