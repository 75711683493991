import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import __ from 'core/lib/localization';
import { SearchIcon } from 'naan/icons/search-icon';
import { Button } from 'naan/primitives/button';
import { clearSavedScrollPosition } from 'lib/scroll-utils/deep-scroll-restoration';
import { discoverPath } from 'components/nav/path-helpers';

export const BrowseStoriesButton = () => {
  const navigate = useNavigate();
  const navigateToDiscover = () => {
    const path = discoverPath();
    clearSavedScrollPosition(path);
    navigate(path);
  };
  return (
    <Button
      leftIcon={<SearchIcon />}
      label={__('Browse stories', 'browseStories')}
      presentation={'teal'}
      onClick={navigateToDiscover}
    />
  );
};
