import * as React from 'react';
import { pick } from './pick';

type TLargePresentation = 'color' | 'grayscale';

export const VernacularIconLarge = ({
  presentation = 'color',
}: {
  presentation?: TLargePresentation;
}) => {
  const color1 = pick(presentation, {
    color: '#B3DBEB',
    grayscale: '#ccc',
  });

  const color2 = pick(presentation, {
    color: '#4EBCEB',
    grayscale: '#999',
  });

  return (
    <svg
      width={72}
      height={72}
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M18 45H54L64.2857 60.4286H7.71429L18 45Z" fill={color1}></path>

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.7475 51.1855C40.0781 47.215 53.4053 30.8395 53.4053 23.0185C53.4053 12.9634 45.7905 5.14282 36.0001 5.14282C26.2096 5.14282 18.5948 12.9634 18.5948 23.0185C18.5948 30.8395 31.922 47.2151 35.2527 51.1855C35.6443 51.6523 36.3558 51.6523 36.7475 51.1855ZM36.0001 29.7219C39.6048 29.7219 42.527 26.7207 42.527 23.0185C42.527 19.3163 39.6048 16.3151 36.0001 16.3151C32.3953 16.3151 29.4731 19.3163 29.4731 23.0185C29.4731 26.7207 32.3953 29.7219 36.0001 29.7219Z"
        fill={color2}
      ></path>
    </svg>
  );
};

export const VernacularIconSmall = ({
  presentation = 'color',
}: {
  presentation?: 'color' | 'grayscale' | 'dark';
}) => {
  const color1 = pick(presentation, {
    color: '#8DC9E3',
    grayscale: '#ccc',
    dark: '#ffffff50',
  });

  const color2 = pick(presentation, {
    color: '#4EBCEB',
    grayscale: '#999',
    dark: '#fff',
  });

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.00001 15.4285H18L21.4286 20.5713H2.57144L6.00001 15.4285Z"
        fill={color1}
      ></path>

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7383 16.4694C14.3013 14.5482 17.8018 9.98709 17.8018 7.67291C17.8018 4.32122 15.2635 1.71436 12 1.71436C8.73654 1.71436 6.19827 4.32122 6.19827 7.67291C6.19827 9.98709 9.69879 14.5482 11.2617 16.4694C11.6463 16.9421 12.3538 16.9421 12.7383 16.4694ZM12 9.90737C13.2016 9.90737 14.1757 8.90697 14.1757 7.67291C14.1757 6.43886 13.2016 5.43845 12 5.43845C10.7984 5.43845 9.82436 6.43886 9.82436 7.67291C9.82436 8.90697 10.7984 9.90737 12 9.90737Z"
        fill={color2}
      ></path>
    </svg>
  );
};
