/* eslint-disable no-unused-vars */
/// enums trip up eslint, so we disable it for this file. We should consider using objects and `as const` instead of enums
// these types are needed by both tikka and core code and the cross dependency was
// causing a strange jest compile error

export const enum FluentListenStatus {
  IS_STUDYING = 'STUDYING',
  IS_LISTENING = 'IS_LISTENING',
  COMPLETED_LISTEN = 'COMPLETED_LISTEN', // aka fluent listen complete
}

// these are the sub-flavors of the StudyModel player
export const enum PlayerMode {
  // SOUNDBITE = 'SOUNDBITE',
  FLUENT_LISTEN = 'FLUENT_LISTEN',
  STUDY = 'STUDY',
  VOCAB_REVIEW = 'VOCAB_REVIEW', // not really a "player mode", but used to switch the the player init logic
}

export const enum PlayerKind {
  SOUNDBITE = 'SOUNDBITE',
  STUDY = 'STUDY',
  LISTEN = 'LISTEN',
  VOCAB_REVIEW = 'VOCAB_REVIEW',
}
