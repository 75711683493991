import * as React from 'react';
import { observer } from 'mobx-react';
import {
  getUiThemePreference,
  setSystemTheme,
  setUiThemePreference,
  styled,
} from '@naan/stitches.config';
import { setTheme } from '@naan/stitches.config';
import {
  dismissOnboardingTip,
  isDismissed,
} from '@app/onboarding/onboarding-service';
import { track } from '@app/track';
import { events } from '@common/instrumentation/events';

import __ from '@core/lib/localization';

const Wrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
});

const ItemWrapper = styled('div', {
  $$radioSize: '24px',
  $$innerSize: '16px',
  $$radioColor: '$colors$gray-100',
  display: 'grid',
  gridTemplateColumns: '$$radioSize 1fr',
  alignItems: 'center',
  gap: 10,

  '&:hover': {
    $$radioColor: '$colors$gray-200',
  },

  '&.selected': {
    $$radioColor: '$colors$teal-500',
    '&:hover': {
      $$radioColor: '$colors$teal-600',
    },
  },

  '& .indicator': {
    width: '$$radioSize',
    height: '$$radioSize',
    borderRadius: '50%',
    border: '2px solid $$radioColor',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& .thingie': {
      width: 14,
      height: 14,
      background: '$$radioColor',
      borderRadius: '50%',
    },
  },

  '& .label': {
    textStyle: 'small-text-bold',
    userSelect: 'none',
  },
});

// this is an experiment on how to fade out the theme transition so it feels less sudden.
// I liked it at first, but It kinda become sick inducing after trying it like 25 times
// @frankharrison if you want to uncomment it and play with it?
function showOverlay() {
  // const overlay = document.createElement('div');
  // overlay.style.position = 'fixed';
  // overlay.style.top = '0';
  // overlay.style.left = '0';
  // overlay.style.right = '0';
  // overlay.style.bottom = '0';
  // overlay.style.backgroundColor = 'var(--colors-gray-100)';
  // overlay.style.zIndex = '9999';
  // overlay.style.transition = 'all 0.12s';
  // document.body.appendChild(overlay);
  // // use webanimations API to fade out the overlay after q second
  // // with easing
  // const animation = overlay.animate(
  //   [
  //     {
  //       opacity: 1,
  //       backgroundColor: 'var(--colors-gray-900)',
  //     },
  //     {
  //       opacity: 0,
  //       backgroundColor: 'var(--colors-gray-100)',
  //     },
  //   ],
  //   {
  //     duration: 200,
  //     easing: 'ease-out',
  //   }
  // );
  // animation.onfinish = () => {
  //   overlay.remove();
  // };
}

// (window as any).o = addOverlay;

const Item = ({
  label,
  selected,
  onSelect,
}: {
  label: string;
  value: string;
  selected: boolean;
  onSelect: () => void;
}) => {
  return (
    <ItemWrapper className={selected ? 'selected' : ''} onClick={onSelect}>
      <div className="indicator">{selected && <div className="thingie" />}</div>
      <div className="label">{label}</div>
    </ItemWrapper>
  );
};

export const UIThemeSwitch = observer(() => {
  const [__uiTheme, __setUiTheme] = React.useState(getUiThemePreference());

  const setUiTheme = React.useCallback(
    (theme: 'light' | 'dark' | 'system') => () => {
      if (!isDismissed('darkModeModal')) {
        dismissOnboardingTip('darkModeModal');
      }
      __setUiTheme(theme);
      setUiThemePreference(theme);

      showOverlay();

      track(events.settings.changedTheme, {
        theme,
      });

      if (theme !== 'system') {
        setTheme(theme);
      } else {
        setSystemTheme();
      }
    },
    [__setUiTheme]
  );

  return (
    <>
      <div className="row">
        <div className="left">
          <Wrapper>
            {__('Appearance', 'appearance')}
            <Item
              label={__('Light theme', 'lightTheme')}
              value="light"
              selected={__uiTheme === 'light'}
              onSelect={setUiTheme('light')}
            />
            <Item
              label={__('Dark theme', 'darkTheme')}
              value="dark"
              selected={__uiTheme === 'dark'}
              onSelect={setUiTheme('dark')}
            />
            <Item
              label={__('Match device theme', 'matchDeviceTheme')}
              value="system"
              selected={__uiTheme === 'system'}
              onSelect={setUiTheme('system')}
            />
          </Wrapper>
        </div>
      </div>
    </>
  );
});
