import * as React from 'react';

export type feedbackDialogStatus = 'initial' | 'open' | 'thanks';

export const useFeedbackState = (
  initialState: feedbackDialogStatus = 'initial'
) => {
  const [state, setState] = React.useState<feedbackDialogStatus>(initialState);

  const openDialog = () => setState('open');
  const closeDialog = () => setState('initial');
  const showThanks = () => setState('thanks');

  return {
    state,
    openDialog,
    closeDialog,
    showThanks,
  };
};
