/// TODO: localize manually
import * as React from 'react';
import * as Dialog from '@naan/primitives/modals';
import { observer } from 'mobx-react-lite';
import { styled } from '@naan/stitches.config';
import { icons } from 'components/soundbites/soundbites-icons';
import { AppFactory } from '@app/app-factory';

import __ from '@core/lib/localization';

// const __ = function (s: string, k: string) {
//   return `[${s}][${k}]`;
// };

const CategoriesWrapper = styled('div', {
  padding: '16px 0 32px',
  '& h3': {
    textStyle: 'medium-heading',
    marginBottom: 12,
  },
  '& ul': {
    display: 'flex',
    flexDirection: 'column',
    gap: 24,

    '& li.category': {
      display: 'flex',
      gap: 12,

      '& .category-icon': {
        '& svg': {
          width: 48,
          height: 48,
        },
      },

      '& .category-description': {
        '& h4': {
          textStyle: 'body-bold',
        },
      },
    },
  },
});

const CategoryIcon = ({ name }: { name: keyof typeof icons }) => {
  const Component = icons[name].large;
  return <Component />;
};

// beware: this data is duplicated in soundbite-categories.ts
// @armando todo: refactor next time we need to touch it
function categoriesData(): {
  category: keyof typeof icons;
  name: string;
  description: string;
}[] {
  return [
    {
      category: 'accents',
      name: __('Speech & Accents', 'sbInfo:speechAndAccentsName'),
      description: __(
        'Appreciating the distinctive sound and style of different speakers',
        'sbInfo:speechAndAccentsDescription'
      ),
    },
    {
      category: 'vernacular',
      name: __('Vernacular & Slang', 'sbInfo:vernacularAndSlangName'),
      description: __(
        'Colloquial usage by region or demographic',
        'sbInfo:vernacularAndSlangDescription'
      ),
    },
    {
      category: 'vocabulary',
      name: __('Vocabulary', 'sbInfo:vocabularyName'),
      description: __(
        'Interesting words and expressions',
        'sbInfo:vocabularyDescription'
      ),
    },
    {
      category: 'grammar',
      name: __('Grammar', 'sbInfo:grammarName'),
      description: __(
        'Rules for how the language fits together',
        'sbInfo:grammarDescription'
      ),
    },
    {
      category: 'patterns',
      name: __('Useful Patterns', 'sbInfo:usefulPatternsName'),
      description: __(
        'Versatile language structures that boost your fluency',
        'sbInfo:usefulPatternsDescription'
      ),
    },
    {
      category: 'idioms',
      name: __('Idioms & Sayings', 'sbInfo:idiomsAndSayingsName'),
      description: __(
        'Weird and wonderful expressions with interesting cultural roots',
        'sbInfo:idiomsAndSayingsDescription'
      ),
    },
    {
      category: 'strategies',
      name: __('Strategies', 'sbInfo:strategiesName'),
      description: __(
        'Hacks for navigating social situations (e.g. offering condolences), and how to study more effectively',
        'sbInfo:strategiesDescription'
      ),
    },
    {
      category: 'culture',
      name: __('History & Culture', 'sbInfo:historyAndCultureName'),
      description: __(
        'Famous people and institutions, music and movies, celebrations and beliefs',
        'sbInfo:famousPeopleAndInstitutionsMusicAndMoviesCelebrationsAndBeliefs'
      ),
    },
  ];
}

export function openSoundbitesHelpDialog() {
  /// to programmatically open the dialog, we need to click the button
  /// there's no easy way to make these dialogs open programmatically without doing a big refactor
  /// And i think this approach is fine.
  // getElementById(elementIds.HELP_DIALOG_BUTTON)?.click();
  AppFactory.dialogPresenter.present(onDismiss => (
    <SoundbiteHelpDialog onDismiss={onDismiss} />
  ));
}

// todo: should probably rename this to "SoundbiteHelpDialog"
// this dialog used to be auto-shown the first time the soundbite player was launched,
// but now only triggered via the `?` button/key
const SoundbiteHelpDialog = observer(
  ({ onDismiss }: { onDismiss: () => void }) => {
    return (
      <Dialog.Container open onDismiss={onDismiss}>
        <Dialog.Heading withBorder>
          {__('Soundbite categories', 'sbInfo:soundbiteCategories')}
        </Dialog.Heading>
        <Dialog.CloseButton />
        <Dialog.Body>
          <CategoriesWrapper>
            <ul>
              {categoriesData().map(category => (
                <li key={category.category} className="category">
                  <div className="category-icon">
                    <CategoryIcon name={category.category} />
                  </div>
                  <div className="category-description">
                    <h4>{category.name}</h4>
                    <p>{category.description}</p>
                  </div>
                </li>
              ))}
            </ul>
          </CategoriesWrapper>
        </Dialog.Body>
      </Dialog.Container>
    );
  }
);
