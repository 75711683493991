import * as React from 'react';
import { GlobalMessage } from 'naan/global-messages';
import { observer } from 'mobx-react';
import {
  useClassroomDashboardMessages,
  useLearnDashboardMessages,
} from 'components/use-global-messages';
import { styled } from '@naan/stitches.config';

const Wrapper = styled('div', {
  position: 'sticky',
  top: 0,
  zIndex: 10,
});

export const ClassroomMessageTarget = observer(() => {
  const currentMessage = useClassroomDashboardMessages();

  if (!currentMessage) {
    return null;
  }

  return (
    <Wrapper>
      <GlobalMessage
        type={currentMessage.type}
        message={currentMessage}
        // onDismiss={MessageService.clear}
        // @armando is this a reasonable approach?
        onDismiss={currentMessage.onDismiss}
        elementId={currentMessage.elementId}
      />
    </Wrapper>
  );
});

export const LearnMessageTarget = observer(() => {
  const currentMessage = useLearnDashboardMessages();

  if (!currentMessage) {
    return null;
  }

  return (
    <Wrapper>
      <GlobalMessage
        type={currentMessage.type}
        message={currentMessage}
        // onDismiss={MessageService.clear}
        // @armando is this a reasonable approach?
        onDismiss={currentMessage.onDismiss}
        elementId={currentMessage.elementId}
      />
    </Wrapper>
  );
});
