import { TFilterQueryParameters } from 'common/filtering-core';

export function stringifyQuery(query: TFilterQueryParameters): string {
  const { filterType, filterValue, sorting } = query;

  const parts = [];
  if (filterType && filterValue) {
    parts.push(`filter_type=${filterType}`);
    parts.push(`filter_value=${filterValue.join(',')}`);
  }

  if (sorting) {
    parts.push(`sorting=${sorting}`);
  }

  return parts.join('&');

  // return `${
  //   filterType
  //     ? `filter_type=${filterType}&filter_value=${filterValue?.join(',')}&`
  //     : ''
  // }${sorting ? `sorting=${sorting}` : ''}`;
}
