import * as React from 'react';
// import { observer } from 'mobx-react';

import { Story } from '@core/models/story-manager';
import { AppFactory } from '@app/app-factory';
import { PointsIcon } from '@naan/icons/points-icon';
import { keyframes, styled } from '@naan/stitches.config';
// import { animate } from 'framer-motion';
import { useReaction } from '@common/hooks/use-reaction';
import { uniq } from 'lodash';
import classNames from 'classnames';

const slideOut = keyframes({
  from: {
    transform: 'translateY(0)',
    opacity: 1,
  },
  to: {
    transform: 'translateY(-100%)',
    opacity: 0,
  },
});

const slideIn = keyframes({
  from: {
    opacity: 9,
    transform: 'translateY(100%)',
  },
  to: {
    opacity: 1,
    transform: 'translateY(0)',
  },
});

const PointsDisplayWrapper = styled('div', {
  $$iconSize: '20px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 4,
  textStyle: 'small-heading',

  svg: {
    width: '$$iconSize',
    height: '$$iconSize',
  },

  '& > .points-icon': {
    color: '$yellow-500',
    marginRight: -2,
  },

  '& > .counter': {
    display: 'grid',
    // background: 'red',
    '&.animated > *': {
      gridArea: '1/1',
      background: '$white',
      transform: 'translateY(-100%)',
      opacity: 0,
      // prev to last child
      '&:nth-last-child(2)': {
        transform: 'translateY(0)',
        opacity: 1,
        animation: `${slideOut} 0.2s ease-out`,
      },
      '&:last-child': {
        transform: 'translateY(0)',
        opacity: 1,
        animation: `${slideIn} 0.2s ease-out`,
      },
    },
  },
});

export const StoryPointsDisplay = ({ story }: { story: Story }) => {
  const { userManager } = AppFactory.root;
  const { userData } = userManager;
  const [storyPoints, setStoryPoints] = React.useState<number[]>([
    userData.totalPointsForStory(story.slug),
  ]);

  useReaction(
    () => userData.totalPointsForStory(story.slug),
    points => {
      setTimeout(() => {
        setStoryPoints(arr => {
          const newArr = [...arr];
          newArr.push(points);
          return uniq(newArr);
        });
      }, 1000);
    }
  );

  return (
    <PointsDisplayWrapper>
      <span className="points-icon">
        <PointsIcon />
      </span>
      <div
        className={classNames('counter', {
          animated: storyPoints.length > 1,
        })}
      >
        {storyPoints.map((points, index) => {
          return <div key={index}>{points}</div>;
        })}
      </div>
    </PointsDisplayWrapper>
  );
};
