import { bugsnagNotify } from '@app/notification-service';
// import { AppFactory } from '@app/app-factory';
import { createLogger } from 'app/logger';
const log = createLogger('wrapped-audio-element');

export class WrappedAudioElement {
  src: string;
  audio: HTMLAudioElement;

  constructor(src: string) {
    this.src = src;
    // log.debug(`src: ${src}`);
    this.audio = this.src ? new Audio(this.src) : new Audio();
    this.audio.onerror = () => this.resetAndPlay();
  }

  printDebug() {
    log.debug('src: ' + this.audio.src);
    log.debug('muted: ' + this.audio.muted);
    log.debug('volume: ' + this.audio.volume);
    log.debug('ended: ' + this.audio.ended);
    log.debug('seekable: ' + this.audio.seekable);
    log.debug('duration: ' + this.audio.duration);
    log.debug('currentTime: ' + this.audio.currentTime);
    log.debug('readyState: ' + this.audio.readyState);
    log.debug('networkState: ' + this.audio.networkState);
    const error = this.audio.error;
    if (error) {
      log.debug('error:', error);
    }
  }

  resetAndPlay() {
    if (
      this.audio.error &&
      (this.audio.error.code === 2 || this.audio.error.code === 3)
    ) {
      log.debug('resetAndPlay');
      const currentTime = this.audio.currentTime;
      const volume = this.audio.volume;
      const playbackRate = this.audio.playbackRate;
      this.audio = new Audio(this.audio.src);
      this.audio.currentTime = currentTime;
      this.audio.volume = volume;
      this.audio.playbackRate = playbackRate;
      this.audio.onerror = () => this.resetAndPlay();

      this.play();
      // console.log('*** RESET AUDIO');
      // console.log('RESET AUDIO');
      // console.log('RESET AUDIO ***');
    }
  }

  play() {
    // this.printDebug();

    const promise = this.audio.play();

    // if (promise !== undefined) {
    if (typeof promise?.catch === 'function') {
      promise.catch(bugsnagNotify);
    } else {
      if (promise === undefined) {
        log.debug('audio.play() returned undefined');
      } else {
        bugsnagNotify(
          `unexpected response from audio.play(): ${String(promise)}`
        );
      }
    }

    // or this just this good enough?
    // const promise = this.audio.play()?.catch(bugsnagNotify);
  }

  pause() {
    return this.audio.pause();
  }

  // experimentally attempt to kick ios 17.4 to trigger 'canplaythrough' event
  async awaitPlayPause() {
    try {
      const profileStartTime = Date.now();
      await this.audio.play();
      this.audio.pause();
      log.info(`awaitPlayPause took: ${Date.now() - profileStartTime}ms`);
      // if (
      //   AppFactory.root.userManager.userData.userSettings.autoDownloadEnabled
      // ) {
      //   (null as any).foo();
      // }
      return true;
    } catch (error) {
      // this was erroring out for armando with blob urls
      // NotAllowedError
      // The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission.
      // https://jiveworld.sentry.io/issues/5112302177/events/1ab35c0ccd0042dc9cecf14bbd109481/
      log.error('awaitPlayPause error:', String(error));
      bugsnagNotify(error as Error);
      return false;
    }
  }

  addEventListener(key: any, f: () => any) {
    return this.audio.addEventListener(key, f);
  }

  get currentTime() {
    return this.audio.currentTime;
  }

  set currentTime(val) {
    this.audio.currentTime = val;
  }

  get playbackRate() {
    return this.audio.playbackRate;
  }

  set playbackRate(val) {
    this.audio.playbackRate = val;
  }

  get duration() {
    return this.audio.duration;
  }

  get volume() {
    return this.audio.volume;
  }

  set volume(val) {
    this.audio.volume = val;
  }
}
