import React from 'react';

export const GoogleIcon = () => {
  return (
    <svg width={32} height={32} viewBox="0 0 32 32">
      <defs>
        <path
          id="a"
          d="M30.91 13.09H16v6.183h8.582c-.8 3.927-4.146 6.182-8.582 6.182A9.436 9.436 0 016.545 16 9.436 9.436 0 0116 6.545c2.255 0 4.29.8 5.89 2.11L26.546 4C23.71 1.527 20.073 0 16 0 7.127 0 0 7.127 0 16c0 8.873 7.127 16 16 16 8 0 15.273-5.818 15.273-16 0-.945-.146-1.964-.364-2.91z"
        />
        <path
          id="c"
          d="M30.91 13.09H16v6.183h8.582c-.8 3.927-4.146 6.182-8.582 6.182A9.436 9.436 0 016.545 16 9.436 9.436 0 0116 6.545c2.255 0 4.29.8 5.89 2.11L26.546 4C23.71 1.527 20.073 0 16 0 7.127 0 0 7.127 0 16c0 8.873 7.127 16 16 16 8 0 15.273-5.818 15.273-16 0-.945-.146-1.964-.364-2.91z"
        />
        <path
          id="e"
          d="M30.91 13.09H16v6.183h8.582c-.8 3.927-4.146 6.182-8.582 6.182A9.436 9.436 0 016.545 16 9.436 9.436 0 0116 6.545c2.255 0 4.29.8 5.89 2.11L26.546 4C23.71 1.527 20.073 0 16 0 7.127 0 0 7.127 0 16c0 8.873 7.127 16 16 16 8 0 15.273-5.818 15.273-16 0-.945-.146-1.964-.364-2.91z"
        />
        <path
          id="g"
          d="M30.91 13.09H16v6.183h8.582c-.8 3.927-4.146 6.182-8.582 6.182A9.436 9.436 0 016.545 16 9.436 9.436 0 0116 6.545c2.255 0 4.29.8 5.89 2.11L26.546 4C23.71 1.527 20.073 0 16 0 7.127 0 0 7.127 0 16c0 8.873 7.127 16 16 16 8 0 15.273-5.818 15.273-16 0-.945-.146-1.964-.364-2.91z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="b" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <path
          fill="#FBBC05"
          fillRule="nonzero"
          mask="url(#b)"
          d="M-1.455 25.455V6.545L10.909 16z"
        />
        <mask id="d" fill="#fff">
          <use xlinkHref="#c" />
        </mask>
        <path
          fill="#EA4335"
          fillRule="nonzero"
          mask="url(#d)"
          d="M-1.455 6.545L10.909 16 16 11.564l17.455-2.837V-1.455h-34.91z"
        />
        <mask id="f" fill="#fff">
          <use xlinkHref="#e" />
        </mask>
        <path
          fill="#34A853"
          fillRule="nonzero"
          mask="url(#f)"
          d="M-1.455 25.455L20.364 8.727l5.745.728 7.346-10.91v34.91h-34.91z"
        />
        <mask id="h" fill="#fff">
          <use xlinkHref="#g" />
        </mask>
        <path
          fill="#4285F4"
          fillRule="nonzero"
          mask="url(#h)"
          d="M33.455 33.455L10.909 16 8 13.818l25.455-7.273z"
        />
      </g>
    </svg>
  );
};
