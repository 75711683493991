import {
  AdhocRangeElement,
  WordElement,
  IElement,
  WordIdRange,
  IDTOfKT,
  ElementList,
} from '../basic-types';

export function makeAdhocRangeElement(
  range: WordIdRange,
  words: ElementList<WordElement>
): AdhocRangeElement {
  const address = words.getIndex(range.begin);
  const endAddress = words.getIndex(range.end);
  const result: AdhocRangeElement = {
    kind: 'ADHOC_RANGE',
    id: ('ADHOC_RANGE:' +
      range.begin +
      ':' +
      range.end) as IDTOfKT<'ADHOC_RANGE'>,
    range,
    address,
    endAddress,
  };
  // TODO work out the strong typing?
  return result;
}

export function isRangeElement(
  element: IElement
): element is AdhocRangeElement {
  return element.kind === 'ADHOC_RANGE';
}
