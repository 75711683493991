import * as React from 'react';
import { ChapterCatalogData } from '@core/models/catalog';
import { styled } from '@naan/stitches.config';
import { useChapterRowUiContext } from '../chapter-row-ui-model';
import { ChapterStudyButton } from '../chapter-study-button';
import classNames from 'classnames';

const ButtonsWrapper = styled('div', {
  display: 'flex',
  gap: 12,

  '&.compact': {
    gap: 7,
    opacity: 0.9,
  },
});

const modes = ['study', 'listen', 'completed'] as const;
export const ChapterCardButtons = ({
  chapter,
  compact,
}: {
  chapter: ChapterCatalogData;
  compact: boolean;
}) => {
  const chapterUiModel = useChapterRowUiContext();

  const getCheckStatus = React.useCallback(
    (mode: typeof modes[number]) => {
      switch (mode) {
        case 'study':
          // return chapter.isFirstListenComplete;
          return chapter.currentPoint.studyComplete;
        case 'listen':
          // return chapter.isSecondListenComplete;
          return chapter.currentPoint.listenComplete;
        case 'completed':
          return chapter.isCompleted;
      }
    },
    [chapter]
  );

  return (
    <ButtonsWrapper className={classNames({ compact })}>
      {modes.map(mode => (
        <ChapterStudyButton
          key={mode}
          mode={mode}
          selected={chapterUiModel.currentStudyMode === mode}
          checked={getCheckStatus(mode)}
          compact={compact}
        />
      ))}
    </ButtonsWrapper>
  );
};
