import * as React from 'react';
import { observer } from 'mobx-react';
import { darkThemeSelector, styled } from '@naan/stitches.config';
import { IconButton } from '@naan/primitives/button';
import { ChevronDownIcon } from '@naan/icons/chevron-down-icon';
import { useChapterReviewModel } from './review-ui';
import { useReaction } from '@common/hooks/use-reaction';
import { toggleClassname } from '@utils/toggle-class';

const RightWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'row-reverse',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: 8,
});

const TitleContainer = styled('div', {
  width: '100%',
  textWrap: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  color: '$globalWhite',
  textStyle: 'body-bold',
});

const HeaderContainer = styled('div', {
  // $$backgroundColor: paletteKey('$$header__backgroundColor'),
  $$backgroundColor: '$colors$purple-900',
  [darkThemeSelector]: {
    $$backgroundColor: '$colors$purple-200',
  },
  position: 'absolute',
  top: '0',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 'var(--top-bar-height)',
  // height: '56px', // TODO: put in a variable
  padding: '0 8px 4px',
  flexShrink: '0',
  background: '$$backgroundColor',
  zIndex: '2',
  transition: 'transform .3s, background .3s',

  '&.playing': {
    transform: 'translateY(-100%)',
    // $$backgroundColor: '$colors$gray-300',
  },

  '& > .center': {
    overflow: 'hidden',
    display: 'flex',
    '@medium': {
      position: 'absolute',
      alignItems: 'center',
      left: '50%',
      transform: 'translateX(-50%)',
      top: 0,
      bottom: 0,
    },
  },

  '& > .right': {
    marginLeft: 'auto',
    marginRight: 8,
    '&  .counter': {
      color: '$globalWhite',
    },
  },

  '& > .progressbar-container': {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: 4,
    background: '$colors$purple-900',
    [darkThemeSelector]: {
      background: '$colors$purple-200',
    },
  },
});

const ProgressBar = styled('div', {
  $$progress: '0%',
  $$color1: '$colors$purple-400',
  $$color2: '$colors$globalBlack-alpha-10',
  [darkThemeSelector]: {
    $$color1: '$colors$purple-600',
  },
  height: 4,
  width: '100%',
  background: `
  linear-gradient(90deg, $$color1, $$color1 $$progress, $$color2 $$progress)

  `,
  // transition: 'background 0.12s ease-in',
  // background: `linear-gradient(
  //       90deg,
  //       red 0%,
  //       green 50%, green 100%,
  //     )`,
});

// @armando, what the React.memo important here? it's not clear how that
// would interplay with the mobx oberserver which is needed to dismiss the popover
// export const StudyHeader = React.memo(() => {
export const ReviewHeader = observer(
  ({ onDismiss }: { onDismiss: () => void }) => {
    // const popoverDismissed =
    //   OnboardingService.instance.isDismissed('naturalListen');
    // const showPopover = OnboardingService.instance.showNaturalListenTip;

    const ref = React.useRef<HTMLDivElement>(null);
    // const model = AppFactory.chapterReviewModel;
    const model = useChapterReviewModel();

    useReaction(
      () => model.isPlaying,
      () => {
        toggleClassname(ref.current, 'playing', model.isPlaying);
      }
    );

    (window as any).reviewModel = model;

    return (
      <>
        <HeaderContainer ref={ref}>
          <div className="left">
            <IconButton
              onClick={() => {
                onDismiss();
                model.recordDismisal();
              }}
              icon={<ChevronDownIcon />}
              presentation="whiteTransparent"
              testId="study-close-control"
            />
          </div>
          <div className="center">
            <TitleContainer>
              {`${model.chapter?.position}. ${model.chapter?.title}`}
            </TitleContainer>
          </div>
          <div className="right">
            <RightWrapper>
              <div className="counter">
                {model.displayCurrentPage}/{model.pagesCount}
              </div>
            </RightWrapper>
          </div>
          <div className="progressbar-container">
            <ProgressBar
              style={
                {
                  '---progress': model.completedPercentage,
                } as any
              }
            />
          </div>
        </HeaderContainer>
      </>
    );
  }
);
