import * as React from 'react';
import { ChevronDownIcon } from 'naan/icons/chevron-down-icon';
import { IconButton } from '@naan/primitives/button';
import { useExitFromStudy } from 'lib/hooks/use-navigate-to-study';

export const CloseControl = () => {
  const exitFromStudy = useExitFromStudy();

  return (
    <IconButton
      onClick={exitFromStudy}
      icon={<ChevronDownIcon />}
      presentation="whiteTransparent"
      testId="study-close-control"
    />
  );
};
