import * as React from 'react';

export function CopyIcon({ width = 24, height = 24, color = 'currentColor' }) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 2C4.34315 2 3 3.34315 3 5V15C3 16.6569 4.34315 18 6 18H7V19C7 20.6569 8.34315 22 10 22H18C19.6569 22 21 20.6569 21 19V9C21 7.34315 19.6569 6 18 6V5C18 3.34315 16.6569 2 15 2H6ZM16 6V5C16 4.44772 15.5523 4 15 4H6C5.44772 4 5 4.44772 5 5V15C5 15.5523 5.44772 16 6 16H7V9C7 7.34315 8.34315 6 10 6H16ZM9 9C9 8.44772 9.44772 8 10 8H18C18.5523 8 19 8.44772 19 9V19C19 19.5523 18.5523 20 18 20H10C9.44772 20 9 19.5523 9 19V9Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}
export function CopySmallIcon({
  width = 20,
  height = 20,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 2C3.89543 2 3 2.89543 3 4V12C3 13.1046 3.89543 14 5 14H7V16C7 17.1046 7.89543 18 9 18H15C16.1046 18 17 17.1046 17 16V8C17 6.89543 16.1046 6 15 6H13V4C13 2.89543 12.1046 2 11 2H5ZM12 6V4C12 3.44772 11.5523 3 11 3H5C4.44772 3 4 3.44772 4 4V12C4 12.5523 4.44772 13 5 13H7V8C7 6.89543 7.89543 6 9 6H12ZM8 14V16C8 16.5523 8.44772 17 9 17H15C15.5523 17 16 16.5523 16 16V8C16 7.44772 15.5523 7 15 7H9C8.44772 7 8 7.44772 8 8V13V14Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}
