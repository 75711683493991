import { styled } from '@naan/stitches.config';
import { paletteKey } from '../../../study/views/study-palette';

export const ControlButton = styled('button', {
  all: 'unset',
  display: 'flex',
  width: 64,
  height: 64,
  alignItems: 'center',
  justifyContent: 'center',
  color: paletteKey('$$transport__buttonColor'),
  textStyle: 'body-bold',
  '&[disabled]': {
    color: paletteKey('$$transport__buttonColor_disabled'),
  },
});
