import * as React from 'react';

export function CloseLargeIcon({
  width = 28,
  height = 28,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 28 28"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.22183 4.80762L4.80762 6.22183L12.5858 14L4.80762 21.7782L6.22183 23.1924L14 15.4142L21.7782 23.1924L23.1924 21.7782L15.4142 14L23.1924 6.22183L21.7782 4.80762L14 12.5858L6.22183 4.80762Z"
        fill={color}
      />
    </svg>
  );
}

export function CloseSmallIcon({
  width = 20,
  height = 20,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.05026 3.63605L3.63605 5.05026L8.58579 10L3.63605 14.9498L5.05026 16.364L10 11.4142L14.9498 16.364L16.364 14.9498L11.4142 10L16.364 5.05026L14.9498 3.63605L10 8.58579L5.05026 3.63605Z"
        fill={color}
      />
    </svg>
  );
}

export function CloseIcon({ width = 24, height = 24, color = 'currentColor' }) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 13.4142L18.2929 19.7071L19.7071 18.2929L13.4142 12L19.7071 5.70712L18.2929 4.29291L12 10.5858L5.70712 4.29291L4.29291 5.70712L10.5858 12L4.29291 18.2929L5.70712 19.7071L12 13.4142Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}
