import React from 'react';
import { observer } from 'mobx-react';
import { HStack, VStack } from 'naan/primitives/stack';
import { HSpacer } from 'naan/primitives/spacer';
import { Button, IconButton } from 'naan/primitives/button';
import { AppFactory } from 'app/app-factory';
import { notEmpty } from '@utils/conditionals';
import { Input } from '@naan/primitives/input';
import { useComboForm } from '@common/hooks/use-comboform';
import { NotificationService } from '@app/notification-service';
import { ArrowLeftExtraSmallIcon } from '@naan/icons/arrow-left-icon';
import { ArrowRightExtraSmallIcon } from '@naan/icons/arrow-right-icon';
import { styled } from '@naan/stitches.config';

// import { createLogger } from '@common/log';
// const log = createLogger('current-date-dev-ui');

type FormInput = {
  date: string;
};

export const CurrentDateDevUI = observer(() => {
  const { storyManager } = AppFactory.root;

  const defaultValues = { date: storyManager.currentDate };

  const {
    register,
    // handleSubmit,
    // errors,
    // clearValidationErrors,
    // handleValidationErrors,
  } = useComboForm<FormInput>({
    /*validationSchema: nameSchema,*/ defaultValues,
  });

  const handleUpdate = (e: any) => {
    e.preventDefault();
    const form = new FormData(e.target);
    const date = form.get('date').toString();
    if (notEmpty(date)) {
      storyManager.setCurrentDate(date);
      NotificationService.open({
        message: 'Updated',
        type: 'success',
      });
    }
  };

  return (
    <>
      <form onSubmit={handleUpdate} noValidate>
        <VStack>
          <HStack align={'center'}>
            <CurrentDateSpinner />
            <HSpacer size={2} />
            -
            <HSpacer size={2} />
            <Input name={'date'} size={10} type="text" {...register('date')} />
            {/* <CatchAllFormErrors errors={errors} data-test-id="error-message" /> */}
            <HSpacer size={1} />
            <Button label="Set" />{' '}
          </HStack>
        </VStack>
      </form>
    </>
  );
});

const Label = styled('div', {
  textStyle: 'small-text',
});

export const CurrentDateSpinner = observer(() => {
  const { storyManager } = AppFactory.root;

  return (
    <HStack align="center">
      <Label>Current Date</Label>
      <HStack align="center">
        <IconButton
          size={'small'}
          onClick={() => storyManager.adjustCurrentDate(-1)}
          icon={<ArrowLeftExtraSmallIcon />}
          testId="decrement-date"
        />
        <Label>{storyManager.currentDate} </Label>
        <HSpacer size={2} />
        <IconButton
          size={'small'}
          onClick={() => storyManager.adjustCurrentDate(1)}
          icon={<ArrowRightExtraSmallIcon />}
          testId="increment-date"
        />
      </HStack>
    </HStack>
  );
});
