import Dayjs from 'dayjs';

import __ from '@core/lib/localization';
import { weeksSinceEpoch } from '@utils/date-utils';
import { alertWarningError } from '@app/notification-service';

const shortFormatDate = (dayjs: Dayjs.Dayjs) =>
  dayjs.format(__('MMMM D', 'weekFormat'));

export function getWeekTitle(
  weekStart: Dayjs.Dayjs,
  today: Dayjs.Dayjs = Dayjs()
): string {
  if (!weekStart || !today) {
    // todo: factor out an 'assert' pattern
    alertWarningError({ error: Error('getWeekTitle - invalid args') });
    return '';
  }

  if (weeksSinceEpoch(today.toDate()) === weeksSinceEpoch(weekStart.toDate())) {
    return __('This week', 'thisWeek');
  }

  const from = shortFormatDate(weekStart);
  const weekEnd = weekStart.add(6, 'day');

  return weekStart.month() === weekEnd.month()
    ? `${from}–${weekEnd.date()}`
    : `${from}–${shortFormatDate(weekEnd)}`;
}

// // this wasn't handling sundays correctly
// export function getWeekTitle(
//   weekIndex: number,
//   now: Date = new Date()
// ): string {
//   if (weekIndex === 0) {
//     return __('This week', 'thisWeek');
//   }

//   const MILLIS_PER_DAY = 24 * 60 * 60 * 1000;
//   const currentWeekStart = new Date(
//     now.getTime() - (now.getDay() - 1) * MILLIS_PER_DAY
//   );
//   const prevWeekStart = new Date(
//     currentWeekStart.getTime() - weekIndex * 7 * MILLIS_PER_DAY
//   );
//   const prevWeekEnd = new Date(prevWeekStart.getTime() + 6 * MILLIS_PER_DAY);
//   const startMonth = prevWeekStart.getMonth();
//   const endMonth = prevWeekEnd.getMonth();
//   const from = shortFormatDate(prevWeekStart);
//   const prevWeekTitle =
//     startMonth === endMonth
//       ? `${from}–${prevWeekEnd.getDate()}`
//       : `${from} – ${shortFormatDate(prevWeekEnd)}`;

//   return prevWeekTitle;
// }
