import { keyboardService } from 'lib/services/keyboard-service';

export const HELP_OVERLAY_ID = 'help-overlay'; // todo rename this id to "keyboard-overlay"

let resetEscapeListener: (() => void) | null = null;

export function openKeyboardOverlay() {
  const overlay = document.getElementById(HELP_OVERLAY_ID);
  if (overlay) {
    overlay.classList.add('open');
    resetEscapeListener = keyboardService.addModalShortcut('escape', () =>
      closeKeyboardOverlay()
    );
  }
}

export function closeKeyboardOverlay() {
  const overlay = document.getElementById(HELP_OVERLAY_ID);
  if (overlay) {
    overlay.classList.remove('open');
    if (resetEscapeListener) {
      resetEscapeListener();
      resetEscapeListener = null;
    }
  }
}

export function toggleKeyboardOverlay() {
  const overlay = document.getElementById(HELP_OVERLAY_ID);
  if (overlay?.classList.contains('open')) {
    closeKeyboardOverlay();
  } else {
    openKeyboardOverlay();
  }
}
