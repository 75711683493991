import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { observer } from 'mobx-react';
import { styled } from '@naan/stitches.config';
import { Story } from '@core/models/story-manager';
import { MarkSimple } from '@naan/primitives/text';
import { TitleStatus } from './story-title';
import { Image } from '@naan/primitives/image';
import { learnStorySlugPath } from 'components/nav/path-helpers';

import __ from '@core/lib/localization';

const Link = styled(RouterLink, {
  all: 'unset',
  paddingInline: 16,
  paddingBlock: 12,
  backgroundColor: '$colors$purple-100',
  borderRadius: '$radii$large',
  cursor: 'pointer',

  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  gap: 12,
  alignItems: 'center',

  '& > img.thumbnail': {
    width: 40,
    height: 40,
    objectFit: 'cover',
    borderRadius: '$radii$medium',
  },

  '& > .content': {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 0,

    '& > .header': {
      gap: 3,
      display: 'flex',
      alignItems: 'center',
      '& > .title': {
        textStyle: 'body-bold',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },

    '& > .meta': {
      textStyle: 'small-text',
      color: '$colors$black-alpha-60',
    },
  },

  '@medium': {
    paddingBlock: 18,
    paddingInline: 20,
  },
});

export const VocabMiniStoryCard = observer(({ story }: { story: Story }) => {
  if (!story) {
    return null;
  }

  const permalink = learnStorySlugPath(story.slug);
  const { title, listImageUrl } = story;

  return (
    <Link to={permalink}>
      <Image src={listImageUrl} alt={title} className="thumbnail" />
      <div className="content">
        <header className="header">
          <TitleStatus story={story} />
          <span className="title">
            <MarkSimple source={story.title} />
          </span>
        </header>
        <div className="meta">
          {__('%{count} to review', 'nToReview', { count: story.vocabCount })}
        </div>
      </div>
    </Link>
  );
});
