import { Story } from 'core/models/story-manager/story';
import {
  TFilter,
  // TFilterQueryParameters,
  TSortingDescription,
} from '../filtering-core';

import { createContext } from 'react';
import { TFilterKey } from './filter-keys';
import { TSortingKey } from './sorting-keys';

type TStory = Story;

export type TFilteringContext = {
  setSortingKey: (sortingKey: TSortingKey) => void;
  addFilter: (key: TFilterKey, value: string, replace?: boolean) => void;
  replaceFilter: (key: TFilterKey, value: string, replace?: boolean) => void;
  removeValueFromFilter: (value: string, replace?: boolean) => void;
  getFilter: (key: TFilterKey) => string[] | null;
  resetAllFilters: (replace?: boolean) => void;
  // setSearch: (search?: string) => void;
  // getQuery: () => TFilterQueryParameters;
  filter: TFilter | null;
  sorting: TSortingDescription;
  filteredUnits: TStory[]; // todo: revisit interface depedency
};

export const FilteringContext = createContext<Partial<TFilteringContext>>({});
