import { alertLevels } from './alert-levels';
import { GenericError } from './generic-error';

const { ERROR } = alertLevels;

export class APIError extends GenericError {
  /**
   * @param {string} key - the key of the validation error
   */
  key?: string;

  /**
   * @param {string} type - the server classname. used to detect validation errors. not sure if used for APIErrors
   */
  type?: string;

  constructor(error: { type?: string; message?: string; key?: string }) {
    super(error.message ?? '');
    this.context = { error };
    this.key = error.key;
    this.type = error.type; // don't think this is currently used, but might as well capture when provided by the server
    this.alertLevel = ERROR;
    this.report = false;
    this.expected = true;
  }
}
