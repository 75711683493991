import * as React from 'react';
// import { observer } from 'mobx-react';
import { getVideoAssets } from 'video-assets/natural-listening';
import {
  VideoInterstitial,
  VideoInterstitialProps,
} from './component/video-interstitial';
import __ from '@core/lib/localization';

export const NaturalListenInterstitial = ({
  onForcePass,
  onDismiss,
}: VideoInterstitialProps) => {
  return (
    <VideoInterstitial
      title={__('Natural Listen', 'naturalListen')}
      onForcePass={onForcePass}
      onDismiss={onDismiss}
      getVideoAssets={getVideoAssets}
      videoAchievementKey="video:natural-listen"
    />
  );
};
