import React from 'react';
import { observer } from 'mobx-react';
import { PrevSentenceControlIcon } from '../../../study/views/player-icons';
import { ControlButton } from './control-button';
import { usePlayerModel } from '../player-model-context';

export const PrevSentenceButton = observer(() => {
  const model = usePlayerModel();
  return (
    <ControlButton
      onClick={() => model.seekPreviousLine()}
      disabled={!model.canSeekPreviousLine}
    >
      <PrevSentenceControlIcon />
    </ControlButton>
  );
});
