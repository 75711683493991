import * as React from 'react';
import { AppFactory } from '@app/app-factory';
import { createLogger } from '@common/log';

const log = createLogger('use-visibility-awareness');

export const useVisibilityAwareness = () => {
  log.debug('hook body');
  const root = AppFactory.root;

  React.useEffect(() => {
    log.debug('useEffect');
    // note, cold start listening triggered via UserManager logic

    const visibilityListener = () => {
      log.debug(`visibilityListener - state: ${document.visibilityState}`);
      if (document.visibilityState === 'visible') {
        root.becameVisible();
      } else {
        root.becameHidden();
      }
    };
    document.addEventListener('visibilitychange', visibilityListener);

    // not sure why the focus hook wouldn't work, but with firestore now visibility is a good trigger
    // const focusListener = () => {
    //   log.debug(`focusListener - state: ${document.visibilityState}`);
    //   root.userManager.syncFromServerIfStale();
    //   root.userManager.startListen();
    // };
    // // @armando, this 'focus' hook doesn't seem to work. any idea why?
    // window.addEventListener('focus', focusListener);

    return () => {
      log.debug('cleaning up');
      // probably not needed, but this is how it's been
      root.becameHidden();
    };
  }, [root]);
};
