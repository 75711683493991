import * as React from 'react';
import { styled } from '@naan/stitches.config';
import { IconButton } from '@naan/primitives/button';
import { CloseIcon } from '@naan/icons/close-icon';
import { Image as NaanImage } from '@naan/primitives/image';

const CloseButtonWrapper = styled('div', {
  position: 'absolute',
  top: '8px',
  right: '8px',
});

type IconButtonProps = React.ComponentProps<typeof IconButton>;

export const CloseButton = ({
  onClick,
}: {
  onClick: IconButtonProps['onClick'];
}) => {
  return (
    <CloseButtonWrapper>
      <IconButton
        size="small"
        icon={<CloseIcon />}
        onClick={onClick}
        testId="close-button"
      />
    </CloseButtonWrapper>
  );
};

export const ButtonContainer = styled('div', {
  marginTop: '$space$3',
});

export const Image = styled(NaanImage, {
  maxWidth: 'min(100%, 420px)',
  maxHeight: '300px',
  display: 'block',
  variants: {
    size: {
      small: {
        '@medium': {
          display: 'none',
        },
      },
      medium: {
        display: 'none',
        '@medium': {
          display: 'block',
        },
      },
    },
  },
});

export const Figure = styled('div', {
  marginTop: '24px',
  display: 'flex',
  justifyContent: 'center',
  '@large': {
    marginTop: 0,
    width: 'calc(50% - 12px)',
  },
  variants: {
    bleed: {
      top: {
        '@medium': {
          marginTop: '-24px',
          alignSelf: 'flex-start',
        },
      },
      bottom: {
        marginBottom: '-20px',
        '@medium': {
          marginBottom: '-24px',
          alignSelf: 'flex-end',
        },
      },
    },
  },
});

export const Title = styled('h3', {
  textStyle: 'small-heading',
});

export const Body = styled('div', {
  textStyle: 'body',

  [`& > ${Title}`]: {
    marginBottom: '$space$2',
  },

  [`& > ${ButtonContainer}`]: {
    marginTop: '$space$3',
  },
});

export const Container = styled('div', {
  background: '$yellow-200',
  borderRadius: '$radii$large',
  position: 'relative',
  padding: '16px 16px 20px',
  marginBottom: '16px',

  '@medium': {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '24px',
    padding: '24px',
    marginBottom: '24px',
  },

  [`&:has(${CloseButtonWrapper})`]: {
    [`& ${Figure}`]: {
      '@medium': {
        paddingRight: '32px',
      },
    },
    [`& ${Title}`]: {
      '@medium': {
        paddingRight: '24px',
      },
    },
  },

  [`& ${Body}`]: {
    '@medium': {
      maxWidth: '640px', // @frank 620px caused the link text to wrap
    },
  },

  [`&:has(${Figure})`]: {
    [`& ${Body}`]: {
      '@medium': {
        width: 'calc(50% - 12px)',
      },
    },
  },
});
