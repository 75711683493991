import React from 'react';
import { observer } from 'mobx-react';
import { Responsive } from '@naan/primitives/responsive';
import { MobilePlaybackRateControl } from './mobile-playback-rate-control';
import { DesktopPlaybackRateControl } from './desktop-playback-rate-control';
import { PlaybackRateControlTip } from 'components/ui/onboarding/speed-control-tip';

export const PlaybackRateControl = observer(() => {
  return (
    <PlaybackRateControlTip>
      <Responsive
        renderDefault={() => <MobilePlaybackRateControl />}
        renderLargeAndUp={() => <DesktopPlaybackRateControl />}
      />
    </PlaybackRateControlTip>
  );
});
