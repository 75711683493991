import * as React from 'react';
import { observer } from 'mobx-react';
import { Soundbite } from '@core/models/story-manager/soundbite';
import { SoundbiteCategory } from 'components/soundbites/soundbite-categories';
import { SoundbiteCategoriesInfo } from 'components/soundbites/soundbite-categories';
import { icons } from 'components/soundbites/soundbites-icons';
import { styled } from '@naan/stitches.config';
import { createLogger } from '@common/log';
import { openSoundbitesHelpDialog } from 'components/soundbites/soundbite-welcome-dialog';

const log = createLogger('sb:script-header-category-info');

const Wrapper = styled('button', {
  all: 'unset',
  cursor: 'default',
  display: 'flex',
  flexDirection: 'row',
  gap: 4,
  color: '$globalWhite',
  textStyle: 'small-text',
  '& svg': {
    width: 20,
    height: 20,
  },
  '@widerThanScript': {
    textStyle: 'body',
    '& svg': {
      width: 24,
      height: 24,
    },
  },
});

export const ScriptHeaderCategoryInfo = observer(
  ({ soundbite }: { soundbite: Soundbite }) => {
    const category = soundbite?.category as SoundbiteCategory;
    const categoryInfo = SoundbiteCategoriesInfo[category];

    if (!categoryInfo) {
      log.error(`missing categoryInfo for ${String(category)}`);
      return null;
    }

    const iconSet = icons[soundbite.category as SoundbiteCategory] ?? null;
    const SmallIcon = iconSet?.small ?? null;

    return (
      <Wrapper onClick={openSoundbitesHelpDialog}>
        {SmallIcon && <SmallIcon presentation="dark" />}
        {categoryInfo.labelFn()}
      </Wrapper>
    );
  }
);
