import * as React from 'react';

import { ChapterNote } from '@tikka/client/client-aliases';
import { PlainMarkdown } from '@naan/primitives/text';
import { Button } from '@naan/primitives/button';
import { useSwitch2 } from '@naan/hooks/use-switch-2';
import { AppFactory } from 'app/app-factory';
import { ChapterNotesDialog } from './chapter-notes-dialog';
import { styled } from '@naan/stitches.config';
import { namedObserver } from 'lib/named-observer';
import __ from '@core/lib/localization';

const NotesWrapper = styled('div', {
  '& > .note ': {
    padding: '16px 0',
  },
  '& > .note + .note': {
    borderTop: '1px solid $colors$gray-100',
  },
});

export const ChapterNotes = namedObserver(
  'ChapterNotes',
  ({ notes }: { notes: ChapterNote[] }) => {
    const model = AppFactory.studyModel;
    const notesSwitch = useSwitch2(false);

    if (!notes.length) {
      return null;
    }

    const defaultOpen = model.onloadModalNeeded;

    return (
      <ChapterNotesDialog
        onDismiss={notesSwitch.off}
        defaultOpen={defaultOpen}
        trigger={
          <span>
            <Button
              label={__('Notes', 'notes')}
              presentation={model.fluentListenMode ? 'gray' : 'grayLight'}
              onClick={notesSwitch.toggle}
            />
          </span>
        }
      >
        <NotesWrapper>
          {notes.map(note => (
            <div className="note" key={note.id}>
              <PlainMarkdown
                source={note.text.l1 || note.text.l2}
                key={note.id}
              />
            </div>
          ))}
        </NotesWrapper>
      </ChapterNotesDialog>
    );
  }
);
