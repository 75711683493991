import * as React from 'react';

export function ArrowDownExtraSmallIcon({
  width = 16,
  height = 16,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.99309 10.1287L6.99309 2.04297L8.99309 2.04297L8.99309 10.1288L11.286 7.83586L12.7002 9.25008L8.99309 12.9572L8.99309 13.043L8.90732 13.043L8.70021 13.2501L7.9931 13.9572L7.286 13.2501L7.07889 13.043L6.99309 13.043L6.99309 12.9572L3.286 9.25007L4.70021 7.83586L6.99309 10.1287Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </svg>
  );
}

export function ArrowDownIcon({
  width = 24,
  height = 24,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 17.9782L19.2716 11.3146L20.728 12.6854L11.9998 21.9591L3.27163 12.6854L4.72803 11.3146L11 17.9786L11 2L13 2L13 17.9782Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}
