import { isEmbeddedPreV8, postNativeMessage } from '@app/app-util';
import { makeObservable, observable, action } from 'mobx';

export class EmbeddedWakeLockManager {
  type = 'embedded';
  active = false;

  constructor() {
    makeObservable(this, {
      active: observable,
      enable: action,
      disable: action,
    });
  }

  enable(): void {
    if (isEmbeddedPreV8()) {
      postNativeMessage({
        type: 'string',
        payload: 'WEB-VIEW-NO-SLEEP-ENABLE',
      });
    } else {
      postNativeMessage({ type: 'ACTIVATE_KEEP_AWAKE' });
    }
    this.active = true;
  }

  disable(): void {
    if (isEmbeddedPreV8()) {
      postNativeMessage({
        type: 'string',
        payload: 'WEB-VIEW-NO-SLEEP-DISABLE',
      });
    } else {
      postNativeMessage({ type: 'DEACTIVATE_KEEP_AWAKE' });
    }
    this.active = false;
  }
}
