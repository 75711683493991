export const events = {
  vocabularyReview: {
    startReview: 'vocabularyReview__start_review',
    endReview: 'vocabularyReview__end_review',
    closeReview: 'vocabularyReview__close_review',
  },
  onboardingVideos: {
    skipVideo: 'onboardingVideos__skip_video',
    restartVideo: 'onboardingVideos__restart_video',
    startVideo: 'onboardingVideos__start_video',
    completeVideo: 'onboardingVideos__complete_video',
    exitVideo: 'onboardingVideos__exit_video',
  },
  shareMenu: {
    shareAction: 'shareMenu__share_action',
  },
  feedback: {
    openFeedback: 'feedback__open_feedback',
    sendFeedback: 'feedback__send_feedback',
    closeFeedbackWithoutSending: 'feedback__close_feedback_without_sending',
  },
  classroom: {
    openStudentVocabModal: 'classroom__open_student_vocab_modal',
    openStudentSoundbitesModal: 'classroom__open_student_soundbites_modal',
    manageStudentAssignment: 'classroom__manage_student_assignment',
    favoriteStory: 'classroom__favorite_story',
  },
  settings: {
    dismissDarkModeDashboardMessage:
      'settings__dismiss_dark_mode_dashboard_message',
    openSettings: 'settings__open_settings',
    changedTheme: 'settings__changed_theme',
    autoDownload: 'settings__auto_download',
    showVocabExport: 'settings__show_vocab_export',
    toggleImmersiveView: 'settings__toggle_immersive_view',
    toggleCompactSentenceToolbar: 'settings__toggle_compact_sentence_toolbar',
    toggleAutoDownload: 'settings__toggle_auto_download',
  },
} as const;

export type EventName =
  typeof events[keyof typeof events][keyof typeof events[keyof typeof events]];
