import * as React from 'react';

import { observer } from 'mobx-react';
import { styled } from '@naan/stitches.config';
import { VocabListModalContent } from './vocablist-content';
import { DialogHtmlContainer } from 'components/ui/dialog-html-container';
import { Story } from '@core/models/story-manager';
import { StudentProgress } from '@core/models/user-manager';
import { keynameForEvent } from 'lib/services/keyboard-service';
import { track } from '@app/track';
import { events } from '@common/instrumentation/events';

const Container = styled(DialogHtmlContainer, {
  width: 'min( 600px, calc(100% - 32px) )',
  borderRadius: '$radii$large',
  overflow: 'hidden',
});

export const StudentVocabModal = observer(
  ({
    story,
    studentProgress,
    onDismiss,
  }: {
    story: Story;
    studentProgress: StudentProgress;
    onDismiss: () => void;
  }) => {
    // /// For some reason, keyvbordService doesn't work while this modal is opened
    React.useEffect(() => {
      const listener = (e: KeyboardEvent) => {
        const shortcutName = keynameForEvent(e);
        if (shortcutName === 'escape') {
          onDismiss();
        }
      };
      document.addEventListener('keydown', listener);
      return () => {
        document.removeEventListener('keydown', listener);
      };
    }, [onDismiss]);

    React.useEffect(() => {
      track(events.classroom.openStudentVocabModal, {
        story: story.title,
      });
    }, [story.title]);

    return (
      <Container
        /// this disables the backdrop click to dismiss
        /// because at this point in the tree we don't have access to the model to save.
        /// we can find a better way to slice the model stuff
        onDismiss={() => {}}
      >
        <VocabListModalContent
          story={story}
          progress={studentProgress.storyProgress}
          studentProgress={studentProgress}
          onDismiss={onDismiss}
        />
      </Container>
    );
  }
);
