import React, { useEffect } from 'react';
import { useStripeCheckout } from '../common/stripe-context';
import { SpinnerIcon } from '../components/ui/icons/spinner-icon';
import { useParams } from 'react-router-dom';
import { styled } from '@naan/stitches.config';
import { alertSevereError } from '@app/notification-service';

const cleanAddressBar = () => {
  var currentUrl = window.location.href.split('?')[0];
  window.history.replaceState({}, '', currentUrl);
};

const Container = styled('div', {
  display: 'flex',
  width: '100vw',
  height: '100vh',
  justifyContent: 'center',
  alignItems: 'center',
  background: '$colors$blue-700',
});

export const RedirectToStripeScreen = () => {
  const params = useParams();
  const stripeCheckout = useStripeCheckout();

  useEffect(() => {
    const checkoutToken = params?.token;

    if (checkoutToken) {
      cleanAddressBar();
      stripeCheckout(checkoutToken).catch(error => {
        alertSevereError({
          error,
          note: 'RedirectToStripeScreen - stripeCheckout',
        });
      });
    }
  }, [stripeCheckout, params]);

  return (
    <Container>
      <SpinnerIcon />
    </Container>
  );
};
