import React from 'react';

type ProgressPieProps = {
  current: number;
  color?: string;
  outlineWidth?: number;
  size?: number;
};

export const ProgressPie = ({
  current,
  color = 'currentColor',
  outlineWidth = 2,
  size = 20,
}: ProgressPieProps) => {
  const minXy = -outlineWidth / 2;
  const r = size / 2;
  const d = 2 * r;
  const f = Math.PI * r;
  const currentP = current * f;
  return (
    <svg
      width={size}
      height={size}
      viewBox={`${minXy} ${minXy} ${d + outlineWidth} ${d + outlineWidth}`}
    >
      <circle
        r={r}
        cx={r}
        cy={r}
        stroke={color}
        fill="none"
        strokeWidth={outlineWidth}
      />
      {currentP > 0 ? (
        <circle
          r={r / 2}
          cx={r}
          cy={r}
          fill="none"
          stroke={color}
          strokeWidth={r}
          strokeDasharray={`${currentP} ${f}`}
          transform={`rotate(-90) translate(-${r * 2})`}
        />
      ) : null}
    </svg>
  );
};
