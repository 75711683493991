import * as React from 'react';
import { styled } from 'naan/stitches.config';
import { Illustration } from './illustration';
import { IconButton } from 'naan/primitives/button';
import { CloseIcon } from 'naan/icons/close-icon';
import { useWelcomeMessage } from './use-welcome-message';
import { observer } from 'mobx-react';
import __ from '@core/lib/localization';
import { PlainMarkdown } from '@naan/primitives/text';

const WelcomeMessageBox = styled('div', {
  background: '$yellow-200',
  borderRadius: '$radii$large',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  minHeight: 'calc($space$1 * 64)',
  overflow: 'hidden',
  marginTop: '24px',
  '@medium': {
    marginTop: '24px',
    paddingRight: '50%',
  },
  '& > .copy': {
    padding: '$space$3',
    overflow: 'hidden',
    '@medium': {
      padding: '$space$6',
    },
  },
  '& > .button-container': {
    position: 'absolute',
    right: '$space$1',
    top: '$space$1',
  },
  h2: {
    all: 'unset',
    textStyle: 'small-heading',
    '@large': {
      textStyle: 'medium-heading',
    },
  },
  '& p': {
    marginTop: '$space$3',
  },
  '& > svg': {
    // all: 'unset',

    '@medium': {
      position: 'absolute',
      right: '16px',
      bottom: '0',
      maxWidth: '50%',
    },
  },
});

export const TeachWelcomeMessage = observer(() => {
  // hook now handles the newsletter prompt guard
  const { show, onClose } = useWelcomeMessage();

  if (!show) {
    return null;
  }

  return (
    <WelcomeMessageBox>
      <div className="copy" data-test-id="classroom-banner">
        <h2>
          {__(
            'Welcome to Jiveworld Classrooms',
            'welcomeToJiveworldClassroooms'
          )}
        </h2>
        <div>
          <p>
            <PlainMarkdown
              source={__(
                `Browse the stories below, and when you find one you'd like to teach with, click **Assign to class**. We've unlocked one of the stories, with its Activity Guide, so you can try Jiveworld with your students for free.`,
                'browseTheStoriesBelowAndWhenYouFindOneYoudLike'
              )}
            />
          </p>
        </div>
      </div>
      <div className="button-container">
        <IconButton
          aria-label="dismiss welcome message"
          icon={<CloseIcon />}
          css={{
            color: '$yellow-800',
          }}
          onClick={onClose}
          testId="close-welcome-message"
        />
      </div>
      <Illustration />
    </WelcomeMessageBox>
  );
});
