import React from 'react';
import { observer } from 'mobx-react';
import { Navigate, useLocation } from 'react-router-dom';
import useSWRImmutable from 'swr/immutable';
import { getQueryParamsFromLocation } from '@common/object-from-url-query';
import { learnStoriesPath } from 'components/nav/path-helpers';

import { createLogger } from 'app/logger';
import { AppFactory } from '@app/app-factory';
const log = createLogger('masala-preview-screen');

const assimilateVolumeData = async (url: string): Promise<string> => {
  // const response = await fetch(url);
  // const data = (await response.json()) as Story;

  const { storyManager } = AppFactory.root;
  const slug = storyManager.assimilateVolumeData(url);
  return slug;
  // return data.slug;
};

export const MasalaPreviewScreen = observer(() => {
  const location = useLocation();
  let { url } = getQueryParamsFromLocation(location);

  let { data: slug, error } = useSWRImmutable(url, assimilateVolumeData);

  if (slug === undefined && !error) {
    return <div className="App">loading...</div>;
  }

  if (error) {
    return <div className="App">{String(error)}</div>;
  }
  const path = `${learnStoriesPath()}/${slug}`;
  log.info(`redirecting to: ${path}`);

  return <Navigate to={path} replace />;
});
