import * as React from 'react';
import { observer } from 'mobx-react';
import { ClientNotation } from '@tikka/client/client-types';
import { usePlayerModel } from 'player/views/player-model-context';
import {
  ChapterReviewModel,
  statusIs,
} from 'vocab-review/chapter-review-model';
import { LearnItem } from './learn-item';
import { ManageItem } from './manage-item';

export const VocabListItem = observer(
  ({ notation }: { notation: ClientNotation }) => {
    const model = usePlayerModel() as ChapterReviewModel;
    const status = model.vocabStatus(notation.id);
    const isManageable = statusIs(
      status,
      'not-added',
      'just-added',
      'previously-learned'
    );

    if (isManageable) {
      return <ManageItem notation={notation} status={status} />;
    } else {
      return <LearnItem notation={notation} status={status} />;
    }
  }
);
