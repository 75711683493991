import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import { isEmpty, omit } from 'lodash';
import { AppFactory } from 'app/app-factory';
import { useSwitch } from '@naan/hooks/use-switch';
import { Button } from '@naan/primitives/button';
import { FormError } from '@naan/primitives/forms/form-error';
import { AuthSmallPrint } from 'components/auth/auth-small-print';
import { useAfterAuthRedirection } from 'lib/hooks/use-after-auth-redirection';
import { ConfirmEmailDialog } from 'components/auth/confirm-email-dialog';
import { VSpacer } from '@naan/primitives/spacer';
import { useComboForm } from 'common/hooks/use-comboform';
import { Label } from '@naan/primitives/forms/label';
import { Input } from '@naan/primitives/input/input';
import { PasswordInput } from '@naan/primitives/input';
import { CatchAllFormErrors } from '@naan/primitives/forms/catch-all-form-error';
import { AuthHeading } from 'components/auth/auth-heading';
import { getSignUpModel } from './sign-up-model';
import { BackButton } from 'components/page-heading';
import { PagingPills } from './paging-pills';
import { OnboardingAuthLayout } from './onboarding-auth-layout';

import __ from 'core/lib/localization';

type FormInput = {
  name: string;
  email: string;
  password: string;
  schoolName: string;
  inviteCode: string;
};

export const SignUpEmailScreen = observer(() => {
  // useTitle(__('Get started', 'getStarted'));
  const navigate = useNavigate();
  useAfterAuthRedirection();

  const { userManager, storyManager } = AppFactory.root;

  const {
    register,
    handleSubmit,
    errors,
    clearValidationErrors,
    handleValidationErrors,
    getValues,
  } = useComboForm<FormInput>({});

  const [showingDialog, showingDialogSwitch] = useSwitch(false);

  const submitHandler = handleSubmit(({ email, password }) => {
    const model = getSignUpModel();
    model.email = email;
    model.password = password;
    if (isEmpty(model.password)) {
      model.password = `MAKE-THIS-OPTIONAL-${Math.round(
        Math.random() * 1000000
      )}`;
    }

    showingDialogSwitch.on();
  });

  const onConfirmEmail = () => {
    const model = getSignUpModel();
    const { name, email, password } = model;

    showingDialogSwitch.off();
    clearValidationErrors();

    userManager
      .signup({
        name,
        email,
        password,
        school_name: '',
        invite_code: '',
      })
      .then(() => {}, handleValidationErrors);
  };

  const getEmail = () => getValues()?.email;
  // const getEmail = () => model.email;

  const backPath = storyManager.onboardingStoryPath;

  return (
    <OnboardingAuthLayout>
      {/* todo: factor back button handling and needs a new layout w/o branded back button */}
      <div className="back-link">
        <BackButton action={() => navigate(backPath, { replace: true })} />
      </div>
      <div className="inner">
        <PagingPills total={2} currentIndex={1} />
        <AuthHeading>
          {__('Create your free account', 'createYourFreeAccount')}
        </AuthHeading>
        <form onSubmit={submitHandler}>
          <div className="form-contents">
            <CatchAllFormErrors
              errors={omit(errors, ['email'])}
              data-test-id="error-message"
            />

            <Label htmlFor="email">{__('Email', 'email')}</Label>
            <VSpacer size="2" />
            <Input
              name={'email'}
              data-test-id={'email-address'}
              data-lpignore="true"
              autoComplete="email"
              type="email"
              {...register('email')}
              required
            />
            <FormError
              error={errors['email']}
              data-test-id="email-error-message"
            />
          </div>
          <div className="form-contents">
            <Label htmlFor="password">{__('Password', 'password')}</Label>
            <p className="hint">
              {__(
                'Optional — you can also sign in by email code',
                'optionalAlsoSignInByCode'
              )}
            </p>
            <VSpacer size={3} />
            <PasswordInput
              name={'password'}
              data-test-id={'password'}
              data-lpignore="true"
              autoComplete="off"
              {...register('password')}
              placeholder={''}
            />
          </div>

          <Button
            label={__('Next', 'next')}
            onClick={handleSubmit as any}
            presentation="teal"
            size="large"
            data-test-id="create-account-btn"
            fullWidth
          />
        </form>
        <VSpacer size={10} />

        <AuthSmallPrint />
        {showingDialog ? (
          <ConfirmEmailDialog
            email={getEmail()}
            onOk={onConfirmEmail}
            onCancel={showingDialogSwitch.off}
          />
        ) : null}
      </div>
    </OnboardingAuthLayout>
  );
});
