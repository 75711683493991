import * as React from 'react';

export function AlertTriangleSmallIcon({
  width = 20,
  height = 20,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.69267 2.32424L2.25744 13.7646C1.695 14.7646 2.41757 16 3.56481 16H16.4353C17.5825 16 18.3051 14.7645 17.7426 13.7646L11.3074 2.32424C10.7339 1.30476 9.26613 1.30476 8.69267 2.32424ZM9 5H11V11H9V5ZM9.99998 14C10.5523 14 11 13.5523 11 13C11 12.4477 10.5523 12 9.99998 12C9.4477 12 8.99998 12.4477 8.99998 13C8.99998 13.5523 9.4477 14 9.99998 14Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}

export function AlertTriangleIcon({
  width = 24,
  height = 24,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.7201 3.09441L2.39467 16.7178C1.78385 17.7173 2.5032 19 3.6746 19H20.3254C21.4968 19 22.2162 17.7173 21.6053 16.7178L13.2799 3.09441C12.695 2.13731 11.305 2.13731 10.7201 3.09441ZM11 7.00001H13V13H11V7.00001ZM12 17C12.5523 17 13 16.5523 13 16C13 15.4477 12.5523 15 12 15C11.4477 15 11 15.4477 11 16C11 16.5523 11.4477 17 12 17Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}
