import * as React from 'react';
import { Image } from '@naan/primitives/image';
import { PlainMarkdownWithLinks } from '@naan/primitives/text';
import { MarkdownStyleWrapper } from '@naan/primitives/text/markdown/markdown-style-wrapper';

import { styled } from '@naan/stitches.config';

import imageSrc from './ra-feature-panel-image.png';

import { __f } from '@core/lib/localization';

const Wrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 16,

  backgroundColor: '$blue-50',
  borderRadius: '$radii$large',
  padding: '24px 16px',

  '& h2': {
    textStyle: 'small-heading',
    marginBottom: 16,
  },

  '& img': {
    width: '100%',
    maxWidth: 396,
  },

  '@medium': {
    flexDirection: 'row',
    padding: '24px',
    '& img': {
      padding: '0 24px',
      maxWidth: '50%',
    },
  },
});

const titleFn = __f('Uniquely Latin American Stories', 'aboutRaTitle');

const blurbFn = __f(
  `Radio Ambulante is a Spanish-language podcast, distributed by NPR, that tells Latin American stories from everywhere Spanish is spoken, including the United States. With more than 200 stories, reported from over 20 countries, Radio Ambulante is a sonic portrait of a region in all its complexity. Their journalism has won numerous accolades, including the prestigious Gabriel García Márquez Prize.

Discover more at [radioambulante.org](http://radioambulante.org).`,
  'aboutRaBlurb'
);

export const aboutRaId = 'about-ra';

// ra branded by parent components
export const AboutRadioAmbulante = () => {
  return (
    <Wrapper id={aboutRaId}>
      <Image src={imageSrc} alt="Radio Ambulante" />
      <div className="text">
        <h2>{titleFn()}</h2>
        <MarkdownStyleWrapper>
          <PlainMarkdownWithLinks source={blurbFn()} />
        </MarkdownStyleWrapper>
      </div>
    </Wrapper>
  );
};

// export const AboutJiveworldGerman = () => {
//   return (
//     <Wrapper id={aboutRaId}>
//       {/* <Image src={imageSrc} alt="[tbd]" /> */}
//       <div className="text">
//         {/* <h2>{title}</h2> */}
//         <MarkdownStyleWrapper>
//           <PlainMarkdownWithLinks source="[german mode about panel TBD]" />
//         </MarkdownStyleWrapper>
//       </div>
//     </Wrapper>
//   );
// };

// export const AboutJiveworldEnglish = () => {
//   // return (
//   //   <Wrapper id={aboutRaId}>
//   //     {/* <Image src={imageSrc} alt="[tbd]" /> */}
//   //     <div className="text">
//   //       {/* <h2>{title}</h2> */}
//   //       <MarkdownStyleWrapper>
//   //         <PlainMarkdownWithLinks source="[English content about panel TBD]" />
//   //       </MarkdownStyleWrapper>
//   //     </div>
//   //   </Wrapper>
//   // );
//   return <></>; // until we have something better
// };
