import * as React from 'react';

export function AlertIcon({ width = 24, height = 24, color = 'currentColor' }) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM11.9902 5.00037C12.7549 5.00037 13.402 5.38562 13.402 6.06847C13.402 7.11028 13.3334 8.37975 13.2647 9.64922C13.1961 10.9187 13.1275 12.1882 13.1275 13.23C13.1275 13.7728 12.4608 14.0004 11.9902 14.0004C11.3629 14.0004 10.8334 13.7728 10.8334 13.23C10.8334 12.1882 10.7648 10.9187 10.6962 9.64922L10.6962 9.64916C10.6276 8.37971 10.559 7.11027 10.559 6.06847C10.559 5.38562 11.1863 5.00037 11.9902 5.00037ZM12.0098 18.4999C11.1471 18.4999 10.5 17.7986 10.5 16.9999C10.5 16.1817 11.147 15.4999 12.0098 15.4999C12.8137 15.4999 13.5 16.1817 13.5 16.9999C13.5 17.7986 12.8137 18.4999 12.0098 18.4999Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}
