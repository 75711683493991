import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import {
  GoogleLogin,
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from 'react-google-login';
import { GoogleIcon } from 'components/ui/icons/google-icon';
import {
  alertError,
  alertSevereError,
} from 'lib/services/notification-service';
import { appConfig } from 'app/env';
import { styled } from 'naan/stitches.config';
import cx from 'classnames';
import { AppFactory } from '@app/app-factory';

const GOOGLE_CLIENT_ID = appConfig.google.clientId;

const Wrapper = styled('div', {
  $$backgroundColor: '$colors$white',
  $$borderColor: '$colors$gray-100',
  $$textColor: '$colors$gray-600',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& button': {
    border: '1px solid $$borderColor',
    borderRadius: '50px',
    background: 'none',
    backgroundColor: '$$backgroundColor',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '4px 8px',
    // cursor: 'pointer',

    '& svg': {
      paddingRight: '$space$2',
    },

    '& span': {
      textStyle: 'body',
      color: '$$textColor',
      paddingRight: '$space$1',
    },

    '&:focus': {
      outline: 'none',
    },
    '&:hover': {
      $$backgroundColor: '$colors$gray-25',
      $$borderColor: '$colors$gray-200',
      $$textColor: '$colors$gray-700',
    },
  },
});

export const GoogleButton = observer(
  ({ title, tabIndex = 0 }: { title: string; tabIndex?: number }) => {
    const navigate = useNavigate();

    const { userManager } = AppFactory.root;

    useEffect(() => {
      if (userManager.loggedInAndReady) {
        navigate('/account');
      }
    }, [userManager.loggedInAndReady, navigate]);

    const onGoogleSuccess = (
      response: GoogleLoginResponse | GoogleLoginResponseOffline
    ) => {
      if ('tokenId' in response) {
        userManager
          .omniauth('google', response.accessToken)
          .catch(error => alertSevereError({ error, note: 'google-button' }));
      } else {
        alertError('Google login failed');
      }
    };

    const onGoogleError = (error: any) => {
      // const err = new Error('Google auth error');
      // err.context = error;
      // console.log(error);
      if (error.details) {
        // this error returned by google is not an actual Error instance,
        // if we pass it as-is, bugsnag will complain and log the complaint instead of the error
        alertError(error.details, { exception: new Error(error.details) });
        return;
        // throw new Error(error.details);
      }
      throw error;
    };

    return (
      <GoogleLogin
        clientId={GOOGLE_CLIENT_ID}
        buttonText="Login"
        onSuccess={onGoogleSuccess}
        onFailure={onGoogleError}
        cookiePolicy={'single_host_origin'}
        render={({ onClick, disabled }) => (
          <Wrapper className={cx({ disabled })}>
            <button onClick={onClick} tabIndex={tabIndex}>
              <GoogleIcon />
              <span>{title}</span>
            </button>
          </Wrapper>
        )}
      />
    );
  }
);
