import { createAtom, IAtom } from 'mobx';

export type MembershipRecord = {
  mobxAtom: IAtom;
  memberships: number;
};

export function createMembershipEnumFuncs(obj: any) {
  let lastKeys: string[] = null;
  let bitValue = 0;
  const memberships = new Map<string, MembershipRecord>();

  const getMembershipRecord = (id: string) => {
    let membershipRecord = memberships.get(id);
    if (!membershipRecord) {
      membershipRecord = {
        mobxAtom: createAtom('memberships:' + id),
        memberships: 0,
      };
      memberships.set(id, membershipRecord);
    }
    return membershipRecord;
  };

  const getAndTouchMembershipRecord = (id: string) => {
    const membershipRecord = getMembershipRecord(id);
    membershipRecord.mobxAtom.reportObserved();
    return membershipRecord;
  };

  const setMemberships = (id: string, keys: string[], remove: boolean) => {
    const membershipRecord = getMembershipRecord(id);

    if (keys !== lastKeys) {
      bitValue = 0;
      for (const key of keys) {
        bitValue |= obj[key];
        if (bitValue === undefined) {
          // TODO throw;
        }
      }
      lastKeys = keys;
    }

    const prevValue = membershipRecord.memberships;
    let nextValue = prevValue;
    if (remove) {
      nextValue = prevValue & ~bitValue;
    } else {
      nextValue = prevValue | bitValue;
    }
    membershipRecord.memberships = nextValue;

    if (nextValue !== prevValue) {
      membershipRecord.mobxAtom.reportChanged();
    }
  };

  return {
    setMemberships,
    getMemberships: (id: string) => getAndTouchMembershipRecord(id).memberships,
  };
}
