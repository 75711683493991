import * as React from 'react';
import * as Onboarding from './components';
import __ from 'core/lib/localization';

export const SoundbitesModalTip = () => {
  return (
    <Onboarding.Container>
      <Onboarding.Title>
        {__('Show me the Soundbites', 'showMeTheSoundbites')}
      </Onboarding.Title>
      <Onboarding.Content>
        {__(
          'You can see the full list of Soundbites for this story here, whether you’ve played them or not.',
          'youCanSeeTheFullListOfSoundbitesForThisStory'
        )}
      </Onboarding.Content>
    </Onboarding.Container>
  );
};
