import { Rgba } from './rgba';

interface HSL {
  h: number; // hue (0-360)
  s: number; // saturation (0-100)
  l: number; // lightness (0-100)
  a: number; // alpha (0-1)
}

// Add cache at the top of the file
const hslCache = new Map<string, HSL>();

export function hexToHsl(hex: string, alpha?: number): HSL {
  // Normalize hex format for consistent cache keys
  const normalizedHex = hex.replace(/^#/, '').toLowerCase();

  // Check cache first
  const cached = hslCache.get(normalizedHex);
  if (cached) {
    return cached;
  }

  // Convert hex to rgb
  const r = parseInt(normalizedHex.slice(0, 2), 16) / 255;
  const g = parseInt(normalizedHex.slice(2, 4), 16) / 255;
  const b = parseInt(normalizedHex.slice(4, 6), 16) / 255;

  // Find min and max values
  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);

  let h = 0;
  let s = 0;
  let l = (max + min) / 2;

  if (max !== min) {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }

  // Convert to degrees and percentages
  const result = {
    h: Math.round(h * 360),
    s: Math.round(s * 100),
    l: Math.round(l * 100),
    a: alpha ?? 1,
  };

  // Store in cache before returning
  hslCache.set(normalizedHex, result);
  return result;
}

export function hslToRgba({ h, s, l, a }: HSL): Rgba {
  // Convert percentages to decimals
  s /= 100;
  l /= 100;

  // Helper function to convert hue to rgb
  const hueToRgb = (p: number, q: number, t: number): number => {
    if (t < 0) t += 1;
    if (t > 1) t -= 1;
    if (t < 1 / 6) return p + (q - p) * 6 * t;
    if (t < 1 / 2) return q;
    if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
    return p;
  };

  // Calculate rgb values
  const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
  const p = 2 * l - q;
  const hue = h / 360;

  const r = Math.round(hueToRgb(p, q, hue + 1 / 3) * 255);
  const g = Math.round(hueToRgb(p, q, hue) * 255);
  const b = Math.round(hueToRgb(p, q, hue - 1 / 3) * 255);

  return {
    r,
    g,
    b,
    a: a ?? 1,
  };
}

// Darkens the HSL color by reducing the luminosity to a maximum of 40%
export function darkenHsl(hsl: HSL, maxLuminosity = 40): HSL {
  // reduce luminosity
  const l = Math.min(hsl.l, maxLuminosity);
  // when we darken a color, it appears more saturated – so we also reduce the saturation by a proportional amount
  const darkenAmount = Math.max(hsl.l - l, 0);
  const s = Math.max(hsl.s - darkenAmount * 0.5, 0);
  // console.log(`darkened by ${darkenAmount}, desaturated by ${hsl.s - s}`);
  return { ...hsl, s: s, l: l };
}

// Helper function to convert HSL back to string format
export function hslToString({ h, s, l, a }: HSL): string {
  return `hsla(${h}, ${s}%, ${l}%, ${a})`;
}
