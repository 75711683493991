import * as React from 'react';

export function ApSmallIcon({
  width = 20,
  height = 20,
  color = 'currentColor',
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.40039 6.94043L2.83008 15H0.451172L4.13574 5.04688H5.63965L5.40039 6.94043ZM7.5332 15L4.95605 6.94043L4.68945 5.04688H6.21387L9.91211 15H7.5332ZM7.42383 11.2949V13.0176H2.23535V11.2949H7.42383Z"
        fill={color}
      />
      <path
        d="M15.2231 11.541H12.7211V9.81152H15.2231C15.5922 9.81152 15.8885 9.75 16.1118 9.62695C16.3396 9.50391 16.506 9.33529 16.6108 9.12109C16.7202 8.90234 16.7748 8.65625 16.7748 8.38281C16.7748 8.10026 16.7202 7.83822 16.6108 7.59668C16.506 7.35059 16.3396 7.15234 16.1118 7.00195C15.8885 6.85156 15.5922 6.77637 15.2231 6.77637H13.5278V15H11.2993V5.04688H15.2231C16.0161 5.04688 16.6951 5.19043 17.2602 5.47754C17.8299 5.76465 18.2651 6.15885 18.5659 6.66016C18.8712 7.1569 19.0239 7.72656 19.0239 8.36914C19.0239 9.01172 18.8712 9.56999 18.5659 10.0439C18.2651 10.5179 17.8299 10.887 17.2602 11.1514C16.6951 11.4111 16.0161 11.541 15.2231 11.541Z"
        fill={color}
      />
    </svg>
  );
}
