import React from 'react';
import { observer } from 'mobx-react';
import { Button } from '@naan/primitives/button';
import { EyeIcon } from '@naan/icons/eye-icon';
import { AppFactory } from 'app/app-factory';
import { EarIcon } from '@naan/icons/ear-icon';

import __ from '@core/lib/localization';

export const StudyFromHereButton = observer(() => {
  const model = AppFactory.studyModel;

  const action = React.useCallback(() => {
    model.togglePlayerMode();

    if (model.isPlaying) {
      model.pauseAfterCurrentSentence();
    }
  }, [model]);

  const reviewMode = model.chapter.isReviewing;
  const noSelectedSentence = model.currentLineId === null;

  if (reviewMode && noSelectedSentence && model.fluentListenMode) {
    return (
      <Button
        onClick={action}
        label={__('Switch to Study', 'switchToStudy')}
        size="medium"
        leftIcon={<EyeIcon />}
        presentation="grayDark"
      />
    );
  }

  return null;
});

export const ListenFromHereButton = observer(() => {
  const model = AppFactory.studyModel;
  const action = React.useCallback(() => {
    model.closeCurrentSentenceInlineExtras();
    model.togglePlayerMode();
  }, [model]);

  if (!model.showListenFromHere) {
    return null;
  }

  return (
    <Button
      onClick={action}
      label={__('Resume Natural Listen', 'resumeNaturalListen')}
      size="medium"
      leftIcon={<EarIcon />}
      presentation="grayDark"
    />
  );
});
