import { AccentsIconLarge, AccentsIconSmall } from './accents';
import { CultureIconLarge, CultureIconSmall } from './culture';
import { GrammarIconLarge, GrammarIconSmall } from './grammar';
import { IdiomsIconLarge, IdiomsIconSmall } from './idioms';
import { PatternsIconLarge, PatternsIconSmall } from './patterns';
import { StrategiesIconLarge, StrategiesIconSmall } from './strategies';
import { VernacularIconLarge, VernacularIconSmall } from './vernacular';
import { VocabularyIconLarge, VocabularyIconSmall } from './vocabulary';

// canonical list here: https://jiveworld.slite.com/app/docs/f9jXaGo-PxyZer

export const icons = {
  accents: {
    large: AccentsIconLarge,
    small: AccentsIconSmall,
  },
  vernacular: {
    large: VernacularIconLarge,
    small: VernacularIconSmall,
  },
  vocabulary: {
    large: VocabularyIconLarge,
    small: VocabularyIconSmall,
  },
  grammar: {
    large: GrammarIconLarge,
    small: GrammarIconSmall,
  },
  patterns: {
    large: PatternsIconLarge,
    small: PatternsIconSmall,
  },
  idioms: {
    large: IdiomsIconLarge,
    small: IdiomsIconSmall,
  },
  strategies: {
    large: StrategiesIconLarge,
    small: StrategiesIconSmall,
  },
  culture: {
    large: CultureIconLarge,
    small: CultureIconSmall,
  },
} as const;
