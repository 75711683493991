import * as React from 'react';

export const ArrowRightExtraSmallIcon = ({
  color = 'currentColor',
  ...props
}) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0858 9.00009H2V7.00009H10.0858L7.79289 4.70718L9.20711 3.29297L12.9142 7.00009H13V7.08586L13.2071 7.29297L13.9142 8.00008L13.2071 8.70718L13 8.91429V9.00009H12.9142L9.20711 12.7072L7.79289 11.293L10.0858 9.00009Z"
      fill={color}
    />
  </svg>
);

export function ArrowRightSmallIcon({
  width = 20,
  height = 20,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.9423 11H2V9H13.9423L9.32172 4.7348L10.6783 3.2652L16.891 9H17V9.10063L17.1783 9.2652L17.9743 10L17.1783 10.7348L17 10.8994V11H16.891L10.6783 16.7348L9.32172 15.2652L13.9423 11Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}

export function ArrowRightIcon({
  width = 24,
  height = 24,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.9782 11L11.3146 4.72837L12.6854 3.27197L21.9591 12.0002L12.6854 20.7284L11.3146 19.272L17.9786 13H2V11H17.9782Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}
