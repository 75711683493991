import micromatch from './micromatch';
import { LogFunction, LogLevel } from './logger-types';
import loggly from '../../legacylib/loggly';
import { appConfig } from 'app/env';
import { bufferLogger } from './buffer-logger';

// console.log('logger-config');

const noop = () => {
  return;
};

const logglyLog = (...args: any[]) => {
  loggly.log(String(args));
};

const resolveLogFunction = () => {
  switch (appConfig.logging.impl) {
    case 'buffer':
      return bufferLogger.log;

    case 'loggly':
      return logglyLog;

    case 'console':
      // eslint-disable-next-line no-console
      return console.log;

    default:
      return noop;
  }
};

export const setLogLevel = (level: LogLevel) => {
  LoggerConfig.instance.minimumLogLevel = level;
};

export class LoggerConfig {
  private static _instance?: LoggerConfig;

  public disabled: string[] = [];

  public logFunction: LogFunction = resolveLogFunction();

  public minimumLogLevel: LogLevel = appConfig.logging.minimumLogLevel;

  public showLevelPrefix: boolean = true;

  private constructor() {
    if (LoggerConfig._instance) {
      throw new Error('Use Singleton.instance instead of new.');
    }
    LoggerConfig._instance = this;
    (window as any)._loggerConfig = this;
  }

  static get instance() {
    return (
      LoggerConfig._instance ?? (LoggerConfig._instance = new LoggerConfig())
    );
  }

  public enable(namespace: string) {
    this.disabled = this.disabled.filter(n => n !== namespace);
  }

  public disable(namespace: string) {
    this.disabled.push(namespace);
  }

  public isDisabled(namespace: string) {
    /// is the namespace disabled?
    if (this.disabled.includes(namespace)) {
      return true;
    }

    /// let's check against the wildcards
    for (let i = 0; i < this.disabled.length; i++) {
      const pattern = this.disabled[i];
      if (micromatch(namespace, pattern)) {
        /// add the pattern to the disabled list
        /// so we don't have to check again
        this.disable(namespace);
        return true;
      }
    }

    return false;
  }
}
