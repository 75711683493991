import React from 'react';
// import { Text } from '@naan/primitives/text';
// import { MiddleColumn } from '../account-layout';
import { presentSimpleAlert } from 'components/ui/simple-dialogs';

import __ from 'core/lib/localization';
import { AppFactory } from '@app/app-factory';

// export const PurchaseFlowDisabled = () => {
//   return (
//     <MiddleColumn>
//       <Text>
//         For full access to all stories on Jiveworld Español visit jiveworld.com
//         to subscribe
//       </Text>
//     </MiddleColumn>
//   );
// };

export const presentPurchaseDisabledAlert = () => {
  const { productName } = AppFactory.root.storyManager;
  presentSimpleAlert(
    <>
      {__(
        'For full access to all stories on %{productName} visit jiveworld.com to subscribe.',
        'forFullAccessToAllStories',
        { productName }
      )}
    </>
  );
};
