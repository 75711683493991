// substituted for VITE_CONFIG_ENV during build
import { config } from './__CONFIG_ENV__';

// for typing
import { config as baseConfig } from './base';

// eslint-disable-next-line no-console
console.log('app/config/index.ts');

export type AppConfig = typeof baseConfig;

export const appConfig: AppConfig = config;

(window as any).appConfig = appConfig;
