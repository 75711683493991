import * as React from 'react';

export function PasswordShownIcon({
  width = 24,
  height = 24,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 5C7.41454 5 3.25621 7.45504 0.187788 11.4427C-0.0625959 11.7694 -0.0625959 12.2258 0.187788 12.5525C3.25621 16.545 7.41454 19 12 19C16.5855 19 20.7438 16.545 23.8122 12.5573C24.0626 12.2306 24.0626 11.7742 23.8122 11.4475C20.7438 7.45504 16.5855 5 12 5ZM12.3199 16.8362C9.35942 17.0201 6.91464 14.6114 7.10086 11.6841C7.25366 9.2707 9.23527 7.3145 11.68 7.16366C14.6405 6.97983 17.0853 9.38854 16.8991 12.3158C16.7415 14.7245 14.7599 16.6807 12.3199 16.8362ZM9.27877 11.8274C9.17041 13.4516 10.5298 14.789 12.1748 14.6868C13.5293 14.6042 14.6325 13.5197 14.7212 12.1726C14.8295 10.5484 13.4702 9.21107 11.8251 9.31319C10.4658 9.40072 9.3625 10.4852 9.27877 11.8274Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}
