import * as React from 'react';
import { AppFactory } from '@app/app-factory';
import { presentDeferredUpdateToast } from 'pwa/window/update-manager';

export const useEnableUpdatePrompt = () => {
  //
  // only allow update prompt on white-listed screens (dashboard, story list, sb cal, stats)
  //
  React.useEffect(() => {
    const { root } = AppFactory;
    root.updatePromptEnabled = true;
    if (root.updatePromptPending) {
      presentDeferredUpdateToast();
    }

    return () => {
      AppFactory.root.updatePromptEnabled = false;
    };
  }, []);
};
