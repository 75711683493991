import * as React from 'react';

import { observer } from 'mobx-react';
import { AppFactory } from 'app/app-factory';
import { useSwitch } from '@naan/hooks/use-switch';
import { Button } from 'naan/primitives/button';
import { FormError } from 'naan/primitives/forms/form-error';
import { AuthSmallPrint } from 'components/auth/auth-small-print';
import { useTitle } from 'common/hooks/use-title';
import { useAfterAuthRedirection } from 'lib/hooks/use-after-auth-redirection';
import { ConfirmEmailDialog } from 'components/auth/confirm-email-dialog';
import { VStack } from 'naan/primitives/stack';
import { VSpacer } from 'naan/primitives/spacer';
import { Checkbox } from 'components/auth/checkbox';
import { styled } from 'naan/stitches.config';
import { useComboForm } from 'common/hooks/use-comboform';
import { Label } from 'naan/primitives/forms/label';
import { Input } from 'naan/primitives/input/input';
import { PasswordInput } from 'naan/primitives/input';
import { FNAME_MAX_CHAR_LIMIT } from 'core/lib/constants/vars';
import { useSearchParams } from 'react-router-dom';
import { CatchAllFormErrors } from '@naan/primitives/forms/catch-all-form-error';
import { omit } from 'lodash';
import { AuthInlineLink } from 'components/auth/auth-inline-link';
import { AuthHeading } from 'components/auth/auth-heading';
import { marketingSiteL1, signInPath } from 'components/nav/path-helpers';
import { PlainMarkdownWithLinks } from '@naan/primitives/text';
import __ from 'core/lib/localization';

const SmallPrint = styled('div', {
  textStyle: 'small-text',
  color: '$textSecondary',
  '& a': {
    color: '$teal-500',
  },
});

type FormInput = {
  name: string;
  email: string;
  password: string;
  schoolName: string;
  inviteCode: string;
};

export const CreateAccountScreen = observer(() => {
  useTitle(__('Get started', 'getStarted'));
  const [queryParams /*, setSearch*/] = useSearchParams();
  const defaultIsTeacher = queryParams.get('teach') === 'yes';
  // clean error messages from other screens
  const { userManager } = AppFactory.root;

  useAfterAuthRedirection();

  const {
    register,
    handleSubmit,
    errors,
    clearValidationErrors,
    handleValidationErrors,
    getValues,
  } = useComboForm<FormInput>({});

  const [showingDialog, showingDialogSwitch] = useSwitch(false);
  const [isTeacher, isTeacherSwitch] = useSwitch(defaultIsTeacher);
  const [schoolNameError, setSchoolNameError] = React.useState<string | null>(
    null
  );

  const submitHandler = handleSubmit(({ schoolName }) => {
    if (isTeacher) {
      if (!schoolName) {
        setSchoolNameError(
          __(
            'Enter the name of the school or schools where you teach',
            'schoolNameRequired'
          )
        );
        return;
      }
    }
    setSchoolNameError(null);
    showingDialogSwitch.on();
  });

  const onConfirmEmail = () => {
    const { name, email, password, schoolName, inviteCode } = getValues();

    showingDialogSwitch.off();
    clearValidationErrors();

    userManager
      .signup({
        name,
        email,
        password,
        school_name: schoolName,
        invite_code: inviteCode, // no longer relevant
      })
      .then(() => {}, handleValidationErrors);
  };

  const getEmail = () => getValues()?.email;

  return (
    <VStack>
      <AuthHeading>
        {__('Create a free account', 'createAFreeAccount')}
      </AuthHeading>
      <form onSubmit={submitHandler}>
        <VStack>
          {/* // make sure invite code error is shown if mismatch between client config and server config */}
          {/* // consider always including, but making the invite code conditionaly omitted */}
          <CatchAllFormErrors
            errors={omit(errors, ['name', 'email', 'password', 'schoolName'])}
            data-test-id="error-message"
          />

          <Label htmlFor="name">{__('First name', 'firstName')}</Label>
          <VSpacer size={2} />

          <Input
            name={'name'}
            data-test-id={'first-name'}
            type="text"
            {...register('name')}
            required
            maxLength={FNAME_MAX_CHAR_LIMIT}
          />
          <FormError error={errors['name']} data-test-id="name-error-message" />

          <VSpacer size={6} />

          <Label htmlFor="email">{__('Email', 'email')}</Label>
          <VSpacer size={2} />
          <Input
            name={'email'}
            data-test-id={'email-address'}
            type="email"
            {...register('email')}
            required
          />
          <FormError
            error={errors['email']}
            data-test-id="email-error-message"
          />

          <VSpacer size={6} />

          <Label htmlFor="password">{__('Password', 'password')}</Label>
          <VSpacer size={2} />
          <PasswordInput
            name={'password'}
            data-test-id={'password'}
            {...register('password')}
            placeholder={''}
            required
          />
          <FormError
            error={errors['password']}
            data-test-id="password-error-message"
          />
          <VSpacer size={6} />
        </VStack>
        <VSpacer size={2} />
        <Checkbox
          checked={isTeacher}
          onChange={isTeacherSwitch.toggle}
          label={__('I want to teach with Jiveworld', 'teachWithJiveworld')}
        />
        {isTeacher ? (
          <>
            <VSpacer size={4} />
            <SmallPrint>
              <PlainMarkdownWithLinks
                source={__(
                  ' Enter your school name(s) to activate Jiveworld Classroom features. [Learn more](%{url}})',
                  'enterYourSchoolNameToActivateJiveworldClassroomFeatures',
                  {
                    url: marketingSiteL1('/schools/classrooms'),
                  }
                )}
              />
            </SmallPrint>
            <VSpacer size={4} />
            <Label htmlFor="schoolName">
              {__('School name(s)', 'schoolNameS')}
            </Label>
            <VSpacer size={2} />
            <Input
              name={'schoolName'}
              data-test-id={'schoolName'}
              {...register('schoolName')}
            />
            <FormError
              error={schoolNameError ? { message: schoolNameError } : undefined}
            />
          </>
        ) : null}

        <VSpacer size={8} />

        <div style={{ width: '100%', display: 'flex' }}>
          <Button
            label={__('Create account', 'createAccount')}
            onClick={handleSubmit as any}
            presentation="teal"
            size="large"
            data-test-id="create-account-btn"
            css={{ flex: 1 }}
          />
        </div>
      </form>
      <VSpacer size={10} />
      <AuthInlineLink
        text={__(
          'Already have an account? [Sign in](%{url})',
          'alreadyHaveAccountSignIn',
          { url: signInPath() }
        )}
      />
      <VSpacer size={10} />
      <AuthSmallPrint />
      {showingDialog ? (
        <ConfirmEmailDialog
          email={getEmail()}
          onOk={onConfirmEmail}
          onCancel={showingDialogSwitch.off}
        />
      ) : null}
    </VStack>
  );
});
