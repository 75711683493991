import * as React from 'react';

export function PasswordHiddenIcon({
  width = 24,
  height = 24,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.65498 17.345L2 21L2.70711 21.7071L21.7071 2.70711L21 2L16.9925 6.00754C15.4137 5.35249 13.7375 5 12 5C7.41454 5 3.25621 7.45504 0.187788 11.4427C-0.0625959 11.7694 -0.0625959 12.2258 0.187788 12.5525C1.75523 14.592 3.60707 16.2302 5.65498 17.345ZM8.08171 14.9183L9.64185 13.3582C9.37859 12.9135 9.24141 12.3875 9.27878 11.8274C9.36251 10.4852 10.4658 9.40074 11.8251 9.3132C12.379 9.27882 12.9005 9.4076 13.3444 9.65557L14.9054 8.09462C14.0124 7.44603 12.8892 7.08861 11.6801 7.1637C9.23528 7.31454 7.25368 9.27073 7.10088 11.6842C7.02342 12.9016 7.4011 14.0294 8.08171 14.9183ZM12 19C10.4379 19 8.92542 18.7151 7.48868 18.1816L9.51634 16.1799C10.3303 16.6524 11.2941 16.9 12.3199 16.8363C14.7599 16.6807 16.7415 14.7245 16.8991 12.3158C16.9636 11.3024 16.7127 10.3511 16.2343 9.5481L18.8646 6.95151C20.7067 8.05208 22.3784 9.58195 23.8122 11.4475C24.0626 11.7742 24.0626 12.2306 23.8122 12.5573C20.7438 16.545 16.5855 19 12 19ZM12.1748 14.6868C11.8176 14.709 11.4739 14.6633 11.1557 14.5616L14.5958 11.1656C14.6989 11.4798 14.7448 11.8194 14.7212 12.1727C14.6325 13.5197 13.5293 14.6042 12.1748 14.6868Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}
