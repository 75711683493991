import * as React from 'react';

export function DownloadedLargeIcon({
  width = 28,
  height = 28,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 28 28"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.7007 23.7592L26.1064 5.24653L22.8936 2.92004L11.7993 18.2407L5.23612 13.5527L2.93054 16.7805L12.7007 23.7592ZM23 24H5V26H23V24Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}

export function DownloadedExtraLargeIcon({
  width = 32,
  height = 32,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5391 26L29 5.56773L25.5344 3L13.5668 19.9093L6.48705 14.7352L4 18.2977L14.5391 26ZM26 27H6V29H26V27Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}

export function DownloadedIcon({
  width = 24,
  height = 24,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.8863 20.3651L22.3769 4.49706L19.6231 2.50293L10.1137 15.6349L4.48811 11.6166L2.5119 14.3833L10.8863 20.3651ZM20 21H4V22H20V21Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}

export function DownloadedSmallIcon({
  width = 20,
  height = 20,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.048 11.53l5.3-8.5-1.697-1.06-4.735 7.595-3.332-2.396-1.168 1.624 4.2 3.019.867.623.565-.906zM5 13v2h10v-2h2v3a1 1 0 01-1 1H4a1 1 0 01-1-1v-3h2z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}
