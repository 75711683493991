export function renderStylesForDomId(
  domId: string,
  styles: string[],
  remove: boolean
) {
  const node = document.getElementById(domId);
  if (node) {
    if (remove) {
      node.classList.remove(...styles);
    } else {
      node.classList.add(...styles);
    }
  }
}
