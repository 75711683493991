import { appConfig } from 'app/env';

// Define global types for fbq function
declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    fbq: any; //(...args: any[]) => void;
    _fbq: any;
  }
}

function initFacebook(): void {
  if (!appConfig.analytics.metaPixelId) {
    return;
  }

  if (window.fbq) return;

  let fbq: any = (window.fbq = function () {
    fbq.callMethod
      ? fbq.callMethod.apply(fbq, arguments)
      : fbq.queue.push(arguments);
  });

  if (!window._fbq) window._fbq = fbq;
  fbq.push = fbq;
  fbq.loaded = true;
  fbq.version = '2.0';
  fbq.queue = [];
  let t = document.createElement('script');
  t.async = true;
  t.src = 'https://connect.facebook.net/en_US/fbevents.js';
  let s = document.getElementsByTagName('script')[0];
  s.parentNode.insertBefore(t, s);
  fbq('init', appConfig.analytics.metaPixelId);
  fbq('track', 'PageView');
}

// paranoia try/catch to bullet proof the module init flow
try {
  // Initialize Facebook Pixel. Looks like we have to do it statically.
  initFacebook();
} catch (e) {
  // eslint-disable-next-line no-console
  console.error('meta: Failed to initialize Facebook Pixel', e);
}

export function trackEvent(eventName: string, data?: any): void {
  try {
    if (window.fbq) {
      // eslint-disable-next-line no-console
      // console.log('meta: trackEvent', eventName, data);
      window.fbq('track', eventName, data);
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('meta: Failed to track event', e);
  }
}
