import * as React from 'react';
import * as Dialog from 'naan/primitives/modals/dialog';

import { VocabReviewModel } from '@core/models/ui/vocab-review-model';
import { ArrowLeftSmallIcon } from '@naan/icons/arrow-left-icon';
import { Responsive } from '@naan/primitives/responsive';
import { observer } from 'mobx-react';

import __ from 'core/lib/localization';

const ResumeReviewModalContent = ({
  vocabReviewModel,
  resume,
  restart,
}: {
  vocabReviewModel: VocabReviewModel;
  resume: () => void;
  restart: () => void;
}) => {
  const { remainingItemCount } = vocabReviewModel;

  return (
    <>
      <Dialog.Heading>
        {__('Resuming from where you left off', 'resumingFromWhereYouLeftOff')}
      </Dialog.Heading>
      <Dialog.Body>
        {__(
          {
            one: 'You have %{count} more item to review from here.',
            other: 'You have %{count} more items to review from here.',
          },
          'youHaveNMoreItemsToReview',
          {
            count: remainingItemCount,
          }
        )}
      </Dialog.Body>
      <Dialog.ButtonsContainer direction={'column'}>
        <Dialog.Button
          label={__('Resume', 'resume')}
          onClick={resume}
          presentation="teal"
        />
        <Dialog.Button
          leftIcon={<ArrowLeftSmallIcon />}
          label={__('Restart from beginning', 'restartFromBeginning')}
          onClick={restart}
          presentation="grayLight"
        />
      </Dialog.ButtonsContainer>
    </>
  );
};

const ResumeReviewDialogContent = observer(
  ({ vocabReviewModel }: { vocabReviewModel: VocabReviewModel }) => {
    const resume = React.useCallback(() => {
      vocabReviewModel.hideResumeModal();
    }, [vocabReviewModel]);

    const restart = React.useCallback(() => {
      vocabReviewModel.restartSession();
      vocabReviewModel.hideResumeModal();
    }, [vocabReviewModel]);

    return (
      <Responsive
        renderDefault={() => (
          <Dialog.Container open flavor="sheet">
            <ResumeReviewModalContent
              vocabReviewModel={vocabReviewModel}
              resume={resume}
              restart={restart}
            />
          </Dialog.Container>
        )}
        renderMediumAndUp={() => (
          <Dialog.Container open>
            <ResumeReviewModalContent
              vocabReviewModel={vocabReviewModel}
              resume={resume}
              restart={restart}
            />
          </Dialog.Container>
        )}
      />
    );
  }
);

export const ResumeReviewDialog = observer(
  ({ vocabReviewModel }: { vocabReviewModel: VocabReviewModel }) => {
    if (!vocabReviewModel.showingResumeModal) {
      return null;
    }

    return <ResumeReviewDialogContent vocabReviewModel={vocabReviewModel} />;
  }
);
