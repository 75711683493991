export type PlayerUIConfig = {
  elementTypeMap: { [index: string]: any };
};

let playerUIConfig = {
  elementTypeMap: null,
} as PlayerUIConfig;

export const usePlayerUIConfig = (config?: PlayerUIConfig): PlayerUIConfig => {
  if (config) {
    playerUIConfig = config;
  }
  return playerUIConfig;
};
