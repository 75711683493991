import * as React from 'react';
import { styled } from 'naan/stitches.config';
import { Story } from 'core/models/story-manager';
import { Link } from 'react-router-dom';
import { SoundbiteIconExtraSmall } from '@naan/icons/soundbite-icon';
import { learnStorySlugPath } from 'components/nav/path-helpers';
import classNames from 'classnames';

import __ from '@core/lib/localization';

const linkWrapperStyles = {
  $$color: '$colors$orange-500',
  all: 'unset',
  // cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  // gap: 4,
  color: '$$color',
  textStyle: 'small-text-bold',
  '&.completed': {
    $$color: '$colors$textSecondary',
  },
  '&.disabled': {
    $$color: '$colors$gray-200',
    cursor: 'default',
  },

  '&.onDark': {
    $$color: '$colors$globalWhite',

    '&.completed': {
      $$color: '$colors$globalWhite-alpha-80',
    },

    '&.disabled': {
      $$color: '$colors$globalWhite-alpha-50',
    },
  },
};

const LinkWrapper = styled(Link, {
  ...(linkWrapperStyles as any),
  cursor: 'pointer',
});
const DivWrapper = styled('div', {
  ...(linkWrapperStyles as any),
  $$color: '$colors$textSecondary',
});

const SoundbitesBadge = ({
  label,
  to,
  completed,
  disabled,
  linkable = true,
  onDark,
}: {
  to: string;
  label: React.ReactNode;
  completed?: boolean;
  disabled?: boolean;
  linkable?: boolean;
  onDark?: boolean;
}) => {
  if (linkable) {
    return (
      <LinkWrapper
        to={to}
        className={classNames('soundbites-badge', {
          completed,
          disabled,
          onDark,
        })}
      >
        <SoundbiteIconExtraSmall />
        {label}
      </LinkWrapper>
    );
  }

  return (
    <DivWrapper
      className={classNames('soundbites-badge', {
        completed,
        disabled,
        onDark,
      })}
    >
      <SoundbiteIconExtraSmall />
      {label}
    </DivWrapper>
  );
};

export const SoundbitesClassroomBadge = ({
  story,
  labeled = false,
  linkable = true,
}: {
  story: Story;
  labeled?: boolean;
  linkable?: boolean;
}) => {
  if (!story.hasVisibleSoundbites) {
    return null;
  }

  return (
    <SoundbitesBadge
      linkable={linkable}
      to={learnStorySlugPath(story.slug) + '?soundbites=1'}
      completed={story.allSoundbitesCompleted}
      label={
        <>
          {story.visibleSoundbiteCount?.toString() || '0'}
          {labeled ? ' ' + __('Soundbites', 'soundbites') : null}
        </>
      }
    ></SoundbitesBadge>
  );
};

export const SoundbitesStoryBadge = ({
  story,
  labeled = false,
  onDark = false,
}: {
  story: Story;
  labeled?: boolean;
  onDark?: boolean;
}) => {
  if (!story.hasVisibleSoundbites) {
    return null;
  }

  return (
    <SoundbitesBadge
      to={learnStorySlugPath(story.slug) + '?soundbites=1'}
      completed={story.allSoundbitesCompleted}
      onDark={onDark}
      label={
        <>
          {story.soundbiteDisplayProgress()}
          {labeled ? ' ' + __('Soundbites', 'soundbites') : null}
        </>
      }
    ></SoundbitesBadge>
  );
};
