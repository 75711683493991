import React from 'react';
import { observer } from 'mobx-react';
import { FullScreenLoader } from '../ds/modals';
import { AppFactory } from '@app/app-factory';

export const LoadingScreen = observer(() => {
  const { loadingData } = AppFactory.root;

  if (!loadingData) {
    return null;
  }

  return <FullScreenLoader />;
});
