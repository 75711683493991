import * as React from 'react';
import { useCopyPowers } from 'common/hooks/use-copy-powers';
import { CopySmallIcon } from 'naan/icons/copy-icon';
import { styled } from 'naan/stitches.config';
import { CheckmarkHeavySmallIcon } from 'naan/icons/checkmark-heavy-icon';
import __ from 'core/lib/localization';
import { AppFactory } from 'app/app-factory';

const CopyButton = styled('div', {
  $$backgroundColor: '$colors$yellow-200',
  $$iconColor: '$colors$yellow-800',

  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  position: 'relative',
  textStyle: 'coupon-code',
  textWrap: 'nowrap',
  userSelect: 'text',
  padding: '$space$1 6px $space$1 $space$2',
  cursor: 'default',
  transition: 'background-color .15s',

  backgroundColor: '$$backgroundColor',

  '& span.icon': {
    color: '$$iconColor',
    marginLeft: '$space$1',
  },

  '&:hover': {
    $$backgroundColor: '$colors$yellow-200',
  },
});

const HiddenInput = styled('input', {
  position: 'absolute',
  left: '0',
  top: '0',
  border: 'none',
  padding: 'none',
  opacity: '0',
});

export const CopyCode = ({ code }: { code: string }) => {
  const { ref: inputRef, active, copyAction } = useCopyPowers();

  const handleCopy = () => {
    void copyAction();

    AppFactory.toastService.open({
      type: 'success',
      message: __('Copied to clipboard', 'copiedToClipboard'),
    });
  };

  return (
    <>
      <HiddenInput type="text" value={code} ref={inputRef as any} readOnly />
      <CopyButton onClick={handleCopy}>
        <span className="text">{code}</span>
        <span className="icon">
          {active ? <CheckmarkHeavySmallIcon /> : <CopySmallIcon />}
        </span>
      </CopyButton>
      {/* <Button
        presentation="grayLight"
        size="small"
        label={active ? activeLabel : label}
        onClick={copyAction}
        data-test-id="copy-code"
      /> */}
    </>
  );
};
