import { ModelTreeNode, identifier } from 'ts-state-tree/tst-core';
import { getBaseRoot } from '../app-root';
import { Root } from '../root';
import { StoryManager } from './story-manager';
import { translateWithoutDefault } from '@core/lib/localization';

// import { createLogger } from 'app/logger';
// const log = createLogger('channel');

// correlates to ChannelCaliData masala schema
export class Channel extends ModelTreeNode {
  static CLASS_NAME = 'Channel' as const;

  @identifier
  slug: string = '';
  title: string = '';
  // tagline: string = '';
  // description: string = '';
  weblink: string;
  themeColor: string = '#8A60AB'; // default guaranteed during ingestion

  // units: UnitCatalogData[] = [];

  static create(snapshot: any = {}) {
    return super.create(Channel, snapshot) as Channel;
  }

  get root(): Root {
    return getBaseRoot(this);
  }

  get storyManager(): StoryManager {
    return this.root?.storyManager;
  }

  get tagline(): string {
    const key = `cms:channel:${this.slug}:tagline`;
    return translateWithoutDefault(key);
  }

  get description(): string {
    const key = `cms:channel:${this.slug}:description`;
    return translateWithoutDefault(key);
  }

  get weblinkUrl(): string {
    // add https:// if not present
    if (!this.weblink.startsWith('http')) {
      return `https://${this.weblink}`;
    }
    return this.weblink;
  }

  get storyCount(): number {
    return this.storyManager?.stories.filter(
      story => story.channelSlug === this.slug
    ).length;
  }
}
