import * as React from 'react';

export function CouponLargeIcon({
  width = 28,
  height = 28,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 28 28"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 6H3V7H4V6H5V7H6V6H7V7H8V6H9V7H10V6H11V7H12V6H13V7H14V6H15V7H16V6H17V7H18V6H19V7H20V6H21V7H22V6H23V7H24V6H25V7H26V7.00012H27V8.00012H26V9H27V10H26V11H27V12H26V13H27V14H26V15H27V16H26V17H27V18H26V19H27V20H26V21H25V22H24V21H23V22H22V21H21V22H20V21H19V22H18V21H17V22H16V21H15V22H14V21H13V22H12V21H11V22H10V21H9V22H8V21H7V22H6V21H5V22H4V21H3V22H2V21V20H1V19H2V18H1V17H2V16H1V15H2V14H1V13H2V12H1V11H2V10H1V9H2V8.00012H1V7.00012H2V7V6ZM16.5931 10.5H15.9004L12.2044 17.2812H12.9108L16.5931 10.5ZM12.1953 13.9253C13.1316 13.9253 13.8906 13.1663 13.8906 12.23C13.8906 11.2937 13.1316 10.5347 12.1953 10.5347C11.259 10.5347 10.5 11.2937 10.5 12.23C10.5 13.1663 11.259 13.9253 12.1953 13.9253ZM12.1953 12.9546C12.5955 12.9546 12.9199 12.6302 12.9199 12.23C12.9199 11.8298 12.5955 11.5054 12.1953 11.5054C11.7951 11.5054 11.4707 11.8298 11.4707 12.23C11.4707 12.6302 11.7951 12.9546 12.1953 12.9546ZM18.3203 15.5437C18.3203 16.48 17.5613 17.239 16.625 17.239C15.6887 17.239 14.9297 16.48 14.9297 15.5437C14.9297 14.6074 15.6887 13.8484 16.625 13.8484C17.5613 13.8484 18.3203 14.6074 18.3203 15.5437ZM17.3496 15.5438C17.3496 15.944 17.0252 16.2684 16.625 16.2684C16.2248 16.2684 15.9004 15.944 15.9004 15.5438C15.9004 15.1436 16.2248 14.8192 16.625 14.8192C17.0252 14.8192 17.3496 15.1436 17.3496 15.5438Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}

export function CouponExtraLargeIcon({
  width = 32,
  height = 32,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 7H4V8H5V7H6V8H7V7H8V8H9V7H10V8H11V7H12V8H13V7H14V8H15V7H16V8H17V7H18V8H19V7H20V8H21V7H22V8H23V7H24V8H25V7H26V8H27V7H28V8H29V8.00012H30V9.00012H29V10H30V11H29V12H30V13H29V14H30V15H29V16H30V17H29V18H30V19H29V20H30V21H29V22H30V23H29V24H28V25H27V24H26V25H25V24H24V25H23V24H22V25H21V24H20V25H19V24H18V25H17V24H16V25H15V24H14V25H13V24H12V25H11V24H10V25H9V24H8V25H7V24H6V25H5V24H4V25H3V24V23H2V22H3V21H2V20H3V19H2V18H3V17H2V16H3V15H2V14H3V13H2V12H3V11H2V10H3V9.00012H2V8.00012H3V8V7ZM18.9635 12H18.1719L13.9479 19.75H14.7552L18.9635 12ZM13.9375 15.9146C15.0075 15.9146 15.875 15.0472 15.875 13.9771C15.875 12.9071 15.0075 12.0396 13.9375 12.0396C12.8674 12.0396 12 12.9071 12 13.9771C12 15.0472 12.8674 15.9146 13.9375 15.9146ZM13.9375 14.8053C14.3949 14.8053 14.7656 14.4346 14.7656 13.9772C14.7656 13.5198 14.3949 13.1491 13.9375 13.1491C13.4802 13.1491 13.1094 13.5198 13.1094 13.9772C13.1094 14.4346 13.4802 14.8053 13.9375 14.8053ZM20.9375 17.7643C20.9375 18.8343 20.07 19.7018 19 19.7018C17.9299 19.7018 17.0625 18.8343 17.0625 17.7643C17.0625 16.6942 17.9299 15.8268 19 15.8268C20.07 15.8268 20.9375 16.6942 20.9375 17.7643ZM19.8281 17.7644C19.8281 18.2218 19.4574 18.5925 19 18.5925C18.5426 18.5925 18.1719 18.2218 18.1719 17.7644C18.1719 17.307 18.5426 16.9363 19 16.9363C19.4574 16.9363 19.8281 17.307 19.8281 17.7644Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}

export function CouponIcon({
  width = 24,
  height = 24,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 5H3V5.5H4V5H5V5.5H6V5H7V5.5H8V5H9V5.5H10V5H11V5.5H12V5H13V5.5H14V5H15V5.5H16V5H17V5.5H18V5H19V5.5H20L20 5H21L21 5.5H22.5V6.00009H23V7.00009H22.5V8H23V9H22.5V9.99991H23V10.9999H22.5V12H23V13H22.5V13.9999H23V14.9999H22.5V16.0001H23V17.0001H22.5V18.5H21V19H20V18.5H19V19H18V18.5H17V19H16V18.5H15V19H14V18.5H13V19H12V18.5H11V19H10V18.5H9V19H8V18.5H7V19H6V18.5H5V19H4V18.5H3V19H2V18.5H1.5V17.0001H1V16.0001H1.5V14.9999H1V13.9999H1.5V13H1V12H1.5V10.9999H1V9.99991H1.5V9H1V8H1.5V7.00009H1V6.00009H1.5V5.5H2V5ZM14.2226 9H13.6289L10.4609 14.8125H11.0664L14.2226 9ZM10.4531 11.936C11.2557 11.936 11.9062 11.2854 11.9062 10.4828C11.9062 9.68029 11.2557 9.0297 10.4531 9.0297C9.65059 9.0297 9 9.68029 9 10.4828C9 11.2854 9.65059 11.936 10.4531 11.936ZM10.4531 11.104C10.7962 11.104 11.0742 10.8259 11.0742 10.4829C11.0742 10.1398 10.7962 9.86178 10.4531 9.86178C10.1101 9.86178 9.83205 10.1398 9.83205 10.4829C9.83205 10.8259 10.1101 11.104 10.4531 11.104ZM15.7031 13.3232C15.7031 14.1257 15.0525 14.7763 14.25 14.7763C13.4475 14.7763 12.7969 14.1257 12.7969 13.3232C12.7969 12.5207 13.4475 11.8701 14.25 11.8701C15.0525 11.8701 15.7031 12.5207 15.7031 13.3232ZM14.8711 13.3233C14.8711 13.6663 14.593 13.9444 14.25 13.9444C13.907 13.9444 13.6289 13.6663 13.6289 13.3233C13.6289 12.9803 13.907 12.7022 14.25 12.7022C14.593 12.7022 14.8711 12.9803 14.8711 13.3233Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}
