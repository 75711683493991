import { makeObservable, observable } from 'mobx';
import { VideoGuideUserData } from '@core/models/user-manager/video-guide-user-data';
import { VideoGuide } from '@core/models/catalog/video-guide';

/**
 * VideoGuideModel
 *
 * UI level model for the modal helplet player
 */
export class VideoPlayerModel {
  userData: VideoGuideUserData;

  @observable
  current: VideoGuide; // current active video within widget UI

  // set based on the state when the video modal is first opened
  // UI local state needed to show enlightenment flavor of end card even though onboarding is already technically complete
  @observable
  onboardingMode: boolean;

  constructor(userData: VideoGuideUserData, video: VideoGuide) {
    makeObservable(this);
    this.userData = userData;
    this.initModalState(video);
  }

  initModalState(video: VideoGuide) {
    this.current = video;
    this.onboardingMode = !this.onboardingComplete;
  }

  advanceToNextVideo() {
    this.current = this.nextOnboardingVideo;
  }

  resetModalState() {
    this.current = null;
    this.onboardingMode = false;
  }

  setCurrent(videoGuide: VideoGuide) {
    this.current = videoGuide;
  }

  setonboardingMode(): boolean {
    return !!this.current;
  }

  // will need expanded logic once we have other categories
  get onboardingComplete(): boolean {
    return this.userData.onboardingComplete;
  }

  get featuredVideo(): VideoGuide {
    return this.userData.featuredVideo;
  }

  get onboardingProgressPercentage(): number {
    return this.userData.onboardingProgressPercentage;
  }

  // fascilitates sharing logic between skip dialog and end card
  get currentOrFeatured(): VideoGuide {
    return this.current || this.userData.featuredVideo;
  }

  get nextOnboardingVideo(): VideoGuide {
    const current = this.currentOrFeatured;
    if (current) {
      const videos = this.userData.onboardingVideos;
      const next = videos[current.position /*-1+1*/]; // assuming 1 based position values
      return next;
    } else {
      return null;
    }
  }

  get hasNext(): boolean {
    return !!this.nextOnboardingVideo;
  }
}
