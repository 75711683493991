import * as React from 'react';
import autoAnimate, {
  AutoAnimateOptions,
  AutoAnimationPlugin,
} from '@formkit/auto-animate';

export const useAutoanimate = (
  config: Partial<AutoAnimateOptions> | AutoAnimationPlugin = {
    duration: 150,
    easing: 'ease-out',
  }
) => {
  const parentRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    parentRef.current && autoAnimate(parentRef.current, config);
  }, [config, parentRef]);

  return parentRef;
};
