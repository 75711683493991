import React from 'react';
import { PageHeading } from 'components/page-heading';
import { observer } from 'mobx-react';
import { Responsive } from 'naan/primitives/responsive';
import { HSpacer } from 'naan/primitives/spacer';
import { HStack } from 'naan/primitives/stack';
import { CopyCode } from 'components/ui/code';
import { ClassroomOverflowMenu } from './classroom-overflow-menu';
import { useCurrentClassroom } from './current-clasroom-context';

const ClassroomTools = () => {
  const { classroom } = useCurrentClassroom();

  return (
    <>
      <HStack align="center">
        <CopyCode code={classroom.code} />
        <HSpacer size={2} />
        <ClassroomOverflowMenu />
      </HStack>
    </>
  );
};

export const ClassroomPageHeader = observer(() => {
  const { classroom } = useCurrentClassroom();

  return (
    <>
      <PageHeading
        title={classroom.label}
        renderAccessory={() => (
          <Responsive renderLargeAndUp={() => <ClassroomTools />} />
        )}
      />
      <Responsive
        renderDefault={() => (
          <HStack>
            <HSpacer expand />
            <ClassroomTools />
          </HStack>
        )}
        renderLargeAndUp={() => null}
      ></Responsive>
    </>
  );
});
