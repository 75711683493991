import * as React from 'react';
import * as Onboarding from './components';
import { Container as DialogContainer } from '@naan/primitives/modals/dialog';
import { runConfirmableDialog } from '@common/dialog-runner';
import { createLogger } from '@common/log';
import { AppFactory } from '@app/app-factory';
import { PlainMarkdown } from '@naan/primitives/text';
import { TipKey } from '@app/onboarding/onboarding-service';
// import { runSkipTipsAlert } from './skip-notice';
// import { bugsnagNotify } from '@app/notification-service';
import { styled } from '@naan/stitches.config';

import __ from 'core/lib/localization';

import imageSrc from './smart-pause-illustration.png';
import { Responsive } from '@naan/primitives/responsive';

const log = createLogger('onboarding-dialogs');

// type OnResolveFn = (value: OnboardingDialogAction) => void;
type OnResolveFn = (value: boolean) => void;

/// this is just an example for dev purposes
/// it is abstracted this way so the design and components
/// can be reused in popovers too
export const ExampleOnboardingContent = () => {
  return (
    <Onboarding.Container>
      {/* <Onboarding.Image src={'https://placekitten.com/200/300'} /> */}
      <Onboarding.Image src={imageSrc} width={80} height={64} />
      <Onboarding.Title>Meet your Smart Pause button</Onboarding.Title>
      <Onboarding.Content>
        <PlainMarkdown
          source={`Listening is best done in whole sentences.
            When you tap **pause**, it plays to the end of the sentence and then stops.
            But if you tap pause again before it reaches the end, it'll stop immediately.
        `}
        />
      </Onboarding.Content>
      <Onboarding.HelpletVideo videoSlug="smart-pause" />
    </Onboarding.Container>
  );
};

/// This context allows the dialog content to know of the resolve functions, whitout
/// them being explicitly passed down. This is mostly necessary for the video modal
export const OnboardingDialogContext = React.createContext<OnResolveFn | null>(
  null
);
export const useOnResolve = () => {
  const onResolve = React.useContext(OnboardingDialogContext);
  if (!onResolve) {
    // throw new Error('useOnResolve must be used inside OnboardingDialog');
    return undefined;
  }
  return onResolve;
};

const ScrollingWrapper = styled('div', {
  // background: 'blue',
  display: 'grid',
  gridTemplateRows: 'auto 1fr auto',
  height: '100%',
  paddingTop: 16,
  // width: '100%',
  '& > .scroller': {
    overflowY: 'auto',
    // maxHeight: 'calc($$calculatedMaxHeight - 80px - 52px)',
  },
});

const DialogOrSheetContainer: React.FC<{
  children: React.ReactNode;
  onDismiss: () => void;
}> = ({
  children,
  onDismiss,
}: {
  children: React.ReactNode;
  onDismiss: () => void;
}) => {
  return (
    <Responsive
      renderDefault={() => (
        <DialogContainer open onDismiss={onDismiss} flavor={'sheet'}>
          {children}
        </DialogContainer>
      )}
      renderSmallAndUp={() => (
        <DialogContainer open onDismiss={onDismiss}>
          {children}
        </DialogContainer>
      )}
    />
  );
};

export const OnboardingDialog = ({
  body,
  onResolve,
}: // renderAsSheetOnMobile,
{
  body: React.ReactNode;
  onResolve: OnResolveFn;
  // renderAsSheetOnMobile?: boolean;
}) => {
  const onCancel = () => log.debug('onCancel ignored');

  // const ContainerComponent = renderAsSheetOnMobile
  //   ? DialogOrSheetContainer
  //   : DialogContainer;

  // console.log('OnboardingDialog', { ContainerComponent });

  // const onSkip = React.useCallback(() => {
  //   runSkipTipsAlert()
  //     .then(() => onResolve('skipTips'))
  //     .catch(bugsnagNotify);
  // }, [onResolve]);

  return (
    <DialogOrSheetContainer onDismiss={onCancel}>
      <ScrollingWrapper>
        {/* <Onboarding.Header>
          <Onboarding.SkipTipsButton onClick={onSkip}>
            {__('Skip tips', 'skipTips')}
          </Onboarding.SkipTipsButton>
        </Onboarding.Header> */}
        <OnboardingDialogContext.Provider value={onResolve}>
          <Onboarding.Scroller className="scroller">{body}</Onboarding.Scroller>
        </OnboardingDialogContext.Provider>
        <Onboarding.ButtonsContainer>
          <Onboarding.GotItButton
            label={__('Got it', 'gotIt')}
            // onClick={() => onResolve('gotIt')}
            onClick={() => onResolve(true)}
          />
        </Onboarding.ButtonsContainer>
      </ScrollingWrapper>
    </DialogOrSheetContainer>
  );
};

type OnboardingDialogProps = React.ComponentProps<typeof OnboardingDialog>;

// export const presentSimpleConfirmation = (
//   body: SimpleConfirmProps['body'],
//   onResolve: SimpleConfirmProps['onResolve']
// ) => {
//   AppFactory.dialogPresenter.present(onDismiss => (
//     <SimpleConfirm
//       body={body}
//       onResolve={value => {
//         onDismiss();
//         onResolve(value);
//       }}
//     />
//   ));
// };

export const runOnboardingDialog = async (
  tipKey: TipKey,
  body: OnboardingDialogProps['body']
): Promise<boolean> => {
  const action = await runConfirmableDialog<boolean>(onResolve => (
    <OnboardingDialog body={body} onResolve={onResolve} />
  ));
  const userSettings = AppFactory.root.userManager.userData.userSettings;
  // switch (action) {
  //   case 'skipTips':
  //     userSettings.dismissAllTips();
  //     break;
  //   case 'gotIt':
  userSettings.dismissTip(tipKey);
  //     break;
  //   default:
  //     log.info('unhandled action', action);
  // }
  return action;
};
