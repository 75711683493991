import * as React from 'react';
import { observer } from 'mobx-react';
import { TableFieldButtonSet, TableFieldWrapper } from './table-field';
import { useSwitch2 } from '@naan/hooks/use-switch-2';
import { useComboForm } from 'common/hooks/use-comboform';
import { passwordSchema } from 'core/lib/validation-rules';
import { ToastService } from 'common/notifications/toast-service';
import { CatchAllFormErrors } from 'naan/primitives/forms/catch-all-form-error';
import { PasswordInput } from 'naan/primitives/input/password-input';
import __ from 'core/lib/localization';
import { AppFactory } from '@app/app-factory';

type FormInput = {
  password: string;
};

export const PasswordForm = observer(() => {
  const editSwitch = useSwitch2(false);
  const { userManager } = AppFactory.root;

  const {
    register,
    handleSubmit,
    errors,
    clearValidationErrors,
    handleValidationErrors,
  } = useComboForm<FormInput>({
    validationSchema: passwordSchema(),
  });

  const handleUpdate = React.useCallback(
    ({ password }: FormInput) => {
      clearValidationErrors();
      userManager.updatePassword(password).then(() => {
        const message = __('Password updated', 'api:update:passwordUpdated'); // hardwire field qualified response
        editSwitch.off();
        ToastService.open({
          message: message,
          type: 'success',
        });
      }, handleValidationErrors);
    },
    [editSwitch, userManager, clearValidationErrors, handleValidationErrors]
  );

  return (
    <TableFieldWrapper
      label={__('Password', 'password')}
      testId="password"
      value={'●●●●●●●●'}
      editing={editSwitch.value}
      onStartEditing={editSwitch.on}
    >
      <form onSubmit={handleSubmit(handleUpdate)} noValidate>
        <PasswordInput
          name={'password'}
          data-test-id={'edit-input'}
          {...register('password')}
        />
        {/* <FormError error={errors['email']} data-test-id="error-message" /> */}
        <CatchAllFormErrors errors={errors} data-test-id="error-message" />
        <TableFieldButtonSet
          updateLabel={__('Update', 'update')}
          cancelAction={editSwitch.off}
        />
      </form>
    </TableFieldWrapper>
  );
});
