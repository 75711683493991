import * as React from 'react';
import { observer } from 'mobx-react';
import { styled } from '@naan/stitches.config';
import { Story } from '@core/models/story-manager';
import { AppFactory } from '@app/app-factory';
import { A } from '@common/ui/action-link';
import chroma from 'chroma-js';
import { agePretty } from '@core/lib/pretty-duration';

// import { createLogger } from '@common/log';
// const log = createLogger('story-card-masala-admin');

const DEFAULT_THEME_COLOR = '#8A60AB';

export const StoryCardMasalaAdmin = observer(({ story }: { story: Story }) => {
  if (!AppFactory.root.userManager.showUglyDevUI) {
    return null;
  }

  const isDefaultColor = story.themeColor === DEFAULT_THEME_COLOR;
  const Wrapper = isDefaultColor ? PlainWrapper : ColoredWrapper;

  const backgroundColor = isDefaultColor ? '#000000' : story.themeColor;
  const [r, g, b] = chroma(backgroundColor).rgb();

  return (
    <Wrapper
      css={{
        $$red: r,
        $$green: g,
        $$blue: b,
      }}
    >
      <StoryDebugInfo story={story} />
    </Wrapper>
  );
});

const ColoredWrapper = styled('div', {
  $$red: 255,
  $$green: 255,
  $$blue: 255,

  backgroundColor: 'rgb($$red, $$green, $$blue)',
  padding: '2px',
  color: '$colors$white',

  // @armando, is this the right way to control the link color?
  'a:link': { color: '$colors$white' },
  'a:visited': { color: '$colors$white' },
});

const PlainWrapper = styled('div', {
  padding: '2px',
  'a:link': { color: '$colors$black' },
  'a:visited': { color: '$colors$black' },
});

export const StoryDebugInfo = ({ story }: { story: Story }) => {
  const { userManager } = AppFactory.root;
  if (!userManager.showUglyDevUI) {
    return null;
  }

  const isDefaultColor = story.themeColor === DEFAULT_THEME_COLOR;

  return (
    <>
      <A href={story.masalaVolumeDetailUrl}>masala</A>
      {' - '}
      theme color: {isDefaultColor ? '[default]' : story.themeColor}
      {' - '}state: {story.progressMayBeNull?.storyState}
      {' - '}
      curr: {story.progressMayBeNull?.currentPoint?.sortableString}, fur:{' '}
      {story.progressMayBeNull?.furthestPoint?.sortableString}
      {' - '}v{story.version}, {agePretty(story.ingestedAt)}
    </>
  );
};
