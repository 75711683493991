import React from 'react';
import { styled } from 'naan/stitches.config';
import { ExternalLink } from '@naan/primitives/text/external-link';
import { eulaUrl, privacyUrl, tosUrl } from 'components/nav/path-helpers';

import __, { addNbsps } from 'core/lib/localization';

const Wrapper = styled('div', {
  textStyle: 'small-text',
  color: '$textSecondary',
  textAlign: 'center',
  '& a': {
    color: 'inherit',
    textDecoration: 'underline',
  },
});

export const AuthSmallPrint = () => {
  return (
    <Wrapper>
      <div>
        {__('By creating an account, you accept our:', 'byCreatingAnAccount')}
      </div>
      <LegalLinks />
    </Wrapper>
  );
};

const LegalLinks = () => {
  return (
    <>
      <ExternalLink href={tosUrl()}>
        {addNbsps(__('Terms of service', 'termsOfService'))}
      </ExternalLink>
      {', '}
      <ExternalLink href={privacyUrl()}>
        {addNbsps(__('Privacy policy', 'privacyPolicy'))}
      </ExternalLink>
      {', '}
      <ExternalLink href={eulaUrl()}>
        {addNbsps(__('User License Agreement', 'userLicenseAgreement'))}
      </ExternalLink>
    </>
  );
};
