import * as React from 'react';
import { styled } from '@naan/stitches.config';
import { Link } from 'react-router-dom';
import { ArrowLeftSmallIcon } from '@naan/icons/arrow-left-icon';
import { JwSymbol, SymbolPresentation } from './jw-symbol';

const LinkWrapper = styled(Link, {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  textDecoration: 'none',
  color: '$gray-800',
  '&:not(.presentation-color) ': {
    color: '$white',
  },
});

type Props = React.ComponentProps<typeof LinkWrapper> & {
  presentation?: SymbolPresentation;
};

export const BrandedBackLink = ({ presentation, ...props }: Props) => {
  return (
    <LinkWrapper {...props} className={`presentation-${presentation}`}>
      <ArrowLeftSmallIcon />
      <JwSymbol presentation={presentation} />
    </LinkWrapper>
  );
};

const ButtonWrapper = styled('button', {
  all: 'unset',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  textDecoration: 'none',
  color: '$gray-800',
  '&:not(.presentation-color) ': {
    color: '$globalWhite',
  },
});

type ButtonProps = React.ComponentProps<typeof ButtonWrapper> & {
  presentation?: SymbolPresentation;
};

export const BrandedBackButton = ({ presentation, ...props }: ButtonProps) => {
  return (
    <ButtonWrapper {...props} className={`presentation-${presentation}`}>
      <ArrowLeftSmallIcon />
      <JwSymbol presentation={presentation} />
    </ButtonWrapper>
  );
};
