import * as React from 'react';

export function FavoriteFilledIcon({
  width = 24,
  height = 24,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.36582 3C4.04051 3 2 5.86252 2 8.21066C2 13.2877 7.86225 18.0002 12 21C16.1379 18 22 13.2872 22 8.21066C22 5.86272 19.9594 3 16.6342 3C14.7771 3 13.1905 4.45748 12.0001 5.84213C10.8097 4.45748 9.22306 3 7.36602 3H7.36582Z"
        fill={color}
        fillRule="evenodd"
        stroke={color}
        strokeWidth="2"
      />
    </svg>
  );
}
