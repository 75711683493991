import * as React from 'react';
import { IconButton } from '@naan/primitives/button';
import { observer } from 'mobx-react-lite';
import { styled } from '@naan/stitches.config';
import { useReaction } from '@common/hooks/use-reaction';
import { AppFactory } from 'app/app-factory';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftSmallIcon } from '@naan/icons/arrow-left-icon';
import { ReturnNavState } from 'components/nav/return-nav-state';
import { homePath } from 'components/nav/path-helpers';
import { headerColors } from 'components/soundbites/soundbite-headers';
import { SoundbiteCategory } from 'components/soundbites/soundbite-categories';
import { soundbitePlayerUiModel } from 'soundbite/fx/soundbite-player-ui-model';
import { MarkSimple } from '@naan/primitives/text';
import { usePlayerModel } from 'player/views/player-model-context';
import { SettingsIcon } from '@naan/icons/settings-icon';
import { presentSettingsModal } from 'components/settings';

const Wrapper = styled('div', {
  $$backgroundColor: '$colors$white',

  position: 'absolute',
  top: '0',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '56px',
  padding: '0 8px',
  flexShrink: '0',
  // background: '$$backgroundColor',
  background: 'transparent',
  zIndex: '4',
  transition: 'transform .3s, background .3s',
  color: '$globalWhite',

  '& > .left': {
    position: 'absolute',
    left: '8px',
  },
  '& > .title': {
    textStyle: 'body-bold',
    transition: 'opacity .3s',
    opacity: 0,
  },
  '& > .right': {
    position: 'absolute',
    right: '8px',
    display: 'flex',
  },

  '& > .date': {
    display: 'none',
    '@playerSmallAndUp': {
      display: 'block',
    },
  },

  '&.playing': {
    transform: 'translateY(-100%)',
  },

  '&.showing-title': {
    background: '$$backgroundColor',
    // boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.1)',
    shadowStyle: 'small',
    '& .title': {
      // transition: 'opacity .3s',
      opacity: 1,
    },
  },
});

const Back = ({ onboarding }: { onboarding: boolean }) => {
  const navigate = useNavigate();

  // return <Logo />;

  return (
    <IconButton
      icon={<ArrowLeftSmallIcon />}
      presentation="whiteTransparent"
      noFocus
      tabIndex={-1}
      onClick={() => {
        // never pop when onboarding
        if (!onboarding && ReturnNavState.canPopFromStudy()) {
          navigate(-1);
        } else {
          navigate(homePath());
        }
      }}
      testId="soundbite-back-button"
    />
  );
};

export const SbHeader = observer(({ onboarding }: { onboarding: boolean }) => {
  const playerModel = usePlayerModel();
  const ref = React.useRef<HTMLDivElement>(null);
  // never automatically opened since we now have the orientation interstitial
  // const autoShowWelcomeDialog = false; //playerModel.onloadModalNeeded;

  const soundbiteModel = AppFactory.soundbiteModel;
  const soundbite = soundbiteModel.soundbite;
  const category = soundbite?.category as SoundbiteCategory;

  const $$backgroundColor = headerColors[category];

  useReaction(
    () => playerModel.isPlaying,
    () => {
      if (playerModel.isPlaying) {
        ref.current?.classList.add('playing');
      } else {
        ref.current?.classList.remove('playing');
      }
    }
  );

  useReaction(
    () => soundbitePlayerUiModel.showTitleInHeader,
    showing => {
      if (showing) {
        ref.current?.classList.add('showing-title');
      } else {
        ref.current?.classList.remove('showing-title');
      }
    }
  );

  return (
    <Wrapper css={{ $$backgroundColor }} ref={ref}>
      <span className="left">
        <Back onboarding={onboarding} />
      </span>
      <span className="title">
        <MarkSimple source={soundbite?.title} />
      </span>
      <span className="right">
        <IconButton
          icon={<SettingsIcon />}
          presentation="whiteTransparent"
          testId={'soundbites-settings-button'}
          onClick={() => presentSettingsModal('Player')}
        />
      </span>
    </Wrapper>
  );
});
