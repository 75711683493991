import { createLogger } from 'app/logger';
import UaParser from 'ua-parser-js';

const log = createLogger('ua-parser');

function isTouchDevice(): boolean {
  return 'maxTouchPoints' in navigator && navigator.maxTouchPoints > 0;
}

/// TODO: replace enum with `as const`
// export const enum BrowserLevel {
//   // eslint-disable-next-line no-unused-vars
//   SUPPORTED = 'SUPPORTED',
//   // eslint-disable-next-line no-unused-vars
//   UNSUPPORTED = 'UNSUPPORTED',
//   // eslint-disable-next-line no-unused-vars
//   UNSURE = 'UNSURE',
// }

// export const browserLevelKeys = ['SUPPORTED', 'UNSUPPORTED', 'UNSURE'] as const;
// export type BrowserLevel = typeof browserLevelKeys[number];
// export type BrowserLevel = 'SUPPORTED' | 'UNSUPPORTED' | 'UNSURE';

export class UaService {
  private static _instance: UaParser;

  static get info() {
    if (!UaService._instance) {
      UaService._instance = new UaParser(window.navigator.userAgent);
    }
    return UaService._instance.getResult();
  }

  /// returns true if we determine that the current browser should be warned about
  static get brownListed(): boolean {
    /// Check for capabilities first, instead of user agent sniffing
    /// no globalThis? brown list it.
    if (typeof globalThis !== 'object') {
      return true;
    }

    const isSafari =
      this.info?.os?.name === 'Mac OS' && this.info?.browser?.name === 'Safari';

    const isDesktopSafari = isSafari && !isTouchDevice();

    if (isDesktopSafari) {
      return true;
    }

    return false;
  }

  // attempt to untagle the chaos browser masquerading
  static get browserInfo() {
    let name: string = 'unknown';
    let major: number;
    try {
      name = this.info?.browser?.name || 'unknown';
      major = Number(this.info?.browser?.major); // how to best handle undefined or NaN?

      // the ipad safari browser lies and claims to be Mac OS, so we only look at the browser and version.
      // except we need to treat chrome, etc on ios as if it were safari.
      if (this.info?.os?.name === 'iOS') {
        name = 'Safari';
        major = Number(this.info?.os?.version?.split('.')[0]);
      }
    } catch (e) {
      // paranoia catch, will fall back to UNSURE if we have a dereference or parse error
      log.info(`parse error: ${e}`);
    }

    return { name, major };
  }

  // this isn't about abstraction, but encapsulation of how to interpret the browser info data
  static get shouldManuallySetScriptContainerHeight() {
    const { name, major } = this.browserInfo;
    return name.includes('Safari') && major <= 14;
  }

  static get isIos(): boolean {
    return UaService.info.os.name === 'iOS';
  }

  static get iosUnsupported(): boolean {
    return UaService.isIos && UaService.iosVersion < 15;
  }

  static get iosShouldUpgrade(): boolean {
    return UaService.isIos && UaService.iosVersion < 16.4;
  }

  static get iosVersion(): number {
    if (UaService.isIos) {
      const versionParts = UaService.info.os.version.split('.').map(Number);
      if (versionParts.length >= 2) {
        return versionParts[0] + versionParts[1] / 10;
      }
    }
    return 0;
  }

  //
  // old soundbite m1 logic which we abandoned
  //

  // static get supportLevel(): BrowserLevel {
  //   if (this.unsupported) {
  //     return 'UNSUPPORTED';
  //   }
  //   if (this.supported) {
  //     return 'SUPPORTED';
  //   }
  //   return 'UNSURE'; // versions where we wish to warn the user out of paranoia
  // }

  // // true for version with known issues
  // static get unsupported(): boolean {
  //   const { name, major } = this.browserInfo;
  //   return (
  //     (name.includes('Safari') && major <= 12) ||
  //     (name.includes('Chrome') && major <= 86) ||
  //     (name.includes('Firefox') && major <= 82) // placeholder based on 2 years old
  //   );
  // }

  // // true for version we've tested and plan on forward regression testing
  // static get supported(): boolean {
  //   const { name, major } = this.browserInfo;
  //   return (
  //     (name.includes('Safari') && major >= 15) ||
  //     (name.includes('Chrome') && major >= 99) || // placeholder version
  //     (name.includes('Firefox') && major >= 102) // placeholder version
  //     // flush out edge, etc
  //   );
  // }

  // market share data may be useful https://www.stetic.com/market-share/browser/
}

(window as any).UaService = UaService;
