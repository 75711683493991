import * as React from 'react';
import { HStack, VStack } from 'naan/primitives/stack';
import { AppFactory } from 'app/app-factory';
import { observer } from 'mobx-react';
import { getSnapshot } from '@ts-state-tree/tst-core';
import { UserManager } from '@core/models/user-manager';
// import { VideoListCard } from 'components/learn/dashboard/helplets-widget/video-list-card';
import { MediumHeading } from '@naan/primitives/text';
import { Definition } from './components/definition';
import { ConfigArea } from './dev-data-dump';
import { ActionLink } from '@common/ui/action-link';
import { NotificationService } from '@app/notification-service';

export const path = 'videoguides';
export const label = 'Video guides';

export const Component = observer(() => {
  const { root } = AppFactory;
  const { userManager, storyManager } = root;
  const { userData } = userManager;

  const userManagerOtherData = userManager.snapshot as UserManager;
  userManagerOtherData.accountData = undefined;
  userManagerOtherData.userData = undefined;

  let videoGuidesJson = '';
  let videoGuideUserDataJson = '';
  try {
    videoGuidesJson = JSON.stringify(
      getSnapshot(storyManager.videoGuides),
      null,
      2
    );
    videoGuideUserDataJson = JSON.stringify(
      getSnapshot(userData.videoGuideUserData),
      null,
      2
    );
  } catch (error) {
    NotificationService.open({
      message: 'Error trying to stringify video guides',
      type: 'error',
    });
  }

  return (
    <VStack>
      <MediumHeading>Video guide stuff</MediumHeading>
      {/* <h4>video guide list card example</h4>
      <VideoListCard video={storyManager.videoGuides[0]} /> */}
      <HStack gap={'small'}>
        {/* Video auto play: {String(localState.videoAutoplay)}
        &nbsp;
        <ActionLink onPress={() => localState.toggleVideoAutoplay()}>
          [toggle]
        </ActionLink> */}
      </HStack>
      <ActionLink
        onPress={() => /*async*/ userData.videoGuideUserData.resetAllData()}
      >
        [Reset video guide user data]
      </ActionLink>
      <p>
        {storyManager.videoGuides.map(video => (
          <React.Fragment key={video.slug}>
            {video.slug}
            {video.completed ? '✓' : '∅'} {/*({video.engagementStatus})*/}
          </React.Fragment>
        ))}
      </p>
      <Definition
        label={'video guide user data'}
        value={
          <ConfigArea
            cols={30}
            rows={6}
            value={videoGuideUserDataJson}
            readOnly
          />
        }
      />
      <Definition
        label={'featuredVideoGuide catalog data'}
        value={
          <ConfigArea cols={30} rows={12} value={videoGuidesJson} readOnly />
        }
      />
    </VStack>
  );
});
