import * as React from 'react';
import { PointsIcon } from 'naan/icons/points-icon';
import { colors } from 'naan/tokens/colors';
import { styled } from '@naan/stitches.config';

const Wrapper = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  textStyle: 'medium-heading',
  gap: 2,
  color: '$textPrimary',
  '& svg': {
    marginLeft: -2,
  },
  variants: {
    isLargeOnDesktop: {
      true: {
        '@medium': {
          textStyle: 'large-heading',
          gap: 4,
          '& svg': {
            width: 28,
            height: 28,
          },
        },
      },
    },
  },
});

export const TotalPoints = ({
  points,
  isLargeOnDesktop = false,
}: {
  points: number;
  isLargeOnDesktop?: boolean;
}) => (
  <Wrapper isLargeOnDesktop={isLargeOnDesktop}>
    <PointsIcon color={colors['yellow-500']} />
    <span>{points.toLocaleString()}</span>
  </Wrapper>
);
