import * as React from 'react';

export function ShareIcon({ width = 24, height = 24, color = 'currentColor' }) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 8C18.6569 8 20 6.65685 20 5C20 3.34315 18.6569 2 17 2C15.3432 2 14 3.34315 14 5C14 5.23218 14.0264 5.4582 14.0763 5.67523L7.84949 9.63775C7.3399 9.23821 6.69778 9 6.00002 9C4.34316 9 3.00002 10.3431 3.00002 12C3.00002 13.6569 4.34316 15 6.00002 15C6.69777 15 7.3399 14.7618 7.84949 14.3622L14.0763 18.3248C14.0264 18.5418 14 18.7678 14 19C14 20.6569 15.3432 22 17 22C18.6569 22 20 20.6569 20 19C20 17.3431 18.6569 16 17 16C16.3023 16 15.6601 16.2382 15.1505 16.6378L8.92372 12.6752C8.97364 12.4582 9.00002 12.2322 9.00002 12C9.00002 11.7678 8.97364 11.5418 8.92372 11.3248L15.1505 7.36225C15.6601 7.76179 16.3023 8 17 8Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}
