import { deleteDoc, doc } from 'firebase/firestore';
import { createLogger } from '@common/log';
import { UserDataSnapshot } from '@core/models/user-manager/user-data';
import { track } from '@app/track';
import { FirebaseConnection } from './firebase-types';
import { BaseFirestoreSync } from './base-firestore-sync';
import { UserDataSync } from './user-data-sync';
import { CaliServerInvoker } from './cali-server-invoker';

const log = createLogger('user-data-sync');

export class UserDataSyncImpl
  extends BaseFirestoreSync<UserDataSnapshot>
  implements UserDataSync
{
  constructor({
    firebaseConnection,
    caliServerInvoker,
  }: {
    firebaseConnection: FirebaseConnection;
    caliServerInvoker: CaliServerInvoker;
  }) {
    super({ firebaseConnection, caliServerInvoker, log });
  }

  get collectionName() {
    return 'UserData';
  }

  get collectionPath() {
    return 'userDatas';
  }

  async mergeSync(
    docId: string,
    localDelta: UserDataSnapshot
  ): Promise<UserDataSnapshot> {
    if (!docId) {
      log.error(`sync - docId required`);
      return;
    }

    if (this.connectionReady) {
      try {
        // beware, this will block while offline (but listener callback with new data will happen immediately)
        await this.firestoreMerge(docId, localDelta);
        log.debug(`after firestoreMerge`);
        const result = await this.firestoreFetchFromServerEnsureDocId(docId);
        // there seems to be some situations where we can't trust the fetched data
        log.debug(`after firestoreFetch`);
        // log.trace(JSON.stringify(result));
        return result;
      } catch (error) {
        this.log.warn(
          `firestoreMerge failed: ${error}; falling back to disconnected mode`
        );
        track('system__firestore_store_failure', { error: String(error) });
        this.firebaseConnection.status = 'ERROR';
      }
    }

    const result = await this.caliServerInvoker.mergeSyncUserData(
      docId,
      localDelta
    );
    return result;
  }

  async store(docId: string, snapshot: UserDataSnapshot): Promise<void> {
    if (!docId) {
      log.error(`store - docId required`);
      return;
    }

    if (this.connectionReady) {
      try {
        await this.firestoreStore(docId, snapshot);
        return;
      } catch (error) {
        this.log.warn(
          `firestoreStore failed: ${error}; falling back to disconnected mode`
        );
        track('system__firestore_store_failure', { error: String(error) });
        this.firebaseConnection.status = 'ERROR';
      }
    }

    await this.caliServerInvoker.storeUserData(docId, snapshot);
  }

  // todo: partial update api
  // async updateUserData(path: string, value: any): Promise<void> {
  //   if (!this.userDataUuid) {
  //     log.warn(`updateUserData(${path}) - ignored when anonymous`);
  //     return;
  //   }
  //   const docRef = this.userDataDocRef;
  //   await updateDoc(docRef, { [path]: value });
  // }

  // useful for testing data migration
  async nukeUserData(docId: string) {
    const docRef = doc(this.db, 'UserData', docId);
    await deleteDoc(docRef);
  }
}
