import { ModelTreeNode } from 'ts-state-tree/tst-core';
import { msToPoints } from './listening-stats';

export const SOUNDBITE_COMPLETION_POINTS = 25;

export type ListeningLogKind = 'CHAPTER' | 'SOUNDBITE';

/**
 * ListeningLog
 *
 * holds study stats for a given day and story
 */
export class ListeningLog extends ModelTreeNode {
  static CLASS_NAME = 'ListeningLog' as const;

  static create(snapshot: any) {
    return super.create(ListeningLog, snapshot) as ListeningLog;
  }

  static mapKey(
    storySlug: string,
    date: string,
    kind: ListeningLogKind = 'CHAPTER'
  ): string {
    if (kind === 'CHAPTER') {
      return [storySlug, date].join('.');
    } else {
      return [storySlug, date, kind].join('.');
    }
  }

  kind: ListeningLogKind; // assumed to be 'CHAPTER' when undefined

  // granularity: string = undefined; // 'daily' implied when undefined, MONTH for coalesced data
  date: string = ''; // iso date (w/ time part truncated now after migration)
  storySlug: string = ''; // story or soundbite slug
  listenedMillis: number = 0;

  relistenedMillis?: number; // deprecated, only needed for legacy data migration input parsing

  get mapKey(): string {
    return ListeningLog.mapKey(this.storySlug, this.date, this.kind);
  }

  get isSoundbite(): boolean {
    return this.kind === 'SOUNDBITE';
  }

  get points(): number {
    if (this.isSoundbite) {
      return SOUNDBITE_COMPLETION_POINTS;
    } else {
      return msToPoints(this.listenedMillis);
    }
  }
}
