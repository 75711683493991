import { useCallback, useState } from 'react';

export function useTickler(): () => void {
  const [, tickler] = useState<{}>(Object.create(null));

  const memoizedTickler = useCallback((): void => {
    tickler(Object.create(null));
  }, [tickler]);
  return memoizedTickler;
}
