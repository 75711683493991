import * as React from 'react';

export function CheckmarkIcon({
  width = 24,
  height = 24,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 4.3739L10.6363 21.1489L2 14.2398L3.57626 12.2695L10.0794 17.472L19.911 2.95874L22 4.3739Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}
