import React from 'react';
import { NotationStatus } from 'vocab-review/chapter-review-model';
import {
  BookmarkCheckmarkLargeIcon,
  BookmarkFilledLargeIcon,
  BookmarkLargeIcon,
  BookmarkXLargeIcon,
  BookmarkQuestionmarkLargeIcon,
} from '@naan/icons/bookmark-icon';

export const BookmarkIconMap: Record<NotationStatus, React.ReactNode> = {
  'not-added': <BookmarkLargeIcon />,
  'just-added': <BookmarkFilledLargeIcon />,
  'got-it': <BookmarkCheckmarkLargeIcon />,
  'not-got-it': <BookmarkXLargeIcon />,
  pending: <BookmarkQuestionmarkLargeIcon />,
  'previously-learned': <BookmarkCheckmarkLargeIcon />,
};
