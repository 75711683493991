import * as React from 'react';
import { observer } from 'mobx-react';
import { getMainScrollingContainer, scrollContainerTo } from 'lib/scroll-utils';
import { throttle } from 'lodash';

function getScrollStorageKey(pageKey: string) {
  return `${pageKey}.scrollTop`;
}

export function intentionallyResetAllScrollStorageKeys() {
  Object.keys(window.sessionStorage)
    .filter(key => key.endsWith('.scrollTop'))
    .forEach(key => window.sessionStorage.removeItem(key));
}

export function clearSavedScrollPosition(pageKey: string) {
  const scrollStorageKey = getScrollStorageKey(pageKey);
  // console.log('pageKey ==>', pageKey);
  window.sessionStorage.removeItem(scrollStorageKey);
}

let __hasMomentumScrollingHack = false;

function useScrollRestoration(pageKey: string) {
  const scrollStorageKey = getScrollStorageKey(pageKey);

  React.useLayoutEffect(() => {
    const scrollingContainer = getMainScrollingContainer();

    if (scrollingContainer) {
      const fragment = window.location.hash.slice(1);

      // if there's a fragment in the URL we don't need to restore the scroll position
      if (fragment) {
        const element = document.getElementById(fragment);
        if (element) {
          return;
        }
      }

      /// if there's a saved scroll position, restore it, otherwise scroll to top
      const oldTop = window.sessionStorage.getItem(scrollStorageKey) || '0';

      /// so, this is a hack to prevent a bug related to a clash between momentum scrolling
      /// on iOS safari and `scrollTo` with behavior set to `instant`.
      /// If you happened to to the `scrollTo` below, while `scrollingContainer` was in the
      /// middle of a momentum scroll, it would do weird things. Not a crash per se,
      /// but the rendering would break. The screen looked blank, even all the content was there.
      /// Either changing the scroll behaviour from 'instant' to 'smooth' or waiting until
      /// the scroll animation was over would prevent the bug. But that wasn't good enough for UX.
      /// So what this `style.overflow` hack does is automatically canceling the momentum scrolling.
      /// -- A.S.
      // june 4 2024: added this flag to prevent a reace condition when react 18 double renders this hook
      if (!__hasMomentumScrollingHack) {
        const oldoverflow = scrollingContainer.style.overflow; // save old value to prevent future bugs
        scrollingContainer.style.overflow = 'hidden'; // cancel momentum scrolling
        __hasMomentumScrollingHack = true;
        /// Here we use a setTimeout with a delay of `0` otherwise subsequent style changes are ignored.
        setTimeout(() => {
          scrollingContainer.style.overflow = oldoverflow; // restore old style value.
          scrollContainerTo(parseInt(oldTop));
          __hasMomentumScrollingHack = false;
        }, 0);
      }

      /// we save the scroll position on every (throthled) scroll event.
      const saveScrollTop = throttle(() => {
        window.sessionStorage.setItem(
          scrollStorageKey,
          scrollingContainer.scrollTop.toString()
        );
      }, 300);

      scrollingContainer.addEventListener('scroll', saveScrollTop);

      return () => {
        /// we unset the event listener when the component is unmounted.
        scrollingContainer.removeEventListener('scroll', saveScrollTop);
      };
    }
  }, [scrollStorageKey]);
}

export const DeepScrollRestoration = observer(
  ({ pageKey }: { pageKey: string }) => {
    useScrollRestoration(pageKey);
    // FIXME
    return <></>;
  }
);
