import * as React from 'react';
import { ArrowLeftExtraSmallIcon } from '@naan/icons/arrow-left-icon';
import { ArrowRightExtraSmallIcon } from '@naan/icons/arrow-right-icon';
import { ArrowUpExtraSmallIcon } from '@naan/icons/arrow-up-icon';
import { ArrowDownExtraSmallIcon } from '@naan/icons/arrow-down-icon';
import { PlusExtraSmallIcon } from '@naan/icons/plus-icon';
import { MinusExtraSmallIcon } from '@naan/icons/minus-icon';
import { ShortcutSection } from './ui/shortcut-section';
import { ShortcutItem } from './ui/shortcut-item';

import __ from '@core/lib/localization';

export const StudyShortcuts = () => {
  return (
    <>
      {/* <ShortcutSection title={__('Mode', 'mode')}>
        <ShortcutItem
          title={__('Switch to Listen mode', 'switchToListenMode')}
          shortcut="Shift + Tab"
        />
      </ShortcutSection> */}

      <ShortcutSection title={__('Playback', 'playback')}>
        <ShortcutItem
          title={__('Play > delayed pause > pause', 'playDelayedPausePause')}
          shortcut={__('Space', 'keyboard.space')}
        />
        <ShortcutItem
          title={__('Cancel delayed pause', 'cancelDelayedPause')}
          shortcut={__('Esc', 'keyboard.esc')}
        />

        <ShortcutItem
          title={__('Rewind/fast-forward', 'rewindSlashFastForward')}
          shortcut={[<ArrowLeftExtraSmallIcon />, <ArrowRightExtraSmallIcon />]}
        />

        <ShortcutItem
          title={__('Previous/next sentence', 'previousSlashNextSentence')}
          shortcut={[<ArrowUpExtraSmallIcon />, <ArrowDownExtraSmallIcon />]}
        />

        <ShortcutItem
          title={__('Adjust speed', 'adjustSpeed')}
          shortcut={[<PlusExtraSmallIcon />, <MinusExtraSmallIcon />]}
        />
      </ShortcutSection>

      <ShortcutSection title={__('View', 'view')}>
        <ShortcutItem
          title={__('Reveal current sentence', 'revealCurrentSentence')}
          shortcut={__('Delete', 'keyboard.delete')}
        />
        <ShortcutItem
          title={__('Show/hide translation', 'showSlashHideTranslation')}
          shortcut={__('Shift+Tab', 'keyboard.shiftTab')}
        />
      </ShortcutSection>

      <ShortcutSection title={__('When paused', 'whenPaused')}>
        <ShortcutItem title={__('Replay', 'replay')} shortcut="Enter" />
        <ShortcutItem
          title={__('Slow replay', 'slowReplay')}
          shortcut={__('Shift+Enter', 'keyboard.shiftEnter')}
        />
        <ShortcutItem
          title={__(
            'Show/hide sentence translation',
            'showSlashHideSentenceTranslation'
          )}
          shortcut={__('Tab', 'keyboard.tab')}
        />

        <ShortcutItem
          title={__(
            'Show/hide sentence vocabulary',
            'showSlashHideSentenceVocabulary'
          )}
          shortcut="V"
        />
        <ShortcutItem
          title={__(
            'Show/hide saved vocabulary',
            'showSlashHideSavedVocabulary'
          )}
          shortcut={__('Shift+V', 'keyboard.shiftV')}
        />
        <ShortcutItem
          title={__('Copy sentence to clipboard', 'copySentenceToClipboard')}
          shortcut={__('C', 'keyboard.c')}
        />
      </ShortcutSection>

      <ShortcutSection title={__('Help', 'help')}>
        <ShortcutItem
          title={__('Hide/show this panel', 'hideSlashShowThisPanel')}
          shortcut={__('K', 'keyboard.k')}
        />
      </ShortcutSection>
    </>
  );
};
