import * as React from 'react';
import { styled } from '@naan/stitches.config';
import { observer } from 'mobx-react';
import { PointsIcon } from '@naan/icons/points-icon';

const PointsDisplayWrapper = styled('div', {
  $$iconSize: '24px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 4,
  textStyle: 'body-bold',

  svg: {
    width: '$$iconSize',
    height: '$$iconSize',
  },

  '& > .points-icon': {
    color: '$yellow-500',
    marginRight: -2,
  },

  '& > .points-legend': {
    textStyle: 'small-text',
    color: '$globalBlack-alpha-50',
    marginTop: 2,
  },

  variants: {
    size: {
      small: {
        $$iconSize: '20px',
      },
      large: {
        textStyle: 'small-heading',
      },
    },
  },
});

type PointsDisplayWrapperProps = React.ComponentProps<
  typeof PointsDisplayWrapper
>;

export const PointsDisplay = observer(
  ({
    points,
    legend,
    size,
  }: { points: number; legend?: string } & PointsDisplayWrapperProps) => {
    return (
      <PointsDisplayWrapper size={size}>
        <span className="points-icon">
          <PointsIcon />
        </span>
        {points}
        {legend ? <span className="points-legend">{legend}</span> : null}
      </PointsDisplayWrapper>
    );
  }
);
