import {
  ExtractETForKT,
  IElement,
  IndexRange,
  IndexedElement,
  WordElement,
  KTOfET,
  ExtractIndexedET,
  ElementList,
} from '../basic-types';
import { ElementNodeType } from './element-tree';
import { ElementTreeRendererImpl } from './element-tree-renderer-impl';
import { Precedence } from './precedence';

// TODO duplicating now, move shared module or find a builtin?

export interface ElementTreeRenderer<
  ET extends IndexedElement,
  WT extends WordElement
> {
  getTreeOfNodesForRange(
    range: IndexRange
  ): ElementNodeType<ET | WT, ET | WT>[];
  getTreeOfNodes(): ElementNodeType<ET | WT, ET | WT>[];
}

export function CreateElementTreeRenderer<
  // union type for all the element types in the input element list
  ET extends IElement,
  WAT extends WordElement[],
  // all kinds in the precedence list must be index kinds and also be in either the points or spans lists
  PRKT extends IDXKT & UKT,
  // all kinds in the point list must be in the precedence list and not in the spans list
  PTKT extends Exclude<PRKT, SPKT>,
  // all kinds in the spans list must be in the precedence list and not in the points list
  SPKT extends Exclude<PRKT, PTKT>,
  // **** computed types for above restrictions and output element type
  // compute all available indexed element types in the passed element list
  IDXET extends IndexedElement = ExtractIndexedET<ET>,
  // compute element kinds of available indexed element types
  IDXKT extends string = KTOfET<IDXET>,
  // compute union of the kinds in spans and points lists
  UKT extends string = SPKT | PTKT,
  // compute output element union type
  TET extends IndexedElement = ExtractETForKT<IDXET, UKT>
>(
  elementList: ElementList<ET>,
  wordArray: WAT,
  precedence: Precedence<readonly PRKT[]>,
  asPoints: readonly PTKT[],
  asSpans: readonly SPKT[]
): ElementTreeRenderer<TET, WAT[number]> {
  return new ElementTreeRendererImpl(
    elementList as any,
    wordArray,
    precedence,
    asPoints,
    asSpans
  ) as ElementTreeRenderer<TET, WAT[number]>;
}
