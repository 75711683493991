import { TFilterQueryParameters } from '../filtering-core';
import { sortingDefinitions } from './sorting-definitions';
import { TSortingKey } from './sorting-keys';

export function convertQueryObjectToSorting(query: TFilterQueryParameters) {
  const defaultKey = 'duration';
  const key = (query.sorting as TSortingKey) ?? defaultKey;
  const defaultSorting = sortingDefinitions[defaultKey];
  return sortingDefinitions[key] ?? defaultSorting;
}
