import * as React from 'react';
import { styled } from 'naan/stitches.config';

const activeSelector =
  '&:hover:not(.disabled), &:active:not(.disabled), &[data-state=open]';

const ButtonWrapper = styled('button', {
  all: 'unset',
  textStyle: 'body-bold',
  py: '$4',
  color: '$textSecondary',
  display: 'flex',
  alignItems: 'center',
  [activeSelector]: {
    color: '$teal-400',
  },
  '@medium': {
    py: '$6',
  },
  variants: {
    selected: {
      true: {
        color: '$teal-500',
      },
    },
    iconOnly: {
      true: {
        lineHeight: 0,
      },
    },
    textSize: {
      small: {
        textStyle: 'small-text',
      },
    },
  },
});

type ButtonWrapperProps = React.ComponentProps<typeof ButtonWrapper>;

type FilterButtonProps = ButtonWrapperProps & {
  label: React.ReactNode;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  selected?: boolean;
};

export const FilterButton = React.forwardRef(
  (
    {
      label,
      selected = false,
      leftIcon,
      rightIcon,
      ...buttonProps
    }: FilterButtonProps,
    ref
  ) => {
    return (
      <ButtonWrapper selected={selected} ref={ref as any} {...buttonProps}>
        {leftIcon ? leftIcon : null}
        <span className="label">{label}</span>
        {rightIcon ? rightIcon : null}
      </ButtonWrapper>
    );
  }
);
