import * as React from 'react';

export const FeedbackIcon = ({
  width = 24,
  height = 24,
  color = 'currentColor',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.8944 17L15.9909 20.5503L17.0833 17H19C19.5523 17 20 16.5523 20 16V7C20 6.44772 19.5523 6 19 6H5C4.44772 6 4 6.44772 4 7V16C4 16.5523 4.44772 17 5 17H11.8944ZM2 7C2 5.34315 3.34315 4 5 4H19C20.6569 4 22 5.34315 22 7V16C22 17.6569 20.6569 19 19 19H18.5604L17.4512 22.6049C17.2387 23.2955 16.3865 23.5397 15.8405 23.0665L11.1484 19H5C3.34315 19 2 17.6569 2 16V7Z"
      fill={color}
    />
  </svg>
);
