import * as React from 'react';
import classNames from 'classnames';
import { styled } from 'naan/stitches.config';
import { paletteKey } from '../../../study/views/study-palette';
import { PlainMarkdown } from '@naan/primitives/text';
import { SentenceId } from '@tikka/basic-types';
import { ReviewNotationsListPanel } from 'study/views/notations/review-notations-list';
import { usePlayerModel } from '../player-model-context';
import { ChapterReviewModel } from 'vocab-review/chapter-review-model';
import { observer } from 'mobx-react';

// const log = createLogger('sentence-extras');

const Wrapper = styled('div', {
  '& > .l1': {
    '& .translation-inner': {
      padding: '0 16px 16px 16px',
      // background: 'cyan',
      textStyle: 'body',
      color: paletteKey('$$sentence__inlineTranslationColor'),
    },
  },

  '& .expandable': {
    display: 'grid',
    gridTemplateRows: '0fr',
    transition: 'grid-template-rows .3s',
    '& > div.expandable-inner': {
      gridRow: '1 / span 2',
      overflow: 'hidden',
      // This lets the focused vocab item's shadow extend out of the container
      // @armando: is there a better way to target .expandable that contains a focussed vocab item?
      '&:has(.focus)': {
        overflow: 'visible',
      },
    },

    '&.expanded': {
      gridTemplateRows: '1fr',
    },
  },
});

export const ReviewSentenceExtras: React.FC<{
  translation: string;
  sentenceId: SentenceId /*onUnredact: () => void*/;
}> = observer(({ translation }) => {
  const translationRef = React.useRef<HTMLDivElement>(null);
  const notationsRef = React.useRef<HTMLDivElement>(null);

  const reviewModel = usePlayerModel() as ChapterReviewModel;
  const expanded = reviewModel.isCurrentSentenceRevealed;

  return (
    <Wrapper>
      <div
        ref={translationRef}
        className={classNames('l1 expandable', {
          expanded,
        })}
      >
        <div className="expandable-inner">
          <div className="translation-inner">
            <PlainMarkdown source={translation} />
          </div>
        </div>
      </div>
      <div
        ref={notationsRef}
        className={classNames('notations expandable', { expanded })}
      >
        <div className="expandable-inner">
          <ReviewNotationsListPanel />
        </div>
      </div>
    </Wrapper>
  );
});
