import * as React from 'react';
import { ZeroSearchResults } from './zero-search-results';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PrimaryFilterKeys } from '@core/models/story-manager/story-manager';
import { isClassroomFilterKey } from '@core/lib/constants/vars';
import { ZeroStateView } from 'components/ui/zero-state-view';
import { VStack } from '@naan/primitives/stack';
import { VSpacer } from '@naan/primitives/spacer';
import { Button } from '@naan/primitives/button';
import {
  storiesPrimaryFiltered,
  storiesSoundbiteFiltered,
} from 'components/nav/path-helpers';
import { SoundbiteFilterValues } from '@core/models/story-manager/story';

import __ from '@core/lib/localization';

export const ZeroStories = () => {
  const navigate = useNavigate();

  const [queryParams] = useSearchParams();

  const primaryParam = queryParams.get('primary');
  const filterTypeParam = queryParams.get('filter_type');
  const filterValueParam = queryParams.get('filter_value');

  let title: string;
  let description: string;
  let buttonLabel = __('Show all stories', 'showAllStories');

  let navigateTo = storiesPrimaryFiltered(PrimaryFilterKeys.ALL);

  if (filterTypeParam === 'search') {
    return <ZeroSearchResults />;
  } else if (filterTypeParam === 'sb') {
    switch (filterValueParam) {
      case SoundbiteFilterValues.withUnstartedFn():
        title = __(
          "Well done! You've done all the Soundbites (so far)",
          'wellDoneYouveDoneAllTheSoundbitesSoFar'
        );
        description = __(
          'Check back later for new ones.',
          'youHaveNotAddedAnySoundbites'
        );
        break;

      case SoundbiteFilterValues.withCompletedFn():
        title = __('Get started with Soundbites', 'getStartedWithSoundbites');
        description = __(
          "They're quick, fun, and very educational.",
          'theyreQuickFunAndVeryEducational'
        );
        buttonLabel = __(
          'Find stories with Soundbites',
          'findStoriesWithSoundbites'
        );
        navigateTo = storiesSoundbiteFiltered('allFn');
        break;
      default:
        return null;
    }
  } else if (primaryParam && isClassroomFilterKey(primaryParam)) {
    title = __('No assignments', 'noAssignments');
    description = __(
      'This classroom currently has no assignments.',
      'thisClassroomHasNoAssignments'
    );
  } else {
    switch (primaryParam) {
      case PrimaryFilterKeys.QUEUED:
        title = __('Your queue is empty', 'yourQueueIsEmpty');
        description = __(
          'Tap "Study later" on a story to add it here.',
          'tapStudyLaterOnAStoryToAddItHere'
        );
        break;
      case PrimaryFilterKeys.IN_PROGRESS:
        title = __('No stories in progress', 'noStoriesInProgress');
        description = __(
          'Stories will appear here when you start listening to them.',
          'storiesWillAppearHereWhenYouStart'
        );
        break;
      case PrimaryFilterKeys.COMPLETED:
        title = __('No completed stories', 'noCompletedStories');
        description = __(
          "Stories appear here when you've reached the end. Meanwhile, you've got some listening to do.",
          'storiesAppearHereWhenYouveReachedTheEnd'
        );
        break;

      default:
        return null;
    }
  }

  return (
    <ZeroStateView title={title} description={description}>
      {' '}
      <VStack align={'center'}>
        <VSpacer size={5} />
        <Button
          label={buttonLabel}
          presentation={'secondary'}
          onClick={() => navigate(navigateTo)}
        />
      </VStack>
    </ZeroStateView>
  );
};
