import React, { useState } from 'react';
import { AppFactory } from '@app/app-factory';
import { createLogger } from '@common/log';
const log = createLogger('use-account-locale');

//
// renders children with the user's selected L1
// used by account and subscription screens
//
export const WithAccountLocale = ({ children }: { children: any }) => {
  const [ready, setReady] = useState(false);
  React.useEffect(() => {
    const { root } = AppFactory;
    log.info(`activating account locale`);
    root.activateAccountLocale();
    setReady(true);

    return () => {
      log.info(`restoring normal locale`);
      root.deactivateAccountLocale();
    };
  }, [setReady]);

  if (ready) {
    return children;
  } else {
    return null;
  }
};
