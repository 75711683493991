import * as React from 'react';
// import { styled } from 'naan/stitches.config';
import { AppFactory } from 'app/app-factory';
import {
  collectionsPath,
  learnHomePath,
  learnStoriesPath,
  storiesPrimaryFiltered,
} from 'components/nav/path-helpers';
import { PrimaryFilterKeys } from '@core/models/story-manager/story-manager';

import __ from 'core/lib/localization';
import { PlainMarkdownWithLinks } from '@naan/primitives/text';

function getZeroMessage() {
  const { storyManager, userManager } = AppFactory.root;
  const { hasNeverListened, fullAccess } = userManager;

  // shortcut the message generation for new users
  if (hasNeverListened && !fullAccess) {
    return __(
      "You've got some listening to do! Let's get you started with a [free story](%{url}).",
      'youveGotSomeListeningToDo',
      {
        url: learnHomePath({ fragment: 'free' }),
      }
    );
  }

  const messages = [];

  if (storyManager.completed.length) {
    messages.push(
      __(
        "Did you know, re-listening to [stories you've completed](%{url}) is excellent for ear training?",
        'didYouKnowRelistening',
        {
          url: storiesPrimaryFiltered(PrimaryFilterKeys.COMPLETED),
        }
      )
    );
  }

  messages.push(
    __(
      'Have you explored our [themed collections](%{url})?',
      'haveYouExplored',
      {
        url: collectionsPath(),
      }
    )
  );

  messages.push(
    __(
      "We've got so many amazing stories. What are you waiting for? [Start something new](%{url}).",
      'weveGotSoManyAmazingStories',
      {
        url: learnStoriesPath(),
      }
    )
  );

  const index = storyManager.daysSinceEpoch % messages.length;

  return messages[index];
}

export const BarchartZeroState = () => {
  return <PlainMarkdownWithLinks source={getZeroMessage()} />;
};
