import React, { ComponentProps } from 'react';
import { observer } from 'mobx-react';
// import { IconButton } from '@naan/primitives/button';
import { usePlayerModel } from 'player/views/player-model-context';
import { RedactionMode } from 'player/models/redaction-modes';
import {
  RightButtonIcon,
  ButtonLabel,
  ButtonStyled,
} from '@naan/primitives/button/button.styles';
import { ChevronDownExtraSmallIcon } from '@naan/icons/chevron-down-icon';
import { StudyModel } from 'study/models/study-model';
import { SoundbiteModel } from 'soundbite/models/soundbite-model';
import { presentSettingsModal } from 'components/settings';

import __ from '@core/lib/localization';

const getLabel = (mode: RedactionMode) => {
  const map = {
    [RedactionMode.SHOW_NONE]: __('Hiding all words', 'hidingAllWords'),
    [RedactionMode.SHOW_SOME]: __('Hiding some words', 'hidingSomeWords'),
    [RedactionMode.SHOW_ALL]: __('Showing all words', 'showingAllWords'),
  };
  return map[mode];
};

type ButtonPickedPresentation = Pick<
  ComponentProps<typeof ButtonStyled>,
  'presentation'
>;

export const GlobalRedactionButton = observer(
  ({
    presentation = 'grayTransparent',
  }: { responsive?: boolean } & ButtonPickedPresentation) => {
    const model = usePlayerModel() as StudyModel | SoundbiteModel;

    if (model.fluentListenMode === true) {
      return null;
    }

    const label = getLabel(model.redactionMode);

    return (
      <ButtonStyled
        presentation={presentation}
        size="small"
        as="div"
        onClick={() => presentSettingsModal('Player')}
      >
        <ButtonLabel size="small">{label}</ButtonLabel>
        <RightButtonIcon size={'small'}>
          <ChevronDownExtraSmallIcon width={9} height={9} />
        </RightButtonIcon>
      </ButtonStyled>
    );
  }
);
