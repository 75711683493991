import { appConfig } from 'app/env';
import { createLogger } from 'app/logger';
import * as Sentry from '@sentry/browser';

const log = createLogger('error-reporting');

const enableErrorTracking = appConfig.sentry.enabled;
log.debug('Error tracking enabled?', enableErrorTracking);

export const setErrorContext = (data: any) => {
  const { accountData, globalConfig, installationId, buildInfo, miscInfo } =
    data;

  // log.info('call to setErrorContext', JSON.stringify(data));

  if (enableErrorTracking) {
    Sentry.configureScope(scope => {
      scope.setUser({
        id: accountData.errorReportingUserId,
        email: accountData.email,
        name: accountData.errorReportingName,
      });
      scope.setContext('account', accountData);
      scope.setContext('globalConfig', globalConfig);
      scope.setContext('installationId', installationId);
      scope.setContext('buildInfo', buildInfo);
      scope.setContext('miscInfo', miscInfo);
    });
  }
};

// minibus.subscribe('SET_REPORTING_CONTEXT', setReportingContext);

// todo: can this be collapsed with bugsnagNotify?
// @elliottjf we could try at antoher pass, the problem is the extra info this function takes
const reportExceptionWithSentry = (
  error: any,
  info: any = {},
  options = {}
) => {
  const {
    //handlerTag = null,
    level = 'error',
  }: any = options;

  if (!(error instanceof Error) && info.exception) {
    error = info.exception; // for backward compatibility
  }

  Sentry.withScope(scope => {
    scope.setExtras(info);
    scope.setLevel(level);
    Sentry.captureException(error);
  });
};

let __disabledNotice = false;
export const reportException = enableErrorTracking
  ? reportExceptionWithSentry
  : () => {
      if (!__disabledNotice) {
        log.error('Bugsnag is disabled for this build.');
        __disabledNotice = true;
      }
    };

/// I don't think this is used anymore
// export const notify = (...args) => {
//   // eslint-disable-next-line no-console
//   console.log('notify is deprecated, use notifyException instead');
//   reportException(...args);
// };
