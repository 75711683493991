import * as React from 'react';

export function SortIcon({ width = 24, height = 24, color = 'currentColor' }) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.00001 1.33325L1.70001 8.39992L3.30001 9.59992L6.00001 5.99992L6.00001 21.9999H8.00001L8.00001 5.99992L10.7 9.59992L12.3 8.39992L7.00001 1.33325Z"
        fill={color}
      />
      <path
        d="M18 17.9999L18 1.99992L16 1.99992L16 17.9999L13.3 14.3999L11.7 15.5999L17 22.6666L22.3 15.5999L20.7 14.3999L18 17.9999Z"
        fill={color}
      />
    </svg>
  );
}
