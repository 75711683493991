import * as React from 'react';
import { useCurrentStory } from 'components/story-detail/current-story-context';
import { ProgressPanelSection } from './panel-section';
import { SectionItem } from './section-item';
import { SoundbiteIcon } from '@naan/icons/soundbite-icon';
import { storyDetailUiModel } from 'components/story-detail/story-detail-ui-model';
import { observer } from 'mobx-react';

import __ from '@core/lib/localization';

export const SoundbiteSection = observer(() => {
  const story = useCurrentStory();

  if (!story.hasVisibleSoundbites) {
    return null;
  }

  const promoted = story.completed && !story.allSoundbitesCompleted;
  const completed = story.allSoundbitesCompleted; /* && story.completed; */

  return (
    <ProgressPanelSection
      title={__('Soundbites', 'soundbites')}
      completed={completed}
    >
      <SectionItem
        promoted={promoted}
        presentation="orange"
        icon={<SoundbiteIcon />}
        title={story.soundbiteDisplayProgress(false)}
        subtitle={__('Complete', 'complete')}
        action={() => {
          storyDetailUiModel.showSoundbitesModal();
        }}
      />
    </ProgressPanelSection>
  );
});
