import React from 'react';
import { observer } from 'mobx-react';
import { Container, Label, Row, Value } from './components';
import { LargeGap } from '@naan/primitives/spacer/gaps';
import { formatDate } from 'utils/format-date';
import { LocaleCode } from '@utils/util-types';
import { AppFactory } from '@app/app-factory';

import __ from 'core/lib/localization';

export const GroupAccess = observer(({ l2 }: { l2: LocaleCode }) => {
  const membership = AppFactory.root.userManager.membershipL2(l2);
  const { membershipTypeDisplay, fullAccessUntil, licensedClassroomLabel } =
    membership;

  return (
    <>
      {/* <MediumHeading>{__('My membership', 'myMembership')}</MediumHeading>
        <VSpacer size="4" /> */}
      <Container>
        <Row>
          <Label>{__('Membership type', 'membershipType')}</Label>
          <Value>{membershipTypeDisplay}</Value>
        </Row>
        <Row>
          <Label>{__('Classroom name', 'classroomName')}</Label>
          <Value>{licensedClassroomLabel}</Value>
        </Row>
        <Row>
          <Label>{__('Expires', 'expires')}</Label>

          <Value>{formatDate(fullAccessUntil)}</Value>
        </Row>
      </Container>
      <LargeGap />
    </>
  );
});
