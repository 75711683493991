import React from 'react';
import { observer } from 'mobx-react';
import { RewindControlIcon } from '../../../study/views/player-icons';
import { ControlButton } from './control-button';
import { usePlayerModel } from '../player-model-context';

export const RewindButton = observer(({ disabled }: { disabled?: boolean }) => {
  const model = usePlayerModel();
  const disableButton = disabled || !model.canSeekPreviousNavStop;
  return (
    <ControlButton onClick={() => model.rewind()} disabled={disableButton}>
      <RewindControlIcon />
    </ControlButton>
  );
});
