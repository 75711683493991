import React from 'react';
import cx from 'classnames';
import { ElementNode, Paragraph } from '@tikka/client/client-aliases';
// import { paragraphTranscript } from '../cali/script-model';
// import { PlainMarkdown } from '@naan/primitives/text';
import { StoryTree } from './story-tree';
import { styled } from '@naan/stitches.config';

const Wrapper = styled('div', {
  '& .speaker': {
    font: 'var(--small-text-font)',
    padding: '4px 8px',
    border: '1px solid var(--gray100)',
    display: 'inline-block',
    borderRadius: '3px',
    margin: '4px 0 24px',
    color: 'var(--gray-5)',
  },

  '& .paragraph': {
    margin: '0 0 24px',
    breakInside: 'avoid-page',

    '@media print, screen and (min-width: 700px)': {
      display: 'flex',
      justifyContent: 'space-between',
    },

    '&.avoid-break': {
      breakInside: 'avoid-page',
    },

    '& > p': {
      '&.original': {
        font: 'var(--compact-script-font)',
        marginBottom: '8px',
        '@media print, screen and (min-width: 700px)': {
          marginBottom: '0',
        },
      },

      '&.translation': {
        font: 'var(--body-font)',
        color: 'var(--text-secondary)',
      },
    },
  },
});

// export const Paragraph = ({
//   paragraph,
//   hint,
// }: {
//   paragraph: ParagraphData;
//   hint?: string;
// }) => {
//   const {
//     elements,
//     speaker: speakerLabel,
//     isSpeakerLabelRequired: speakerLabelRequired,
//   } = paragraph;
//   const showSpeakerNote = speakerLabel && speakerLabelRequired;
//   return (
//     <Wrapper>
//       {hint ? <h3 className="hint">{hint}</h3> : null}
//       {showSpeakerNote ? <h4 className="speaker">{speakerLabel}</h4> : null}
//       <div className={cx('paragraph', { 'avoid-break': hint })}>
//         <p className="original">
//           <StoryTree elements={elements} />
//         </p>
//         {/* <p className="translation">
//           {elements.map(({ translation }) => {
//             if (!translation) {
//               return null;
//             }
//             return translation + ' ';
//           })}
//         </p> */}
//       </div>
//     </Wrapper>
//   );
// };

const SpeakerLabel = ({ node }: { node: ElementNode }) => {
  const element = node.element as Paragraph;

  const label = element.speakerLabel;
  if (!label) {
    return null;
  }

  return <h4 className={'speaker'}>{label}</h4>;
};

export const ParagraphPresentation = ({ node }: { node: ElementNode }) => {
  // const transcript = paragraphTranscript(node);

  return (
    <Wrapper>
      <SpeakerLabel key={node.element.id} node={node} />
      <div className={cx('paragraph' /*, { 'avoid-break': hint }*/)}>
        <p className="original">
          {/* <PlainMarkdown source={transcript} /> */}
          {/* iterate through sentences */}
          <StoryTree node={node} />
        </p>
      </div>
    </Wrapper>
  );
};
