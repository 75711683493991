import { convertQueryObjectToSorting } from './convert-query-object-to-sorting';
import { convertQueryObjectToFilter } from './convert-query-object-to-filters';
import { parseQueryString } from './parse-query-string';

export function locationStringToFilteringState(locationString: string) {
  return {
    filter: convertQueryObjectToFilter(parseQueryString(locationString)),
    sorting: convertQueryObjectToSorting(parseQueryString(locationString)),
  };
}
