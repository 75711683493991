import { capitalize } from 'lodash';
import { ModelTreeNode } from 'ts-state-tree/tst-core';

/**
 * PaymentData
 *
 * singleton child of AccountData which groups the subscription related details
 */
export class PaymentData extends ModelTreeNode {
  static CLASS_NAME = 'PaymentData' as const;

  static create(snapshot: any) {
    return super.create(PaymentData, snapshot) as PaymentData;
  }

  autoRenewInterval?: string;
  paymentProvider?: string; // apple
  cardLastFour?: string;
  cardExp?: string;
  cardBrand?: string;
  autoRenewAmount?: number;
  lastPaymentAt?: string;
  lastPaymentAmount?: number;
  autoRenewFailed: boolean = false;

  get paymentMethodDisplay() {
    if (this.paymentProvider === 'apple') {
      return 'Apple subscription';
    }
    if (this.cardExp) {
      const [month, year] = this.cardExp.split(' / ');

      const exp = `${month.length > 1 ? month : `0${month}`}/${year.slice(2)}`;

      return `${capitalize(this.cardBrand)} ****${
        this.cardLastFour
      }, expires ${exp}`;
    }

    return null;
  }
}
