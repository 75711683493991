import * as React from 'react';

export function LicenceLargeIcon({
  width = 28,
  height = 28,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 28 28"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 6H23C23 7.10457 23.8954 8 25 8L25 20C23.8954 20 23 20.8954 23 22L5 22C5 20.8954 4.10457 20 3 20V8C4.10457 8 5 7.10457 5 6ZM2 7C2 5.89543 2.89543 5 4 5H24C25.1046 5 26 5.89543 26 7V21C26 22.1046 25.1046 23 24 23H4C2.89543 23 2 22.1046 2 21V7ZM9 9H19V11H9V9ZM20 15H8V16H20V15ZM8 17H20V18H8V17Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}

export function LicenceIcon({
  width = 24,
  height = 24,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 17L3 7C4.10457 7 5 6.10457 5 5L19 5C19 6.10457 19.8954 7 21 7V17C19.8954 17 19 17.8954 19 19H5C5 17.8954 4.10457 17 3 17ZM2 6C2 4.89543 2.89543 4 4 4H20C21.1046 4 22 4.89543 22 6V18C22 19.1046 21.1046 20 20 20H4C2.89543 20 2 19.1046 2 18V6ZM16 8H8V10H16V8ZM7 13H17V14H7V13ZM17 15H7V16H17V15Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}
