import { IElement, StringToNumber } from '../basic-types';
import { Precedence } from './precedence';

export class PrecedenceImpl<KTL extends readonly string[]>
  implements Precedence<KTL>
{
  precedence: KTL;
  keyToPrecedenceLevel: StringToNumber;

  constructor(precedence: KTL) {
    this.precedence = precedence;
    this.keyToPrecedenceLevel = {};

    for (const [index, kind] of this.precedence.entries()) {
      this.keyToPrecedenceLevel[kind] = index;
    }
  }

  // this is actually one lower than the lowest precedence level
  leastPrecedenceLevel() {
    return this.precedence.length;
  }

  precedenceAtLeastFilter(kind: string): (el: IElement) => boolean {
    const level: number = this.keyToPrecedenceLevel[kind];
    return (el: IElement) => this.keyToPrecedenceLevel[el.kind] >= level;
  }
}
