// DEPRECATED: This component is no longer used.
import React from 'react';
import { CopyCode } from 'components/ui/code';
import { Button } from '@naan/primitives/button';
import { EmptyCard } from './empty-card';
import { Classroom } from 'core/models/user-manager';
import { styled } from '@naan/stitches.config';
import __ from 'core/lib/localization';

const ControlWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  '& .button': {
    marginTop: '16px',
  },

  '@media (min-width: 720px)': {
    flexDirection: 'row',

    '& .button': {
      marginTop: '0',
      marginLeft: '24px',
    },
  },
});

const ZeroStateControl = ({
  classroom,
  onInvite,
}: {
  classroom: Classroom;
  onInvite: () => void;
}) => {
  return (
    <ControlWrapper>
      <CopyCode code={classroom.code} />
      <div className="button">
        <Button
          presentation={'grayLight'}
          size="small"
          label={__('Instructions for students', 'instructionsForStudents')}
          onClick={onInvite}
          data-test-id="student-instructions"
        />
      </div>
    </ControlWrapper>
  );
};

export const StudentsZeroState = ({
  classroom,
  onInvite,
}: {
  classroom: Classroom;
  onInvite: () => void;
}) => {
  return (
    <EmptyCard
      control={<ZeroStateControl classroom={classroom} onInvite={onInvite} />}
    >
      <div className="title">
        {__('Share your class code with students', 'shareYourClassCode')}
      </div>
      <div className="small-text">
        {__(
          'They will appear here after they enter it from their Jiveworld account',
          'studentsWillAppearHereAfter'
        )}
      </div>
    </EmptyCard>
  );
};
