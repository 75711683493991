import React from 'react';
import { Plan } from '@cas-shared/plan';
import { formatMoney } from '@cas-shared/format-money';
import { capitalize } from 'lodash';
import { AppFactory } from '@app/app-factory';
import { createLogger } from '@common/log';

import __ from 'core/lib/localization';

const log = createLogger('use-derived-plan-vars');

function resolveIntervalLabel(plan: Plan): string {
  switch (plan.billingInterval) {
    case 'month':
      return __('Monthly', 'monthly');
    case 'year':
      return __('Yearly', 'yearly');
    case 'day':
      return 'Daily (testing)'; //__('Daily', 'daily'); // only used for internal testing, not really needed any more
    default:
      log.error(`unexpected billing interval: ${plan.billingInterval}`);
      return capitalize(plan.billingInterval);
  }
}

// todo: clean this up. we only use "billed yearly" and it's lower cased
// function resolvePricingExplanation(plan: Plan): string {
//   const { isMonthly, isYearly /*, isDiscounted, normalPrice*/ } = plan;
//   let result = isMonthly
//     ? __('Billed monthly', 'billedMonthly')
//     : isYearly
//     ? __('Billed yearly', 'billedYearly')
//     : 'Billed daily'; // for testing only

//   return result;
// }

export const useDerivedPlanVars = (plan: Plan) => {
  const {
    price: planPrice,
    annualizedMonthlyPrice,
    isMonthly,
    isYearly,
    currency,
  } = plan;

  // needed to handle the percentage discount flow
  // const normalPrice = plan.normalPrice || plan.price;

  // let's curry this to the currency
  const formatCurrency = React.useCallback(
    (n: number) => formatMoney(n, currency),
    [currency]
  );

  const intervalLabel = resolveIntervalLabel(plan);
  // const pricingExplanation = resolvePricingExplanation(plan);

  const {
    hasSpecialPricing,
    pricingTierLabel,
    discountScheme,
    showEducationIcon,
  } = AppFactory.root.userManager.membership;

  const percentOff = discountScheme?.percentOff || 0;
  const hasPercentageDiscount = percentOff > 0;
  const price = planPrice * (1 - percentOff / 100);

  const perMonthPrice = isMonthly
    ? price
    : isYearly
    ? (price as number) / 12
    : (price as number) * 30; /* daily testing */

  // const showNormalPrice = isYearly || percentOff > 0;

  const showRibbon = hasSpecialPricing;

  var yellowText: string = null;

  if (isYearly) {
    const yearlyPercentOff = Math.round(
      (100 * (annualizedMonthlyPrice - planPrice)) / annualizedMonthlyPrice
    );

    yellowText = discountScheme
      ? __(
          'Save additional {{yearlyPercentOff}}%',
          'saveAdditionalPercentage',
          {
            yearlyPercentOff,
          }
        )
      : __('Save {{yearlyPercentOff}}%', 'savePercentage', {
          yearlyPercentOff,
        });
  }

  return {
    yellowText,
    intervalLabel,
    // pricingExplanation,
    pricingTierLabel,
    showEducationIcon,
    actualPrice: price,
    planPrice,
    perMonthPrice,
    annualizedMonthlyPrice,
    ribbonTitle: pricingTierLabel,
    showRibbon,
    isYearly,
    hasPercentageDiscount,
    formatCurrency,
  } as const;
};
