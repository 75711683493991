import './init-embed-state';

export const Insets = {
  // should always be assigned, but being paranoid
  top: window?.embedState?.insetTop || 0,
  bottom: window?.embedState?.insetBottom || 0,
};

// eslint-disable-next-line no-console
console.log(`Insets: ${JSON.stringify(Insets)}`);
