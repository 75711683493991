/// transform a pattern with * into a regular expression
function patternToRegexp(pattern: string) {
  // escape special characters
  const escaped = pattern.replace(/[-[\]{}()+?.,\\^$|#\s]/g, '\\$&');
  // replace wildcards
  return new RegExp(escaped.replace(/\*/g, '.*?'));
}

/**
 * Wether a pattern matches a path
 */
function match(path: string, pattern: string) {
  const regexp = patternToRegexp(pattern);
  return path.match(regexp) !== null;
}

export default match;
