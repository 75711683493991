import * as React from 'react';
import { FilterButton } from './filter-button';
import { Menu, MenuItem } from 'naan/primitives/menus';
import { styled } from 'naan/stitches.config';
import { useFiltering } from '@common/filtering/use-filtering';
import { AppFactory } from '@app/app-factory';

import __ from '@core/lib/localization';

const Carrot = styled('span', {
  marginTop: '-2px',
  display: 'block',
  px: '$1',
  transitionProperty: 'transform',
  transitionDuration: '400ms',
  variants: {
    selected: {
      true: {
        color: '$teal-500',
        transitionDuration: '200ms',
        transform: 'rotate(-180deg) ',
      },
    },
  },
});

const carrot = (
  <svg
    width={8}
    height={7}
    viewBox="0 0 8 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.2002 1.40039L4.0002 4.60039L0.800196 1.40039"
      stroke="currentColor"
      strokeWidth={2}
    />
  </svg>
);

export const ChannelDropdownButton = ({
  onMenuOpen,
}: {
  onMenuOpen: () => void;
  inClassroomMode?: boolean;
}) => {
  const { storyManager } = AppFactory.root;
  const channels = storyManager.channelsWithStories;

  const { addFilter } = useFiltering();

  return (
    <Menu
      align="start"
      offset={-8}
      onOpenChange={open => {
        if (open) {
          onMenuOpen();
        }
      }}
      trigger={
        <FilterButton
          selected={false}
          label={__('Channels', 'channels')}
          rightIcon={<Carrot selected={false}>{carrot}</Carrot>}
        />
      }
    >
      {channels.map(channel => (
        <MenuItem
          action={() => {
            addFilter('channel', channel.title);
          }}
          label={channel.title}
        />
      ))}
    </Menu>
  );
};
