import * as React from 'react';

export function useIntersectionObserver({
  threshold = 0.15,
  inViewCallback,
}: { threshold?: number; inViewCallback?: () => void } = {}) {
  const [isInView, setIsInView] = React.useState(false);
  const elementRef = React.useRef<HTMLDivElement>(null);
  const hasBeenInView = React.useRef(false);

  React.useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting && !hasBeenInView.current) {
            hasBeenInView.current = true;
            if (inViewCallback) {
              inViewCallback();
            }
          }
          setIsInView(entry.isIntersecting);
        });
      },
      { threshold }
    );

    const currentRef = elementRef.current;

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [threshold, inViewCallback]);

  return {
    isInView,
    newWidgetRef: elementRef,
  };
}
