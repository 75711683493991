import { styled } from 'naan/stitches.config';

export const ButtonBadge = styled('span', {
  color: '$colors$white',
  backgroundColor: '$$buttonTextColor',
  borderRadius: '999px',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  textStyle: 'badge-text',
  px: '6px',
  paddingTop: '2px',
  paddingBottom: '-2px',
  aspectRatio: '1',
  position: 'relative',
  top: '-1px',
});
