// import dayjs from 'dayjs';
import { isEmpty, isNil, isNull } from 'lodash';

export function isNumber(o: any) {
  return typeof o === 'number';
}

// beware, the lodash version if isEmpty always returns 'true' for numbers
export function isEmptyStringOrNumber(o: any) {
  if (isNumber(o)) {
    return !o; // treat 'falsy' as empty for numbers
  } else {
    return isEmpty(o);
  }
}

export function notEmpty(o: any) {
  return !isEmpty(o);
}

export function notNil(o: any) {
  return !isNil(o);
}

export function notNull(o: any) {
  return !isNull(o);
}

// export function isDateToday(date: string): boolean {
//   const today = dayjs();
//   const inputDate = dayjs(date);

//   return today.isSame(inputDate, 'day');
// }
