import * as React from 'react';
import { FeedbackDialog } from './feedback-dialog';
import { ThanksDialog } from './thanks-dialog';
import { FeedbackMetadata } from './feedback-metadata';
import { useFeedbackState } from './use-feedback-state'; // import the hook
import { AppFactory } from '@app/app-factory';
import { observer } from 'mobx-react';

type RenderControlProps = {
  openDialog: () => void;
};

export type FeedbackControlProps = {
  dialogContext: React.ReactNode;
  metadata: FeedbackMetadata;
  renderControl: (props: RenderControlProps) => React.ReactNode;
};

/*
  Encapsulates the rendering of the a feedback control and either the feedback form or thanks dialogs
*/
export const FeedbackControl = observer(
  ({ dialogContext, metadata, renderControl }: FeedbackControlProps) => {
    const { state, openDialog, closeDialog, showThanks } = useFeedbackState();
    const { offline } = AppFactory.root;

    if (offline && state === 'initial') {
      return null;
    }

    return (
      <>
        {state === 'open' && (
          <FeedbackDialog
            context={dialogContext}
            onDismiss={closeDialog}
            afterSubmit={showThanks}
            metadata={metadata}
          />
        )}
        {state === 'thanks' && <ThanksDialog onDismiss={closeDialog} />}

        {renderControl({ openDialog })}
      </>
    );
  }
);
