import * as React from 'react';
import { observer } from 'mobx-react';
import { useFiltering } from 'common/filtering/use-filtering';
import { StoryList } from './story-list';
import { AppFactory } from 'app/app-factory';

import {
  hasPrimaryFilter,
  PrimaryFilterKeys,
} from '@core/models/story-manager/story-manager';
import { Story } from '@core/models/story-manager/story';
import { ZeroStories } from './zero-stories';

export const FilteredStories = observer(
  ({ primaryFilterKey }: { primaryFilterKey: PrimaryFilterKeys }) => {
    const { storyManager } = AppFactory.root;
    var stories: Story[];
    if (hasPrimaryFilter(primaryFilterKey)) {
      // with latest design, tag filtering is always independent of 'primary' filters
      stories = storyManager.primaryFilteredStories(primaryFilterKey);
    } else {
      const { filteredUnits } = useFiltering();
      stories = filteredUnits;
    }

    return stories.length > 0 ? (
      <StoryList stories={stories} />
    ) : (
      <ZeroStories />
    );
  }
);
