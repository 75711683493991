import { styled } from '@naan/stitches.config';

export const AccountTableContainer = styled('div', {
  borderBottom: '1px solid $gray-100',
});

export const AccountTableSubheading = styled('h2', {
  textStyle: 'body-bold',
  padding: '12px 0',
});
