import { darkThemeClassName, styled } from 'naan/stitches.config';
import { Insets } from 'native-support/insets';
import {
  studyPalette,
  paletteKey,
  // listenModePalette,
  darkStudyPalette,
} from 'study/views/study-palette';

export const LayoutContainer = styled('section', {
  position: 'fixed',
  left: 0,
  bottom: 0,
  right: 0,
  // top: 'var(--statusbarHeight,0px)',
  top: Insets.top,

  backgroundColor: paletteKey('$$script__backgroundColor_default'),
  zIndex: 2,
  display: 'grid',
  gridTemplateRows: '1fr auto',

  '&::before': {
    content: '""',
    display: 'block',
    height: Insets.top,
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    // background: 'red',
    zIndex: 100,
    background: 'var(--statusbarBgColor, $color$gray-800)',
  },

  [`body:not(.${darkThemeClassName}) &`]: {
    ...studyPalette,
  },

  [`body.${darkThemeClassName} &`]: {
    ...darkStudyPalette,
  },

  // '&.listen-mode': {
  //   ...listenModePalette,
  // },
});

LayoutContainer.displayName = 'LayoutContainer';
LayoutContainer.defaultProps = {
  id: 'study-layout-container',
};

const ScriptOuterContainer = styled('div', {
  '--top-notation-pos': 0,

  overflow: 'hidden',
  overflowY: 'auto',
  overscrollBehaviorY: 'contain',
  transition: 'background .3s',
  backgroundColor: paletteKey('$$script__backgroundColor_default'),
});

ScriptOuterContainer.displayName = 'ScriptOuterContainer';

const ScriptInnerContainer = styled('div', {
  width: 'min( calc( 100% - $space$4), 600px)',
  margin: '0 auto',
});

ScriptInnerContainer.displayName = 'ScriptInnerContainer';

export const ControlsContainer = styled('div', {
  background: '$colors$gray-25',
  display: 'flex',
  flexShrink: '0',
  position: 'relative',
  zIndex: '2',
});
