import { PassagePresentation } from './passage';
import { ParagraphPresentation } from './paragraph';
import { WordPresentation } from './word';
import { SentencePresentation } from './sentence';

export const typeMap = {
  PASSAGE: PassagePresentation,
  PARAGRAPH: ParagraphPresentation,
  SENTENCE: SentencePresentation,
  WORD: WordPresentation,
} as any; // todo: tighten this up
