import * as React from 'react';

import { CloseIcon } from '@naan/icons/close-icon';
import { styled } from '@naan/stitches.config';
import { PlayerViewController } from 'components/ui/video-player/player-view-controller';
import { observer } from 'mobx-react';
import { Image } from '@naan/primitives/image';
import { IconButton } from '@naan/primitives/button';
import { SpinnerIcon } from 'components/ui/icons/spinner-icon';

const Wrapper = styled('div', {
  position: 'absolute',
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  padding: 48,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 2,

  '& > .poster': {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    zIndex: 1,

    '& > img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  },

  '& > .exit-button': {
    position: 'absolute',
    left: 12,
    top: 12,
    zIndex: 2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 8,
  },

  '& > .center': {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 2,
    position: 'relative',
    paddingBottom: 16,
    '& > .spinner svg': {
      width: 104,
      height: 104,
      '& > circle': {
        stroke: '$colors$globalWhite',
        strokeWidth: 8,
        strokeLinecap: 'round',
      },
    },
    '& > .play-btn': {
      width: 112,
      height: 112,
      all: 'unset',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& > svg': {
        width: 104,
        height: 104,
        transition: 'transform 0.3s ease-out',
      },

      '&:hover > svg': {
        transform: 'scale(1.07)',
      },
    },
    '& > .title': {
      top: '100%',
      position: 'absolute',
      textAlign: 'center',
      textStyle: 'handwritten-video-title',
      color: '$colors$globalWhite',
      maxWidth: 325,
    },
  },
});

const PlayVideoIcon = (
  <svg
    width="112"
    height="112"
    viewBox="0 0 112 112"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M56 112C86.9279 112 112 86.9279 112 56C112 25.0721 86.9279 0 56 0C25.0721 0 0 25.0721 0 56C0 86.9279 25.0721 112 56 112ZM47.6922 78.4574L79.5947 57.5615C79.8094 57.4209 79.9928 57.2374 80.1334 57.0227C80.6983 56.1603 80.4571 55.0033 79.5947 54.4384L47.6922 33.5425C47.3882 33.3434 47.0328 33.2374 46.6694 33.2374C45.6385 33.2374 44.8027 34.0731 44.8027 35.104V76.8959C44.8027 77.2592 44.9088 77.6147 45.1079 77.9187C45.6727 78.7811 46.8298 79.0223 47.6922 78.4574Z"
      fill="white"
    />
  </svg>
);

export const NotStartedOverlay = observer(
  ({
    title,
    poster,
    player,
    onExit,
    onPlay,
    loading = false,
  }: {
    title: string;
    poster: string;
    player: PlayerViewController;
    onExit: () => void;
    onPlay: () => void;
    loading?: boolean;
  }) => {
    return (
      <Wrapper className="not-started-overlay">
        <div className="poster">
          <Image src={poster} />
        </div>
        <div className="center">
          {loading ? (
            <div className="spinner">
              <SpinnerIcon />
            </div>
          ) : (
            <button
              className="play-btn"
              onClick={() => {
                onPlay();
              }}
            >
              {PlayVideoIcon}
            </button>
          )}
          <div className="title">{title}</div>
        </div>
        <div className="exit-button">
          <IconButton
            presentation={'whiteDarken'}
            size="small"
            testId={'video-exit-button'}
            icon={<CloseIcon />}
            onClick={() => {
              player.pause();
              onExit();
            }}
          />
        </div>
      </Wrapper>
    );
  }
);
