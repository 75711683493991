import * as React from 'react';
import { styled } from '@naan/stitches.config';
import { PlainMarkdownWithLinks } from '@naan/primitives/text';
import { LifebuoyIcon } from '@naan/icons/lifebuoy-icon';
import { appConfig } from '@app/env';
import { contactUs, profileHomePath } from 'components/nav/path-helpers';
import { AppFactory } from '@app/app-factory';
import { HelpBoxStyles } from './help-box-styles';

import __, { pickLocalizedValue } from '@core/lib/localization';

const Wrapper = styled(HelpBoxStyles, {
  $$iconColor: '$colors$orange-500',
  background: '$colors$yellow-100',
});

export const HelpBox = () => {
  const { purchaseFlowDisabled } = AppFactory.root.userManager;

  const helpCenterUrl = pickLocalizedValue({
    en: appConfig.website.urls.enSupport,
    pt: appConfig.website.urls.ptSupport,
  });

  return (
    <Wrapper>
      <div className="icon">
        <LifebuoyIcon />
      </div>
      <h3>{__('Need help?', 'needHelpQuestionMark')}</h3>
      <div className="content">
        {purchaseFlowDisabled ? (
          <PlainMarkdownWithLinks
            source={__(
              `[Visit our Help Center](%{helpCenterUrl}) for answers to common problems. Still need help?
[Get in touch](%{contactUrl}).`,
              'visitOurHelpCenterForAnswersPurchaseDisabled',
              {
                helpCenterUrl,
                contactUrl: contactUs(),
              }
            )}
          />
        ) : (
          <PlainMarkdownWithLinks
            source={__(
              `[Visit our Help Center](%{helpCenterUrl}) for answers to common problems. Still need help?
[Get in touch](%{contactUrl}). You can also manage your subscription from [your account](%{accountUrl}).`,
              'visitOurHelpCenterForAnswers',
              {
                helpCenterUrl,
                contactUrl: contactUs(),
                accountUrl: profileHomePath(),
              }
            )}
          />
        )}
      </div>
    </Wrapper>
  );
};
