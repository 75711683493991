import * as React from 'react';

export function PrinterExtraLargeIcon({
  width = 32,
  height = 32,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 9H24V5.5C24 5.22386 23.7761 5 23.5 5H8.5C8.22386 5 8 5.22386 8 5.5V9ZM25 9V9.00005H27C28.1046 9.00005 29 9.89548 29 11V21C29 22.1046 28.1046 23 27 23H25V25.5C25 26.3284 24.3284 27 23.5 27H8.5C7.67157 27 7 26.3284 7 25.5V23H5C3.89543 23 3 22.1046 3 21V11C3 9.89548 3.89543 9.00005 5 9.00005H7V9V5.5C7 4.67157 7.67157 4 8.5 4H23.5C24.3284 4 25 4.67157 25 5.5V9ZM24 17V23V25.5C24 25.7761 23.7761 26 23.5 26H8.5C8.22386 26 8 25.7761 8 25.5V23V17H24ZM26 13C26.5523 13 27 12.5523 27 12C27 11.4478 26.5523 11 26 11C25.4477 11 25 11.4478 25 12C25 12.5523 25.4477 13 26 13ZM22 18H10V19H22V18ZM10 20H22V21H10V20ZM22 22H10V23H22V22Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}

export function PrinterIcon({
  width = 24,
  height = 24,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 4.5V7H6V4.5C6 4.22386 6.22386 4 6.5 4H17.5C17.7761 4 18 4.22386 18 4.5ZM5 7V4.5C5 3.67157 5.67157 3 6.5 3H17.5C18.3284 3 19 3.67157 19 4.5V7H20C21.1046 7 22 7.89543 22 9V15C22 16.1046 21.1046 17 20 17H19V19.5C19 20.3284 18.3284 21 17.5 21H6.5C5.67157 21 5 20.3284 5 19.5V17H4C2.89543 17 2 16.1046 2 15V9C2 7.89543 2.89543 7 4 7H5ZM6 17V19.5C6 19.7761 6.22386 20 6.5 20H17.5C17.7761 20 18 19.7761 18 19.5V17V13H6V17ZM21 9C21 9.55228 20.5523 10 20 10C19.4477 10 19 9.55228 19 9C19 8.44772 19.4477 8 20 8C20.5523 8 21 8.44772 21 9ZM7 14H17V15H7V14ZM17 16H7V17H17V16Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}
