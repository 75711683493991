import { isNil } from 'lodash';
import {
  ElementId,
  WideElementList,
  IElement,
  IntervalIndexedElement,
  IndexRange,
} from '../basic-types';
import { isRangeElement } from '../elements/ad-hoc-word-range';
import { elementIdToDomId } from '../elements/element-id-utils';
import { MembershipReconciler } from './membership-reconciler';

// TODO Enum for add/remove??
export type MembershipList = {
  memberships: string[];
  elements: WideElementList;
  domScope?: string; // TODO use? OR MAYBE NEEDS TO BE FUNCTION id -> dom id to handle mapping to correct sentence element etc???
  useRanges?: boolean;
  range?: IndexRange;
};

export class MembershipReconcilerImpl implements MembershipReconciler {
  episodeKey = '';
  domScope: string = ''; // TODO
  layers: Map<string, MembershipList> = new Map();
  layerStack: MembershipList[] = [];

  constructor(
    setMembershipsForId: (
      id: string,
      memberships: string[],
      remove: boolean
    ) => void
  ) {
    this.setMembershipsForId = setMembershipsForId;
  }

  setMembershipsForId: (
    id: string,
    memberships: string[],
    remove: boolean
  ) => void = null;

  setMembershipsForElement(
    element: IElement | IntervalIndexedElement,
    memberships: string[],
    elementList: WideElementList,
    domScope: string,
    remove: boolean,
    useRangesOf = false
  ) {
    if (useRangesOf) {
      if (elementList.words) {
        if (isRangeElement(element)) {
          const adhocWordRange = element;
          const range = adhocWordRange.range;
          const wordIds = elementList.words.idRangeAsIds(range);
          for (const wordId of wordIds) {
            const domId = elementIdToDomId(domScope, wordId);
            this.setMembershipsForId(domId, memberships, remove);
          }
        } else {
          if ('address' in element) {
            const address = element.address;
            const endAddress = element.endAddress;
            const words = elementList.words.values;
            for (let i = address; i <= endAddress; i++) {
              const domId = elementIdToDomId(domScope, words[i].id);
              this.setMembershipsForId(domId, memberships, remove);
            }
          } else if ('anchor' in element) {
            // TODO cannot deal with this in current tikka minimalized typespace, handle this case when unify masala
          }
        }
      } else {
        // TODO throw?
      }
    } else {
      const domId = elementIdToDomId(domScope, element.id);
      this.setMembershipsForId(domId, memberships, remove);
    }
  }

  simpleSetMembershipsForElementId(
    id: ElementId,
    memberships: string[],
    remove: boolean
  ) {
    const domId = elementIdToDomId('', id);
    this.setMembershipsForId(domId, memberships, remove);
  }

  setMembershipsForElementList(
    elementList: WideElementList,
    memberships: string[],
    range: IndexRange,
    domScope: string,
    remove: boolean,
    useRangesOf = false
  ) {
    // TODO handle non bottom open ranges
    const elements = range
      ? elementList.values.slice(0, range.end + 1)
      : elementList.values;
    for (const element of elements) {
      this.setMembershipsForElement(
        element,
        memberships,
        elementList,
        domScope,
        remove,
        useRangesOf
      );
    }
  }

  getMembershipForAddress(index: number): string[] {
    let membership = [];
    for (const layer of this.layerStack) {
      if (layer.useRanges) {
        const elements = layer.elements;
        const found = elements.getElementContainingWordAddress(index);
        if (found && isRangeElement(found)) {
          membership.push(...layer.memberships);
        }
      }
      // TODO consider if could relate to range reconciliation?
    }
    return membership;
  }

  getJoinedMembershipStringForAddress(index: number): string {
    return this.getMembershipForAddress(index).join(' ');
  }

  getMembershipForElement(id: ElementId): string[] {
    const memberships: string[] = [];
    for (const layer of this.layerStack) {
      const elements = layer.elements;
      if (elements.hasElement(id)) {
        if (layer.range) {
          if (elements.getIndex(id) <= layer.range.end) {
            memberships.push(...layer.memberships);
          }
        } else {
          memberships.push(...layer.memberships);
        }
      }
    }
    return memberships;
  }

  getJoinedMembershipStringForElement(id: ElementId): string {
    return this.getMembershipForElement(id).join(' ');
  }

  setMembershipLists(layers0: Map<string, MembershipList>) {
    this.layers = layers0;
    // compute layer stack
    this.layerStack = [...layers0.values()];
  }

  reconcileMembershipLists(
    episodeKey: string,
    layers0: Map<string, MembershipList>
  ) {
    // TODO change name renderMembershipOverlays??
    // DOM manipulation is done imperatively in this function nothing is driven by observable
    // if episodeKey same current (otherwise membership changes and expressed by html rendering not DOM manipulation)
    if (this.episodeKey !== episodeKey) {
      this.layers = new Map();
    }

    // for key in layers do
    for (const key of layers0.keys()) {
      // get the old and new layers for key
      const newOverlay = layers0.get(key);
      const oldOverlay = this.layers.get(key);
      // compute differences in both directions using elementlist difference
      // TODO difference is minus here, use .minus instead of .difference?
      // TODO figure out what the bool param is
      if (isNil(oldOverlay)) {
        this.setMembershipsForElementList(
          newOverlay.elements,
          newOverlay.memberships,
          newOverlay.range,
          null,
          false,
          newOverlay.useRanges
        );
      } else {
        if (!newOverlay.range) {
          const addedElements = newOverlay.elements.difference(
            oldOverlay.elements
          );
          const removedElements = oldOverlay.elements.difference(
            newOverlay.elements
          );
          this.setMembershipsForElementList(
            removedElements,
            newOverlay.memberships,
            null,
            null,
            true,
            newOverlay.useRanges
          );
          this.setMembershipsForElementList(
            addedElements,
            newOverlay.memberships,
            null,
            null,
            false,
            newOverlay.useRanges
          );
        } else {
          // TODO using range reconciliation
          // old and new overlay exist need to use ranges of to add and remove
          if (oldOverlay.elements !== newOverlay.elements) {
            throw new Error(
              'range reconciliation can only diff the same elements list with diff ranges'
            );
          }
          const elements = oldOverlay.elements;
          const oldEnd = oldOverlay.range.end;
          const newEnd = newOverlay.range.end;
          const memberships = newOverlay.memberships;
          if (newEnd > oldEnd) {
            const addRange = { begin: oldEnd + 1, end: newEnd };
            this.setMembershipsForElementList(
              elements,
              memberships,
              addRange,
              null,
              false
            );
          }
          if (oldEnd > newEnd) {
            const removeRange = { begin: newEnd + 1, end: oldEnd };
            this.setMembershipsForElementList(
              elements,
              memberships,
              removeRange,
              null,
              false
            );
          }
        }
      }
    }

    this.episodeKey = episodeKey;
    this.layers = layers0;
    // compute layer stack
    this.layerStack = [...layers0.values()];
  }
}
