import { createLogger } from '@common/log';
import { SettingsSync } from './settings-sync';
import { BaseFirestoreSync } from './base-firestore-sync';
import { GlobalSettings } from '@core/models/global-settings';
import { FirebaseConnection } from './firebase-types';
import { CaliServerInvoker } from './cali-server-invoker';
import { AppFactory } from '@app/app-factory';

const log = createLogger('catalog-meta-sync');

// singleton document to fetch
const globalDocId = 'global';
// const germanDocId = 'german';
const englishDocId = 'english';

export class SettingsSyncImpl
  extends BaseFirestoreSync<GlobalSettings>
  implements SettingsSync
{
  constructor({
    firebaseConnection,
    caliServerInvoker,
  }: {
    firebaseConnection: FirebaseConnection;
    caliServerInvoker: CaliServerInvoker;
  }) {
    super({ firebaseConnection, caliServerInvoker, log });
  }

  get docId() {
    const { l2 } = AppFactory.root;

    if (l2 === 'en') {
      return englishDocId;
    } else {
      return globalDocId;
    }
  }

  get collectionName() {
    return 'Settings';
  }

  get collectionPath() {
    return 'settings';
  }

  get globalDocRef() {
    return this.docRef(this.docId);
  }

  async fetchGlobal(): Promise<GlobalSettings> {
    return await this.fetch(this.docId);
  }

  subscribeGlobal(cb: (data: GlobalSettings) => void) {
    this.subscribe(this.docId, cb);
  }
}
