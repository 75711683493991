import React from 'react';
import { observer } from 'mobx-react';
import { ClientNotation } from '@tikka/client/client-types';
import { styled } from '@naan/stitches.config';
import { usePlayerModel } from 'player/views/player-model-context';
import { ChapterReviewModel } from 'vocab-review/chapter-review-model';
import { VocabListItem } from './vocab-list-item';
import { ZeroState } from './review-list-zero-state';

const ListWrapper = styled('div', {
  '& > *:not(.focus):before ': {
    content: '',
    display: 'block',
    position: 'absolute',
    top: 0,
    left: 16,
    right: 16,
    borderTop: '1px solid $colors$black-alpha-10',
  },
});

const PanelWrapper = styled('div', {});

export const ReviewNotationsListPanel = observer(() => {
  const model = usePlayerModel() as ChapterReviewModel;
  (window as any).reviewModel = model;

  // const notations = model.getNotationsForSentence(sentenceId);
  const notations = model.currentSentenceNotations;

  return (
    <PanelWrapper>
      <div className="inner">
        {notations.length === 0 ? (
          <ZeroState />
        ) : (
          <ListWrapper>
            {notations.map((notation: ClientNotation) => {
              return <VocabListItem key={notation.id} notation={notation} />;
            })}
          </ListWrapper>
        )}
      </div>
    </PanelWrapper>
  );
});
