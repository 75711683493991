import { frozen, ModelTreeNode } from 'ts-state-tree/tst-core';
import { L12String } from 'utils/util-types';
import { displayedProp } from 'utils/string-utils';
import { isEmpty } from 'lodash';

export class Speaker extends ModelTreeNode {
  static CLASS_NAME = 'Speaker' as const;

  static create(snapshot: any) {
    return super.create(Speaker, snapshot) as Speaker;
  }

  @frozen
  labelL12: L12String;

  @frozen
  bioL12: L12String;

  @frozen
  accentL12: L12String;

  get labelKey(): string {
    return this.labelL12?.l2;
  }

  get bio(): string {
    return displayedProp(this.bioL12);
  }

  get accent(): string {
    return displayedProp(this.accentL12);
  }

  get label(): string {
    return displayedProp(this.labelL12);
  }

  get showTip(): boolean {
    return (
      !!this.bio ||
      !!this.accent ||
      (!!this.labelL12?.l1 && !!this.labelL12?.l2)
    );
  }

  get includeInVoices(): boolean {
    return !!this.bio; // in future might also have an explicit boolean flag
  }

  matches(label: string): boolean {
    if (isEmpty(label)) return false;
    return this.labelKey === label || this.label === label;
  }

  // get displayedTip() {
  //   const bio = displayedProp(this.bio);
  //   const accent = displayedProp(this.accent);
  //   if (!bio && !accent) {
  //     return null;
  //   }
  //   if (bio && accent) {
  //     return `${bio} (${accent})`;
  //   }
  //   if (bio) {
  //     return bio;
  //   } else {
  //     return `(${accent})`;
  //   }
  // }
}
