import {
  ElementList as BElementList,
  ExtractTimedET,
  IDTOfET,
  KTOfET,
  SimplestElementList,
  universalETOf,
  universalIDTOf,
} from '../basic-types';
import {
  ClientChapter,
  ClientChapterComplete,
  ClientChapterNote,
  ClientElement,
  ClientNotation,
  ClientParagraph,
  ClientPassage,
  ClientSentence,
  ClientSic,
  ClientTricky,
  ClientWord,
} from './client-types';
import { ElementNodeType } from '../elements/element-tree';
import { Tracker } from '../tracking/tracker';
import { CreateElementList } from '../elements/element-list';
// import { StructuredPlayer } from '../player/structured-player';
// import { AudioTransport, TransportState } from '../player/audio-transport';
// import { Navigation } from '../navigation/timeline-navigator';

export type Element = ClientElement;
export type Word = ClientWord;
export type Sentence = ClientSentence;
export type Notation = ClientNotation;
export type Tricky = ClientTricky;
export type Sic = ClientSic;
export type Paragraph = ClientParagraph;
export type Passage = ClientPassage;
export type Chapter = ClientChapter;
export type ChapterNote = ClientChapterNote;
export type ChapterComplete = ClientChapterComplete;
export type ElementId = IDTOfET<Element>;
export type ElementKind = KTOfET<Element>;

export type IDTOf<T extends ElementKind | Element> = universalIDTOf<Element, T>;
export type ETOf<T extends ElementId | ElementKind> = universalETOf<Element, T>;

export type WordElementList = BElementList<Word>;
export type ElementList<ET extends Element> = BElementList<ET>;
export type ElementNode = ElementNodeType<Element, Element>;
export type ElementTracker<ET extends ExtractTimedET<Element>> = Tracker<
  IDTOfET<ET>,
  ET
>;

export const SimpleElementList = SimplestElementList as <ET extends Element>(
  elements: ET[]
) => BElementList<ET>;
export const EmptyWordElementList = SimpleElementList([] as Word[]);
export const EmptyElementList = CreateElementList({
  elements: [] as Element[],
  words: EmptyWordElementList,
}) as unknown as ElementList<any>;
