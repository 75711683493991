import * as React from 'react';

export function NoteSmallIcon({
  width = 20,
  height = 20,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5 18H5.5C4.67157 18 4 17.3284 4 16.5V3.5C4 2.67157 4.67157 2 5.5 2H14.5C15.3284 2 16 2.67157 16 3.5V13.5L11.5 18ZM15 13V3.5C15 3.22386 14.7761 3 14.5 3H5.5C5.22386 3 5 3.22386 5 3.5V16.5C5 16.7761 5.22386 17 5.5 17H11V14.5C11 13.6716 11.6716 13 12.5 13H15ZM12 16.0858V14.5C12 14.2239 12.2239 14 12.5 14H14.0858L12 16.0858Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}

export function NoteIcon({ width = 24, height = 24, color = 'currentColor' }) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 3H18C18.5523 3 19 3.44772 19 4V14H14.5C13.1193 14 12 15.1193 12 16.5V21H6C5.44772 21 5 20.5523 5 20V4C5 3.44772 5.44772 3 6 3ZM13 20.5858L18.5858 15H14.5C13.6716 15 13 15.6716 13 16.5V20.5858ZM4 4C4 2.89543 4.89543 2 6 2H18C19.1046 2 20 2.89543 20 4V14.5858C20 14.851 19.8946 15.1054 19.7071 15.2929L13.2929 21.7071C13.1054 21.8946 12.851 22 12.5858 22H6C4.89543 22 4 21.1046 4 20V4Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}
