import { MegaphoneIcon } from '@naan/icons/megaphone-icon';
import { MoonIcon } from '@naan/icons/moon-icon';
import { AlertIcon } from 'naan/icons/alert-icon';
import { AlertTriangleIcon } from 'naan/icons/alert-triangle-icon';
import { CheckmarkHeavyIcon } from 'naan/icons/checkmark-heavy-icon';
import { CloseIcon } from 'naan/icons/close-icon';
import { InfoCircleIcon } from 'naan/icons/info-circle-icon';
import { Button, IconButton } from 'naan/primitives/button';
import { styled } from 'naan/stitches.config';
import * as React from 'react';

const Wrapper = styled('div', {
  $$backgroundColor: '$colors$green-600',
  $$textColor: '$colors$globalWhite',
  $$linkColor: '$$textColor',
  $$iconColor: '$colors$globalWhite',

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '8px 16px',
  backgroundColor: '$$backgroundColor',
  color: '$$textColor',

  '& > .inner': {
    display: 'flex',
    alignItems: 'center',

    '&:has(> .close-button)': {
      paddingRight: '$space$9',
    },

    '@large': {
      margin: '0 auto',
    },

    '& > span+span': {
      px: 0,
      marginLeft: '$space$2',
    },

    '& span.label': {
      textStyle: 'body-bold',
      padding: '8px 0',
    },

    '& span.icon': {
      lineHeight: 0,
      color: '$$iconColor',
    },

    '& span.close-button': {
      position: 'absolute',
      right: '$space$2',
    },

    '& a': {
      textStyle: 'body',
      color: '$$linkColor',
      textWrap: 'no-wrap',
    },

    '@medium': {
      justifyContent: 'center',
      '&:has(> .close-button)': {
        paddingLeft: '$space$9',
      },
    },
  },

  variants: {
    type: {
      info: {
        $$backgroundColor: '$colors$blue-500',
        $$iconColor: '$colors$globalWhite',
        $$textColor: '$colors$globalWhite',
      },
      success: {
        $$backgroundColor: '$colors$green-600',
        $$iconColor: '$colors$globalWhite',
        $$textColor: '$colors$globalWhite',
      },
      warning: {
        $$backgroundColor: '$colors$yellow-300',
        $$iconColor: '$colors$black-alpha-70',
        $$textColor: '$colors$black-alpha-70',
      },
      error: {
        $$backgroundColor: '$colors$red-500',
        $$iconColor: '$colors$globalWhite',
        $$textColor: '$colors$globalWhite',
      },
      announcement: {
        $$backgroundColor: '$colors$yellow-300',
        $$iconColor: '$colors$orange-500',
        $$textColor: '$colors$black-alpha-70',
      },
      'dark-mode': {
        $$backgroundColor: '$colors$gray-800',
        $$iconColor: '$colors$globalWhite',
        $$textColor: '$colors$globalWhite',
      },
    },
  },
});

export type MessageType =
  | 'success'
  | 'warning'
  | 'error'
  | 'info'
  | 'announcement'
  | 'dark-mode';

export type Message = {
  type?: MessageType;
  message: React.ReactNode;
  renderLink?: () => React.ReactNode;
  action?: {
    label: string;
    callback: () => void;
  } | null;
  dismissible?: boolean; // @armando, do you want keep this boolean prop or just rely on onDismiss?
  onDismiss?: () => void;
  elementId?: string;
};

type GlobalMessageProps = {
  type: MessageType;
  message: Message;
  onDismiss?: () => void;
  elementId?: string;
};

export const GlobalMessage = ({
  type,
  message,
  onDismiss,
  elementId,
}: GlobalMessageProps) => {
  const icon = React.useMemo(() => {
    switch (type) {
      case 'info':
        return <InfoCircleIcon />;
      case 'success':
        return <CheckmarkHeavyIcon />;
      case 'warning':
        return <AlertIcon />;
      case 'error':
        return <AlertTriangleIcon />;
      case 'announcement':
        return <MegaphoneIcon />;
      case 'dark-mode':
        return <MoonIcon />;
      default:
        break;
    }
    return null;
  }, [type]);

  const showButton = !!message.action;

  const dismissHandler = message.onDismiss || onDismiss; // not sure if we need both options
  // const showCloseButton = message.dismissible && onDismiss;
  const showCloseButton = message.dismissible && dismissHandler;

  return (
    <Wrapper type={type} id={elementId} data-testid="global-message-wrapper">
      <div className="inner">
        <span className="icon">{icon}</span>
        <span className="label">
          {message.message}
          {message.renderLink ? (
            <span className="link"> {message.renderLink()}</span>
          ) : null}
        </span>
        {showButton ? (
          <span className="action-button">
            <Button
              onClick={message.action.callback}
              label={message.action.label}
              size={'small'}
              presentation="white"
            />
          </span>
        ) : null}
        {showCloseButton ? (
          <span className="close-button">
            <IconButton
              icon={<CloseIcon />}
              presentation={
                type === 'warning' || type === 'announcement'
                  ? 'grayTransparent'
                  : 'whiteTransparent'
              }
              onClick={dismissHandler}
              testId="close-global-message"
            />
          </span>
        ) : null}
      </div>
    </Wrapper>
  );
};
