import * as React from 'react';

function scrollWindowToTop() {
  window.scrollTo({
    top: 0,
    behavior: 'auto',
  });
}

function useScrollToTop(dependencies: any[] = []) {
  React.useLayoutEffect(() => {
    /// this RAF is necessary because if we scroll before we have a chance to render,
    /// the scroll will be ignored. This is more obvious when using the browser back button
    requestAnimationFrame(scrollWindowToTop);
  }, [dependencies]);
}

export const ScrollToTop = ({
  dependencies = [],
}: {
  dependencies?: any[];
}): null => {
  useScrollToTop(dependencies);
  return null;
};
