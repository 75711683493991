import React from 'react';
import useSWR from 'swr';
import { AppFactory } from 'app/app-factory';
import { FullScreenLoader } from 'components/ds/modals';
import { useParams } from 'react-router-dom';
import { ScriptFlavor, ScriptModel } from 'components/guides/cali/script-model';
import { Story } from '@core/models/story-manager/story';
import { CaliStoryScript } from 'components/guides/cali/cali-story-script';
import { NotFoundScreen } from 'routes/not-found-screen';
import { upperCase } from 'lodash';

// import { createLogger } from 'app/logger';
// const log = createLogger('cali-basic-script');

const fetcher = ({
  story,
  flavor,
}: {
  story: Story;
  flavor: ScriptFlavor;
}): Promise<ScriptModel> => ScriptModel.create(story, flavor);

export const CaliScriptScreen = () => {
  const { flavor: flavorParam, slug } =
    useParams<{ flavor: string; slug: string }>();
  const { storyManager, userManager } = AppFactory.root;
  const story = storyManager.story(slug);
  const flavor = upperCase(flavorParam) as ScriptFlavor; // let model constructor validate

  const { data: model, error } = useSWR<ScriptModel, any, any>(
    { story, flavor },
    fetcher
  );

  if (model === undefined && !error) {
    return <FullScreenLoader />;
  }

  if (error) {
    return <div>{String(error)}</div>;
  }

  if (!model.story) {
    return <NotFoundScreen reportError={false} />;
  }

  const canAccess =
    model.story.trial || flavor === ScriptFlavor.BASIC
      ? true
      : userManager?.accountData?.canAccessAllStoryScripts;

  if (!canAccess) {
    // ui should prevent attempt
    return <NotFoundScreen />;
  }

  return <CaliStoryScript model={model} />;
};
