import { ModelTreeNode } from 'ts-state-tree/tst-core';

export const enum VideoGuideEngagementStatus {
  // eslint-disable-next-line no-unused-vars
  UNPLAYED = 'UNPLAYED',
  // eslint-disable-next-line no-unused-vars
  SKIPPED = 'SKIPPED',
  // eslint-disable-next-line no-unused-vars
  PLAYED = 'PLAYED',
}

// const orderedStatus = [
//   VideoGuideEngagementStatus.SKIPPED,
//   VideoGuideEngagementStatus.PLAYED,
// ];

// const statusOrdinal = (status: SoundbiteEngagementStatus): number => {
//   return orderedStatus.indexOf(status);
// };

// export const maxStatus = (
//   a: SoundbiteEngagementStatus,
//   b: SoundbiteEngagementStatus
// ) => {
//   const aOrdinal = statusOrdinal(a);
//   const bOrdinal = statusOrdinal(b);

//   return bOrdinal > aOrdinal ? b : a;
// };

export class VideoGuideEngagement extends ModelTreeNode {
  static CLASS_NAME = 'VideoGuideEngagement' as const;

  static create(snapshot: any) {
    return super.create(VideoGuideEngagement, snapshot) as VideoGuideEngagement;
  }

  slug: string;
  status: VideoGuideEngagementStatus;
  timestamp: string; // local timestamp - iso8601 date time; last time status progressed

  // in the loose sense of "played or skipped"
  get completed(): boolean {
    // return !!this.status;
    return (
      this.status === VideoGuideEngagementStatus.SKIPPED ||
      this.status === VideoGuideEngagementStatus.PLAYED
    );
  }
}
