import * as React from 'react';

export function CheckmarkHeavyIcon({
  width = 24,
  height = 24,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.3038 3L22 4.88789L10.9873 20.6157L2 14.3227L3.88789 11.6265L10.179 16.0316L19.3038 3Z"
        fill={color}
      />
    </svg>
  );
}

export function CheckmarkHeavySmallIcon({
  width = 20,
  height = 20,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.0863 2.5L18.3332 4.07324L9.15592 17.1797L1.6665 11.9356L3.23975 9.68875L8.48234 13.3597L16.0863 2.5Z"
        fill={color}
      />
    </svg>
  );
}
