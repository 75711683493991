import * as React from 'react';
import { Button } from '@naan/primitives/button';
import { styled } from '@naan/stitches.config';
import { ArrowDownExtraSmallIcon } from '@naan/icons/arrow-down-icon';
import { scrollTo } from 'soundbite/fx/scrolling';
import { useReaction } from '@common/hooks/use-reaction';
import { soundbitePlayerUiModel } from 'soundbite/fx/soundbite-player-ui-model';
import { toggleClassname } from '@utils/toggle-class';
import { AppFactory } from '@app/app-factory';
import { AnswerState } from 'soundbite/models/soundbite-model';
import { elementIds } from 'components/dom-utils/element-ids';
import { getElementById } from 'components/dom-utils';
import __ from '@core/lib/localization';

const Wrapper = styled('div', {
  position: 'fixed',
  bottom: 0,
  left: 0,
  // background: 'red',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
  paddingBottom: 'calc(16px + var(--sab) )',
  '& > .inner': {
    transition: 'transform 0.3s ease-in-out',
    transform: 'translate(0, -80px)', // height of SB player controls
  },
  '&.hidden': {
    '& > .inner': {
      transform: 'translate(0, 0)',
    },
  },
  // zIndex: 100,
});

export function scrollToAnswer() {
  requestAnimationFrame(() => {
    const translationTip = getElementById(elementIds.INLINE_TRANSLATION_TIP);
    if (translationTip) {
      const MAGIC_OFFSET_TO_SHOW_LAST_SENTENCE = 56 + 64; //64 is for the sticky bar
      scrollTo(translationTip, MAGIC_OFFSET_TO_SHOW_LAST_SENTENCE);
    } else {
      const element = getElementById(elementIds.END_AREA);
      if (element) {
        scrollTo(element);
      }
    }
  });
}

export const ScrollToAnswerButton = React.memo(() => {
  const { answerState: state } = AppFactory.soundbiteModel;

  const ref = React.useRef();
  useReaction(
    () => soundbitePlayerUiModel.showScrollToAnswerButton,
    show => {
      if (ref.current) {
        toggleClassname(ref.current, 'hidden', show === false);
      }
    }
  );

  if (state !== AnswerState.revealed) {
    return null;
  }

  return (
    <Wrapper ref={ref}>
      <div className="inner">
        <Button
          presentation="green"
          label={__('Scroll to answer', 'scrollToAnswer')}
          leftIcon={<ArrowDownExtraSmallIcon />}
          onClick={() => {
            scrollToAnswer();
          }}
        />
      </div>
    </Wrapper>
  );
});
