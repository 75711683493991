import Dayjs from 'dayjs';
import { ModelTreeNode, getParentOfType } from 'ts-state-tree/tst-core';
import { StoryManager } from './story-manager';
import { Story } from './story';
import { Soundbite } from './soundbite';
import { weeksSinceEpoch } from '@utils/date-utils';

export class SoundbiteEdition extends ModelTreeNode {
  static CLASS_NAME = 'SoundbiteEdition' as const;

  static create(snapshot: any) {
    return super.create(SoundbiteEdition, snapshot) as SoundbiteEdition;
  }

  slug: string; // primary key
  volumeSlug: string;
  calendarSummary: string;
  calendarSoundbiteSlugs: string[];
  auxilarySoundbiteSlugs: string[];
  visibilityWeekPostEpoch: string | number; // @jason, FYI, current firebase data is dirty. should coerse during ingestion

  // get root(): Root {
  //   return getBaseRoot(this);
  // }

  get visibilityWeekPostEpochNumber(): number {
    return Number(this.visibilityWeekPostEpoch) || 0;
  }

  get currentWeek(): boolean {
    const visibilityDate = this.visibilityDayjs?.toDate();
    return (
      visibilityDate &&
      weeksSinceEpoch(visibilityDate) ===
        weeksSinceEpoch(this.storyManager.today.toDate())
    );
  }

  get storyManager(): StoryManager {
    const result = getParentOfType(this, StoryManager);
    return result;
  }

  get story(): Story {
    return this.storyManager.story(this.volumeSlug);
  }

  get visible(): boolean {
    return this.storyManager.isTodaySameOrAfter(this.visibilityDayjs);
  }

  get visibilityDayjs(): Dayjs.Dayjs {
    if (
      this.visibilityWeekPostEpoch === undefined ||
      this.visibilityWeekPostEpoch === null
    ) {
      return null;
    }
    return this.storyManager.soundbiteEpoch.add(
      this.visibilityWeekPostEpochNumber,
      'week'
    );
  }

  // resolve the date that the given soundbite is featured
  // as the 'daily' soundbite
  featuredDayjs(soundbite: Soundbite): Dayjs.Dayjs {
    const ordinal = this.calendarSoundbiteSlugs.indexOf(soundbite?.slug);
    if (ordinal >= 0) {
      return this.visibilityDayjs.add(ordinal, 'day');
    } else {
      return null;
    }
  }

  get calendarSoundbites(): Soundbite[] {
    const storyManager = this.storyManager;
    const result = this.calendarSoundbiteSlugs.map(slug =>
      storyManager.soundbite(slug)
    );
    return result;
  }

  get allSlugs(): string[] {
    // todo: sort by story position
    return [...this.calendarSoundbiteSlugs, ...this.auxilarySoundbiteSlugs];
  }

  get allSoundbites(): Soundbite[] {
    const storyManager = this.storyManager;
    const result = this.allSlugs.map(slug => storyManager.soundbite(slug));
    return result;
  }

  get allSoundbitesCount(): number {
    return this.allSoundbites.length;
  }

  hasSoundbite(soundbite: Soundbite) {
    return this.allSlugs.includes(soundbite?.slug);
  }
}
