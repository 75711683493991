import { styled } from '@naan/stitches.config';

export const BookmarkIconStyles = styled('div', {
  color: '$$color',
  '&.not-added': {
    $$color: '$colors$gray-400',
  },
  '&.just-added': {
    $$color: '$colors$purple-500',
  },
  '&.got-it': {
    $$color: '$colors$green-500',
  },
  '&.not-got-it': {
    $$color: '$colors$purple-500',
  },
  '&.pending': {
    $$color: '$colors$purple-500',
  },
  '&.previously-learned': {
    $$color: '$colors$green-500',
  },
});
