import { styled } from 'naan/stitches.config';
import { makeQuerySizeVariants, makeSizeVariants } from './size-variants';

const sizeVariants = makeSizeVariants('$$stackGap');

export const VSpacer = styled('div', {
  height: '$$stackGap',
  variants: {
    size: {
      ...sizeVariants,
    },
    mediumSize: {
      ...makeQuerySizeVariants('$$stackGap', '@medium'),
    },
  },
});
