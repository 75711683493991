import * as React from 'react';
import * as Dialog from 'naan/primitives/modals/dialog';
import { useNavigate } from 'react-router-dom';
import { ArrowRightIcon } from 'naan/icons/arrow-right-icon';
import { HSpacer, VSpacer } from 'naan/primitives/spacer';
import { Button, IconButton } from 'naan/primitives/button';
import { Divider } from 'naan/primitives/divider';
import { PlusSmallIcon } from 'naan/icons/plus-icon';
import { observer } from 'mobx-react';
import { VStack } from 'naan/primitives/stack';
import { Link, Text } from 'naan/primitives/text';
import { styled } from 'naan/stitches.config';
import { Classroom } from 'core/models/user-manager';
import { assignmentNavPathByClassroomAssignmentSlug } from 'app/app-helpers';
import { AppFactory } from '@app/app-factory';

import __ from 'core/lib/localization';

type ClassSelectorModalProps = {
  selectedUnit: string;
  onSelectClass: (classId: string) => void;
  onCreateClass: () => void;
  onClose: () => void;
};

type ClassroomSelectorItemProps = {
  selectedUnit: string;
  classroom: Classroom;
  onSelectClass: (classId: string) => void;
};

const ModalBody = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: 'min(480px, calc(100vw - 72px))',
});

const TextControl = styled('div', {
  textStyle: 'small-text',
  color: '$textSecondary',
  '& > button': {
    all: 'unset',
    color: 'inherit',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
});

const ClassRow = styled('div', {
  display: 'flex',
  alignItems: 'center',
  paddingTop: '$space$4',
  paddingBottom: '$space$4',
  cursor: 'pointer',
  '&:hover .label': {
    color: '$colors$teal-500',
  },
  '&:hover button .inner': {
    background: '$colors$gray-100',
  },
});

const ClassSelectorItem = observer(
  ({ classroom, onSelectClass, selectedUnit }: ClassroomSelectorItemProps) => {
    const classHasBeenAssigned = classroom.hasAssignment(selectedUnit);
    const navigate = useNavigate();
    const handleRowClick = () => {
      if (classHasBeenAssigned) {
        navigate(
          assignmentNavPathByClassroomAssignmentSlug(classroom, selectedUnit)
        );
      } else {
        onSelectClass(classroom.id);
      }
    };
    return (
      <>
        <ClassRow onClick={handleRowClick} role="button" tabIndex={0}>
          <VStack>
            <div className="label">{classroom.label}</div>
            {/* TODO: factor this up with class-detail-modal */}
            <Text textStyle={'small-text'} color={'textSecondary'}>
              {__(
                {
                  one: '%{count} assignment',
                  other: '%{count} assignments',
                },
                'assignmentsCount',
                {
                  count: classroom.assignmentCount,
                }
              )}
              {', '}
              {__(
                {
                  one: '%{count} student',
                  other: '%{count} students',
                },
                'studentsCount',
                {
                  count: classroom.studentCount,
                }
              )}
            </Text>
          </VStack>
          <HSpacer expand />
          {classHasBeenAssigned ? (
            <Link>{__('Assigned', 'assigned')}</Link>
          ) : (
            <IconButton
              icon={<ArrowRightIcon />}
              presentation="grayLight"
              onClick={() => onSelectClass(classroom.id)}
              testId={`assign-class-${classroom.id}`}
            />
          )}
        </ClassRow>
        <Divider />
      </>
    );
  }
);

const ClassSelectorZeroState = () => {
  return (
    <>
      <VSpacer size={4} />
      <Text>
        {__(
          "You'll need to create a class to assign this story. You'll then be able to invite students and view their progress.",
          'youllNeedToCreateAClass'
        )}
      </Text>
    </>
  );
};

const ClassSelectorBody = observer(
  ({
    selectedUnit,
    onSelectClass,
    onCreateClass,
    onClose,
  }: ClassSelectorModalProps) => {
    const { managedL2Classrooms } = AppFactory.root.userManager.accountData;

    if (!managedL2Classrooms?.length) {
      return (
        <Dialog.Container
          open={true}
          onDismiss={onClose}
          css={{ $$maxWidth: 'auto' }}
        >
          <Dialog.CloseButton />
          <Dialog.Heading>
            {__('Assign to class', 'assignToClass')}
          </Dialog.Heading>
          <Dialog.Body>
            <ModalBody>
              <ClassSelectorZeroState />
              <VSpacer size={4} />
              <Button
                leftIcon={<PlusSmallIcon />}
                label={__('Add class', 'addClass')}
                presentation="secondary"
                onClick={onCreateClass}
              />
            </ModalBody>
          </Dialog.Body>
        </Dialog.Container>
      );
    }

    return (
      <Dialog.Container
        open={true}
        onDismiss={onClose}
        css={{ $$maxWidth: '480px' }}
      >
        <Dialog.CloseButton />
        <Dialog.Heading>
          {__('Assign to class', 'assignToClass')}
        </Dialog.Heading>
        <Dialog.Body>
          {managedL2Classrooms.map(classroom => (
            <ClassSelectorItem
              key={classroom.id}
              classroom={classroom}
              onSelectClass={onSelectClass}
              selectedUnit={selectedUnit}
            />
          ))}
          <VSpacer size={4} />
          <TextControl>
            {__('Class not shown?', 'classNotShown')}{' '}
            <button onClick={onCreateClass}>
              {__('Add a new class', 'addANewClass')}
            </button>
          </TextControl>
        </Dialog.Body>
      </Dialog.Container>
    );
  }
);

export const ClassSelectorModal = observer(
  ({
    selectedUnit,
    onSelectClass,
    onCreateClass,
    onClose,
  }: ClassSelectorModalProps) => {
    return (
      <ClassSelectorBody
        onClose={onClose}
        selectedUnit={selectedUnit}
        onSelectClass={onSelectClass}
        onCreateClass={onCreateClass}
      />
    );
  }
);
