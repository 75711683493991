import * as React from 'react';
// import { observer } from 'mobx-react';
import { getVideoAssets } from 'video-assets/study-mode';
import { VideoInterstitial } from './component/video-interstitial';
import __ from '@core/lib/localization';

export const StudyInterstitial = ({
  onForcePass,
  onDismiss,
}: {
  onForcePass: () => void;
  onDismiss: () => void;
}) => {
  return (
    <VideoInterstitial
      title={__('Study', 'study')}
      onForcePass={onForcePass}
      onDismiss={onDismiss}
      getVideoAssets={getVideoAssets}
      videoAchievementKey="video:study-mode"
    />
  );
};

// import * as React from 'react';
// // import { observer } from 'mobx-react';
// import { namedObserver } from 'lib/named-observer';
// import { Story } from '@core/models/story-manager/story';
// import { ChapterActionButton } from 'story/chapter-list-item/chapter-item-contents/chapter-action-button';
// import { track } from '@app/track';
// import { SteppedInterstitialLayout } from './component/stepped-interstitial-layout';
// import { ChapterRowUiProvider } from 'story/chapter-list-item/chapter-item-contents/chapter-row-ui-model';

// import __ from '@core/lib/localization';

// export const StudyInterstitial = namedObserver(
//   'StudyInterstitial',
//   ({ story }: { story: Story }) => {
//     // const navigateToStudy = useNavigateToStudy();

//     // const handleContinue = () => {
//     //   const chapter = story.firstChapter;
//     //   const { currentMillis: ms /*, midListen*/ } = chapter;
//     //   const viaDetail = false;
//     //   navigateToStudy(chapter, PlayerMode.STUDY, ms, viaDetail);
//     // };

//     const chapter = story.firstChapter;

//     React.useEffect(() => {
//       track('onboarding__interstitial_shown', { stage: 'study' });
//     }, []);

//     return (
//       <SteppedInterstitialLayout
//         story={story}
//         stage={1}
//         title={__('Study', 'study')}
//         copy={__(
//           "Now you'll study Chapter 1 in full. We’ll help you along your way with vocab and translations.",
//           'studyInterstitialCopy'
//           // "Now you'll play Chapter 1 in the Study Player. If you get stuck on a sentence, tap pause to get help with vocabulary.",
//           // 'nowYoullPlayChapter1InTheStudyPlayer'
//         )}
//         renderButton={() => (
//           <ChapterRowUiProvider chapter={chapter}>
//             <ChapterActionButton
//               chapter={chapter}
//               viaDetail={false}
//               presentation="teal"
//             />
//           </ChapterRowUiProvider>
//         )}
//       />
//     );
//   }
// );
