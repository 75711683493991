import * as React from 'react';

export const PlayVideoIcon = () => (
  <svg
    width={96}
    height={96}
    viewBox="0 0 96 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width={96} height={96} rx={48} fill="var(--play-video-button-bg)" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M68.224 49.3384L40.879 67.2491C40.1398 67.7333 39.1481 67.5266 38.6639 66.7874C38.4932 66.5268 38.4023 66.2221 38.4023 65.9107V30.0891C38.4023 29.2055 39.1187 28.4891 40.0023 28.4891C40.3138 28.4891 40.6185 28.58 40.879 28.7507L68.224 46.6615C68.9632 47.1456 69.17 48.1374 68.6858 48.8766C68.5653 49.0606 68.4081 49.2178 68.224 49.3384Z"
      fill="white"
    />
  </svg>
);
