import { pickLocalizedValue } from '@core/lib/localization';
import { AppFactory } from '@app/app-factory';

const poster = 'natural-listening-poster.svg';

const shortVideoId = '02da09a020b0c7e6a5f7c5503cbbd7c4';

const en = {
  videoId: '580bc7c99c9cacdc17f1d4d1e72cd75d',
  poster,
};

const pt = {
  videoId: 'd5efb7ca1ba1eeae39e7e16f47f04f07',
  poster,
};

const es = {
  videoId: 'fdda0c7936ae71b585e11b79f5eb7f43',
  poster,
};

export function getVideoAssets() {
  const result = pickLocalizedValue({ en, pt, es });
  const { shortVideosEnabled } = AppFactory.root.localState;
  if (shortVideosEnabled) {
    result.videoId = shortVideoId;
  }
  return result;
}
