import * as React from 'react';
import { AppFactory } from '@app/app-factory';
import { CenterColumnLayout } from 'components/layouts/center-column-layout';
import { PageHeading } from 'components/page-heading';
import { HelpBox } from 'components/support/help-box';
import { MediumGap } from '@naan/primitives/spacer/gaps';
import { VideoGuidesList } from 'components/support/video-guides-list';
import { VSpacer } from '@naan/primitives/spacer';
import { useScrollContainerToTop } from 'lib/scroll-utils';
import { styled } from '@naan/stitches.config';

import __ from '@core/lib/localization';
import { FeedbackBox } from 'components/support/feedback-box';
import { SectionHeading } from 'components/learn/dashboard/dashboard-section-heading';

const Wrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
  '& > *': {
    flex: 1,
  },

  '@medium': {
    gap: 24,
    flexDirection: 'row',
  },
});

export const SupportScreen = () => {
  const { l2 } = AppFactory.root;
  useScrollContainerToTop();
  const showHelplets = l2 === 'es';

  return (
    <CenterColumnLayout>
      <PageHeading title={__('Support and feedback', 'supportAndFeedback')} />
      <Wrapper>
        <HelpBox />
        <FeedbackBox />
      </Wrapper>
      <MediumGap />
      <VSpacer size={5} />
      {showHelplets ? (
        <>
          <SectionHeading
            title={__('Jiveworld essentials', 'jiveworldEssentials')}
          />
          <VideoGuidesList />
        </>
      ) : null}
      <MediumGap />
    </CenterColumnLayout>
  );
};
