import { styled } from 'naan/stitches.config';

/// this is just a very simple wrapper
/// for things that should stick to the top of the layout
export const HeaderContainer = styled('div', {
  position: 'sticky',
  top: -1,
  zIndex: 2,
  background: '$colors$white',
});

HeaderContainer.displayName = 'HeaderContainer';
