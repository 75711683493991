import React from 'react';
import { observer } from 'mobx-react';
import { ClientNotation } from '@tikka/client/client-types';
import {
  ChapterReviewModel,
  NotationStatus,
} from 'vocab-review/chapter-review-model';
import { IconButton } from '@naan/primitives/button/icon-button';
import { usePlayerModel } from 'player/views/player-model-context';
import { BookmarkIconMap } from './bookmark-icon-map';
import { BookmarkIconStyles } from './bookmark-icon-styles';

const BookmarkButton = observer(
  ({
    onClick,
    notation,
    notationStatus,
  }: Pick<React.ComponentProps<typeof IconButton>, 'onClick'> & {
    notation: ClientNotation;
    notationStatus: NotationStatus;
  }) => {
    return (
      <IconButton
        presentation={'grayTransparent'}
        size={'large'}
        onClick={onClick}
        icon={
          <BookmarkIconStyles className={notationStatus}>
            {BookmarkIconMap[notationStatus]}
          </BookmarkIconStyles>
        }
        testId={`bookmark-button-${notation.id}`}
      />
    );
  }
);

export const ItemActionButton = observer(
  ({
    notation,
    status,
  }: {
    notation: ClientNotation;
    status: NotationStatus;
  }) => {
    const model = usePlayerModel() as ChapterReviewModel;

    const handleClick = React.useCallback(
      (notationId: ClientNotation['id']) => {
        const notationStatus = model.vocabStatus(notationId);

        switch (notationStatus) {
          case 'previously-learned':
          case 'not-added': {
            return model.markJustAdded(notationId);
          }

          case 'just-added': {
            return model.undoJustAdded(notationId);
          }
        }
      },
      [model]
    );

    return (
      <BookmarkButton
        onClick={() => handleClick(notation.id)}
        notation={notation}
        notationStatus={status}
      />
    );
  }
);
