import React from 'react';
import { styled } from 'naan/stitches.config';
import { observer } from 'mobx-react';
import { Link as RouterLink } from 'react-router-dom';
import { HSpacer } from 'naan/primitives/spacer';
import { ArrowLeftSmallIcon } from 'naan/icons/arrow-left-icon';
import { Text } from 'naan/primitives/text';

const BackButton = styled(RouterLink, {
  textDecoration: 'none',
  marginRight: 'auto',
  textStyle: 'small-heading',
  display: 'flex',
  alignItems: 'center',
  color: '$gray-800',
});

/// For when the spec says stuff like: have it be sticky,
/// but also have it have a border which isn't really a border
/// because it has to be outside of the sticky element.
/// but still, you know,  fit into the layout.
export const ParentScreenLayoutWrapper = styled('div', {
  position: 'sticky',
  top: 0,
  background: '$white',
  zIndex: 2,
});

const Wrapper = styled('div', {
  display: 'flex',
  py: '$space$5',
  position: 'relative',
  background: '$white',
});

const BottomRule = styled('div', {
  position: 'absolute',
  bottom: -1,
  left: 0,
  right: 0,
  borderBottom: '1px solid $colors$black-alpha-10',
});

export const ParentScreenLink = observer(
  ({ to, title }: { to: string; title: string }) => {
    return (
      <Wrapper>
        <BackButton to={to}>
          <ArrowLeftSmallIcon />
          <HSpacer size={2} />
          <Text textStyle="small-heading">{title}</Text>
        </BackButton>
        <BottomRule />
      </Wrapper>
    );
  }
);
