import * as React from 'react';

import { observer } from 'mobx-react';
import { useLocation, useParams } from 'react-router-dom';
import { AppFactory } from '@app/app-factory';
import { CollectionDetail } from 'components/learn/collections/collection-detail';
import { DeepScrollRestoration } from 'lib/scroll-utils/deep-scroll-restoration';

export const CollectionDetailScreen = observer(() => {
  const { storyManager } = AppFactory.root;
  const { slug } = useParams<{ slug: string }>();
  const collection = storyManager.collection(slug);
  const location = useLocation();

  return (
    <>
      <DeepScrollRestoration pageKey={location.pathname} />
      <CollectionDetail collection={collection} />
    </>
  );
});
