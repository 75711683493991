import * as React from 'react';
import { pick } from './pick';

type TLargePresentation = 'color' | 'grayscale';

export const PatternsIconLarge = ({
  presentation = 'color',
}: {
  presentation?: TLargePresentation;
}) => {
  const color1 = pick(presentation, {
    color: '#FF534F',
    grayscale: '#999',
  });

  const color2 = pick(presentation, {
    color: '#FF9A9A',
    grayscale: '#ccc',
  });

  return (
    <svg
      width={72}
      height={72}
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="14.1429" cy="14.1429" r="6.42857" fill={color1} />
      <circle cx="14.1429" cy="36.0001" r="6.42857" fill={color2} />
      <circle cx="14.1429" cy="57.857" r="6.42857" fill={color2} />
      <circle cx="57.8572" cy="14.1429" r="6.42857" fill={color2} />
      <circle cx="57.8572" cy="36.0001" r="6.42857" fill={color2} />
      <circle cx="57.8572" cy="57.857" r="6.42857" fill={color1} />
      <circle cx="36" cy="14.1429" r="6.42857" fill={color1} />
      <circle cx="36" cy="37.2858" r="6.42857" fill={color1} />
      <circle cx="36" cy="57.857" r="6.42857" fill={color1} />
      <rect
        x="14.1429"
        y="12.8572"
        width="21.8571"
        height="2.57143"
        fill={color1}
      />
      <rect x="36" y="56.5715" width="21.8571" height="2.57143" fill={color1} />
      <rect
        x="34.7143"
        y="57.8572"
        width="43.7143"
        height="2.57143"
        transform="rotate(-90 34.7143 57.8572)"
        fill={color1}
      />
    </svg>
  );
};

export const PatternsIconSmall = ({
  presentation = 'color',
}: {
  presentation?: 'color' | 'grayscale' | 'dark';
}) => {
  const color1 = pick(presentation, {
    color: '#FF534F',
    grayscale: '#999',
    dark: '#fff',
  });

  const color2 = pick(presentation, {
    color: '#FF9A9A',
    grayscale: '#ccc',
    dark: '#ffffff50',
  });

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="4.7143" cy="4.71439" r="2.14286" fill={color1} />
      <circle cx="4.7143" cy={12} r="2.14286" fill={color2} />
      <circle cx="4.7143" cy="19.2857" r="2.14286" fill={color2} />
      <circle cx="19.2857" cy="4.71439" r="2.14286" fill={color2} />
      <circle cx="19.2857" cy={12} r="2.14286" fill={color2} />
      <circle cx="19.2857" cy="19.2857" r="2.14286" fill={color1} />
      <circle cx={12} cy="4.71439" r="2.14286" fill={color1} />
      <circle cx={12} cy="12.4285" r="2.14286" fill={color1} />
      <circle cx={12} cy="19.2857" r="2.14286" fill={color1} />
      <rect
        x="4.71429"
        y="4.28564"
        width="7.28571"
        height="0.857143"
        fill={color1}
      />
      <rect
        x={12}
        y="18.8572"
        width="7.28571"
        height="0.857143"
        fill={color1}
      />
      <rect
        x="11.5714"
        y="19.2856"
        width="14.5714"
        height="0.857143"
        transform="rotate(-90 11.5714 19.2856)"
        fill={color1}
      />
    </svg>
  );
};
