import * as React from 'react';

export function PlusLargeIcon({
  width = 28,
  height = 28,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 28 28"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 2H13V13H2V15H13V26H15V15H26V13H15V2Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}

export function PlusSmallIcon({
  width = 20,
  height = 20,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 2H9V9H2V11H9V18H11V11H18V9H11V2Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}

export function PlusExtraSmallIcon({
  width = 16,
  height = 16,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 2H7V7H2V9H7V14H9V9H14V7H9V2Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}

export function PlusIcon({ width = 24, height = 24, color = 'currentColor' }) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 2H11V11H2V13H11V22H13V13H22V11H13V2Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}
