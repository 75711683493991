import * as React from 'react';

export function SignOutIcon({
  width = 24,
  height = 24,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 2C5.34315 2 4 3.34315 4 5V19C4 20.6569 5.34315 22 7 22H17C18.6569 22 20 20.6569 20 19V17H18V19C18 19.5523 17.5523 20 17 20H7C6.44772 20 6 19.5523 6 19V5C6 4.44772 6.44772 4 7 4H17C17.5523 4 18 4.44772 18 5V7H20V5C20 3.34315 18.6569 2 17 2H7ZM18.7929 9.70711L20.0858 11H12V13H20.0858L18.7929 14.2929L20.2071 15.7071L23.2071 12.7071L23.9142 12L23.2071 11.2929L20.2071 8.29289L18.7929 9.70711Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}

export function SignOutExtraSmallIcon({
  width = 16,
  height = 16,
  color = 'currentColor',
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 2C3.11929 2 2 3.11929 2 4.5V11.5C2 12.8807 3.11929 14 4.5 14H11C12.1046 14 13 13.1046 13 12H12C12 12.5523 11.5523 13 11 13H4.5C3.67157 13 3 12.3284 3 11.5V4.5C3 3.67157 3.67157 3 4.5 3H11C11.5523 3 12 3.44772 12 4H13C13 2.89543 12.1046 2 11 2H4.5ZM11.6464 5.95355L13.1929 7.5H7V8.5H13.1929L11.6464 10.0464L12.3535 10.7536L14.7535 8.35355L15.1071 8L14.7535 7.64645L12.3535 5.24645L11.6464 5.95355Z"
        fill={color}
      />
    </svg>
  );
}
