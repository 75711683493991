import { BrowserWakeLockManager } from './browser-wakelock';
import { bugsnagNotify } from '@app/notification-service';
import { embeddedMode } from '../app-util';
import { createLogger } from '@common/log';
import { EmbeddedWakeLockManager } from './embedded-wakelock';

const log = createLogger('wake-lock');

// interface NoSleepHandler {
//   enable: () => void;
//   disable: () => void;
// }

type NoSleepHandler = BrowserWakeLockManager | EmbeddedWakeLockManager;

// Can be enabled for the study player and left disabled for the soundbite player
// Allows the low-level audio transport to signal play/pauses and only honor for the study player
// expected to be used as a singleton
export class WakeLock {
  // screen level state
  handlerEnabled: boolean = false;
  handler: NoSleepHandler = null;

  constructor() {
    this.enableHandler();
    this.handler = createWakeLockManager();
  }

  enableHandler(): void {
    this.handlerEnabled = true;
  }

  disableHandler(): void {
    this.deactivate(); // just in case not paused before player exited
    this.handlerEnabled = false;
  }

  // transport level controls - only honored when enabled at screen level

  // nosleep.js enable() will only work when triggered in response to an input event handler
  // but that seems to be naturally the case for our 'play' actions
  activate(): void {
    if (this.handlerEnabled) {
      try {
        void this.handler.enable();
      } catch (error) {
        // an 'The requesting page is not visible' error was seen here on windows, but not sure how to reproduce
        // https://app.bugsnag.com/jiveworld/cali-spa-beta/errors/641f44571ee4e40009c67388?filters%5Bevent.since%5D=30d&filters%5Berror.status%5D=open
        log.warn(`ignoring noSleepHandler.enable() error: ${error}`);
        bugsnagNotify(error as Error);
      }
    }
  }

  deactivate(): void {
    if (this.handlerEnabled) {
      try {
        void this.handler.disable();
      } catch (error) {
        log.warn(`ignoring noSleepHandler.disable() error: ${error}`);
        bugsnagNotify(error as Error);
      }
    }
  }

  get active(): boolean {
    return this.handler?.active ?? false;
  }

  get type(): string {
    return this.handler?.type ?? 'none';
  }
}

const createWakeLockManager = () => {
  if (embeddedMode()) {
    return new EmbeddedWakeLockManager();
  } else {
    return new BrowserWakeLockManager();
  }
};
