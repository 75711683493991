import { ElementId, ElementKind, IDTOfKT, KTOfIDT } from '../basic-types';

export function isWordId(id: string) {
  return id && id.length > 0 && id.indexOf(':') === -1;
  // return !isNaN(id as number); // attempt to coerce back to a number and check the result
}

export function getKindFromId<IDT extends ElementId>(id: IDT): KTOfIDT<IDT> {
  if (isWordId(id)) {
    return 'WORD' as any;
  } else {
    let stringId = id as string;
    return stringId.slice(0, stringId.indexOf(':')) as any;
  }
}

export function isKindOfId<IDT extends ElementId, KT extends string>(
  id: IDT,
  kind: KT
): id is IDT & `${KT}:${string}` {
  if (kind === 'WORD') {
    return isWordId(id as ElementId);
  }
  return (id as string).startsWith(kind);
}

export function elementIdToDomId(domScope: string, id: ElementId): string {
  domScope = domScope ?? '';
  if (!isWordId(id)) {
    return domScope + '_' + id;
  } else {
    return domScope + '_WORD:' + id;
  }
}

export function domIdToElementId(domScope: string, domId: string): ElementId {
  // TODO or check startsWith domScope??
  const underScoreIndex = domId.indexOf('_');
  if (underScoreIndex === -1) {
    // TODO or throw?
    return '' as any;
  }
  domId = domId.slice(underScoreIndex + 1);
  if (domId.startsWith('WORD:')) {
    const wordIdStr: string = domId.slice(domId.indexOf(':') + 1);
    return wordIdStr as any;
  } else {
    return domId as any;
  }
}

export function getTranslationId(
  id: ElementId,
  locale: string
): IDTOfKT<'TRANSLATION'> {
  return `TRANSLATION:${id}:${locale}`;
}

export const ID_SEPARATOR = '.';

export function getGlobalExcerptId(unitId: string, elementId: ElementId) {
  return unitId + ID_SEPARATOR + elementId;
}

export function hasIdSepartor(path: string) {
  return path && path.includes(ID_SEPARATOR);
}

export function idParts(path: string): [ElementKind, ElementId] {
  return path.split(ID_SEPARATOR) as [ElementKind, ElementId];
}
