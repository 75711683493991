import * as React from 'react';
import { darkThemeSelector, styled } from '@naan/stitches.config';
import { observer } from 'mobx-react';
import { AppFactory } from 'app/app-factory';
import { CheckmarkCircleIcon } from '@naan/icons/checkmark-circle-icon';
import { StreakIcon } from '@naan/icons/streak-icon';
import { PointsDisplay } from './points-display';

import { VSpacer } from '@naan/primitives/spacer';
import { daysCount } from '@core/lib/localization-helpers';

import __ from '@core/lib/localization';

export const PointsCardWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '$yellow-200',
  [darkThemeSelector]: {
    backgroundColor: '$yellow-800',
  },
  padding: '0 16px',
  borderRadius: '$radii$large',
  '@widerThanScript': {
    borderRadius: '$radii$medium',
  },
  '& > .row': {
    display: 'flex',
    alignItems: 'center',
    color: '$globalBlack-alpha-80',
    // justifyContent: 'space-between',
    gap: 4,
    padding: '20px 0',
    textStyle: 'small-heading',
    '&:not(:last-child)': {
      borderBottom: '1px solid $globalBlack-alpha-06',
    },

    '& > .title': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      gap: 8,
    },
    '& > .points': {
      marginLeft: 'auto',
    },
    '& > .icon': {
      '&.streak': {
        color: '$colors$orange-500',
      },
    },
    '&.total': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 16,
      padding: '16px 0',
    },
  },
});

export const StreakRow = observer(() => {
  const { userData } = AppFactory.root.userManager;
  const { currentStreak, streakShowState } = userData;
  const displayStreak = streakShowState === 'SHOW_INLINE';

  if (!displayStreak) {
    return null;
  }

  return (
    <>
      <div className="row">
        <span className="streak icon">
          <StreakIcon />
        </span>
        <span className="title">{__('Daily streak', 'dailyStreak')}</span>
        <span className="points">{daysCount(currentStreak)}</span>
      </div>
    </>
  );
});

export const PointsRow = observer(
  ({
    icon,
    title,
    points,
  }: {
    icon?: React.ReactNode;
    title: string;
    points: number;
  }) => {
    return (
      <div className="row">
        {icon ? <span className="icon">{icon}</span> : null}
        <span className="title">{title}</span>
        <span className="points">
          <PointsDisplay points={points} size="large" />
        </span>
      </div>
    );
  }
);

export const SummaryRow = observer(
  ({
    todayPoints,
    sevenDayPoints,
  }: {
    todayPoints: number;
    sevenDayPoints: number;
  }) => {
    return (
      <div className="row total">
        <PointsDisplay
          points={todayPoints}
          size="small"
          legend={__('today', 'todayLower')}
        />
        <PointsDisplay
          points={sevenDayPoints}
          size="small"
          legend={__('last 7 days', 'lastSevenDays')}
        />
      </div>
    );
  }
);

export const PointsCard = observer(({ points }: { points: number }) => {
  const { userData } = AppFactory.root.userManager;
  const { todayPoints, sevenDayPoints, totalPoints } = userData;

  const showTotalRow = totalPoints > points;

  return (
    <>
      <PointsCardWrapper>
        <StreakRow />

        <div className="row">
          <span className="icon">
            <CheckmarkCircleIcon />
          </span>
          <span className="title">
            {__('Soundbite complete', 'soundbiteComplete')}
          </span>
          <span className="points">
            <PointsDisplay points={points} size="large" />
          </span>
        </div>

        {showTotalRow ? (
          <div className="row total">
            <PointsDisplay
              points={todayPoints}
              size="small"
              legend={__('today', 'todayLower')}
            />
            <PointsDisplay
              points={sevenDayPoints}
              size="small"
              legend={__('last 7 days', 'lastSevenDays')}
            />
          </div>
        ) : null}
      </PointsCardWrapper>
      <VSpacer size={4} />
    </>
  );
});
