import * as React from 'react';

export function InfoCircleSmallIcon({
  width = 20,
  height = 20,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="7.5" stroke={color} />
      <path
        d="M8.57853 14.1361C8.57853 13.7827 8.68325 13.233 8.76178 12.8403L9.42932 9.68585L8.5 9.59422L8.57853 9.09684L11.3665 8.25915L11.5759 8.44239L10.5942 12.9843C10.5288 13.2199 10.5026 13.4031 10.5026 13.5471C10.5026 13.7173 10.5942 13.8089 10.7251 13.8089C10.9476 13.8089 11.2356 13.5471 11.6675 12.9974L12.0602 13.2591C11.5367 14.1492 10.712 15 9.58639 15C8.9712 15 8.57853 14.6989 8.57853 14.1361ZM10.9477 7.04189C10.4504 7.04189 9.99224 6.68848 9.99224 6.11257C9.99224 5.45812 10.5289 5 11.1179 5C11.6676 5 12.0734 5.37958 12.0734 5.91623C12.0734 6.55759 11.5367 7.04189 10.9477 7.04189Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}

export function InfoCircleLargeIcon({
  width = 28,
  height = 28,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 28 28"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25 14C25 20.0751 20.0751 25 14 25C7.92487 25 3 20.0751 3 14C3 7.92487 7.92487 3 14 3C20.0751 3 25 7.92487 25 14ZM26 14C26 20.6274 20.6274 26 14 26C7.37258 26 2 20.6274 2 14C2 7.37258 7.37258 2 14 2C20.6274 2 26 7.37258 26 14ZM11.977 18.0901C11.8639 18.6555 11.7131 19.4471 11.7131 19.956C11.7131 20.7665 12.2785 21.2 13.1644 21.2C14.7853 21.2 15.9728 19.9749 16.7267 18.6932L16.1613 18.3162C15.5393 19.1079 15.1246 19.4848 14.8042 19.4848C14.6157 19.4848 14.4838 19.3529 14.4838 19.1079C14.4838 18.9005 14.5215 18.6367 14.6157 18.2974L16.0293 11.7571L15.7277 11.4932L11.7131 12.6995L11.6 13.4157L12.9382 13.5477L11.977 18.0901ZM13.7488 8.40209C13.7488 9.23141 14.4085 9.74031 15.1247 9.74031C15.9729 9.74031 16.7456 9.04293 16.7456 8.11937C16.7456 7.3466 16.1613 6.8 15.3697 6.8C14.5216 6.8 13.7488 7.45969 13.7488 8.40209Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}

export function InfoCircleIcon({
  width = 24,
  height = 24,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12ZM22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM10.3141 15.4084C10.2199 15.8796 10.0942 16.5393 10.0942 16.9633C10.0942 17.6387 10.5654 18 11.3037 18C12.6544 18 13.644 16.979 14.2722 15.911L13.801 15.5968C13.2827 16.2565 12.9372 16.5707 12.6702 16.5707C12.5131 16.5707 12.4031 16.4607 12.4031 16.2565C12.4031 16.0838 12.4346 15.8639 12.5131 15.5811L13.6911 10.1309L13.4398 9.91098L10.0942 10.9162L10 11.5131L11.1152 11.623L10.3141 15.4084ZM11.7906 7.33508C11.7906 8.02618 12.3404 8.45026 12.9372 8.45026C13.644 8.45026 14.288 7.86911 14.288 7.09948C14.288 6.4555 13.8011 6 13.1414 6C12.4346 6 11.7906 6.54974 11.7906 7.33508Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}
