import React from 'react';
import { NavLink } from 'react-router-dom';
import { appConfig } from 'app/env';
import { devToolsPath } from 'components/nav/path-helpers';
import { alertSevereError, notifySuccess } from '@app/notification-service';
import { createLogger } from '@common/log';
import { AppFactory } from '@app/app-factory';
import { fetchJson } from '@utils/util';

//
// no longer needed
//

const log = createLogger('list-screen');

//
// fetch latest catalog slug to url mappings from the masala servers
// and mirror to the firestore db.
// expected to be triggered from masala after publishing a catalog.
// used to relay catalog updates the end users.
//
// TODO: move this to some sort of server tier
//

const listEndpoints = appConfig.admin.catalogSyncDataUrls;

interface LoaderState {
  status?: string;
  failure?: Error;
}

const syncFromMasalaToFirestore = async (): Promise<string> => {
  log.info(
    `syncFromMasalaToFirestore - endpoints: ${listEndpoints.join(', ')}`
  );
  const { catalogMetaSync } = AppFactory;
  for (const endpoint of listEndpoints) {
    // const response = await fetch(endpoint);
    // const tuples: [string, string][] = await response.json();
    const tuples = await fetchJson<[string, string][]>(endpoint);

    log.info(`fetched data: ${JSON.stringify(tuples)}`);
    for (const [slug, url] of tuples) {
      await catalogMetaSync.firestoreStore(slug, { slug, url });
    }
  }
  return 'success';
};

export const SyncCatalogUrls = () => {
  const [loaderState, setLoaderState] = React.useState<LoaderState>({});
  const { status, failure } = loaderState;
  log.info(`render - status: ${status}, failure: ${failure}`);

  React.useEffect(
    () => {
      if (status === undefined && !failure) {
        syncFromMasalaToFirestore()
          .then(status => setLoaderState({ status }))
          .catch(failure => setLoaderState({ failure }));
      }
      // should only ever run the effect once per page load
    } /*, [status, failure]*/
  );

  if (!status && !failure) {
    return <>loading...</>;
  }

  if (failure) {
    alertSevereError({ error: failure });
  }
  if (status) {
    notifySuccess(status);
  }

  // return <Navigate to={devToolsPath() + '/catalog'} />;

  return (
    <>
      <br />
      {status ? <p>status: {String(status)}</p> : null}
      {failure ? <p>failure: {String(failure)}</p> : null}
      <br />
      <NavLink to={devToolsPath() + '/catalog'}>
        [dev tools - catalog stuff]
      </NavLink>
    </>
  );
};
