// code to be shared between service worker and reload-prompt

export interface BuildInfo {
  forceUpdateScheme: number;
  buildVersion: string;
  buildTimestampIso: string;
  commit: string;
  commitTimestampIso: string;
  hash: string;
  apiEnv: string;
}

// window -> service worker messages
export const SKIP_WAITING_MESSAGE_TYPE = 'SKIP_WAITING';
export const RELOAD_PROMPTED_MESSAGE_TYPE = 'RELOAD_PROMPTED'; // UI presented, tells server to be paient
export const ACTIVE_BUILD_INFO_MESSAGE_TYPE = 'ACTIVE_BUILD_INFO';
export const ENABLE_LOGGING_MESSAGE_TYPE = 'ENABLE_LOGGING';
export const FLUSH_LOGS_MESSAGE_TYPE = 'FLUSH_LOGS';
export const CRASH_TEST_MESSAGE_TYPE = 'CRASH_TEST'; // test out bugsnag reporting

// service worker -> window messages
export const RELAYED_LOGS_MESSAGE_TYPE = 'RELAYED_LOGS';

// incrementing this will tigger a forceful activation and load of new build immediately upon
// page load or update detection
export const expectedScheme = 1;
