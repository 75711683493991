import * as React from 'react';
import { useSwitch2 } from '@naan/hooks/use-switch-2';
import { Input } from './input';
import { IconButton } from '../button';
import { PasswordHiddenIcon } from 'naan/icons/password-hidden-icon';
import { PasswordShownIcon } from 'naan/icons/password-shown-icon';
import { styled } from 'naan/stitches.config';

const Wrapper = styled('div', {
  position: 'relative',
});

const ButtonWrapper = styled('div', {
  position: 'absolute',
  top: 0,
  right: 0,
  height: '100%',
});

type PasswordInputProps = Omit<React.ComponentProps<typeof Input>, 'type'>;

export const PasswordInput = React.forwardRef<
  HTMLInputElement,
  PasswordInputProps
>((props, ref) => {
  const showPasswordSwitch = useSwitch2(false);

  return (
    <Wrapper>
      <Input
        type={showPasswordSwitch.value ? 'text' : 'password'}
        placeholder={'●●●●●●●●'}
        ref={ref}
        {...props}
        css={{ paddingRight: 52 }}
      />
      <ButtonWrapper>
        <IconButton
          type={'button'}
          onClick={showPasswordSwitch.toggle}
          size="large"
          icon={
            showPasswordSwitch.value ? (
              <PasswordHiddenIcon />
            ) : (
              <PasswordShownIcon />
            )
          }
          testId="password-input-show-password-btn"
        />
      </ButtonWrapper>
    </Wrapper>
  );
});
