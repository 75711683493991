// import { prevent } from 'common/prevent';
import { AppFactory } from '@app/app-factory';
import { VSpacer } from '@naan/primitives/spacer';
import { InteractiveTag, TagsContainer } from 'naan/primitives/tags';
import * as React from 'react';
import { AnimatedTagsContainer } from './animated-tags-container';
import { FilterBox } from './filter-box';

export const TopicsFilterPanel = ({ show = false }: { show: boolean }) => {
  const { storyManager } = AppFactory.root;
  const list = storyManager.topicsList;

  return (
    <AnimatedTagsContainer show={show}>
      <VSpacer size="2" />
      <TagsContainer>
        <FilterBox
          filterItems={list}
          filterQueryKey={'topics'}
          renderFilterControl={(item, selected, action) => {
            return (
              <InteractiveTag
                role="button"
                selected={selected}
                onSelect={action}
                type="topic"
              >
                {item}
              </InteractiveTag>
            );
          }}
        />
      </TagsContainer>
      <VSpacer size="2" />
    </AnimatedTagsContainer>
  );
};
