import { styled } from 'naan/stitches.config';
import { Tag } from './tag';

export const LinkTag = styled(Tag, {
  cursor: 'pointer',
  textDecoration: 'none',
  '&:hover': {
    backgroundColor: '$$hoverBackgroundColor',
    borderColor: '$$hoverBorderColor',
    color: '$$hoverColor',
  },
});
