import * as React from 'react';
import { toUnicodePunctuationStr } from '@utils/content-string-utils';
import ReactMarkdown from 'react-markdown';
import { AdaptiveLink } from './custom-components';

export const PlainMarkdown = ({
  source,
  ...props
}: {
  source: string;
} & Omit<React.ComponentProps<typeof ReactMarkdown>, 'children'>) => {
  const preprocessed = toUnicodePunctuationStr(source);
  const passedPropsComponents = props.components;
  return (
    <ReactMarkdown
      children={preprocessed}
      {...props}
      components={{
        ul: ({ node, ...props }) => <ul {...props} data-markdown />,
        ol: ({ node, ...props }) => <ol {...props} data-markdown />,
        ...passedPropsComponents,
      }}
    />
  );
};

export const PlainMarkdownWithLinks = ({
  source,
  ...props
}: {
  source: string;
} & Omit<React.ComponentProps<typeof ReactMarkdown>, 'children'>) => {
  return (
    <PlainMarkdown
      source={source}
      {...props}
      components={{
        a: ({ node, ...props }) => <AdaptiveLink {...props} />,
      }}
    />
  );
};
