import React from 'react';
import { observer } from 'mobx-react';
import { makeFilterLink } from '@common/filtering/make-filter-link';
import { learnStoriesPath } from 'components/nav/path-helpers';
import { AppFactory } from '@app/app-factory';
import { TFilter, filterTypes } from '@common/filtering-core';
import { SectionHeadingLink } from './dashboard-section-heading';
import { Responsive } from '@naan/primitives/responsive';
import { StoryRow } from './story-row';
import { Channel } from '@core/models/story-manager/channel';
import { ChannelSectionHeading } from './dashboard-channel-heading';

import __ from '@core/lib/localization';

export const ChannelWidget = observer(({ channel }: { channel: Channel }) => {
  const { storyManager } = AppFactory.root;
  // const key = 'channelTitles'; // beware, the 'key' here is the mapped key, not the query param
  const value = channel.title;
  // const heading = channel.title;
  const values = [value];
  const filterPath = makeFilterLink(
    learnStoriesPath(),
    // key as TFilterKey,
    'channel', // query param flavor of the key
    values
  );
  const filter: TFilter = {
    queryType: 'channel', // probably not used
    key: 'channelTitles', // mapped object property flavor of the key
    value: values,
    method: filterTypes.INCLUDES_ANY,
  };

  const filteredStories = storyManager.filteredList(filter);

  if (filteredStories.length === 0) {
    return null;
  }

  return (
    <>
      <ChannelSectionHeading
        channel={channel}
        renderLinks={() => (
          <SectionHeadingLink to={filterPath}>
            {__('Show all', 'showAll')}
          </SectionHeadingLink>
        )}
      />

      <Responsive
        renderDefault={() => <StoryRow stories={filteredStories} n={2} />}
        renderSmallAndUp={() => <StoryRow stories={filteredStories} n={3} />}
        renderMediumAndUp={() => <StoryRow stories={filteredStories} n={4} />}
      />
    </>
  );
});
