import * as React from 'react';

export function SearchIcon({
  width = 24,
  height = 24,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 9C14 11.7614 11.7614 14 9 14C6.23858 14 4 11.7614 4 9C4 6.23858 6.23858 4 9 4C11.7614 4 14 6.23858 14 9ZM13.1922 14.6064C12.0236 15.4816 10.5723 16 9 16C5.13401 16 2 12.866 2 9C2 5.13401 5.13401 2 9 2C12.866 2 16 5.13401 16 9C16 10.5723 15.4816 12.0236 14.6064 13.1922L20.5356 19.1213L19.1213 20.5355L13.1922 14.6064Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}
