import React from 'react';
import { observer } from 'mobx-react';
import { CenterColumnLayout } from 'components/layouts/center-column-layout';
// import { SoundbiteEtAlWidgetGroup } from './soundbite-et-al-widget-group';
import { useNavigate } from 'react-router-dom';
// import { SampleStories } from './sample-stories';
import { subscribePath } from 'components/nav/path-helpers';
import { Button } from '@naan/primitives/button';
import { styled, darkThemeSelector } from '@naan/stitches.config';
import { AppFactory } from '@app/app-factory';
import { presentPurchaseDisabledAlert } from 'components/account/account-cta/purchase-flow-disabled';
import { ArrowRightIcon } from '@naan/icons/arrow-right-icon';
import { Responsive } from '@naan/primitives/responsive';
import { LocaleCode } from '@utils/util-types';
import __, {
  pickLocalizedValue,
  translateWithoutDefault,
} from '@core/lib/localization';

import subscribeImgDesktopEn from './assets/subscribe-desktop.png';
import subscribeImgMobileEn from './assets/subscribe-mobile.png';
import subscribeImgDesktopPt from './assets/subscribe-desktop-pt.png';
import subscribeImgMobilePt from './assets/subscribe-mobile-pt.png';
import { useCurrentStory } from 'components/story-detail/current-story-context';

const Layout = styled(CenterColumnLayout, {
  position: 'relative',
  background: '$red-50',
  [darkThemeSelector]: {
    background: '$blue-100',
  },
});

const Wrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  paddingTop: 32,
  // borderBottom: '1px solid $black-alpha-10',
  gap: 16,
  marginBottom: 32,

  '& .text': {
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: 4,
    '& .heading': {
      textStyle: 'medium-heading',
    },
    '& .copy': {
      textStyle: 'body-text',
      color: '$black-alpha-50',
      width: 'min(100% - 32px, 500px)',
      textAlign: 'center',
    },
  },

  '&.story': {
    '&::before': {
      height: 40,
      content: '""',
      display: 'block',
      left: 0,
      right: 0,
      top: 0,
      position: 'absolute',
      boxShadow: 'inset 0 8px 8px -8px rgba(0, 0, 0, 0.25)',
    },
    '& .text .heading': {
      textStyle: 'small-heading',
    },
  },

  '@medium': {
    paddingTop: 56,
    marginBottom: 40,
    '& .text': {
      '& .heading': {
        textStyle: 'large-heading',
      },
    },
    '&.story': {
      paddingTop: 40,
    },
  },
});

const ImageWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  '& .img': {
    width: '100%',
    maxWidth: 960,
  },
});

const bodyCopy = (l2: LocaleCode) => {
  return translateWithoutDefault(`cms:subscribeCtaBlurb:${l2}`);
};

const SubscribeCta = observer(
  ({
    title,
    copy,
    className,
  }: {
    title: string;
    copy: string;
    className?: 'story';
  }) => {
    const navigate = useNavigate();
    const { userManager } = AppFactory.root;
    const { purchaseFlowDisabled } = userManager;

    return (
      <Layout>
        <Wrapper className={className}>
          <div className="text">
            <h3 className="heading">{title} </h3>
            <div className="copy">{copy}</div>
          </div>
          <div>
            <Button
              presentation={'teal'}
              label={__('Subscribe now', 'subscribeNow')}
              size={'large'}
              rightIcon={<ArrowRightIcon />}
              onClick={() => {
                if (purchaseFlowDisabled) {
                  presentPurchaseDisabledAlert();
                } else {
                  navigate(subscribePath());
                }
              }}
            />
          </div>
        </Wrapper>
        <ImageWrapper>
          <Responsive
            renderDefault={() => (
              <img
                className="img"
                src={pickLocalizedValue({
                  en: subscribeImgMobileEn,
                  pt: subscribeImgMobilePt,
                })}
                alt=""
              />
            )}
            renderMediumAndUp={() => (
              <img
                className="img"
                src={pickLocalizedValue({
                  en: subscribeImgDesktopEn,
                  pt: subscribeImgDesktopPt,
                })}
                alt=""
              />
            )}
          ></Responsive>
        </ImageWrapper>
      </Layout>
    );
  }
);

export const DashboardSubscribeCta = observer(() => {
  const { storyManager, l2 } = AppFactory.root;
  const { productName } = storyManager;

  return (
    <SubscribeCta
      title={__('Unlock  %{productName}', 'unlockProductName', {
        productName,
      })}
      copy={bodyCopy(l2)}
    />
  );
});

// shown at the very bottom of the free story deatil page when user is still unsubscribed
export const StorySubscribeCta = observer(() => {
  const { l2, userManager } = AppFactory.root;

  const story = useCurrentStory();
  if (story.locked || userManager.fullAccess) {
    return null;
  }

  return (
    <SubscribeCta
      title={__('Unlock more stories', 'unlockMoreStories')}
      copy={bodyCopy(l2)}
      className="story"
    />
  );
});
