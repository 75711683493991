import { TFilterKey } from './filter-keys';
import { stringifyQuery } from './stringify-query';

export const makeFilterLink = (
  prefix: string,
  filterType: TFilterKey,
  filterValue: string[],
  sorting: string | null = null
) => {
  const newSearch = stringifyQuery({
    filterType,
    filterValue,
    sorting,
  });
  return `${prefix}?${newSearch}`;
};
