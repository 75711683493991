import * as React from 'react';
import { Text } from 'naan/primitives/text';
import { HSpacer } from 'naan/primitives/spacer';
import { useFiltering } from 'common/filtering/use-filtering';

import { SortByMenuButton } from './sort-by-menu-button';
import { Responsive } from 'naan/primitives/responsive';
import { styled } from 'naan/stitches.config';
import { observer } from 'mobx-react';
import { storiesCount } from '@core/lib/localization-helpers';

const Wrapper = styled('div', {
  display: 'flex',
  borderBottom: '1px solid $gray-100',
  alignItems: 'center',
  '@medium': {
    borderBottom: 'none',
  },
});

const StoryCount = styled('div', {
  '@medium': {
    display: 'flex',
    justifyContent: 'flex-end',
    marginLeft: 24,
  },
});

export const SortingSection = observer(() => {
  const { filteredUnits, sorting, setSortingKey } = useFiltering();
  const unitsCount = filteredUnits.length;

  return (
    <Wrapper>
      <StoryCount>
        <Text textStyle="small-text" color="textSecondary">
          {storiesCount(unitsCount)}
        </Text>
      </StoryCount>
      <Responsive
        renderDefault={() => <HSpacer expand />}
        renderMediumAndUp={() => <HSpacer size={3} />}
      />
      {/*
      <FilterButton label={<GearIcon />} iconOnly />
      <HSpacer size={3} />
       */}
      <SortByMenuButton sorting={sorting} setSortingKey={setSortingKey} />
    </Wrapper>
  );
});
