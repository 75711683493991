import * as React from 'react';
import * as Onboarding from './components';
// import { PlainMarkdown } from '@naan/primitives/text';
import { styled } from '@stitches/react';
import { observer } from 'mobx-react';
import { OnboardingService } from '@app/onboarding/onboarding-service';
import { OnboardingDialog } from './onboarding-dialogs';
import { unwidow } from '@utils/string-utils';
import { OnboardingPopover } from './onboarding-popover';
import { PlainMarkdown } from '@naan/primitives/text';

import __ from 'core/lib/localization';

import illustration from './assets/smart-pause-tip-illustration.png';

const ToolipPositioner = styled('span', {
  '&.highlight > *': {
    outline: '6px solid $teal-300',
    borderRadius: '50%',
  },
});

// export const SmartPauseTip = () => {
//   return (
//     <Onboarding.Container>
//       {/* <Onboarding.Image src={'https://placekitten.com/200/300'} /> */}
//       <Onboarding.Image src={imageSrc} width={80} height={64} />
//       <Onboarding.Title>
//         {__('Meet your Smart Pause button', 'smartPauseTipTitle')}
//       </Onboarding.Title>
//       <Onboarding.Content>
//         <PlainMarkdown
//           source={__(
//             `Listening is best done in whole sentences. When you tap pause, it plays to the end of the sentence and *then* stops. But if you tap pause again before it reaches the end, it'll stop immediately.`,
//             'smartPauseTipBody'
//           )}
//         />
//       </Onboarding.Content>
//       <Onboarding.HelpletVideo videoSlug="smart-pause" />
//     </Onboarding.Container>
//   );
// };

const ExistingUsersSmartPauseOnboardingDialog = observer(() => {
  const { smartPauseDialogDismissed } = OnboardingService.instance;

  return (
    <OnboardingDialog
      body={
        <Onboarding.Container>
          <Onboarding.Image src={illustration} />
          <Onboarding.Title>
            {__(
              'Smart Pause, now in technicolor',
              'smartPauseNowInTechnicolor'
            )}
          </Onboarding.Title>
          <Onboarding.Content>
            <PlainMarkdown
              source={__(
                "Smart Pause helps you practice listening in complete sentences. This feature now works in all players – including in Soundbites – and you'll notice we've added some _rojo_, just because. By the way, you can also pause the player by tapping the current sentence.",
                'smartPauseHelpsYouPracticeListeningInCompleteSentences'
              )}
            />
          </Onboarding.Content>
        </Onboarding.Container>
      }
      onResolve={smartPauseDialogDismissed}
    />
  );
});

const NewUsersSmartPauseOnboardingDialog = observer(() => {
  const { smartPauseDialogDismissed } = OnboardingService.instance;

  return (
    <OnboardingDialog
      body={
        <Onboarding.Container>
          <Onboarding.Image src={illustration} />
          <Onboarding.Title>
            {__('Introducing Smart Pause', 'introductingSmartPause')}
          </Onboarding.Title>
          <Onboarding.Content>
            {__(
              'In real life, you let people finish their sentences — so let\'s practice listening the same way. Now, when you tap "pause" it\'ll continue to the end of the current sentence (unless you tap pause a second time).',
              'inRealLifeYouLetPeopleFinishTheirSentences'
            )}
          </Onboarding.Content>
        </Onboarding.Container>
      }
      onResolve={smartPauseDialogDismissed}
    />
  );
});

export const SmartPauseOnboardingDialog = observer(() => {
  const {
    showSmartPauseDialogForNewUsers: forNew,
    showSmartPauseDialogForExistingUsers: forExisting,
  } = OnboardingService.instance;

  if (forExisting) {
    return <ExistingUsersSmartPauseOnboardingDialog />;
  }

  if (forNew) {
    return <NewUsersSmartPauseOnboardingDialog />;
  }

  return null;
});

export const SmartPauseOnboardingTip: React.FC<
  React.PropsWithChildren<unknown>
> = observer(({ children }) => {
  const dismissed = OnboardingService.instance.isDismissed('smartPauseTip');
  const showPopover = OnboardingService.instance.showSmartPauseTip;

  return (
    <OnboardingPopover
      dismissed={dismissed}
      showPopover={showPopover}
      renderAnchor={({ ref, getProps }) => (
        <ToolipPositioner
          ref={ref}
          {...getProps()}
          className={showPopover ? 'highlight' : null}
        >
          {children}
        </ToolipPositioner>
      )}
      renderBody={() => {
        return (
          <Onboarding.Container>
            <Onboarding.Content>
              {unwidow(
                __(
                  'You can change your Smart Pause setting here.',
                  'youCanChangeYourSmartPauseSettingHere'
                )
              )}
            </Onboarding.Content>
          </Onboarding.Container>
        );
      }}
      onResolvePopover={OnboardingService.instance.smartPauseTipDismissed}
    />
  );
});
