import * as React from 'react';
import { Outlet } from 'react-router-dom';
import { AuthLayout } from 'components/layouts';
import { FullScreenLoader } from 'components/ds/modals';

export const AuthRouteLayout = () => {
  return (
    <AuthLayout>
      <React.Suspense fallback={<FullScreenLoader />}>
        <Outlet />
      </React.Suspense>
    </AuthLayout>
  );
};
