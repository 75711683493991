import * as React from 'react';

import { observer } from 'mobx-react';
import { GoogleButton } from 'components/ui/buttons/google-button';
import { VSpacer } from '@naan/primitives/spacer';
import { useTitle } from 'common/hooks/use-title';
import { useAfterAuthRedirection } from 'lib/hooks/use-after-auth-redirection';
import { VStack } from 'naan/primitives/stack';
import { Link, Outlet } from 'react-router-dom';
import { AppFactory } from 'app/app-factory';
import { AuthInlineLink } from 'components/auth/auth-inline-link';
import { AuthHeading } from 'components/auth/auth-heading';
import {
  createAccountPath,
  emailCodePath,
  signInPath,
} from 'components/nav/path-helpers';
import { styled } from '@naan/stitches.config';
import __ from 'core/lib/localization';

// import { createLogger } from '@common/log';
// const log = createLogger('sign-in-password-screen');

const LinkWrapper = styled('div', {
  textAlign: 'center',
  paddingTop: 40,
  paddingBottom: 32,
  '& a': {
    textDecoration: 'underline',
    color: '$teal-500',
  },
});

export const SignInLayout = observer(() => {
  useTitle(__('Welcome back', 'welcomeBack'));
  useAfterAuthRedirection();

  const { accountCreationDisabled } = AppFactory.root;

  return (
    <VStack>
      <AuthHeading>{__('Welcome back', 'welcomeBack')}</AuthHeading>
      <Outlet />

      {accountCreationDisabled ? null : (
        <>
          <AuthInlineLink
            text={__(
              "Don't have an account? [Create one](%{url})",
              'dontHaveAnAccountCreateOne',
              { url: createAccountPath() }
            )}
          />
        </>
      )}
    </VStack>
  );
});

export const SignInWithPasswordLink = () => (
  <LinkWrapper>
    <Link to={signInPath()}>
      {__('Sign in with password instead', 'withPasswordInstead')}
    </Link>
  </LinkWrapper>
);

export const SignInWithCodeLink = () => (
  <LinkWrapper>
    <Link to={emailCodePath()}>
      {/* using onBlur to capture email into model instead of extracting imperatively here */}
      {/* // document.querySelector('input#email')?.value */}
      {__('Email me a one-time code instead', 'emailCodeInstead')}
    </Link>
  </LinkWrapper>
);

export const GoogleButtonIfEnabled = () => {
  if (AppFactory.root.disableGoogleAuth) {
    return null;
  }

  return (
    <>
      {/* <MediumGap /> */}
      <VSpacer size={2} />
      <GoogleButton
        title={__('Sign in with Google', 'signInWithGoogle')}
        tabIndex={5}
      />
      <VSpacer size={10} />
    </>
  );
};
