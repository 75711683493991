import { SoundbiteCategory } from '../soundbite-categories';
import accents from './accents.svg';
import culture from './culture.svg';
import grammar from './grammar.svg';
import idioms from './idioms.svg';
import patterns from './patterns.svg';
import strategies from './strategies.svg';
import vernacular from './vernacular.svg';
import vocabulary from './vocabulary.svg';

export const headerColors: Record<SoundbiteCategory, string> = {
  accents: '#c25da6',
  vernacular: '#2c96c3',
  vocabulary: '#389e4f',
  grammar: '#a18412',
  patterns: '#d75050',
  idioms: '#d17833',
  strategies: '#4d4d4d',
  culture: '#346792',
} as const;

export const headerImages: Record<SoundbiteCategory, string> = {
  accents: `url(${accents})`,
  vernacular: `url(${vernacular})`,
  vocabulary: `url(${vocabulary})`,
  grammar: `url(${grammar})`,
  patterns: `url(${patterns})`,
  idioms: `url(${idioms})`,
  strategies: `url(${strategies})`,
  culture: `url(${culture})`,
} as const;
