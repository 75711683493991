import * as React from 'react';
import { styled } from 'naan/stitches.config';
import { observer } from 'mobx-react';
import { GlobalFooter } from 'components/global-footer';
import { ScrollToTop } from 'lib/scroll-utils';
import { appModePath } from 'components/nav/path-helpers';
import { BrandedBackLink } from 'components/branding/branded-back-link';

const FooterContainer = styled('div', {
  position: 'sticky',
  top: '100vh',
  // px: '$space$4',
  // backgroundColor: '$colors$red-100',

  '@large': {
    px: '$space$6',
  },

  '@extraLarge': {
    margin: '0 auto',
    maxWidth: 'auto',
  },
});

const AccountNavWrapper = styled('nav', {
  $$itemSpacing: '16px',
  $$logoHeight: '32px',
  padding: '16px 0',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  backgroundColor: '$colors$white',

  '& > .logo': {
    color: 'inherit',
    textDecoration: 'none',
    marginRight: 'auto',
    '& > .just-the-icon': {
      color: '$colors$gray-400',
    },
    '& > not(:.just-the-icon) svg': {
      height: '$$logoHeight',
      width: 'auto',
    },
  },
  '@medium': {
    $$logoHeight: 'auto',
    $$itemSpacing: '24px',
  },
});

const LogoLink = () => {
  return (
    <div className="logo">
      <BrandedBackLink to={appModePath()} presentation="color" />
    </div>
  );
};

const Wrapper = styled('div', {
  display: 'grid',
  gridTemplateRows: 'auto 1fr',
  // '& .inner': {
  //   margin: '0 auto',
  //   width: 'min( 600px, calc( 100% - 32px ) )',
  // },
  '& > .nav': {
    padding: 'var(--sat) 16px 0 16px',
    backgroundColor: '$colors$white',
    position: 'sticky',
    top: '0',
    zIndex: '999',
    marginBottom: -16,
    width: 'min( 632px, 100% )',
    margin: '0 auto',
  },

  '& > .content': {
    display: 'grid',
    gridTemplateColumns: '1fr min( 600px, calc( 100% - 32px ) ) 1fr',
    '& > .center': {
      gridColumn: '2',
    },
    '& > .full-bleed': {
      gridColumn: '1 / -1',
    },
  },
});

export const ProfileLayout: React.FC<React.PropsWithChildren<unknown>> =
  observer(({ children }) => {
    return (
      <>
        <ScrollToTop />
        <Wrapper className="profile">
          <div className="nav">
            <div className="inner">
              <AccountNavWrapper>
                <LogoLink />
              </AccountNavWrapper>
            </div>
          </div>
          <section className="content">{children}</section>
        </Wrapper>
        <FooterContainer>
          <Wrapper>
            <div className="inner">
              <GlobalFooter />
            </div>
          </Wrapper>
        </FooterContainer>
      </>
    );
  });
