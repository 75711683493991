import {
  ElementId,
  ELTOf,
  WideElementList,
  IElement,
  SimplestElementList,
  IndexRange,
} from '../basic-types';
import { MembershipReconcilerImpl } from './membership-reconciler-impl';

type MembershipListBase = {
  memberships: string[];
  presentationIdPrefix?: string; // TODO use? OR MAYBE NEEDS TO BE FUNCTION id -> dom id to handle mapping to correct sentence element etc???
  useRanges?: boolean;
  range?: IndexRange;
  membershipsString?: string;
};

export type MembershipList = MembershipListBase & {
  elements: WideElementList; // TODO not the external contract
};

type MembershipListOptions1 = MembershipListBase & {
  elements: ELTOf<IElement>;
};

type MembershipListOptions2 = MembershipListBase & {
  elements: IElement[];
  useRanges?: false;
};

type MembershipListOptions3 = MembershipListBase & {
  elements: ELTOf<IElement>;
  range: IndexRange;
};

export interface MembershipReconciler {
  simpleSetMembershipsForElementId(
    id: ElementId,
    memberships: string[],
    remove: boolean
  ): void;

  // TODO jrw: change to string[] at some point
  // getMembershipForAddress(index: number): string[]; // CHANGED return from string to string[]
  // getMembershipForElement(id: ElementId): string[]; // CHANGED return from string to string[]

  getJoinedMembershipStringForAddress(index: number): string;
  getJoinedMembershipStringForElement(id: ElementId): string;

  setMembershipLists(lists: Map<string, MembershipList>): void;

  reconcileMembershipLists(
    episodeKey: string,
    layers0: Map<string, MembershipList>
  ): void;
}

export function CreateMembershipList({
  memberships,
  elements,
  range = null,
  presentationIdPrefix = null,
  useRanges = false,
}:
  | MembershipListOptions1
  | MembershipListOptions2
  | MembershipListOptions3): MembershipList {
  if (Array.isArray(elements)) {
    elements = SimplestElementList(elements);
  }
  return {
    memberships,
    elements: elements as unknown as WideElementList,
    range,
    presentationIdPrefix,
    useRanges,
  };
}

export function CreateMembershipReconciler(
  setMembershipsForId: (
    id: string,
    memberships: string[],
    remove: boolean
  ) => void
): MembershipReconciler {
  return new MembershipReconcilerImpl(setMembershipsForId);
}
