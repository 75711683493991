import { bugsnagNotify } from '@app/notification-service';
import {
  getParentOfType,
  ModelTreeNode,
  snap,
  TSTStringMap,
} from 'ts-state-tree/tst-core';
import { UserManager } from '.';
import { createLogger } from '@common/log';

const log = createLogger('classroom-user-data');

/**
 * ClassroomUserData
 *
 * teacher specific client managed data
 */
export class ClassroomUserData extends ModelTreeNode {
  static CLASS_NAME = 'ClassroomUserData' as const;

  static create(snapshot: any) {
    return super.create(ClassroomUserData, snapshot) as ClassroomUserData;
  }

  favoritedStorySlugSet: TSTStringMap<boolean> = snap({});
  // legacy
  // favoritedStorySlugs: string[];

  get userManager(): UserManager {
    return getParentOfType(this, UserManager);
  }

  async toggleFavorite(slug: string): Promise<void> {
    log.trace(`toggleFavorite: ${slug}`);
    if (this.isFavorited(slug)) {
      await this.removeFromFavorites(slug);
    } else {
      await this.addToFavorites(slug);
    }
  }

  isFavorited(slug: string): boolean {
    // return this.favoritedStorySlugSet.has(slug);
    return !!this.favoritedStorySlugSet.get(slug);
  }

  async addToFavorites(slug: string): Promise<void> {
    this.favoritedStorySlugSet.set(slug, true);
    await this.userManager.persistUserData().catch(bugsnagNotify); // clean this up. await/catch doesn't look right
  }

  async removeFromFavorites(slug: string): Promise<void> {
    // this.favoritedStorySlugSet.delete(slug);
    this.favoritedStorySlugSet.set(slug, false);
    await this.userManager.persistUserData().catch(bugsnagNotify);
  }

  // migrateListToMap(): boolean {
  //   if (!this.favoritedStorySlugs) return false;

  //   log.info(
  //     `migrate ${this.favoritedStorySlugs.length} favoritedStorySlugs to map`
  //   );
  //   runInAction(() => {
  //     for (const slug of this.favoritedStorySlugs) {
  //       this.favoritedStorySlugSet.set(slug, true);
  //     }
  //     this.favoritedStorySlugs = undefined;
  //   });
  //   return true;
  // }
}
