import * as React from 'react';
import * as Onboarding from './components';
import __ from 'core/lib/localization';

export const SkipSoundbitesTip = () => {
  return (
    <Onboarding.Container>
      <Onboarding.Title>
        {__(
          'Prefer to do the Soundbites later?',
          'preferToDoTheSoundbitesLater'
        )}
      </Onboarding.Title>
      <Onboarding.Content>
        {__(
          'Tap here to jump straight into studying the full chapter.',
          'tapHereToJumpStraightIntoStudyingTheFullChapter'
        )}
      </Onboarding.Content>
    </Onboarding.Container>
  );
};
