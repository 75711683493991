import * as React from 'react';

export const LocationIcon = ({
  width = 24,
  height = 24,
  color = 'currentColor',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.734 22.1481C14.7112 19.8101 20 13.2523 20 10C20 5.5 16.5 2 12 2C7.5 2 4 5.5 4 10C4 13.2523 9.2888 19.8101 11.266 22.1481C11.6526 22.6053 12.3474 22.6053 12.734 22.1481ZM12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13Z"
      fill={color}
    />
  </svg>
);
