import React from 'react';
import { Navigate, useParams } from 'react-router-dom';
import useSWR from 'swr';
import { AppFactory } from '@app/app-factory';
import { learnStoriesPath } from 'components/nav/path-helpers';
import { createLogger } from 'app/logger';
import { FullScreenLoader } from 'components/ds/modals/full-screen-loader';

const log = createLogger('select-catalog');

const selectCatalog = async (slug: string): Promise<boolean> => {
  const { userManager } = AppFactory.root;
  await userManager.overrideCatalogSlug(slug);
  log.info(`catalog slug after update: ${userManager.catalogSlug}`);
  return true;
};

export const SelectCatalog = () => {
  const { slug } = useParams<{ slug: string }>();
  log.info(`slug: ${slug}`);

  // beware, this is a misuse of SWR
  let { data, error } = useSWR(slug, selectCatalog);

  if (data === undefined && !error) {
    return <FullScreenLoader />;
  }

  if (error) {
    return <>{String(error)}</>;
  }

  return <Navigate to={learnStoriesPath()} />;
};
