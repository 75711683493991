import * as React from 'react';

export function IbSmallIcon({
  width = 20,
  height = 20,
  color = 'currentColor',
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6.12012 5.04688V15H3.89844V5.04688H6.12012Z" fill={color} />
      <path
        d="M12.8765 10.7002H10.3198L10.3062 9.20996H12.4185C12.7922 9.20996 13.0952 9.16439 13.3277 9.07324C13.5646 8.9821 13.7378 8.84993 13.8472 8.67676C13.9566 8.49902 14.0112 8.28027 14.0112 8.02051C14.0112 7.72428 13.9566 7.48503 13.8472 7.30273C13.7378 7.12044 13.5646 6.98828 13.3277 6.90625C13.0952 6.81966 12.7945 6.77637 12.4253 6.77637H11.1265V15H8.89797V5.04688H12.4253C13.0223 5.04688 13.5555 5.10156 14.0249 5.21094C14.4943 5.32031 14.8931 5.48893 15.2212 5.7168C15.5539 5.94466 15.8068 6.22949 15.98 6.57129C16.1532 6.91309 16.2398 7.31641 16.2398 7.78125C16.2398 8.18685 16.1486 8.5651 15.9663 8.91602C15.7886 9.26693 15.5015 9.55176 15.105 9.77051C14.7085 9.98926 14.1776 10.1077 13.5122 10.126L12.8765 10.7002ZM12.7876 15H9.75246L10.5591 13.2773H12.7876C13.1294 13.2773 13.4074 13.2227 13.6216 13.1133C13.8358 13.0039 13.993 12.8558 14.0933 12.6689C14.1935 12.4821 14.2437 12.2725 14.2437 12.04C14.2437 11.7666 14.1958 11.5296 14.1001 11.3291C14.009 11.1286 13.8631 10.9736 13.6626 10.8643C13.4667 10.7549 13.2046 10.7002 12.8765 10.7002H10.8804L10.8941 9.20996H13.3345L13.854 9.79785C14.4875 9.78418 14.9933 9.88672 15.3716 10.1055C15.7544 10.3197 16.0301 10.6022 16.1987 10.9531C16.3674 11.304 16.4517 11.6755 16.4517 12.0674C16.4517 12.7145 16.3127 13.2568 16.0347 13.6943C15.7567 14.1273 15.3443 14.4531 14.7974 14.6719C14.2551 14.8906 13.5851 15 12.7876 15Z"
        fill={color}
      />
    </svg>
  );
}
