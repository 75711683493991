import * as React from 'react';

export const SettingsIcon = ({
  width = 24,
  height = 24,
  color = 'currentColor',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 9.5C16.8284 9.5 17.5 8.82843 17.5 8C17.5 7.17157 16.8284 6.5 16 6.5C15.1716 6.5 14.5 7.17157 14.5 8C14.5 8.82843 15.1716 9.5 16 9.5ZM16 11.5C17.5855 11.5 18.9248 10.4457 19.3551 9H22V7H19.3551C18.9248 5.55426 17.5855 4.5 16 4.5C14.4145 4.5 13.0752 5.55426 12.6449 7H2V9H12.6449C13.0752 10.4457 14.4145 11.5 16 11.5ZM11.3551 15H22V17H11.3551C10.9248 18.4457 9.58551 19.5 8 19.5C6.41449 19.5 5.07521 18.4457 4.64494 17H2V15H4.64494C5.07521 13.5543 6.41449 12.5 8 12.5C9.58551 12.5 10.9248 13.5543 11.3551 15ZM9.5 16C9.5 16.8284 8.82843 17.5 8 17.5C7.17157 17.5 6.5 16.8284 6.5 16C6.5 15.1716 7.17157 14.5 8 14.5C8.82843 14.5 9.5 15.1716 9.5 16Z"
        fill={color}
      />
    </svg>
  );
};

export const SettingsExtraSmallIcon = ({
  width = 16,
  height = 16,
  color = 'currentColor',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 6.5C11.8284 6.5 12.5 5.82843 12.5 5C12.5 4.17157 11.8284 3.5 11 3.5C10.1716 3.5 9.5 4.17157 9.5 5C9.5 5.82843 10.1716 6.5 11 6.5ZM11 8C12.3062 8 13.4175 7.16519 13.8293 6H15V4H13.8293C13.4175 2.83481 12.3062 2 11 2C9.69378 2 8.58254 2.83481 8.17071 4H1V6H8.17071C8.58254 7.16519 9.69378 8 11 8ZM7.82929 10H15V12H7.82929C7.41746 13.1652 6.30622 14 5 14C3.69378 14 2.58254 13.1652 2.17071 12H1V10H2.17071C2.58254 8.83481 3.69378 8 5 8C6.30622 8 7.41746 8.83481 7.82929 10ZM6.5 11C6.5 11.8284 5.82843 12.5 5 12.5C4.17157 12.5 3.5 11.8284 3.5 11C3.5 10.1716 4.17157 9.5 5 9.5C5.82843 9.5 6.5 10.1716 6.5 11Z"
        fill={color}
      />
    </svg>
  );
};
