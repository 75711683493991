import * as React from 'react';

export function ExportIcon({
  width = 24,
  height = 24,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 15V4.12948L14.5052 6L16 4.53093L12 0L8 4.53093L9.49483 6L11 4.12948V15H13ZM5 9C5 8.44772 5.44772 8 6 8H7V6H6C4.34315 6 3 7.34315 3 9V18C3 19.6569 4.34315 21 6 21H18C19.6569 21 21 19.6569 21 18V9C21 7.34315 19.6569 6 18 6H17V8H18C18.5523 8 19 8.44772 19 9V18C19 18.5523 18.5523 19 18 19H6C5.44772 19 5 18.5523 5 18V9Z"
        fill={color}
        fillRule="evenodd"
      />
      <path
        d="M13 15V4.12948L14.5052 6L16 4.53093L12 0L8 4.53093L9.49483 6L11 4.12948V15H13ZM5 9C5 8.44772 5.44772 8 6 8H7V6H6C4.34315 6 3 7.34315 3 9V18C3 19.6569 4.34315 21 6 21H18C19.6569 21 21 19.6569 21 18V9C21 7.34315 19.6569 6 18 6H17V8H18C18.5523 8 19 8.44772 19 9V18C19 18.5523 18.5523 19 18 19H6C5.44772 19 5 18.5523 5 18V9Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}
