import * as React from 'react';
import { FeedbackDialog } from 'components/ui/feedback/feedback-dialog';
import { feedbackDialogStatus } from 'components/ui/feedback/use-feedback-state';
import { FeedbackMetadata } from 'components/ui/feedback/feedback-metadata';
import { action, makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import { ChapterCatalogData } from '@core/models/catalog';
import { styled } from '@naan/stitches.config';
import { ThanksDialog } from 'components/ui/feedback/thanks-dialog';

class SentenceFeedbackUiController {
  status: feedbackDialogStatus = 'initial';
  metadata: FeedbackMetadata = null;
  chapter: ChapterCatalogData = null;

  constructor() {
    this.status = 'initial';
    this.metadata = null;

    makeObservable(this, {
      status: observable,
      metadata: observable,
      setMetadata: action,
      // selectSentence: action,
      showThanks: action,
    });
  }

  setMetadata(metaData: FeedbackMetadata): void {
    this.metadata = metaData;
  }

  // @armando, this is dead code, right?
  // selectSentence(sentenceId: string): void {
  //   this.status = 'open';
  //   debugger;
  //   this.setMetadata({
  //     type: 'sentence',
  //     story: this.chapter.story,
  //     chapter: this.chapter,
  //     // context: sentenceId,
  //     context: `chapter ${this.chapter.position} (${this.chapter.title}), sentence ${sentenceId}`,
  //   });
  // }

  showThanks(): void {
    this.status = 'thanks';
  }

  closeDialog(): void {
    this.status = 'initial';
    // reset all
    this.metadata = null;
  }
}

export const sentenceFeedbackUiController = new SentenceFeedbackUiController();

const DialogContext = styled('div', {
  '& .story-title': {
    textStyle: 'body-bold',
  },
  '& .chapter-title': {
    textStyle: 'body',
  },
  '& .sentence-context': {
    textStyle: 'small-text',
    color: '$textSecondary',
    marginTop: 2,
  },
});

export const SentenceFeedbackUi = observer(
  ({ chapter }: { chapter: ChapterCatalogData }) => {
    const controller = sentenceFeedbackUiController;
    controller.chapter = chapter;

    if (!controller.metadata) {
      return null;
    }

    if (controller.status === 'thanks') {
      return <ThanksDialog onDismiss={() => controller.closeDialog()} />;
    }

    return (
      <FeedbackDialog
        afterSubmit={() => controller.showThanks()}
        onDismiss={() => controller.closeDialog()}
        metadata={controller.metadata}
        context={
          <DialogContext>
            <div className="story-title">{chapter.story.title}</div>
            <div className="chapter-title">
              {chapter.position}. {chapter.title}
            </div>
            <div className="sentence-context">
              {controller.metadata.context}
            </div>
          </DialogContext>
        }
      />
    );
  }
);
