// DEPRECATED: this will be unnecessary in 6.2.0
import * as React from 'react';

type LayoutContextType = {
  showingSidebar?: boolean;
  toggleSidebar?: () => void;
};

const LayoutContext = React.createContext<LayoutContextType>({});

export const useLayoutContext = () => React.useContext(LayoutContext);
