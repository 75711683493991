import * as React from 'react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { AppFactory } from 'app/app-factory';
import { VStack } from 'naan/primitives/stack';
import { ChapterListItem } from '../../../story/chapter-list-item';
import { CenterColumnLayout } from 'components/layouts/center-column-layout';
import { ChapterCatalogData, UnitCatalogData } from '@core/models/catalog';
import { ReturnNavState } from 'components/nav/return-nav-state';
import { SmallGap } from '@naan/primitives/spacer/gaps';
import { elementIds } from 'components/dom-utils/element-ids';
import { ChapterRowUiProvider } from 'story/chapter-list-item/chapter-item-contents/chapter-row-ui-model';
import { Story } from '@core/models/story-manager/story';
import {
  ChapterPositionUiProvider,
  useChapterPositionUiContext,
} from 'story/chapter-list-item/chapter-item-contents/chapter-position-ui-model';
import { ChapterListHeader } from 'components/story-detail/chapter-list-toolbar';
import { styled } from '@naan/stitches.config';

function maybeCurrentId(chapter: ChapterCatalogData) {
  if (chapter.isCurrent) {
    return { id: elementIds.CURRENT_CHAPTER_ID };
  }

  return {};
}

const UnitHeader = ({ unit }: { unit: UnitCatalogData }) => {
  return (
    <div className="unitHeader">
      {unit.partLabel}
      <span className="unitDuration">{unit.durationDescription}</span>{' '}
    </div>
  );
};

const Wrapper = styled('div', {
  flexGrow: 1,
});

export const StoryTab = observer(() => {
  // track when it's safe to pop back from study view
  ReturnNavState.storyDetailVisited = true;

  const { storyManager } = AppFactory.root;
  const { slug } = useParams<{ slug: string }>();
  const story = storyManager.story(slug);
  // useStoryTabScrollingFx({ unstarted: story.unstarted });

  return (
    <Wrapper>
      <ChapterListHeader />
      <ChapterPositionUiProvider story={story}>
        <StoryTabContent story={story} />
      </ChapterPositionUiProvider>
      {/* <SoundbiteFloatingCard story={story} /> */}
    </Wrapper>
  );
});

// break out content within the ui model provider so the useEffect can
// update the uiModel on initial load
const StoryTabContent = observer(({ story }: { story: Story }) => {
  const positionUiModel = useChapterPositionUiContext();
  const { progress } = story;

  React.useEffect(() => {
    positionUiModel.initWithStory(story);
  }, [positionUiModel, story, progress.currentChapterSortingPosition]);

  return (
    <CenterColumnLayout narrow>
      <VStack id={elementIds.CHAPTER_LIST_ID}>
        {/* <DebugChapterUiModel /> */}
        {story.units.map(unit => (
          <React.Fragment key={unit.slug}>
            {story.multiUnit ? <UnitHeader unit={unit} /> : null}
            <div>
              {unit.chapters.map(chapter => (
                <div
                  key={unit.slug + '_' + chapter.position}
                  {...maybeCurrentId(chapter)}
                >
                  <ChapterRowUiProvider chapter={chapter}>
                    <ChapterListItem chapter={chapter} />
                  </ChapterRowUiProvider>
                </div>
              ))}
            </div>
          </React.Fragment>
        ))}
      </VStack>
      <SmallGap />
    </CenterColumnLayout>
  );
});

// const DebugChapterUiModel = observer(() => {
//   const model = useStoryDetailUiContext();
//   return (
//     <div>
//       chapterPosition: {String(model.currentChapterPosition)}, soundbiteIndex:{' '}
//       {String(model.currentSoundbiteIndex)}, stage: {String(model.currentStage)}
//       , studyMode: {String(model.currentStudyMode)}
//     </div>
//   );
// });
