import { IElement, StringToNumber } from '../basic-types';
import { PrecedenceImpl } from './precedence-impl';

export interface Precedence<KTL extends readonly string[]> {
  precedence: KTL;
  keyToPrecedenceLevel: StringToNumber;

  leastPrecedenceLevel(): number;
  precedenceAtLeastFilter(
    kind: string,
    mapTranslations?: boolean
  ): (el: IElement) => boolean;
}

export function CreatePrecedence<KTL extends readonly string[]>(
  precedence: KTL
): Precedence<KTL> {
  return new PrecedenceImpl(precedence);
}
