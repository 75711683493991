//
// fork of sign-in-screen with functional-but-ugly support for emailed auth code
//

import * as React from 'react';

import { observer } from 'mobx-react';
import { Button } from 'naan/primitives/button';
import { useTitle } from 'common/hooks/use-title';
import { useAfterAuthRedirection } from 'lib/hooks/use-after-auth-redirection';
import { VStack } from 'naan/primitives/stack';
import { Input } from 'naan/primitives/input';
import { Label } from 'naan/primitives/forms/label';
import { useComboForm } from 'common/hooks/use-comboform';
import { VSpacer } from 'naan/primitives/spacer';
// import { Link } from 'react-router-dom';
import { styled } from 'naan/stitches.config';
import { CatchAllFormErrors } from 'naan/primitives/forms/catch-all-form-error';
import { FormError } from 'naan/primitives/forms';
import { isEmpty, omit } from 'lodash';
import { AppFactory } from 'app/app-factory';
import { intentionallyResetAllScrollStorageKeys } from 'lib/scroll-utils/deep-scroll-restoration';
import { createLogger } from '@common/log';
// import { ActionLink } from '@common/ui/action-link';
import { getSignInModel } from './sign-in-model';
import { NotificationService, notifySuccess } from '@app/notification-service';
import { notificationTypes } from '@common/notifications/notification-types';
import { SignInWithPasswordLink } from './sign-in-layout';
import { resolveApiMessage } from '@core/models/user-manager/api-messages';
import { Text } from '@naan/primitives/text';

import __ from 'core/lib/localization';

const log = createLogger('sign-in-screen');

const ResetLink = styled('a', {
  textStyle: 'small-text',
  color: '$teal-500',
  textDecoration: 'underline',
  textAlign: 'right',
  position: 'absolute',
  right: 0,
});

const LabelWrapper = styled('div', {
  position: 'relative',
});

type FormInput = {
  email: string;
  code: string;
};

export const SignInCodeScreen = observer(() => {
  useTitle(__('Welcome back', 'welcomeBack'));
  useAfterAuthRedirection();
  const { userManager } = AppFactory.root;

  const signInModel = getSignInModel();
  const defaultValues = { email: signInModel.email };

  const {
    register,
    handleSubmit,
    errors,
    clearValidationErrors,
    handleValidationErrors,
  } = useComboForm<FormInput>({ defaultValues });

  const handleLogin = React.useCallback(
    ({ email, code }: FormInput) => {
      log.info('handleLogin');
      clearValidationErrors();
      userManager.login(email, null, code).then(() => {
        signInModel.reset();
        intentionallyResetAllScrollStorageKeys();
      }, handleValidationErrors);
    },
    [userManager, signInModel, handleValidationErrors, clearValidationErrors]
  );

  const handleResend = React.useCallback(() => {
    clearValidationErrors();
    const { email } = signInModel;

    if (isEmpty(email)) {
      NotificationService.open({
        type: notificationTypes.WARNING,
        message: __('Email is required', 'emailRequired'),
      });
      return;
    }

    userManager.sendShortAuth(email).then(response => {
      log.info(`server resp: ${JSON.stringify(response)}`); // {messageKey, message}
      const message = resolveApiMessage(response);
      log.info(`resolved message: ${message}`);
      notifySuccess(message);
      signInModel.codeSent = true;
    }, handleValidationErrors);
  }, [userManager, signInModel, handleValidationErrors, clearValidationErrors]);

  return (
    <>
      <form onSubmit={handleSubmit(handleLogin)}>
        <CatchAllFormErrors
          errors={omit(errors, ['email', 'code'])}
          data-test-id="error-message"
        />
        <VStack>
          <Label htmlFor="email">{__('Email', 'email')}</Label>
          <VSpacer size={2} />
          <Input
            name={'email'}
            data-test-id={'email-address'}
            type="email"
            tabIndex={1}
            {...register('email')}
            required
            onBlur={e => {
              signInModel.email = e.currentTarget.value;
            }}
          />
          <FormError
            error={errors['email']}
            data-test-id="email-error-message"
          />

          <VSpacer size={6} />

          <LabelWrapper>
            <Label htmlFor="code">{__('One-time code', 'oneTimeCode')}</Label>
            <ResetLink onClick={handleResend} href="#">
              {__('Resend code', 'resendCode')}
            </ResetLink>
          </LabelWrapper>

          <VSpacer size={1} />

          <Text color="textSecondary">
            {__(
              "We've emailed you a 4-digit code. Please enter this below.",
              'weveEmailedYouACode'
            )}
          </Text>

          <VSpacer size={3} />

          <Input
            name={'code'}
            data-test-id={'code'}
            tabIndex={2}
            {...register('code')}
            placeholder="- - - -"
            css={{
              textAlign: 'center',
              textStyle: 'medium-heading',
              '&::placeholder': {
                textStyle: 'medium-heading',
              },
              '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
                WebkitAppearance: 'none',
                margin: 0,
              },
            }}
            type="number"
            required
            maxLength={4}
          />
          <FormError error={errors['code']} data-test-id="code-error-message" />
          <VSpacer size={6} />
        </VStack>
        <div style={{ width: '100%', display: 'flex' }}>
          <Button
            label={__('Sign in', 'signIn')}
            onClick={handleSubmit as any}
            presentation="teal"
            size="large"
            fullWidth
            tabIndex={4}
          />
        </div>
      </form>
      <SignInWithPasswordLink />
    </>
  );
});
