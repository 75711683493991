/**
 * FIXME: this is duplicated here and on each project. Find a way to decouple.
 * feb 9, 2023 update: I removed some stuff that we no longer needed. Still need to
 * evaluate what to do with the rest.
 *
 */
// import __ from '../localization';
// export { default as tipsData } from './tips-data';

// export const storySortKeys = {
//   DUE_DATE: 'assignmentDueDate',
//   MOST_RECENT: 'mostRecent',
//   TITLE: 'title',
//   DURATION: 'duration',
//   COUNTRY: 'country',
//   TOPIC: 'topic',
//   BROADCAST_DATE: 'originalBroadcast',
// };

// export const getStorySortLabels = () => {
//   return {
//     assignmentDueDate: __('Due date', 'stories.sort.dueDate'),
//     mostRecent: __('Most recent', 'stories.sort.mostRecent'),
//     title: __('A–Z', 'stories.sort.title'),
//     duration: __('Duration', 'stories.sort.duration'),
//     country: __('Country', 'stories.sort.country'),
//     topic: __('Topic', 'stories.sort.topic'),
//     originalBroadcast: __(
//       'Original air date',
//       'stories.sort.originalBroadcast'
//     ),
//   };
// };

export const CLASSROOM_FILTER_KEY_PREFIX = 'classroom/';

export const isClassroomFilterKey = (key: string) =>
  key.startsWith(CLASSROOM_FILTER_KEY_PREFIX);

// export const storyFilterKeys = {
//   ALL: 'all',
//   UNPLAYED: 'unplayed',
//   IN_PROGRESS: 'inProgress',
//   COMPLETED: 'completed',
//   NOT_DOWNLOADED: 'notDownloaded',
//   DOWNLOADED: 'downloaded',
// };

// export const END_OF_CHAPTER = '999.';
// export const BEGINNING_OF_CHAPTER = '000.';
export const END_OF_STORY_CHAPTER = 999; // remove usages
// export const END_OF_UNIT_CHAPTER = 999;
export const END_OF_VOLUME_UNIT = 999;
// export const getCarouselButtonLabels = () => ({
//   BEGIN: __('Begin chapter', 'story.carousel.begin'),
//   RELISTEN: __('Relisten', 'story.carousel.relisten'),
//   CONTINUE_LISTEN: __('Continue listen', 'story.carousel.continueListen'),
//   CONTINUE_RELISTEN: __('Continue relisten', 'story.carousel.continueRelisten'),
//   AGAIN: __('Listen again', 'story.carousel.again'),
// });

// export const tipTypes = {
//   TIP: 'TIP',
//   DIALOG: 'DIALOG',
// };

// export const startupModalTypes = {
//   NONE: 'NONE',
//   WELCOME: 'WELCOME',
//   WHATS_NEW: 'WHATS_NEW',
// };

export const FNAME_MAX_CHAR_LIMIT = 15;
// export const INITIAL_NUM_COMPLETED_FOR_REVIEW_CTA = 3;
