import * as React from 'react';
import { FilterButton } from './filter-button';
import { styled } from 'naan/stitches.config';

const Carrot = styled('span', {
  marginTop: '-2px',
  display: 'block',
  px: '$1',
  transitionProperty: 'transform',
  transitionDuration: '400ms',
  variants: {
    selected: {
      true: {
        color: '$teal-500',
        transitionDuration: '200ms',
        transform: 'rotate(-180deg) ',
      },
    },
  },
});

const carrot = (
  <svg
    width={8}
    height={7}
    viewBox="0 0 8 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.2002 1.40039L4.0002 4.60039L0.800196 1.40039"
      stroke="currentColor"
      strokeWidth={2}
    />
  </svg>
);

type FilterDisclosureButtonProps = {
  label: React.ReactNode;
  selected: boolean;
  onClick: () => void;
};

export const FilterDisclosureButton = ({
  label,
  selected,
  onClick,
}: FilterDisclosureButtonProps) => {
  return (
    <FilterButton
      onClick={onClick}
      selected={selected}
      label={label}
      rightIcon={<Carrot selected={selected}>{carrot}</Carrot>}
    />
  );
};
