import * as React from 'react';
import { styled } from '@naan/stitches.config';
import { observer } from 'mobx-react';
import { Button } from '@naan/primitives/button';
import {
  CheckmarkCircleExtraExtraLargeIcon,
  CheckmarkCircleIcon,
} from '@naan/icons/checkmark-circle-icon';
import { RewindExtraSmallIcon } from '@naan/icons/rewind-icon';
import { ChapterCatalogData } from '@core/models/catalog';
import { PlayerMode } from '@common/misc-types';
import { ChapterReviewOverflowMenu } from './chapter-review-overflow-menu';
import { useNavigateToStudy } from 'lib/hooks/use-navigate-to-study';
import { useChapterRowUiContext } from './chapter-row-ui-model';
import classNames from 'classnames';
import autoAnimate from '@formkit/auto-animate';

import __ from '@core/lib/localization';

const CardWrapper = styled('div', {
  $$backgroundColor: '$colors$gray-50',
  display: 'flex',
  flexDirection: 'column',
  // alignItems: 'center',
  // justifyContent: 'center',
  // padding: 16,
  // paddingTop: 40,
  borderRadius: '$radii$large',
  backgroundColor: '$$backgroundColor',
  transition:
    'background-color 0.2s ease-in-out, transform 0.2s ease-in-out, opacity 0.2s ease-in-out',
  overflow: 'auto',

  '& .title-container': {
    cursor: 'default',
    userSelect: 'none',
    padding: '16px 20px 16px 20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    '&.open': {
      display: 'flex',
      justifyContent: 'center',
    },
    '& .card-title': {
      textStyle: 'small-caps',
      color: '$black-alpha-50',
    },
    '& .icon': {
      color: '$green-500',
    },
  },
});

const InnerWrapper = styled('div', {
  $$backgroundColor: '$colors$gray-50',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 16,
  paddingTop: 36,
  paddingBottom: 48,
  borderRadius: '$radii$large',
  backgroundColor: '$$backgroundColor',
  transition:
    'background-color 0.2s ease-in-out, transform 0.2s ease-in-out, opacity 0.2s ease-in-out',
  overflow: 'auto',

  '& .heading': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 20,

    '& svg': {
      width: 56,
      height: 56,
    },
    '& .title': {
      textStyle: 'small-heading',
      margin: '2px 0',
    },
    '& .duration': {
      textStyle: 'small-text',
      color: '$gray-500',
    },
  },

  '@medium': {
    paddingTop: 44,
    paddingBottom: 56,
  },
});

const OverflowMenuWrapper = styled('div', {
  position: 'absolute',
  top: 4,
  right: 8,
});

export const ChapterCompleteCard = observer(
  ({ chapter }: { chapter: ChapterCatalogData }) => {
    const model = useChapterRowUiContext();
    const open = model.currentStage === 'study';
    const parent = React.useRef<HTMLDivElement>(null);

    const navigateToStudy = useNavigateToStudy();

    React.useEffect(() => {
      parent.current &&
        autoAnimate(parent.current, {
          duration: 150,
          easing: 'ease-out',
        });
    }, [parent]);

    return (
      <CardWrapper ref={parent}>
        {open ? null : (
          <div
            className={classNames('title-container', { open })}
            onClick={() => {
              if (model.currentStage !== 'study') {
                model.setCurrentStage('study');
              }
            }}
          >
            <span className="card-title">
              {__('Study & listen', 'StudyAmpListen')}
            </span>
            <span className="icon">
              <CheckmarkCircleIcon />
            </span>
          </div>
        )}
        {open ? (
          <InnerWrapper>
            <OverflowMenuWrapper className="something">
              <ChapterReviewOverflowMenu chapter={chapter} />
            </OverflowMenuWrapper>

            <div className="heading">
              <CheckmarkCircleExtraExtraLargeIcon color="var(--colors-green-500)" />
              <span className="title">
                {__('Chapter complete', 'chapterComplete')}
              </span>
              <span className="duration">{chapter.prettyDuration}</span>
            </div>
            <div className="button-wrapper">
              <Button
                onClick={e => {
                  e.stopPropagation();
                  chapter.review();
                  navigateToStudy(chapter, PlayerMode.FLUENT_LISTEN);
                }}
                label={__('Relisten from here', 'relistenFromHere')}
                size="small"
                presentation={'grayDark'}
                leftIcon={<RewindExtraSmallIcon />}
              />
            </div>
          </InnerWrapper>
        ) : null}
      </CardWrapper>
    );
  }
);
