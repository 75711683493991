import * as React from 'react';

export function MinusLargeIcon({
  width = 28,
  height = 28,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 28 28"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect height="2" width="24" fill={color} x="2" y="13" />
    </svg>
  );
}

export function MinusSmallIcon({
  width = 20,
  height = 20,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect height="2" width="16" fill={color} x="2" y="9" />
    </svg>
  );
}

export function MinusExtraSmallIcon({
  width = 16,
  height = 16,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect height="2" width="12" fill={color} x="2" y="7" />
    </svg>
  );
}

export function MinusIcon({ width = 24, height = 24, color = 'currentColor' }) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2 11H22V13H2V11Z" fill={color} />
    </svg>
  );
}
