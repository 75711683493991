import Dayjs from 'dayjs';
import { createLogger } from '@app/logger';
import { dayjsToIsoDate } from '@utils/date-utils';
import { EntitlementDataType } from '@cas-shared/cas-types';
const log = createLogger('subscription-helpers');

export function pauseSubscriptionDates(
  state: EntitlementDataType,
  { months, effectiveDate }: { months: number; effectiveDate?: string }
) {
  if (!effectiveDate) {
    effectiveDate = dayjsToIsoDate(Dayjs());
  }

  const effectiveDateDayjs = Dayjs(effectiveDate);
  const pausedSince = dayjsToIsoDate(effectiveDateDayjs);
  const pausedUntil = dayjsToIsoDate(effectiveDateDayjs.add(months, 'month'));
  const billingResumesOn = dayjsToIsoDate(
    Dayjs(state.accessUntil).add(months, 'month')
  );
  log.info(
    `pausedSince: ${pausedSince}, pausedUntil: ${pausedUntil}, billingResumesOn: ${billingResumesOn}`
  );

  return { pausedSince, pausedUntil, billingResumesOn };
}

export function resumeSubscriptionDates(
  state: EntitlementDataType,
  { effectiveDate }: { effectiveDate: string }
) {
  if (!effectiveDate) {
    effectiveDate = dayjsToIsoDate(Dayjs());
  }

  const pausedSince = state.pausedSince;
  const pausedUntil = state.pausedUntil;
  const billingResumesOn = state.billingResumesOn;
  const pausedDays = Dayjs(effectiveDate).diff(Dayjs(pausedSince), 'day');
  const reclaimedDays = Dayjs(pausedUntil).diff(Dayjs(effectiveDate), 'day');
  const accessUntil = dayjsToIsoDate(
    Dayjs(billingResumesOn).subtract(reclaimedDays, 'day')
  );
  return { pausedDays, reclaimedDays, accessUntil };
}

// export function switchToYearlyData(
//   state: EntitlementDataType
//   // { effectiveDate }: { effectiveDate: string }
// ) {
//   // if (!effectiveDate) {
//   //   effectiveDate = dayjsToIsoDate(Dayjs());
//   // }

//   const currentPlanSlug = state.planSlug;
//   const oldPlan = getPlanBySlug(currentPlanSlug);
//   let newPlanSlug: PlanSlug;
//   if (currentPlanSlug.endsWith('month')) {
//     newPlanSlug = currentPlanSlug.replace('month', 'year') as PlanSlug;
//   } else {
//     newPlanSlug = currentPlanSlug.replace('year', 'month') as PlanSlug;
//   }
//   const newPlan = getPlanBySlug(newPlanSlug);
//   const newPrice = newPlan.price;
//   const currency = newPlan.currency;
//   const oldAnnualPrice = oldPlan.price * 12;
//   const savedPercent = Math.round(
//     (100 * (oldAnnualPrice - newPrice)) / oldAnnualPrice
//   );

//   // const nextBillingDayjs = newSubscription.current_period_end
//   //   ? Dayjs(newSubscription.current_period_end * 1000)
//   //   : null;
//   // const newAccessUntil = dayjsToIsoDate(nextBillingDayjs);

//   // beware, this is an estimate used by the client ui
//   // server calculation is derived from the stripe subscription response data
//   const newAccessUntil = dayjsToIsoDate(
//     Dayjs(state.accessUntil).add(11, 'month')
//   );

//   // const accessUntil = dayjsToIsoDate(Dayjs(state.accessUntil).add(1, 'year'));
//   const result = {
//     newPlanSlug,
//     newPrice,
//     currency,
//     newAccessUntil,
//     savedPercent,
//   };
//   log.info(`switchToYearlyData: ${JSON.stringify(result, null, 2)}`);
//   return result;
// }
