import * as React from 'react';
import { AppFactory } from '@app/app-factory';
import { createLogger } from 'app/logger';

const log = createLogger('study-ui');

export function useWakeLock() {
  React.useEffect(() => {
    return () => {
      /// be sure to disable the handler when the component unmounts
      /// even if the player hasn't stopped playing
      log.debug('disabling wake lock handler');
      AppFactory.wakeLock.deactivate();
    };
  }, []);
}
