// import React from 'react';
import { makeObservable, observable } from 'mobx';
import { AppFactory } from '@app/app-factory';
import { bugsnagNotify } from '@app/notification-service';
import { createLogger } from '@common/log';
import { UserManager } from '@core/models/user-manager';
// import { runOnboardingDialog } from 'components/ui/onboarding/onboarding-dialogs';
// import { SmartPauseTip } from 'components/ui/onboarding/smart-pause-tip';
// import { isStudyModel } from 'player/models/player-model-handle';
// import { StudyFlowTip } from 'components/ui/onboarding/study-flow-tip';
import { PlayerModel } from 'player/models/player-model';
import { PlayerKind } from '@common/misc-types';
import { RedactionMode } from 'player/models/redaction-modes';
import { ChapterCatalogData } from '@core/models/catalog';
import { StudyModel } from 'study/models/study-model';
import { track } from '@app/track';
import { didAchieve } from './achievements';

const log = createLogger('onboarding-service');

// beware, keys must be added here and to the TipKey type below
export const tipKeys = [
  'speedControl',
  'translation',
  'addToVocab',
  'savedFirstVocabModal',
  'vocabList',
  'smartPauseDialog',
  'smartPauseTip',
  'studyFlow',
  'naturalListen',
  'redactionMenuDialog',
  'redactionMenuTip',
  'firstVocabAdded',
  'storyInterstitial',
  // 'onboardingInitiated', // we're now only care if it's complete
  'onboardingComplete',
  'skipSoundbitesToChapter',
  'soundbitesModal',
  'completedStoryCta', // guards the end-of-story CTA from being seen more than once
  'onboardingCompleteModal',
  'pauseForHelpDialog',
  'tooLongVocabTip',

  'darkModeModal',

  'vocabReviewTip',
  'vocabReviewWithAdditionsTip',

  'hotspot:inlineTranslation',
  'hotspot:inlineNotation',
] as const;

export type TipKey = typeof tipKeys[number];

export class OnboardingService {
  userManager: UserManager;

  showSpeedControlTip = false;
  showTranslationTip = false;
  showAddToVocabTip = false;
  showVocabListTip = false;
  showNaturalListenTip = false;
  showRedactionMenuDialog = false;
  showRedactionMenuTip = false;
  showSmartPauseDialogForNewUsers = false;
  showSmartPauseDialogForExistingUsers = false;
  showSmartPauseTip = false;
  showSkipSoundbitesToChapterTip = false;
  showSoundbitesModalTip = false;
  showPauseForHelpDialog = false;

  currentTipKey: TipKey | null = null;

  // needed to handle player resume after speed control tip resolved
  pendingResumeFn: () => void;

  private constructor() {
    this.userManager = AppFactory.root.userManager;
    makeObservable(this, {
      showSpeedControlTip: observable,
      showTranslationTip: observable,
      showAddToVocabTip: observable,
      showVocabListTip: observable,
      showNaturalListenTip: observable,
      showRedactionMenuTip: observable,
      showRedactionMenuDialog: observable,
      showSmartPauseDialogForNewUsers: observable,
      showSmartPauseDialogForExistingUsers: observable,
      showSmartPauseTip: observable,
      showSkipSoundbitesToChapterTip: observable,
      showSoundbitesModalTip: observable,
      showPauseForHelpDialog: observable,
      currentTipKey: observable,
    });

    this.speedControlTipDismissed = this.speedControlTipDismissed.bind(this);
    // this.translationTipDismissed = this.translationTipDismissed.bind(this);
    this.addToVocabTipDismissed = this.addToVocabTipDismissed.bind(this);
    this.vocabListTipDismissed = this.vocabListTipDismissed.bind(this);
    this.naturalListenTipDismissed = this.naturalListenTipDismissed.bind(this);
    this.redactionMenuTipDismissed = this.redactionMenuTipDismissed.bind(this);
    this.redactionMenuDialogDismissed =
      this.redactionMenuDialogDismissed.bind(this);
    this.onSoundbitesSkipDismissed = this.onSoundbitesSkipDismissed.bind(this);
    this.onSoundbitesModalTipDismissed =
      this.onSoundbitesModalTipDismissed.bind(this);

    this.smartPauseDialogDismissed = this.smartPauseDialogDismissed.bind(this);
    this.smartPauseTipDismissed = this.smartPauseTipDismissed.bind(this);

    this.dismissPauseForHelpDialog = this.dismissPauseForHelpDialog.bind(this);
  }

  isDismissed(key: TipKey): boolean {
    return this.userManager.userData.userSettings.tipIsDismissed(key);
  }

  dismiss(key: TipKey): void {
    this.userManager.userData.userSettings.dismissTip(key);
  }

  resetOnboardingState(): void {
    this.userManager.userData.userSettings.resetAllTips();
  }

  // get isOnboardingActive(): boolean {
  //   return (
  //     // this.isDismissed('onboardingInitiated') &&
  //     !this.isDismissed('onboardingComplete')
  //   );
  // }

  get isLegacyOnboardingComplete(): boolean {
    return this.isDismissed('onboardingComplete');
  }

  get isInterstitialOnboardingComplete(): boolean {
    return didAchieve(['video:recap']) || this.isLegacyOnboardingComplete;
  }

  //
  // Speed control tip
  //
  onPlay({
    model,
    resumeFn,
  }: {
    model: PlayerModel;
    resumeFn: () => void;
  }): boolean {
    /// this constants are just for readability
    const CONTINUE_PLAYBACK = false;
    const INTERRUPT_PLAYBACK = true;

    // const isStudyPlayer = model.playerKind === PlayerKind.STUDY;
    const isSoundbitePlayer = model.playerKind === PlayerKind.SOUNDBITE;

    // if (isStudyPlayer) {
    // if (!this.isDismissed('pauseForHelpDialog')) {
    //   /// TODO: this condition may be unnecessary in the future.
    //   if (this.isOnboardingComplete) {
    //     this.dismiss('pauseForHelpDialog');
    //     return CONTINUE_PLAYBACK;
    //   }

    //   this.showPauseForHelpDialog = true;
    //   return INTERRUPT_PLAYBACK;
    // }
    // }

    if (isSoundbitePlayer) {
      if (this.isDismissed('speedControl')) {
        return CONTINUE_PLAYBACK;
      }

      if (model.transportState.playbackRate !== 0.8) {
        log.info('automatically dismissing speed control tip');
        const { userSettings } = AppFactory.root.userManager.userData;
        userSettings.dismissTip('speedControl');
        return CONTINUE_PLAYBACK;
      } else {
        log.info('triggering speed control tip');
        this.pendingResumeFn = resumeFn;
        this.showSpeedControlTip = true;
        return INTERRUPT_PLAYBACK; // signal to abort operation
      }
    }

    return CONTINUE_PLAYBACK;
  }

  // speedControlTipResolved(action: OnboardingDialogAction): void {
  speedControlTipDismissed(): void {
    this.showSpeedControlTip = false;
    const { userSettings } = AppFactory.root.userManager.userData;
    // userSettings.handleTipAction('speedControl', action);
    userSettings.dismissTip('speedControl');
    if (this.pendingResumeFn) {
      this.pendingResumeFn();
    } else {
      bugsnagNotify(
        new Error('speedControlTipResolved: missing pendingResumeFn')
      );
    }
    this.pendingResumeFn = undefined;
  }

  dismissPauseForHelpDialog(model: PlayerModel) {
    this.showPauseForHelpDialog = false;
    const { userSettings } = AppFactory.root.userManager.userData;
    userSettings.dismissTip('pauseForHelpDialog');
    model.player.play();
  }

  //
  // Translation tip
  //
  onRevealAnswer(): void {}

  // didShowTranslation(): void {
  //   // this.showTranslationTip = false;
  //   const { userSettings } = AppFactory.root.userManager.userData;
  //   // userSettings.handleTipAction('translation', action);
  //   userSettings.dismissTip('translation');
  // }

  // get shouldSHowTranslationTip(): boolean {
  //   if (this.isDismissed('translation')) {
  //     return false;
  //   }
  //   return this.showTranslationTip;
  // }

  //
  // Add to vocab tip
  //
  onVocabListSeen(): void {
    if (this.isDismissed('addToVocab')) {
      return;
    }
    log.info('triggering add to vocab tip');
    setTimeout(() => {
      this.currentTipKey = 'addToVocab';
      // runOnboardingDialog('addToVocab', <AddToVocabTip />).catch(bugsnagNotify);
      // this.showAddToVocabTip = true;
    }, 500);
  }

  // addToVocabTipResolved(action: OnboardingDialogAction): void {
  addToVocabTipDismissed(): void {
    // this.showAddToVocabTip = false;
    this.currentTipKey = null;
    const { userSettings } = AppFactory.root.userManager.userData;
    // userSettings.handleTipAction('addToVocab', action);
    userSettings.dismissTip('addToVocab');
  }

  //
  // Vocab list tip
  //
  onVocabAdded(): void {
    if (!this.isDismissed('savedFirstVocabModal')) {
      this.currentTipKey = 'savedFirstVocabModal';
    }
  }

  // vocabListTipResolved(action: OnboardingDialogAction): void {
  vocabListTipDismissed(): void {
    this.showVocabListTip = false;
    const { userSettings } = AppFactory.root.userManager.userData;
    // userSettings.handleTipAction('vocabList', action);
    userSettings.dismissTip('vocabList');
  }

  //
  // Too long vocab tip
  //
  onTooLongVocab(): void {
    if (this.isDismissed('tooLongVocabTip')) {
      return;
    }

    this.currentTipKey = 'tooLongVocabTip';
  }

  //
  // Smart pause tip
  //

  onSmartPause(): void {}

  //
  // Study flow tip - OBSOLETE
  //
  onStoryDetail(): void {
    return; // not relevant with new onboarding flow
  }

  //
  // Natural listen mode tip
  //
  onNaturalListenLaunch(): void {
    if (this.isDismissed('naturalListen')) {
      return;
    }
  }

  // onResolveNaturalListenTip(action: OnboardingDialogAction): void {
  naturalListenTipDismissed(): void {
    this.showNaturalListenTip = false;
    const { userSettings } = AppFactory.root.userManager.userData;
    // userSettings.handleTipAction('naturalListen', action);
    userSettings.dismissTip('naturalListen');
  }

  onSoundbitePlayerOpened(): void {
    const isOnboardingComplete = this.isDismissed('onboardingComplete');

    if (isOnboardingComplete && !this.isDismissed('darkModeModal')) {
      // console.log('triggering dark mode tip');
      this.currentTipKey = 'darkModeModal';
    }
  }

  //
  // Redaction menu
  //
  // shown when study player opened at start of chapter and speed control tip
  // already seen and dismissed. will suppress auto-open of chapter notes.
  // (relying on the start-of-chapter filter checked at the screen level)
  onStudyPlayerOpened(model: StudyModel): boolean {
    if (
      // speed control tip must be seen first
      // @elliottjf is this condition still relevant?
      !this.isDismissed('speedControl')
    ) {
      return;
    }

    // https://linear.app/jiveworld/issue/ENG-4160/move-smartpause-onboarding-tip-earlier-and-before-x-ray-tip
    const { playerSettings } = AppFactory.root.userManager.userData;

    const smartPauseDialogNotDismissed = !this.isDismissed('smartPauseDialog');
    const smartPauseDisabled = playerSettings.smartPauseEnabled === false;
    const isOnboardingComplete = this.isDismissed('onboardingComplete');
    const isFluentListenMode = model.fluentListenMode;

    if (
      isOnboardingComplete &&
      this.isDismissed('redactionMenuTip') &&
      !this.isDismissed('darkModeModal') &&
      model.playerKind === PlayerKind.STUDY
    ) {
      // console.log('triggering dark mode tip');
      this.currentTipKey = 'darkModeModal';
      return true;
    }

    /// Block for both smart pauses
    if (smartPauseDialogNotDismissed && smartPauseDisabled) {
      if (isOnboardingComplete) {
        playerSettings.enableSmartPause();
        setTimeout(() => {
          this.showSmartPauseDialogForExistingUsers = true;
        }, 500);
        return true;
      } else if (isFluentListenMode) {
        playerSettings.enableSmartPause();
        setTimeout(() => {
          this.showSmartPauseDialogForNewUsers = true;
        }, 500);
        return true;
      }
    }
    /// https://jiveworld.slite.com/app/docs/UIQLBCIm2PGnzx/Onboarding-tip-updates
    const redactionConditions =
      !this.isDismissed('redactionMenuDialog') &&
      model.chapter.position > 1 &&
      model.redactionMode === RedactionMode.SHOW_ALL;

    if (redactionConditions) {
      setTimeout(() => {
        model.setRedactionMode(RedactionMode.SHOW_SOME);
        this.showRedactionMenuDialog = true;
      }, 500);
      return true; // signal to suppress auto-show of chapter notes
    }

    /// not sure if somethign like this is even necessary :S
    if (!this.isDismissed('redactionMenuTip')) {
      return true;
    }
  }

  redactionMenuDialogDismissed(): void {
    this.showRedactionMenuDialog = false;
    const { userSettings } = AppFactory.root.userManager.userData;
    userSettings.dismissTip('redactionMenuDialog');
    setTimeout(() => {
      this.showRedactionMenuTip = true;
    }, 500);
  }

  redactionMenuTipDismissed(): void {
    this.showRedactionMenuTip = false;
    const { userSettings } = AppFactory.root.userManager.userData;
    userSettings.dismissTip('redactionMenuTip');
  }

  smartPauseDialogDismissed(): void {
    this.showSmartPauseDialogForNewUsers = false;
    this.showSmartPauseDialogForExistingUsers = false;
    const { userSettings } = AppFactory.root.userManager.userData;
    userSettings.dismissTip('smartPauseDialog');
    setTimeout(() => {
      this.showSmartPauseTip = true;
    }, 500);
  }

  smartPauseTipDismissed(): void {
    this.showSmartPauseTip = false;
    const { userSettings } = AppFactory.root.userManager.userData;
    userSettings.dismissTip('smartPauseTip');
  }

  onChapterComplete({ chapter }: { chapter: ChapterCatalogData }): void {
    // if this particular tip has been dismissed, ignore
    if (this.isDismissed('skipSoundbitesToChapter')) {
      return;
    }

    // if the chapter has no soundbites or all the soundbites are complete, ignore
    if (
      chapter.chapterSoundbites.length === 0 ||
      chapter.allChapterSoundbitesCompleted ||
      chapter.position < 3
    ) {
      return;
    }

    // otherwise, show the tip
    this.showSkipSoundbitesToChapterTip = true;
  }

  onSoundbitesSkipDismissed(): void {
    this.showSkipSoundbitesToChapterTip = false;
    const { userSettings } = AppFactory.root.userManager.userData;
    userSettings.dismissTip('skipSoundbitesToChapter');
    log.info('onSoundbitesSkipDismissed');

    /// August 2, 2024. We deisabled this tip

    // window.setTimeout(() => {
    // if (this.isDismissed('soundbitesModal')) {
    //   log.info('soundbitesModal already dismissed');
    //   return;
    // }
    // else {
    // log.info('triggering soundbitesModal tip');
    // this.showSoundbitesModalTip = true;
    // }
    // }, 300);
  }

  onSoundbitesModalTipDismissed(): void {
    this.showSoundbitesModalTip = false;
    const { userSettings } = AppFactory.root.userManager.userData;
    userSettings.dismissTip('soundbitesModal');
  }

  onStoryCompleteWithoutVocabReview(didAddVocab = false): void {
    if (
      this.isDismissed('vocabReviewTip') ||
      this.isDismissed('vocabReviewWithAdditionsTip')
    ) {
      return;
    }

    this.currentTipKey = didAddVocab
      ? 'vocabReviewWithAdditionsTip'
      : 'vocabReviewTip';
  }

  onDismissVocabReviewTip({
    storySlug,
    vocabCount,
  }: {
    storySlug: string;
    vocabCount: number;
  }): void {
    this.dismiss('vocabReviewTip');
    this.dismiss('vocabReviewWithAdditionsTip');
    this.currentTipKey = null;
    track('story__presented_review_vocab_tip', {
      storySlug,
      vocabCount,
    });
  }

  /// Singleton
  /// ==========
  private static _instance: OnboardingService;

  static get instance() {
    if (!OnboardingService._instance) {
      OnboardingService._instance = new OnboardingService();
    }
    return OnboardingService._instance;
  }
}

export function isDismissed(key: TipKey): boolean {
  return OnboardingService.instance.isDismissed(key);
}

export function isCurrentTip(key: TipKey): boolean {
  return OnboardingService.instance.currentTipKey === key;
}

export function dismissOnboardingTip(key: TipKey): void {
  OnboardingService.instance.dismiss(key);
}

export function dismissCurrentTip(): void {
  OnboardingService.instance.dismiss(OnboardingService.instance.currentTipKey);
  OnboardingService.instance.currentTipKey = null;
}

(window as any).OnboardingService = () => OnboardingService.instance;
