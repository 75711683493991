import * as React from 'react';

import { useNavigate } from 'react-router-dom';
import { AppFactory } from 'app/app-factory';
import { bugsnagNotify } from '@app/notification-service';
import { signInPath } from 'components/nav/path-helpers';

// import { createLogger } from '@common/log';
// const log = createLogger('sign-in-password-screen');

export const SignInGoogleScreen = () => {
  const navigate = useNavigate();

  React.useEffect(() => {
    AppFactory.root.localState
      .storeGoogleAuthEnabled(true)
      .then(() => navigate(signInPath()))
      .catch(bugsnagNotify);
  }, [navigate]);

  return <></>;
};
