import * as React from 'react';
import { Modal } from 'naan/primitives/modals';
import { Story } from '@core/models/story-manager';
import { styled } from '@naan/stitches.config';
import { ArrowRightExtraSmallIcon } from '@naan/icons/arrow-right-icon';
import { DatesVoicesCredits } from './story-voices-credits';

import __ from '@core/lib/localization';

const ModalTrigger = styled('div', {
  display: 'flex',
  my: '$space$4',
  flexDirection: 'row',
  alignItems: 'center',
  textStyle: 'small-text',
  cursor: 'default',
});

export const StoryVoicesCreditsModal = ({ story }: { story: Story }) => (
  <Modal
    heading={story.title}
    trigger={
      <ModalTrigger>
        {__('View credits and resources', 'viewCreditsAndResources')}&nbsp;{' '}
        <ArrowRightExtraSmallIcon />
      </ModalTrigger>
    }
  >
    <DatesVoicesCredits story={story} />
  </Modal>
);
