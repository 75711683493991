import * as React from 'react';
import Media from 'react-media';
import { breakpoints } from 'naan/tokens/breakpoints';
import { isFunction } from 'lodash';

type RenderFunction = () => React.ReactNode;

type ResponsiveProps = {
  renderDefault?: RenderFunction;
  renderSmallAndUp?: RenderFunction;
  renderMediumAndUp?: RenderFunction;
  renderLargeAndUp?: RenderFunction;
  renderExtraLargeAndUp?: RenderFunction;
};

type BreakpointRendererPair = [
  keyof typeof breakpoints,
  keyof Omit<ResponsiveProps, 'renderDefault'>
];

const mediaMap: BreakpointRendererPair[] = [
  ['small', 'renderSmallAndUp'],
  ['medium', 'renderMediumAndUp'],
  ['large', 'renderLargeAndUp'],
  ['extraLarge', 'renderExtraLargeAndUp'],
];

export const Responsive = ({
  renderDefault = () => null,
  ...rendererFunctions
}: ResponsiveProps) => {
  return (
    <Media queries={breakpoints}>
      {matches => {
        // inverted loop, otherwise smallest always win
        for (let i = mediaMap.length - 1; i >= 0; i--) {
          const [breakpoint, rendererName] = mediaMap[i];
          const renderer = rendererFunctions[rendererName];
          if (matches[breakpoint] && isFunction(renderer)) {
            return renderer();
          }
        }
        return renderDefault();
      }}
    </Media>
  );
};
