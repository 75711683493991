// todo: confirm if we can clean this all up by removing `_`s from all the punctuation matchers

import { elipsis, emdash } from '@utils/content-string-utils';

// should probably think of (and name)  `_`s as "markdown" not "punctuation"
export const noQuotesOrUnderscorePunctuation = `!()+,\\-./:;<=>?[\\]^\`{|}~¡¿—–${emdash}${elipsis}`; // !()+,\-./:;<=>?[\]^_`{|}~¡¿—–
export const noQuotesPunctuation = `_${noQuotesOrUnderscorePunctuation}`; // `!()+,\\-./:;<=>?[\\]^_\`{|}~¡¿—–`; // !()+,\-./:;<=>?[\]^_`{|}~¡¿—–
export const noUnderscorePunctuation = `'"${noQuotesOrUnderscorePunctuation}`; // `!()+,\\-./:;<=>?[\\]^_\`{|}~¡¿—–`; // !()+,\-./:;<=>?[\]^_`{|}~¡¿—–
export const punctuation = `_${noUnderscorePunctuation}`; // !"'()+,-./:;<=>?[]^_`{|}~¡¿—–

// const punctuationRegexOld = /[!"'()+,\-./:;<=>?[\]^_`{|}~¡¿—–]/g;
export const punctuationRegex = new RegExp(`[${punctuation}]`, 'g');
// const trailingPunctuationRegexOld = /([!"'()+,\-./:;<=>?[\]^_`{|}~¡¿—–]+)\s*$/g;
export const trailingPunctuationRegex = new RegExp(
  `([${punctuation}]+)\\s*$`,
  'g'
);

export const trailingPunctuationExceptUnderscoreRegex = new RegExp(
  `([${noUnderscorePunctuation}]+)\\s*$`,
  'g'
);

// const leadingPunctuationRegexOld = /^([!"'()+,\-./:;<=>?[\]^_`{|}~¡¿—–]+)\s*/g;
export const leadingPunctuationRegex = new RegExp(
  `^([${punctuation}]+)\\s*`,
  'g'
);

export const leadingPunctuationExceptUnderscoreRegex = new RegExp(
  `^([${noUnderscorePunctuation}]+)\\s*`,
  'g'
);

// const allPunctuationRegexOld = /[!"'()+,\-./:;<=>?[\]^_`{|}~¡¿—–]/g;
export const allPunctuationRegex = new RegExp(`[${punctuation}]`, 'g');
export const whitespaceRegex = /\s+/g;

export const splitLines = (text: string): string[] => text.split(/\r?\n/);
export const splitLinesFiltered = (text: string): string[] => {
  return splitLines(text).filter(l => l);
};

export const pathTail = (text: string): string => {
  const paths = text.split('/');
  return paths[paths.length - 1];
};

export function stripTrailingPunctuation(str: string) {
  if (!str) {
    return str;
  }
  return str.replace(trailingPunctuationRegex, '');
}

export function stripTrailingPunctuationExceptUnderscore(str: string) {
  if (!str) {
    return str;
  }
  return str.replace(trailingPunctuationExceptUnderscoreRegex, '');
}

export function stripLeadingPunctuation(str: string) {
  if (!str) {
    return str;
  }
  return str.replace(leadingPunctuationRegex, '');
}

export function stripLeadingPunctuationExceptUnderscore(str: string) {
  if (!str) {
    return str;
  }
  return str.replace(leadingPunctuationExceptUnderscoreRegex, '');
}

export function trimPunctuation(str: string) {
  str = stripTrailingPunctuation(str);
  return stripLeadingPunctuation(str);
}

export function trimPunctuationExceptUnderscore(str: string) {
  str = stripTrailingPunctuationExceptUnderscore(str);
  return stripLeadingPunctuationExceptUnderscore(str);
}
