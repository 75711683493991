import * as React from 'react';

export function SnailIcon({ width = 24, height = 24, color = 'currentColor' }) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.1507 6.30039C22.6816 6.30039 22.3017 6.73032 22.3017 7.25991C22.3017 7.4757 22.3668 7.67234 22.4727 7.83268L21.247 10.2093C21.1066 10.2068 20.9521 10.2159 20.7771 10.2437V8.14492C21.0821 7.99885 21.2956 7.65781 21.2956 7.26C21.2956 6.73041 20.9159 6.30048 20.4468 6.30048C19.9777 6.30048 19.5976 6.73041 19.5976 7.26C19.5976 7.65781 19.8117 7.99885 20.1164 8.14492V10.4161C19.2962 10.6997 18.5526 10.86 16.875 12.1486C17.0843 11.7547 18 10.2437 15.8438 10.3118C15.4148 7.20168 13.5 5 9.69785 5C5 5 2.5 7.91088 2.5 11.5C2.5 11.7194 2.51024 11.9349 2.52865 12.1486C2.52865 12.1486 0.361654 13.3925 2.46548 14.709C1.5527 15.2248 0.802868 16.6577 0.802868 16.6577C0.802868 16.6577 -1.24943 18.0074 1.20846 18H16.5251C16.5251 18 18.1207 18.0074 19.5421 15.6543C21.117 13.0486 23.245 12.8181 23.245 11.7285C23.245 10.9192 22.6155 10.4909 21.9589 10.3118L23.044 8.20803C23.0797 8.21264 23.1142 8.21961 23.1507 8.21961C23.62 8.21961 24 7.79046 24 7.26009C24 6.73041 23.62 6.30039 23.1507 6.30039Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}
