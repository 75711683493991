import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useStripeCheckout } from 'common/stripe-context';
import { objectFromUrlQuery } from 'common/object-from-url-query';
import { alertSevereError } from '@app/notification-service';
import { createLogger } from '@common/log';

const log = createLogger('checkout-result-target');

// import { launchCheckoutInterstitial } from './account-utils/launch-checkout-interstitial';

// console.log('LN', launchCheckoutInterstitial);

// (window as any).chk = launchCheckoutInterstitial;

// import { snap } from 'ts-state-tree/tst-core';

// const createDialogCreator = (reset: () => void) => {
//   const dismiss = () => {
//     reset();
//     DialogService.closeDialog();
//   };

//   const dismissAnd = (fn: () => void | boolean) => () => {
//     if (fn() !== false) {
//       dismiss();
//     }
//   };

//   return ({
//     body,
//     cancelButtonLabel = 'Cancel',
//     okButtonAction = () => {},
//     okButtonLabel = 'OK',
//     showCancelButton = true,
//   }: Pick<
//     DialogProps,
//     | 'body'
//     | 'cancelButtonLabel'
//     | 'okButtonAction'
//     | 'okButtonLabel'
//     | 'showCancelButton'
//   >) => {
//     DialogService.openDialog({
//       body,
//       okButtonLabel,
//       okButtonAction: dismissAnd(okButtonAction),
//       cancelButtonLabel,
//       cancelButtonAction: dismiss,
//       showCancelButton,
//     });
//   };
// };

export const CheckoutResultTarget = observer((): any => {
  /// TODO: @elliottjf we still need to figure out how are we going to do this, right?
  // const { accountData } = userManager;
  // const createDialog = createDialogCreator(resetCheckoutResult);
  const stripeCheckout = useStripeCheckout();

  // this is for stripe auto redirect
  // if there's a checkout_token key with a valid stripe key we will
  // autoredirect to stripe.
  useEffect(() => {
    const { checkoutToken = null } = objectFromUrlQuery();
    if (checkoutToken) {
      stripeCheckout(checkoutToken)
        .then(result => log.debug('checkout result', result))
        .catch(error => {
          alertSevereError({ error, note: 'CheckoutResultTarget.useEffect' });
        });
    }
  }, [stripeCheckout]);

  // useEffect(() => {
  //   if (!checkoutResult) {
  //     // DialogService.closeDialog();
  //     return;
  //   }

  //   const { interstitialMessageKey, stripeSessionId, ...params } =
  //     checkoutResult;

  //   /**
  //    * utility function to redirect to stripe checkout
  //    */
  //   const checkout = () =>
  //     stripeCheckout(stripeSessionId)
  //       .then(result => {
  //         // eslint-disable-next-line no-console
  //         console.log('RESULT', result);
  //       })
  //       .catch(err => {
  //         // eslint-disable-next-line no-console
  //         console.log('ERR', err);
  //       });

  //   /**
  //    * This is the success condition.
  //    * We will redirect to stripe checkout without user input.
  //    */
  //   if (interstitialMessageKey === 'none') {
  //     checkout();
  //     return;
  //   }
  // }, [
  //   checkoutResult,
  //   createDialog,
  //   accountData.fullAccessUntil,
  //   userManager.cancelAutoRenew,
  //   stripeCheckout,
  // ]);

  return null;
});
