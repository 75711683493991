import * as React from 'react';
import { FeedbackControl, FeedbackControlProps } from './feedback-control';
import { Button } from '@naan/primitives/button';
import { FeedbackIcon } from '@naan/icons/feedback-icon';
import { observer } from 'mobx-react';
import { styled } from '@naan/stitches.config';

const ButtonWrapper = styled('div', {
  '&.dark': {
    opacity: 0.6,
  },
});

export const FeedbackButton = observer(
  ({
    label,
    presentation = 'light',
    ...props
  }: Omit<FeedbackControlProps, 'renderControl'> & {
    label: string;
    presentation?: 'dark' | 'light';
  }) => {
    const isDark = presentation === 'dark';
    return (
      <FeedbackControl
        {...props}
        renderControl={({ openDialog }) => (
          <ButtonWrapper className={isDark ? 'dark' : ''}>
            <Button
              presentation={isDark ? 'whiteTransparent' : 'grayTransparent'}
              size="small"
              label={label}
              leftIcon={<FeedbackIcon />}
              onClick={openDialog}
            />
          </ButtonWrapper>
        )}
      />
    );
  }
);
