import { ModelTreeNode } from 'ts-state-tree/tst-core';

export class Author extends ModelTreeNode {
  static CLASS_NAME = 'Author' as const;

  name: string = null;
  title: string = null;
  avatarUrl: string = null;
  bio: string = null;

  static create(snapshot: any) {
    return super.create(Author, snapshot) as Author;
  }
}
