import * as React from 'react';
import { styled } from '@naan/stitches.config';

const MenuItemWrapper = styled('li', {
  $$shortcutColor: '$colors$textSecondary',
  $$shortcutBorderColor: '$colors$black-alpha-10',

  display: 'flex',
  borderTop: '1px solid $$borderColor',

  '.dark &': {
    $$shortcutColor: '$colors$globalWhite-alpha-60',
    $$shortcutBorderColor: '$colors$globalWhite-alpha-10',
  },

  '& > button': {
    all: 'unset',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'flex-start',
    padding: '16px 18px',
    transition: 'all 0.12s ease',
    color: '$$buttonTextColor',
    '&[disabled]': {
      opacity: 0.5,
    },

    '&:active': {
      backgroundColor: '$$buttonActiveBackgroundColor',
    },

    '& .icon': {
      marginLeft: -16,
      marginRight: 12,
      '& svg': {
        width: 24,
        height: 24,
      },
    },
    '& .label': {
      // textStyle: 'small-text-bold',
    },
    '& .key': {
      color: '$$shortcutColor',
      textStyle: 'small-text-bold',
      // display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '5px 10px',
      borderRadius: '$radii$medium',
      border: '1px solid $$shortcutBorderColor',
      display: 'none',
      margin: '-4px -18px -4px auto',
      '&:has(svg)': {
        padding: '7px',
      },
      '& + &': {
        marginLeft: 8,
      },

      '@medium': {
        '@media (hover: hover) and (pointer: fine)': {
          display: 'flex',
        },
      },
    },
  },
});

export const MenuActionItem = ({
  action,
  icon,
  label,
  disabled,
  shortcut,
}: {
  action: () => void;
  icon: React.ReactNode;
  label: string;
  disabled?: boolean;
  shortcut?: string;
}) => {
  return (
    <MenuItemWrapper>
      <button
        onClick={e => {
          e.preventDefault();
          action();
        }}
        disabled={disabled}
      >
        <span className="icon">{icon}</span>
        <span className="label">{label}</span>
        {shortcut ? <kbd className="key">{shortcut}</kbd> : null}
      </button>
    </MenuItemWrapper>
  );
};
