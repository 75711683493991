import { AppFactory } from '@app/app-factory';
import * as React from 'react';

// extend window with Reform
declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    Reform: any;
  }
}

function getIframe(targetId: string) {
  return document.querySelector(`${targetId} iframe`) as HTMLIFrameElement;
}

export const useReform = ({
  formUrl,
  onFormComplete,
  targetId = '#my-reform',
  emailFieldName,
  userIdFieldName,
}: {
  formUrl: string;
  targetId?: string;
  emailFieldName?: string;
  userIdFieldName?: string;
  onFormComplete: (data: any) => Promise<void>;
}) => {
  const { accountData } = AppFactory.root.userManager;

  React.useEffect(() => {
    const iframe = getIframe(targetId);

    if (iframe) {
      // prevent multiple forms in one page
      return;
    }

    const url = new URL(formUrl);

    // Add the user's email to the form
    if (accountData?.email && emailFieldName) {
      url.searchParams.append(emailFieldName, accountData.email);
    }

    if (accountData?.userId && userIdFieldName) {
      url.searchParams.append(userIdFieldName, accountData.userId);
    }

    // Create a Reform function in the global scope if it doesn't exist
    window.Reform =
      window.Reform ||
      function () {
        (window.Reform.q = window.Reform.q || []).push(arguments);
      };

    // Create the Reform form
    window.Reform('init', {
      url,
      target: targetId,
      background: 'transparent',
      onFormComplete,
    });

    /// Load the Reform embed script
    const script = document.createElement('script');
    script.id = 'reform-script';
    script.async = true;
    script.src = 'https://embed.reform.app/v2/embed.js';
    document.head.appendChild(script);

    /// Clean up function
    return () => {
      /// Remove the script when the component is unmounted
      document.head.removeChild(script);
      /// Remove the global Reform function
      delete window.Reform;
    };
  }, [
    formUrl,
    onFormComplete,
    targetId,
    accountData,
    emailFieldName,
    userIdFieldName,
  ]);
};
