import * as React from 'react';
import { FeedbackButton } from './feedback-button';
import { styled } from '@naan/stitches.config';

import __ from '@core/lib/localization';

const Wrapper = styled('div', {
  padding: 16,
  borderTop: '1px solid $colors$gray-100',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'sticky',
  top: '100vh',
});

const TextWrapper = styled('div', {
  textStyle: 'body-bold',
});

export const ClassroomFeedbackButton = () => {
  return (
    <Wrapper>
      <FeedbackButton
        metadata={{
          type: 'global',
          context: 'classrooms',
        }}
        dialogContext={
          <TextWrapper>
            {__('Jiveworld Classrooms', 'jiveworldClassrooms')}
          </TextWrapper>
        }
        label={__(
          'Give feedback on Jiveworld Classrooms',
          'giveFeedbackOnJiveworldClassrooms'
        )}
      />
    </Wrapper>
  );
};
