import { createMachine, assign, State } from 'xstate';

type TCloseEvent = {
  type: 'CLOSE';
};

type TOpenEvent = {
  type: 'OPEN';
  unitSlug: string;
};

type TSelectClassEvent = {
  type: 'SELECT_CLASS';
  classId: string;
};

type TShowNewClassEvent = {
  type: 'SHOW_NEW_CLASS';
};

type TAssignmentEvent =
  | TCloseEvent
  | TOpenEvent
  | TSelectClassEvent
  | TShowNewClassEvent;

type TAssignmentContext = {
  selectedClass: string | null;
  selectedUnit: string | null;
};

export type TAssignmentMachineState = State<
  TAssignmentContext,
  TAssignmentEvent,
  any,
  {
    value: any;
    context: TAssignmentContext;
  }
>;

const selectAction = assign<TAssignmentContext, TSelectClassEvent>({
  selectedClass: (_, { classId }) => {
    return classId;
  },
});

const openAction = assign<TAssignmentContext, TOpenEvent>({
  selectedUnit: (_, { unitSlug }) => {
    return unitSlug;
  },
});

export const assignmentMachine = createMachine<
  TAssignmentContext,
  TAssignmentEvent
>({
  id: 'assignmentMachine',
  initial: 'closed',
  context: {
    selectedClass: null,
    selectedUnit: null,
  },
  states: {
    closed: {
      on: {
        OPEN: {
          target: 'classSelector',
          actions: openAction,
        },
      },
    },
    classSelector: {
      on: {
        SELECT_CLASS: {
          target: 'classDetails',
          actions: selectAction,
        },
        SHOW_NEW_CLASS: { target: 'classPrompt' },
        CLOSE: { target: 'closed' },
      },
    },
    classPrompt: {
      on: {
        SELECT_CLASS: {
          target: 'classDetails',
          actions: selectAction,
        },
        CLOSE: { target: 'closed' },
      },
    },
    classDetails: {
      on: {
        CLOSE: { target: 'closed' },
      },
    },
  },
});
