import { NO_INDEX } from '@tikka/basic-types';
import { trimPunctuationExceptUnderscore } from '@tikka/misc/string-utils';
import {
  stripUnderscores,
  toUnicodePunctuationStr,
} from '@utils/content-string-utils';
import { isEmptyOrNA } from '@utils/string-utils';
import React from 'react';

type FormatAnnotatedString = {
  string: string;
  format: 'italic'; // just in case we want to support bold in the future
};

type MarksideChunk = string | FormatAnnotatedString;

// derived from tikka/client-data extractItalicsRanges
function markside(text: string): MarksideChunk[] {
  const words = text.split(' ');
  let transition: boolean;
  let beginItalics: boolean;
  let endItalics: boolean;
  let beganNormal: number = 0;
  let beganItalics: number = NO_INDEX;
  const parts: MarksideChunk[] = [];

  for (const [index, word] of words.entries()) {
    beginItalics = false;
    endItalics = false;
    let text = word;
    transition = text.includes('_');
    if (transition) {
      text = trimPunctuationExceptUnderscore(text);
      beginItalics = text.startsWith('_');
      endItalics = text.endsWith('_');
    }
    if (beginItalics && beganItalics === NO_INDEX) {
      beganItalics = index;
      if (index > beganNormal) {
        const chunk = stripUnderscores(
          words.slice(beganNormal, beganItalics).join(' ')
        );
        parts.push(chunk);
      }
      beganNormal = NO_INDEX;
    }
    if (endItalics && beganItalics !== NO_INDEX) {
      const begin = beganItalics;
      const end = index;
      const chunk = stripUnderscores(words.slice(begin, end + 1).join(' '));
      parts.push({ string: chunk, format: 'italic' });
      beganItalics = NO_INDEX;
      beganNormal = index + 1;
    }
  }
  if (beganNormal < words.length) {
    const chunk = stripUnderscores(words.slice(beganNormal).join(' '));
    parts.push(chunk);
  }

  return parts;
}

export const MarkSimple = ({ source }: { source: string }) => {
  if (isEmptyOrNA(source)) {
    return null;
  }
  const preprocessed = toUnicodePunctuationStr(source);
  const parts = markside(preprocessed);
  return (
    <>
      {parts.map((part, i) =>
        typeof part === 'string' ? (
          <React.Fragment key={i}> {part} </React.Fragment>
        ) : (
          <i key={i}>{part.string} </i>
        )
      )}
    </>
  );
};
