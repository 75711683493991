//
// chatgpt generated
//

export class SeededRandom {
  private seed: number;

  constructor(seed: number) {
    this.seed = seed;
  }

  // Linear Congruential Generator
  private next(): number {
    this.seed = (this.seed * 48271) % 2147483647;
    return this.seed / 2147483647;
  }

  public shuffleArray<T>(array: T[]): T[] {
    const shuffled = array.slice(); // Copy the array
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(this.next() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  }
}

/// Usage
// const seed = 12345; // Your seed
// const random = new SeededRandom(seed);
// const myList = [1, 2, 3, 4, 5];
// const shuffledList = random.shuffleArray(myList);

// console.log(shuffledList);
