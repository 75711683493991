import React from 'react';
import { AppFactory } from 'app/app-factory';
import { observer } from 'mobx-react';
import { CenterColumnLayout } from 'components/layouts/center-column-layout';
import { darkThemeSelector, styled } from '@naan/stitches.config';
import { LockSmallIcon } from '@naan/icons/lock-icon';
import { ArrowLeftIcon } from '@naan/icons/arrow-left-icon';
// import { AvatarIcon } from '@naan/icons/avatar-icon';
import { Button } from '@naan/primitives/button';
import {
  // createAccountPath,
  // dailySoundbitePath,
  homePath,
  signInPath,
  subscribePath,
} from 'components/nav/path-helpers';
import { ReturnNavState } from 'components/nav/return-nav-state';
import { useNavigate } from 'react-router-dom';
import { PlainMarkdownWithLinks } from '@naan/primitives/text';
import { Soundbite } from '@core/models/story-manager/soundbite';
import { getPageBackTracker } from '@app/track';
import __ from '@core/lib/localization';
import { createLogger } from '@common/log';

const log = createLogger('soundbite-gatekeeper');

const FLOW = 'spa-sb-gtkpr';

const Wrapper = styled('div', {
  width: '100%',
  height: '100%',
  position: 'fixed',
  display: 'flex',
  alignItems: 'flex-end',
  bottom: 0,
  left: 0,
  backgroundColor: 'rgba(0,0,0,.5)',
  zIndex: 100,
});

const BannerWrapper = styled('div', {
  background: '$red-50',
  [darkThemeSelector]: {
    background: '$blue-100',
  },
  width: '100%',
  paddingBottom: 'var(--sab)',

  '&  .banner-inner': {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '32px 16px 40px 16px',
    maxWidth: '600px',
    margin: '0 auto',

    '& > .title': {
      textStyle: 'small-heading',
      display: 'flex',
      marginBottom: '12px',
      alignItems: 'center',
      gap: '4px',
    },

    '& > .description': {
      color: '$colors$black-alpha-50',
      '& a': {
        color: 'inherit',
        textDecoration: 'underline',
      },
    },

    '& > .buttons': {
      display: 'flex',
      marginTop: '20px',
      '& > * + *': {
        marginLeft: 8,
      },
    },
  },
});

const LockedBanner = observer(
  ({
    title,
    description,
    renderButtons,
    icon,
  }: {
    title: React.ReactNode;
    description: React.ReactNode;
    renderButtons: () => React.ReactNode;
    icon?: React.ReactNode;
  }) => {
    return (
      <BannerWrapper>
        <CenterColumnLayout>
          <div className="banner-inner">
            <h3 className="title">
              {icon ? <span className="icon">{icon}</span> : null}
              {title}
            </h3>
            <div className="description">{description}</div>
            <div className="buttons">{renderButtons()}</div>
          </div>
        </CenterColumnLayout>
      </BannerWrapper>
    );
  }
);

const GoBackButton = () => {
  const navigate = useNavigate();
  const trackBack = getPageBackTracker();

  return (
    <Button
      presentation="grayLight"
      label={__('Back', 'back')}
      leftIcon={<ArrowLeftIcon />}
      onClick={() => {
        if (ReturnNavState.canPopFromStudy()) {
          trackBack();
          navigate(-1);
        } else {
          trackBack(homePath());
          navigate(homePath());
        }
      }}
    />
  );
};

// const AccountRequiredBanner = () => {
//   const navigate = useNavigate();
//   return (
//     <Wrapper>
//       <LockedBanner
//         icon={<AvatarIcon />}
//         title={__('Account required', 'accountRequired')}
//         description={
//           <PlainMarkdownWithLinks
//             source={__(
//               'Create a free account to revisit your completed Soundbites. Already have an account? [Sign in](%{url})',
//               'createAFreeAccountToRevisitYourCompletedSoundbites',
//               {
//                 url: signInPath({
//                   flow: FLOW,
//                 }),
//               }
//             )}
//           />
//         }
//         renderButtons={() => (
//           <>
//             <GoBackButton />

//             <Button
//               onClick={() =>
//                 navigate(
//                   createAccountPath({
//                     flow: FLOW,
//                   })
//                 )
//               }
//               presentation="teal"
//               label={__('Create account', 'createAccount')}
//             />
//           </>
//         )}
//       />
//     </Wrapper>
//   );
// };

const SubscribersOnlyBanner = ({
  promptToSignIn = false,
}: // promptToSoudbite = false,
{
  promptToSignIn?: boolean;
  // promptToSoudbite?: boolean;
}) => {
  const navigate = useNavigate();
  const parts: string[] = [];
  // if (promptToSoudbite) {
  //   parts.push(
  //     __(
  //       "To see this Soundbite you need a subscription — but today's Soundbite is always free.",
  //       'toSeeThisSoundbiteButTodayFree'
  //     )
  //   );
  // } else {
  parts.push(
    __(
      'A subscription is required to unlock all Soundbites and stories.',
      'aSubscriptionIsRequiredToUnlockAllSoundbitesAndStories'
    )
  );
  // }
  if (promptToSignIn) {
    parts.push(
      __('Already subscribed? [Sign in](%{url})', 'alreadySubscribedSignIn', {
        url: signInPath({
          flow: FLOW,
        }),
      })
    );
  }
  const source = parts.join(' ');
  return (
    <Wrapper>
      <LockedBanner
        icon={<LockSmallIcon />}
        title={__('Subscribers only', 'subscribersOnly')}
        description={<PlainMarkdownWithLinks source={source} />}
        renderButtons={() => (
          <>
            <GoBackButton />
            {/* {promptToSoudbite ? (
              <Button
                onClick={() =>
                  navigate(
                    dailySoundbitePath({
                      orientation: 'skip',
                      flow: FLOW,
                    })
                  )
                }
                presentation="teal"
                label={__("View today's Soundbite", 'viewTodaysSoundbite')}
              />
            ) : ( */}
            <Button
              onClick={() =>
                navigate(
                  subscribePath({
                    flow: FLOW,
                  })
                )
              }
              presentation="teal"
              label={__('Subscribe', 'subscribe')}
            />
            {/* )} */}
          </>
        )}
      />
    </Wrapper>
  );
};

const gateKeeperStatuses = {
  UNLOCKED: 'UNLOCKED',
  ACCOUNT_REQUIRED: 'ACCOUNT_REQUIRED',
  SUBSCRIBERS_ONLY: 'SUBSCRIBERS_ONLY',
  // LOGGED_OUT_SUBSCRIBERS_ONLY_PROMPT_SB:
  //   'LOGGED_OUT_SUBSCRIBERS_ONLY_PROMPT_SB',
  LOGGED_OUT_SUBSCRIBERS_ONLY: 'LOGGED_OUT_SUBSCRIBERS_ONLY',
} as const;

type GateKeeperStatus = keyof typeof gateKeeperStatuses;

export const getGatekeeperStatus = ({
  soundbite,
}: {
  soundbite: Soundbite;
}): GateKeeperStatus => {
  const { authenticated, fullAccess } = AppFactory.root.userManager;
  // const { membershipState } = accountData;
  // const { completedSoundbitesCount } = userData;
  const isFeatured = soundbite?.isFeatured;
  const hasBeenCompleted = soundbite?.completed;

  if (soundbite?.story?.trial) {
    return gateKeeperStatuses.UNLOCKED;
  }

  // allow masala preview through the gate
  if (window.location.pathname.includes('preview')) {
    return gateKeeperStatuses.UNLOCKED;
  }

  const queryParams = new URLSearchParams(window.location.search);

  // if (queryParams.has('account-required')) {
  //   return gateKeeperStatuses.ACCOUNT_REQUIRED;
  // }

  // for testing
  if (queryParams.has('sub-required')) {
    if (authenticated) {
      return gateKeeperStatuses.SUBSCRIBERS_ONLY;
    } else {
      return gateKeeperStatuses.LOGGED_OUT_SUBSCRIBERS_ONLY;
    }
  }

  if (isFeatured || fullAccess) {
    return gateKeeperStatuses.UNLOCKED;
  }

  if (authenticated) {
    if (hasBeenCompleted) {
      return gateKeeperStatuses.UNLOCKED;
    }
    return gateKeeperStatuses.SUBSCRIBERS_ONLY;
    // } else {
    //   if (hasBeenCompleted) {
    //     return gateKeeperStatuses.ACCOUNT_REQUIRED;
    //   }
  }

  // if (completedSoundbitesCount > 0) {
  return gateKeeperStatuses.LOGGED_OUT_SUBSCRIBERS_ONLY;
  // }

  // return gateKeeperStatuses.LOGGED_OUT_SUBSCRIBERS_ONLY_PROMPT_SB;
};

export const SoundbiteGatekeeper = observer(
  ({ soundbite }: { soundbite: Soundbite }) => {
    const status = React.useMemo(
      () => getGatekeeperStatus({ soundbite }),
      [soundbite]
    );

    switch (status) {
      case gateKeeperStatuses.UNLOCKED:
        return null;
      // case gateKeeperStatuses.ACCOUNT_REQUIRED:
      //   return <AccountRequiredBanner />;
      case gateKeeperStatuses.SUBSCRIBERS_ONLY:
        return <SubscribersOnlyBanner />;
      case gateKeeperStatuses.LOGGED_OUT_SUBSCRIBERS_ONLY:
        return <SubscribersOnlyBanner promptToSignIn />;
      // case gateKeeperStatuses.LOGGED_OUT_SUBSCRIBERS_ONLY_PROMPT_SB:
      //   return <SubscribersOnlyBanner /*promptToSoudbite*/ promptToSignIn />;
      default:
        log.error(
          `SoundbiteGatekeeper: unknown status: ${status} for soundbite: ${soundbite?.slug}`
        );
        return null;
    }
  }
);
