import * as React from 'react';

export function MoonIcon({ width = 24, height = 24, color = 'currentColor' }) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.2937 15.2937C21.1171 15.7498 19.8377 16 18.5 16C12.701 16 8 11.299 8 5.50002C8 4.16227 8.25017 2.88295 8.70628 1.7063C4.78226 3.22737 2 7.03878 2 11.5C2 17.299 6.70101 22 12.5 22C16.9612 22 20.7726 19.2178 22.2937 15.2937Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}
