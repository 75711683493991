import * as React from 'react';
import { styled } from 'naan/stitches.config';
import { Responsive } from 'naan/primitives/responsive';
import { useLayoutContext } from '.';
import { observer } from 'mobx-react';
// import { useNavigate } from 'react-router-dom';
import ReactDOM from 'react-dom';
import { useLocation } from 'react-router-dom';

export const StaticSidebarContainer = styled('div', {
  display: 'block',
  height: '100%',
});

export const FixedSidebarContainer = styled('div', {
  zIndex: 1100,
  display: 'hidden',
  position: 'fixed',
  top: 0,
  right: 0,
  bottom: 0,
  width: '$$sidebarWidth',
  willChange: 'transform',
  transition: 'transform',
  variants: {
    status: {
      open: {
        transitionDelay: '0.15s',
        transitionDuration: '0.25s',
        transform: `translateX(0)`,
      },
      closed: {
        transitionDelay: '0s',
        transitionDuration: '0.25s',
        transform: `translateX(100%)`,
      },
    },
  },
});

export const Backdrop = styled('div', {
  zIndex: 1099,
  backgroundColor: '$black-alpha-20',
  position: 'fixed',
  top: 0,
  right: 0,
  left: 0,
  bottom: 0,
  willChange: 'opacity',
  transition: 'opacity',
  variants: {
    status: {
      open: {
        display: 'block',
        transitionDuration: '0.3s',
        opacity: 1,
      },
      closed: {
        display: 'none',
        transitionDelay: '0.15s',
        transitionDuration: '0.25s',
        opacity: 0,
      },
    },
  },
});

function createDiv() {
  const id = 'sidebar-portal';
  const existingDiv = document.getElementById(id);
  if (existingDiv) {
    return existingDiv;
  }
  const element = document.createElement('div');
  document.body.appendChild(element);
  element.id = id;
  return element;
}

function Portal(props: any) {
  const node = React.useRef(createDiv());
  return ReactDOM.createPortal(props.children, node.current);
}

type SidebarProps = {
  children: React.ReactNode;
};

const ConditionalSidebar = observer(({ children }: SidebarProps) => {
  const { showingSidebar, toggleSidebar } = useLayoutContext();
  const location = useLocation();

  /// track page changes automatically, whenever location changes
  React.useEffect(() => {
    /// whenever we switch routes, we close the sidebar if open
    if (showingSidebar) {
      /// this is weird syntax, but it's valid JS!
      toggleSidebar?.();
    }
    /// we only want to run this when location changes and no other property.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  // (window as any).toggleSidebar = toggleSidebar;

  React.useLayoutEffect(() => {
    // this adds a special class to the body that prevent is from scrolling
    if (showingSidebar) {
      document.body.classList.add('__no-scroll');
    }
    return () => {
      document.body.classList.remove('__no-scroll');
    };
  }, [showingSidebar]);

  return (
    <Portal>
      <Backdrop
        status={showingSidebar ? 'open' : 'closed'}
        onClick={toggleSidebar}
      >
        <FixedSidebarContainer status={showingSidebar ? 'open' : 'closed'}>
          {children}
        </FixedSidebarContainer>
      </Backdrop>
    </Portal>
  );
});

export const SidebarContainer = (props: SidebarProps) => {
  return (
    <Responsive
      renderDefault={() => <ConditionalSidebar {...props} />}
      renderLargeAndUp={() => <StaticSidebarContainer {...props} />}
    />
  );
};
