import * as React from 'react';
import { AnswerState, SoundbiteModel } from 'soundbite/models/soundbite-model';
import { CountdownNotice } from 'components/countdown-notice';
import { observer } from 'mobx-react';

export const SoundbiteCountdownNotice = observer(
  ({ model }: { model: SoundbiteModel }) => {
    const { answerState: state } = model;

    if (state !== AnswerState.revealed) {
      return null;
    }

    return <CountdownNotice />;
  }
);
