import * as React from 'react';
import { observer } from 'mobx-react';
import { Button } from 'naan/primitives/button';
import { styled } from '@naan/stitches.config';
import classNames from 'classnames';
// import { useAutoanimate } from '@common/hooks/use-autoanimate';

import __ from 'core/lib/localization';

const Wrapper = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr auto',
  alignItems: 'center',
  gap: 12,
  transition: 'grid-template-columns 0.3s',
  padding: '14px 0',
  borderTop: '1px solid $gray-100',
  '&.editing': {
    gridTemplateColumns: '1fr',
  },

  '& .left': {
    overflow: 'hidden',
  },
  '& .value': {
    /// long emails were breaking the whole layout
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },

  '& .row-label': {
    textStyle: 'small-text-bold',
    color: '$textSecondary',
  },

  '& .form-container': {
    marginTop: 12,
  },
});

export const TableFieldWrapper = observer(
  ({
    label,
    children,
    value,
    testId,
    editing,
    onStartEditing,
  }: {
    label: React.ReactNode;
    children: React.ReactNode;
    value: React.ReactNode;
    testId?: string;
    editing: boolean;
    onStartEditing: () => void;
  }) => {
    // const parent = useAutoanimate();

    return (
      <Wrapper
        data-test-id={testId}
        className={classNames({ editing })}
        // ref={parent}
      >
        <div className="left">
          <div className="row-label">{label}</div>
          {editing ? null : <div className="value">{value}</div>}
          {editing ? <div className="form-container">{children}</div> : null}
        </div>
        {editing ? null : (
          <div className="right">
            <Button
              onClick={onStartEditing}
              label={__('Edit', 'edit')}
              size={'small'}
              presentation={'grayLight'}
            />
          </div>
        )}
      </Wrapper>
    );
  }
);

const FormButtonsWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'flex-start',
  marginTop: 12,
});

export const TableFieldButtonSet = observer(
  ({
    updateLabel,
    cancelAction,
  }: {
    updateLabel: string;
    cancelAction: () => void;
  }) => {
    return (
      <FormButtonsWrapper>
        <Button
          size="small"
          presentation="teal"
          data-test-id="submit-button"
          label={updateLabel}
        />
        <Button
          size="small"
          type={'button'}
          presentation={'tealTransparent'}
          css={{ $$buttonTextColor: '#999' }}
          onClick={cancelAction}
          data-test-id="cancel-button"
          label={__('Cancel', 'cancel')}
        />
      </FormButtonsWrapper>
    );
  }
);
