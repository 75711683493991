import * as React from 'react';

import { observer } from 'mobx-react';
import { styled } from '@naan/stitches.config';
import { useCurrentStory } from '../current-story-context';
import { VocabListModalContent } from './vocablist-content';
import { DialogHtmlContainer } from 'components/ui/dialog-html-container';
import { keynameForEvent } from 'lib/services/keyboard-service';

const Container = styled(DialogHtmlContainer, {
  width: 'min( 600px, calc(100% - 32px) )',
  borderRadius: '$radii$large',
  overflow: 'hidden',
});

export const VocabListModal = observer(
  ({ onDismiss }: { onDismiss: () => void }) => {
    const story = useCurrentStory();
    /// For some reason, keyvbordService doesn't work while this modal is opened
    React.useEffect(() => {
      const listener = (e: KeyboardEvent) => {
        const shortcutName = keynameForEvent(e);
        if (shortcutName === 'shift+v' || shortcutName === 'escape') {
          onDismiss();
        }
      };
      document.addEventListener('keydown', listener);
      return () => {
        document.removeEventListener('keydown', listener);
      };
    }, [onDismiss]);

    return (
      <Container
        /// this disables the backdrop click to dismiss
        /// because at this point in the tree we don't have access to the model to save.
        /// we can find a better way to slice the model stuff
        onDismiss={() => {}}
      >
        <VocabListModalContent
          story={story}
          progress={story.progress}
          onDismiss={onDismiss}
        />
      </Container>
    );
  }
);
