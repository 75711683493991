import * as React from 'react';
// import { PlainMarkdown } from '@naan/primitives/text';
import { styled } from 'naan/stitches.config';
import { observer } from 'mobx-react';
import {
  dismissCurrentTip,
  OnboardingService,
} from '@app/onboarding/onboarding-service';
import * as Dialog from '@naan/primitives/modals/dialog';

import __ from 'core/lib/localization';
import { SettingsIcon } from '@naan/icons/settings-icon';
import { presentSettingsModal } from 'components/settings';

const DarkModeDialog = styled(Dialog.Container, {
  backgroundColor: '$gray-800',
  color: '$white',
});

const BodyWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '16px 0 24px',
  gap: 16,
  '& .text': {
    textAlign: 'center',
    '& .title': {
      textStyle: 'body-bold',
    },
  },
});

const Moon = () => {
  return (
    <div>
      <svg
        width={80}
        height={80}
        viewBox="0 0 80 80"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M74.3127 50.979C70.3905 52.4993 66.1262 53.3332 61.667 53.3332C42.337 53.3332 26.667 37.6632 26.667 18.3332C26.667 13.8741 27.5009 9.60968 29.0213 5.6875C15.9412 10.7577 6.66699 23.4624 6.66699 38.3332C6.66699 57.6632 22.337 73.3332 41.667 73.3332C56.5378 73.3332 69.2425 64.059 74.3127 50.979Z"
          fill="white"
        />
      </svg>
    </div>
  );
};

function openSettings() {
  dismissCurrentTip();
  setTimeout(() => {
    presentSettingsModal();
  }, 100);
}

(window as any).openSettings = openSettings;

const ExistingUsersSmartPauseOnboardingDialog = observer(() => {
  return (
    <DarkModeDialog open={true}>
      <Dialog.Body>
        <BodyWrapper>
          <div className="figure">
            <Moon />
          </div>
          <div className="text">
            <div className="title">
              {__('Try the dark theme', 'tryTheDarkTheme')}
            </div>
            <div className="text">
              {__('Perfect for tired eyes.', 'perfectForTiredEyes')}
            </div>
          </div>
        </BodyWrapper>
      </Dialog.Body>
      <Dialog.ButtonsContainer direction="column">
        <Dialog.Button
          presentation="teal"
          label={__('Go to settings', 'goToSettings')}
          leftIcon={<SettingsIcon />}
          onClick={openSettings}
        ></Dialog.Button>
        <Dialog.Button
          presentation="gray"
          label={__('Maybe later', 'maybeLater')}
          onClick={dismissCurrentTip}
        ></Dialog.Button>
      </Dialog.ButtonsContainer>
    </DarkModeDialog>
  );
});

export const DarkModeOnboardingDialog = observer(() => {
  const { currentTipKey } = OnboardingService.instance;

  if (currentTipKey !== 'darkModeModal') {
    return null;
  }

  return <ExistingUsersSmartPauseOnboardingDialog />;
});
