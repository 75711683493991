import * as React from 'react';

export const KeyboardIcon = ({
  color = 'currentColor',
}: {
  color?: string;
}) => (
  <svg
    width={33}
    height={22}
    viewBox="0 0 33 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x={5} y={5} width={3} height={3} fill={color} />
    <rect x={10} y={5} width={3} height={3} fill={color} />
    <rect x={15} y={5} width={3} height={3} fill={color} />
    <rect x={20} y={5} width={3} height={3} fill={color} />
    <rect x={25} y={5} width={3} height={3} fill={color} />
    <rect x="7.5" y="9.5" width={3} height={3} fill={color} />
    <rect x="12.5" y="9.5" width={3} height={3} fill={color} />
    <rect x="17.5" y="9.5" width={3} height={3} fill={color} />
    <rect x="22.5" y="9.5" width={3} height={3} fill={color} />
    <rect x={6} y={15} width={21} height={2} fill={color} />
    <rect
      x={1}
      y={1}
      width={31}
      height={20}
      rx={2}
      stroke={color}
      strokeWidth={2}
    />
  </svg>
);
