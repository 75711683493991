import * as React from 'react';

export function StreakIcon({
  width = 24,
  height = 24,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.3767 5.19807C25.2992 15.1672 18.4045 21.2198 13.9669 23C18.2602 15.858 14.5302 11.1498 12.1286 9.68841C13.4569 12.2391 12.0693 15.1973 11.2094 16.3575C11.2332 14.6357 10.2508 13.1602 9.75659 12.6377C9.90484 14.7633 8.36303 16.4106 8.03688 18.5097C7.77596 20.1889 8.9758 22.2029 9.60833 23C-0.747798 18.9812 3.98706 11.4631 4.67652 10.3684L4.68642 10.3527C5.20826 9.52367 5.57592 7.65137 5.69452 6.81884C6.501 8.11546 6.95959 9.11272 7.08807 9.44928C9.29404 7.23865 10.2013 2.89533 10.3792 1C13.7949 1.85024 15.8546 6.36715 16.4575 8.51932C17.1691 7.81787 17.3668 6.01288 17.3767 5.19807Z"
        fill={color}
      />
    </svg>
  );
}
