import * as React from 'react';
import * as Sentry from '@sentry/react';
import { appConfig } from '@app/env';
import { ErrorBoundary } from 'components/error-boundary';

import __ from 'core/lib/localization';

const errorReportingEnabled = appConfig.sentry.enabled;

export const BugReportingBoundary = React.memo(
  ({ children }: { children: React.ReactNode }) => {
    if (errorReportingEnabled === true) {
      /// we will use Sentry's ErrorBoundary if errorReportingEnabled is true
      return (
        <Sentry.ErrorBoundary
          fallback={({ error, componentStack, resetError }) => (
            <div>
              <h1>{error.message}</h1>
              <button onClick={resetError}>
                {__('Try again', 'tryAgain')}
              </button>
            </div>
          )}
        >
          {children}
        </Sentry.ErrorBoundary>
      );
    }

    /// or use our own ErrorBoundary if errorReportingEnabled is false
    return <ErrorBoundary>{children}</ErrorBoundary>;
  }
);
