import React, { useEffect } from 'react';
import { createLogger } from '@common/log';
import { alertWarningError, bugsnagNotify } from '@app/notification-service';
import { AppFactory } from '@app/app-factory';
import __ from '@core/lib/localization';
import { appConfig } from '@app/env';
import {
  versionCheck,
  // @ts-expect-error
} from '@jw-spa-version';
import { RELOAD_PROMPTED_MESSAGE_TYPE } from 'pwa/shared';
import {
  fetchVersionTxt,
  showReloadToast,
  startUpdatePolling,
} from './update-checker';
import { messageSW, Workbox } from 'workbox-window';
import { reload } from '@core/lib/app-util';
import { track } from '@app/track';

const log = createLogger('sw:update-manager');

const swMode: string = '__SW_MODE__';
const promptEnabled = swMode === 'prompt';
log.info(
  `swMode: ${swMode}, promptEnabled: ${String(
    promptEnabled
  )}, build: ${versionCheck}`
);

// always attempt to listen for updates except for dev build
// export const updateManagerEnabled = import.meta.env.PROD;

let serviceWorkerRegistered: boolean = undefined;

export const isServiceWorkerRegistered = () => serviceWorkerRegistered;

export const UpdateManager = () => {
  // const serviceWorker = navigator.serviceWorker;

  useEffect(() => {
    log.debug(`inside useEffect - build: ${versionCheck}`);
    initializeUpdateManager().catch(
      bugsnagNotify /* errors should already be handled below */
    );
  }, []);

  return <></>;
};

const initializeUpdateManager = async () => {
  try {
    log.info(
      `initializeUpdateManager - nav.sw: ${String(
        hasServiceWorker()
      )}, sw.con: ${String(hasSwController())}, promptEnabled: ${String(
        promptEnabled
      )}`
    );
    if (hasServiceWorker()) {
      const wb = new Workbox('/sw.js');
      AppFactory.setWorkbox(wb);

      const firstTimeInstall = !navigator.serviceWorker?.controller;
      log.info(`firstTimeInstall: ${String(firstTimeInstall)}`);

      // we always want to refresh when we detected a new controller
      wb.addEventListener('controlling', async event => {
        try {
          const userManager = AppFactory.root?.userManager;
          log.debug(
            `received controlling event, isUpdate: ${String(event.isUpdate)}`
          );
          // if (event.isUpdate) { // beware, isUpdate not populated as expected
          if (firstTimeInstall) {
            log.info('initial install complete - offline ready');
            // if (userManager?.hasAdminAccess) {
            //   notifySuccess('[service worker: offline ready]');
            // }
          } else {
            log.info(
              `about to reload, will persist local - user email: ${String(
                userManager?.accountData?.email
              )}`
            );
            await userManager?.persistLocal();
            reload();
          }
        } catch (error) {
          alertWarningError({ error });
        }
      });

      if (promptEnabled) {
        wb.addEventListener('waiting', async event => {
          log.debug(
            `received waiting event, isUpdate: ${String(
              event.isUpdate
            )}, isExternal: ${String(event.isExternal)}`
          );
          track('update__received_waiting_event', {
            mode: 'sw',
            isUpdate: String(event.isUpdate),
            isExternal: String(event.isExternal),
          });

          // if (event.isUpdate) { // beware, isUpdate not populated as expected
          conditionallyPresentSwUpdateToast(wb);
          informPrompted().catch(error => alertWarningError({ error }));
        });
      }

      log.debug('before wb.register');
      await wb.register();
      serviceWorkerRegistered = true;
      log.debug('initializeUpdateManager - serviceWorkerRegistered');

      log.debug(
        `reg.active: ${!!wb.active}, sw.con: ${String(
          !!navigator.serviceWorker?.controller
        )}`
      );
    } else {
      serviceWorkerRegistered = false;
      log.warn(
        'initializeUpdateManager - service worker not found - using version.txt poll mode'
      );
    }
  } catch (error) {
    // note, this seems to fail on ios 15.7 with a "Job rejected for non app-bound domain" error
    // or that's possibly a casualy of testing with too many subdomains: https://bugs.webkit.org/show_bug.cgi?id=227531
    serviceWorkerRegistered = false;
    log.error(
      `initializeUpdateManager sw.reg flow failed: ${error} - will poll version.txt`
    );
    bugsnagNotify(error as Error);
    // alertWarningError({ error, note: 'UpdateManager.useEffect' });
  } finally {
    // adaptively polls either sw.js or version.txt
    startUpdatePolling();
  }
};

export const presentDeferredUpdateToast = () => {
  const { root, workbox } = AppFactory;
  if (!root) {
    log.warn(`presentDeferredUpdateToast - root not yet available`);
    return;
  }

  const mode = root.updatePromptPending;
  track('update__deferred_prompt_presented', { mode });
  root.updatePromptPending = null;
  if (mode === 'sw') {
    if (workbox) {
      conditionallyPresentSwUpdateToast(workbox);
    } else {
      bugsnagNotify(
        `presentDeferredUpdateToast, AppFactory.workbox unexpectedly missing`
      );
    }
  } else {
    showReloadToast();
  }
};

const conditionallyPresentSwUpdateToast = (wb: Workbox) => {
  log.debug('conditionallyPresentSwUpdateToast');
  const { root } = AppFactory;
  if (!root) {
    log.warn(`conditionallyPresentSwUpdateToast - root not yet available`);
    return;
  }

  if (!root.updatePromptEnabled) {
    log.warn(
      'presentSwUpdateToast while updatePrompt disabled - deferring, mode: sw'
    );
    track('system__update_prompt_deferred', { mode: 'sw' });
    root.updatePromptPending = 'sw';
    return;
  }

  fetchVersionTxt()
    .then(({ mismatched }) => {
      if (mismatched) {
        reallyPresentSwUpdateToast(wb);
      } else {
        track('update__extraneous_prompt_ignored', { mode: 'sw' });
        bugsnagNotify('update__extraneous_prompt_ignored');
      }
    })
    .catch(bugsnagNotify);
};

const reallyPresentSwUpdateToast = (wb: Workbox) => {
  log.debug('reallyPresentSwUpdateToast');
  const { toastService } = AppFactory;
  track('update__prompt_shown', { mode: 'sw' });

  toastService.open({
    message: __('This site has been updated', 'thisSiteHasBeenUpdated'),
    type: 'info',
    action: {
      label: __('Reload', 'reload'),
      callback: () => {
        setTimeout(() => {
          // seems possible to require this flow if an update is detected before
          // the initial install is complete
          log.warn(
            `presentSwUpdateToast: skip waiting didn't reload window yet - will trigger directly`
          );
          track('update__skip_waiting_timeout', { mode: 'sw' });
          reload();
          // not even the expected flow, so let's not expand permutations here
          // reloadOrNativeReset();
        }, 2000);
        log.debug(`before updateServiceWorker`);
        track('update__skip_waiting_pre', { mode: 'sw' });
        wb.messageSkipWaiting();
        track('update__skip_waiting_post', { mode: 'sw' });
      },
    },
    timeout: null,
  });
};

const informPrompted = async () => {
  // this didn't reliably return the installed and waiting SW
  let waitingSW = await AppFactory.workbox.getSW();
  log.info(`waitingSW: ${String(!!waitingSW)}`);

  if (!waitingSW) {
    // can't figure out how to access the registration through the documented workbox api
    // (it seems to be stuffed into a private property)
    const registration = await navigator.serviceWorker.getRegistration();
    log.info(`registration: ${String(!!registration)}`);
    // not yet sure when this can happen
    if (!registration) {
      throw Error('sw.register failed');
    }

    log.info(
      `has reg.waiting: ${String(
        !!registration.waiting
      )}, has reg.installing: ${String(!!registration.installing)}`
    );
    waitingSW = registration.waiting || registration.installing;
    log.info(`fallback waitingSW: ${String(!!waitingSW)}`);
    // if (!waitingSW) {
    //   bugsnagNotify(`informPrompted - unable to resolve waitingSW`);
    // }
  }
  // tell the new service worker to be paitent
  // pendingSW.postMessage({ type: RELOAD_PROMPTED_MESSAGE_TYPE }); // postMessage doesn't work on ios pwa

  // messageSW uses 'channel' api
  if (waitingSW) {
    log.debug('before message waitingSW');
    messageSW(waitingSW, { type: RELOAD_PROMPTED_MESSAGE_TYPE }).catch(
      bugsnagNotify
    );
    log.debug('after message waitingSW');
  } else {
    bugsnagNotify(
      Error('UpdateManager.informPrompted - failed to resolve waitingSW')
    );
  }

  // messageSW(waitingSW, { type: RELOAD_PROMPTED_MESSAGE_TYPE }).catch(error =>
  //   log.error(error)
  // );
};

// for manual trigger from dev-tools
export const trySkipWaiting = async () => {
  AppFactory.workbox.messageSkipWaiting();
};

// one-off check from dev-tools
export const checkForSwUpdate = async () => {
  log.info(`checkForSwUpdate (one-off)`);
  const registration = await resolveRegistration();
  if (registration) {
    registration.update().catch(error =>
      alertWarningError({
        error,
        note: 'checkForSwUpdate - registration.update() failed',
      })
    );
  }
};

export const resolveRegistration = async ({
  alert = true,
}: { alert?: boolean } = {}): Promise<ServiceWorkerRegistration> => {
  const serviceWorker = navigator.serviceWorker;
  if (!serviceWorker) {
    log.error(`no serviceWorker - ignoring`);
    return null;
  }

  const registration = await serviceWorker.getRegistration();
  if (!registration) {
    const message = 'failed to resolve sw.registration';
    if (alert) {
      alertWarningError({
        error: Error(message),
      });
    } else {
      log.warn(message);
    }
    return null;
  }

  return registration;
};

export const attemptReregistration = async () => {
  const serviceWorker = navigator.serviceWorker;
  const oldRegistration = await resolveRegistration();
  log.info(
    ` old reg: ${String(!!oldRegistration)}, sw.controller: ${String(
      !!serviceWorker?.controller
    )}`
  );
  if (oldRegistration) {
    await oldRegistration.unregister();
  }
  const registration = await serviceWorker.register('/sw.js');
  log.info(
    `new reg: ${String(!!registration)}, sw.controller: ${String(
      !!serviceWorker?.controller
    )}`
  );
};

const { simulateMissingServiceWorker } = appConfig;

export const hasServiceWorker = (): boolean => {
  return !!navigator.serviceWorker && !simulateMissingServiceWorker;
};

export const hasSwController = (): boolean => {
  return hasServiceWorker && !!navigator.serviceWorker?.controller;
};
