import { makeObservable, observable, action } from 'mobx';

class SoundbitePlayerUiModel {
  public showTitleInHeader = true;
  public showScrollToAnswerButton = true;

  constructor() {
    makeObservable(this, {
      showTitleInHeader: observable,
      setShowTitleInHeader: action,
      showScrollToAnswerButton: observable,
      setShowScrollToAnswerButton: action,
    });
  }

  public setShowTitleInHeader(showTitleInHeader: boolean) {
    this.showTitleInHeader = showTitleInHeader;
  }

  public setShowScrollToAnswerButton(showScrollToAnswerButton: boolean) {
    this.showScrollToAnswerButton = showScrollToAnswerButton;
  }
}

export const soundbitePlayerUiModel = new SoundbitePlayerUiModel();
