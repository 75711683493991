import { __s, translateWithoutDefault } from '@core/lib/localization';
import { listToMappedOrdinals } from '@utils/string-utils';
import { ModelTreeNode } from 'ts-state-tree/tst-core';

// this is the order the credits should be displayed by
const ORDERED_CREDIT_LABELS = [
  'writtenBy',
  'producedBy',
  'coproducedWith',
  'executiveProducer',
  'directedBy',
  'createdBy',
  'host',
  'guest',
  'storyBy',
  'researchBy',
  'editedBy',
  'originalMusicBy',
  'soundDesignBy',
  'artworkBy',
  'photoBy',
  'factCheckingBy',
  'productionAssistant',
  'associateProducedBy',
  'sponsoredBy',
  'producedIn',
];

// note, this code snippet exists purely to drive localized string extraction logic.
// it's not used at run-time.
// eslint-disable-next-line no-unused-vars
const creditLabelsTranslationSnippet = () => {
  __s('Written by', 'creditLabels:writtenBy'); // The Truth
  __s('Produced by', 'creditLabels:producedBy'); // RA
  __s('Co-produced with', 'creditLabels:coproducedWith');
  __s('Executive producer', 'creditLabels:executiveProducer');
  __s('Directed by', 'creditLabels:directedBy');
  __s('Created by', 'creditLabels:createdBy');
  __s('Host', 'creditLabels:host');
  __s('Guest', 'creditLabels:guest');
  __s('Story by', 'creditLabels:storyBy');
  __s('Research by', 'creditLabels:researchBy');
  __s('Edited by', 'creditLabels:editedBy'); // RA
  __s('Original music by', 'creditLabels:originalMusicBy');
  __s('Sound design by', 'creditLabels:soundDesignBy'); // RA
  __s('Artwork by', 'creditLabels:artworkBy'); // RA
  __s('Photo by', 'creditLabels:photoBy');
  __s('Fact checking by', 'creditLabels:factCheckingBy');
  __s('Production assistant', 'creditLabels:productionAssistant');
  __s('Associate produced by', 'creditLabels:associateProducedBy');
  __s('Sponsored by', 'creditLabels:sponsoredBy');
  __s('Produced in', 'creditLabels:producedIn'); // RA
  // keeping around previously translated keys, not currently assignable in masala
  __s('Senior editor', 'creditLabels:seniorEditor');
  __s('Senior producer', 'creditLabels:seniorProducer');
};

const CREDIT_LABEL_ORDINALS = listToMappedOrdinals(ORDERED_CREDIT_LABELS);

// cali catalog format is very simple now
// assume labels will be localized during ingestion
export class Credit extends ModelTreeNode {
  static CLASS_NAME = 'Credit' as const;

  static create(snapshot: any) {
    return super.create(Credit, snapshot) as Credit;
  }

  name: string = '';
  label: string = '';
  labelSlug: string = '';

  public static sort(list: Credit[]): Credit[] {
    // const ordinals = list.map(a => a.sortOrdinal);
    return list.slice().sort((a, b) => a.sortOrdinal - b.sortOrdinal);
  }

  get sortOrdinal(): number {
    return CREDIT_LABEL_ORDINALS[this.labelSlug] ?? 999;
  }

  get labelL1() {
    const translated = translateWithoutDefault(
      `creditLabels:${this.labelSlug}`
    );
    return translated ?? this.label;
  }
}
