import * as React from 'react';
import { styled } from '@naan/stitches.config';

const PagingPillsWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  gap: 6,
  marginBottom: 24,
});

const Pill = styled('div', {
  backgroundColor: '$gray-100',
  width: 24,
  height: 8,
  borderRadius: 3,
  variants: {
    selected: {
      true: {
        backgroundColor: '$teal-500',
      },
    },
  },
});

export const PagingPills = ({
  total,
  currentIndex = 0,
}: {
  total: number;
  currentIndex: number;
}) => {
  const counter = Array.from({ length: total }).map((_, i) => i);
  return (
    <PagingPillsWrapper>
      {counter.map((_, i) => (
        <Pill selected={i === currentIndex} />
      ))}
    </PagingPillsWrapper>
  );
};
