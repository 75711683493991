import React from 'react';
import { observer } from 'mobx-react';
import { AppFactory } from '@app/app-factory';
import { LocaleCode } from '@utils/util-types';
import { darkThemeSelector, styled } from '@naan/stitches.config';
// import { PriceIncreaseSubscribeCta } from 'routes/profile/price-increase-subscribe-cta';
import { PlainMarkdown } from '@naan/primitives/text';
import { daysCount } from '@core/lib/localization-helpers';
import { VSpacer } from '@naan/primitives/spacer';
import { PricingCards } from '../pricing-cards';
import { useAutoanimate } from '@common/hooks/use-autoanimate';
import { Membership } from '@core/models/user-manager/membership';
import { AccountScreenUiController } from '../account-screen-ui-controller';
import { IconButton } from '@naan/primitives/button';
import { CloseIcon } from '@naan/icons/close-icon';
import { useReaction } from '@common/hooks/use-reaction';

import __ from '@core/lib/localization';

const Expandable = styled('div', {
  display: 'grid',
  gridTemplateRows: '0fr',
  transition: 'grid-template-rows .3s',
  '& > div.expandable-inner': {
    gridRow: '1 / span 2',
    overflow: 'hidden',
  },

  '&.expanded': {
    gridTemplateRows: '1fr',
  },
});

const ZippyWrapper = styled('div', {
  $$zippyBackgroundColor: '$colors$blue-600',
  [darkThemeSelector]: {
    $$zippyBackgroundColor: '$colors$blue-400',
  },
  background: '$$zippyBackgroundColor',
  color: '$globalWhite',

  '@medium': {
    background: '$transparent',
  },

  // marginBottom: 40,
  '& > div': {
    // paddingBlock: 24,
    margin: '0 auto',
    width: 'min( 600px, calc( 100% - 32px ) )',
    paddingBottom: 32,
    '@medium': {
      background: '$$zippyBackgroundColor',
      paddingInline: 16,
      paddingBottom: 16,
      borderRadius: '$radii$large',
    },
  },

  '& .heading': {
    height: 56,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid $colors$globalWhite-alpha-20',
    marginBottom: 16,
    h3: {
      textStyle: 'small-heading',
    },
    button: {
      marginRight: -8,
    },
  },
  '& .message': {
    textStyle: 'body',
  },
});

const ExtendZippyContent = observer(
  ({
    expanded,
    membership,
    showPrinceIncreaseMessage,
    l2,
  }: {
    expanded: boolean;
    membership: Membership;
    showPrinceIncreaseMessage: boolean;
    l2: LocaleCode;
  }) => {
    const parent = useAutoanimate({
      duration: 300,
    });

    const showMessage =
      membership.showAccountPageCheckout && !showPrinceIncreaseMessage;

    const remainingDays = membership.remainingFullAccessInDays;

    useReaction(
      () => AccountScreenUiController.instance.extendingL2,
      () => {
        if (AccountScreenUiController.instance.extendingL2 === l2) {
          parent.current?.scrollIntoView({
            behavior: 'smooth',
          });
        } else {
          const row = document.querySelector('.extend-row');
          if (row) {
            row.scrollIntoView({
              behavior: 'smooth',
            });
          }
        }
      }
    );

    return (
      <Expandable ref={parent} className={expanded ? 'expanded' : ''}>
        <div className="expandable-inner">
          {/* <PriceIncreaseSubscribeCta flavor="fullAccess" /> */}

          <ZippyWrapper>
            <div>
              <span className="heading">
                <h3>{__('Extend membership', 'extendMembership')}</h3>
                <IconButton
                  presentation="whiteTransparent"
                  icon={<CloseIcon />}
                  onClick={() =>
                    AccountScreenUiController.instance.extendL2(null)
                  }
                  testId={'extend-close-icon-btn'}
                />
              </span>
              {showMessage ? (
                <div className="message">
                  {remainingDays > 0 ? (
                    <>
                      <PlainMarkdown
                        source={__(
                          'By extending your membership, you will be starting a new subscription. You will **not** lose your remaining access ({{duration}}) -- these will appear as "trial days" on the next screen, before your first charge.',
                          'extendMembershipInfo',
                          {
                            duration: daysCount(remainingDays),
                          }
                        )}
                      />
                      <VSpacer size={6} />
                    </>
                  ) : null}
                </div>
              ) : null}
              <PricingCards l2={l2} />
            </div>
          </ZippyWrapper>
        </div>
      </Expandable>
    );
  }
);

export const ExtendZippie = observer(({ l2 }: { l2: LocaleCode }) => {
  const { userManager } = AppFactory.root;

  const membership = userManager.membershipL2(l2);

  React.useEffect(() => {
    // @elliottjf is this whole pricing increase thing still a thing?
    if (membership.showPriceIncreaseInlineNotice) {
      AccountScreenUiController.instance.extendL2(l2);
    }
  }, [l2, membership.showPriceIncreaseInlineNotice]);

  return (
    <ExtendZippyContent
      l2={l2}
      showPrinceIncreaseMessage={membership.showPriceIncreaseInlineNotice}
      membership={membership}
      expanded={AccountScreenUiController.instance.extendingL2 === l2}
    />
  );
});
