import * as React from 'react';
import { MenuItem, MenuItemProps } from 'naan/primitives/menus';
import { CheckmarkIcon } from '@naan/icons/checkmark-icon';

type CheckMenuItemProps = Omit<MenuItemProps, 'rightIcon'> & {
  checked: boolean;
};

export const CheckMenuItem = ({ checked, ...props }: CheckMenuItemProps) => {
  return <MenuItem rightIcon={checked ? <CheckmarkIcon /> : null} {...props} />;
};
