import * as React from 'react';
import * as Dialog from '@naan/primitives/modals/dialog';
import { observer } from 'mobx-react';
import { DialogHtmlContainer } from 'components/ui/dialog-html-container';
import __ from '@core/lib/localization';

export const MarkStoryCompleteDialog = observer(
  ({ onReset, onDismiss }: { onReset: () => void; onDismiss: () => void }) => {
    return (
      <DialogHtmlContainer onDismiss={onDismiss}>
        <Dialog.HtmlHeading>
          {__('Mark story complete', 'markStoryComplete')}
        </Dialog.HtmlHeading>
        <Dialog.Body>
          {__(
            "This will mark all chapters as complete, but won't affect your progress statistics or vocab list.",
            'thisWillMarkAllChaptersAsComplete'
          )}
        </Dialog.Body>
        <Dialog.ButtonsContainer>
          <Dialog.Button
            onClick={onDismiss}
            label={__('Cancel', 'cancel')}
            presentation="grayTransparent"
          />
          <Dialog.Button
            onClick={onReset}
            label={__('Mark complete', 'markComplete')}
            presentation="teal"
          />
        </Dialog.ButtonsContainer>
      </DialogHtmlContainer>
    );
  }
);
