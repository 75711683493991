import * as React from 'react';
import colorLogo from './jw-icon-color.png';
import colorLogoOnDark from './jw-icon-color-on-dark.png';
import whiteLogo from './jw-icon-white.svg';
import { styled } from '@stitches/react';

const symbolTypes = {
  color: 'color',
  colorOnDark: 'colorOnDark',
  white: 'white',
} as const;

export type SymbolPresentation = typeof symbolTypes[keyof typeof symbolTypes];

const presentationSrcMap: Record<keyof typeof symbolTypes, string> = {
  [symbolTypes.color]: colorLogo,
  [symbolTypes.colorOnDark]: colorLogoOnDark,
  [symbolTypes.white]: whiteLogo,
};

const Image = styled('img', {
  width: 43,
  height: 32,
  '@medium': {
    width: 54,
    height: 40,
  },
});

export const JwSymbol = ({
  presentation = 'color',
  ...props
}: {
  presentation?: SymbolPresentation;
} & React.ComponentProps<typeof Image>) => {
  const src = presentationSrcMap[presentation];
  return <Image src={src} alt="Jiveworld" {...props} />;
};
