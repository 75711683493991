import * as React from 'react';

export function FavoriteSmallIcon({
  width = 20,
  height = 20,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 3L15 3L15 17.1257L11.1137 14.5204C10.4399 14.0688 9.56009 14.0688 8.88634 14.5204L5 17.1257L5 3Z"
        stroke={color}
        strokeWidth="2"
      />
    </svg>
  );
}

export function FavoriteIcon({
  width = 24,
  height = 24,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5927 4.4123C9.65261 3.60831 8.56575 3 7.36602 3H7.36582C4.04051 3 2 5.86252 2 8.21066C2 13.2877 7.86225 18.0002 12 21C16.1379 18 22 13.2872 22 8.21066C22 5.86272 19.9594 3 16.6342 3C15.4345 3 14.3476 3.60831 13.4075 4.4123C12.8925 4.85279 12.4215 5.35202 12.0001 5.84213C11.5787 5.35202 11.1078 4.85279 10.5927 4.4123ZM12 18.5143C13.737 17.2061 15.5762 15.689 17.0698 14.0409C18.9454 11.9713 20 9.99563 20 8.21066C20 6.80146 18.696 5 16.6342 5C16.2437 5 15.7756 5.15223 15.2043 5.54708C14.6297 5.94427 14.0641 6.50919 13.5167 7.14596L12.0001 8.91002L10.4835 7.14596C9.93608 6.50919 9.37055 5.94427 8.79589 5.54708C8.22463 5.15223 7.75647 5 7.36602 5H7.36582C5.3039 5 4 6.80128 4 8.21066C4 9.9959 5.05461 11.9717 6.93026 14.0412C8.42384 15.6891 10.263 17.2062 12 18.5143Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}
