import * as React from 'react';
import { styled } from '@naan/stitches.config';
import { HelpBoxStyles } from './help-box-styles';
import { FeedbackIcon } from '@naan/icons/feedback-icon';
import { FeedbackControl } from 'components/ui/feedback/feedback-control';
import { Button } from '@naan/primitives/button';
import { VSpacer } from '@naan/primitives/spacer';

import __ from '@core/lib/localization';

const Wrapper = styled(HelpBoxStyles, {
  $$iconColor: '$colors$green-500',
  background: '$colors$green-100',
});

export const FeedbackBox = () => {
  return (
    <Wrapper>
      <div className="icon">
        <FeedbackIcon />
      </div>
      <h3>{__('Got feedback?', 'gotFeedback')}</h3>
      <div className="content">
        {__(
          "Let us know how we can improve, and you can be sure it'll reach the right ears at Jiveworld.",
          'letUsKnowHowWeCanImprove'
        )}
      </div>
      <FeedbackControl
        dialogContext={null}
        renderControl={({ openDialog }) => (
          <>
            <VSpacer size={4} />
            <Button
              presentation="green"
              size="small"
              label={__(
                'Give feedback on Jiveworld',
                'giveFeedbackOnJiveworld'
              )}
              onClick={openDialog}
              alignment={'unset'}
            />
          </>
        )}
        metadata={{
          type: 'global',
          context: 'help-screen',
        }}
      />
    </Wrapper>
  );
};
