import {
  Element,
  Word,
  ElementList,
  ElementNode,
  EmptyElementList,
  Notation,
  Sentence as SentenceElement,
} from '@tikka/client/client-aliases';

import {
  buildContentElements,
  extractItalicsRanges,
} from '@tikka/client/client-data';
import { ExcerptData, PlayerData } from '@tikka/client/catalog-types';
import { createLogger } from 'app/logger';
import { Story } from '@core/models/story-manager';
import { ChapterCatalogData, UnitCatalogData } from '@core/models/catalog';
import { ChapterNote } from '@core/models/catalog/chapter-catalog-data';
import {
  CreateMembershipList,
  CreateMembershipReconciler,
  MembershipList,
} from '@tikka/membership-reconciliation/membership-reconciler';
import { WordElement, WordId } from '@tikka/basic-types';
import { AppFactory } from '@app/app-factory';
import { CreateElementTreeRenderer } from '@tikka/elements/element-tree-renderer';
import { CreatePrecedence } from '@tikka/elements/precedence';
import { notEmptyOrNA } from '@utils/string-utils';
import { WordMembership } from 'player/models/word-membership';
import { createMembershipEnumFuncs } from '@tikka/membership-reconciliation/membership-enum-funcs';
import { elementIdToDomId } from '@tikka/elements/element-id-utils';

const log = createLogger('simple-script-model');

export const enum ScriptFlavor {
  // eslint-disable-next-line no-unused-vars
  BASIC = 'BASIC',
  // eslint-disable-next-line no-unused-vars
  SCAFFOLDED = 'SCAFFOLDED',
}

const ValidScriptFlavors = ['BASIC', 'SCAFFOLDED'];

const validScriptFlavor = (value: ScriptFlavor) =>
  // Object.values(ScriptFlavor).includes(value); // this didn't compile
  ValidScriptFlavors.includes(value); // is there a better way?

// plain transcript for given sentence tree node
const sentenceTranscript = (node: ElementNode) =>
  node.children
    .map(({ element }: ElementNode) => (element as WordElement).text)
    .join(' ');

// plain transcript text for given paragraph tree node
// (assuming a paragraph -> sentence tree structure)
export const paragraphTranscript = (node: ElementNode) =>
  node.children.map(node => sentenceTranscript(node)).join(' ');

// translation text for given paragraph tree node
// (assuming a paragraph -> sentence tree structure)
export const paragraphTranslation = (node: ElementNode) =>
  node.children
    .map(node => (node.element as SentenceElement).translation)
    .join(' ');

export class ScriptModel {
  flavor: ScriptFlavor;
  storyCatalogData: Story; // thin version of data fetched from preloaded catalog
  loadedVolumeData: Story; // jit loaded volumeDataUrl data
  unitModels: UnitModel[] = [];
  // todo: consider adding an observable 'ready' status

  static async create(story: Story, flavor: ScriptFlavor = ScriptFlavor.BASIC) {
    const model = new ScriptModel(story, flavor);
    await model.init();
    return model;
  }

  protected constructor(story: Story, flavor: ScriptFlavor) {
    if (!validScriptFlavor(flavor)) {
      throw Error(`invalid script flavor: ${flavor}`);
    }
    this.flavor = flavor;
    this.storyCatalogData = story;
  }

  get isBasic(): boolean {
    return this.flavor === ScriptFlavor.BASIC;
  }

  get story(): Story {
    return this.loadedVolumeData;
  }

  protected async init() {
    if (!this.storyCatalogData) {
      // swr loader needs to always return a result.
      // expect screen code to interpret the absense of story data as a 'not found' error
      return;
    }
    this.loadedVolumeData =
      await AppFactory.root.storyManager.loadVolumeDataUrl(
        this.storyCatalogData.volumeDataUrl
      );

    for (const unit of this.loadedVolumeData.units) {
      const unitModel = await UnitModel.create(unit, this.flavor);
      this.unitModels.push(unitModel);
    }
  }
}

const scriptTreePrecedence = CreatePrecedence([
  'CHAPTER', // treated as point
  'CHAPTER_NOTE', // point
  'PASSAGE', // point
  'PARAGRAPH', // node
  'SENTENCE', // child node, needed for translations
  // WORD - implied
] as const);

class UnitModel {
  flavor: ScriptFlavor;
  unitData: UnitCatalogData;
  chapterModels: ChapterModel[] = [];

  static async create(
    unitData: UnitCatalogData,
    flavor: ScriptFlavor = ScriptFlavor.BASIC
  ) {
    const model = new UnitModel(unitData, flavor);
    await model.init();
    return model;
  }

  protected constructor(unitData: UnitCatalogData, flavor: ScriptFlavor) {
    this.flavor = flavor;
    this.unitData = unitData;
  }

  protected async init() {
    if (!this.unitData) {
      return;
    }

    // todo: consider loading these in parallel
    for (const chapterData of this.unitData.chapters) {
      const chapterModel = await ChapterModel.create(chapterData, this.flavor);
      this.chapterModels.push(chapterModel);
    }
  }

  get slug(): string {
    return this.unitData.slug;
  }

  get partLabel(): string {
    return this.unitData.partLabel;
  }
}

export class ChapterModel {
  flavor: ScriptFlavor;
  chapterData: ChapterCatalogData;
  playerData: PlayerData;

  // the main tree model used by the view
  elementNodes: ElementNode[];

  // the heterogenious list of all content elements
  elements: ElementList<Element> = EmptyElementList;

  words: ElementList<Word> = EmptyElementList;

  // data needed for scaffolded story script
  notations: ElementList<Notation> = EmptyElementList;

  trickyMembershipList: MembershipList = null;
  notationsMembershipList: MembershipList = null;

  sicStarts: Set<WordId> = null;
  sicIntended: Map<WordId, string> = null;
  sicMembershipList: MembershipList = null;
  italicsMembershipList: MembershipList = null;
  getWordMemberships: (id: WordId) => WordMembership;

  static async create(
    chapterData: ChapterCatalogData,
    flavor: ScriptFlavor = ScriptFlavor.BASIC
  ) {
    const model = new ChapterModel(chapterData, flavor);
    await model.init();
    return model;
  }

  protected constructor(chapterData: ChapterCatalogData, flavor: ScriptFlavor) {
    this.flavor = flavor;
    this.chapterData = chapterData;
  }

  protected async init() {
    log.info(`loading: ${this.chapterData.playerDataUrl}`);
    const response = await fetch(this.chapterData.playerDataUrl);
    const data: ExcerptData = await response.json();
    this.initPlayerData(data);
    if (this.flavor === ScriptFlavor.SCAFFOLDED) {
      this.initScaffoldData();
    }
  }

  protected initPlayerData(data: PlayerData) {
    log.trace('initFromPlayerData');
    this.playerData = data;

    const elements = buildContentElements(data);
    this.elements = elements;
    const words = this.elements.words;
    this.words = words;

    // forked from tikka/client-data, nests sentences as children of paragraphs
    const treeModel = CreateElementTreeRenderer(
      elements,
      elements.words.values,
      scriptTreePrecedence,
      ['CHAPTER', 'CHAPTER_NOTE', 'PASSAGE'],
      ['PARAGRAPH', 'SENTENCE']
    );
    this.elementNodes = treeModel.getTreeOfNodes();
  }

  // the extra data needed by richer versio of the story script
  // beware, predomiantly copied from BasePlayerModel/StudyModel
  protected initScaffoldData() {
    const trickys = this.elements.filterByKind('TRICKY');
    this.trickyMembershipList = CreateMembershipList({
      memberships: ['TRICKY'],
      elements: trickys,
      useRanges: true,
    });

    this.notations = this.elements.filterByKind('NOTATION');
    this.notationsMembershipList = CreateMembershipList({
      memberships: ['NOTATION'],
      elements: this.notations,
      useRanges: true,
    });

    const sics = this.elements.filterByKind('SIC');
    const sicStarts = new Set<WordId>();
    const sicIntended = new Map<WordId, string>();

    for (const sic of sics.values) {
      const beginId = sic.address.toString();
      const endId = sic.endAddress.toString();
      const intended = sic.intended || '?';

      sicStarts.add(beginId as WordId);
      sicIntended.set(endId as WordId, intended);
    }

    this.sicStarts = sicStarts;
    this.sicIntended = sicIntended;
    this.sicMembershipList = CreateMembershipList({
      memberships: ['SIC'],
      elements: sics,
      useRanges: true,
    });

    this.italicsMembershipList = CreateMembershipList({
      memberships: ['ITALIC'],
      elements: extractItalicsRanges(this.words),
      useRanges: true,
    });

    // todo: run one-time reconciliation
    const membershipFuncs = createMembershipEnumFuncs(WordMembership);

    const wordMembershipReconciler = CreateMembershipReconciler(
      membershipFuncs.setMemberships
    );
    const wordMembershipLists: Map<string, MembershipList> = new Map();
    wordMembershipLists.set('trickyWord', this.trickyMembershipList);
    wordMembershipLists.set('notationWord', this.notationsMembershipList);
    wordMembershipLists.set('sicWord', this.sicMembershipList);
    wordMembershipLists.set('italicWord', this.italicsMembershipList);

    wordMembershipReconciler.reconcileMembershipLists('', wordMembershipLists);
    const getWordMemberships = membershipFuncs.getMemberships;
    this.getWordMemberships = (wordId: WordId) => {
      return getWordMemberships(elementIdToDomId(null, wordId));
    };
  }

  // hack until one-time reconcilation hooked up
  // isNotation(id: WordId): boolean {
  //   const address = Number(id);
  //   const match = this.notations.values.find(
  //     n => address >= n.address && address <= n.endAddress
  //   );
  //   return !!match;
  // }

  // render fragment keys
  get key(): string {
    return String(this.chapterData.sortingRef);
  }

  // impersonate Node interface
  get children(): ElementNode[] {
    return this.elementNodes;
  }

  get chapterNotes(): ChapterNote[] {
    // should be able to remove the filter once we ditch the misguided aux l1 support
    return this.chapterData.chapterNotes.filter(note =>
      notEmptyOrNA(note.body)
    );
  }

  get passages() {
    return this.chapterData.passages;
  }
}
