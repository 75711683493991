import * as React from 'react';
import { styled } from '@naan/stitches.config';
import { PlainMarkdownWithLinks } from '@naan/primitives/text';
import { storiesPrimaryFiltered } from 'components/nav/path-helpers';
import { PrimaryFilterKeys } from '@core/models/story-manager/story-manager';

import __ from '@core/lib/localization';

const Container = styled('div', {
  padding: '$space$4 0',
  '& h2': {
    textStyle: 'small-heading',
  },
  div: {
    color: '$textSecondary',
    '& a': {
      color: '$textSecondary',
    },
  },
});

export const ZeroSearchResults = () => (
  <Container>
    <h2 className="heading">{__('No results', 'noResults')}</h2>
    <div>
      <PlainMarkdownWithLinks
        source={__(
          'Try another search term or [show all stories](%{url})',
          'tryAnotherSearchTerm',
          {
            url: storiesPrimaryFiltered(PrimaryFilterKeys.ALL),
          }
        )}
      />
    </div>
  </Container>
);
