import * as React from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import { ClientNotation } from '@tikka/client/client-types';
import { keyframes, styled } from '@naan/stitches.config';
import { ItemActionButton } from '../item-action-button';
import { NotationStatus, statusIs } from 'vocab-review/chapter-review-model';
import { CheckmarkHeavySmallIcon } from '@naan/icons/checkmark-heavy-icon';
import { VocabListItemHead } from './item-head';

import __ from '@core/lib/localization';

const ItemWrapper = styled('div', {
  padding: '8px 0 12px',
  display: 'flex',
  flexDirection: 'column',
  paddingInline: '$space$4',
  position: 'relative',
  transition: 'box-shadow .3s',

  '&.focus': {
    background: 'white',
    boxShadow: '0px 1px 10px 0px rgba(0, 0, 0, 0.10)',
    outline: '1px solid $colors$black-alpha-06',
    borderRadius: '$radii$large',
  },

  '& > .inner': {
    display: 'grid',
    gridTemplateColumns: '1fr 36px',
    '& .bookmark-icon': {
      display: 'flex',
      alignItems: 'center',
      '&.blocked': {
        pointerEvents: 'none',
      },
    },
  },
});

const appearFromTheRight = keyframes({
  from: { opacity: 0, transform: 'translateX(10%)' },
  to: { opacity: 1, transform: 'translateX(0%)' },
});

const disappear = keyframes({
  from: { opacity: 1 },
  to: { opacity: 0 },
});

const ToastWrapper = styled('div', {
  textStyle: 'body-bold',
  display: 'flex',
  flexDirection: 'row',
  color: '$colors$purple-800',
  backgroundColor: '$colors$purple-100',
  gap: 4,
  padding: '8px 16px',
  borderRadius: '$radii$medium',
  position: 'absolute',
  top: 'calc(50% - 20px)',
  right: 48,
  animation: `${appearFromTheRight} 0.3s ease-out`,
});

const InlineToast = ({
  status,
  callback,
}: {
  status: NotationStatus;
  callback: () => void;
}) => {
  const parent = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    function afterAppearingCallback() {
      parent.current.style.animation = '';
      parent.current.style.animation = `${disappear} 0.3s ease 1.5s forwards`;
      parent.current.removeEventListener(
        'animationend',
        afterAppearingCallback
      );

      function afterDisappearingCallback() {
        callback();
        parent.current.removeEventListener(
          'animationend',
          afterDisappearingCallback
        );
      }

      parent.current.addEventListener(
        'animationend',
        afterDisappearingCallback
      );
    }
    parent.current.addEventListener('animationend', afterAppearingCallback);
  }, [callback, status]);

  return (
    <ToastWrapper ref={parent}>
      <CheckmarkHeavySmallIcon />
      {__('Added for next review', 'addedForNextReview')}
    </ToastWrapper>
  );
};

export const ManageItem = observer(
  ({
    notation,
    status,
  }: {
    notation: ClientNotation;
    status: NotationStatus;
  }) => {
    const [showToast, setShowToast] = React.useState(false);
    React.useEffect(() => {
      if (statusIs(status, 'just-added')) {
        setShowToast(true);
      }
    }, [status]);

    const toastAnimationCallback = React.useCallback(() => {
      setShowToast(false);
    }, []);

    return (
      <ItemWrapper>
        <div className="inner">
          <VocabListItemHead notation={notation} />
          {showToast ? (
            <InlineToast status={status} callback={toastAnimationCallback} />
          ) : null}
          <div className={classNames('bookmark-icon', { blocked: showToast })}>
            <ItemActionButton notation={notation} status={status} />
          </div>
        </div>
      </ItemWrapper>
    );
  }
);
