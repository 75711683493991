import * as Yup from 'yup';
import { FNAME_MAX_CHAR_LIMIT } from 'core/lib/constants/vars';
import __ from 'core/lib/localization';

// @armando, i'll let you think about if/where this file belongs (i moved from the prior core/lib into here)

const nameRule = () =>
  Yup.string()
    .max(
      FNAME_MAX_CHAR_LIMIT,
      __('First name must be at most 15 characters', 'firstNameNotValid')
    )
    .required(__('First name is required', 'firstNameRequired'))
    .trim();

const emailRule = () =>
  Yup.string()
    .email(__('Email is not valid', 'emailNotValid'))
    .required(__('Email is required', 'emailRequired'))
    .trim();

const passwordRule = () =>
  Yup.string().required(__('Password is required', 'passwordRequired'));

/**
 * Validation schema for signup form
 */
export const signUpSchema = Yup.object().shape({
  name: nameRule(),
  email: emailRule(),
  password: passwordRule(),
});

/**
 * Validation schema for login form
 */
export const signInSchema = Yup.object().shape({
  email: emailRule(),
  password: passwordRule(),
});

export const nameSchema = () =>
  Yup.object().shape({
    name: nameRule(),
  });

export const emailSchema = () =>
  Yup.object().shape({
    email: emailRule(),
  });

export const passwordSchema = () =>
  Yup.object().shape({
    password: passwordRule(),
  });

export const couponCodeSchema = () =>
  Yup.object().shape({
    code: Yup.string().required(__('Code is required', 'codeIsRequired')),
  });

//
// Classroom
//

const classNameRule = () => {
  const classNameMin = 2;
  const classNameMax = 20;
  const classNameMinMaxMsg = __(
    'Class name needs to be between %{min} and %{max} characters',
    'labelMaxLengthValidationMessage',
    { min: classNameMin, max: classNameMax }
  );
  return Yup.string()
    .min(classNameMin, classNameMinMaxMsg)
    .max(classNameMax, classNameMinMaxMsg)
    .trim();
};
export const classNameSchema = () =>
  Yup.object().shape({
    label: classNameRule(),
  });

/// I had to duplicate the rules because Typescript
export const schoolNameSchema = () => {
  const schoolNameMaxLength = 80;

  return Yup.object().shape({
    schoolName: Yup.string()
      .max(
        schoolNameMaxLength,
        __(
          'School name must be at most %{n} characters',
          'schoolNameMaxLengthError',
          { n: schoolNameMaxLength }
        )
      )
      .required(
        __(
          'Enter the name of the school or schools where you teach',
          'schoolNameRequired'
        )
      )
      .trim(),
  });
};

//
// Assignment
//

// export const dateRule = Yup.string()
//   .trim();
const assignmentDetailsRule = () => {
  const maxDetailsLength = 250;
  return Yup.string()
    .max(
      maxDetailsLength,
      __(
        'Assignment details must be at most %{n} characters',
        'assignmentDetailsMaxLengthError',
        { n: maxDetailsLength }
      )
    )
    .trim();
};

export const assignmentSchema = () =>
  Yup.object().shape({
    details: assignmentDetailsRule(),
  });
