import { styled } from 'naan/stitches.config';
import { paletteKey } from 'study/views/study-palette';

export const ToolbarStyles = styled('div', {
  position: 'sticky',
  bottom: 0,
  borderRadius: '$radii$large',
  padding: 8,
  paddingTop: 0,
  background: paletteKey('$$sentence__backgroundColor_current'),

  '& > .toolbar-inner': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 4,
    alignItems: 'center',
    alignSelf: 'stretch',
    padding: '4px 6px',
    borderRadius: 12,
    background: paletteKey('$$sentence_toolbar__backgroundColor'),
    boxShadow: `0px 1px 3px ${paletteKey('$$sentence_toolbar__shadowColor')}`,

    '&.with-divider': {
      '@media (max-width: 390px)': {
        gap: 0,
      },
    },

    '& .notation-icon-text': {
      fill: paletteKey('$$sentence__backgroundColor_current'),
    },

    '& .spacer': {
      flex: 1,
      position: 'relative',
      height: 28,
      '@media (max-width: 390px)': {
        '&.with-divider': {
          flex: 0,
          padding: '0 12px',
          '&::after': {
            content: '""',
            position: 'absolute',
            left: 'calc(50% - 1px)',
            height: 28,
            width: 1,
            background: '$colors$black-alpha-15',
          },
        },
      },
    },
  },
});
