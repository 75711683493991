import * as React from 'react';
import * as Dialog from 'naan/primitives/modals/dialog';
import { HSpacer, VSpacer } from 'naan/primitives/spacer';
import { styled } from 'naan/stitches.config';
import { MarkSimple, Text } from 'naan/primitives/text';
import { DateSmallIcon } from 'naan/icons/date-icon';
import { NoteSmallIcon } from 'naan/icons/note-icon';
import { HStack, VStack } from 'naan/primitives/stack';
import { useCurrentAssignment } from './current-assignment-context';
import { VolumeCardMeta } from 'components/volume-layouts/volume-card-meta';
import { ClassDetailModal } from '../assignment-dialog/class-detail-modal';
import { Button } from 'naan/primitives/button';
import { TrashSmallIcon } from 'naan/icons/trash-icon';
import { Box } from 'naan/primitives/box';
import { useSwitch2 } from '@naan/hooks/use-switch-2';
import { AppFactory } from 'app/app-factory';
import { pick } from 'lodash';
import { Image } from '@naan/primitives/image';

import __ from 'core/lib/localization';

const Thumbnail = styled(Image, {
  $$thumbSize: '184px',
  width: '$$thumbSize',
  height: '$$thumbSize',
  borderRadius: '$radii$medium',
  display: 'none',
  marginRight: '$space$6',
  '@small': {
    display: 'block',
  },
});

const AssignmentControl = styled('div', {
  display: 'flex',
  alignItems: 'flex-start',
  textStyle: 'small-text',
  color: '$textSecondary',
  cursor: 'pointer',
  '& .icon': {
    marginRight: '$space$2',
    color: '$teal-500',
    lineHeight: 0,
  },
});

const DeleteConfirmationDialog = ({
  onDismiss,
  okAction,
}: {
  onDismiss: () => void;
  okAction: () => void;
}) => {
  const handleOkAndDismiss = React.useCallback(() => {
    onDismiss();
    okAction();
  }, [okAction, onDismiss]);

  return (
    <Dialog.Container open={true} onDismiss={onDismiss}>
      <Dialog.Body>
        <VStack>
          <Text>
            {__(
              'Are you sure you want to remove this story from the class?',
              'areYouSureYouWantTo'
            )}
          </Text>
          <VSpacer size={1} />
        </VStack>
      </Dialog.Body>
      <Dialog.ButtonsContainer>
        <Dialog.Button
          presentation={'cancel'}
          label={__('No, keep it', 'noKeepIt')}
          data-test-id="okButton"
          onClick={onDismiss}
        />
        <Dialog.Button
          presentation={'teal'}
          label={__('Yes, delete', 'yesDelete')}
          data-test-id="okButton"
          onClick={handleOkAndDismiss}
        />
      </Dialog.ButtonsContainer>
    </Dialog.Container>
  );
};

const DeleteAssignmentButton = () => {
  const showConfirmation = useSwitch2(false);
  const { assignment, deleteAssignment } = useCurrentAssignment();
  const handleDeleteAssignment = React.useCallback(() => {
    deleteAssignment(assignment?.episodeSlug);
    showConfirmation.off();
  }, [assignment, deleteAssignment, showConfirmation]);

  const presentConfirmation = React.useCallback(() => {
    AppFactory.dialogPresenter.present(onDismiss => {
      return (
        <DeleteConfirmationDialog
          onDismiss={onDismiss}
          okAction={handleDeleteAssignment}
        />
      );
    });
  }, [handleDeleteAssignment]);

  return (
    <>
      <Box css={{ paddingLeft: '16px' }}>
        <Button
          label={__('Delete', 'delete')}
          presentation={'destructive'}
          leftIcon={<TrashSmallIcon />}
          onClick={presentConfirmation}
          size={'small'}
        />
      </Box>
    </>
  );
};

export const AssignmentDetail = () => {
  const { assignment, classroom } = useCurrentAssignment();
  const { story } = assignment;

  const presentAssignmentDialog = React.useCallback(() => {
    /// What's this monstrosity doing here??
    /// well, there is a very weird race condition that happens
    /// (as far as I can tell) when `useComboForm` and a `Dialog`
    /// interact with each other. The app just crashes.
    /// I tried a number of things and I could not find the soruce of the bug
    /// but I've found that delaying presenting the dialog by a little seems to help
    /// the `requestAnimationFrame` here helps by doing that.
    requestAnimationFrame(() => {
      AppFactory.dialogPresenter.present(onDismiss => (
        <ClassDetailModal
          assignment={pick(assignment, ['details', 'dueDate']) as any}
          unitSlug={story.slug}
          classId={classroom.id}
          onClose={onDismiss}
          buttonLabel={__('Save', 'save')}
        />
      ));
    });
  }, [assignment, story.slug, classroom.id]);

  return (
    <>
      <HStack>
        <Thumbnail
          src={assignment.story.listImageUrl}
          alt={assignment.story.title}
        />
        <VStack css={{ flex: 1 }}>
          <HStack>
            <Text textStyle={'large-heading'}>
              <MarkSimple source={assignment.story.title} />
            </Text>
            <HSpacer expand />
            <DeleteAssignmentButton />
          </HStack>
          <VSpacer size={2} />
          <VolumeCardMeta story={story} showSoundbitesLabel />
          <VSpacer size={4} />
          <AssignmentControl onClick={presentAssignmentDialog}>
            <span className="icon">
              <DateSmallIcon />
            </span>
            {assignment.displayDueDate}
          </AssignmentControl>
          <VSpacer size={2} />
          <AssignmentControl onClick={presentAssignmentDialog}>
            <span className="icon">
              <NoteSmallIcon />
            </span>
            {assignment.displayNote}
          </AssignmentControl>
        </VStack>
      </HStack>
    </>
  );
};
