import type * as Stitches from '@stitches/react';
import { theme, config } from 'naan/stitches.config';

type TSpaceKey = keyof typeof theme.space;
type TCSS = Stitches.CSS<typeof config>;

export const makeSizeVariants = (varname = '$$size') =>
  (Object.keys(theme.space) as Array<TSpaceKey>).reduce<
    Record<TSpaceKey, TCSS>
  >(
    (acc, cv) => ({
      ...acc,
      [cv]: { [varname]: `$space$${cv}` },
    }),
    {} as any
  );

export const makeQuerySizeVariants = (varname = '$$size', query: string) =>
  (Object.keys(theme.space) as Array<TSpaceKey>).reduce<
    Record<TSpaceKey, TCSS>
  >(
    (acc, cv) => ({
      ...acc,
      [cv]: { [query]: { [varname]: `$space$${cv}` } },
    }),
    {} as any
  );
