import * as React from 'react';
import { Menu, MenuItem } from 'naan/primitives/menus';
import { useSwitch } from '@naan/hooks/use-switch';
import { useCurrentClassroom } from './current-clasroom-context';
import { AppFactory } from 'app/app-factory';
import { ClassRenamingModal } from './assignment-dialog/class-renaming-modal';
import { styled } from '@naan/stitches.config';

import __ from 'core/lib/localization';
import { HtmlAlertDialog } from 'components/ui/html-alert-dialog';

const Wrapper = styled('div', {
  maxWidth: '400px',
});

export const ClassroomOverflowMenu = () => {
  const { classroom, deleteCurrentClassroom } = useCurrentClassroom();

  const { isDeletable } = classroom;

  const [showArchiveDialog, { toggle, off }] = useSwitch(false);

  const handleRenameClass = React.useCallback(() => {
    AppFactory.dialogPresenter.present(onDismiss => (
      <ClassRenamingModal onDismiss={onDismiss} classroom={classroom} />
    ));
  }, [classroom]);

  return (
    <>
      <Menu align="end">
        <MenuItem
          label={__('Rename class', 'renameClass')}
          action={handleRenameClass}
        />
        {isDeletable ? (
          <MenuItem
            label={__('Delete class', 'deleteClass')}
            isDestructive={true}
            action={toggle}
          />
        ) : null}
      </Menu>
      {showArchiveDialog ? (
        // FIXME: use the new Dialog component and dialog presenter instead of a switch
        <HtmlAlertDialog
          open
          heading={__('Delete class "%{label}"?', 'confirmClassDeletionTitle', {
            label: classroom.label,
          })}
          body={
            <Wrapper>
              {__(
                'Students in this class will no longer see this class or its assignments in the Jiveworld app. Deleting the class will not change any full access to Jiveworld they have on their accounts. This action cannot be undone.',
                'confirmClassDeletionBody'
              )}
            </Wrapper>
          }
          onDismiss={off}
          okButtonAction={deleteCurrentClassroom}
          okButtonLabel={__('Yes, delete', 'yesDelete')}
          cancelButtonLabel={__('No, keep', 'noKeep')}
          cancelButtonAction={off}
          showCancelButton={true}
        />
      ) : null}
    </>
  );
};
