import React from 'react';
import { observer } from 'mobx-react';
import { SectionBreak, SectionEnds } from '@naan/primitives/spacer/gaps';
import { Table } from 'components/ui/tables';
import { ClassLabelCell } from '../components/classrooms/table-cells/class-label-cell';
import { CodeCell } from '../components/classrooms/table-cells/code-cell';
import { ClassStudentsCell } from '../components/classrooms/table-cells/class-students-cell';
import { MobileOnlyTableHeader } from '../components/classrooms/mobile-only-table-header';
import { Text } from '@naan/primitives/text';
import { VSpacer, HSpacer } from 'naan/primitives/spacer';
import { HStack } from 'naan/primitives/stack';
import { CreateClassButton } from '../components/classrooms/classroom-list/create-class-button';
import { LicenseBadge } from 'components/classrooms/license-badge';
import { AppFactory } from 'app/app-factory';
import { PageHeading } from 'components/page-heading';
import { ContentGridInnerContainer } from 'components/global-layout';
import { InlineNotice } from '@naan/inline-notice';
import { DeepScrollRestoration } from 'lib/scroll-utils/deep-scroll-restoration';
import { useLocation } from 'react-router-dom';
import { CenterColumnLayout } from 'components/layouts/center-column-layout';
import { ExternalLink } from '@naan/primitives/text/external-link';
import { Classroom } from '@core/models/user-manager';
import { contactUs } from 'components/nav/path-helpers';
import { useEnableUpdatePrompt } from '@common/hooks/use-enable-update-prompt';
import { bugsnagNotify } from '@app/notification-service';
import { ClassroomMessageTarget } from 'components/ui/global-message-target';
import { ClassroomFeedbackButton } from 'components/ui/feedback/classroom-feedback-btn';

import __ from 'core/lib/localization';

export const ClassroomsScreen = observer(() => {
  useEnableUpdatePrompt();
  const location = useLocation();

  const { accountData } = AppFactory.root.userManager;
  const { managedL2Classrooms, showClassroomLicenseNag, studentAccessFetched } =
    accountData;

  React.useEffect(() => {
    accountData.ensureStudentAccessStatuses().catch(bugsnagNotify);
  }, [accountData, studentAccessFetched]);

  React.useEffect(
    () => {
      // refresh account data if at least 1 min stale
      AppFactory.root.userManager
        .refreshAccountDataIfStale(60 * 1000)
        .catch(bugsnagNotify);
    } /* trigger for every render */
  );

  return (
    <>
      <ClassroomMessageTarget />
      <DeepScrollRestoration pageKey={location.pathname} />
      <CenterColumnLayout css={{ height: '100%' }}>
        <ContentGridInnerContainer>
          <PageHeading title={__('My classes', 'myClasses')} />
          <HStack css={{ marginTop: '-48px' }}>
            <HSpacer expand />
            <CreateClassButton />
          </HStack>
          <VSpacer size={4} />
          {showClassroomLicenseNag ? (
            <>
              <InlineNotice
                type="warning"
                message={__(
                  'Please remove some students or get in touch to add additional seats.',
                  'classLicenseQuotaExceeded'
                )}
                renderLink={() => (
                  <ExternalLink href={contactUs()}>
                    {__('Contact sales', 'contactSales')}
                  </ExternalLink>
                )}
              />
              <VSpacer size={3} />
            </>
          ) : null}
          <MobileOnlyTableHeader>
            {__(
              {
                one: '%{count} class',
                other: '%{count} classes',
              },
              'countClasses',
              {
                count: managedL2Classrooms.length,
              }
            )}
          </MobileOnlyTableHeader>
          {managedL2Classrooms.length > 0 ? (
            <Table
              data={managedL2Classrooms}
              cells={[
                {
                  fieldName: 'label',
                  headerLabel: __('Label', 'label'),
                  renderCell: ({ label, id }: Classroom) => (
                    <ClassLabelCell label={label} id={id} />
                  ),
                },
                {
                  headerLabel: __('Class code', 'classCode'),
                  renderCell: ({ code }: Classroom, cell) => (
                    <CodeCell code={code} cell={cell} />
                  ),
                },
                {
                  headerLabel: __('Students', 'students'),
                  renderCell: ({ studentCount }: Classroom, cell) => (
                    <ClassStudentsCell
                      studentCount={studentCount.toString()}
                      cell={cell}
                    />
                  ),
                },
                {
                  headerLabel: __('License', 'license'),
                  renderCell: (classroom: Classroom) => (
                    <LicenseBadge classroom={classroom} />
                  ),
                },
              ]}
            />
          ) : (
            <>
              <VSpacer
                size={4}
                css={{ borderTop: '1px solid $colors$gray-100' }}
              />
              <Text>
                {__(
                  'No active classes. New classes will appear here when you create them.',
                  'classListZeroState'
                )}
              </Text>
            </>
          )}
          <SectionBreak />
          <SectionEnds />
        </ContentGridInnerContainer>
        <ClassroomFeedbackButton />
      </CenterColumnLayout>
    </>
  );
});
