import * as React from 'react';
import { ReturnNavState } from 'components/nav/return-nav-state';
import { CurrentStoryProvider } from 'components/story-detail/current-story-context';
import { BodyProxy } from 'components/ui/body-proxy';
import { Story } from '@core/models/story-manager';
import { NotFoundScreen } from 'routes/not-found-screen';
import { VocabReviewModal } from '../vocab-review-modal';
import { VocabListModal } from 'components/story-detail/vocab-list';
import { StoryDetailContent } from './story-detail-content';
import { SoundbitesModal } from 'components/story-detail/soundbites-modal';
import { storyDetailUiModel } from 'components/story-detail/story-detail-ui-model';
import { observer } from 'mobx-react';
import { StoryChannelModal } from 'components/learn/dashboard/channel-modal';
import { scrollToProgressPanel } from '../scrolling-fx';

const StoryDetailModals = observer(({ story }: { story: Story }) => {
  const uiModel = storyDetailUiModel;
  React.useEffect(() => {
    setTimeout(() => {
      uiModel.resolveQueryParams();
    }, 200);
  }, [uiModel]);

  // TODO: probably should move show/hide state inside each modal
  return (
    <>
      {uiModel.showingVocabReviewModal ? (
        <VocabReviewModal
          story={story}
          onDismiss={() => uiModel.hideVocabReviewModal()}
        />
      ) : null}
      {uiModel.showingVocabListModal ? (
        <VocabListModal
          onDismiss={() => {
            uiModel.hideVocabListModal();
          }}
        />
      ) : null}
      {uiModel.showingSoundbitesModal ? (
        <SoundbitesModal
          story={story}
          completedSlugs={story.completedSoundbiteSlugs}
          studentName={null /* normal mode, not teacher mode */}
          onDismiss={() => {
            uiModel.hideSoundbitesModal();
            // remove 'soundbites' query param
            window.history.replaceState({}, '', window.location.pathname);
          }}
        />
      ) : null}
      <StoryChannelModal />
    </>
  );
});

export const StoryDetailScreen = ({ story }: { story: Story }) => {
  // track when it's safe to pop back from study view
  ReturnNavState.storyDetailVisited = true;

  (window as any).currentStory = story; // console convenience

  React.useEffect(() => {
    const completed = story.completed;
    const relistening = story.progressMayBeNull?.inReviewMode;
    if (completed && !relistening) {
      setTimeout(() => {
        scrollToProgressPanel();
      }, 200);
    }
  }, [story]);

  if (!story) {
    return <NotFoundScreen reportError={false} />;
  }

  return (
    <CurrentStoryProvider story={story}>
      <BodyProxy className={'__no-scroll'} />
      <StoryDetailContent story={story} />
      <StoryDetailModals story={story} />
    </CurrentStoryProvider>
  );
};
