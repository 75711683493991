import React from 'react';
import { StoryCover } from './story-cover';
// import { StoryCast } from './story-cast';
// import { StoryCredits } from './story-credits';
import { StoryGuideLicense } from './story-guide-license';

import { Story } from '@core/models/story-manager/story';
import { VoicesCredits } from 'components/story-detail/story-voices-credits';
import { styled } from '@naan/stitches.config';

const OverviewWrapper = styled('section', {
  borderTop: '1px solid var(--gray100)',
  padding: '24px 0 0',
  marginBottom: '80px',
  marginTop: '0',

  '@media print, screen and (min-width: 900px)': {
    '& .credits': {
      columnCount: '3',
    },
  },
});

export const StoryOverview = ({
  story,
  basic = false,
}: {
  story: Story;
  basic?: boolean;
}) => {
  return (
    <OverviewWrapper>
      <div className="overview">
        <StoryCover story={story} />
        {basic ? null : (
          <>
            {/* // todo: @Armando, please look at the media print css and if this can factored and shared like this */}
            {/* <StoryCast story={story} />
            <StoryCredits story={story} /> */}
            <VoicesCredits story={story} />
            <StoryGuideLicense />
          </>
        )}
      </div>
    </OverviewWrapper>
  );
};
