import React from 'react';
import { ValidationError } from 'core/lib/errors';
import { alertSevereError } from '@app/notification-service';
import { resolveApiMessage } from '@core/models/user-manager/api-messages';

/// This "Server" in the name is just so we know that
/// this is not for client-side validation, which should
/// will be done using a client-side form library.
export function useServerValidation() {
  const [errors, setErrors] = React.useState<
    Record<string, { message: string }>
  >({});

  const setValidationError = (error: ValidationError) => {
    const message = resolveApiMessage(error);
    setErrors({ [error.key]: { message } }); // { messageKey, message }
  };

  const clearValidationErrors = React.useCallback(() => {
    setErrors({});
  }, [setErrors]);

  const handleValidationErrors = (error: any) => {
    if (error instanceof ValidationError) {
      setValidationError(error);
      return;
    }
    // @armando, this probably needs revisiting, but non-validation errors seemed to be
    // getting swallowed, so i think it's better to show an alert
    alertSevereError({ error });
    // throw error;
  };
  return {
    errors,
    handleValidationErrors,
    clearValidationErrors,
  };
}
