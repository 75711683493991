import * as React from 'react';

export function ExternalLinkIcon({
  width = 24,
  height = 24,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 4H18.5858L10.7929 11.7929L12.2071 13.2071L20 5.41421V9H22V2H15V4Z"
        fill={color}
      />
      <path
        d="M5 4C4.44772 4 4 4.44772 4 5V19C4 19.5523 4.44772 20 5 20H19C19.5523 20 20 19.5523 20 19V12H18V18H6V6H12V4H5Z"
        fill={color}
      />
    </svg>
  );
}
