import * as React from 'react';

export function HomeIcon({ width = 24, height = 24, color = 'currentColor' }) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 22H5C4.44772 22 4 21.5523 4 21V11.2361C4 11.0808 3.96385 10.9277 3.89443 10.7889L3.36663 9.73325C3.15345 9.3069 3.27084 8.78934 3.64711 8.49669L11.3861 2.47751C11.7472 2.19665 12.2528 2.19665 12.6139 2.47751L20.3529 8.49669C20.7292 8.78934 20.8465 9.3069 20.6334 9.73325L20.1056 10.7889C20.0361 10.9277 20 11.0808 20 11.2361V21C20 21.5523 19.5523 22 19 22H15C14.4477 22 14 21.5523 14 21V17C14 16.4477 13.5523 16 13 16H11C10.4477 16 10 16.4477 10 17V21C10 21.5523 9.55228 22 9 22Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}

export function HomeOutlineIcon({
  width = 24,
  height = 24,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6644 2.25259C12.2855 1.9158 11.7145 1.9158 11.3356 2.25259L2.33565 10.2526C1.92286 10.6195 1.88568 11.2516 2.2526 11.6644C2.61952 12.0771 3.25159 12.1143 3.66437 11.7474L4.00001 11.4491L4.00001 21C4.00001 21.5523 4.44773 22 5.00001 22H10C10.5523 22 11 21.5523 11 21L11 16H13L13 21C13 21.5523 13.4477 22 14 22H19C19.5523 22 20 21.5523 20 21L20 11.4491L20.3356 11.7474C20.7484 12.1143 21.3805 12.0771 21.7474 11.6644C22.1143 11.2516 22.0772 10.6195 21.6644 10.2526L12.6644 2.25259ZM18 9.67129L12 4.33795L6.00001 9.67129L6.00001 20H9.00001L9.00001 15C9.00001 14.4477 9.44773 14 10 14H14C14.5523 14 15 14.4477 15 15L15 20H18L18 9.67129Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}
