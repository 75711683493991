import { getElementById } from 'components/dom-utils';
import { elementIds } from 'components/dom-utils/element-ids';
// import { getPlayerModel } from 'player/views/player-model-context';
import { throttle } from 'lodash';
import { action, makeObservable, observable } from 'mobx';
// import { StudyModel } from 'study/models/study-model';
// import { PlayerModel } from 'player/models/player-model';

const SCRIPT_SCROLLING_CONTAINER_ID = 'script-scrolling-container';

class LineScrollObserver {
  private observer: IntersectionObserver;
  private root: HTMLElement;
  private targets: Element[];
  private options: IntersectionObserverInit;
  // private model: PlayerModel;

  isCurrentSentenceVisible = true;
  isGlobalRedactionButtonVisible: Boolean | null = null;
  isScriptTitleVisible = true;

  constructor() {
    makeObservable(this, {
      isCurrentSentenceVisible: observable,
      isGlobalRedactionButtonVisible: observable,
      isScriptTitleVisible: observable,
      setIsCurrentSentenceVisible: action,
      setIsGlobalRedactionButtonVisible: action,
      setIsScriptTitleVisible: action,
    });
  }

  connect() {
    const root = document.getElementById(SCRIPT_SCROLLING_CONTAINER_ID);
    this.root = root;
    this.options = {
      root,
      /// the header is superimposed on the top of the script so we need to compensate for it
      rootMargin: '56px 0px 0px 0px',
      /// 75% of the target element must be visible/
      threshold: 0.75,
    };

    this.observer = new IntersectionObserver(
      this.callback.bind(this),
      this.options
    );

    this.observe();
  }

  setIsCurrentSentenceVisible(isVisible: boolean) {
    this.isCurrentSentenceVisible = isVisible;
  }

  setIsGlobalRedactionButtonVisible(isVisible: boolean) {
    this.isGlobalRedactionButtonVisible = isVisible;
  }

  setIsScriptTitleVisible(isVisible: boolean) {
    this.isScriptTitleVisible = isVisible;
  }

  observe() {
    /// this [...] pattern is a trick to turn a readonly NodeList into an array
    this.targets = [...this.root.querySelectorAll('.line')];

    const redactionButton = this.root.querySelector('.redaction-btn');
    if (redactionButton) {
      this.targets.push(redactionButton);
    }

    const title = getElementById(elementIds.SCRIPT_HEADER_TITLE);
    if (title) {
      this.targets.push(title);
    }

    this.targets.forEach(target => {
      this.observer.observe(target);
    });
  }

  unobserve() {
    this.targets.forEach(target => {
      this.observer.unobserve(target);
    });
  }

  disconnect() {
    this.observer.disconnect();
  }

  callback(entries: IntersectionObserverEntry[], _: IntersectionObserver) {
    // const $$ = document.getElementById;
    // const $notationListPanel = document.getElementById('NOTATION_LIST_PANEL');
    // const $backToTop = document.getElementById('BACK_TO_TOP');
    // const $backToBottom = document.getElementById('BACK_TO_BOTTOM');
    entries.forEach(entry => {
      if (entry.target.classList.contains('redaction-btn')) {
        if (entry.isIntersecting) {
          this.setIsGlobalRedactionButtonVisible(true);
        } else {
          this.setIsGlobalRedactionButtonVisible(false);
        }
      } else if (entry.target.id === elementIds.SCRIPT_HEADER_TITLE) {
        if (entry.isIntersecting) {
          this.setIsScriptTitleVisible(true);
        } else {
          this.setIsScriptTitleVisible(false);
        }
      } else if (entry.target.classList.contains('current')) {
        if (entry.isIntersecting) {
          this.setIsCurrentSentenceVisible(true);
        } else {
          this.setIsCurrentSentenceVisible(false);
        }
      }
    });
  }
}

export const lineScrollObserver = new LineScrollObserver();

// (window as any).sentenceScrollObserver = sentenceScrollObserver;

/**
 * This function combines two concepts from frank's original prototype.
 *
 * - Scrolling to a sentence so it's centered on the screen.
 * - Scrolling to a sentence so it ends up exactly over the notation panel.
 *
 * I combined them both here, because otherwise you'd have to call one or the other
 * depending on the state of the player and it could get messy.
 * Instead I'm looking for the existence of a visible notation panel and using the second
 * variation when it exists.
 *
 * It's all in the DOM, so there are no state side effects at all.
 *
 * @param line
 */
function scrollToLine(line: HTMLElement) {
  const scriptPanel = document.getElementById(SCRIPT_SCROLLING_CONTAINER_ID);
  if (scriptPanel) {
    // const sentence = document.getElementById(sentenceId);
    if (line) {
      /// we need to give time for the notation panel to be mounted
      /// otherwise it would point to the wrong thing.
      /// TODO: find a better place for this
      setTimeout(() => {
        let scrollTop: number;
        scrollTop =
          line.offsetTop - (scriptPanel.offsetHeight - line.offsetHeight) / 2;

        scriptPanel.scrollTo({
          top: scrollTop,
          behavior: 'smooth',
        });
      }, 25);
    }
  }
}

/// The top position of the notation panel is driven by a CSS variable: `--notation-panel-top`
/// which is set in the script panel itself. This function adjusts the value of that variable
/// based on the height of the notation panel and it's scroll position.

export function scrollToCurrentLine() {
  const currentLine = document.querySelector('.line.current');
  scrollToLine(currentLine as HTMLElement);
  /// force sentence visibility to true, because otherwise the change of current sentence
  /// will happen after the scroll and the observer would not update automatically
  lineScrollObserver.setIsCurrentSentenceVisible(true);
}

export const throttledScrollToCurrentLine = throttle(scrollToCurrentLine, 100);

export function positionExpandedSentence() {
  const line = document.querySelector('.line.current') as HTMLElement;
  const scriptPanel = document.getElementById(SCRIPT_SCROLLING_CONTAINER_ID);
  if (scriptPanel && line) {
    // grab all the elements that make up height of sentence
    const l2 = line.querySelector('.l2-and-translation') as HTMLElement,
      expandedPanels = line.querySelectorAll(
        '.expandable.expanded .expandable-inner > div'
      ),
      toolbarHeight = 64,
      playerHeaderHeight = 56;
    // calculate height of sentence when all active panels have finished opening
    let sentenceHeight = l2.offsetHeight + toolbarHeight;
    expandedPanels.forEach(expandable => {
      sentenceHeight += (expandable as HTMLElement).offsetHeight;
    });
    // get full sentence on screen, but prioritise top being visible
    let currentScrollPos = scriptPanel.scrollTop,
      maxScroll = line.offsetTop - playerHeaderHeight - 8,
      minScroll =
        line.offsetTop - scriptPanel.offsetHeight + sentenceHeight + 8;
    let scrollPos = Math.min(Math.max(currentScrollPos, minScroll), maxScroll);
    scriptPanel.scrollTo({
      top: scrollPos,
      behavior: 'smooth',
    });
  }
}
