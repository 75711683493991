import * as React from 'react';
import { AppFactory } from '@app/app-factory';
import { PlainMarkdown } from '@naan/primitives/text';
import { styled } from '@naan/stitches.config';
import { ChannelAvatar } from 'components/learn/dashboard/channel-avatar';
import { useSearchParams } from 'react-router-dom';
import { Button } from '@naan/primitives/button';
import { presentStoryChannelModal } from 'components/learn/dashboard/channel-modal';
import { Channel } from '@core/models/story-manager/channel';
import { ArrowRightSmallIcon } from '@naan/icons/arrow-right-icon';
import { GlobeIcon } from '@naan/icons/globe-icon';
import { ExternalLink } from '@naan/primitives/text/external-link';

import __ from '@core/lib/localization';

const Wrapper = styled('div', {
  $$backgroundColor: '$colors$gray-800',
  backgroundColor: '$$backgroundColor',
  color: '$globalWhite',
  display: 'grid',
  gridTemplateColumns: '1fr auto',
  padding: '20px 16px 16px 16px',
  gap: 16,
  '& .text': {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    '& .title': {
      textStyle: 'body-bold',
    },
    '& .description': {
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: '3',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '& .show-more-button': {
      marginLeft: -16,
    },
  },

  '& .avatar': {
    width: 96,
    '& img': {
      width: '100%',
    },
  },

  '& .external-link': {
    display: 'none',
    '& > a': {
      display: 'flex',
      alignItems: 'center',
      gap: 4,
      color: '$globalWhite-alpha-70',
      textDecoration: 'none',
      '& .icon': {
        '& svg': {
          width: 20,
          height: 20,
        },
      },
    },
  },

  '@small': {
    borderRadius: '$radii$large',
    gap: 24,
    marginTop: 16,
    marginBottom: 16,
    position: 'relative',
    '&:after': {
      content: '',
      display: 'block',
      borderTop: '1px solid $gray-100',
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: -16,
    },

    '& .text': {
      gap: 16,
      '& .title': {
        textStyle: 'heading',
      },
      '& .description': {
        display: 'block',
      },
    },

    '& .show-more-button': {
      display: 'none',
    },

    '& .external-link': {
      display: 'flex',
    },
  },

  '@medium': {
    padding: 24,
    '& .avatar': {
      width: 200,
    },
    '& .text': {
      maxWidth: 500,
    },
  },
});

export const ChannelFilterBranding = () => {
  const storyManager = AppFactory?.root?.storyManager;
  const [queryParams] = useSearchParams();

  const onShowMore = React.useCallback((channel: Channel) => {
    presentStoryChannelModal(channel);
  }, []);

  /// this two lines of code allow us to link directly to the soundbites
  /// when one of the sb filters is used.
  /// it's very unclean to couple this component to the url but it's the easiest way to do it.
  const inChannel = queryParams.get('filter_type') === 'channel';

  if (!inChannel || !storyManager) {
    return null;
  }

  const channelTitle = queryParams.get('filter_value');
  const channel = storyManager.channelByTitle(channelTitle);

  return (
    <Wrapper css={{ $$backgroundColor: channel.themeColor }}>
      <div className="text">
        <h3 className="title">{channel.title}</h3>
        <div className="description">
          <PlainMarkdown source={channel.description} />
        </div>
        <div className="show-more-button">
          <Button
            onClick={() => onShowMore(channel)}
            label={__('Show more', 'showMore')}
            size="small"
            presentation="whiteTransparent"
            rightIcon={<ArrowRightSmallIcon />}
          />
        </div>
        <div className="external-link">
          <ExternalLink href={channel.weblinkUrl}>
            <span className="icon">
              <GlobeIcon />
            </span>
            {channel.weblink}
          </ExternalLink>
        </div>
      </div>
      <div className="avatar">
        <ChannelAvatar channel={channel} />
      </div>
    </Wrapper>
  );
};

const FullScreenWrapper = styled('div', {
  width: 'calc(100% + 32px)',
  marginLeft: -16,
});

export const SmallChannelFilterBranding = () => {
  return (
    <FullScreenWrapper>
      <ChannelFilterBranding />
    </FullScreenWrapper>
  );
};
