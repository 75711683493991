import * as React from 'react';

export function DateSmallIcon({
  width = 20,
  height = 20,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 2H6V3H4.5C3.67157 3 3 3.67157 3 4.5V15.5C3 16.3284 3.67157 17 4.5 17H15.5C16.3284 17 17 16.3284 17 15.5V4.5C17 3.67157 16.3284 3 15.5 3H14V2H13V3H7V2ZM6 4V5H7V4H13V5H14V4H15.2C15.6418 4 16 4.35817 16 4.8V7H4V4.8C4 4.35817 4.35817 4 4.8 4H6ZM4 8V15.2C4 15.6418 4.35817 16 4.8 16H15.2C15.6418 16 16 15.6418 16 15.2V8H4Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}

export function DateIcon({ width = 24, height = 24, color = 'currentColor' }) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.25 2C4.83579 2 4.5 2.33579 4.5 2.75V4H3.5C2.67157 4 2 4.67157 2 5.5V18.5C2 19.3284 2.67157 20 3.5 20H20.5C21.3284 20 22 19.3284 22 18.5V5.5C22 4.67157 21.3284 4 20.5 4H19.5V2.75C19.5 2.33579 19.1642 2 18.75 2C18.3358 2 18 2.33579 18 2.75V4H6V2.75C6 2.33579 5.66421 2 5.25 2ZM21 8H3V18.2C3 18.6418 3.35817 19 3.8 19H20.2C20.6418 19 21 18.6418 21 18.2V8Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}
