import { styled } from '@naan/stitches.config';

export const CIRCLE_RADIUS = 12;

export const CircleIcon = styled('div', {
  $$color: '$colors$gray-100',
  display: 'flex',
  width: CIRCLE_RADIUS * 2,
  height: CIRCLE_RADIUS * 2,
  borderRadius: '50%',
  border: '3px solid',
  borderColor: '$$color',
  // color: '$colors$textSecondary',
  // textAlign: 'center',
  // alignItems: 'center',
  // justifyContent: 'center',
  // textStyle: 'small-text-bold',
  variants: {
    presentation: {
      darkGray: {
        $$color: '$colors$gray-400',
      },
      teal: {
        $$color: '$colors$teal-500',
      },
    },
  },
});
