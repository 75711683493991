import * as React from 'react';
import { styled } from 'naan/stitches.config';
import { ApSmallIcon } from 'naan/icons/ap-icon';
import { IbSmallIcon } from 'naan/icons/ib-icon';
import { CheckmarkSuperHeavyIcon } from 'naan/icons/checkmark-super-heavy-icon';
import { Story } from 'core/models/story-manager';
import __ from '@core/lib/localization';
import { AppFactory } from '@app/app-factory';

const Wrapper = styled('div', {
  textStyle: 'small-text',
  color: '$colors$textSecondary',
  marginBottom: 16,
  li: {
    listStyleType: 'none',
    margin: 0,
    display: 'flex',
    marginBottom: '$space$1',
  },
  'span.icon': {
    lineHeight: 0,
    color: '$gray-400',
    marginRight: '$space$1',
    '&.checkmark': {
      color: '$green-500',
    },
  },
});

export const TeacherMeta = ({ story }: { story: Story }) => {
  const { apIbTagsEnabled } = AppFactory.root;
  const hasActivityGuide = !!story.activityGuideUrl;
  const apTags = story.apTagNames;
  const ibTags = story.ibTagNames;

  return (
    <Wrapper>
      {hasActivityGuide ? (
        <li>
          <span className="icon checkmark">
            <CheckmarkSuperHeavyIcon />
          </span>
          {__('Includes Activity Guide', 'includesActivityGuide')}
        </li>
      ) : null}
      {apIbTagsEnabled && apTags.length > 0 ? (
        <li>
          <span className="icon">
            <ApSmallIcon />
          </span>
          {apTags.join(', ')}
        </li>
      ) : null}
      {apIbTagsEnabled && ibTags.length > 0 ? (
        <li>
          <span className="icon">
            <IbSmallIcon />
          </span>
          {ibTags.join(', ')}
        </li>
      ) : null}
    </Wrapper>
  );
};
