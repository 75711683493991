import * as React from 'react';
import * as Dialog from 'naan/primitives/modals/dialog';
import { observer } from 'mobx-react';
import { formatDate } from 'utils/format-date';
import { LocaleCode } from '@utils/util-types';

import __ from 'core/lib/localization';
import { BillingInterval } from '@cas-shared/cas-types';
import { useSwitch2 } from '@naan/hooks/use-switch-2';
import { PlainMarkdown } from '@naan/primitives/text';
import { billingIntervalL1 } from '@core/lib/localization-helpers';

const GrandfatherCancelSubscriptionDialog = observer(
  ({
    onOk,
    onDismiss,
    grandfatheredPrice, // formatted
    latestPrice, // formatted
    interval,
  }: {
    onOk: () => void;
    onDismiss: () => void;
    grandfatheredPrice: string;
    latestPrice: string;
    interval: BillingInterval;
  }) => {
    // const productName = AppFactory.root.productNameForL2(l2);
    const intervalL1 = billingIntervalL1(interval);

    return (
      <Dialog.Container
        onDismiss={onDismiss}
        open={true}
        /// @frankharrison I made it slightly longer to accommodate the title
        css={{ $$maxWidth: '420px' }}
      >
        <Dialog.Heading>
          {__(
            "Don't lose your special subscription rate",
            'dontLoseYourSpecialSubscriptionRate'
          )}
        </Dialog.Heading>
        <Dialog.Body>
          <PlainMarkdown
            source={__(
              'As an early subscriber you are still enjoying your original rate of **%{originalPricing}**. After you cancel, any new subscription will be at the latest rate, currently **%{currentPricing}**.',
              'asAnEarlySubscriberYouAreStillEnjoyingYourOriginalRate',
              {
                originalPricing: `${grandfatheredPrice}/${intervalL1}`,
                currentPricing: `${latestPrice}/${intervalL1}`,
              }
            )}
          />
        </Dialog.Body>
        <Dialog.ButtonsContainer>
          <Dialog.Button
            onClick={onDismiss}
            label={__('Go back', 'goBack')}
            presentation="cancel"
          />
          <Dialog.Button
            onClick={() => {
              onOk();
            }}
            label={__("It's ok, I understand", 'itsOkIUnderstand')}
            presentation={'redLight'}
          />
        </Dialog.ButtonsContainer>
      </Dialog.Container>
    );
  }
);

const ConfirmCancelSubscriptionDialog = observer(
  ({
    onOk,
    onDismiss,
    fullAccessUntil,
  }: {
    onOk: () => void;
    onDismiss: () => void;
    fullAccessUntil: string;
  }) => {
    // const productName = AppFactory.root.productNameForL2(l2);

    return (
      <Dialog.Container onDismiss={onDismiss} open={true}>
        <Dialog.Heading>
          {__('Are you sure you want to cancel?', 'areYouSureYouWantToCancel')}
        </Dialog.Heading>
        <Dialog.Body>
          {!!fullAccessUntil
            ? __(
                "By canceling your subscription you won't receive any further charges, and your access will end on {{date}}",
                'cancelSubscriptionBodyWithAccess',
                {
                  date: formatDate(fullAccessUntil),
                }
              )
            : __(
                "By canceling your subscription you won't receive any further charges.",
                'cancelSubscriptionBodyWithoutAccess'
              )}
        </Dialog.Body>
        <Dialog.ButtonsContainer>
          <Dialog.Button
            onClick={onDismiss}
            label={__('Go back', 'goBack')}
            presentation="cancel"
          />
          <Dialog.Button
            onClick={() => {
              onOk();
              setTimeout(() => {
                onDismiss();
              }, 100);
            }}
            label={__('Cancel subscription', 'cancelSubscription')}
            presentation={'redLight'}
          />
        </Dialog.ButtonsContainer>
      </Dialog.Container>
    );
  }
);

export const CancelSubscriptionDialog = observer(
  ({
    onOk,
    onDismiss,
    fullAccessUntil,
    grandfatheredPrice, // formatted
    latestPrice, // formatted
    interval,
  }: {
    onOk: () => void;
    onDismiss: () => void;
    l2: LocaleCode;
    fullAccessUntil: string;
    grandfatheredPrice: string;
    latestPrice: string;
    interval: BillingInterval;
  }) => {
    const gfSwitch = useSwitch2(!!grandfatheredPrice);

    if (gfSwitch.value) {
      return (
        <GrandfatherCancelSubscriptionDialog
          onOk={gfSwitch.off}
          onDismiss={onDismiss}
          grandfatheredPrice={grandfatheredPrice}
          latestPrice={latestPrice}
          interval={interval}
        />
      );
    }

    return (
      <ConfirmCancelSubscriptionDialog
        onOk={onOk}
        onDismiss={onDismiss}
        fullAccessUntil={fullAccessUntil}
      />
    );
  }
);
