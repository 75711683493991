import * as React from 'react';

export function LightningIconSmall({
  width = 20,
  height = 20,
  color = 'currentColor',
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.1821 7.36264L11.7025 2.04141C11.7634 1.82802 11.4833 1.68892 11.3501 1.86647L4.86 10.52C4.71167 10.7178 4.85279 11 5.1 11H9.35961C9.6849 11 9.92358 11.3057 9.84468 11.6213L8.27162 17.9135C8.21747 18.1301 8.50512 18.2592 8.6309 18.0747L15.1802 8.469C15.316 8.26986 15.1734 8 14.9324 8H10.6629C10.3307 8 10.0908 7.68205 10.1821 7.36264Z"
        fill={color}
      />
    </svg>
  );
}
