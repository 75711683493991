import React from 'react';
import { observer } from 'mobx-react';
import { NextSentenceControlIcon } from '../../../study/views/player-icons';
import { ControlButton } from './control-button';
import { usePlayerModel } from '../player-model-context';

export const NextSentenceButton = observer(() => {
  const model = usePlayerModel();
  return (
    <ControlButton
      onClick={() => model.seekNextLine()}
      disabled={!model.canSeekNextLine}
    >
      <NextSentenceControlIcon />
    </ControlButton>
  );
});
