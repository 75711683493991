import * as React from 'react';

export function HeadphonesIcon({
  width = 24,
  height = 24,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 4C7.65065 4 4 7.82222 4 12.697V13.5C4 12.6716 4.67157 12 5.5 12C6.32843 12 7 12.6716 7 13.5V20.5C7 21.3284 6.32843 22 5.5 22C4.67157 22 4 21.3284 4 20.5V19C2.89543 19 2 18.1046 2 17V12.697C2 6.86075 6.40822 2 12 2C17.5918 2 22 6.86075 22 12.697V17C22 18.1046 21.1046 19 20 19V20.5C20 21.3284 19.3284 22 18.5 22C17.6716 22 17 21.3284 17 20.5V13.5C17 12.6716 17.6716 12 18.5 12C19.3284 12 20 12.6716 20 13.5V12.697C20 7.82222 16.3493 4 12 4Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}

export function HeadphonesExtraExtraLargeIcon({
  width = 40,
  height = 40,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 37C29.3888 37 37 29.3888 37 20C37 10.6112 29.3888 3 20 3C10.6112 3 3 10.6112 3 20C3 29.3888 10.6112 37 20 37ZM20 11C15.6507 11 12 14.8223 12 19.697V20.5C12 19.6716 12.6716 19 13.5 19C14.3284 19 15 19.6716 15 20.5V27.5C15 28.3285 14.3284 29 13.5 29C12.6716 29 12 28.3285 12 27.5V26C10.8954 26 10 25.1046 10 24V19.697C10 13.8608 14.4082 9.00004 20 9.00004C25.5918 9.00004 30 13.8608 30 19.697V24C30 25.1046 29.1046 26 28 26V27.5C28 28.3285 27.3284 29 26.5 29C25.6716 29 25 28.3285 25 27.5V20.5C25 19.6716 25.6716 19 26.5 19C27.3284 19 28 19.6716 28 20.5V19.697C28 14.8223 24.3493 11 20 11Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </svg>
  );
}
