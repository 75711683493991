import * as React from 'react';
import { observer } from 'mobx-react';
import { ClientNotation } from '@tikka/client/client-types';
import { styled } from '@naan/stitches.config';
import { PlainMarkdown } from '@naan/primitives/text';
import { paletteKey } from '../../../../../study/views/study-palette';

const HeadWrapper = styled('div', {
  cursor: 'default',
  '& dt': {
    textStyle: 'serif',
    // color: 'blue',
    marginBottom: -2,
  },
  '& dd': {
    textStyle: 'body',
    color: paletteKey('$$sentence__inlineTranslationColor'),
  },
});

export const VocabListItemHead = observer(
  ({ notation }: { notation: ClientNotation }) => {
    // todo: factor with vocab review and notatil detail
    const text = !!notation.usageText
      ? `${notation.usageText} (${notation.headword})`
      : notation.headword;

    return (
      <HeadWrapper>
        <dl>
          <dt>
            <PlainMarkdown source={text} />
          </dt>
          {notation.note ? (
            <dd>
              <PlainMarkdown source={notation.note} />
            </dd>
          ) : null}
        </dl>
      </HeadWrapper>
    );
  }
);
