import * as React from 'react';
import { Assignment, Classroom } from 'core/models/user-manager';

interface TCurrentAssignmentContext {
  classroom: Classroom;
  assignment: Assignment;
  deleteAssignment: (slug: string) => void;
}

export const CurrentAssignmentContext =
  React.createContext<TCurrentAssignmentContext>({} as any);

export const useCurrentAssignment = () =>
  React.useContext(CurrentAssignmentContext);
