import * as React from 'react';

import { observer } from 'mobx-react';
import { Button } from 'naan/primitives/button';
import { FormError } from 'naan/primitives/forms/form-error';
import { useAfterAuthRedirection } from 'lib/hooks/use-after-auth-redirection';
import { useComboForm } from 'common/hooks/use-comboform';
import { Label } from 'naan/primitives/forms/label';
import { Input } from 'naan/primitives/input/input';
import { FNAME_MAX_CHAR_LIMIT } from 'core/lib/constants/vars';
import { useNavigate } from 'react-router-dom';
import { CatchAllFormErrors } from '@naan/primitives/forms/catch-all-form-error';
import { omit } from 'lodash';
import { AuthHeading } from 'components/auth/auth-heading';
import { BackButton } from 'components/page-heading';
import { getSignUpModel } from './sign-up-model';
import { AppFactory } from '@app/app-factory';
import { PagingPills } from './paging-pills';
import { OnboardingAuthLayout } from './onboarding-auth-layout';
import { VSpacer } from '@naan/primitives/spacer';
import { createAccountPath } from 'components/nav/path-helpers';
import { nonBreakingHyphens } from '@utils/string-utils';

import __ from 'core/lib/localization';

type FormInput = {
  name: string;
};

export const SignUpNameScreen = observer(() => {
  // useTitle(__('Get started', 'getStarted'));
  const navigate = useNavigate();

  useAfterAuthRedirection();

  const { storyManager } = AppFactory.root;

  const { register, handleSubmit, errors } = useComboForm<FormInput>({});

  const submitHandler = handleSubmit(({ name }) => {
    const model = getSignUpModel();
    model.reset();
    model.name = name;
    navigate(`${createAccountPath()}/email`, { replace: true });
  });

  const backPath = storyManager.onboardingStoryPath;

  return (
    <OnboardingAuthLayout>
      {/* <BackButton action={() => navigate(-1)} /> */}
      <div className="back-link">
        <BackButton action={() => navigate(backPath, { replace: true })} />
      </div>
      <div className="inner">
        <PagingPills total={2} currentIndex={0} />
        <AuthHeading>
          {nonBreakingHyphens(
            __('What shall we call you?', 'whatShallWeCallYou')
          )}
        </AuthHeading>
        <form onSubmit={submitHandler}>
          <div className="form-contents">
            {/* // make sure invite code error is shown if mismatch between client config and server config */}
            {/* // consider always including, but making the invite code conditionaly omitted */}
            <CatchAllFormErrors
              errors={omit(errors, ['name'])}
              data-test-id="error-message"
            />

            <Label htmlFor="name">{__('First name', 'firstName')}</Label>
            <VSpacer size="2" />
            <Input
              name={'name'}
              data-test-id={'first-name'}
              type="text"
              {...register('name')}
              required
              maxLength={FNAME_MAX_CHAR_LIMIT}
            />
            <FormError
              error={errors['name']}
              data-test-id="name-error-message"
            />
          </div>

          <Button
            label={__('Next', 'next')}
            onClick={handleSubmit as any}
            presentation="teal"
            size="large"
            data-test-id="create-account-btn"
            fullWidth
          />
        </form>
      </div>
    </OnboardingAuthLayout>
  );
});
