// imported by base config
// keep as clean as possible

export const logLevels = {
  none: 0,
  trace: 1,
  debug: 2,
  info: 3,
  warn: 4,
  error: 5,
  fatal: 6,
} as const;

export type LogLevelKey = keyof typeof logLevels;

export type LogLevel = typeof logLevels[LogLevelKey];

export type LogFunction = typeof console.log;
