import * as React from 'react';
import { styled } from 'naan/stitches.config';
import { Tag } from './tag';

const InteractiveTagStyled = styled(Tag, {
  cursor: 'default',
  '&:hover': {
    backgroundColor: '$$hoverBackgroundColor',
    borderColor: '$$hoverBorderColor',
    color: '$$hoverColor',
  },
  variants: {
    selected: {
      true: {
        backgroundColor: '$teal-500',
        borderColor: '$teal-500',
        color: '$white',
        '&:hover': {
          backgroundColor: '$teal-600',
          borderColor: '$teal-500',
          color: '$white',
        },
      },
    },
  },
});

type InteractiveTagStyledProps = React.ComponentProps<
  typeof InteractiveTagStyled
>;

type InteractiveTagProps = Omit<InteractiveTagStyledProps, 'onSelect'> & {
  selected?: boolean;
  onSelect: () => void;
};

export const InteractiveTag = ({
  selected = false,
  onSelect,
  ...props
}: InteractiveTagProps) => {
  return (
    <InteractiveTagStyled {...props} selected={selected} onClick={onSelect} />
  );
};
