import * as React from 'react';
import { VSpacer } from 'naan/primitives/spacer';
import * as Dialog from 'naan/primitives/modals/dialog';
import { observer } from 'mobx-react';
import { VStack } from 'naan/primitives/stack';
import { Text } from 'naan/primitives/text';
import { styled } from 'naan/stitches.config';
import { useComboForm } from 'common/hooks/use-comboform';
import { classNameSchema } from 'core/lib/validation-rules';
import { AppFactory } from 'app/app-factory';
import { CatchAllFormErrors } from 'naan/primitives/forms/catch-all-form-error';

import __ from 'core/lib/localization';
import { Classroom } from 'core/models/user-manager';
import { DialogHtmlContainer } from 'components/ui/dialog-html-container';
import { resolveApiMessage } from '@core/models/user-manager/api-messages';

const Input = styled('input', {
  all: 'unset',
  backgroundColor: '$black-alpha-06',
  textStyle: 'body',
  color: '$textPrimary',
  py: '$space$3',
  px: '$space$4',
  borderRadius: '$radii$medium',
  '&::placeholder': {
    color: '$gray-300',
  },
});

type FormInput = {
  label: string;
};

type ClassCreationModalProps = {
  onDismiss: () => void;
  classroom: Classroom;
};
export const ClassRenamingModal = observer(
  ({ onDismiss, classroom }: ClassCreationModalProps) => {
    const formRef = React.useRef<HTMLFormElement>(null);
    const defaultValues = { label: classroom.label };
    const {
      register,
      handleSubmit,
      errors,
      clearValidationErrors,
      handleValidationErrors,
      watch,
    } = useComboForm<FormInput>({
      validationSchema: classNameSchema(),
      defaultValues,
    });

    const handleRename = React.useCallback(
      ({ label }: FormInput) => {
        clearValidationErrors();

        classroom.updateLabel(label).then(result => {
          onDismiss();
          const message = resolveApiMessage(result);
          AppFactory.toastService.open({
            message,
            type: 'success',
          });

          // onSelectClass(result.classroom.id);
        }, handleValidationErrors);
      },
      [clearValidationErrors, classroom, handleValidationErrors, onDismiss]
    );

    const valueWatch = watch('label');

    // React.useEffect(() => {
    //   if (formRef.current) {
    //     const input = formRef.current.querySelector('input[type=text]');
    //     if (input instanceof HTMLInputElement) {
    //       input.focus();
    //       input.select();
    //     }
    //   }
    // }, [userManager]);

    return (
      <DialogHtmlContainer onDismiss={onDismiss}>
        <form onSubmit={handleSubmit(handleRename)} noValidate ref={formRef}>
          <Dialog.HtmlHeading>
            {__('Rename class', 'renameClass')}
          </Dialog.HtmlHeading>
          <Dialog.Body>
            <VStack>
              <VSpacer size={4} />
              <label htmlFor="label">
                <Text textStyle="small-text" color="textSecondary">
                  {__('Enter new name for this class', 'enterNewLabelForClass')}
                </Text>
              </label>
              <VSpacer size={2} />
              <Input
                type="text"
                id="label"
                {...register('label')}
                placeholder={__('E.g. Fall Spanish 2', 'enterLabelPlaceholder')}
              />
              <VSpacer size={4} />
              <CatchAllFormErrors errors={errors} />
            </VStack>
          </Dialog.Body>
          <Dialog.ButtonsContainer>
            <Dialog.Button
              label={__('Cancel', 'cancel')}
              presentation="cancel"
              type="button"
              onClick={onDismiss}
            />
            <Dialog.Button
              label={__('Rename class', 'renameClass')}
              presentation="teal"
              disabled={!valueWatch}
            />
          </Dialog.ButtonsContainer>
        </form>
      </DialogHtmlContainer>
    );
  }
);
