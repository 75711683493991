import * as React from 'react';
import { observer } from 'mobx-react';
import { AppFactory } from 'app/app-factory';
import { createLogger } from '@common/log';
import { NetworkErrorScreen } from 'routes/network-error-screen';
import { AppInitStatus } from '@core/models/app-root';

const log = createLogger('ensure-ready');

// don't render anything until app root fully initialized
export const EnsureReady = observer(({ children }: { children: any }) => {
  const { status } = AppFactory.root;
  log.debug(`status: ${status}`);

  // React.useEffect(() => {
  //   if (!authenticated && !authSkipped) {
  //     log.info('anonymous deep link detected, skipping auth');
  //     userManager.skipAuth().catch(error =>
  //       alertWarningError({
  //         error,
  //         note: 'ensure-ready - anonymous deep link',
  //       })
  //     );
  //   }
  // }, [userManager, authenticated, authSkipped]);

  if (status === AppInitStatus.READY) {
    return children;
  } else {
    if (status === AppInitStatus.STARTUP_FAILURE) {
      // return <h3>Unexpected startup failure</h3>;
      return <NetworkErrorScreen />;
    }
    return null;
  }
});
