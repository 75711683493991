import * as React from 'react';
import { observer } from 'mobx-react';
import { AppFactory } from 'app/app-factory';
import { LastListenedWidget } from './started-widget';
import { LastQueuedWidget } from './queued-widget';
import { CenterColumnLayout } from 'components/layouts/center-column-layout';
import { LearnDashboardHeading } from './dashboard-heading';
import { QuickLinks } from 'components/account/quick-links';
import { SmallGap, MediumGap, LargeGap } from '@naan/primitives/spacer/gaps';
// import { SoundbiteEtAlWidgetGroup } from './soundbite-et-al-widget-group';
import { DeepScrollRestoration } from 'lib/scroll-utils/deep-scroll-restoration';
import { useLocation } from 'react-router-dom';
import { FeaturedContent } from './featured-content';
import { TeacherWelcomeMessage } from '../welcome-message/full-access-welcome-message';

// import { NewsletterDialog } from 'components/account/newsletter-dialog';
import { AboutRadioAmbulante } from './about-ra';
import { BodyProxy } from 'components/ui/body-proxy';
import { FreeStoryWidget } from './free-story-widget';
import { DashboardWidgets } from './dashboard-widgets';
import { ClassroomWidgetList } from './classroom-widget-list';
import { GlobalFooter } from 'components/global-footer';
import { VocabToReviewWidget } from './vocab-to-review-widget';
import { WidgetColumnContainer } from './widget-column-container';
import { NewStoryWidget } from './new-story-widget';

// full-access flavor

export const LearnDashboard = observer(() => {
  const { storyManager, userManager, raBranding } = AppFactory.root;
  const location = useLocation();

  const { showFreeStoryWidget, featuredInProgress, featuredVocabToReview } =
    storyManager;
  const featuredQueued = !featuredInProgress
    ? storyManager.featuredQueued
    : null;

  // todo: also add classroom widget stories to exclude list
  const toReviewExcluded = [featuredInProgress, featuredQueued];
  const classrooms = userManager.accountData.joinedClassroomsWithAssignments;
  for (const classroom of classrooms) {
    toReviewExcluded.push(classroom.featuredAssignment?.story);
  }

  return (
    <>
      <BodyProxy className={'__no-scroll'} />
      <DeepScrollRestoration pageKey={location.pathname} />
      <LearnDashboardHeading />

      <CenterColumnLayout isolate>
        <TeacherWelcomeMessage />
      </CenterColumnLayout>
      {showFreeStoryWidget ? <FreeStoryWidget /> : null}
      <WidgetColumnContainer
        isolate
        className={showFreeStoryWidget ? 'with-free-story-widget' : ''}
      >
        <ClassroomWidgetList />

        {/* // only one of these two will be non-null */}
        {featuredInProgress ? (
          <>
            <LastListenedWidget story={featuredInProgress} />
            <SmallGap />
          </>
        ) : null}
        {featuredQueued ? (
          <>
            <LastQueuedWidget story={featuredQueued} />
            <SmallGap />
          </>
        ) : null}

        <VocabToReviewWidget
          stories={featuredVocabToReview}
          excluded={toReviewExcluded}
        />
        <DashboardWidgets />
        <MediumGap />
        <NewStoryWidget />
        <FeaturedContent />
        <MediumGap />
        {raBranding ? (
          <>
            <AboutRadioAmbulante />
            <MediumGap />
          </>
        ) : null}
        <QuickLinks />
        <LargeGap />
        <GlobalFooter />
        {/* <NavLink to="/logout">[logout]</NavLink> */}
      </WidgetColumnContainer>
      {/* <NewsletterDialog /> */}
    </>
  );
});
