import * as React from 'react';

export const focusRestorerId = '__naan-focus-restorer';
export const FocusRestorer = () => {
  // make sure there's only one of these in the DOM at a time
  const existingElement = document.getElementById(focusRestorerId);
  if (existingElement) {
    return null;
  }
  return (
    <button
      id={focusRestorerId}
      style={{ position: 'absolute', left: -1000, top: -1000 }}
    />
  );
};
