import React from 'react';
import { observer } from 'mobx-react';
import { makeFilterLink } from '@common/filtering/make-filter-link';
import { learnStoriesPath } from 'components/nav/path-helpers';
import { AppFactory } from '@app/app-factory';
import {
  SectionHeading,
  SectionHeadingLink,
} from './dashboard-section-heading';
import { Responsive } from '@naan/primitives/responsive';
import { StoryRow } from './story-row';
import __ from '@core/lib/localization';
import { SoundbiteFilterValues } from '@core/models/story-manager/story';
import { SeededRandom } from '@utils/seed-random';

export const SoundbiteStoriesWidget = observer(() => {
  const { storyManager } = AppFactory.root;
  const filterPath = makeFilterLink(learnStoriesPath(), 'sb', [
    SoundbiteFilterValues.withUnstartedFn(),
  ]);
  const filteredStories = storyManager.withUncompletedSoundbites;
  const seed = storyManager.daysSinceEpoch;
  const random = new SeededRandom(seed);
  const stories = random.shuffleArray(filteredStories);

  return (
    <>
      <SectionHeading
        title={__('Stories with Soundbites', 'storiesWithSoundbites')}
        renderLinks={() => (
          <SectionHeadingLink to={filterPath}>
            {__('Show all', 'showAll')}
          </SectionHeadingLink>
        )}
      />

      <Responsive
        renderDefault={() => <StoryRow stories={stories} n={2} />}
        renderSmallAndUp={() => <StoryRow stories={stories} n={3} />}
        renderMediumAndUp={() => <StoryRow stories={stories} n={4} />}
      />
    </>
  );
});
