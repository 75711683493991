import * as React from 'react';
import { darkThemeSelector, styled } from '@naan/stitches.config';

const Wrapper = styled('div', {
  color: '$globalBlack-alpha-60',
  background: '$colors$yellow-300',
  [darkThemeSelector]: {
    background: '$colors$yellow-600',
  },
  textStyle: 'body-bold',
  padding: '8px 0',
  marginInline: 8,
  position: 'relative',

  '.edge': {
    color: '$yellow-300',
    [darkThemeSelector]: {
      color: '$yellow-600',
    },
    position: 'absolute',
    '&.l': {
      left: -8,
      top: 0,
    },
    '&.r': {
      transform: 'rotate(180deg)',
      right: -8,
      top: 0,
    },
  },
});

const Edge = (
  <svg
    width={8}
    height={40}
    viewBox="0 0 8 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 0H8V40H0L8 21L0 0Z"
      fill="currentColor"
    />
  </svg>
);

export const Ribbon = ({ title }: { title: string }) => {
  return (
    <Wrapper>
      <span className="edge l">{Edge}</span>
      {title}
      <span className="edge r">{Edge}</span>
    </Wrapper>
  );
};
