import React from 'react';
import { observer } from 'mobx-react';
import { FFControlIcon } from '../../../study/views/player-icons';
import { ControlButton } from './control-button';
import { usePlayerModel } from '../player-model-context';

export const ForwardButton = observer(
  ({ disabled }: { disabled?: boolean }) => {
    const model = usePlayerModel();
    const disableButton = disabled || !model.canSeekNextNavStop;
    return (
      <ControlButton onClick={() => model.forward()} disabled={disableButton}>
        <FFControlIcon />
      </ControlButton>
    );
  }
);
