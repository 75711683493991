import { styled } from 'naan/stitches.config';
import { makeSizeVariants } from './size-variants';

const sizeVariants = makeSizeVariants('$$stackGap');

export const HSpacer = styled('div', {
  width: '$$stackGap',
  variants: {
    size: {
      ...sizeVariants,
    },
    expand: {
      true: {
        flex: 1,
      },
    },
  },
});
