import { createLogger } from 'app/logger';
import { BaseCacher, CacherOptions } from './base-cacher';
import { bugsnagNotify } from './services/notification-service';

const log = createLogger('asset-cacher');

export class AssetCacher extends BaseCacher {
  static async create(name: string, options: CacherOptions) {
    log.debug(`create(${name})`);
    const instance = new AssetCacher(name, options);
    await instance.init();
    return instance;
  }

  async add(url: string): Promise<void> {
    if (this.enabled) {
      try {
        await this.cache.add(url);
        log.info(`added to cache: ${url}`);
      } catch (error) {
        this.disable();
        // @elliottjf I think we should throw a custom error we can handle in the UI
        log.error(`error caching asset - ${url}, ${error}`);
        // alertWarningError({ error, note: 'asset-cacher - add' });
        bugsnagNotify(error as Error);
        bugsnagNotify('Cache store failed');
      }
    }
  }

  async addAll(urls: string[]): Promise<boolean> {
    if (this.enabled) {
      try {
        await this.cache.addAll(urls);
        return true;
      } catch (error) {
        this.disable();
        log.error(`error caching assets - ${String(urls)}, ${error}`);
        bugsnagNotify(error as Error);
        bugsnagNotify('Cache store failed');
        return false;
      }
    }
    return false;
  }

  async isCached(url: string): Promise<boolean> {
    if (this.enabled) {
      try {
        const cached = await this.cache.match(url);
        return !!cached;
      } catch (error) {
        this.disable();
        bugsnagNotify(error as Error);
        bugsnagNotify('Cache match failed');
      }
    }
    return false;
  }

  async ensureCached(url: string): Promise<void> {
    if (this.enabled) {
      try {
        const cached = await this.isCached(url);
        if (!cached) {
          await this.add(url);
        }
      } catch (error) {
        this.disable();
        bugsnagNotify(error as Error);
        bugsnagNotify('Cache match failed');
      }
    }
  }

  // // not currently used
  // async fetchResponse(url: string): Promise<Response> {
  //   const cached = await this.cache.match(url);
  //   if (cached) {
  //     return cached;
  //   } else {
  //     throw new Error('asset not found in cache');
  //   }
  // }

  async remove(url: string): Promise<void> {
    // allow the removal attempt even if disabled
    try {
      await this.cache?.delete(url);
    } catch (error) {
      // this.disable();
      bugsnagNotify(error as Error);
      bugsnagNotify('Cache remove failed');
    }
  }

  // returns local blob url if already cached, otherwise the passes through the source url
  // neccessary when construction an HTMLAudioElement
  async maybeCachedUrl(url: string): Promise<string> {
    if (this.disabled) {
      return url;
    }

    try {
      const cachedResponse = await this.cache?.match(url);
      if (cachedResponse) {
        const blob = await cachedResponse.blob();
        const cachedUrl: string = URL.createObjectURL(blob);
        log.info(`using cache for url: ${url} -> ${cachedUrl}`);
        return cachedUrl;
      }
    } catch (error) {
      this.disable();
      log.warn(`cache fetch error: ${error}, for url: ${url}`);
      bugsnagNotify(error as Error);
      bugsnagNotify(`Cache fetch failed - falling back to source URL`);
    }
    return url;
  }

  // more convenient when passing along to a fetch anyways
  async maybeCachedResponse(url: string): Promise<Response> {
    if (this.enabled) {
      const cachedResponse = await this.cache.match(url);
      if (cachedResponse) {
        return cachedResponse;
      }
    }
    return fetch(url);
  }
}
