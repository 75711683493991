import * as React from 'react';

import { darkThemeSelector, styled } from '@naan/stitches.config';
import { ArrowLeftIcon } from '@naan/icons/arrow-left-icon';
import { IconButton } from '@naan/primitives/button';
// import { Responsive } from '@naan/primitives/responsive';
// import { StoryStatusBar } from 'components/story-status/story-status-bar';
import { observer } from 'mobx-react';
import { storyDetailUiModel } from 'components/story-detail/story-detail-ui-model';
import { useCurrentStory } from 'components/story-detail/current-story-context';
import { MarkSimple } from '@naan/primitives/text';
import { scrollContainerTo } from 'lib/scroll-utils';
import { TitleStatus } from 'components/stories/story-title';
import { Story } from '@core/models/story-manager';

function titleClickHandler(e: React.MouseEvent<HTMLDivElement>) {
  // exclude .downloaded-badge, button elements, and the overflow menu
  if (
    (e.target as HTMLElement).closest(
      '.downloaded-badge, button, .overflowMenu'
    )
  ) {
    return;
  }
  scrollContainerTo(0, 'smooth');
}

const BackButton = ({ backAction }: { backAction: () => void }) => (
  <IconButton
    presentation={'whiteTransparent'}
    icon={<ArrowLeftIcon />}
    onClick={backAction}
    testId="story-header-back-button"
  />
);

const HeaderContainer = styled('div', {
  $$backgroundColor: 'transparent',
  $$darkBackgroundColor: 'transparent',
  display: 'flex',
  flex: 1,
  flexDirection: 'row',
  height: '56px',
  padding: '0 6px',
  alignItems: 'center',
  justifyContent: 'space-between',
  transition: 'background .5s',
  background: '$$backgroundColor',

  [darkThemeSelector]: {
    background: '$$darkBackgroundColor',
  },

  '& .title': {
    cursor: 'default',
    userSelect: 'none',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 4,
    minWidth: 0,
    textAlign: 'center',
  },
  '& .title-text': {
    color: '$globalWhite',
    textStyle: 'body-bold',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    '@medium': {
      textStyle: 'small-heading',
    },
  },

  '& .right': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  '& .accessory': {
    alignSelf: 'center',
    marginLeft: 8,
  },
});

type StoryHeaderSimpleProps = {
  backAction: () => void;
  backgroundColor: string;
  darkBackgroundColor: string;
  solidHeader: boolean;
  title: string; //React.ReactNode;
  showTitle: boolean;
  // statusBar?: React.ReactNode;
  accessory: React.ReactNode;
};

const StoryHeaderSimple = ({
  backAction,
  backgroundColor = 'currentColor',
  darkBackgroundColor = 'currentColor',
  solidHeader,
  title,
  showTitle,
  accessory,
  story,
}: StoryHeaderSimpleProps & { story?: Story }) => {
  const bgColor = solidHeader ? backgroundColor : null;
  return (
    <HeaderContainer
      css={
        solidHeader
          ? {
              $$backgroundColor: bgColor,
              $$darkBackgroundColor: darkBackgroundColor,
            }
          : null
      }
      onClick={titleClickHandler}
    >
      <BackButton backAction={backAction} />
      {showTitle ? (
        <div className="title">
          {story ? (
            <TitleStatus presentation="white" size="small" story={story} />
          ) : null}
          <div className="title-text">
            <MarkSimple source={title} />
          </div>
        </div>
      ) : null}
      <div className="right">{accessory}</div>
    </HeaderContainer>
  );
};

type StoryHeaderProps = {
  backAction: () => void;
  accessory: React.ReactNode;
};

export const StoryHeader = observer(
  ({ backAction, accessory: overflowMenu }: StoryHeaderProps) => {
    const showTitle = storyDetailUiModel.showTitleInHeader;
    const solidHeader = storyDetailUiModel.solidHeader;
    const story = useCurrentStory();
    const { themePalette, title } = story;

    const { themeColor, darkThemeColor } = themePalette;

    return (
      <StoryHeaderSimple
        backAction={backAction}
        backgroundColor={themeColor}
        darkBackgroundColor={darkThemeColor}
        title={title}
        showTitle={showTitle}
        solidHeader={solidHeader}
        accessory={overflowMenu}
        story={story}
      />
    );
  }
);
