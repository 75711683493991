// useful only in the spa
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { createLogger } from 'app/logger';

import { objectFromUrlQuery } from 'common/object-from-url-query';
import { AppFactory } from 'app/app-factory';
import { homePath, timedOfferPath } from 'components/nav/path-helpers';

const log = createLogger('use-after-auth-redirection');

const clearQuery = () => {
  log.info('clearing query params');
  var currentUrl = window.location.href.split('?')[0];
  window.history.replaceState({}, '', currentUrl);
};

export const useAfterQueryVar = (
  defaultValue: string
): [string, () => void] => {
  let redirectAfter = defaultValue;

  const sskey = 'redirect-after-auth';
  const { after: afterFromQuery = null, flow } =
    objectFromUrlQuery<{ after: string | null; flow?: string }>();

  // we want to keep the query params if we have marketing params
  if (flow === undefined) {
    clearQuery();
  }

  if (afterFromQuery) {
    try {
      window.sessionStorage.setItem(sskey, afterFromQuery);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('User disabled localStorage');
    }
    redirectAfter = afterFromQuery;
  } else {
    try {
      const afterFromStorage = window.sessionStorage.getItem(sskey);
      if (afterFromStorage) {
        redirectAfter = afterFromStorage;
      }
    } catch (error) {}
  }

  const clear = () => {
    clearQuery();
    try {
      window.sessionStorage.removeItem(sskey);
    } catch (error) {}
  };

  // this is a hack to fix the issue where the redirectAfter is double encoded
  if (redirectAfter && redirectAfter.indexOf('%') > -1) {
    redirectAfter = decodeURIComponent(redirectAfter);
  }

  return [redirectAfter, clear];
};

export const useAfterAuthRedirection = () => {
  const navigate = useNavigate();
  const { userManager } = AppFactory.root;

  const [redirectAfter, clearAfter] = useAfterQueryVar('');
  const log = createLogger('auth-redirection-hook');
  log.debug(
    `hook body - loggedInAndReady: ${userManager.loggedInAndReady}, authenticated: ${userManager.authenticated}`
  );

  useEffect(() => {
    const { loggedInAndReady } = userManager;
    log.debug(
      `inside useEffect - loggedInAndReady: ${loggedInAndReady}, authenticated: ${userManager.authenticated}`
    );

    if (loggedInAndReady) {
      log.info('just logged in, will redirect to', redirectAfter);
      // todo: confirm full logic here. for now, only interject offer screen
      // for the onboarding flow, but not other `after` flows
      const { timedDiscountElgible } = userManager.membership;
      const bypassTimedDiscount =
        !!redirectAfter && !redirectAfter.includes('/stories'); // onboarding contuation path
      let path = redirectAfter ? redirectAfter : homePath();
      // todo: confirm exact dynamics of various redirec flows
      if (
        timedDiscountElgible &&
        userManager.userData.accountJustCreated &&
        !bypassTimedDiscount
      ) {
        path = timedOfferPath();
      } else {
        clearAfter(); // maintain original 'after' state for offer screen 'maybe later' action
      }
      log.info(`resolved path: ${path}`);
      navigate(path, { replace: true });
    } else {
      log.info('Signed out, after auth, should redirect to', redirectAfter);
    }
    // we only care about the logged in status changing
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userManager.loggedInAndReady]);

  return redirectAfter;
};
