import React from 'react';
import { observer } from 'mobx-react';
import { CloseAccountLink } from '../close-account-link';
import { MediumGap } from '@naan/primitives/spacer/gaps';
import { NameForm } from './name-form';
import { EmailForm } from './email-form';
import { PasswordForm } from './password-form';
import { SectionHeading } from 'components/learn/dashboard/dashboard-section-heading';
import { AccountTableContainer } from './account-table';

import __ from 'core/lib/localization';
// import { DevToolsLink } from 'components/account/account-form/dev-links';

export const AccountForm = observer(() => {
  return (
    <>
      <SectionHeading title={__('My details', 'myDetails')} />
      <AccountTableContainer>
        <NameForm />
        <EmailForm />
        <PasswordForm />
      </AccountTableContainer>
      {/* <DevToolsLink /> */}
      <CloseAccountLink />
      <MediumGap />
    </>
  );
});
