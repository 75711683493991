import React from 'react';
import { observer } from 'mobx-react';
import { Table } from 'components/ui/tables';
import { ProgressCell } from './progress-cell';
import { VocabCountCell } from './vocab-count-cell';
import { StudentNameCell } from '../table-cells/student-name-cell';
import { StudentEmailCell } from '../table-cells/student-email-cell';
import { StudentTimeStudiedCell } from '../table-cells/student-time-studied-cell';
import { Text } from 'naan/primitives/text';
import { useCurrentAssignment } from './current-assignment-context';
import { StudentProgress } from 'core/models/user-manager';

import __ from 'core/lib/localization';
import { bugsnagNotify } from '@app/notification-service';
import { assignmentUiModel } from './assignment-ui-model';
import { StudentVocabModal } from 'components/story-detail/vocab-list/student-vocab-modal';
import { SoundbitesModal } from 'components/story-detail/soundbites-modal';
import { SoundbiteCell } from './soundbite-cell';

export const AssignmentStudentSection = observer(
  ({ show }: { show: boolean }) => {
    const { assignment } = useCurrentAssignment();
    const uiModel = assignmentUiModel;

    const studentProgresses = assignment.studentProgresses;
    // const { students } = classroom;
    const { story, hasStudents, loading } = assignment;
    // console.log({ classroom, assignment });

    React.useEffect(() => {
      assignment?.refreshStudentProgresses().catch(bugsnagNotify);

      return () => {
        assignmentUiModel.hideSoundbitesModal();
        assignmentUiModel.hideVocabModal();
      };
    }, [assignment]);

    if (!show) {
      return null;
    }

    // const loadingData = data === null || store.loadingData;
    // const tableData = data?.studentProgresses ?? [];

    return (
      <>
        {uiModel.showingVocabModal ? (
          <StudentVocabModal
            story={story}
            studentProgress={uiModel.studentProgress!}
            onDismiss={() => {
              uiModel.hideVocabModal();
            }}
          />
        ) : null}
        {uiModel.showingSoundbitesModal ? (
          <SoundbitesModal
            story={story}
            completedSlugs={uiModel.studentProgress?.completedSoundbiteSlugs}
            studentName={uiModel.studentProgress?.name}
            onDismiss={() => {
              uiModel.hideSoundbitesModal();
            }}
          />
        ) : null}

        {/* // todo: confirm if studentProgresses is guarantees to include all students */}
        {hasStudents ? (
          <Table
            data={studentProgresses}
            loading={loading}
            cells={[
              {
                headerLabel: __('Name', 'name'),
                renderCell: ({ name }: StudentProgress) => {
                  return <StudentNameCell name={name} />;
                },
              },
              {
                headerLabel: __('Email', 'email'),
                renderCell: ({ email }: StudentProgress) => {
                  return <StudentEmailCell email={email} />;
                },
              },
              {
                headerLabel: __('Progress', 'progress'),
                renderCell: (progress: StudentProgress) => (
                  <ProgressCell {...{ story, progress }} />
                ),
              },
              {
                headerLabel: __('Time studied', 'timeStudied'),
                renderCell: ({ allTimeStudiedHourMin }: StudentProgress) => {
                  return (
                    <StudentTimeStudiedCell
                      allTimeStudiedHourMin={allTimeStudiedHourMin}
                    />
                  );
                },
              },
              {
                headerLabel: __('Vocab', 'vocab'),
                renderCell: (studentProgress: StudentProgress) => {
                  return (
                    <div
                      onClick={() => {
                        if (studentProgress.hasVocab) {
                          uiModel.showVocabModal(studentProgress);
                        }
                      }}
                    >
                      <VocabCountCell studentProgress={studentProgress} />
                    </div>
                  );
                },
              },
              {
                headerLabel: __('Soundbites', 'soundbites'),
                renderCell: (studentProgress: StudentProgress) => {
                  return (
                    <div
                      onClick={() => {
                        if (studentProgress.hasCompletedSoundbites) {
                          uiModel.showSoundbitesModal(studentProgress);
                        }
                      }}
                    >
                      <SoundbiteCell studentProgress={studentProgress} />
                    </div>
                  );
                },
              },
            ]}
          />
        ) : (
          <Text>
            {__(
              'After you add students to this class you will see their progress for this story here',
              'studentsZeroState'
            )}
          </Text>
        )}
      </>
    );
  }
);
