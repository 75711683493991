import * as React from 'react';
import { ChapterCatalogData } from '@core/models/catalog';
import { observer } from 'mobx-react';
import { ChapterListHeader } from './chapter-item-contents/chapter-list-header';
import { ChapterSoundbiteBadge } from './components/chapter-soundbite-badge';
import { CircleIcon } from './components/circle-icon';

// when story not yet started
export const Locked = observer(
  ({ chapter }: { chapter: ChapterCatalogData }) => {
    return (
      <div style={{ overflowY: 'clip' }}>
        <ChapterListHeader
          chapter={chapter}
          stationIcon={<CircleIcon />}
          accessory={<ChapterSoundbiteBadge chapter={chapter} />}
        />
      </div>
    );
  }
);
