import { styled } from 'naan/stitches.config';

export const TagsContainer = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  marginBottom: '-6px',
  '& > * ': {
    marginRight: '6px',
    marginBottom: '6px',
  },
});
