import { getDocs } from 'firebase/firestore';
import { createLogger } from '@common/log';
import { CatalogMeta, CatalogMetaSync } from './catalog-meta-sync';
import { BaseFirestoreSync } from './base-firestore-sync';
import { FirebaseConnection } from './firebase-types';
import { CaliServerInvoker } from './cali-server-invoker';
import { track } from '@app/track';

const log = createLogger('catalog-meta-service');

export class CatalogMetaSyncImpl
  extends BaseFirestoreSync<CatalogMeta>
  implements CatalogMetaSync
{
  constructor({
    firebaseConnection,
    caliServerInvoker,
  }: {
    firebaseConnection: FirebaseConnection;
    caliServerInvoker: CaliServerInvoker;
  }) {
    super({ firebaseConnection, caliServerInvoker, log });
  }

  get collectionName() {
    return 'CatalogMeta';
  }

  get collectionPath() {
    return 'catalogs';
  }

  async list(): Promise<CatalogMeta[]> {
    if (this.connectionReady) {
      try {
        const querySnapshot = await getDocs(this.collectionRef);
        const result = querySnapshot.docs.map(doc => doc.data() as CatalogMeta);
        return result;
      } catch (error) {
        this.log.warn(
          `firestoreFetch failed: ${error}; falling back to disconnected mode`
        );
        track('system__firestore_list_failure', { error: String(error) });
        this.firebaseConnection.status = 'ERROR';
      }
    }
    return await this.nodeList();
  }

  async firestoreList(): Promise<CatalogMeta[]> {
    const querySnapshot = await getDocs(this.collectionRef);
    const result = querySnapshot.docs.map(doc => doc.data() as CatalogMeta);
    return result;
  }

  async nodeList(): Promise<CatalogMeta[]> {
    return await this.caliServerInvoker.listCatalogMetas();
  }

  async allSlugs(): Promise<string[]> {
    const list = await this.list();
    return list.map(data => data.slug);
  }
}
