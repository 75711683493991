import { styled } from 'naan/stitches.config';

export const Box = styled('div', {
  margin: 0,
  padding: 0,
  variants: {
    display: {
      inline: {
        display: 'inline-flex',
      },
    },
  },
});

export const InlineBox = styled('span', {});
