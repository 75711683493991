import React from 'react';
import { Navigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import { AppFactory } from 'app/app-factory';
import { teachHomePath } from 'components/nav/path-helpers';
// import { createLogger } from 'app/logger';

// handle marketing site link, which redirects differently based on auth/user state
export const ClassroomsRedirection = observer(() => {
  const { authenticated, classroomEnabled } = AppFactory.root.userManager;

  // const log = createLogger('classrooms-redirection');

  if (!authenticated) {
    // log.debug('User is logged out. Will redirect through auth process');
    return <Navigate to="/auth/create-account?teach=yes" replace />;
  }

  if (classroomEnabled) {
    return <Navigate to={teachHomePath()} replace />;
  } else {
    return <Navigate to="/app/profile/schools" replace />;
  }
});
