import * as React from 'react';
import { observer } from 'mobx-react';

import { ElementNode } from '@tikka/client/client-aliases';

import { usePlayerModel } from 'player/views/player-model-context';
import { usePlayerUIConfig } from './player-ui-config';

export const SCRIPT_SCROLLING_CONTAINER_ID = 'script-scrolling-container';
export const SOUNDBITE_TITLE_CONTAINER = 'soundbite-title-container';

export function getScriptScrollingContainer() {
  return document.getElementById(SCRIPT_SCROLLING_CONTAINER_ID);
}

export const PLAYER_CONTROLS_ID = 'player-controls';

export function getPlayerControls() {
  return document.getElementById(PLAYER_CONTROLS_ID);
}

export const PlayerUIBody = observer(() => {
  const model = usePlayerModel();
  const elementNodes = model.elementNodes;
  return (
    <>
      {elementNodes.map((node: ElementNode) => (
        <El key={node.element.id} node={node} />
      ))}
    </>
  );
});

const El = ({ node }: { node: ElementNode }) => {
  const element = node.element;
  const kind = element.kind;

  const { elementTypeMap } = usePlayerUIConfig();

  const Component = elementTypeMap[kind];
  if (Component) {
    return <Component node={node} />;
  } else {
    return null;
  }
};
