import * as React from 'react';
import * as Onboarding from './components';
import { OnboardingPopover } from './onboarding-popover';
import { styled } from '@naan/stitches.config';
import {
  dismissCurrentTip,
  isCurrentTip,
  isDismissed,
} from '@app/onboarding/onboarding-service';

import { observer } from 'mobx-react';

import __ from 'core/lib/localization';

const ToolipPositioner = styled('span', {
  '&.highlight > *': {
    outline: '6px solid $purple-200',
    borderRadius: '$radii$large',
  },
});

export const LongVocabTip: React.FC<{
  children: React.ReactNode;
}> = observer(({ children }) => {
  const popoverDismissed = isDismissed('tooLongVocabTip');
  const showPopover = isCurrentTip('tooLongVocabTip');

  return (
    <OnboardingPopover
      showPopover={showPopover}
      dismissed={popoverDismissed}
      onResolvePopover={dismissCurrentTip}
      renderAnchor={({ ref, getProps }) => (
        <ToolipPositioner
          ref={ref}
          {...getProps()}
          data-id="popover-wrapper"
          className={showPopover ? 'highlight' : null}
        >
          {children}
        </ToolipPositioner>
      )}
      renderBody={() => {
        return (
          <Onboarding.Container>
            <Onboarding.Content>
              {__(
                'Your vocabulary list is getting long. Tap here to begin a review.',
                'yourVocabularyListIsGettingLongTapHereToBeginAReview'
              )}
            </Onboarding.Content>
          </Onboarding.Container>
        );
      }}
    />
  );
});
