import * as React from 'react';
import { makeObservable, observable } from 'mobx';
import { Story } from '@core/models/story-manager';

class ChapterPositionUiModel {
  public currentChapterSortingPosition: number = 0; // use the sortingRef to keep unique between parts

  constructor(story: Story) {
    makeObservable(this, {
      currentChapterSortingPosition: observable,
    });

    this.initWithStory(story);
  }

  public initWithStory(story: Story) {
    const { progress } = story;
    this.setChapterSortingPosition(progress.currentChapterSortingPosition);
  }

  public setChapterSortingPosition(position: number) {
    // if the current chapter is clicked, close it
    if (this.currentChapterSortingPosition === position) {
      this.currentChapterSortingPosition = -1;
      return;
    }
    this.currentChapterSortingPosition = position;
  }
}

const ChapterPositionUiContext =
  React.createContext<ChapterPositionUiModel | null>(null);

export const useChapterPositionUiContext = () => {
  const context = React.useContext(ChapterPositionUiContext);
  if (!context) {
    throw new Error(
      'useChapterPositionUiContext must be used within a ChapterPositionUiProvider'
    );
  }
  return context;
};

export const ChapterPositionUiProvider: React.FC<
  React.PropsWithChildren<{ story: Story }>
> = ({ children, story }) => {
  const model = new ChapterPositionUiModel(story);
  return (
    <ChapterPositionUiContext.Provider value={model}>
      {children}
    </ChapterPositionUiContext.Provider>
  );
};
