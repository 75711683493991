import * as React from 'react';
import { AppFactory } from '@app/app-factory';
import { Button } from '@naan/primitives/button';
import { useNavigate } from 'react-router-dom';
import { homePath, subscribePath } from 'components/nav/path-helpers';
import { useAfterQueryVar } from 'lib/hooks/use-after-auth-redirection';
import { CountdownClock } from 'components/countdown-clock';
import { darkThemeSelector, styled } from '@naan/stitches.config';
import { Image } from '@naan/primitives/image';

import __, { pickLocalizedValue } from '@core/lib/localization';

import skybgSrc from './assets/sky-bg.svg';
import testimonialSrcEn from './assets/testimonial-img.png';
import testimonialSrcPt from './assets/testimonial-img-pt.png';
import { createLogger } from '@common/log';

const log = createLogger('timed-offer-screen');

const getTestimonial = () =>
  pickLocalizedValue({
    en: {
      quote:
        '“The best money I’ve spent on language learning. Jiveworld has improved my listening immensely.”',
      source: 'Janey L, Stockholm',
      image: testimonialSrcEn,
    },
    pt: {
      quote:
        '“Jiveworld transformou meu inglês. Aprender com histórias autênticas torna o aprendizado mais significativo.”',
      source: 'Mirian T',
      image: testimonialSrcPt,
    },
  });

const Wrapper = styled('div', {
  height: '100vh',
  '@supports (height: 100dvh)': {
    height: '100dvh',
  },
  overflow: 'auto',
  background: '$green-700',
  [darkThemeSelector]: {
    background: '$green-400',
  },
  display: 'flex',
  flexDirection: 'column',
  color: '$textPrimary',

  '& .top': {
    background: `url(${skybgSrc}) no-repeat center bottom`,
    backgroundSize: 'cover',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

    '& .top-content': {
      padding: '32px 16px calc(32px + 3%)',
      maxWidth: '472px',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },

    '& .heading': {
      textStyle: 'medium-heading',
      textAlign: 'center',
      marginBottom: '16px',
      textWrap: 'balance',
      color: '$globalBlack-alpha-80',

      '@small': {
        textStyle: 'large-heading',
      },

      '@medium': {
        textStyle: 'extra-large-heading',
      },
    },

    '& .countdown': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '8px',
      '& .countdown-heading': {
        textStyle: 'small-caps',
        color: '$globalBlack-alpha-50',
      },
    },
  },

  '& .bottom': {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

    '& .bottom-content': {
      padding: '0 16px 16px',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',

      '@media screen and (min-width: 550px)': {
        maxWidth: '472px',
        paddingBottom: '32px',
      },

      '& .testimonial': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        flexGrow: 1,
        paddingBlock: 24,

        '& img': {
          width: 80,
          height: 80,
          marginBottom: '12px',

          '@medium': {
            width: 96,
            height: 96,
          },
        },

        '& .quote': {
          textStyle: 'serif',
          textAlign: 'center',
          color: '$globalWhite',
          marginBottom: 4,
          fontSize: 19,
          lineHeight: 1.48,
        },

        '& .source': {
          color: '$globalWhite-alpha-70',
        },
      },

      '& .buttons': {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
      },
    },
  },
});

export const TimedOfferScreen = () => {
  const navigate = useNavigate();
  const [redirectAfter, clearAfter] = useAfterQueryVar('');
  let maybeLaterPath = redirectAfter ? redirectAfter : homePath();

  const testimonial = getTestimonial();

  React.useEffect(() => {
    return () => {
      clearAfter();
    };
  });

  const { userManager } = AppFactory.root;
  const { timedDiscountElgible, timedDiscountActive } = userManager.membership;

  if (!timedDiscountElgible) {
    log.warn('not eligible for timed discount - redirecting');
    navigate(homePath());
    return null;
  }

  if (!timedDiscountActive) {
    log.info('activating timed discount');
    userManager.userData.activateTimedDiscount();
  }

  const { discountCountdownSeconds } = userManager.membership;

  const { productName } = AppFactory.root.storyManager;

  return (
    <Wrapper>
      <div className="top">
        <div className="top-content">
          <h2 className="heading">
            {__(
              'Unlock {{productName}} and save 20%',
              'unlockProductAndSave20',
              {
                productName,
              }
            )}
          </h2>
          <div className="countdown">
            <div className="countdown-heading">
              {__('Offer expires in', 'offerExpiresIn')}
            </div>
            <CountdownClock seconds={discountCountdownSeconds} />
          </div>
        </div>
      </div>

      <div className="bottom">
        <div className="bottom-content">
          <div className="testimonial">
            <Image src={testimonial.image} alt="" />
            <p className="quote">{testimonial.quote}</p>
            <p className="source">{testimonial.source}</p>
          </div>
          <div className="buttons">
            <Button
              label={__('See features and pricing', 'seeFeaturesAndPricing')}
              presentation={'yellow'}
              size="large"
              onClick={() => navigate(subscribePath())}
              fullWidth
            />

            <Button
              label={__('Maybe later', 'maybeLater')}
              presentation={'whiteDarken'}
              size="large"
              onClick={() => navigate(maybeLaterPath)}
              fullWidth
            />
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
