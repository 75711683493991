import * as React from 'react';
// import { observer } from 'mobx-react';
import {
  getPosterUrl,
  getSourceForId,
} from 'components/ui/video-player/player-view-controller';
import {
  achieve,
  AchievementKey,
  didAchieve,
} from '@app/onboarding/achievements';
import { VideoPlayer2 } from 'components/ui/video-player/video-player2/player';
import { createLogger } from '@common/log';

const log = createLogger('video-interstitial');

export type VideoInterstitialProps = {
  onForcePass: () => void;
  onDismiss: () => void;
};

type VideoInterstitialWrapperProps = VideoInterstitialProps & {
  getVideoAssets: () => { videoId: string; subtitles: string; poster: string };
  videoAchievementKey: AchievementKey;
  title: string;
};

export const VideoInterstitial = ({
  onForcePass,
  onDismiss,
  getVideoAssets,
  videoAchievementKey,
  title,
}: VideoInterstitialWrapperProps) => {
  // debugger;
  const { videoId, poster } = getVideoAssets();

  const videoSrc = getSourceForId(videoId);
  const posterUrl = getPosterUrl(poster);
  return (
    <VideoPlayer2
      title={title}
      poster={posterUrl}
      videoSrc={videoSrc}
      videoWatchedFn={() => didAchieve([videoAchievementKey])}
      onEnded={() => {
        // achieve(videoAchievementKey);
        log.debug('video ended - noop');
      }}
      onComplete={() => {
        // experimenetally delay the achievement until "let's go" so as to avoid
        // unexplained rerenders which would potentially mess with the video experience
        achieve(videoAchievementKey);
        onForcePass();
      }}
      onExit={() => {
        onDismiss();
      }}
      // onSkip={() => {
      //   achieve(videoAchievementKey);
      // }}
    />
  );
};
