import * as React from 'react';
import { ActivityGuide } from 'core/models/catalog';
import { styled } from 'naan/stitches.config';
import { VStack } from 'naan/primitives/stack';
import { Downloadable } from './downloadable';
import { Divider } from 'naan/primitives/divider';
import { PlainMarkdown, Text } from 'naan/primitives/text';
import { VSpacer } from 'naan/primitives/spacer';
import { observer } from 'mobx-react';
import { Image } from '@naan/primitives/image';

import __ from 'core/lib/localization';

const Avatar = styled(Image, {
  $$size: '80px',
  width: '$$size',
  height: '$$size',
  borderRadius: 'calc($$size / 2)',
  marginBottom: '$space$4',
  '@medium': {
    marginBottom: 0,
    marginRight: '$space$5',
  },
});

const ActivityGuideWrapper = styled('div', {
  px: '$space$6',
  paddingBottom: '$space$6',
  h3: {
    margin: 0,
    py: '$space$6',
    textStyle: 'small-heading',
    span: {
      color: '$colors$black-alpha-40',
    },
  },
  '@medium': {
    width: '62.5%',
    paddingRight: '$space$4',
  },
});

const Serif = styled('div', {
  textStyle: 'serif',
  color: '$black-alpha-70',
  '& p + p': {
    marginTop: '$space$4',
  },
});

const AuthorBio = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  '@medium': {
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
});

export const ActivityGuideSection = observer(
  ({
    data,
    locked,
    onTappingLockedResource,
  }: {
    data: ActivityGuide;
    locked: boolean;
    onTappingLockedResource: () => void;
  }) => {
    return (
      <ActivityGuideWrapper>
        <h3>
          {__('Classroom activities', 'classroomActivities')}{' '}
          <span>
            {__('by %{author}', 'byAuthor', {
              author: data.authorData?.name,
            })}
          </span>
        </h3>
        <Divider type="transparent" />
        <Downloadable
          label={__('Activity guide', 'activityGuide')}
          description={__(
            'Student activities relating to the story',
            'readyToDeployResources'
          )}
          url={data.resourceUrl ?? ''}
          showLockedResourcesDialog={onTappingLockedResource}
          locked={locked}
        />
        <Divider type="transparent" />
        <VSpacer size={4} />
        <Serif>
          <PlainMarkdown source={data.authorNote ?? ''} />
        </Serif>
        <VSpacer size={6} />
        <AuthorBio>
          {data.authorData?.avatarUrl ? (
            <Avatar
              src={data.authorData?.avatarUrl}
              aria-hidden
              alt={data.authorData?.name ?? ''}
            />
          ) : null}
          <VStack>
            <Text textStyle="body-bold">{data.authorData?.name}</Text>
            <Text>{data.authorData?.title}</Text>
            <VSpacer size={1} />
            <Text textStyle="small-text" color="black-alpha-40">
              <PlainMarkdown source={data.authorData?.bio ?? ''} />
            </Text>
          </VStack>
        </AuthorBio>
      </ActivityGuideWrapper>
    );
  }
);
