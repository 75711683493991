import * as React from 'react';

import { observer } from 'mobx-react';
import { VocabListSection } from '@core/models/ui/vocab-list-model';
import { styled } from '@naan/stitches.config';
import { PlainMarkdown } from '@naan/primitives/text';

const PartHeadingWrapper = styled('div', {
  zIndex: 1,
  borderTop: '1px solid $gray-100',
  position: 'sticky',
  top: 0,
  textStyle: 'small-heading',
  height: 40,
  display: 'flex',
  alignItems: 'center',
  background: '$gray-25',
  paddingLeft: '$space$4',
});

const TitleHeadingWrapper = styled('div', {
  zIndex: 1,
  borderBottom: '1px solid $gray-100',
  position: 'sticky',
  top: 0,
  textStyle: 'body-bold',
  height: 48,
  display: 'flex',
  alignItems: 'center',
  background: '$gray-25',
  paddingLeft: '$space$4',

  '&.has-part': {
    top: 40,
  },

  '& > .number': {
    width: 24,
    color: '$textSecondary',
  },
  '& > .title': {},
});

export const VocabListSectionHeading = observer(
  ({ section }: { section: VocabListSection }) => {
    return (
      <>
        {section.unitHeader ? (
          <PartHeadingWrapper>
            {section.chapterData.unitData.partLabel}
          </PartHeadingWrapper>
        ) : null}
        <TitleHeadingWrapper className={section.isMultipart ? 'has-part' : ''}>
          <span className="number">{section.chapterData.position}</span>
          <PlainMarkdown className="title" source={section.chapterData.title} />
        </TitleHeadingWrapper>
      </>
    );
  }
);
