import { LocaleCode } from '@utils/util-types';
import { DiscountScheme } from './cas-types';

const discounts: DiscountScheme[] = [
  // a generic 'Coupon' will be displayed by stripe if stripeLabel not set
  // todo: do we need distinct strings for pricing cards. localization?
  { percentOff: 10, l1: 'en', slug: 'jfedev-10' },
  { percentOff: 20, l1: 'en', slug: 'jfedev-20' },
  {
    percentOff: 50,
    // stripeLabel: 'Oferta exclusiva para ouvintes da Rádio Escafandro', // label here not currently relevant
    slug: 'esca50',
    l1: 'pt',
    affiliates: ['escafandro', 'esca'],
  },
  {
    percentOff: 20,
    slug: 'pcnt20-en',
    l1: 'en',
  },
  {
    percentOff: 20,
    slug: 'pcnt20-pt',
    l1: 'pt',
  },
  {
    percentOff: 20,
    slug: 'pcnt20-es',
    l1: 'es',
  },
  {
    percentOff: 30,
    slug: 'pcnt30-en',
    l1: 'en',
    // affiliates: [],
  },
  {
    percentOff: 30,
    slug: 'pcnt30-pt',
    l1: 'pt',
    // affiliates: [],
  },
];

export function accountCreationDiscountSlug(l1: LocaleCode) {
  switch (l1) {
    case 'en':
      return 'pcnt20-en';
    case 'pt':
      return 'pcnt20-pt';
    case 'es':
      return 'pcnt20-es';
    default:
      return undefined;
  }
}

export function allDiscounts() {
  return discounts;
}

export function matchedL1(discount: DiscountScheme, l1: LocaleCode) {
  return !discount.l1 || discount.l1 === l1;
}

// export function matchedL2(discount: DiscountScheme, l2: LocaleCode) {
//   return !discount.l2 || discount.l2 === l2;
// }

export function getDiscountBySlug({
  discountSlug,
  l1,
}: {
  discountSlug: string;
  l1: LocaleCode;
}) {
  if (!discountSlug) {
    return undefined;
  }
  return discounts.find(d => d.slug === discountSlug && matchedL1(d, l1));
}

// deprecated. post 11.2, all affiliates should have explicit discountSlug's assigned in rails
export function getDiscountByAffiliate({
  affiliateSlug,
  l1,
}: {
  affiliateSlug: string;
  l1: LocaleCode;
}) {
  if (!affiliateSlug) {
    return undefined;
  }
  const result = discounts.find(
    d => d.affiliates?.includes(affiliateSlug) && matchedL1(d, l1)
  );
  // if (!result) {
  //   result = discounts.find(
  //     d => d.affiliates?.includes('DEFAULT') && matchedL1(d, l1)
  //   );
  // }
  return result;
}
