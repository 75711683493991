/* eslint-disable no-unused-vars */
// todo: will probably want to refactor this to use string based membership in the future
// but for now, this is the simplest way to implement a trivially observeable set of membership
export enum WordMembership {
  CURRENT = 1 << 0,
  BEFORE = 1 << 1,
  VISITED = 1 << 2,
  NOTATION = 1 << 3,
  TRICKY = 1 << 4,
  SIC = 1 << 5,
  ITALIC = 1 << 6,
  SELECTED_NOTATION = 1 << 7,
  NAV_STOP = 1 << 9,
  CURRENT_NAV_STOP = 1 << 10,
  SMART_REMAINING = 1 << 11,
}
