import * as React from 'react';
import { observer } from 'mobx-react';
import { Story } from '@core/models/story-manager/story';
import { StoryMinicard } from './story-minicard';
import { namedStyled } from '@naan/stitches.config';

const GridWrapper = namedStyled('StoryRowGridWrapper', 'div', {
  $$columns: 4,
  display: 'grid',
  gridTemplateColumns: 'repeat($$columns, 1fr)',
  gap: '$space$4',
  '@medium': {
    gap: '$space$6',
  },
});

export const StoryRow = observer(
  ({ stories, n }: { stories: Story[]; n: number }) => {
    const sliced = stories.slice(0, n);

    return (
      <GridWrapper css={{ $$columns: n }}>
        {sliced.map(story => (
          <StoryMinicard story={story} key={story.slug} />
        ))}
      </GridWrapper>
    );
  }
);
