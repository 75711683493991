import {
  ELTOf,
  IElement,
  IndexedElement,
  IndexRange,
  TimedElement,
} from '../basic-types';
import { IDTOfET } from '../basic-types';
import { Interval, Intervals } from '../intervals/intervals';
import { Signal } from './signal';
import { TrackerImpl } from './tracker-impl';

export interface Tracker<
  IDT extends string | number,
  ET extends TimedElement | IndexedElement,
  ELT extends ELTOf<ET> = ELTOf<ET>
> {
  configure({
    elements,
    triggerFunction,
    positionFunction,
    intervals,
  }: {
    elements?: ELTOf<ET> | IDT[];
    triggerFunction?: () => number;
    positionFunction?: () => number;
    intervals?: Intervals | ((elementList: ELT) => Intervals);
  }): void;

  dispose(): void;
  subscribeIsUnder(f: (el: IDT) => void): () => void;
  subscribeIsBefore(f: (el: IDT) => void): () => void;
  subscribeIsVisited(f: (el: IDT) => void): () => void;
  anyIsChangedSignal: Signal;
  isUnderSignal(elementId: IDT): Signal;
  isBeforeSignal(elementId: IDT): Signal;
  isVisitedSignal(elementId: IDT): Signal;
  changedSignal(elementId: IDT): Signal;
  currentIsUnder(): IDT;
  isUnder(elementId: IDT): boolean;
  isBefore(elementId: IDT): boolean;
  isVisited(elementId: IDT): boolean;
  watchIsUnder(elementId: IDT): boolean;
  watchIsBefore(elementId: IDT): boolean;
  watchIsVisited(elementId: IDT): boolean;
  useWatchIsUnder(elementId: IDT, tickler?: () => void): boolean;
  useWatchIsBefore(elementId: IDT, tickler?: () => void): boolean;
  useWatchIsVisited(elementId: IDT, tickler?: () => void): boolean;
  elementInterval(elementId: IDT): Interval;
  observableIsUnder(): IDT;
  observableBeforeRange(): IndexRange;
  observableVisitedRange(): IndexRange;
  furthestTrackedPosition(): number;
  forceFurthestVisitedTime(time: number): void;
  setVisitedTolerance(tolerance: number): void;
}

export enum TrackerOptions {
  // eslint-disable-next-line no-unused-vars
  LOG_CHANGES = 1 << 0,
}

export function CreateTracker<
  IDT extends _IDTOfET extends null ? string | number : _IDTOfET,
  ET extends TimedElement | IndexedElement | null = null,
  _IDTOfET extends string = ET extends IElement ? IDTOfET<ET> : null
  // ELT extends ELTOf<ET> = ELTOf<ET>
>(params: {
  elements?: ELTOf<ET> | IDT[];
  triggerFunction?: () => number;
  positionFunction?: () => number;
  intervals?: Intervals | ((arg: any) => Intervals);
  options?: TrackerOptions;
}): Tracker<IDT, ET> {
  // TODO figure typing of intervals function, think contravariance is issue
  const tracker = new TrackerImpl<IDT, ET>();
  tracker.configure(params as any);
  return tracker;
}
