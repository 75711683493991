import * as React from 'react';

import { Image } from '@naan/primitives/image';
import { styled } from 'naan/stitches.config';
import { pickLocalizedValue } from '@core/lib/localization';
import { observer } from 'mobx-react';

import imageSrcEn from './jw-for-schools.png';
import imageSrcPt from './jw-for-schools-pt.png';

const Wrapper = styled('div', {
  width: '100%',
  display: 'flex',
  img: {
    maxWidth: '100%',
  },
});

export const ClassroomOnboardingScreenshot = observer(() => {
  return (
    <Wrapper>
      <Image
        src={pickLocalizedValue({
          en: imageSrcEn,
          pt: imageSrcPt,
        })}
        alt="Screenshot"
      />
    </Wrapper>
  );
});
