import React from 'react';
import { observer } from 'mobx-react';
import { ClientNotation } from '@tikka/client/client-types';
import { styled } from '@naan/stitches.config';
import { NotationStatus } from 'vocab-review/chapter-review-model';
import { BookmarkIconMap } from './bookmark-icon-map';
import { BookmarkIconStyles } from './bookmark-icon-styles';
import { playSound } from 'lib/services/sound-manager';

const animateDot = (element: HTMLElement, n: number) => {
  const rotation = 60 * n;
  element.animate(
    [
      { opacity: 1, transform: `rotate(${rotation}deg) translateX(0px)` },
      { opacity: 0, transform: `rotate(${rotation}deg) translateX(30px)` },
    ],
    {
      duration: 300,
      easing: 'ease-out',
      fill: 'forwards',
    }
  );
};

const shakeElement = (element: HTMLElement) => {
  element.animate(
    [
      { transform: 'translate3d(-1px, 0, 0)', offset: 0.1 },
      { transform: 'translate3d(2px, 0, 0)', offset: 0.2 },
      { transform: 'translate3d(-4px, 0, 0)', offset: 0.3 },
      { transform: 'translate3d(4px, 0, 0)', offset: 0.4 },
      { transform: 'translate3d(-4px, 0, 0)', offset: 0.5 },
      { transform: 'translate3d(4px, 0, 0)', offset: 0.6 },
      { transform: 'translate3d(-4px, 0, 0)', offset: 0.7 },
      { transform: 'translate3d(2px, 0, 0)', offset: 0.8 },
      { transform: 'translate3d(-1px, 0, 0)', offset: 0.9 },
    ],
    {
      duration: 300,
      easing: 'ease-out',
      fill: 'forwards',
    }
  );
};

export const animateStatusIcon = (
  element: HTMLElement,
  status: NotationStatus
) => {
  if (!element) {
    return;
  }
  if (status !== 'pending') {
    if (status === 'not-got-it') {
      shakeElement(element as HTMLElement);
      playSound('swoosh');
    }

    if (status === 'got-it') {
      playSound('bing');
      const pops = element?.querySelectorAll('.pops > div');
      pops?.forEach((pop, i) => {
        animateDot(pop as HTMLElement, i);
      });
    }
  }
};

const StatusIconWrapper = styled('div', {
  color: '$$color',
  display: 'flex',
  width: 48,
  height: 48,
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',

  '&.got-it': {
    // background: 'yellow',
    position: 'relative',
    '--size': '8px',
    '--rotation': '0deg',
    '--translate': '30px',
    '--opacity': '100%',

    '& .pops': {
      position: 'absolute',
      inset: 0,
      // background: 'rgba(120,255,120,0.5)',
      display: 'grid',
      placeItems: 'center',
      gridTemplateColumns: '1',
      gridTemplateRows: '1',

      '& > div': {
        opacity: 'var(--opacity)',
        width: 'var(--size)',
        height: 'var(--size)',
        borderRadius: '50%',
        background: '$colors$green-500',
        transformOrigin: 'center',
        transform: 'rotate(0deg) translateX(0px)',
        gridColumn: '1 / 2',
        gridRow: '1 / 2',
      },
    },
  },
});

// const useStatusAnimation = (status: NotationStatus) => {
//   const ref = React.useRef<HTMLDivElement>(null);
//   React.useEffect(() => {
//     animateStatusIcon(ref.current, status);
//   }, [status]);

//   return ref;
// };

export const ItemStatusIcon = observer(
  ({ status }: { notation: ClientNotation; status: NotationStatus }) => {
    // const ref = useStatusAnimation(status);

    return (
      <div>
        <StatusIconWrapper data-status-icon className={status}>
          <BookmarkIconStyles className={status}>
            {BookmarkIconMap[status]}
          </BookmarkIconStyles>
          {status === 'got-it' ? (
            <div className="pops">
              <div />
              <div />
              <div />
              <div />
              <div />
              <div />
            </div>
          ) : null}
        </StatusIconWrapper>
      </div>
    );
  }
);
