import * as React from 'react';
import { PlainMarkdownWithLinks } from './plain-markdown';
import { MarkdownStyleWrapper } from './markdown-style-wrapper';

type WrapperProps = React.ComponentProps<typeof MarkdownStyleWrapper>;

export const FullyStyledMarkdown = ({
  source,
  ...props
}: WrapperProps & { source: string }) => {
  return (
    <MarkdownStyleWrapper {...props}>
      <PlainMarkdownWithLinks source={source} />
    </MarkdownStyleWrapper>
  );
};
