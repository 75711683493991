// import { createLogger } from '@common/log';
import { supportsMediaSource } from './utils';
import { DashPlayerViewController } from './dash';
import { HlsPlayerViewController } from './hsl';
import { PlayerViewController } from './abstract';

let autoplay = false;

export const setVideoAutoplay = (bool: boolean) => {
  autoplay = bool;
  if (playerSingleton) {
    playerSingleton.setAutoplay(bool);
  }
};

let playerSingleton: PlayerViewController | null = null;

// todo: consider renaming this to VideoPlayer...
export function PlayerViewControllerFactory(): PlayerViewController {
  if (playerSingleton) {
    return playerSingleton;
  }

  if (supportsMediaSource()) {
    playerSingleton = new DashPlayerViewController();
  } else {
    playerSingleton = new HlsPlayerViewController();
  }
  // playerSingleton = new HlsPlayerViewController();

  if (autoplay) {
    playerSingleton.setAutoplay(autoplay);
  }
  return playerSingleton;
}

(window as any).videoPlayer = PlayerViewControllerFactory;
