import { action, makeObservable, observable } from 'mobx';
import { Story } from '@core/models/story-manager';
import { StudentProgress } from '@core/models/user-manager';
import { createLogger } from 'app/logger';
const log = createLogger('assignment-ui-model');

class AssignmentUiModel {
  @observable
  public story: Story | null = null;

  @observable
  public studentProgress: StudentProgress | null = null;

  @observable
  public showingVocabModal = false;

  @observable
  public showingSoundbitesModal = false;

  constructor() {
    makeObservable(this);
  }

  @action
  public setStory(story: Story) {
    this.story = story;
  }

  @action
  showVocabModal = (studentProgress: StudentProgress) => {
    log.info('showVocabModal', studentProgress?.email);
    this.studentProgress = studentProgress;
    this.showingVocabModal = true;
  };

  @action
  hideVocabModal = () => {
    this.showingVocabModal = false;
    this.studentProgress = null;
  };

  @action
  showSoundbitesModal = (studentProgress: StudentProgress) => {
    log.info('showSoundbitsModal', studentProgress?.email);
    this.studentProgress = studentProgress;
    this.showingSoundbitesModal = true;
  };

  @action
  hideSoundbitesModal = () => {
    this.showingSoundbitesModal = false;
  };

  public reset() {
    this.story = null;
    this.studentProgress = null;
    this.showingVocabModal = false;
    this.showingSoundbitesModal = false;
  }
}

export const assignmentUiModel = new AssignmentUiModel();

(window as any).assignmentUiModel = assignmentUiModel;
