import * as React from 'react';

import { Button } from '@naan/primitives/button';
import { styled } from '@naan/stitches.config';
import { Image as NaanImage } from '@naan/primitives/image';
import { PlayVideoIcon } from 'components/learn/dashboard/helplets-widget/play-video-icon';
import __ from '@core/lib/localization';
import { AppFactory } from '@app/app-factory';
import { VideoPlayerModel } from 'components/learn/dashboard/helplets-widget/video-player-model';
import { SimpleVideoModal } from 'components/learn/dashboard/helplets-widget/video-modal';
import { track } from '@app/track';
import { useOnResolve } from './onboarding-dialogs';

// reexport from dialogs so it's more consistent
export { ButtonsContainer } from '@naan/primitives/modals/dialog';

// export type OnboardingDialogAction = 'skipTips' | 'gotIt';

export const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
});

export const Scroller = styled('div', {});

export const GotItButton = ({
  ...props
}: React.ComponentProps<typeof Button>) => (
  <Button size="large" presentation={'teal'} fullWidth {...props} />
);

const ImageWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '& img': {
    maxWidth: 'calc(100% - 32px)',
  },
});

export const Image: React.FC<
  React.PropsWithChildren<{} & React.ComponentProps<typeof NaanImage>>
> = ({ ...props }) => {
  return (
    <ImageWrapper>
      <NaanImage {...props} />
    </ImageWrapper>
  );
};

export const Title = styled('h2', {
  textStyle: 'small-heading',
  padding: '0 16px',
});

export const Content = styled('div', {
  padding: '0 16px',
});

const HelpletVideoWrapper = styled('div', {
  flex: 1,
  backgroundColor: '$gray-50',
  borderRadius: '$radii$medium',
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  gridTemplateRows: '1fr',
  gap: 16,
  alignItems: 'center',
  margin: '0 16px',
  padding: 12,
  '& .thumbnail': {
    position: 'relative',
    width: 128,
    height: 72,
    borderRadius: '$radii$small',
    backgroundColor: '$gray-100',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    '& .button': {
      '--play-video-button-bg': '$colors$teal-500',
      all: 'unset',
      position: 'absolute',
      inset: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 3,
      svg: {
        width: '32px',
      },
      '&:hover': {
        '--play-video-button-bg': '$colors$teal-600',
      },
    },
  },
  '& .info': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    '& .learn-more': {
      color: '$textSecondary',
      textStyle: 'small-caps',
      marginBottom: 4,
    },
    '& .title': {
      color: '$textPrimary',
      textStyle: 'body-bold',
    },
  },
});

export const HelpletVideo = ({ videoSlug }: { videoSlug: string }) => {
  const { videoGuideUserData } = AppFactory.root.userManager.userData;
  const video = videoGuideUserData.getVideoBySlug(videoSlug);
  const model = new VideoPlayerModel(videoGuideUserData, video);
  const onResolve = useOnResolve();

  if (!video) {
    return null;
  }

  const presentVideoModal = () => {
    onResolve(true);

    track('helplet__open_from_onboarding_tip', {
      videoSlug: videoSlug,
    });

    AppFactory.dialogPresenter.present(onDismiss => {
      return <SimpleVideoModal model={model} onDismiss={onDismiss} />;
    });
  };

  return (
    <HelpletVideoWrapper onClick={presentVideoModal}>
      <div
        className="thumbnail"
        style={{
          backgroundImage: `url(${video.imageUrlLandscape})`,
        }}
      >
        <div className="button">
          <PlayVideoIcon />
        </div>
      </div>
      <div className="info">
        <div className="learn-more">{__('Learn more', 'learnMore')}</div>
        <div className="title">{video.title}</div>
      </div>
    </HelpletVideoWrapper>
  );
};
