import * as React from 'react';

export const SpinnerIcon = ({ color = '#19bbd4' }) => {
  return (
    <svg
      width={28}
      height={28}
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
    >
      <circle
        cx={50}
        cy={50}
        fill="none"
        stroke={color}
        strokeWidth={10}
        r={45}
        strokeDasharray="212.05750411731105 72.68583470577035"
        transform="rotate(122.422 50 50)"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          calcMode="linear"
          values="0 50 50;360 50 50"
          keyTimes="0;1"
          dur="0.7s"
          begin="0s"
          repeatCount="indefinite"
        />
      </circle>
    </svg>
  );
};
