import * as React from 'react';

import { observer } from 'mobx-react';
import { VocabRowData } from '@core/models/ui/vocab-list-model';
import { styled } from '@naan/stitches.config';
import { IconButton } from '@naan/primitives/button';
import classNames from 'classnames';
import { PlainMarkdown } from '@naan/primitives/text';
import { vocabUsageText } from '@core/models/user-manager/story-progress';
import {
  BookmarkCheckmarkLargeIcon,
  BookmarkFilledLargeIcon,
  BookmarkLargeIcon,
} from '@naan/icons/bookmark-icon';
// import { useCurrentStory } from '../current-story-context';

const Wrapper = styled('div', {
  marginLeft: 40,
  padding: '8px 56px 12px 0',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',

  '& > .headword': {
    textStyle: 'serif',
  },
  '& > .note': {
    textStyle: 'small-text',
    color: '$textSecondary',
  },
  '& > .action': {
    position: 'absolute',
    right: 4,
    top: 10,
    // background: 'yellow',
    '& > .no-hover': {
      position: 'absolute',
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      background: 'transparent',
      opacity: '30%',
    },
  },
  '&.removing > .headword': {
    color: '$gray-100',
  },
  '&.removing > .note': {
    color: '$gray-100',
  },
});

export const VocabRow = observer(
  ({ data, studentMode }: { data: VocabRowData; studentMode: boolean }) => {
    // const text = data.hasUsageText
    //   ? `${data.usageText} (${data.headword})`
    //   : data.headword;
    // const story = useCurrentStory();
    const text = vocabUsageText(data.notation);
    const handleClick = React.useCallback(() => {
      if (studentMode) {
        return;
      }
      data.toggle();
    }, [data, studentMode]);

    // const progress = story.progress;
    // const previouslyLearned = progress.vocabLearned(data.notation.id);
    const { previouslyLearned, unlearned } = data;

    const removed = data.remove;

    return (
      <Wrapper className={classNames({ removing: data.remove })}>
        <PlainMarkdown className="headword" source={text} />
        <PlainMarkdown className="note" source={data.note} />
        {/* // @armando: TODO: set opacity on buttons */}
        <div className="action">
          {studentMode ? <div className="no-hover"></div> : null}
          {removed ? (
            <IconButton
              presentation={'grayTransparent'}
              size={'large'}
              onClick={handleClick}
              icon={<BookmarkLargeIcon />}
              testId={`vocab-list-row-remove-btn-${data.slug}`}
            />
          ) : previouslyLearned && !unlearned ? (
            <IconButton
              presentation={'greenTransparent'}
              size={'large'}
              onClick={handleClick}
              icon={<BookmarkCheckmarkLargeIcon />}
              testId={`vocab-list-row-remove-btn-${data.slug}`}
            />
          ) : (
            <IconButton
              presentation={'purple'}
              size={'large'}
              onClick={handleClick}
              icon={<BookmarkFilledLargeIcon />}
              testId={`vocab-list-row-remove-btn-${data.slug}`}
            />
          )}
        </div>
      </Wrapper>
    );
  }
);
