import * as React from 'react';
// import { prevent } from 'common/prevent';
import { VSpacer } from 'naan/primitives/spacer';
import { styled } from 'naan/stitches.config';
import { InteractiveTag, TagsContainer } from 'naan/primitives/tags';
import { Text } from 'naan/primitives/text';
import { AnimatedTagsContainer } from './animated-tags-container';
import { FilterBox } from './filter-box';
import { AppFactory } from '@app/app-factory';

import __ from 'core/lib/localization';

const ThemesFilterPanelWrapper = styled('div', {
  maxWidth: '960px',
  margin: '0 auto',
  paddingBottom: 4,
  borderBottom: '1px solid $gray-100',
});

export const ThemesFilterPanel = ({ show = false }: { show: boolean }) => {
  const { storyManager } = AppFactory.root;

  return (
    <AnimatedTagsContainer show={show}>
      <ThemesFilterPanelWrapper>
        <VSpacer size={2} />
        <Text textStyle="tiny-text" color={'textSecondary'}>
          {__('AP THEMES', 'apThemes')}
        </Text>
        <VSpacer size={2} />
        <TagsContainer>
          <FilterBox
            filterItems={storyManager.apTagsList}
            filterQueryKey={'themes'}
            renderFilterControl={(item, selected, action) => {
              return (
                <InteractiveTag
                  role="button"
                  selected={selected}
                  onSelect={action}
                  type="topic"
                >
                  {item}
                </InteractiveTag>
              );
            }}
          />
        </TagsContainer>
        <VSpacer size={4} />
        <Text textStyle="tiny-text" color={'textSecondary'}>
          {__('IB THEMES', 'ibThemes')}
        </Text>
        <VSpacer size={2} />
        <TagsContainer>
          <FilterBox
            filterItems={storyManager.ibTagsList}
            filterQueryKey={'themes'}
            renderFilterControl={(item, selected, action) => {
              return (
                <InteractiveTag
                  role="button"
                  selected={selected}
                  onSelect={action}
                  type="topic"
                >
                  {item}
                </InteractiveTag>
              );
            }}
          />
        </TagsContainer>
      </ThemesFilterPanelWrapper>
    </AnimatedTagsContainer>
  );
};
