import React from 'react';
import { isFunction } from 'lodash';
import { styled } from 'naan/stitches.config';

const SuccessIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32">
    <g fill="none">
      <circle cx="16" cy="16" r="16" fill="#69C00D" />
      <polyline
        stroke="#FFF"
        strokeWidth="3"
        points="9 17.765 14.444 22 23 10"
      />
    </g>
  </svg>
);

const AlertIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32">
    <path
      fill="#FF6767"
      fillRule="evenodd"
      d="M16,32 C7.163444,32 0,24.836556 0,16 C0,7.163444 7.163444,0 16,0 C24.836556,0 32,7.163444 32,16 C32,24.836556 24.836556,32 16,32 Z M15.9843038,4.8 C14.6980696,4.8 13.694272,5.41640239 13.694272,6.50895403 C13.694272,9.84276102 14.133386,14.6335576 14.133386,17.9673646 C14.133386,18.8358161 14.9805062,19.2 15.9843038,19.2 C16.7372468,19.2 17.803924,18.8359008 17.803924,17.9673646 C17.803924,14.6335576 18.2431329,9.84276102 18.2431329,6.50895403 C18.2431329,5.41640239 17.2078481,4.8 15.9843038,4.8 Z M16.0156962,26.4 C17.3019304,26.4 18.4,25.277927 18.4,24 C18.4,22.6908834 17.3019304,21.6 16.0156962,21.6 C14.6352848,21.6 13.6,22.6908834 13.6,24 C13.6,25.277927 14.6353797,26.4 16.0156962,26.4 Z"
    />
  </svg>
);

const InfoIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32">
    <g fill="none" fillRule="evenodd">
      <circle cx="16" cy="16" r="16" fill="#F7C70A" fillRule="nonzero" />
      <path
        fill="#FFF"
        d="M12.6115777,23.571031 C12.6115777,22.8818948 12.8157662,21.8099053 12.9689076,21.0441985 L14.2706092,14.8930205 L12.4584364,14.7143556 L12.6115777,13.7444603 L18.0480961,12.1109524 L18.456473,12.4682823 L16.542206,21.3249577 C16.4145882,21.7843817 16.3635411,22.1417116 16.3635411,22.4224708 C16.3635411,22.754277 16.542206,22.932942 16.7974416,22.932942 C17.2313421,22.932942 17.7928605,22.4224708 18.6351379,21.3504812 L19.4008448,21.8609524 C18.3799023,23.5965545 16.7719181,25.2555859 14.5768919,25.2555859 C13.3772845,25.2555859 12.6115777,24.6685441 12.6115777,23.571031 Z M17.2313421,9.73726133 C16.2614468,9.73726133 15.3681222,9.0481252 15.3681222,7.92508856 C15.3681222,6.64891054 16.4145882,5.75558594 17.5631484,5.75558594 C18.6351379,5.75558594 19.4263683,6.49576918 19.4263683,7.54223515 C19.4263683,8.7928896 18.3799023,9.73726133 17.2313421,9.73726133 Z"
      />
    </g>
  </svg>
);

const iconMap = {
  success: SuccessIcon,
  alert: AlertIcon,
  info: InfoIcon,
};

const Wrapper = styled('div', {
  borderRadius: '$radii$medium',
  backgroundColor: '$white',
  border: '1px solid $gray-100',
  padding: '$space$6',
  marginBottom: '$space$8',
  '& h2': {
    textStyle: 'medium-heading',
    color: '$primary',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '$space$2',
    '& svg': {
      marginRight: '$space$2',
    },
  },
});

export const Message = ({
  title,
  body,
  type = 'success',
  showIcon = true,
}: {
  title: React.ReactNode;
  body: React.ReactNode | (() => React.ReactNode);
  type?: 'success' | 'alert' | 'info';
  showIcon?: boolean;
}) => {
  const Icon = iconMap[type];
  const displayBody = isFunction(body) ? body() : body;

  return (
    <Wrapper>
      <h2 data-test-id="message-title">
        {showIcon ? <Icon /> : null}
        {title}
      </h2>
      <div className="body">{displayBody}</div>
    </Wrapper>
  );
};
