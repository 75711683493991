import * as React from 'react';
import { useServerValidation } from './use-server-validation';
import {
  DeepPartial,
  UnpackNestedValue,
  useForm,
  UseFormProps,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import type Lazy from 'yup/lib/Lazy';

type useComboFormArguments<FormGeneric> = {
  validationSchema?: Yup.AnyObjectSchema | Lazy<any>;
  // @armando: please sort out the form defaults (this didn't actually work)
  defaultValues?: UnpackNestedValue<DeepPartial<FormGeneric>>;
} & UseFormProps<FormGeneric>;

export function useComboForm<FormGeneric>({
  validationSchema,
  defaultValues,
  ...args
}: useComboFormArguments<FormGeneric>) {
  // @armando what's the simplest way to support a default param here?
  // defaultValues = defaultValues || {};
  const form = useForm<FormGeneric>({
    resolver: validationSchema ? yupResolver(validationSchema) : undefined,
    defaultValues,
    ...args,
  });
  const {
    formState: { errors: clientErrors },
    watch,
  } = form;

  const {
    errors: serverErrors,
    handleValidationErrors: handleServerValidationErrors,
    clearValidationErrors: clearServerValidationErrors,
  } = useServerValidation();

  React.useEffect(() => {
    if (validationSchema) {
      const subscription = watch(() => {
        clearServerValidationErrors();
      });
      return subscription.unsubscribe;
    }
  }, [clearServerValidationErrors, validationSchema, watch]);

  return {
    ...form,
    errors: { ...serverErrors, ...clientErrors },
    handleValidationErrors: handleServerValidationErrors,
    clearValidationErrors: clearServerValidationErrors,
  };
}
