import * as React from 'react';
import { createLogger } from 'app/logger';
import { track } from 'app/track';
import { Navigate, useLocation } from 'react-router-dom';
import { getQueryParamsFromLocation } from '@common/object-from-url-query';
import { alertSevereError } from '@app/notification-service';
import { homePath } from 'components/nav/path-helpers';
import { SoundbiteView } from 'soundbite/views/soundbite-view';

const log = createLogger('admin-soundbite-screen');

//
// handles the editorial preview flow
//

export const AdminSoundbiteScreen = () => {
  const location = useLocation();
  let { url } = getQueryParamsFromLocation(location);
  log.debug(`render - url: ${url}`);

  React.useEffect(() => {
    track('soundbite__player_opened', {
      url,
      screenType: 'admin',
    });
  }, [url]);

  if (url) {
    return <SoundbiteView dataUrl={url} />;
  } else {
    alertSevereError({ error: Error('url query param required') });
    return <Navigate to={homePath()} replace />;
  }
};
