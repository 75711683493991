import * as React from 'react';
import { observer } from 'mobx-react';
import { AppFactory } from 'app/app-factory';
import { ClassroomWidget } from './classroom-widget';
import { SmallGap } from '@naan/primitives/spacer/gaps';

export const ClassroomWidgetList = observer(() => {
  const classrooms =
    AppFactory.root.userManager.accountData.joinedClassroomsWithAssignments;

  return (
    <>
      {classrooms.map(classroom => (
        <React.Fragment key={classroom.id}>
          <ClassroomWidget classroom={classroom} />
          <SmallGap />
        </React.Fragment>
      ))}
    </>
  );
});
