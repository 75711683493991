import * as React from 'react';
import * as Dialog from '@naan/primitives/modals/dialog';
import { observer } from 'mobx-react';
import { DialogHtmlContainer } from 'components/ui/dialog-html-container';
import { Checkbox } from 'components/auth/checkbox';
import { styled } from 'naan/stitches.config';
import { useSwitch2 } from '@naan/hooks/use-switch-2';
import { ResetOptions } from '@core/models/user-manager/story-progress';

import __ from '@core/lib/localization';

const DialogBodyWrapper = styled('div', {
  '& .checkboxes': {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    paddingBottom: 8,
  },
});

export const ResetStoryDialog = observer(
  ({
    onReset,
    onDismiss,
  }: {
    onReset: (options: ResetOptions) => void;
    onDismiss: () => void;
  }) => {
    const progress = useSwitch2(true);
    const vocabulary = useSwitch2(false);
    const soundbites = useSwitch2(false);

    const handleReset = React.useCallback(
      ({
        progress,
        vocabulary,
        soundbites,
      }: {
        progress: boolean;
        vocabulary: boolean;
        soundbites: boolean;
      }) => {
        onReset({
          progress: progress,
          vocabulary: vocabulary,
          soundbites: soundbites,
        });
        // dialog.close();
        // notifySuccess(__('Quickstart guide reset', 'quickstartGuideReset'));
      },
      [onReset]
    );

    return (
      <DialogHtmlContainer onDismiss={onDismiss}>
        <Dialog.HtmlHeading>
          {__('What would you like to reset?', 'whatWouldYouLikeToReset')}
        </Dialog.HtmlHeading>
        <Dialog.Body>
          <DialogBodyWrapper>
            <div className="checkboxes">
              <Checkbox
                presentation="settings"
                label={__(
                  'Chapter listening progress',
                  'chapterListeningProgress'
                )}
                hint={
                  '(' +
                  __(
                    'Your listening statistics will not be affected',
                    'yourListeningStatisticsWillNotBeAffected'
                  ) +
                  ')'
                }
                checked={progress.value}
                onChange={progress.toggle}
              />
              <Checkbox
                presentation="settings"
                label={__('Saved vocabulary', 'savedVocabulary')}
                checked={vocabulary.value}
                onChange={vocabulary.toggle}
              />
              <Checkbox
                presentation="settings"
                label={__('Completed Soundbites', 'completedSoundbites')}
                checked={soundbites.value}
                onChange={soundbites.toggle}
              />
            </div>
          </DialogBodyWrapper>
        </Dialog.Body>
        <Dialog.ButtonsContainer>
          <Dialog.Button
            onClick={onDismiss}
            label={__('Cancel', 'cancel')}
            presentation="grayLight"
          />
          <Dialog.Button
            onClick={() =>
              handleReset({
                progress: progress.value,
                vocabulary: vocabulary.value,
                soundbites: soundbites.value,
              })
            }
            label={__('Reset', 'reset')}
            presentation="redLight"
          />
        </Dialog.ButtonsContainer>
      </DialogHtmlContainer>
    );
  }
);
