/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { NoLicense } from './no-license';
import { Seats } from './seats';

import { Classroom } from 'core/models/user-manager';
import { styled } from '@naan/stitches.config';

const LicenseContainer = styled('div', {
  display: 'flex',
  '& .side': {
    height: '32px',
    display: 'flex',
    alignItems: 'center',
  },
  '& .side + .side': {
    flex: '1',
    display: 'flex',
    borderLeft: '1px solid $colors$gray-100,',
    marginLeft: '8px',
    paddingLeft: '8px',
  },
});

export const LicenseBadge = ({ classroom }: { classroom: Classroom }) => {
  return (
    <LicenseContainer>
      {!classroom.license ? (
        <div className="side" data-test-id="classroom-no-license">
          <NoLicense classroom={classroom} />
        </div>
      ) : (
        <div className="side" data-test-id="classroom-seats">
          <Seats classroom={classroom} />
        </div>
      )}
      {/*devMode ? (
        <Pi onClick={() => console.log(classroom.toJSON())}>π</Pi>
      ) : null*/}
    </LicenseContainer>
  );
};
