export function toggleClassname(
  element: HTMLElement,
  className: string,
  condition: boolean
) {
  if (condition) {
    element?.classList.add(className);
  } else {
    element?.classList.remove(className);
  }
}
