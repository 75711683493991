import { styled } from '@naan/stitches.config';

export const HelpBoxStyles = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  padding: 16,
  paddingTop: 24,
  paddingBottom: 28,
  borderRadius: '$radii$large',
  '& > .icon': {
    color: '$$iconColor',
    marginBottom: 8,
    '& svg': {
      width: 32,
      height: 32,
    },
  },
  '& > h3': {
    textStyle: 'small-heading',
    marginBottom: 4,
  },
  '& > .content': {
    maxWidth: 400,
  },
  a: {
    color: 'inherit',
  },
  button: {
    marginBottom: 4,
  },
  '@medium': {
    padding: 32,
    paddingBottom: 36,
    '& > .icon': {
      marginBottom: 12,
      '& svg': {
        width: 48,
        height: 48,
      },
    },
  },
});
