import Dayjs from 'dayjs';
import __ from '@core/lib/localization';

export function getRelativeTime(
  date: Dayjs.Dayjs,
  now: Dayjs.Dayjs /* expect root.today to be explicitly passed in */
): string {
  const diff = Math.round(now.diff(date, 'day'));

  if (diff < 1) {
    return __('today', 'todayLower');
  } else if (diff === 1) {
    return __('yesterday', 'yesterdayLower');
  } else if (diff < 7) {
    return __('%{count} days ago', 'daysAgoCount', {
      count: diff,
    });
  } else if (diff < 30) {
    return __(
      { one: '1 week ago', other: '%{count} weeks ago' },
      'weeksAgoCount',
      {
        count: Math.floor(diff / 7),
      }
    );
  } else if (diff < 365) {
    return __(
      { one: '1 month ago', other: '%{count} months ago' },
      'monthsAgoCount',
      {
        count: Math.floor(diff / 30),
      }
    );
  } else {
    return __(
      { one: '1 year ago', other: '%{count} years ago' },
      'yearsAgoCount',
      {
        count: Math.floor(diff / 365),
      }
    );
  }
}
