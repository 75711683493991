import * as React from 'react';
import { ChapterCatalogData } from '@core/models/catalog';
import { observer } from 'mobx-react';
// import { ChapterButtons } from '../chapter-buttons';
import { ChapterListHeader } from './chapter-item-contents/chapter-list-header';
// import { AppFactory } from '@app/app-factory';
// import { maybePresentEndOfStoryCtaDialog } from 'components/cta/end-of-story-cta-dialog';
import { styled } from '@naan/stitches.config';
import { ChapterSoundbites } from './chapter-item-contents/chapter-soundbites';
import { Box } from '@naan/primitives/box';
import { ChapterStudyCard } from './chapter-item-contents/chapter-study-card';
import {
  ChapterItemContents,
  CollapsibleWrapper,
} from './chapter-item-contents/chapter-item-contents';
import { useChapterRowUiContext } from './chapter-item-contents/chapter-row-ui-model';
import { OnboardingService } from '@app/onboarding/onboarding-service';
import { OnboardingPopover } from 'components/ui/onboarding/onboarding-popover';
import { SkipSoundbitesTip } from 'components/ui/onboarding/skip-soundbites-tip';
import classNames from 'classnames';
import { CircleIcon } from './components/circle-icon';
// import { useChapterListUiModel } from './chapter-item-contents/chapter-list-ui-model';
import { storyDetailUiModel } from 'components/story-detail/story-detail-ui-model';
import { scrollToCurrentChapter } from 'routes/learn/scrolling-fx';

const ToolipPositioner = styled('span', {
  '&.highlight > *': {
    outline: '6px solid $teal-300',
    borderRadius: '$radii$large',
  },
});

const Tooltip: React.FC<{ children: React.ReactNode }> = observer(
  ({ children }) => {
    const dismissed = OnboardingService.instance.isDismissed(
      'skipSoundbitesToChapter'
    );
    const showPopover =
      OnboardingService.instance.showSkipSoundbitesToChapterTip;

    return (
      <OnboardingPopover
        dismissed={dismissed}
        showPopover={showPopover}
        renderAnchor={({ ref, getProps }) => (
          <ToolipPositioner
            ref={ref}
            {...getProps()}
            className={showPopover ? 'highlight' : null}
          >
            {children}
          </ToolipPositioner>
        )}
        renderBody={() => {
          return <SkipSoundbitesTip />;
        }}
        onResolvePopover={OnboardingService.instance.onSoundbitesSkipDismissed}
      />
    );
  }
);

export const CurrentAndFurthest = observer(
  ({ chapter }: { chapter: ChapterCatalogData }) => {
    const anySoundbites = chapter.hasSoundbites;
    const model = useChapterRowUiContext();

    const ref = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
      if (!anySoundbites && model.currentStage === 'soundbites') {
        model.setCurrentStage('study');
      }

      /// this tells the model which is the container we are going to animate
      storyDetailUiModel.setCurrentWrapper(ref.current);
      /// this flag is sent whenever we mark the previous chapter as complete
      /// if present, the container will start collapsed and then expand after 300ms
      if (storyDetailUiModel.pendingExpandTransition) {
        setTimeout(() => {
          void storyDetailUiModel
            .imperativeExpandChapterContainer()
            .then(() => {
              storyDetailUiModel.setPendingExpandTransition(false);
            });
        }, 300);
        /// @frankharrison:
        /// this second timeout helps making the scrolling AND expanding to happen at the same-ish time.
        /// it's a matter of playing with the values
        setTimeout(() => {
          // this worked with my tests but you can move it to the .then block above
          // and it will execute after the expand css animation is over
          scrollToCurrentChapter(true);
        }, 400);
      }

      // }
    }, [anySoundbites, model]);

    return (
      <Box css={{ overflowY: 'clip' }}>
        <ChapterListHeader
          chapter={chapter}
          presentation={chapter.unstarted ? 'unvisited' : 'current'}
          stationIcon={<CircleIcon presentation="teal" />}
        />
        <CollapsibleWrapper
          ref={ref}
          className={classNames({
            collapsed: storyDetailUiModel.pendingExpandTransition,
          })}
        >
          <ChapterItemContents
            className={classNames('expandable-inner', {
              'is-last': chapter.isLastOne,
            })}
          >
            <ChapterSoundbites chapter={chapter} />
            <Tooltip>
              <ChapterStudyCard chapter={chapter} />
            </Tooltip>
          </ChapterItemContents>
        </CollapsibleWrapper>
      </Box>
    );
  }
);
