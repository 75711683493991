import * as React from 'react';
import * as Dialog from 'naan/primitives/modals/dialog';
import { PlainMarkdown, Text } from 'naan/primitives/text';
import { styled } from 'naan/stitches.config';
import { VStack } from 'naan/primitives/stack';
import { VSpacer } from 'naan/primitives/spacer';
import { Image } from '@naan/primitives/image';

import __, { pickLocalizedValue } from '@core/lib/localization';

import image1en from './illustrations/resources-illustration-01-en.png';
import image2en from './illustrations/resources-illustration-02-en.png';
import image3en from './illustrations/resources-illustration-03-en.png';

import image1pt from './illustrations/resources-illustration-01-pt.png';
import image2pt from './illustrations/resources-illustration-02-pt.png';
import image3pt from './illustrations/resources-illustration-03-pt.png';

const images = {
  en: {
    image1: image1en,
    image2: image2en,
    image3: image3en,
  },
  pt: {
    image1: image1pt,
    image2: image2pt,
    image3: image3pt,
  },
};

const Layout = styled('div', {
  width: 'min(900px, calc(100vw - 64px))',
});
const Intro = styled('div', {
  maxWidth: 'calc(100vw - 72px)',
  marginBottom: 16,
  '@medium': {
    maxWidth: '60ch',
  },
});
const Features = styled('div', {
  maxWidth: 'calc(100vw - 72px)',
  margin: 0,
  '@medium': {
    display: 'grid',
    gridTemplateColumns: 'repeat(3,1fr)',
    gap: '$space$6',
  },
});
const FeatureWrapper = styled('div', {
  img: {
    maxWidth: '100%',
  },
});

const Feature = ({
  image,
  title,
  content,
}: {
  image: string;
  title: string;
  content: string;
}) => (
  <FeatureWrapper>
    <VStack>
      <Image src={image} alt={title} />
      <VSpacer size={4} />
      <Text textStyle={'small-heading'}>{title}</Text>
      <VSpacer size={3} />
      <Text>{content}</Text>
      <VSpacer size={4} />
    </VStack>
  </FeatureWrapper>
);

export const TeacherResourcesModal = ({
  onDismiss,
}: {
  onDismiss: () => void;
}) => {
  const { image1, image2, image3 } = pickLocalizedValue(images);

  return (
    <Dialog.Container
      open={true}
      onDismiss={onDismiss}
      css={{ $$maxWidth: 'auto' }}
    >
      <Dialog.CloseButton />
      <Dialog.Heading>
        {__('Teacher Resources', 'teacherResources')}
      </Dialog.Heading>
      <Dialog.Body>
        <Layout id="modal-wrapper">
          <VStack>
            <Intro>
              <PlainMarkdown
                source={__(
                  `Jiveworld is a self-paced language learning platform for students to focus on listening fluency and vocabulary acquisition. The following resources support you in the classroom so you can dive deeper into the themes and language in the story, keeping your students engaged.`,
                  'jiveworldIsASelfPacedLanguageLearningPlatform'
                )}
              />
            </Intro>
            <VSpacer size={4} />
            <Features>
              <Feature
                image={image1}
                title={__('Activity Guides', 'activityGuides')}
                content={__(
                  `Authored by top language teachers, each e-booklet has a series of ready-to-deploy student activities that build towards a final creative deliverable based on the story theme.`,
                  'authoredByTopLanguageTeachersEachEBookletHasASeries'
                )}
              />
              <Feature
                image={image2}
                title={__('Story Scripts', 'storyScripts')}
                content={__(
                  `Two printable scripts for each story, to help you review and plan your lessons: the "Basic Script" sets out the full transcript and translation side by side; the "Scaffolded Script" adds structure with chapters and passage headers, plus curated vocabulary and language notes.`,
                  'twoPrintableScriptsForEachStoryToHelpTeachersReviewAnd'
                )}
              />
              <Feature
                image={image3}
                title={__('Listening Worksheets', 'listeningWorksheets')}
                content={__(
                  `Students use these to take structured notes while listening. Each comes in two formats: for printing and writing; and for importing into Google Docs or Word for typing.`,
                  'studentsUseTheseToTakeStructuredNotesWhileListeningEach'
                )}
              />
            </Features>
          </VStack>
        </Layout>
      </Dialog.Body>
    </Dialog.Container>
  );
};

// /// TODO: remove this in favor of the stateful version
// export const TeacherResources = () => {
//   const navigate = useNavigate();

//   return (
//     <Routes>
//       <Route
//         path={'teacher-resources'}
//         element={
//           <TeacherResourcesContent onDismiss={() => navigate(-1)} open={true} />
//         }
//       />
//     </Routes>
//   );
// };
