// import { appConfig } from '@app/env';
import { ModelTreeNode } from 'ts-state-tree/tst-core';
// import { createLogger } from '@common/log';
// const log = createLogger('local-state');

//
// global configuration data owned by firestore singleton doc (scoped to server environment)
//

export class GlobalSettings extends ModelTreeNode {
  static CLASS_NAME = 'GlobalSettings' as const;

  // todo: reslice default catalog url as an L2 scoped sub-property instead of fully duplicated settings
  // will likely also want to slice off from the AppRoot so it can be loaded before the /welcome screen

  // catalogSlug: string = DEFAULT_CATALOG_SLUG; // default catalog unless overridden within userData
  // catalogSlug: string = defaultCatalogSlugForL2(defaultL2()); // default catalog unless overridden within userData
  catalogUrl: string; // = FALLBACK_CATALOG_URL; // default url for catalog slug - denormalized data to avoid extra roundtrip to node server

  // drives embedded mode update banners
  appStoreBuildNumber: number = 0;
  playStoreBuildNumber: number = 0;

  debugBuildNumber: number = 0; // triggers apple review mode when matched

  // stripe client key needs to be paired with node server, so easiest to configure via firebase
  stripePublishableKey: string;

  // still driven by rails accountData
  // debugBuildNumber?: number; // drives special apple review behavior

  // don't need to restrict yet beyond the apple review mode handling
  // iosNosCreate: boolean;
  // androidNoCreate: boolean;

  static create(snapshot: any = {}): GlobalSettings {
    return super.create(GlobalSettings, snapshot) as GlobalSettings;
  }

  // expect to get the latest catalog url from the node server, but fallback to a hardwired
  // catalog url if that fails or is unexpectedly missing
  get defaultCatalogUrl(): string {
    return this.catalogUrl; // || FALLBACK_CATALOG_URL;
  }
}
