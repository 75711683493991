import { styled } from 'naan/stitches.config';

export const Link = styled('a', {
  $$linkColor: '$colors$teal-500',
  textStyle: 'body',
  color: '$$linkColor',
  '&:hover': {
    $$linkColor: '$colors$teal-600',
  },
  variants: {
    destructive: {
      true: {
        $$linkColor: '$colors$red-500',
        '&:hover': {
          $$linkColor: '$colors$red-600',
        },
      },
    },
  },
});
