import * as React from 'react';
import { observer } from 'mobx-react';
import { AppFactory } from '@app/app-factory';
import { ReturnNavState } from 'components/nav/return-nav-state';
import { PageHeading } from 'components/page-heading';
import { CenterColumnLayout } from 'components/layouts/center-column-layout';
import { CalendarHeader } from './calendar-header';
import { CalendarStoryCard } from './calendar-story-card';
import { WeekHeading } from './week-header';
import { SoundbiteCalendarEdition } from './soundbite-calendar-card';
import { DeepScrollRestoration } from 'lib/scroll-utils/deep-scroll-restoration';
import { useLocation } from 'react-router-dom';

import __ from '@core/lib/localization';
import { useEnableUpdatePrompt } from '@common/hooks/use-enable-update-prompt';

export const CalendarScreen = observer(() => {
  useEnableUpdatePrompt();
  const location = useLocation();
  // track when it's safe to pop back from detail or study view
  ReturnNavState.calendarVisited = true;

  const { storyManager } = AppFactory.root;
  const { calendarEditions } = storyManager;

  return (
    <CenterColumnLayout isolate>
      <DeepScrollRestoration pageKey={location.pathname} />

      <PageHeading title={__('Soundbites', 'soundbites')} />
      <CalendarHeader />

      {calendarEditions.map(edition => (
        <>
          <WeekHeading visibilityDayjs={edition.visibilityDayjs} />
          <CalendarStoryCard edition={edition} />
          <SoundbiteCalendarEdition edition={edition} />
        </>
      ))}
    </CenterColumnLayout>
  );
});
