import Dayjs from 'dayjs';
import { floor } from 'lodash';

export const MILLIS_PER_DAY = 24 * 60 * 60 * 1000;

export const decrementDate = (isoDate: string): string => {
  return adjustDate(isoDate, -1);
};

export const adjustDate = (isoDate: string, deltaDays: number): string => {
  const millis = Date.parse(isoDate);
  if (!millis) {
    return null;
  }
  return epochMillisToIso8601Date(millis + deltaDays * MILLIS_PER_DAY);
};

export const epochMillisToIso8601Date = (millis: number): string => {
  if (!millis) {
    return null;
  } else {
    return new Date(millis).toISOString().split('T')[0]; // better way??
  }
};

export const epochSecondsToIso8601Date = (seconds: number): string => {
  if (!seconds) {
    return null;
  } else {
    return epochMillisToIso8601Date(seconds * 1000);
  }
};

export const dayjsToIsoDate = (date: Dayjs.Dayjs) => date?.format('YYYY-MM-DD');

export const dayOfMondayBasedWeek = (date: Date) => (date.getDay() + 6) % 7;

// need a reference date in the same timezone as the user to diff from
const DAYJS_EPOCH = Dayjs('1970-01-01');

// this version didn't work on british time
// export const daysSinceEpoch = (date: Date): number =>
//   (date?.getTime() || 0) / MILLIS_PER_DAY;

export const daysSinceEpoch = (date: Date): number => {
  if (!date) {
    return 0;
  }
  const dayjs = Dayjs(date);
  const result = dayjs.diff(DAYJS_EPOCH, 'days');
  return result;
};

// monday based cardinal week since unix epoch
export const weeksSinceEpoch = (date: Date): number =>
  floor((daysSinceEpoch(date) - 4) / 7);

// monday based
export const firstDayOfWeek = (dayjs: Dayjs.Dayjs = Dayjs()): Dayjs.Dayjs => {
  return dayjs.subtract((dayjs.day() + 6) % 7, 'day');
};
