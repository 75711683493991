import * as React from 'react';
import { styled } from 'naan/stitches.config';

const StyledLabel = styled('label', {
  textStyle: 'body-bold',
});

type LabelProps = React.ComponentProps<typeof StyledLabel> & {};

export const Label = ({ ...props }: LabelProps) => {
  return <StyledLabel {...props} />;
};
