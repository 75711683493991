import * as React from 'react';

export function MegaphoneIcon({
  width = 24,
  height = 24,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.261 1.56966L15.7767 4.85551L14.4097 4.238L15.894 0.952148L17.261 1.56966ZM9.47513 2.16116C8.88433 2.53034 8.79858 3.36433 8.68681 4.45131C8.47772 6.48486 8.17759 9.40384 4.30906 11.8212L2.18894 13.146C0.953059 13.9182 0.390251 14.877 1.94968 17.4762C3.60219 20.0172 4.71067 19.9317 5.94655 19.1594L8.06667 17.8346C11.9352 15.4173 14.6903 16.4271 16.6097 17.1306C17.6357 17.5067 18.4229 17.7952 19.0137 17.426C20.2978 16.6236 17.4428 12.0705 15.663 9.23209L15.5166 8.99862L15.371 8.76473C13.6 5.92076 10.7593 1.35873 9.47513 2.16116ZM8.99655 18.3791L6.4524 19.9689L7.7772 22.089C8.2162 22.7916 9.14161 23.0052 9.84415 22.5662C10.5467 22.1272 10.7603 21.2018 10.3213 20.4992L8.99655 18.3791ZM17.6097 8.57508L21.0019 6.4554L20.207 5.18333L16.8148 7.303L17.6097 8.57508ZM19.3543 10.5815L22.9583 10.6878L22.9141 12.1872L19.3101 12.0808L19.3543 10.5815Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}
