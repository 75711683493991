import React from 'react';
import { observer } from 'mobx-react';
import { styled } from '@naan/stitches.config';
import { PlusIcon } from '@naan/icons/plus-icon';
import { MinusIcon } from '@naan/icons/minus-icon';
import { useSwitch2 } from '@naan/hooks/use-switch-2';
// import { useOnClickOutside } from '@common/hooks/use-onclick-outside';
import { ControlButton } from './control-button';
import classNames from 'classnames';
import { SnailIcon } from '@naan/icons/snail-icon';
import { usePlayerModel } from '../player-model-context';

const Wrapper = styled('div', {
  position: 'relative',

  '.overlay': {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    // bottom: 80,
    bottom: 0,
    zIndex: 1,
    backgroundColor: 'rgba(0,0,0,0.05)',
    // backdropFilter: 'blur(0)',
    opacity: 0,
    visibility: 'none',
    pointerEvents: 'none',
    transition: 'opacity 0.3s ease-in-out, visibility 0.2s ease-in-out',
    '&.open': {
      opacity: 1,
      visibility: 'visible',
      pointerEvents: 'all',
      // backdropFilter: 'blur(1px)',
    },
  },

  '.popover': {
    backgroundColor: '$colors$white',
    borderRadius: '$radii$large',
    // boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.2)',
    shadowStyle: 'medium',
    position: 'absolute',
    bottom: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    transform: 'scale(0.5) translateY(55%)',
    // transition: 'transform 0.12s ease-in-out',
    transition: 'transform 0.18s ease-in-out, opacity 0.12s ease-in-out',
    opacity: 0,
    // opacity: 0.5,
    pointerEvents: 'none',
    zIndex: 2,

    [`& > ${ControlButton}`]: {
      color: '$gray-400',
      transform: 'scale(0.5)',
      transition: 'transform 0.24s ease-in-out',
      '&:active': {
        color: '$gray-500',
      },
    },

    '& > .current': {
      textStyle: 'body-bold',
      transform: 'scale(2)',
      transition: 'transform 0.18s ease-in-out',
    },

    '&.open': {
      transform: 'scale(1) translateY(0)',
      opacity: 1,
      pointerEvents: 'all',
      transition: 'transform 0.2s ease-in-out, opacity 0.3s ease-in-out',

      [`& > ${ControlButton}`]: {
        transform: 'scale(1)',
      },

      '& > .current': {
        transform: 'scale(1)',
      },
    },
  },
});

export const MobilePlaybackRateControl = observer(() => {
  const model = usePlayerModel();
  const showSwitch = useSwitch2(false);
  const ref = React.useRef<HTMLDivElement>(null);
  // useOnClickOutside(ref, () => {
  //   showSwitch.off();
  // });
  const { player } = model;
  const { transportState } = player;

  if (model.inSnailReplayMode) {
    return (
      <ControlButton
        css={{ color: '$orange-500' }}
        onClick={() => model.handleSnailReplayRestore(true /*force*/)}
      >
        <SnailIcon height={36} width={36} />
      </ControlButton>
    );
  }

  return (
    <Wrapper>
      <div
        className={classNames(['overlay', { open: showSwitch.value }])}
        onClick={() => showSwitch.off()}
      />
      <div
        className={classNames(['popover', { open: showSwitch.value }])}
        ref={ref}
      >
        <ControlButton
          onClick={() => {
            player.adjustPlaybackRateAction(true);
          }}
        >
          <PlusIcon />
        </ControlButton>
        <span className="current">{` ${transportState.playbackRate}x`}</span>
        <ControlButton
          onClick={() => {
            player.adjustPlaybackRateAction(false);
          }}
        >
          <MinusIcon />
        </ControlButton>
      </div>
      <ControlButton
        onClick={showSwitch.toggle}
      >{` ${transportState.playbackRate}x`}</ControlButton>
    </Wrapper>
  );
});
