import React from 'react';
import { typeMap } from './type-map';
import { ElementNode } from '@tikka/client/client-aliases';

export const StoryTree = ({ node, ...props }: { node: ElementNode }) => {
  return (
    <>
      {node.children.map(node => {
        const Component = typeMap[node.element.kind];
        if (Component) {
          return <Component node={node} key={node.element.id} {...props} />;
        }
        return null; // ignore chapter title and note element types
      })}
    </>
  );
};
