import * as React from 'react';
import { observer } from 'mobx-react';
import Dayjs from 'dayjs';
import { getWeekTitle } from './get-week-title';
import { styled } from '@naan/stitches.config';
import { AppFactory } from '@app/app-factory';

const Wrapper = styled('div', {
  textStyle: 'small-caps',
  color: '$textSecondary',
  paddingBottom: 8,
  borderBottom: '1px solid $gray-100',
});

export const WeekHeading = observer(
  ({ visibilityDayjs }: { visibilityDayjs: Dayjs.Dayjs }) => {
    const todayDayjs = AppFactory.root.storyManager.today;
    return <Wrapper>{getWeekTitle(visibilityDayjs, todayDayjs)}</Wrapper>;
  }
);
