import React, { useLayoutEffect } from 'react';
import { useKeyboardBinding } from 'common/hooks/use-keyboard-binding';
import { useOnClickOutside } from 'common/hooks/use-onclick-outside';
import { Portal } from './portal';
import { styled } from '@naan/stitches.config';

const ModalBackground = styled('div', {
  position: 'fixed',
  margin: '0',
  padding: '0',
  left: '0',
  right: '0',
  top: '0',
  bottom: '0',
  background: 'rgba(0, 0, 0, 0.2)',
  zIndex: '2147483647',
});

const ModalContainer = styled('div', {
  width: '100%',
  height: '100%',
  display: 'flex',
  flex: '1',
  justifyContent: 'center',
  alignItems: 'center',
});

export const Modal = ({
  children,
  contentRef,
  onEscape = () => {},
  onClickOutside = () => {},
}: {
  children: React.ReactNode;
  contentRef?: React.RefObject<HTMLDivElement>;
  onEscape?: () => void;
  onClickOutside?: () => void;
}) => {
  useKeyboardBinding('ESCAPE', onEscape);
  useOnClickOutside(contentRef, onClickOutside);
  // this adds a special class to the body that would prevent it scrolling while the modal is being shown
  useLayoutEffect(() => {
    document.body.classList.add('__no-scroll');
    return () => {
      document.body.classList.remove('__no-scroll');
    };
  });
  return (
    <Portal>
      <ModalBackground className="__modal-container">
        <ModalContainer>{children}</ModalContainer>
      </ModalBackground>
    </Portal>
  );
};
