import { Story } from 'core/models/story-manager';
import { get } from 'lodash';
import Moment from 'moment'; // TODO: remove 'moment' usage in favor of dayjs

const filterNewStories = (
  stories: Story[], // array of stories
  now = Moment(), // for testing purposes, allows us to control when "today" is
  datePath = 'releaseDate' // how to `get` the date from the story object, useful for testing
) => {
  const today = Moment(now); // whatever the input, cast as a moment instance

  // we'll start seeking for stories published this week
  // week starts on sunday
  const controlDate = Moment(today).startOf('week');

  // First, we remove any future or invalid story to make this faster
  const candidates = stories.filter(story => {
    const releaseDate = get(story, datePath);
    // some stories have no release dates
    // we'll ignore them
    if (!releaseDate) {
      return false;
    }

    const storyDate = Moment(releaseDate);

    // if the story is set in the future, ignore for now
    if (storyDate.isAfter(today)) {
      return false;
    }

    return true;
  });

  let n = 0;

  do {
    // console.log('WEEK LOOKUP', n);

    // we get all the stories that are to be released after controlDate
    const stories = candidates.filter(story => {
      const storyDate = Moment(get(story, datePath));
      return storyDate.isAfter(controlDate);
    });

    // if we find any, will return just those
    if (stories.length > 0) {
      return stories;
    }

    // if we don't, let's move the control date 1 week back and try again
    controlDate.subtract(1, 'week');

    n++;
  } while (n < 52); // I'm scared of infinite loops
  return []; // don't barf if no stories with a release date (actually happened when testing a demo catalog)
};

export default filterNewStories;
