import { __f } from '@core/lib/localization';

export type SoundbiteCategory =
  | 'accents'
  | 'vernacular'
  | 'vocabulary'
  | 'grammar'
  | 'patterns'
  | 'idioms'
  | 'strategies'
  | 'culture';

type SoundbiteCategoryInfo = {
  labelFn: () => string;
  tooltipFn: () => string;
};

// beware, this data is duplicated in soundbite-welcome-dialog/index.tsx
export const SoundbiteCategoriesInfo: Record<
  SoundbiteCategory,
  SoundbiteCategoryInfo
> = {
  accents: {
    labelFn: __f('Speech & Accents', 'speechAccents'),
    tooltipFn: __f(
      'Appreciating the distinctive sound and style of different speakers',
      'distinctiveSound'
    ),
  },
  vernacular: {
    labelFn: __f('Vernacular & Slang', 'vernacularSlang'),
    tooltipFn: __f(
      'Colloquial usage by region or demographic',
      'colloquialUsage'
    ),
  },
  vocabulary: {
    labelFn: __f('Vocabulary', 'vocabulary'),
    tooltipFn: __f('Interesting words and expressions', 'interestingWords'),
  },
  grammar: {
    labelFn: __f('Grammar', 'grammar'),
    tooltipFn: __f('Rules for how the language fits together', 'languageRules'),
  },
  patterns: {
    labelFn: __f('Useful Patterns', 'usefulPatterns'),
    tooltipFn: __f(
      'Versatile language structures that boost your fluency',
      'versatileStructures'
    ),
  },
  idioms: {
    labelFn: __f('Idioms & Sayings', 'idiomsSayings'),
    tooltipFn: __f(
      'Weird and wonderful expressions with interesting cultural roots',
      'weirdExpressions'
    ),
  },
  strategies: {
    labelFn: __f('Strategies', 'strategies'),
    tooltipFn: __f(
      'Hacks for navigating social situations (e.g. offering condolences), and how to study more effectively',
      'socialHacks'
    ),
  },
  culture: {
    labelFn: __f('History & Culture', 'historyCulture'),
    tooltipFn: __f(
      'Famous people and institutions, music and movies, celebrations and beliefs',
      'famousPeople'
    ),
  },
};
