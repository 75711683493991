import React from 'react';
import { ElementNode, Passage } from '@tikka/client/client-aliases';
import { passageHintText } from '@tikka/client/client-data';
import { MarkSimple } from '@naan/primitives/text';
import { hashCode } from '@utils/string-utils';
import { styled } from '@naan/stitches.config';

const Wrapper = styled('h3', {
  paddingTop: '32px',
  color: 'var(--dark-blue)',
  marginBottom: '24px',
});

// export const OldPassage = ({ root: passage }: { root: PassageData }) => {
//   const { elements, address } = passage;
//   const chapter = useChapterContext() as ChapterCatalogData;
//   const id = chapter.position + '--' + address.replace('.', '-');
//   return (
//     <Wrapper id={id}>
//       {elements
//         .filter(el => isParagraphData(el))
//         .map((paragraph, i) => {
//           const hint = i === 0 ? passage.hint : null;
//           return (
//             <Paragraph
//               paragraph={paragraph as ParagraphData}
//               hint={hint}
//               key={`passage-${i}`}
//             />
//           );
//         })}
//     </Wrapper>
//   );
// };

export const PassagePresentation = ({ node }: { node: ElementNode }) => {
  const element = node.element as Passage;
  const hint = passageHintText(element);

  const id = element.id || String(hashCode(hint)); // fallback until all catalog data reingested
  return (
    <Wrapper id={id}>
      <MarkSimple source={hint} />
    </Wrapper>
  );
};
