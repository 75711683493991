import React from 'react';
import { observer } from 'mobx-react';
import { formatDate } from 'utils/format-date';
import { AppFactory } from '@app/app-factory';
import { LocaleCode } from '@utils/util-types';
import {
  Container,
  Label,
  LeftSide,
  RightSide,
  Row,
  Value,
} from './components';
import { Button } from '@naan/primitives/button';
import { AccountScreenUiController } from '../account-screen-ui-controller';

import __ from 'core/lib/localization';

export const FullNoRenew = observer(({ l2 }: { l2: LocaleCode }) => {
  // we no longer show pricing cards while access is still active
  // const changing = useSwitch2(false);

  const { userManager } = AppFactory.root;
  const membership = userManager.membershipL2(l2);
  const {
    membershipTypeDisplay,
    fullAccessUntil,
    // showAccountPageCheckout,
    statusKey,
  } = membership;
  const isExpired = statusKey === 'expired';

  if (userManager.purchaseFlowDisabled) {
    return null;
  }

  // const autoExpand = membership.showPriceIncreaseInlineNotice;
  // const showPrinceIncreaseMessage = membership.showPriceIncreaseInlineNotice;

  const changing = AccountScreenUiController.instance.extendingL2 === l2;

  return (
    <>
      {/* <MediumHeading>{__('My membership', 'myMembership')}</MediumHeading>
        <VSpacer size="4" /> */}
      <Container className={'extend-row ' + (changing ? 'borderless' : '')}>
        <Row>
          <Label>{__('Membership type', 'membershipType')}</Label>
          <Value> {membershipTypeDisplay}</Value>
        </Row>

        <Row>
          <LeftSide>
            <Label>
              {isExpired
                ? __('Expired on', 'expiredOn')
                : __('Expires', 'expires')}
            </Label>
            <Value>{formatDate(fullAccessUntil)}</Value>
          </LeftSide>
          <RightSide>
            {/*             {autoExpand === false && showAccountPageCheckout ? (
              <Button
                presentation={changing ? 'grayLight' : 'teal'}
                size="small"
                onClick={() =>
                  AccountScreenUiController.instance.toggleExtendL2(l2)
                }
                label={changing ? __('Close', 'close') : __('Extend', 'extend')}
              ></Button>
            ) : null} */}
            {changing ? null : (
              <Button
                presentation={changing ? 'grayLight' : 'teal'}
                size="small"
                onClick={() =>
                  AccountScreenUiController.instance.toggleExtendL2(l2)
                }
                label={changing ? __('Close', 'close') : __('Extend', 'extend')}
              />
            )}
          </RightSide>
        </Row>
      </Container>
    </>
  );
});
