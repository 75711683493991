import * as React from 'react';
import * as Layout from './volume-card-layout';
import { FullyStyledMarkdown, MarkSimple } from 'naan/primitives/text';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from 'naan/stitches.config';
import { Story } from 'core/models/story-manager';
import { VolumeCardMeta } from './volume-card-meta';
import { ResponsiveTeacherVolumeCardHeaderControls } from './volume-card-header-controls';
import { TeacherMeta } from './teacher-meta';
import { VolumeTagLinks } from './volume-tag-links';
import { observer } from 'mobx-react';
import { useFavoriteToggle } from 'lib/hooks/use-favorite-toggle';
import { discoverPath } from 'components/nav/path-helpers';

// classroom portal flavor
type VolumeCardProps = {
  story: Story;
  truncateDescription?: boolean;
  onClassAssignment: () => void;
};

const Link = styled(RouterLink, {
  color: 'inherit',
  fontSize: 'inherit',
  fontFamily: 'inherit',
  textDecoration: 'none',
});

const Title = styled('h2', {
  textStyle: 'small-heading',
  color: '$colors$textPrimary',
  margin: '-8px 0',
  padding: '8px 0',
  'span.suffix': {
    marginLeft: '$space$1',
    color: '$colors$textSecondary',
  },
  '&:hover': {
    color: '$colors$teal-500',
  },
});

export const VolumeCard = observer(
  ({
    story,
    truncateDescription = false,
    onClassAssignment,
  }: VolumeCardProps) => {
    const permalink = `v/${story.slug}`;
    const { title } = story;

    const description = story.description;
    const thumbUrl = story.listImageUrl;

    const { favoriteToggle } = useFavoriteToggle(story);

    return (
      <Layout.Wrapper>
        <Layout.Figure to={permalink}>
          <Layout.Thumbnail src={thumbUrl} alt={title} />
        </Layout.Figure>
        <Layout.HeaderWrapper>
          <Layout.Header>
            <Link to={permalink}>
              <Title data-test-id="card-title">
                <MarkSimple source={title} />
              </Title>
            </Link>
            <Layout.Meta>
              <VolumeCardMeta story={story} />
            </Layout.Meta>
          </Layout.Header>
          <Layout.HeaderTools>
            <ResponsiveTeacherVolumeCardHeaderControls
              onFavorite={favoriteToggle}
              onClassAssignment={onClassAssignment}
              isFavorited={story.isClassroomFavorited}
              badgeCount={story.assignCount}
              storySlug={story.slug}
            />
          </Layout.HeaderTools>
        </Layout.HeaderWrapper>
        <Layout.Description className={truncateDescription ? 'truncate' : ''}>
          <FullyStyledMarkdown
            source={description}
            css={{ textStyle: 'small-text', color: '$textSecondary' }}
          />
        </Layout.Description>
        <Layout.TeacherTags>
          <TeacherMeta story={story} />
          <VolumeTagLinks tags={story.allTags} linkPrefix={discoverPath()} />
        </Layout.TeacherTags>
      </Layout.Wrapper>
    );
  }
);
