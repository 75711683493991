import * as React from 'react';
import { FilterButton } from 'components/filters/filter-button';
import { SortIcon } from 'naan/icons/sort-icon';
import { HSpacer } from 'naan/primitives/spacer';
import { Menu, CheckMenuItem } from 'naan/primitives/menus';
import { sortingKeysFn, TSortingKey } from 'common/filtering/sorting-keys';
import { TFilteringContext } from 'common/filtering/filtering-context';

export const SortByMenuButton = ({
  sorting,
  setSortingKey,
}: {
  sorting: TFilteringContext['sorting'];
  setSortingKey: TFilteringContext['setSortingKey'];
}) => {
  const handleSetSorting = (newSortingOption: TSortingKey) => () => {
    setSortingKey(newSortingOption);
  };

  const label = sortingKeysFn[sorting.name as TSortingKey]() ?? 'duration';

  return (
    <Menu
      align="end"
      offset={-8}
      trigger={
        <FilterButton
          label={label}
          textSize={'small'}
          leftIcon={
            <>
              <SortIcon width={16} height={16} />
              <HSpacer size={1} />
            </>
          }
        />
      }
    >
      {Object.keys(sortingKeysFn).map(key => {
        const label = sortingKeysFn[key as TSortingKey]();
        return (
          <CheckMenuItem
            key={key}
            label={label}
            action={handleSetSorting(key as TSortingKey)}
            checked={sorting?.name === key}
          />
        );
      })}
    </Menu>
  );
};
