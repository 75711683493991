import * as React from 'react';
import { observer } from 'mobx-react';
import { PricingCards } from 'components/account/pricing-cards';
import { VSpacer } from '@naan/primitives/spacer';
import { PlainCouponLink } from '../../coupons/coupon-buttons';
import { ExternalLink } from '@naan/primitives/text/external-link';
import { marketingSiteL1 } from 'components/nav/path-helpers';
import { createLogger } from '@common/log';
import { AppFactory } from '@app/app-factory';
import { styled } from '@naan/stitches.config';

import __ from 'core/lib/localization';

const log = createLogger('authenticated-flow');

const Copy = styled('div', {
  '& strong': {
    textStyle: 'body-bold',
  },
});

export const AuthenticatedFlow = observer(() => {
  log.info(`render - selectedL1: ${AppFactory.root.locale}`);
  const { l2 } = AppFactory.root;

  // afunction to force a re-render
  const forceFullTreeRerender = React.useCallback(async () => {
    await AppFactory.root.appInitialize();
    // the rerender above is abit disorienting because it scrolls to the top
    // this is a hack to scroll back to the priing cards
    setTimeout(() => {
      const anchor = document.getElementById('after-pricing-cards');
      if (anchor) {
        anchor.scrollIntoView();
      }
    }, 100);
  }, []);

  return (
    <div>
      <PricingCards l2={l2} />
      <span id="after-pricing-cards"></span>
      <VSpacer size={12} />
      <PlainCouponLink afterSuccess={forceFullTreeRerender} />
      <VSpacer size={6} />
      <Copy>
        <strong>
          {__('Teaching with Jiveworld?', 'teachingWithJiveworld')}{' '}
        </strong>
        <br />
        <ExternalLink href={marketingSiteL1('/schools/plans')}>
          {__(
            'Learn about our classroom licenses',
            'learnAboutClassroomLicenses'
          )}
        </ExternalLink>
      </Copy>
      <VSpacer size={8} />
    </div>
  );
});
