import React from 'react';
import { HStack, VStack } from 'naan/primitives/stack';
// import { VSpacer } from 'naan/primitives/spacer';
import { AppFactory } from 'app/app-factory';
import { ActionLink } from 'common/ui/action-link';
import { observer } from 'mobx-react';
import {
  alertSevereError,
  alertWarningError,
  bugsnagNotify,
} from 'app/notification-service';
import { UserManager } from '@core/models/user-manager';
import { sleep } from '@utils/util';
import { MediumHeading } from '@naan/primitives/text';
import { showReloadToast } from 'pwa/window/update-checker';
// import { alertLevels, HandleableError } from '@core/lib/errors';
export const path = 'error';
export const label = 'Error stuff';

export const Component = observer(() => {
  const { root } = AppFactory;
  const { userManager } = root;

  // const { userData } = userManager;
  const [renderError, setRendererError] = React.useState(false);
  const handleClick = () => {
    setRendererError(true);
  };

  // strip down for easier browsing
  const userManagerOtherData = userManager.snapshot as UserManager;
  userManagerOtherData.accountData = undefined;
  userManagerOtherData.userData = undefined;

  return (
    <VStack>
      <MediumHeading>
        Error Stuff (for testing error handling and reporting)
      </MediumHeading>
      <HStack gap={'medium'}>
        <ActionLink onPress={handleClick} label={'[Render error]'}></ActionLink>
        {renderError && (window as any).nonexistent()}
        <ActionLink
          onPress={() => {
            throw new Error('This is an unhandled error');
          }}
          label={'[Throw error]'}
        />
        <ActionLink
          onPress={() => {
            // eslint-disable-next-line no-throw-literal
            throw 'This is an thrown string';
          }}
          label={'[Throw string]'}
        />
        <ActionLink
          onPress={() => {
            throw new Error('NetworkError');
          }}
          label={'[Throw network error]'}
        />
        {/* warnings should be direclty alerted, not thrown */}
        {/* <ActionLink
          onClick={() => {
            throw new HandleableError('Unhandled warn level error', {
              alertLevel: alertLevels.WARN,
              alert: true,
            });
          }}
          label={'[Throw warn level error]'}
        /> */}
      </HStack>
      {/* <VSpacer size={2} /> */}
      <HStack gap={'medium'}>
        <ActionLink
          onPress={() => bugsnagNotify('a message sent directly to bugsnag')}
        >
          [bugsnagNotify - string]
        </ActionLink>
        <ActionLink
          onPress={() =>
            bugsnagNotify(Error('an error sent directly to bugsnag'))
          }
        >
          [bugsnagNotify - error]
        </ActionLink>
        <ActionLink onPress={bugsnagNotifyAsyncError}>
          [bugsnagNotify - async error]
        </ActionLink>
        {/* <ActionLink onPress={() => invariant(false, 'test failed invariant')}>
          [failed invariant]
        </ActionLink> */}
        {/* <ActionLink
              onPress={() => {
                throw createSevereError({
                  error: new Error('source error'),
                  note: 'dev-tools',
                });
              }}
            >
              [Throw Severe Error]
            </ActionLink> */}
      </HStack>
      <HStack gap={'medium'}>
        <ActionLink
          onPress={() => {
            alertSevereError({
              error: new Error('Source error'),
              note: 'dev-tools',
            });
          }}
        >
          [alertSevereError]
        </ActionLink>

        <ActionLink
          onPress={alertAsyncSevereError}
          label={'[alertAsyncSevereError]'}
        />

        <ActionLink
          onPress={alertAsyncWarningError}
          label={'[alertAsyncWarningError]'}
        />
      </HStack>
      <HStack gap={'medium'}>
        {/* <ActionLink
          onPress={() =>
            checkVersionTextForUpdate().catch(bugsnagNotify)
          }
        >
          [Check version.txt for update]
        </ActionLink> */}
        <ActionLink
          onPress={showReloadToast}
          label={'[Show legacy update toast (no check)]'}
        />
        {/* <Button onClick={showUpdateToast} label={'View update toast'} /> */}
      </HStack>
    </VStack>
  );
});

const throwAsyncError = async (message?: string) => {
  await sleep(250);
  throw Error(message ?? 'Async error test');
};

const alertAsyncWarningError = () => {
  throwAsyncError('Warning async error').catch(error =>
    alertWarningError({ error, note: 'warning error - dev-tools test link' })
  );
};

const alertAsyncSevereError = () => {
  throwAsyncError('Severe async error').catch(error =>
    alertSevereError({ error, note: 'severe error - dev-tools test link' })
  );
};

const bugsnagNotifyAsyncError = () => {
  throwAsyncError('Bugsnag notified async error').catch(bugsnagNotify);
};
