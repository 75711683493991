import React from 'react';
import { observer } from 'mobx-react';
import { StoryCard } from 'components/stories/story-card';
import { Story } from '@core/models/story-manager/story';
// import { AppFactory } from '@app/app-factory';
// import { storiesPrimaryFiltered } from 'components/nav/path-helpers';
// import { PrimaryFilterKeys } from '@core/models/story-manager/story-manager';
import { Divider } from '@naan/primitives/divider';
import {
  SectionHeading,
  // SectionHeadingLink,
} from './dashboard-section-heading';
import __ from '@core/lib/localization';

// only needed for keeping miedo on the dashboard after completed for trial users
// no need to link to a stories filter list like the similar dashboard widgets
export const LastCompletedWidget = observer(({ story }: { story: Story }) => {
  if (!story) return null;

  // const { storyManager } = AppFactory.root;
  // const count = storyManager.completed.length;
  // const filterPath = storiesPrimaryFiltered(PrimaryFilterKeys.COMPLETED);

  return (
    <>
      <SectionHeading
        title={__('Listen again', 'listenAgain')}
        // renderLinks={() => (
        //   <>
        //     {count > 0 ? (
        //       <SectionHeadingLink to={filterPath}>
        //         {__('Study later queue', 'studyLaterQueue')}
        //       </SectionHeadingLink>
        //     ) : null}
        //   </>
        // )}
      />
      <Divider />
      <StoryCard story={story} truncateDescription={true} />
    </>
  );
});
