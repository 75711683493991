import * as React from 'react';

export function DownloadLargeIcon({
  width = 28,
  height = 28,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 28 28"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 3C11 2.44772 11.4477 2 12 2H16C16.5522 2 17 2.44772 17 3V13H22.7928C23.2383 13 23.4614 13.5386 23.1464 13.8536L14.3535 22.6464C14.1582 22.8417 13.8417 22.8417 13.6464 22.6464L4.8535 13.8536C4.53852 13.5386 4.76161 13 5.20706 13H11V3ZM23 24H5V26H23V24Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}

export function DownloadSmallIcon({
  width = 20,
  height = 20,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect height="11" width="6" fill={color} rx="1" x="7" y="2" />
      <rect height="1" width="12" fill={color} x="4" y="17" />
      <path
        d="M16.2929 9H3.70711C3.26165 9 3.03857 9.53857 3.35355 9.85355L9.64645 16.1464C9.84171 16.3417 10.1583 16.3417 10.3536 16.1464L16.6464 9.85355C16.9614 9.53857 16.7383 9 16.2929 9Z"
        fill={color}
      />
    </svg>
  );
}

export function DownloadExtraLargeIcon({
  width = 32,
  height = 32,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 4C12 3.44772 12.4477 3 13 3H19C19.5523 3 20 3.44772 20 4V15H25.7929C26.2383 15 26.4614 15.5386 26.1464 15.8536L16.3535 25.6464C16.1583 25.8417 15.8417 25.8417 15.6464 25.6464L5.85355 15.8536C5.53857 15.5386 5.76165 15 6.2071 15H12V4ZM26 27H6V29H26V27Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}

export function DownloadIcon({
  width = 24,
  height = 24,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 3C9 2.44772 9.44772 2 10 2H14C14.5523 2 15 2.44772 15 3V11H19.7929C20.2383 11 20.4614 11.5386 20.1464 11.8536L12.3536 19.6464C12.1583 19.8417 11.8417 19.8417 11.6464 19.6464L3.85355 11.8536C3.53857 11.5386 3.76165 11 4.20711 11H9V3ZM20 21H4V22H20V21Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}
