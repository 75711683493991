import { AppFactory } from 'app/app-factory';
import { appConfig } from 'app/env';
import __ from 'core/lib/localization';
// import { bugsnagNotify } from './services/notification-service';
// @ts-expect-error
import { versionCheck } from '@jw-spa-version';

import { createLogger } from '@common/log';
import {
  /*hasSwController,*/ isServiceWorkerRegistered,
} from './update-manager';
import { reload } from '@core/lib/app-util';
import { track } from '@app/track';
import { bugsnagNotify } from '@app/notification-service';
const log = createLogger('update-checker');

//
// used for native ios webview
//

const { updatePollingEnabled } = appConfig;
let pollIntervalMillis = appConfig.updateCheckIntervalMs;
log.info(
  `poll interval: ${pollIntervalMillis}ms - build version: ${versionCheck}`
);

// when doing a dumb reload and we see the mismatched version.txt immediately upon startup
// we need to deplay the user prompt
const BLIND_RELOAD_DELAY_MS = 15 * 1000;

let firstPoll = true;
let timeoutId: number = undefined;
let lastPollTimestamp = 0;
let checking = false;

// maybe paranoid, but attempt to sanity check that the polling is still active
export const isPollingStale = () => {
  return timeoutId && Date.now() - lastPollTimestamp > pollIntervalMillis * 1.5;
};

export const backoff = () => {
  pollIntervalMillis = pollIntervalMillis * 2;
  log.info(`back off poll interval to ${pollIntervalMillis}ms`);
};

// restore default check interval
export const unbackoff = () => {
  pollIntervalMillis = appConfig.updateCheckIntervalMs;
};

export const cancelUpdatePolling = () => {
  if (!updatePollingEnabled) {
    return;
  }
  if (timeoutId !== undefined) {
    log.debug(`cancelPolling - clearing timeout`);
    clearTimeout(timeoutId);
    timeoutId = undefined;
  } else {
    log.warn('cancelPolling - no timeoutId, ignoring');
  }
};

export const startUpdatePolling = () => {
  if (!updatePollingEnabled) {
    return;
  }
  if (timeoutId) {
    if (isPollingStale()) {
      log.warn(
        'startPolling - existing timeout appears to be stale, restarting'
      );
    } else {
      log.info('startPolling - already active, ignoring');
      return;
    }
  }
  handleCheckForUpdate({ poll: true }).catch(bugsnagNotify);
};

export const checkOnceForUpdate = async () => {
  return await handleCheckForUpdate({ poll: false });
};

const handleCheckForUpdate = async ({
  poll = false,
}: {
  poll?: boolean;
}): Promise<boolean> => {
  if (!updatePollingEnabled) {
    return;
  }
  if (checking) {
    log.debug(`ignoring concurrent checkForUpdate attempt`);
    return;
  }
  checking = true;
  try {
    if (poll) {
      timeoutId = undefined;
      // beware, ipad simulator safari can report a false negative here. and not sure if there are other situations also
      // if (AppFactory.root?.offlineOrHidden) {
      //   log.debug('offline/hidden - disabling update checker poll');
      //   log.debug(
      //     `offline: ${String(AppFactory.root?.offline)}, hidden: ${String(
      //       AppFactory.root?.hidden
      //     )}`
      //   );
      //   cancelUpdatePolling();
      //   return;
      // }
      if (AppFactory.root?.hidden) {
        log.debug('hidden - disabling update checker poll');
        cancelUpdatePolling();
        return;
      }
    }

    let mismatched = false;
    // check for the controller, not just sw, because it appears that sometimes we'll have a service worker,
    // but not yet an active controller
    if (isServiceWorkerRegistered()) {
      // && hasSwController()) {
      log.debug(`checkForUpdate - sw`);
      await checkSwForUpdate(); // we don't directly know if an update was found or not
    } else {
      log.debug(`checkForUpdate - verstion.txt`);
      mismatched = await checkVersionTextForUpdate();
    }

    if (poll && !mismatched) {
      firstPoll = false; // used to delay second reload prompt for blind version.txt poll
      unbackoff();
      log.debug(`rechecking in ${pollIntervalMillis}ms`);
      timeoutId = window.setTimeout(
        () => handleCheckForUpdate({ poll }),
        pollIntervalMillis
      );
    } else {
      log.info(`not rechecking, (poll: ${String(poll)})`);
    }
    return mismatched;
  } catch (error) {
    log.warn(`poll failed: ${error}`);
    backoff();
  } finally {
    checking = false;
  }
};

// for testing
(window as any).checkOnceForUpdate = checkOnceForUpdate;
(window as any).startUpdatePolling = startUpdatePolling;
(window as any).cancelUpdatePolling = cancelUpdatePolling;
(window as any).getTimeoutId = () => timeoutId;

export const checkSwForUpdate = async (): Promise<void> => {
  log.debug('checking for sw update');
  if (AppFactory.workbox) {
    await AppFactory.workbox.update();
  } else {
    // can happen in local dev
    log.warn(`checkSwForUpdate - workbox not defined, ignoring`);
  }
};

// test the version.txt for changes w/o side-effects
export const fetchVersionTxt = async (): Promise<{
  fetchedVersion: string;
  mismatched: boolean;
}> => {
  const fetchOptions = {
    method: 'GET',
    cache: 'no-store', // This prevents caching
  };
  const res = await fetch(
    '/version.txt',
    fetchOptions as any /* TS doens't like "no-store" */
  );
  const text = await res.text();
  const fetchedVersion = text.trim();
  const mismatched = fetchedVersion !== versionCheck;
  log.info(
    `buildVersion: ${versionCheck}, fetchedVersion: ${fetchedVersion}, mismatched: ${String(
      mismatched
    )}, firstPoll: ${String(firstPoll)}`
  );
  (window as any).fetchedVersionTxt = fetchedVersion; // debug hacking
  return { fetchedVersion, mismatched };
};

export const checkVersionTextForUpdate = async (): Promise<boolean> => {
  const { fetchedVersion, mismatched } = await fetchVersionTxt();
  if (mismatched) {
    track('update__version_txt_changed', {
      mode: 'dumb',
      fetchedVersion,
      buildVersion: versionCheck,
      firstPoll,
    });
    if (firstPoll) {
      log.info(`immedialy mismatched version.txt - pausing before prompt`);
      setTimeout(showReloadToast, BLIND_RELOAD_DELAY_MS);
    } else {
      showReloadToast();
    }
  }
  return mismatched;
};

(window as any).checkVersionTextForUpdate = checkVersionTextForUpdate; // for testing (see index.html

// dumb reload, used for version text mismatch flow
export const showReloadToast = () => {
  const { root } = AppFactory;
  if (!root.updatePromptEnabled) {
    log.warn(
      'presentSwUpdateToast while updatePrompt disabled - deferring, mode: dumb'
    );
    track('update__prompt_deferred', { mode: 'dumb' });
    root.updatePromptPending = 'dumb';
    return;
  }
  track('update__prompt_shown', { mode: 'dumb' });

  AppFactory.toastService.open({
    message: __('This site has been updated', 'thisSiteHasBeenUpdated'),
    type: 'info',
    action: {
      label: __('Refresh', 'refresh'),
      callback: () => {
        AppFactory.root?.userManager
          ?.persistLocal()
          .then(() => {
            reload();
            // not sure how to even trigger this flow any more, so not messing with reload flavor right now
            // experimentally reset to dashboard for ios embedded update
            // reloadOrNativeReset();
          })
          .catch(bugsnagNotify);
      },
    },
    timeout: null,
  });
};
