import * as React from 'react';
import { observer } from 'mobx-react';
import { AppFactory } from 'app/app-factory';
import { PageHeading } from 'components/page-heading';
import { isEmpty } from 'lodash';
import { CenterColumnLayout } from 'components/layouts/center-column-layout';
import { PointsNav } from './unauthenticated-dashboard-nav/points-nav';
import { createLogger } from '@common/log';
// import __ from '@core/lib/localization';

const log = createLogger('learn-dashboard-heading');

function resolveGreeting(l2: string, name: String) {
  switch (l2) {
    case 'en':
      return isEmpty(name) ? 'Hello!' : `Hello, ${name}`;
    // case 'es':
    default:
      return isEmpty(name) ? '¡Hola!' : `Hola, ${name}`;
  }
}

export const LearnDashboardHeading = observer(() => {
  const { root } = AppFactory;
  const { userManager } = root;
  const { accountData, userData, authenticated } = userManager;

  if (!authenticated && !userManager.fullAccess /*for brasil demo flow*/) {
    return null;
  }

  const l2 = root.l2;
  let title = resolveGreeting(l2, accountData.name);

  if (accountData.showAffiliateWelcome) {
    title = accountData.affiliateWelcomeHeading;
  }

  // for some reason it's important to reference userData.totalPoints here.
  // when inlined into the component params, it wasn't reacting after logout.
  const totalPoints = userData.totalPoints;
  log.debug(`totalPoints: ${totalPoints}`);

  return (
    <CenterColumnLayout>
      <PageHeading
        title={title}
        renderAccessory={() => <PointsNav />}
        showAccessoryInSmall={true}
      />
    </CenterColumnLayout>
  );
});
