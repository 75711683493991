import * as React from 'react';
import { ChapterCatalogData } from '@core/models/catalog';
import { styled } from '@naan/stitches.config';
import { useChapterRowUiContext } from '../chapter-row-ui-model';
import { observer } from 'mobx-react';
// import { OnboardingCompleteModal } from 'routes/learn/story-detail-screen/onboarding/onboarding-complete-modal';

import __ from '@core/lib/localization';

const ContentWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 4,
  width: '100%',
  maxWidth: 240,
  height: 136,
  // margin: '20px 0 28px',
  '@medium': {
    height: 152,
    paddingBottom: 8,
    // margin: '24px 0 36px',
  },
  '& .title': {
    textStyle: 'small-heading',
    textAlign: 'center',
  },
  '& .description': {
    textStyle: 'small-text',
    color: '$black-alpha-70',
    textAlign: 'center',
  },
  '& .duration': {
    textStyle: 'small-text',
    color: '$black-alpha-40',
  },
});

const StudyModeContent = ({ chapter }: { chapter: ChapterCatalogData }) => {
  return (
    <>
      <ContentWrapper>
        <div className="title">{__('Study', 'study')}</div>
        <div className="description">
          {__(
            'Assisted listen with speed control, vocabulary and translation',
            'assistedListenWithSpeedControl'
          )}
        </div>
        <div className="duration">{chapter.durationInWords}</div>
      </ContentWrapper>
    </>
  );
};

const ListenModeContent = ({ chapter }: { chapter: ChapterCatalogData }) => {
  return (
    <>
      <ContentWrapper>
        <div className="title">{__('Natural Listen', 'naturalListen')}</div>
        <div className="description">
          {__(
            'Listen at full speed without assistance',
            'listenAtFullSpeedWithoutAssistance'
          )}
        </div>
        <div className="duration">{chapter.durationInWords}</div>
      </ContentWrapper>
    </>
  );
};

const WhoaModeContent = () => {
  return (
    <>
      <ContentWrapper>
        <div className="title">{__('Whoa there!', 'whoaThereBang')}</div>
        <div className="description">
          {__(
            'Complete at least one listen before marking this chapter complete',
            'completeAtLeastOneListenBeforeMarkingThisChapterComplete'
          )}
        </div>
      </ContentWrapper>
    </>
  );
};

const CompletedModeContent = () => {
  return (
    <>
      <ContentWrapper>
        <div className="title">{__('Ready to move on?', 'readyToMoveOn')}</div>
        <div className="description">
          {__(
            'Tap the buttons above to get back into study',
            'tapTheButtonsAboveToGetBackIntoStudy'
          )}
        </div>
      </ContentWrapper>
      {/* <OnboardingCompleteModal /> */}
    </>
  );
};

export const ChapterStudyCardContent = observer(
  ({ chapter }: { chapter: ChapterCatalogData }) => {
    const model = useChapterRowUiContext();
    const { currentStudyMode } = model;
    const whoa =
      currentStudyMode === 'completed' && !chapter.isFirstListenComplete;

    return (
      <>
        {currentStudyMode === 'study' ? (
          <StudyModeContent chapter={chapter} />
        ) : currentStudyMode === 'listen' ? (
          <ListenModeContent chapter={chapter} />
        ) : whoa ? (
          <WhoaModeContent />
        ) : currentStudyMode === 'completed' ? (
          <CompletedModeContent />
        ) : null}
      </>
    );
  }
);
