import { AppFactory } from 'app/app-factory';
import { PlayerModel } from 'player/models/player-model';

// todo: should be able to remove this now and just use the AppFactory everyplace

// all we really need is some global state that is set during the player init.
// no need for a react context since we'll never render the study and soundbite players concurrent in the same app instance

// let playerModel: PlayerModel = null;

// const usePlayerModel = (model?: PlayerModel): PlayerModel => {
//   if (model !== undefined) {
//     playerModel = model;
//   }
//   return playerModel;
// };

const usePlayerModel = (): PlayerModel => {
  return AppFactory.playerModel;
};

// can't use the 'usexxx' function name from a class component
const getPlayerModel = () => AppFactory.playerModel;

export { usePlayerModel, getPlayerModel };
