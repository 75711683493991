import * as React from 'react';
import { TAssignmentMachineState } from './assignment-machine';
import { ClassSelectorModal } from './class-selector-modal';
import { ClassDetailModal } from './class-detail-modal';
import { ClassCreationModal } from './class-creation-modal';

type AssignmentDialogProps = {
  state: TAssignmentMachineState;
  onClose: () => void;
  onSelectClass: (classId: string) => void;
  onCreateClass: () => void;
};

export const AssignmentDialog = ({
  state,
  onClose,
  onSelectClass,
  onCreateClass,
}: AssignmentDialogProps) => {
  switch (state.value) {
    case 'closed':
      return null;

    case 'classSelector':
      return (
        <ClassSelectorModal
          selectedUnit={state.context?.selectedUnit as string}
          onClose={onClose}
          onSelectClass={onSelectClass}
          onCreateClass={onCreateClass}
        />
      );
    case 'classPrompt':
      return (
        <ClassCreationModal onClose={onClose} onSelectClass={onSelectClass} />
      );
    case 'classDetails':
      return (
        <ClassDetailModal
          onClose={onClose}
          unitSlug={state.context.selectedUnit!}
          classId={state.context.selectedClass!}
        />
      );

    default:
      return null;
  }
};
