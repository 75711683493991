import * as React from 'react';
import * as Dialog from '@naan/primitives/modals/dialog';
import { FavoriteIcon } from '@naan/icons/favorite-icon';
import { styled } from '@naan/stitches.config';

import __ from '@core/lib/localization';

const Wrapper = styled('div', {
  paddingBlock: 16,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 4,

  '& .icon': {
    color: '$red-400',
    display: 'flex',
    justifyContent: 'center',

    lineHeight: 0,
    svg: {
      width: 56,
      height: 56,
    },
  },

  '& .title': {
    textStyle: 'small-heading',
  },
});

export const ThanksDialog = ({ onDismiss }: { onDismiss: () => void }) => {
  return (
    <Dialog.Container open onDismiss={onDismiss}>
      <Dialog.Body>
        <Wrapper>
          <div className="icon">
            <FavoriteIcon />
          </div>
          <div className="title">
            {__('Thanks for your feedback', 'thanksForYourFeedback')}
          </div>
          <div className="context">
            {__(
              'We appreciate your taking the time.',
              'weAppreciateYourTakingTheTime'
            )}
          </div>
        </Wrapper>
      </Dialog.Body>
      <Dialog.ButtonsContainer>
        <Dialog.Button
          onClick={onDismiss}
          label={__('Done', 'done')}
          presentation="teal"
        />
      </Dialog.ButtonsContainer>
    </Dialog.Container>
  );
};
