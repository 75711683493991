import {
  makeObservable,
  computed,
  observable,
  reaction,
  runInAction,
} from 'mobx';
import { WrappedAudioElement } from './wrapped-audio-element';

const emptyAudioSource = new WrappedAudioElement(null);

export class MultiAudioSource {
  audioSourceKeySortOrder: string[];
  defaultSourceKey: string;
  sourcesKey: string;
  // eslint-disable-next-line no-unused-vars
  audioSourceDefinitions: { [index in string]: string };
  currentAudioSourceKey: string;
  currentAudioElement: WrappedAudioElement;
  constructor(audioSourceKeySortOrder: string[], defaultSourceKey: string) {
    this.audioSourceKeySortOrder = audioSourceKeySortOrder;
    this.defaultSourceKey = defaultSourceKey;
    this.sourcesKey = ''; // TODO use sourcesKey to not do mutation if same?
    this.audioSourceDefinitions = {};
    this.currentAudioSourceKey = this.defaultSourceKey;
    this.currentAudioElement = emptyAudioSource;
    makeObservable(this, {
      audioSourceDefinitions: observable.ref,
      currentAudioSourceKey: observable.ref,
      currentAudioElement: observable.ref,
      audioSourceElements: computed({ keepAlive: true }),
      sourceKeys: computed({ keepAlive: true }),
      computedAudioElement: computed({ keepAlive: true }),
    });
    reaction(
      () => this.computedAudioElement,
      () => this.configureCurrentAudioElement(),
      { fireImmediately: true }
    );
  }

  setAudioSourceDefinitions(
    key: string,
    // eslint-disable-next-line no-unused-vars
    sources: { [index in string]: string }
  ) {
    this.sourcesKey = key;
    runInAction(() => {
      this.audioSourceDefinitions = sources;
      this.currentAudioSourceKey = this.defaultSourceKey;
    });
  }

  setCurrentAudioSourceKey(sourceKey: string) {
    this.currentAudioSourceKey = sourceKey;
  }

  // eslint-disable-next-line no-unused-vars
  get audioSourceElements(): { [index in string]: WrappedAudioElement } {
    // eslint-disable-next-line no-unused-vars
    const result: { [index in string]: WrappedAudioElement } = {} as any;
    for (const [key, url] of Object.entries(this.audioSourceDefinitions)) {
      result[key] = new WrappedAudioElement(url);
    }
    return result;
  }

  get sourceKeys() {
    const availableSources = new Set(Object.keys(this.audioSourceDefinitions));
    return this.audioSourceKeySortOrder.filter(key =>
      availableSources.has(key)
    );
  }

  get computedAudioElement() {
    const result = this.audioSourceElements[this.currentAudioSourceKey];
    if (result) {
      return result;
    }
    return emptyAudioSource;
  }

  configureCurrentAudioElement() {
    if (this.computedAudioElement !== emptyAudioSource) {
      this.computedAudioElement.currentTime =
        this.currentAudioElement.currentTime;
      this.computedAudioElement.playbackRate =
        this.currentAudioElement.playbackRate;
    }
    this.currentAudioElement = this.computedAudioElement;
  }

  audioElementFunction = () => this.currentAudioElement;
}
