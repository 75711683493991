import { Classroom } from 'core/models/user-manager';
import * as React from 'react';
import { snap } from 'ts-state-tree/tst-core';

type TCurrentClassroomContext = {
  classroom: Classroom;
  deleteCurrentClassroom: () => void;
};

export const CurrentClassroomContext =
  React.createContext<TCurrentClassroomContext>({
    classroom: snap({}),
    deleteCurrentClassroom: () => {},
  });

export const useCurrentClassroom = () =>
  React.useContext(CurrentClassroomContext);
