import { appConfig } from 'app/env';
import {
  basicScriptUrl,
  scaffoldedScriptUrl,
} from 'components/nav/path-helpers';
import __ from 'core/lib/localization';
import { Story } from 'core/models/story-manager';

const makeUrl = (params: any) => {
  const { btoa } = window;
  const id = btoa(btoa(JSON.stringify(params)));
  return `${appConfig.website.urls.jwPressServer}/api/listening-guide?request_id=${id}`;
};

export type DownloadableResource = {
  label: string;
  description: string;
  url: string;
  restricted?: boolean;
};

export function getResources(
  story: Story,
  email: string
): DownloadableResource[] {
  const { slug } = story;
  // need to build fully qualified URLs for native external links to work
  // const baseUrl = appConfig.website.baseUrl;
  // const { token } = AppFactory.root.userManager;

  const resources: DownloadableResource[] = [];

  const guidedListeningLabel = __(
    'Guided listening worksheet',
    'guidedListeningWorkSheet'
  );

  resources.push({
    label: __('Basic story script', 'basicStoryScript'),
    description: __(
      'Story transcript and translation, side by side',
      'storyTranscriptAndTranslation'
    ),
    // url: `${baseUrl}/scripts/basic/${story.slug}`,
    url: basicScriptUrl(story),
    restricted: false,
  });

  resources.push({
    label: __('Scaffolded story script', 'scaffoldedStoryScript'),
    description: __(
      'Fully annotated by chapter with vocabulary, hints, and notes',
      'fullyAnnotatedByChapter'
    ),
    // i don't think the token should be needed
    // url: `${baseUrl}/scripts/scaffolded/${story.slug}?token=${token}`,
    url: scaffoldedScriptUrl(story),
  });

  resources.push({
    label: `${guidedListeningLabel} (${__('PDF', 'pdf')})`,
    description: __('Print out for writing notes', 'printOutFor'),
    url: makeUrl({ slug, email }),
  });

  resources.push({
    label: `${guidedListeningLabel} (${__('RTF', 'rtf')})`,
    description: __(
      'Open in Word or Google Docs for typing notes',
      'openInWordOr'
    ),
    url: makeUrl({ slug, email, format: 'rtf' }),
  });

  return resources;
}
