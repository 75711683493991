import * as React from 'react';
import { getUiThemePreference, setTheme } from '@naan/stitches.config';

export const UiModeHandler: React.FC = () => {
  const preferredUiTheme = getUiThemePreference();

  if (preferredUiTheme === 'system') {
    const prefersDarkMode =
      window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches;

    if (prefersDarkMode) {
      setTheme('dark');
    } else {
      setTheme('light');
    }
  } else {
    setTheme(preferredUiTheme === 'dark' ? 'dark' : 'light');
  }

  React.useEffect(() => {
    const preferredUiTheme = localStorage.getItem('jw-ui-theme');
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleChange = (e: MediaQueryListEvent) => {
      if (preferredUiTheme === 'system' && e.matches) {
        setTheme('dark');
      }
    };

    mediaQuery.addEventListener('change', handleChange);

    return () => mediaQuery.removeEventListener('change', handleChange);
  }, []);

  return null;
};
