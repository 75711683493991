import { useLayoutEffect } from 'react';
import { getMainScrollingContainer } from './get-first-scrolling-parent';

export function scrollContainerToElementId(id: string) {
  const element = document.getElementById(id);
  if (element) {
    const top = element.offsetTop;
    scrollContainerTo(top, 'smooth');
  }
}

export function scrollContainerTo(
  top: number,
  behavior: 'smooth' | 'instant' = 'instant'
) {
  const scrollingParent = getMainScrollingContainer();

  if (scrollingParent) {
    scrollingParent?.scrollTo({
      top,
      left: 0,
      /// typescript is wrong here. 'instant' is a valid value
      // @ts-ignore
      behavior,
    });
  }
}

export function scrollContainerToTop() {
  return scrollContainerTo(0);
}

export function useScrollContainerToTop() {
  return useLayoutEffect(() => {
    /// this RAF is necessary because if we scroll before we have a chance to render,
    /// the scroll will be ignored. This is more obvious when using the browser back button
    requestAnimationFrame(scrollContainerToTop);
  }, []);
}
