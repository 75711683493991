import * as React from 'react';

export function ClearIcon({ width = 24, height = 24, color = 'currentColor' }) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM7 8.11111L8.11111 7L12 10.8889L15.8889 7L17 8.11111L13.1111 12L17 15.8889L15.8889 17L12 13.1111L8.11111 17L7 15.8889L10.8889 12L7 8.11111Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}
