import * as React from 'react';
import { focusRestorerId } from './focus-restorer';

export const useRestoreFocus = () => {
  // this will return focus to whatever had focus before opening the dialog. :party-emoji:
  const prevFocusElement = React.useRef<HTMLElement | null>(null);
  React.useEffect(() => {
    prevFocusElement.current = document.activeElement as HTMLElement;
    return () => {
      /// Apparently, if the body is the active element, then keyboard focus is lost
      /// So, we are using this hack, where we set the focus to a hidden button
      /// this makes the shortcuts work again
      if (document.body === prevFocusElement.current) {
        const focusRestorer = document.getElementById(focusRestorerId);
        if (focusRestorer) {
          // console.log('USING RESTORER');
          focusRestorer.focus();
        }
      } else {
        prevFocusElement.current?.focus();
      }
    };
  }, []);
};
