// FIXME:
// On a second thought, I don't think this library belongs in core
// but we need to find a way to share code such as hooks with some differentiation
// that some will be universal, while others may be web only (like this one)

import * as React from 'react';

import { FilteringContext, TFilteringContext } from './filtering-context';

export function useFiltering(): TFilteringContext {
  const context = React.useContext(FilteringContext);
  if (context.filter === undefined) {
    throw new Error('useFiltering must be used within FilteringContext');
  }

  return context as TFilteringContext;
}
