import { styled } from '@naan/stitches.config';
import { paletteKey } from '../../../study/views/study-palette';

export const ProgressBar = styled('div', {
  $$furthest: '50%',
  $$current: '40%',
  $$backgroundColor: paletteKey('$$transport__progressBackgroundColor'),
  $$currentColor: paletteKey('$$transport__progressCurrentColor'),
  $$furthestColor: paletteKey('$$transport__progressFurthestColor'),
  height: 4,
  width: '100%',
  transition: 'background 0.12s ease-in',
  background: `linear-gradient(
        90deg,
        $$currentColor $$current,
        $$furthestColor $$current, $$furthestColor $$furthest,
        $$backgroundColor $$furthest
      )`,
});
