import React from 'react';
import { Heading } from 'components/ds/common/heading';
import { styled } from '@naan/stitches.config';

const Container = styled('div', {
  marginBottom: '80px',
});

export const ClassroomSection = ({
  title,
  children,
  control,
  withHeadingMargin,
  withHeadingRule,
  stickyHeader = false,
}: {
  title: string;
  children: React.ReactNode;
  control?: React.ReactNode;
  withHeadingMargin?: boolean;
  withHeadingRule?: boolean;
  stickyHeader?: boolean;
}) => {
  return (
    <Container>
      <Heading
        control={control}
        makeSticky={stickyHeader}
        withRule={withHeadingRule}
        withMargin={withHeadingMargin}
      >
        {title}
      </Heading>
      {children}
    </Container>
  );
};
