import * as React from 'react';

export function EduLargeIcon({
  width = 28,
  height = 28,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 28 28"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 1.86682L28 9.54234L14 17.2179L2.28882e-05 9.54234L14 1.86682ZM14 18L4.99999 13V18.9686C4.99999 20.0681 7.87999 23 14 23C20.12 23 23 20.0681 23 18.9686V13L14 18ZM27 11L26 11.5V17.3832L25.5 21.5V22H27.5V21.5L27 17.3832V11Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}

export function EduExtraLargeIcon({
  width = 32,
  height = 32,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 2.13354L32 10.9056L16 19.6776L2.67029e-05 10.9056L16 2.13354ZM16 20.5L6 15V21.5654C6 22.7749 9.2 26 16 26C22.8 26 26 22.7749 26 21.5654V15L16 20.5ZM31 12.5001L30 13.0001V19.8666L29.5 24.5001V25.0001H31.5V24.5001L31 19.8666V12.5001Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}

export function EduIcon({ width = 24, height = 24, color = 'currentColor' }) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 1.60016L24 8.17918L12 14.7582L1.52588e-05 8.17918L12 1.60016ZM12 15.5001L4.00002 11.0001V16.3718C4.00002 17.3613 6.56001 20.0001 12 20.0001C17.44 20.0001 20 17.3613 20 16.3718V11.0001L12 15.5001ZM23 9.50006L22 10.0001V14.8999L21.5 18.3999V19.0001H23.5V18.3999L23 14.8999V9.50006Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}
