import * as React from 'react';
import { AppFactory } from '@app/app-factory';
import { profileHomePath } from 'components/nav/path-helpers';
import { useNavigate } from 'react-router-dom';
import {
  alertWarning,
  bugsnagNotify,
  notifySuccess,
} from '@app/notification-service';

export const EnableVocabExport = () => {
  const navigate = useNavigate();
  const { authenticated, userData } = AppFactory.root.userManager;
  const { userSettings } = userData;

  React.useEffect(() => {
    if (authenticated) {
      userSettings
        .enableVocabExport()
        .then(() => {
          notifySuccess('Vocab export feature enabled');
          navigate(profileHomePath());
        })
        .catch(bugsnagNotify);
    } else {
      alertWarning('Must be signed in to enable vocab export');
      navigate(profileHomePath());
    }
  }, [authenticated, userSettings, navigate]);

  return <></>;
};
