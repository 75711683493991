import * as React from 'react';
import { observer } from 'mobx-react';
import { Vocabulary } from './info/vocabulary';
import { ExcerptData } from '@tikka/client/catalog-types';
import { styled } from '@naan/stitches.config';
import { GlobalRedactionButton } from 'study/views/global-redaction-button';
import { useReaction } from '@common/hooks/use-reaction';
import { usePlayerModel } from 'player/views/player-model-context';
import { paletteKey } from 'study/views/study-palette';

const Wrapper = styled('div', {
  padding: '16px 16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  // extra 8px here is to cover shadows on speaker labels/sentences that pass under this
  maxWidth: '608px',
  margin: '0 auto 8px',
  position: 'sticky',
  top: '56px', // @armando: this is height of player header – should this be a var?
  background: paletteKey('$$script__backgroundColor_default'),
  zIndex: 2,
  transition: 'top 0.3s ease 0s, max-width 0.3s',
  // This slightly odd setup is because the toolbar needs to be in front of the backdrop, but should still darken when playing
  '&:before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: paletteKey('$$scriptPlayingBackdrop__backgroundColor'),
    zIndex: -1,
    opacity: 0,
    transition: 'opacity .3s',
  },
  '&.playing': {
    top: 0,
    '&:before': {
      opacity: 1,
    },
  },

  '& > .redaction-button': {
    position: 'absolute',
    right: '0',
  },

  '& > .rule': {
    display: 'block',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    borderBottom: '1px solid $black-alpha-06',
    '@widerThanScript': {
      left: 4,
      right: 4,
    },
  },

  '@widerThanScript': {
    justifyContent: 'center',
  },
  '@playerMedium': {
    ':has(.showing-translation) &': {
      // extra 8px here is to cover shadows on speaker labels/sentences that pass under this
      maxWidth: '1000px',
    },
  },
});

export const SbToolbar = observer(({ excerpt }: { excerpt: ExcerptData }) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const model = usePlayerModel();

  useReaction(
    () => model.isPlaying,
    () => {
      if (model.isPlaying) {
        ref.current?.classList.add('playing');
      } else {
        ref.current?.classList.remove('playing');
      }
    }
  );

  return (
    <Wrapper ref={ref}>
      <div className="vocabulary-button">
        <Vocabulary excerpt={excerpt} />
      </div>
      <div className="redaction-button">
        <GlobalRedactionButton />
      </div>
      <div className="rule"></div>
    </Wrapper>
  );
});
