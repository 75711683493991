import * as React from 'react';
import { styled } from '@naan/stitches.config';
import { ClassroomBrand } from './classroom-brand';
import { JwSymbol, SymbolPresentation } from './jw-symbol';
import { presentL2InfoSwitchModal } from 'routes/auth/welcome-screen/l2-info-switch-modal';
import { ChevronDownExtraSmallIcon } from '@naan/icons/chevron-down-icon';
// import { appConfig } from '@app/config';

// import { subBrand } from '@core/lib/app-util';

const Wrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  // img: {
  //   width: '100%',
  // },
  '& .sub-brand': {
    color: '$gray-500',
    textStyle: 'small-caps',
    userSelect: 'none',
    marginLeft: 4,
  },
  '&:not(.presentation-color) .sub-brand': {
    color: '$globalWhite',
  },
  '& .sub-brand:has(svg)': {
    color: '$textPrimary',
  },

  '& .chevron': {
    // marginLeft: 4,
    color: '$gray-500',
    // marginTop: -4,
    '& svg': {
      width: 12,
    },
  },

  '&:has(.chevron)': {
    '&:hover': {
      '& .sub-brand': {
        opacity: 0.8,
      },
    },
  },
});

const subBrandTypes = {
  none: 'none',
  classrooms: 'classrooms',
  // @armando, should we just ditch the typing here at this point?
  espanol: 'español',
  español: 'español',
  english: 'english',
  es: 'es',
  en: 'en',
} as const;

type SubBrandType = typeof subBrandTypes[keyof typeof subBrandTypes];

export const JwLogo = ({
  presentation = 'color',
  subBrand = subBrandTypes.none,
  l2Switch = false,
}: {
  presentation?: SymbolPresentation;
  subBrand?: SubBrandType;
  l2Switch?: boolean; // ignored for now - always on/off per deployment config
}) => {
  // l2Switch = !appConfig.forcedL2;
  // const handleClick = l2Switch ? () => presentL2InfoSwitchModal() : undefined;
  const handleClick = () => presentL2InfoSwitchModal();
  return (
    <Wrapper className={`presentation-${presentation}`} onClick={handleClick}>
      <JwSymbol presentation={presentation} />
      {subBrand === subBrandTypes.classrooms ? (
        <span className={'sub-brand'}>
          <ClassroomBrand />
        </span>
      ) : subBrand !== subBrandTypes.none ? (
        <>
          <span className="sub-brand">{subBrand}</span>
          {l2Switch ? (
            <span className="chevron">
              <ChevronDownExtraSmallIcon />
            </span>
          ) : null}
        </>
      ) : null}
    </Wrapper>
  );
};
