import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { styled } from '@naan/stitches.config';

const Wrapper = styled('div', {
  color: '$colors$gray-800',
  marginBottom: '4px',
  paddingTop: '16px',
  fontWeight: '600',
  paddingRight: '32px',
});

export const StudentNameCell = ({ name }: { name: string }) => {
  const isMobile = useMediaQuery({
    query: '(max-device-width: 550px)',
  });

  if (!isMobile) {
    return <>{name}</>;
  }

  return <Wrapper>{name}</Wrapper>;
};
