import { LocaleCode } from '@utils/util-types';
import { observable, makeObservable, action } from 'mobx';

export class AccountScreenUiController {
  @observable
  extendingL2: LocaleCode | null = null;

  constructor() {
    makeObservable(this);
  }

  @action extendL2(l2: LocaleCode) {
    this.extendingL2 = l2;
  }

  @action cancelExtendL2() {
    this.extendingL2 = null;
  }

  @action toggleExtendL2(l2: LocaleCode) {
    if (this.extendingL2 === l2) {
      this.cancelExtendL2();
    } else {
      this.extendL2(l2);
    }
  }

  static __instance: AccountScreenUiController | null = null;

  static get instance() {
    if (!AccountScreenUiController.__instance) {
      AccountScreenUiController.__instance = new AccountScreenUiController();
    }

    return AccountScreenUiController.__instance;
  }
}
