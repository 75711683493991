export interface Rgba {
  r: number;
  g: number;
  b: number;
  a: number;
}

export function hexToRgba(hex: string, alpha: number): Rgba {
  const hexValue = parseInt(hex.slice(1), 16);
  const r = (hexValue >> 16) & 255;
  const g = (hexValue >> 8) & 255;
  const b = hexValue & 255;

  return { r, g, b, a: alpha };
}

export function rgbaToString(rgba: Rgba): string {
  return `rgba(${rgba.r},${rgba.g},${rgba.b},${rgba.a})`;
}
