import * as React from 'react';
import { observer } from 'mobx-react';
import { VSpacer } from '@naan/primitives/spacer';
import {
  createAccountPath,
  marketingSiteL1,
  signInPath,
  subscribePath,
} from 'components/nav/path-helpers';
import { AnonPricingCards } from 'components/account/pricing-cards/pricing-cards';
import { Button } from '@naan/primitives/button';
import { AdaptiveLink, PlainMarkdown } from '@naan/primitives/text';
import { AnonymousPlainCouponLink } from 'components/coupons/coupon-buttons';
import { useNavigate } from 'react-router-dom';
import { AppFactory } from '@app/app-factory';
import { styled } from '@naan/stitches.config';

import __ from 'core/lib/localization';

const Copy = styled('div', {
  '& strong': {
    textStyle: 'body-bold',
  },
});

// profile/subscribe
export const AnonymousFlow = observer(() => {
  const navigate = useNavigate();
  const { l2 } = AppFactory.root;
  return (
    <div>
      <AnonPricingCards l2={l2} />

      <div className="anon-cta">
        <Button
          label={__('Create an account', 'createAnAccount')}
          presentation={'teal'}
          fullWidth
          size="extraLarge"
          onClick={() =>
            // navigate(`/auth/create-account?after=${profileHomePath()}`)
            navigate(createAccountPath({ after: subscribePath() }))
          }
        />
        <VSpacer size={4} />
        <span className="or-sign-in">
          <PlainMarkdown
            components={{
              a: ({ node, ...props }) => <AdaptiveLink {...props} />,
            }}
            source={__(
              'or [sign in](%{url}) to choose your plan',
              'orSignInToChooseYourPlanLower',
              // { url: `/auth/sign-in?after=${profileHomePath()}` }
              { url: signInPath({ after: subscribePath() }) }
            )}
          />
        </span>
      </div>
      <VSpacer size={12} />
      <AnonymousPlainCouponLink />
      <VSpacer size={6} />
      <Copy>
        <strong>
          <PlainMarkdown
            // @daniel, is it okay to flatten this to the singular 'Student discount'?
            source={__('Student discounts', 'studentDiscounts')}
          />
        </strong>
        <PlainMarkdown
          components={{
            a: ({ node, ...props }) => <AdaptiveLink {...props} />,
          }}
          source={__(
            '[Sign up with your school email](%{signupUrl}) to see student prices. For educators, [learn about our classroom licenses](%{classroomUrl})',
            'signUpWithYourSchoolEmailToSeeStudentPrices',
            {
              // signupUrl: `/auth/create-account?after=${profileHomePath()}`,
              signupUrl: createAccountPath({ after: subscribePath() }),
              classroomUrl: marketingSiteL1('/schools/plans'),
            }
          )}
        />
      </Copy>
      <VSpacer size={8} />
    </div>
  );
});
