import React from 'react';
import { styled } from '@naan/stitches.config';

const DefWrapper = styled('dl', {
  borderBottom: '1px solid $gray-100',
  paddingBottom: '$2',
  marginBottom: '$2',
  dt: {
    textStyle: 'small-text',
    color: '$textSecondary',
    marginBottom: '$space$1',
  },
  dd: {},
});

export const Definition = ({
  label,
  value,
}: {
  label: React.ReactNode;
  value: React.ReactNode;
}) => (
  <DefWrapper>
    <dt>{label}</dt>
    <dd>{value}</dd>
  </DefWrapper>
);
