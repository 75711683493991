import { createLogger } from '@common/log';
import { action, makeObservable, observable } from 'mobx';

const log = createLogger('browser-wake-lock');

export class BrowserWakeLockManager {
  private wakeLock: WakeLockSentinel | null = null;
  private visibilityListener: () => void;

  type = 'browser';

  active = false;

  constructor() {
    makeObservable(this, {
      active: observable,
      enable: action,
      disable: action,
    });

    // // Listen for visibility change events
    // this.visibilityListener = () => {
    //   if (document.visibilityState === 'visible' && !this.active) {
    //     /// If the document becomes visible and the wake lock is not active, re-enable it
    //     log.info('Visibility changed to visible. Re-acquiring WakeLock.');
    //     void this.enable();
    //   }
    // };
    // document.addEventListener('visibilitychange', this.visibilityListener);
  }

  async enable(): Promise<boolean> {
    if (!('wakeLock' in navigator)) {
      log.error('WakeLock API is not supported in this browser.');
      return false;
    }

    try {
      this.wakeLock = await navigator.wakeLock.request('screen');
      this.active = true;
      this.wakeLock.addEventListener('release', () => {
        this.active = false;
        log.info('WakeLock released.');
      });

      log.info('WakeLock enabled.');
      return true;
    } catch (error) {
      log.error('Error while requesting WakeLock:', error);
      return false;
    }
  }

  async disable(): Promise<void> {
    if (this.wakeLock) {
      try {
        await this.wakeLock.release();
        this.wakeLock = null;
        log.info('WakeLock disabled.');
      } catch (error) {
        log.error('Error while releasing WakeLock:', error);
      }
    }
  }

  // Cleanup the event listener when the instance is destroyed
  destroy() {
    document.removeEventListener('visibilitychange', this.visibilityListener);
  }
}
