import { AppFactory } from '@app/app-factory';
import { __, __s, translateWithoutDefault } from './localization';
import { CmsKeys } from '../../langs/cms-keys';
import { BillingInterval } from '@cas-shared/cas-types';
// import { getLocale } from './localization';
// import { startCase } from 'lodash';

// export const localizedProperty = (object: any, propertyName: string) => {
//   const qualified = `${propertyName}${startCase(getLocale())}`;
//   const fallback = `${propertyName}En`; // hardwired fallback to english for now
//   return object[qualified] || object[fallback];
// };

export function cms(key: CmsKeys) {
  const { l2 } = AppFactory.root;
  return translateWithoutDefault(`cms:${key}:${l2}`);
}

// note, this code snippet exists purely to drive localized string extraction logic.
// it's not used at run-time.
// eslint-disable-next-line no-unused-vars
const miscTranslationSnippet = () => {
  // these are generic enough that we might use beyond the billing interval
  __s('month', 'monthLower');
  __s('year', 'yearLower');
};

export function billingIntervalL1(interval: BillingInterval) {
  return translateWithoutDefault(`${interval}Lower`) || interval;
}

export const storiesCount = (n: number) => {
  return __({ one: '1 story', other: '%{count} stories' }, 'storiesCount', {
    count: n,
  });
};

export const monthsCount = (count: number) =>
  __(
    {
      one: '1 month',
      other: '{{count}} months',
    },
    'monthsCount',
    { count }
  );

export const daysCount = (count: number) =>
  __(
    {
      one: '1 day',
      other: '{{count}} days',
    },
    'daysCount',
    { count }
  );
