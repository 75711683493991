import * as React from 'react';
import { HStack, VStack } from 'naan/primitives/stack';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { ActionLink } from 'common/ui/action-link';
import { MediumHeading } from '@naan/primitives/text';
import { LoggerConfig, setLogLevel } from '@common/log/logger-config';
import { Definition } from './components/definition';
import { ConfigArea } from './dev-data-dump';
import { appConfig } from '@app/config';
import { bufferLogger } from '@common/log/buffer-logger';
import { LogLevel } from '@common/log/logger-types';
import * as loggly from 'legacylib/loggly';
import { AppFactory } from '@app/app-factory';
import { bugsnagNotify } from '@app/notification-service';
// import { createLogger } from '@common/log';
// const log = createLogger('dev-logs');

const observableLogLevel = observable({
  level: LoggerConfig.instance.minimumLogLevel,
});

const LogLevels = observer(() => {
  const updateLevel = (level: LogLevel) => {
    setLogLevel(level);
    // trigger desired rerenders in isolation from logging implementation
    observableLogLevel.level = level;
  };

  return (
    <>
      <HStack gap={'small'}>
        Level: {observableLogLevel.level}
        {'  '}&nbsp;
        <ActionLink onPress={() => updateLevel(0)} label="[off-0]" />{' '}
        <ActionLink onPress={() => updateLevel(1)} label="[trace-1]" />{' '}
        <ActionLink onPress={() => updateLevel(2)} label="[debug-2]" />{' '}
        <ActionLink onPress={() => updateLevel(3)} label="[info-3]" />{' '}
        <ActionLink onPress={() => updateLevel(4)} label="[warn-4]" />{' '}
        <ActionLink onPress={() => updateLevel(5)} label="[warn-5]" />
        {/* &nbsp; (not responsive) */}
      </HStack>
    </>
  );
});

export const path = 'logs';
export const label = 'Logs';

export const Component = observer(() => {
  return (
    <VStack>
      <MediumHeading>Logs</MediumHeading>
      <p>Logger config: {appConfig.logging.impl}</p>
      <LogLevels />
      <BufferView />
      <LogglyView />
    </VStack>
  );
});

const BufferView = observer(() => {
  if (appConfig.logging.impl !== 'buffer') {
    return null;
  }
  const { userManager } = AppFactory.root;

  return (
    <>
      <p>
        Buffer activated: {String(bufferLogger.bufferActivated)}{' '}
        <ActionLink onPress={() => bufferLogger.toggleBuffer()}>
          [toggle]
        </ActionLink>
        {' - '}
        <ActionLink onPress={() => bufferLogger.clear()}>[clear]</ActionLink>
        &nbsp; (not responsive)
      </p>
      <p>
        Console activated: {String(bufferLogger.consoleActivated)}{' '}
        <ActionLink onPress={() => bufferLogger.toggleConsole()}>
          [toggle]
        </ActionLink>
      </p>
      <p>
        Native relay activated: {String(bufferLogger.nativeRelayActivated)}{' '}
        <ActionLink onPress={() => bufferLogger.toggleNativeRelay()}>
          [toggle]
        </ActionLink>
        &nbsp;(ignored when not embedded)
      </p>
      <p>
        <ActionLink
          onPress={() =>
            userManager
              .sendDebugData({ logs: bufferLogger.buffer })
              .catch(bugsnagNotify)
          }
        >
          [send log data to rails]
        </ActionLink>
      </p>
      <Definition
        label={'logs'}
        value={
          <ConfigArea
            // todo: best defaults sizing?
            cols={80}
            rows={30}
            value={bufferLogger.bufferText}
            readOnly
          />
        }
      />
    </>
  );
});

const LogglyView = observer(() => {
  const { root } = AppFactory;
  if (!loggly.enabled) {
    return null;
  }

  return (
    <>
      Loggly activated:&nbsp;
      {loggly.isActivated() ? <strong>true</strong> : 'false'}
      &nbsp;
      <ActionLink
        onPress={() => {
          loggly.toggleActivation();
          root.localState
            .storeLogglyEnabled(loggly.isActivated())
            .catch(error => bugsnagNotify(error));
          // setDirty(Date.now());
        }}
      >
        [toggle]
      </ActionLink>
      &nbsp;(not responsive)
    </>
  );
});
